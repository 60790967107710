import React, {useEffect} from 'react';
import {PrivateRoute} from 'components';
import {Switch, useRouteMatch} from 'react-router'
import AllAboutMeContainer from 'pages/AllAboutMe/AllAboutMeContainer';
import AllAboutMeCategory from 'pages/AllAboutMe/AllAboutMeCategory';
import AllAboutMeQuestion from 'pages/AllAboutMe/AllAboutMeQuestion';
import AllAboutMeRandomQuestion from "./AllAboutMeRandomQuestion";



const AllAboutMe = () => {
    const match = useRouteMatch();
  useEffect(() => {
    document.title = "JRNL | All About Me"
  }, []);
    return (
        <div>
            <main className="app-main">
                <Switch>
                    <PrivateRoute exact path={`${match.path}category/:categoryId/`} component={AllAboutMeCategory}/>
                    <PrivateRoute exact path={`${match.path}question/:questionId`} component={AllAboutMeQuestion}/>
                    <PrivateRoute exact path={`${match.path}answers/:questionId`} component={AllAboutMeQuestion}/>
                    <PrivateRoute exact path={`${match.path}random-question`} component={AllAboutMeRandomQuestion}/>
                    <PrivateRoute exact path={`${match.path}`} component={AllAboutMeContainer}/>
                </Switch>
            </main>
        </div>
    )
};

export default AllAboutMe;