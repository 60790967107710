import React from 'react';

export default (Component, sharedUsers, onUpdate, publicEntry) => (props) => (
    <Component
        onUpdate={onUpdate}
        sharedUsers={sharedUsers}
        publicEntry={publicEntry}
        {...props}
    />
);
