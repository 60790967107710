import React, { useEffect, useCallback } from 'react';
// redux
import { connect } from 'react-redux';
// Selectors
import { getUI } from 'redux/selectors';
import { createStructuredSelector } from 'reselect';
// Actions
import { updateUI } from 'redux/actions/ui';
// utils
import { checkFrontCoverSetup } from 'common/utils/validatecheck/bookcreate';

const FrontCoverStep = ({ display, ui, dispatch }) => {
    // check validability
    const title = ui.title;
    useEffect(() => {
        if (display) {
            dispatch(
                updateUI({
                    canGoNext: checkFrontCoverSetup(title)
                })
            );
        }
        return () => {};
    }, [display, title, dispatch]);

    // handlers
    const onChangeFrontCover = useCallback(
        (e) => {
            dispatch(
                updateUI({
                    title: e.target.value
                })
            );
        },
        [dispatch]
    );

    return (
        <div
            className={`new-book-step-7 new-book-step ${
                display ? 'active-form' : ''
            }`}>
            <div className="section-title">Front Cover</div>
            <div className="new-book-step-form">
                <div className="form-group">
                    <div className="form-group__item">
                        <label htmlFor="front-cover">Title</label>
                        <input
                            type="text"
                            onChange={onChangeFrontCover}
                            value={ui.title || ''}
                            id="frontCoverTitle"
                            name="front-cover"
                            placeholder="Enter Book Title"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    ui: getUI
});
export default connect(state)(FrontCoverStep);
