import _ from 'underscore';
import {
    ADD_CONTACT,
    FETCH_CONTACTS,
    ACTION_USER_LOGOUT
} from 'redux/actions/actionTypes';

const initialState = {
    contacts: []
};

const ContactsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CONTACTS: {
            return {
                ...state,
                contacts: action.data.contacts
            };
        }

        case ADD_CONTACT: {
            let contacts = state.contacts;
            contacts = contacts.concat(action.data);
            const data = {
                ...state,
                contacts
            };
            localStorage.setItem('contacts', JSON.stringify(data));

            return data;
        }

        case ACTION_USER_LOGOUT: {
            return _.extend({}, initialState);
        }

        default: {
            return state;
        }
    }
};

export default ContactsReducer;
