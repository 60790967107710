import React, {
    useState,
    useRef,
    useCallback,
    useEffect,
    createRef,
    lazy,
    Suspense
} from 'react';
import { CircularProgressWithLabel } from 'components/circular-progress-with-label';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { connect, useDispatch, useSelector } from 'react-redux';
import { updateUI } from 'redux/actions/ui';

// Actions
import * as BookActions from 'redux/actions/book';
import * as BookOrderActions from 'redux/actions/bookorder';
// selectors
import { getUser, getBookById, getBookPendingStatus } from 'redux/selectors';
import { createStructuredSelector } from 'reselect';
import { useHistory } from 'react-router';
import { URLS } from 'common/urls';
import withBook from 'common/utils/components/withBook';
import BookSaveDialog from './BookSaveDialog';

// Icons
import iconCheckSm from 'assets/images/icons_svg/icon_check-sm.svg';
import iconAngleLeftSm from 'assets/images/icons_svg/icon_angle-left-sm.svg';
import iconAngleRightSm from 'assets/images/icons_svg/icon_angle-right-sm.svg';
import {
    BOOK_EXPORT_FORMAT,
    BOOK_ORDER_LIMIT,
    SETTING_CONNECT_URLS
} from 'common/constant';
// import { getFileFromPDF } from 'redux/actions/book';
import { checkResponseSuccess } from 'common/utils/responseUtils';

const LazyDocument = lazy(() =>
    import('components/Book/LazyDocument').then((res) => res)
);
// PDFJS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.js`;

const useStyles = makeStyles({
    root: {
        width: '6%',
        position: 'absolute',
        right: 0
    }
});

const BOOK_SIZES = {
    MIN: {
        value: 20,
        text:
            "The book can't be created, because it has less than 20 pages, please check and try again."
    },
    MAX: {
        value: 900,
        text:
            "The book can't be created, because it has over 900 pages, please check and try again."
    }
};

const ValidationErrorBlock = (props) => {
    return (
        <div
            className="col-10 offset-1 p-3"
            style={{
                border: '#f5c6cb solid 1px',
                textAlign: 'center'
            }}>
            <i
                className="fas fa-exclamation-triangle fa-3x"
                style={{
                    color: 'Tomato',
                    marginBottom: '.66rem'
                }}></i>
            <h5>{props.text}</h5>
        </div>
    );
};

const BookPreviewDialog = ({ book, user, close, open }) => {
    const [validationErrorShow, setValidationErrorShow] = useState(false);
    const [validationErrorText, setValidationErrorText] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [pdfFile, setPdfFile] = useState(null);
    const [numPages, setNumPages] = useState(0);
    const [loadingPdf, setLoadingPdf] = useState(true);
    const [loadingThumbnails, setLoadingThumbnails] = useState(true);
    const pdfThumbnailsRef = useRef([]);
    const pdfThumbnailContainerRef = useRef(null);
    const documentRef = createRef();
    const dispatch = useDispatch();
    const bookDownloadProgress = useSelector(getBookPendingStatus);
    const history = useHistory();
    const [progress, setProgress] = useState(0);
    const classes = useStyles();
    const [paginatedPageNumber, setPaginatedPageNumber] = useState(1);
    const [approveFlag, setApproveFlag] = useState(true);
    const rootRef = useRef(null);
    const onChangeProgressValue = ({ loaded, total }) => {
        setProgress(Math.round((loaded / total) * 100));
        dispatch(
            BookActions.setBookDownloadProgress(
                Math.round((loaded / total) * 100)
            )
        );
    };
    const documentIsPending = useCallback(() => {
        return (
            bookDownloadProgress !== 100 ||
            (loadingPdf && loadingThumbnails && progress !== 100)
        );
    }, [bookDownloadProgress, loadingThumbnails, progress, loadingPdf]);

    useEffect(() => {
        setPdfFile(book.preview_pdf);
        if (book.export_format !== 'PDF') {
            if (book.page_count < BOOK_SIZES.MIN.value) {
                setValidationErrorShow(true);
                setValidationErrorText(BOOK_SIZES.MIN.text);
            } else if (book.page_count > BOOK_SIZES.MAX.value) {
                setValidationErrorShow(true);
                setValidationErrorText(BOOK_SIZES.MAX.text);
            } else {
                setApproveFlag(false);
            }
        } else {
            setApproveFlag(false);
        }
    }, [book.preview_pdf, book.page_count, book.export_format]);

    useEffect(() => {
        rootRef.current.addEventListener('mousedown', (e) => {
            if (e.target.getAttribute('page')) {
                const elems =
                    rootRef.current &&
                    rootRef.current.querySelectorAll('.thumbnail-active');

                Array.from(elems).forEach((elem) => {
                    elem.classList.remove('thumbnail-active');
                });

                e.target.parentElement.classList.add('thumbnail-active');

                setPageNumber(parseInt(e.target.getAttribute('page')));
            }
        });
    });

    const changeThumbnailsPosition = (value) => {
        // get offset
        const offset =
            (pdfThumbnailsRef.current[value - 2] &&
                pdfThumbnailsRef.current[value - 2].offsetTop) ||
            0;

        pdfThumbnailContainerRef.current.scrollTo({
            top: offset,
            left: 0,
            behavior: 'smooth',
            block: 'end'
        });
    };
    const nextPage = () => {
        setPageNumber(pageNumber < numPages ? pageNumber + 1 : pageNumber);
        setPaginatedPageNumber(
            pageNumber < numPages ? pageNumber + 1 : pageNumber
        );
        changeThumbnailsPosition(pageNumber + 1);
    };

    const prevPage = () => {
        setPageNumber(pageNumber > 1 ? pageNumber - 1 : pageNumber);
        setPaginatedPageNumber(pageNumber > 1 ? pageNumber - 1 : pageNumber);
        changeThumbnailsPosition(pageNumber - 1);
    };

    useEffect(() => {
        const elems =
            rootRef.current &&
            rootRef.current.querySelectorAll(`[page='${pageNumber}']`);

        Array.from(
            rootRef.current.querySelectorAll('.thumbnail-active')
        ).forEach((elem) => {
            elem.classList.remove('thumbnail-active');
        });

        Array.from(elems).forEach((elem) => {
            elem.parentElement.classList.add('thumbnail-active');
        });
    }, [pageNumber]);

    const renderColor = useCallback(() => {
        if (book) {
            if (book.color) {
                return (
                    <li>
                        <img src={iconCheckSm} alt="" />
                        Full Color
                    </li>
                );
            } else {
                return (
                    <li>
                        <img src={iconCheckSm} alt="" />
                        Black and White
                    </li>
                );
            }
        }
    }, [book]);

    const renderPhotos = useCallback(() => {
        if (book) {
            if (book.photos) {
                return (
                    <li>
                        <img src={iconCheckSm} alt="" />
                        Photos
                    </li>
                );
            } else {
                return (
                    <li>
                        <img src={iconCheckSm} alt="" />
                        No Photos
                    </li>
                );
            }
        }
    }, [book]);

    const approve = useCallback(async () => {
        // approve book
        if (
            window.location.href.includes('localhost') &&
            !window.location.href.includes('localhost:')
        ) {
            const url_ = SETTING_CONNECT_URLS['book-payments'];
            window.open(url_);
            return;
        }

        setApproveFlag(true);
        await dispatch(
            BookActions.update(
                {
                    id: book.id,
                    approved: true
                },
                { freeze_status: true }
            )
        );

        // calculate the count for book order created this year
        const response = await dispatch(
            BookOrderActions.fetch({
                created_year: new Date().getFullYear()
            })
        );

        close();
        if (checkResponseSuccess(response?.payload?.status)) {
            const limit = user.is_pro
                ? BOOK_ORDER_LIMIT.PRO
                : user.is_ty
                ? BOOK_ORDER_LIMIT.THANKYOU
                : BOOK_ORDER_LIMIT.FREE;

            // if user has free pdf generation
            if (
                book.export_format === BOOK_EXPORT_FORMAT.PDF &&
                response?.payload?.data.meta?.total_count < limit
            ) {
                await dispatch(BookOrderActions.create({ book: book.id }));
                history.push(URLS.BOOK.ORDERS);
                return;
            }
        }
        setApproveFlag(false);
        history.push(`/book-order/${book.id}`, { display: 'block' });
    }, [book, history, dispatch, user, close]);

    const cancel = useCallback(() => {
        history.push(URLS.BOOK.ROOT);
        // close modal
        close();

        book &&
            dispatch(
                updateUI({
                    showDialog: true,
                    dialogs: [withBook(BookSaveDialog, book.id)]
                })
            );
    }, [history, dispatch, book, close]);

    const onKeydown = (e) => {
        const pageNumber = Number(e.target.value);
        if (e.key === 'Enter') {
            setPageNumber(pageNumber);
            changeThumbnailsPosition(pageNumber);
        }
    };

    // on page number changed
    const onChangePageNum = (e) => {
        const pageNumber = parseInt(e.target.value);
        if (pageNumber <= numPages) {
            setPaginatedPageNumber(pageNumber);
        }
    };

    // on blur page number
    const onBlurPageNum = (e) => {
        const pageNumber = Number(e.target.value);

        setPageNumber(pageNumber);
        changeThumbnailsPosition(pageNumber);
    };
    return (
        <div
            ref={rootRef}
            id="book-preview-modal"
            className="modal-frame modal-frame--fill-mobile"
            style={{ display: open ? 'flex' : 'none' }}>
            <div className="modal-box modal-box--800">
                <div className="modal__header modal__header--button">
                    Book Preview
                </div>
                <div
                    className="book-preview-status"
                    style={{ marginBottom: '1rem' }}>
                    {validationErrorShow && (
                        <ValidationErrorBlock text={validationErrorText} />
                    )}
                    {documentIsPending() ? (
                        <>
                            <div className="book-preview-status__title">
                                <span>
                                    Calculating page count and generating pdf
                                    preview...
                                </span>
                                <div className={classes.root}>
                                    <CircularProgressWithLabel
                                        value={bookDownloadProgress || progress}
                                    />
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
                <div className="modal__body">
                    <div className="book-preview-panel mb-3">
                        <div className="book-preview">
                            <div
                                className="book-preview__viewer"
                                ref={documentRef}>
                                <Suspense
                                    fallback={
                                        <div
                                            style={{
                                                position: 'fixed',
                                                top: '20%'
                                            }}>
                                            Document is Loading...
                                        </div>
                                    }>
                                    <LazyDocument
                                        pdfFile={pdfFile}
                                        onChangeProgressValue={
                                            onChangeProgressValue
                                        }
                                        setLoadingPdf={setLoadingPdf}
                                        setNumPages={setNumPages}
                                        pageNumber={pageNumber}
                                        book={pageNumber}
                                        pdfThumbnailsRef={pdfThumbnailsRef}
                                        rootRef={rootRef}
                                        setPageNumber={setPageNumber}
                                        setPaginatedPageNumber={
                                            setPaginatedPageNumber
                                        }
                                        setLoadingThumbnails={
                                            setLoadingThumbnails
                                        }
                                    />
                                </Suspense>
                            </div>
                            <div className="book-preview__controls">
                                <div className="panel-pagination">
                                    <label className="panel-pagination__title">
                                        Page
                                    </label>
                                    <input
                                        type="number"
                                        value={paginatedPageNumber}
                                        onKeyDown={onKeydown}
                                        onChange={onChangePageNum}
                                        onBlur={onBlurPageNum}
                                    />
                                    <label className="panel-pagination__page-total">
                                        /{numPages}
                                    </label>
                                </div>
                                <div className="panel-arrows">
                                    <button
                                        className="panel-arrows__btn"
                                        onClick={prevPage}>
                                        <img src={iconAngleLeftSm} alt="" />
                                    </button>
                                    <button
                                        className="panel-arrows__btn"
                                        onClick={nextPage}>
                                        <img src={iconAngleRightSm} alt="" />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div
                            className="book-preview-panel__thumbnails text-center"
                            id="pdf-thumbnails"
                            ref={pdfThumbnailContainerRef}
                        />
                    </div>
                    <div className="book-preview-options">
                        <div className="section-title">Included</div>
                        <ul className="book-preview-list">
                            <li>
                                <img src={iconCheckSm} alt="" />
                                {numPages} Pages
                            </li>
                            {renderColor()}
                            {renderPhotos()}
                        </ul>
                    </div>
                </div>
                <div className="modal__footer">
                    <button onClick={cancel} className="btn btn-outline">
                        Cancel
                    </button>
                    <button
                        className="btn btn-gradient"
                        disabled={approveFlag}
                        onClick={approve}>
                        Approve and Continue
                    </button>
                </div>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    user: getUser,
    book: getBookById
});
export default connect(state)(BookPreviewDialog);
