import React from 'react';

// images
import logo from 'assets/images/jrnl-logo_full-light.svg';
import { Link } from 'react-router-dom';

const NonAuthHeader = () => {
    return (
        <header className="header">
            <div className="container">
                <Link to="/" className="header__logo" title="JRNL">
                    <img src={logo} alt="JRNL.com logo" />
                </Link>
                <Link to="/login" className="header__link">
                    Login
                </Link>
            </div>
        </header>
    );
};

export default NonAuthHeader;
