import React from 'react';
import { Button } from '@material-ui/core';
import { PLAN_LABELS, PLAN_PRICES } from 'common/constant';

export const PlansInfo = ({ classes, isBasic, setDialogs }) => {
    const basicHtml = () => (
        <section className="comparison-plan">
            <div className="container">
                <div className="row justify-content-center">
                    <h3 className="comparison-plan-header">
                        Compare Basic and Pro Features
                    </h3>
                </div>
                <div className="row justify-content-end comparison-table-content">
                    <div className="col-4">
                        <h3 className="comparison-table-title">
                            {PLAN_LABELS.FREE}
                        </h3>
                        <p className="comparison-table-text">On the house</p>
                    </div>
                    <div className="col-4">
                        <h3 className="comparison-table-title">
                            {PLAN_LABELS.PRO}
                        </h3>
                        <p className="comparison-table-text">
                            ${PLAN_PRICES.PRO.DEFAULT}
                            <span>/ year</span>
                        </p>
                    </div>
                </div>
                <div className="row justify-content-center graphic-first-plan-table">
                    <div className="col-3 comparison-plan-content">
                        <h3 className="comparison-plan-title">Storage</h3>
                        <p className="comparison-plan-info">
                            Available in your account for photos, files…
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <p>2 GB</p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <p>1 TB</p>
                    </div>
                    <div className="col-3 comparison-plan-content">
                        <h3 className="comparison-plan-title"># of Journals</h3>
                        <p className="comparison-plan-info">
                            Unlimited diaries to record your thoughts
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">1</div>
                    <div className="col-4 comparison-bg">No limit</div>
                    <div className="col-3 comparison-plan-content">
                        <h3 className="comparison-plan-title">Includes AAM</h3>
                        <p className="comparison-plan-info">
                            All About Me Section
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-check" />
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-check" />
                    </div>
                    <div className="col-3 comparison-plan-content">
                        <h3 className="comparison-plan-title">
                            Multiple Photos/Entry
                        </h3>
                        <p className="comparison-plan-info">
                            Upload unlimited photos per entry
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-check" />
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-check" />
                    </div>
                    <div className="col-3 comparison-plan-content">
                        <h3 className="comparison-plan-title">
                            Unlimited Text Entries*
                        </h3>
                        <p className="comparison-plan-info">
                            Share your thoughts with other JRNL’s users
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-check" />
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-check" />
                    </div>
                    <div className="col-3 comparison-plan-content">
                        <h3 className="comparison-plan-title">
                            Shared Entries
                        </h3>
                        <p className="comparison-plan-info">
                            Share your thoughts with other JRNL’s users
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <p>
                            <span className="fa fa-close" />
                            (can only receive and comment)
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <p>
                            <span className="fa fa-check" />
                            (can share and receive)
                        </p>
                    </div>
                    <div className="col-3 comparison-plan-content">
                        <h3 className="comparison-plan-title">
                            Create Shared Journals
                        </h3>
                        <p className="comparison-plan-info">
                            Send entire journals to other JRNL’s users
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-close" />
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-check" />
                    </div>
                </div>
                <div className="row justify-content-end comparison-actions">
                    <div className="col-4">
                        <Button
                            className={classes.btn}
                            variant="outlined"
                            color="inherit"
                            fullWidth
                            onClick={() =>
                                setDialogs({
                                    free: true
                                })
                            }>
                            Try JRNL Free
                        </Button>
                    </div>
                    <div className="col-4">
                        <Button
                            className={classes.btn}
                            variant="outlined"
                            color="inherit"
                            fullWidth
                            onClick={() =>
                                setDialogs({
                                    pro: true
                                })
                            }>
                            Upgrade to {PLAN_LABELS.PRO}
                        </Button>
                    </div>
                </div>
                <div className="row justify-content-center graphic-second-plan-table">
                    <div className="col-3 comparison-plan-content">
                        <h3 className="comparison-plan-title">
                            # of Shared Journals
                        </h3>
                        <p className="comparison-plan-info">
                            Upload your own audios and videos
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-close" />
                    </div>
                    <div className="col-4 comparison-bg">10</div>
                    <div className="col-3 comparison-plan-content">
                        <h3 className="comparison-plan-title">Audio & Video</h3>
                        <p className="comparison-plan-info">
                            Upload your own audios and videos
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-close" />
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-check" />
                    </div>
                    <div className="col-3 comparison-plan-content">
                        <h3 className="comparison-plan-title">Email to JRNL</h3>
                        <p className="comparison-plan-info">
                            Get a discount on all JRNL purchases
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-close" />
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-check" />
                    </div>
                    <div className="col-3 comparison-plan-content">
                        <h3 className="comparison-plan-title">
                            Custom Book Cover**
                        </h3>
                        <p className="comparison-plan-info">
                            Download your Journals in PDF format
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-close" />
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-check" />
                    </div>
                    <div className="col-3 comparison-plan-content">
                        <h3 className="comparison-plan-title">
                            Can purchase JRNL Types
                        </h3>
                        <p className="comparison-plan-info">
                            Attach files to your entries
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-check" />
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-check" />
                    </div>
                    <div className="col-3 comparison-plan-content">
                        <h3 className="comparison-plan-title">
                            Social Connect
                        </h3>
                        <p className="comparison-plan-info">
                            Import content automatically from your social media
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-close" />
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-check" />
                    </div>
                    <div className="col-3 comparison-plan-content">
                        <h3 className="comparison-plan-title">
                            Discount JRNL Types, Books, PDF purchase
                        </h3>
                        <p className="comparison-plan-info">
                            Save your account in case something happens
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <p>0%</p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <p>20%</p>
                    </div>
                    <div className="col-3 comparison-plan-content">
                        <h3 className="comparison-plan-title">Free PDF/yr</h3>
                        <p className="comparison-plan-info">
                            Save your account in case something happens
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <p>0</p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <p>12</p>
                    </div>
                </div>
            </div>
        </section>
    );
    const proHtml = () => (
        <section className="comparison-plan">
            <div className="container">
                <div className="row justify-content-center">
                    <h3 className="comparison-plan-header">
                        {`Compare "${PLAN_LABELS.LOYALTY}" and ${PLAN_LABELS.PRO}{' '}
                        Features`}
                    </h3>
                </div>
                <div className="row justify-content-end comparison-table-content">
                    <div className="col-4">
                        <h3 className="comparison-table-title">
                            “{PLAN_LABELS.LOYALTY}”
                        </h3>
                        <p className="comparison-table-text">On the hosue</p>
                    </div>
                    <div className="col-4">
                        <h3 className="comparison-table-title">
                            {PLAN_LABELS.PRO}
                        </h3>
                        <p className="comparison-table-text">
                            ${PLAN_PRICES.PRO.DEFAULT}
                            <span>/ year</span>
                        </p>
                    </div>
                </div>
                <div className="row justify-content-center graphic-first-plan-table">
                    <div className="col-3 comparison-plan-content">
                        <h3 className="comparison-plan-title">Storage</h3>
                        <p className="comparison-plan-info">
                            Available in your account for photos, files…
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <p>1 TB</p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <p>1 TB</p>
                    </div>
                    <div className="col-3 comparison-plan-content">
                        <h3 className="comparison-plan-title"># of Journals</h3>
                        <p className="comparison-plan-info">
                            Unlimited diaries to record your thoughts
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">No Limit</div>
                    <div className="col-4 comparison-bg">No limit</div>
                    <div className="col-3 comparison-plan-content">
                        <h3 className="comparison-plan-title">Includes AAM</h3>
                        <p className="comparison-plan-info">
                            All About Me Section
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-check" />
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-check" />
                    </div>
                    <div className="col-3 comparison-plan-content">
                        <h3 className="comparison-plan-title">
                            Multiple Photos/Entry
                        </h3>
                        <p className="comparison-plan-info">
                            Upload unlimited photos per entry
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-check" />
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-check" />
                    </div>
                    <div className="col-3 comparison-plan-content">
                        <h3 className="comparison-plan-title">
                            Unlimited Text Entries*
                        </h3>
                        <p className="comparison-plan-info">
                            Share your thoughts with other JRNL’s users
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-check" />
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-check" />
                    </div>
                    <div className="col-3 comparison-plan-content">
                        <h3 className="comparison-plan-title">
                            Shared Entries
                        </h3>
                        <p className="comparison-plan-info">
                            Share your thoughts with other JRNL’s users
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <p>
                            <span className="fa fa-check" />
                            (can share and receive)
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <p>
                            <span className="fa fa-check" />
                            (can share and receive)
                        </p>
                    </div>
                    <div className="col-3 comparison-plan-content">
                        <h3 className="comparison-plan-title">
                            Create Shared Journals
                        </h3>
                        <p className="comparison-plan-info">
                            Send entire journals to other JRNL’s users
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-close" />
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-check" />
                    </div>
                </div>
                <div className="row justify-content-end comparison-actions">
                    <div className="col-4">
                        <Button
                            className={classes.btn}
                            variant="outlined"
                            color="inherit"
                            fullWidth
                            onClick={() => setDialogs({ thank_you: true })}>
                            Get It For Free
                        </Button>
                    </div>
                    <div className="col-4">
                        <Button
                            className={classes.btn}
                            variant="outlined"
                            color="inherit"
                            fullWidth
                            onClick={() => setDialogs({ pro: true })}>
                            Upgrade Now
                        </Button>
                    </div>
                </div>
                <div className="row justify-content-center graphic-second-plan-table">
                    <div className="col-3 comparison-plan-content">
                        <h3 className="comparison-plan-title">
                            # of Shared Journals
                        </h3>
                        <p className="comparison-plan-info">
                            Upload your own audios and videos
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-close" />
                    </div>
                    <div className="col-4 comparison-bg">10</div>
                    <div className="col-3 comparison-plan-content">
                        <h3 className="comparison-plan-title">Audio & Video</h3>
                        <p className="comparison-plan-info">
                            Upload your own audios and videos
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-close" />
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-check" />
                    </div>
                    <div className="col-3 comparison-plan-content">
                        <h3 className="comparison-plan-title">Email to JRNL</h3>
                        <p className="comparison-plan-info">
                            Get a discount on all JRNL purchases
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-check" />
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-check" />
                    </div>
                    <div className="col-3 comparison-plan-content">
                        <h3 className="comparison-plan-title">
                            Custom Book Cover**
                        </h3>
                        <p className="comparison-plan-info">
                            Download your Journals in PDF format
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-check" />
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-check" />
                    </div>
                    <div className="col-3 comparison-plan-content">
                        <h3 className="comparison-plan-title">
                            Can purchase JRNL Types
                        </h3>
                        <p className="comparison-plan-info">
                            Attach files to your entries
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-check" />
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-check" />
                    </div>
                    <div className="col-3 comparison-plan-content">
                        <h3 className="comparison-plan-title">
                            Social Connect
                        </h3>
                        <p className="comparison-plan-info">
                            Import content automatically from your social media
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-check" />
                    </div>
                    <div className="col-4 comparison-bg">
                        <span className="fa fa-check" />
                    </div>
                    <div className="col-3 comparison-plan-content">
                        <h3 className="comparison-plan-title">
                            Discount JRNL Types, Books, PDF purchase
                        </h3>
                        <p className="comparison-plan-info">
                            Save your account in case something happens
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <p>10%</p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <p>20%</p>
                    </div>
                    <div className="col-3 comparison-plan-content">
                        <h3 className="comparison-plan-title">Free PDF/yr</h3>
                        <p className="comparison-plan-info">
                            Save your account in case something happens
                        </p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <p>1</p>
                    </div>
                    <div className="col-4 comparison-bg">
                        <p>12</p>
                    </div>
                </div>
            </div>
        </section>
    );
    return <>{isBasic ? basicHtml() : proHtml()}</>;
};
