import React, { useCallback } from 'react';
import DefaultUser from 'assets/images/default-user.jpg';

// Icons
import iconShare from 'assets/images/icons_svg/icon_share.svg';

const InvitationListButton = ({ invitations }) => {
    const renderUserName = useCallback(() => {
        const invitation = invitations[0];
        if (!invitation) {
            return;
        }

        return invitation.user
            ? invitation.user.public_display_name
            : invitation.email;
    }, [invitations]);

    const renderBadge = useCallback(() => {
        return invitations.length - 1;
    }, [invitations]);

    const renderUserAvatars = useCallback(() => {
        return (invitations || [])
            .slice(0, 2)
            .map((item) => (
                <img
                    key={0}
                    className="image-group"
                    src={item.user ? item.user.avatar_image_url : DefaultUser}
                    alt=""
                />
            ));
    }, [invitations]);
    return (
        <>
            <img className="left btn-icon btn-icon--6" src={iconShare} alt="" />
            <span className="btn-text-shared">
                <span>{renderUserName()}</span>
            </span>
            {invitations?.length > 1 && (
                <span className="additional-shares"> +{renderBadge()}</span>
            )}
            <div className="image-avatars">{renderUserAvatars()}</div>
        </>
    );
};

export default InvitationListButton;
