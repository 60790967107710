import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import SimpleReactCalendar from 'simple-react-calendar';
import { connect } from 'react-redux';
import * as FilterActions from 'redux/actions/filter';
import * as EntryActions from 'redux/actions/entry';
import 'assets/css/calendar.css';
import { createStructuredSelector } from 'reselect';
import { getCalendar, getFilters, getUI } from 'redux/selectors';
import CalendarHeader from './CalendarHeader';
import { useLocation } from 'react-router';
import { ENTRY_QA } from 'common/constant';
import { ENTRY_PUBLISHED } from 'common/constant';
import { MonthYearTabs } from './MonthYearTabs';
import 'assets/css/new-calendar-styles.css';
import { fetch as fetchCalendar } from 'redux/actions/calendar';
import _ from 'underscore';
import { updateUI } from 'redux/actions/ui';

const Calendar = ({
    dispatch,
    calendar,
    ui,
    filters,
    onDateSelect: propsOnDateSelect,
    mode = 'range',
    date: propsDate
}) => {
    const [currentDate, setCurrentDate] = useState(propsDate || moment());
    const daysWithEntries = useMemo(() => calendar.calendar, [
        calendar.calendar
    ]);
    const { pathname } = useLocation();
    const [showTabs, setShowTabs] = useState(false);
    const onDateSelect = useCallback(
        (dateAsMoment, viewMode, start, end) => {
            let minDate, maxDate;
            switch (viewMode) {
                case 'year': {
                    setShowTabs(false);
                    minDate = moment(dateAsMoment).startOf('year');
                    maxDate = moment(dateAsMoment).endOf('year');
                    break;
                }
                case 'month': {
                    setShowTabs(false);

                    minDate = moment(dateAsMoment).startOf('month');
                    maxDate = moment(dateAsMoment).endOf('month');
                    break;
                }
                case 'day': {
                    minDate = moment(start).startOf('day');
                    maxDate = moment(end).endOf('day');
                    break;
                }
                default: {
                    break;
                }
            }
            setSelectedDays({
                start: minDate,
                end: maxDate
            });
            const calendar_min_date = moment(dateAsMoment)
                .startOf('year')
                .format();
            const calendar_max_date = moment(dateAsMoment)
                .endOf('year')
                .format();

            const data = {
                dateViewMode: viewMode,
                minDate,
                maxDate
            };

            if (pathname.includes('answers')) {
                data.status = ENTRY_PUBLISHED;
                data.entry_type = ENTRY_QA;
            }

            // if props onDateSelect exists, call it, if not, work as default
            if (propsOnDateSelect) {
                viewMode === 'day' && propsOnDateSelect(data);
            } else {
                if (calendar_min_date && calendar_max_date) {
                    data.calendar_min_date = calendar_min_date;
                    data.calendar_max_date = calendar_max_date;
                }
                dispatch(
                    updateUI({
                        activeDate: null
                    })
                );
                dispatch(FilterActions.update(data));
                dispatch(EntryActions.fetch());
                if (
                    !(
                        calendar?.calendar &&
                        moment(calendar_min_date).format('YYYY') in
                            calendar.calendar
                    )
                ) {
                    dispatch(fetchCalendar());
                }
            }
        },
        [dispatch, pathname, propsOnDateSelect, calendar]
    );

    const onNextMonth = useCallback(() => {
        const _nextMonth = moment(currentDate).add(1, 'month');
        setCurrentDate(_nextMonth);
        onDateSelect(_nextMonth, 'month');
    }, [currentDate, onDateSelect]);

    const onPrevMonth = useCallback(() => {
        const _prevMonth = moment(currentDate).subtract(1, 'month');
        setCurrentDate(_prevMonth);
        onDateSelect(_prevMonth, 'month');
    }, [currentDate, onDateSelect]);

    const onSelectDay = useCallback(
        (_day) => {
            onDateSelect(_day, 'day');
            setCurrentDate(moment(_day));
            setSelectedDays({
                start: _day,
                end: _day
            });
        },
        [onDateSelect]
    );

    const [selectedDays, setSelectedDays] = useState({
        start: '',
        end: ''
    });
    const handleSelect = (e) => {
        setSelectedDays({
            start: e.start,
            end: e.end
        });
    };
    const toggleTabs = useCallback(() => {
        setShowTabs(!showTabs);
    }, [showTabs]);
    const renderCalendarHeader = useCallback(() => {
        return (
            <div className="month">
                <CalendarHeader
                    toggleTabs={toggleTabs}
                    showTabs={showTabs}
                    currentDate={currentDate}
                    onNextMonth={onNextMonth}
                    onPrevMonth={onPrevMonth}
                    setCurrentDate={setCurrentDate}
                    onDateSelect={onDateSelect}
                />
            </div>
        );
    }, [
        currentDate,
        onDateSelect,
        onNextMonth,
        onPrevMonth,
        toggleTabs,
        showTabs
    ]);

    const handleClearCalendar = useCallback(() => {
        const { start, end } = selectedDays;
        if (!start || !end) {
            return;
        }
        dispatch(
            FilterActions.update({
                minDate: undefined,
                maxDate: undefined
            })
        );
        dispatch(EntryActions.fetch());
        setSelectedDays({
            start: '',
            end: ''
        });
    }, [dispatch, selectedDays]);
    useEffect(() => {
        const daysList = document.querySelectorAll('.calendar-week-day');
        const startMonth = moment(currentDate).startOf('month');
        if (
            daysWithEntries &&
            startMonth.year() in daysWithEntries &&
            startMonth.month() + 1 in daysWithEntries[startMonth.year()]
        ) {
            const monthEntry =
                daysWithEntries[startMonth.year()][startMonth.month() + 1];
            for (const element of daysList) {
                const dayInElement = Number(moment(element.value).format('D'));
                const isContain = monthEntry.some((item) => {
                    return (
                        currentDate.month() + 1 ===
                            moment(element.value).month() + 1 &&
                        item.day === dayInElement
                    );
                });
                if (isContain) {
                    Object.assign(element.style, {
                        borderBottomColor: '#0064ff'
                    });
                } else {
                    Object.assign(element.style, {
                        borderBottomColor: ''
                    });
                }
            }
        } else {
            daysList.forEach((element) => {
                Object.assign(element.style, {
                    borderBottomColor: ''
                });
            });
        }
    }, [daysWithEntries, currentDate]);

    const onSelect = useCallback(
        (e) => {
            // handler for select date
            onDateSelect({}, 'day', e.start || e, e.end || e);
        },
        [onDateSelect]
    );

    useEffect(() => {
        if (ui.activeDate) {
            setCurrentDate(moment(ui.activeDate));
        } else if (filters.minDate || filters.maxDate) {
            setCurrentDate(moment(filters.minDate));
        }
    }, [ui.activeDate, filters.maxDate, filters.minDate]);
    const today = useCallback(
        () =>
            ui.activeDate || filters.minDate
                ? new Date(ui.activeDate || filters.minDate)
                : new Date(),
        [ui.activeDate, filters.minDate]
    );
    useEffect(() => {
        if (_.isEmpty(calendar.calendar)) {
            dispatch(fetchCalendar());
        }
        //eslint-disable-next-line
    }, []);
    return (
        <div className="calendar mt-3">
            <div className="drawer__heading">
                <div className="drawer__title">Calendar</div>
                <button
                    className="btn btn-today clear_calendar_btn"
                    onClick={handleClearCalendar}>
                    Clear
                </button>
                <button
                    className="btn btn-today"
                    onClick={() => onSelectDay(moment())}>
                    Go to Today
                </button>
            </div>
            <div className="calendar mt-3">
                {renderCalendarHeader()}
                {showTabs ? (
                    <MonthYearTabs
                        setShowTabs={setShowTabs}
                        currentDate={currentDate}
                        onDateSelect={onDateSelect}
                        setCurrentDate={setCurrentDate}
                    />
                ) : (
                    <SimpleReactCalendar
                        activeMonth={new Date(currentDate)}
                        MonthHeaderComponent={() => <></>}
                        mode={mode}
                        selected={
                            mode === 'range'
                                ? selectedDays
                                : new Date(currentDate)
                        }
                        onSelect={onSelect}
                        onSelectionProgress={handleSelect}
                        weekStartsOn={0}
                        today={today()}
                    />
                )}
            </div>
        </div>
    );
};
const state = createStructuredSelector({
    calendar: getCalendar,
    ui: getUI,
    filters: getFilters
});
export default React.memo(connect(state)(Calendar));
