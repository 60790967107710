import React, { memo, useCallback, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { getJournalList } from 'redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { fetch as getAllJournals } from 'redux/actions/journal';
import Select from 'react-select';
import * as EntryActions from 'redux/actions/entry';
import * as EntryMediaActions from 'redux/actions/entrymedia';
import { renderSummaryText } from 'common/utils/renderSummaryText';

export const AddToEntry = memo(({ media, open, onClose }) => {
    const { journals } = useSelector(getJournalList);
    const [entries, setEntries] = useState([]);
    const [selectedEntry, setSelectedEntry] = useState();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAllJournals());
    }, [dispatch]);

    const journalOptions =
        journals &&
        journals.map((item) => {
            return {
                value: item.id,
                label: item.title
            };
        });

    const onChangeJournal = useCallback(
        (e) => {
            dispatch(
                EntryActions.fetchEntriesWithOptions({
                    by_journal: e.value
                })
            ).then((res) => {
                if (res.payload.status === 200) {
                    setEntries(res.payload.data);
                }
            });
        },
        [dispatch]
    );

    const addImageToEntry = useCallback(() => {
        // const formData = new FormData();
        // formData.append('entry', selectedEntry.id);
        // formData.append('image_url', media.image);
        dispatch(
            EntryMediaActions.create({
                id: media.id,
                entry: selectedEntry.id,
                image_url: media.image || media.file
            })
        );
        onClose && onClose();
    }, [dispatch, selectedEntry, media, onClose]);
    return (
        <Dialog open={open} style={{ zIndex: 99999 }} onClose={onClose}>
            <div
                id="add-files-various-entries-modal"
                className="add-files-various-entries-modal">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8 col-xl-7 various-entries-content">
                            <div className="various-entries-content-header">
                                <h3 className="various-entries-content-title">
                                    Add File to an Entry
                                </h3>
                                <span
                                    className="icon-times-light"
                                    onClick={() => onClose()}
                                />
                            </div>
                            <div className="add-file-to-journal-form">
                                <label htmlFor="journalAddFile">Journal</label>
                                <Select
                                    className="media-select"
                                    options={journalOptions}
                                    onChange={onChangeJournal}
                                />
                            </div>
                            <ul className="various-entries-list">
                                {(entries?.objects || []).map(
                                    (entry, index) => (
                                        <li
                                            key={index}
                                            className={`media-entry ${
                                                entry.id === selectedEntry?.id
                                                    ? 'active'
                                                    : ''
                                            }`}
                                            onClick={() =>
                                                setSelectedEntry(entry)
                                            }>
                                            <div className="media-entry-header">
                                                <img
                                                    src={
                                                        entry?.user
                                                            ?.avatar_image_url
                                                    }
                                                    alt="avatar"
                                                />
                                                <div className="media-entry-header-content">
                                                    <h3 className="media-entry-title">
                                                        <span>
                                                            {
                                                                entry?.user
                                                                    ?.short_public_display_name
                                                            }
                                                        </span>{' '}
                                                        in{' '}
                                                        {entry?.journal?.title}
                                                    </h3>
                                                    <p className="media-entry-info">
                                                        {entry.entry_date}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="media-entry-content">
                                                <h3 className="media-entry-content-title">
                                                    {entry?.title}
                                                </h3>
                                                <p className="media-entry-content-text">
                                                    {renderSummaryText(
                                                        entry?.content,
                                                        true,
                                                        entry?.id
                                                    )}
                                                </p>
                                            </div>
                                            <div className="media-entry-actions">
                                                <div className="media-entry-comments">
                                                    <span className="icon-comments" />
                                                    {entry.comments_count}
                                                </div>
                                                <button className="btn btn-sharing">
                                                    <span className="fa fa-share-alt blue-color" />
                                                </button>
                                            </div>
                                        </li>
                                    )
                                )}
                            </ul>
                            <div className="add-file-various-entries-actions">
                                <button
                                    className="btn btn-cancel"
                                    onClick={() => onClose()}>
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-add-to-entry"
                                    onClick={addImageToEntry}>
                                    <span className="fa fa-plus-circle" />
                                    Add to Entry
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
});
