import { BookSelector } from 'redux/selectors/BookSelector';
import { CommentSelector } from 'redux/selectors/CommentSelector';
import { EntryStateSelector } from 'redux/selectors/EntrySelector';
import { InstagramSelector } from 'redux/selectors/InstagramSelector';
import { JournalSelector } from 'redux/selectors/JournalSelector';
import { SearchSelector } from 'redux/selectors/SearchSelector';
import { TagSelector } from 'redux/selectors/TagSelector';
import { createSelector } from 'reselect';

export const getLoadingProgress = createSelector(
    [
        BookSelector,
        CommentSelector,
        EntryStateSelector,
        InstagramSelector,
        JournalSelector,
        SearchSelector,
        TagSelector
    ],
    (book, comment, entry, instagram, journal, search, tag) => {
        let progress = 0,
            loadingCnt = 0;

        [book, comment, entry, instagram, journal, search, tag].forEach(
            (item) => {
                if (item.loading === true) {
                    loadingCnt++;
                    if (item.isPending === false) {
                        progress++;
                    }
                }
            }
        );

        if (loadingCnt === 0) {
            return 100;
        }

        return (progress * 100) / loadingCnt;
    }
);
