import React, { useRef, useState, useCallback } from 'react';

// material ui components
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';

const DropdownMenu = (props) => {
    const anchorRef = useRef();
    const [open, setOpen] = useState(false);
    const [children, setChildren] = useState([]);

    // children update
    useDeepCompareEffectNoCheck(() => {
        if (Array.isArray(props.children)) {
            setChildren(props.children);
        } else if (props.children !== undefined) {
            setChildren([props.children]);
        }
        return () => {};
    }, [props.children]);

    const handleToggle = useCallback((e) => {
        e.stopPropagation();
        setOpen((prevOpen) => !prevOpen);
    }, []);

    const handleClose = useCallback(
        (e, action) => {
            e.stopPropagation();
            if (!props.open) {
                setOpen(false);
            }
            //if (typeof (action) === 'function') action();
        },
        [props.open]
    );

    const renderChildElement = useCallback(
        (child, index) => {
            if (child) {
                return (
                    <MenuItem
                        key={index}
                        onClick={(e) => handleClose(e, child?.props?.onClick)}
                        style={props?.approveStyles && { padding: 0 }}>
                        {child}
                    </MenuItem>
                );
            }
            return undefined;
        },
        [handleClose, props]
    );

    return (
        <div className={`material-dropdown-menu ${props.className || ''}`}>
            <div
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}>
                {props.anchor}
            </div>

            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                placement={props.placement}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: props.placement
                                ? 'none'
                                : placement === 'bottom'
                                ? 'right bottom'
                                : 'right top',
                            width: props.width || 'auto',
                            marginTop: props.marginTop || 'inherit'
                        }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="menu-list-grow">
                                    {children &&
                                        Array.isArray(children) &&
                                        children.map((child, index) =>
                                            renderChildElement(child, index)
                                        )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

export default DropdownMenu;
