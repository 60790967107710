import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { updateUI } from 'redux/actions/ui';

import 'react-phone-number-input/style.css';
import 'react-intl-tel-input/dist/main.css';

import StepVerificationEnabled from 'assets/images/step-verification-enabled.png';
import StepVerificationDisabled from 'assets/images/step-verification-disabled.png';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { getUser } from 'redux/selectors';

import * as UserActions from 'redux/actions/user';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import { checkResponseSuccess } from 'common/utils/responseUtils';

const StepVerificationDialog = ({
    phone,
    dispatch,
    setActive,
    isDeleting,
    setDelete
}) => {
    const [phoneNumber, setPhoneNumber] = useState(phone || '');
    const [validationStep, setValidationStep] = useState(isDeleting ? 3 : 0);
    const [validationCode, setValidationCode] = useState('');
    const [error, setError] = useState(null);
    const [disableContinueButton, setDisableContinueButton] = useState(false);

    const closeDialog = useCallback(() => {
        dispatch(
            updateUI({
                dialogs: []
            })
        );
    }, [dispatch]);

    const isContinueButtonDisabled = useCallback(() => {
        switch (validationStep) {
            case 0: {
                return !isValidPhoneNumber(phoneNumber);
            }
            case 1:
            case 4: {
                return validationCode.length !== 6;
            }
            case 2:
            case 5:
                return false;
            default:
                return true;
        }
    }, [phoneNumber, validationCode, validationStep]);

    const updateValidationCode = useCallback((event) => {
        setError(null);
        setValidationCode(event.target.value);
    }, []);

    const renderSteps = useCallback(() => {
        switch (validationStep) {
            case 0:
                return (
                    <div className="step-verification-number-form active-step">
                        <p className="step-verification-title">
                            Enable 2-Step Verification
                        </p>
                        <p className="step-verification-desc">
                            {phone ? 'Edit' : 'Add'} your phone number below to
                            enable <br />
                            2-Step Verification
                        </p>
                        <PhoneInput
                            international
                            className="step-verification-form form-control"
                            defaultCountry="US"
                            value={phoneNumber}
                            onChange={(val) => setPhoneNumber(val)}
                        />
                        <p className="step-verification-select-msg">
                            {"We'll send you a SMS: local rates may apply."}
                        </p>
                    </div>
                );
            case 1:
                return (
                    <div className="step-verification-enable-code">
                        <p className="step-verification-title">
                            Confirm Your Phone Number
                        </p>
                        <p className="step-verification-desc">
                            We just sent you a SMS message with a code. Please
                            introduce the code below to continue.
                        </p>
                        <input
                            type="text"
                            className="settings-secturity-code-input"
                            placeholder="1 2 3 4 5 6"
                            value={validationCode}
                            onChange={updateValidationCode}
                            maxLength={6}
                        />
                        {error && (
                            <p className="input-error-msg">
                                <i className="icon-error"></i>
                                Invalid verification code
                            </p>
                        )}
                    </div>
                );
            case 3:
            case 4: {
                return (
                    <div className="step-verification-disable-code">
                        <p className="step-verification-title">
                            Disable 2-Step Verification
                        </p>
                        <p className="step-verification-desc">
                            {
                                "We need to confirm your identity before disabling it. Please enter the code we've send you"
                            }
                        </p>
                        <input
                            type="text"
                            className="settings-secturity-code-input"
                            placeholder="1 2 3 4 5 6"
                            value={validationCode}
                            onChange={updateValidationCode}
                            maxLength={6}
                        />
                        {error && (
                            <p className="input-error-msg">
                                <i className="icon-error"></i>
                                Invalid verification code
                            </p>
                        )}
                    </div>
                );
            }
            case 5: {
                return (
                    <div className="step-verification-disabled">
                        <p className="step-verification-title">
                            2-Step Verification Disabled
                        </p>
                        <img
                            className="mb-4"
                            src={StepVerificationDisabled}
                            alt="step-verification-disabled"
                        />
                        <p className="step-verification-desc">
                            {
                                "You'll only need your password from now to log in. Make sure your password is secure and unique"
                            }
                        </p>
                    </div>
                );
            }
            default:
                return (
                    <div className="step-verification-enabled">
                        <p className="step-verification-title">
                            2-Step Verification Enabled
                        </p>
                        <img
                            className="step-verification-img-phone"
                            src={StepVerificationEnabled}
                            alt="step-verification-enabled"
                        />
                        <p className="step-verification-desc">
                            {
                                "You're all set. We will ask for a code each time you log into your account."
                            }
                        </p>
                    </div>
                );
        }
    }, [
        phoneNumber,
        validationStep,
        validationCode,
        phone,
        updateValidationCode,
        error
    ]);

    const onFinish = useCallback(() => {
        if (setActive) {
            setActive(phoneNumber);
        }
        if (setDelete) {
            setDelete();
        }

        closeDialog();

        // fetch user data again
        dispatch(UserActions.fetch());
    }, [phoneNumber, setActive, closeDialog, setDelete, dispatch]);

    const runAction = useCallback(async () => {
        switch (validationStep) {
            case 0: {
                // update user phone number
                setDisableContinueButton(true);
                let response = await dispatch(
                    UserActions.update({
                        phone: phoneNumber
                    })
                );
                if (response.payload.status !== 202) {
                    setDisableContinueButton(false);
                    return false;
                }

                // enable 2fa
                response = await dispatch(UserActions.enable2FA(true));
                setDisableContinueButton(false);
                return checkResponseSuccess(response.payload.status);
            }
            case 1: {
                // verify code
                setDisableContinueButton(true);
                const response = await dispatch(
                    UserActions.enable2FA(true, validationCode)
                );
                setDisableContinueButton(false);
                return (
                    checkResponseSuccess(response.payload.status) &&
                    !response.payload.data.error
                );
            }

            case 3: {
                // disable code api request.
                setDisableContinueButton(true);
                const response = await dispatch(UserActions.enable2FA(false));
                setDisableContinueButton(false);
                return checkResponseSuccess(response.payload.status);
            }

            case 4: {
                // verify disable
                setDisableContinueButton(true);
                const response = await dispatch(
                    UserActions.enable2FA(false, validationCode)
                );
                setDisableContinueButton(false);
                return (
                    checkResponseSuccess(response.payload.status) &&
                    !response.payload.data.error
                );
            }

            default: {
                return true;
            }
        }
    }, [phoneNumber, dispatch, validationStep, validationCode]);

    const onContinue = useCallback(async () => {
        if (validationStep === 2 || validationStep === 5) {
            onFinish();
        } else {
            if (await runAction()) {
                setValidationStep(validationStep + 1);
            } else {
                setError(true);
            }
        }
    }, [onFinish, validationStep, runAction]);

    // useEffect
    useDeepCompareEffectNoCheck(() => {
        if (validationStep === 3) {
            // make api request to disable
            onContinue();
        }
    }, [validationStep]);

    return (
        <div className="step-verification-modal" id="step-verification-modal">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 step-verification-card">
                        <span
                            id="step-verification-close"
                            className="step-verification-close icon-times-light"
                            onClick={closeDialog}
                        />
                        {renderSteps()}
                        <div className="step-verification-btn">
                            <button
                                disabled={
                                    isContinueButtonDisabled() ||
                                    disableContinueButton
                                }
                                className="btn btn-change-pass w-100"
                                id="step-verification-btn"
                                onClick={onContinue}>
                                {validationStep === 2 ? 'Finish' : 'Continue'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    user: getUser
});
export default connect(state)(StepVerificationDialog);
