import React, { useCallback, useEffect, useState, useRef } from 'react';
import _ from 'underscore';

// Actions
import { connect } from 'react-redux';

import * as JournalActions from 'redux/actions/journal';
import * as TagActions from 'redux/actions/tag';
import * as FilterActions from 'redux/actions/filter';
import * as EntryActions from 'redux/actions/entry';
import * as JournalInvitationActions from 'redux/actions/journalinvitation';
import { updateUI } from 'redux/actions/ui';

// Selectors
import { createStructuredSelector } from 'reselect';
import { getUser, getJournalList, getTagList, getUI } from 'redux/selectors';
import { useHistory, useLocation } from 'react-router-dom';

// Dialogs
import DeleteJournalDialog from 'pages/Dialogs/DeleteJournalDialog';
import EditJournalDialog from 'pages/Dialogs/EditJournalDialog';
import DeleteTagDialog from 'pages/Dialogs/DeleteTagDialog';
import EditTagDialog from 'pages/Dialogs/EditTagDialog';
import NewJournalDialog from 'pages/Dialogs/NewJournalDialog';
import NewTagDialog from 'pages/Dialogs/NewTagDialog';

// Components
import { DropdownMenu } from 'components';
import withSharedUsers from 'common/utils/components/withSharedUsers';
import ShareDialog from 'pages/Dialogs/InvitationDialog/ShareDialog';

// Icons
import iconAdd from 'assets/images/icons_svg/icon_add-sm.svg';
import iconJournal from 'assets/images/icons_svg/icon_journal.svg';
import iconTag from 'assets/images/icons_svg/icon_tag.svg';
import iconLock from 'assets/images/icons_svg/icon_lock.svg';
import iconShare from 'assets/images/icons_svg/icon_share.svg';
import iconHide from 'assets/images/icons_svg/icon_hide.svg';
import iconEllipsisVert from 'assets/images/icons_svg/icon_ellipsis-vert.svg';
import withFreeAccountCheck from 'common/utils/components/withFreeAccountCheck';
import { JOURNAL_AAM, JOURNAL_STATE } from 'common/constant';
import { checkUserSame } from 'common/utils/resourceUtils';
const withJournal = (Component, journal) => (props) => {
    return <Component journal={journal} {...props} />;
};

const withTag = (Component, tag) => (props) => {
    return <Component tag={tag} {...props} />;
};

const JournalsAndTags = ({ user, dispatch, ui, ...props }) => {
    const [activeTab, setActiveTab] = useState(0);
    const selectedJournal = useRef(null);
    const { journals } = props.journals;
    // history
    const history = useHistory();
    const location = useLocation();
    useEffect(() => {
        dispatch(JournalActions.fetch());
        dispatch(TagActions.fetch({ order_by: 'name' }));

        switch (location.pathname) {
            case '/journals/': {
                setActiveTab(0);
                break;
            }
            case '/tags/': {
                setActiveTab(1);
                break;
            }
            default: {
                break;
            }
        }
        return () => {};
    }, [dispatch, location.pathname]);

    // change page according to the url
    useEffect(() => {
        switch (location.pathname) {
            case '/journals/': {
                setActiveTab(0);
                break;
            }
            case '/tags/': {
                setActiveTab(1);
                break;
            }
            default: {
                break;
            }
        }
        return () => {};
    }, [location.pathname]);

    const onTabChange = useCallback(
        (tab) => {
            switch (tab) {
                case 0: {
                    history.push('/journals/');
                    break;
                }
                case 1: {
                    history.push('/tags/');
                    break;
                }
                default: {
                    break;
                }
            }
        },
        [history]
    );

    const onNewJournal = useCallback(() => {
        // Show New Entry Dialog
        dispatch(
            updateUI({
                dialogs: [
                    ...(ui.dialogs || []),
                    withFreeAccountCheck(NewJournalDialog, true, false)
                ]
            })
        );
    }, [dispatch, ui]);

    const onEditJournal = useCallback(
        (journal) => {
            // Show Edit Journal Dialog
            dispatch(
                updateUI({
                    dialogs: [withJournal(EditJournalDialog, journal)]
                })
            );
        },
        [dispatch]
    );

    const onDeleteJournal = useCallback(
        (journal) => {
            // Show Delete Journal Dialog
            dispatch(
                updateUI({
                    dialogs: [withJournal(DeleteJournalDialog, journal)]
                })
            );
        },
        [dispatch]
    );

    const onDeleteSharedJournal = useCallback(
        async (journal) => {
            // find invitation for user
            const invitation = (journal.invitations || []).find((invitation) =>
                checkUserSame(invitation.user, user)
            );

            // if invitation exists, then delete it.
            if (invitation) {
                await dispatch(
                    JournalInvitationActions.remove(invitation, journal.id)
                );
            }
        },
        [dispatch, user]
    );

    const onHideJournal = useCallback(
        (journal) => {
            dispatch(JournalActions.patch({ state: 100 }, journal.id));
        },
        [dispatch]
    );

    const onShowJournal = useCallback(
        (journal) => {
            dispatch(JournalActions.patch({ state: 101 }, journal.id));
        },
        [dispatch]
    );

    const onJournalFilter = useCallback(
        (journal) => {
            dispatch(FilterActions.reset());
            dispatch(
                FilterActions.update({
                    filterByJournal: [journal],
                    minDate: undefined,
                    maxDate: undefined
                })
            );
            dispatch(EntryActions.setLoadingProgress());
            dispatch(EntryActions.fetch());
            history.push(`/journals/${journal.id}`);
        },
        [dispatch, history]
    );

    const onTagFilter = useCallback(
        (tag) => {
            dispatch(FilterActions.reset());
            dispatch(
                FilterActions.update({
                    filterByTag: [tag],
                    minDate: undefined,
                    maxDate: undefined
                })
            );
            dispatch(EntryActions.setLoadingProgress());
            dispatch(EntryActions.fetch());
            history.push('/');
        },
        [dispatch, history]
    );

    const onNewTag = useCallback(() => {
        // New Tag
        dispatch(
            updateUI({
                dialogs: [NewTagDialog]
            })
        );
    }, [dispatch]);

    const onEditTag = useCallback(
        (tag) => {
            // Edit Tag
            dispatch(
                updateUI({
                    dialogs: [withTag(EditTagDialog, tag)]
                })
            );
        },
        [dispatch]
    );

    const onDeleteTag = useCallback(
        (tag) => {
            // Show Delete Tag Dialog
            dispatch(
                updateUI({
                    dialogs: [withTag(DeleteTagDialog, tag)]
                })
            );
        },
        [dispatch]
    );
    const renderTab = useCallback(() => {
        const { journals } = props.journals;
        const { tags } = props.tags;
        return (
            <ul
                className={'nav nav-tabs justify-content-around'}
                id="myTab"
                role="tablist">
                <li
                    className={`nav-item journal-post-nav w-50 ${
                        activeTab === 0 ? 'active' : ''
                    }`}
                    id="journals-tab-fix">
                    <div
                        className="nav-link journal-tabs active"
                        onClick={() => onTabChange(0)}
                        id="journals-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="journals"
                        aria-selected="true">
                        <img
                            src={iconJournal}
                            className="btn-icon btn-icon--6 mr-2"
                            alt="Journals"
                        />
                        Journals
                        <span className="journal-badge">
                            {journals?.length}
                        </span>
                    </div>
                </li>
                <li
                    className={`nav-item journal-post-nav w-50 ${
                        activeTab === 1 ? 'active' : ''
                    }`}
                    id="tags-tab-fix">
                    <div
                        className="nav-link journal-tabs"
                        onClick={() => onTabChange(1)}
                        id="tags-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="tags"
                        aria-selected="false">
                        <img
                            src={iconTag}
                            className="btn-icon btn-icon--6 mr-2"
                            alt="Tags"
                        />
                        Tags
                        <span className="journal-badge">{tags?.length}</span>
                    </div>
                </li>
            </ul>
        );
    }, [onTabChange, props.journals, props.tags, activeTab]);

    const onShareJournalUserUpdate = useCallback(
        async (sharedUsers, added_users, removed_users, customMessage) => {
            if (selectedJournal.current?.id) {
                // create invitations
                await dispatch(
                    JournalInvitationActions.inviteUsers(
                        added_users,
                        selectedJournal.current?.id,
                        customMessage
                    )
                );
                // remove invitations
                await dispatch(
                    JournalInvitationActions.removeUsers(
                        removed_users,
                        selectedJournal.current.invitations,
                        selectedJournal.current.id
                    )
                );
            }
        },
        [dispatch, selectedJournal]
    );

    const shareJournal = useCallback(
        (share, journal, e) => {
            e.stopPropagation();
            selectedJournal.current = journal;
            if (share === true) {
                dispatch(
                    updateUI({
                        publicEntry: true,
                        dialogs: [
                            withSharedUsers(
                                ShareDialog,
                                journal.invitations || [],
                                onShareJournalUserUpdate,
                                true
                            )
                        ]
                    })
                );
            } else {
                dispatch(
                    updateUI({
                        publicEntry: false,
                        dialogs: [
                            withSharedUsers(
                                ShareDialog,
                                journal.invitations || [],
                                onShareJournalUserUpdate,
                                false
                            )
                        ]
                    })
                );
                onShareJournalUserUpdate([], [], journal.invitations);
            }
        },
        [dispatch, onShareJournalUserUpdate]
    );

    const renderJournalListItem = useCallback(
        (journal, shared) => {
            // render journal list item

            const entry_count = journal.entry_count || 0;

            // identifies if user is owner
            const isOwner = journal.user.id === user.id;
            const isAAM = journal.journal_type === JOURNAL_AAM && isOwner;

            // identifies if the journal can be deleted
            const canDeleteJournal =
                (isOwner && entry_count === 0 && journal.is_default !== true) ||
                !isOwner;

            const shareDropdownAnchor =
                !isOwner || (journal.invitations || []).length > 0 ? (
                    <button
                        onClick={(e) => shareJournal(true, journal, e)}
                        className="btn-icon btn-icon--4">
                        <img src={iconShare} alt="Shared" />
                    </button>
                ) : (
                    <button
                        onClick={(e) => shareJournal(false, journal, e)}
                        className="btn-icon btn-icon--4">
                        <img src={iconLock} alt="Private" />
                    </button>
                );

            return (
                <div
                    key={journal.id}
                    className={`list__item ${
                        journal.is_default ? 'active' : ''
                    }`}>
                    <div className="list__img list__img--icon-md">
                        <img
                            src={iconJournal}
                            className="btn-icon btn-icon--6"
                            alt=""
                        />
                    </div>
                    <div className="list__content">
                        <div
                            className="list__title list__title--link truncate"
                            onClick={() => onJournalFilter(journal)}>
                            <span>{journal.title}</span>
                        </div>
                        <div className="list__subtitle">
                            {entry_count} Entries
                            {journal.missionjrnl_id &&
                            journal.missionjrnl_id !== '' ? (
                                <span className="default-journal-tag">
                                    MissionJRNL
                                </span>
                            ) : (
                                isOwner &&
                                journal.is_default && (
                                    <span className="default-journal-tag">
                                        {journal.missionjrnl_id
                                            ? 'MissionJRNL'
                                            : 'Default Journal'}
                                    </span>
                                )
                            )}
                        </div>
                        {shared && (
                            <div className="list__subtitle shared-journal-tag">
                                <img
                                    src={journal.user.avatar_image_url}
                                    alt="avatar"
                                />
                                <span>{journal.user.public_display_name}</span>
                            </div>
                        )}
                    </div>
                    <div className="list__options">
                        {journal.state === 100 && (
                            <img
                                src={iconHide}
                                className="icon-static btn-icon--6"
                                alt=""
                            />
                        )}
                        {shareDropdownAnchor}
                        <DropdownMenu
                            approveStyles={true}
                            anchor={
                                <img
                                    src={iconEllipsisVert}
                                    className="dropdown-trigger btn-icon btn-icon--4"
                                    alt=""
                                />
                            }
                            placement="bottom">
                            {isOwner && !(user.is_free && !journal.is_default) && (
                                <span
                                    key={'edit'}
                                    onClick={() => {
                                        onEditJournal(journal);
                                    }}>
                                    Edit Journal
                                </span>
                            )}
                            {isOwner &&
                                journal.state ===
                                    JOURNAL_STATE.PUBLIC_SHOWN && (
                                    <span
                                        key={'hide'}
                                        onClick={() => onHideJournal(journal)}>
                                        Hide Journal
                                    </span>
                                )}
                            {isOwner && journal.state === JOURNAL_STATE.HIDDEN && (
                                <span
                                    key={'show'}
                                    onClick={() => onShowJournal(journal)}>
                                    <span> Show Journal </span>
                                </span>
                            )}
                            {isOwner && !journal.is_default && !isAAM && (
                                <span
                                    key={'delete'}
                                    onClick={() => {
                                        onDeleteJournal(journal);
                                    }}>
                                    Delete Journal
                                </span>
                            )}
                            {!isOwner && canDeleteJournal && (
                                <span
                                    key={'delete'}
                                    onClick={() => {
                                        onDeleteSharedJournal(journal);
                                    }}>
                                    Delete Journal
                                </span>
                            )}
                        </DropdownMenu>
                    </div>
                </div>
            );
        },
        [
            onEditJournal,
            onDeleteJournal,
            onJournalFilter,
            onHideJournal,
            onShowJournal,
            user,
            shareJournal,
            onDeleteSharedJournal
        ]
    );

    const renderTagListItem = useCallback(
        (tag) => {
            // render tag list item
            const entry_count = tag.entry_count || 0;
            return (
                <div key={tag.id} className="list__item">
                    <div className="list__img list__img--icon--md">
                        <img
                            src={iconTag}
                            className="btn-icon btn-icon--6"
                            alt="Tags"
                        />
                    </div>
                    <div className="list__content">
                        <div
                            className="list__title list__title--link truncate"
                            onClick={() => onTagFilter(tag)}>
                            <span>{tag.name}</span>
                        </div>
                        <div className="list__subtitle">
                            {entry_count} Entries &nbsp;
                        </div>
                    </div>
                    <div className="list__options">
                        <DropdownMenu
                            anchor={
                                <img
                                    src={iconEllipsisVert}
                                    className="dropdown-trigger btn-icon btn-icon--4"
                                    alt=""
                                />
                            }>
                            <span
                                key={'edit'}
                                onClick={() => {
                                    onEditTag(tag);
                                }}>
                                Edit Tag
                            </span>
                            {entry_count === 0 && (
                                <span
                                    key={'delete'}
                                    onClick={() => onDeleteTag(tag)}>
                                    Delete Tag
                                </span>
                            )}
                        </DropdownMenu>
                    </div>
                </div>
            );
        },
        [onEditTag, onDeleteTag, onTagFilter]
    );

    const renderJournalTabPanel = useCallback(() => {
        // render journal tab panel
        const defaultJournal = _.find(
            journals,
            (journal) =>
                journal.is_default === true && checkUserSame(journal.user, user)
        );
        const sortedByAlphabetically = journals?.sort((a, b) => {
            return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
        });
        const aamJournals = journals?.filter((journal) => {
            return (
                journal.journal_type === JOURNAL_AAM &&
                checkUserSame(journal.user, user)
            );
        });
        const myJournals = sortedByAlphabetically?.filter((journal) => {
            return (
                journal.id !== defaultJournal?.id &&
                journal.journal_type !== JOURNAL_AAM &&
                checkUserSame(journal.user, user)
            );
        });
        const sharedJournals = sortedByAlphabetically?.filter((journal) => {
            return (
                Array.isArray(journal.invitations) &&
                journal.invitations.length > 0 &&
                !checkUserSame(journal.user, user)
            );
        });

        return (
            <div
                className={`tab-pane fade journal-tab-pane ${
                    activeTab === 0 ? 'active show' : ''
                }`}
                id="journals"
                role="tabpanel"
                aria-labelledby="journals-tab">
                <div className="tab-pane__actions">
                    <button
                        onClick={onNewJournal}
                        className="btn btn-journal btn-gradient">
                        New Journal
                        <img
                            className="right"
                            src={iconAdd}
                            alt="New Journal"
                        />
                    </button>
                </div>
                <div className="list list--page">
                    {defaultJournal && renderJournalListItem(defaultJournal)}
                    {aamJournals &&
                        aamJournals.map((journal) => {
                            return renderJournalListItem(journal);
                        })}
                    {myJournals &&
                        myJournals.map((journal) =>
                            renderJournalListItem(
                                journal,
                                renderJournalListItem
                            )
                        )}
                    <h3 className="journals-section-title">Shared With Me</h3>
                    {sharedJournals &&
                        sharedJournals.map((journal) =>
                            renderJournalListItem(
                                journal,
                                renderJournalListItem
                            )
                        )}
                </div>
            </div>
        );
    }, [renderJournalListItem, activeTab, onNewJournal, journals, user]);

    const renderTagTabPanel = useCallback(() => {
        // render tag tab panel
        const { tags } = props.tags;

        return (
            <div
                className={`tab-pane fade tags-tab-pane ${
                    activeTab === 1 ? 'active show' : ''
                }`}
                id="tags"
                role="tabpanel"
                aria-labelledby="tags-tab">
                <div className="tab-pane__actions">
                    <button
                        onClick={onNewTag}
                        className="btn btn-tags btn-gradient">
                        New Tag
                        <img className="right" src={iconAdd} alt="Add Tag" />
                    </button>
                </div>
                <div className="list list--page">
                    {tags && tags.map((tag) => renderTagListItem(tag))}
                </div>
            </div>
        );
    }, [props.tags, renderTagListItem, activeTab, onNewTag]);

    const isBottom = (el) => {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    };

    const onJournalScrolling = useCallback(() => {
        const wrappedElement = document.getElementById('journals');
        if (isBottom(wrappedElement)) {
            if (
                props.journals.meta?.next &&
                props.journals.isPending === false
            ) {
                dispatch(JournalActions.fetch({}, props.journals.meta?.next));
            }
        }
    }, [dispatch, props]);

    // component did mount
    useEffect(() => {
        window.addEventListener('scroll', onJournalScrolling);
        return () => {
            window.removeEventListener('scroll', onJournalScrolling);
        };
    }, [onJournalScrolling]);

    return (
        <section className="journals-section centered-items">
            <div className={`page-container ${ui.viewWidth}`}>
                <div className="page">
                    {renderTab()}
                    <div className="tab-content" id="myTabContent">
                        {renderJournalTabPanel()}
                        {renderTagTabPanel()}
                    </div>
                </div>
            </div>
        </section>
    );
};

const state = createStructuredSelector({
    user: getUser,
    journals: getJournalList,
    tags: getTagList,
    ui: getUI
});

export default connect(state)(JournalsAndTags);
