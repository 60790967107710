import React, { useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { URLS } from 'common/urls';
import timeline_bg from 'assets/images/timeline-images/timeline-bg.png';
// import logo from 'assets/images/logo.png'
import { Modal } from '@material-ui/core';
import verificationImg from 'assets/images/verification.png';
import { PASSWORD_STATE, SIGNUP_STATE } from 'common/constant';

export const ThankYouPage = () => {
    const linkStyle = {
        width: '10rem',
        background: 'var(--primary-color)',
        borderRadius: '50px',
        height: '2rem',
        textAlign: 'center',
        paddingTop: '10px',
        color: 'white'
    };
    const { state } = useLocation();
    const text =
        state === SIGNUP_STATE
            ? 'Please check your email to verify your account.'
            : 'Please see your email for instructions on completing the reset of your password';
    const title = state === SIGNUP_STATE ? 'Thanks for signing up' : '';
    const cond = state === SIGNUP_STATE || state === PASSWORD_STATE;
    const renderSection = useCallback(() => {
        return (
            <Modal open={cond}>
                <section className="verification">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-10 col-lg-8 col-xl-7 verification-banner">
                                <div className="verification-banner-img">
                                    <img
                                        src={verificationImg}
                                        alt="verification"
                                        className="img-fluid"
                                    />
                                </div>
                                <div className="verification-banner-content">
                                    <h2>{title}!</h2>
                                    <p style={{ lineHeight: '25px' }}>{text}</p>
                                    {state === PASSWORD_STATE && (
                                        <div
                                            className="forgot-pass-links"
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between'
                                            }}>
                                            <Link
                                                to={URLS.LOGIN}
                                                className="reset-link"
                                                style={linkStyle}>
                                                LOGIN TO MY ACCOUNT
                                            </Link>
                                            <Link
                                                to={URLS.SIGNUP}
                                                className="reset-link"
                                                style={linkStyle}>
                                                SIGN UP HERE
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Modal>
        );
        // eslint-disable-next-line
    }, [state, text]);

    return (
        <React.Fragment>
            <div className="bg">
                <img className="img-bg" src={timeline_bg} alt="background" />
            </div>
            {/* <header className="navbar-app">
                <div className="container-fluid">
                    <div className="d-flex justify-content-start align-items-xl-center flex-column flex-xl-row">
                        <div className="logo-img-app" style={{width: '11.4375rem'}}>
                            <Link to="#">
                                <img src={logo} alt="logo" className="img-fluid"/>
                            </Link>
                        </div>
                        <div className="d-none d-xl-block ml-2 height-fix-nav-40">
                            <ul className="list-unstyled list-inline mb-0"/>
                        </div>
                    </div>
                </div>
            </header> */}
            {cond && renderSection()}
        </React.Fragment>
    );
};
