import { PLAN_LABELS } from 'common/constant';
import { closeDialog, openDialog } from 'common/utils/dialog-utils';
import UpgradeToProDialog from 'pages/Dialogs/UpgradeToProDialog';
import React, { useCallback } from 'react';
// Icons
import iconCloseSm from 'assets/images/icons_svg/icon_close-sm.svg';

const UpgradeFailDialog = () => {
    const onRetry = useCallback(() => {
        openDialog(UpgradeToProDialog);
    }, []);
    return (
        <div id="upgrade-pro-error-modal" className="modal-frame">
            <div className="modal-box modal-box--600">
                <div className="modal__header">
                    <button
                        className="modal__close btn-icon btn-icon--3"
                        onClick={closeDialog}>
                        <img src={iconCloseSm} alt="" />
                    </button>
                </div>
                <div className="modal__body pb-3">
                    <div className="upgrade-pro-error-card">
                        <h3 className="modal-title text-center">
                            Something went wrong...
                        </h3>
                        <p className="upgrade-pro-error-text">
                            We were unable to upgrade your account to{' '}
                            {PLAN_LABELS.PRO}. Please check your payment method
                            and try again.
                        </p>
                        <button onClick={onRetry} className="btn btn-gradient">
                            Retry
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpgradeFailDialog;
