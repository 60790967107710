import React, { useEffect } from 'react';
import BookAndBookOrders from 'pages/Book/BookAndBookOrders';

import { PrivateRoute } from 'components';
import BookPreview from 'pages/Book/BookPreview';

import BookOrder from 'pages/Book/BookOrder';

const JournalTags = () => {
    useEffect(() => {
        document.title = 'JRNL | Books';
    }, []);
    return (
        <>
            <main id="main" style={{ marginLeft: 0 }} className="app-main">
                <PrivateRoute
                    path="/books/"
                    component={BookAndBookOrders}></PrivateRoute>
                <PrivateRoute
                    path="/book-orders/"
                    component={BookAndBookOrders}></PrivateRoute>
                <PrivateRoute
                    path="/book-preview/:bookId"
                    component={BookPreview}></PrivateRoute>
                <PrivateRoute
                    path="/book-order/:bookId"
                    component={BookOrder}></PrivateRoute>
            </main>
        </>
    );
};

export default JournalTags;
