import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import emotions from 'common/emotions';
import {
    FONT_SIZE_TYPES,
    USER_SETTINGS,
    FONT_SIZE_USE_TYPE,
    HEADER_COLORS
} from 'common/constant';
import {
    getFontSize as getFontSizeSelector,
    getUserHeaderColor,
    getUI,
    getUserEmotions,
    getUser
} from 'redux/selectors';

// Actions
import * as UserActions from 'redux/actions/user';
import getFontSize from 'common/utils/getFontSize';
import ChangeBackgroundDialog from 'pages/Dialogs/SettingsDialog/ChangeBackgroundDialog';

import timelineBg from 'assets/images/timeline-images/timeline-bg.png';
import { openDialog } from 'common/utils/dialog-utils';
import { URLS } from 'common/urls';
import { useLocation } from 'react-router-dom';

import { DropDown } from 'components';

export const FONTSIZE_INPUT_VALUES = {
    DEFAULT: 17,
    SMALL: 14,
    LARGE: 20
};

const ThemePane = ({
    dispatch,
    userFontSize,
    userHeaderColor,
    ui,
    user,
    emotionsList
}) => {
    const [fontSize, setFontSize] = useState(FONTSIZE_INPUT_VALUES.DEFAULT);
    const [headerColor, setHeaderColor] = useState(HEADER_COLORS.WHITE.name);
    const location = useLocation();
    const onChangeFont = useCallback(
        (e) => {
            setFontSize(e.target.value);
            dispatch(
                UserActions.update(
                    {
                        font_size: Object.keys(FONTSIZE_INPUT_VALUES).find(
                            (obj) =>
                                parseInt(FONTSIZE_INPUT_VALUES[obj]) ===
                                parseInt(e.target.value)
                        )
                    },
                    USER_SETTINGS
                )
            );
        },
        [dispatch]
    );
    const onChangeHeaderColor = useCallback(
        (item) => {
            setHeaderColor(item.name);
            dispatch(
                UserActions.update({ header_color: item.name }, USER_SETTINGS)
            );
        },
        [dispatch]
    );

    useEffect(() => {
        setFontSize(
            FONTSIZE_INPUT_VALUES[userFontSize || FONT_SIZE_TYPES.DEFAULT]
        );
    }, [userFontSize]);

    useEffect(() => {
        setHeaderColor(
            HEADER_COLORS[userHeaderColor]?.name || HEADER_COLORS.WHITE.name
        );
    }, [userHeaderColor]);

    const openBackgroundDialog = useCallback(() => {
        openDialog(ChangeBackgroundDialog);
    }, []);

    const showBackgroundImage = useCallback(() => {
        return (
            user.wallpaper_webp_image_url ||
            user.wallpaper_image_url ||
            timelineBg
        );
    }, [user]);

    const removeBackground = useCallback(() => {
        dispatch(UserActions.update({ wallpaper_image: null }, USER_SETTINGS));
    }, [dispatch]);

    const renderEmotionDropdown = useCallback(
        (index) => {
            const changeEmotion = (emotion, isDisabled) => {
                if (!isDisabled) {
                    const emoticons = emotionsList;
                    emoticons.splice(index, 1, emotion.id);
                    dispatch(
                        UserActions.update(
                            { emoticons: JSON.stringify(emoticons) },
                            USER_SETTINGS
                        )
                    );
                }
            };

            return emotions.map((emotion) => {
                const isDisabled = emotionsList.includes(emotion.id);
                return (
                    <li
                        key={emotion.id}
                        className={isDisabled ? 'disabled' : ''}
                        onClick={() => changeEmotion(emotion, isDisabled)}>
                        <img src={emotion.img} alt="hearts" />
                    </li>
                );
            });
        },
        [dispatch, emotionsList]
    );

    const renderEmotionList = () => {
        return (
            <ul className="emoticons-list">
                {emotionsList?.map((emotionId, index) => {
                    const emotionImage = emotions.find(
                        (item) => item.id === emotionId
                    );
                    return (
                        <DropDown
                            key={emotionImage.id}
                            className="dropdown-toggle"
                            id="emoticonsDropdown"
                            dropDownMenuProps={{
                                className: 'dropdown-emoticon-menu'
                            }}
                            classes={{
                                dropdown_list: 'dropdown-emoticon-list'
                            }}
                            anchor={
                                <li className="emoticons-list-item">
                                    <img src={emotionImage?.img} alt="hearts" />
                                    <span className="fa fa-caret-down" />
                                </li>
                            }>
                            {renderEmotionDropdown(index)}
                        </DropDown>
                    );
                })}
            </ul>
        );
    };
    const activeClassName =
        location.pathname === URLS.SETTINGS.THEMES ? 'active show' : '';

    return (
        <div
            className={`tab-pane fade themes-settings ${activeClassName}`}
            id="themes"
            role="tabpanel"
            aria-labelledby="themes-tab">
            <div className="background-themes-section">
                <h3 className="settings-title">Background</h3>
                <div className="background-img-showcase">
                    <img src={showBackgroundImage()} alt="background" />
                </div>
                <div className="background-themes-actions">
                    <button
                        className="btn btn-change-wallpaper"
                        onClick={openBackgroundDialog}>
                        Change Wallpaper
                    </button>
                    <button
                        className="btn btn-remove-wallpaper"
                        onClick={removeBackground}>
                        Remove Wallpaper
                    </button>
                </div>
            </div>
            <div className="header-color-themes-section">
                <h3 className="settings-title">Header Color</h3>
                <ul className="header-colors-list">
                    {Object.keys(HEADER_COLORS).map((item) => {
                        const obj = HEADER_COLORS[item];
                        return (
                            <li
                                onClick={() => onChangeHeaderColor(obj)}
                                key={obj.name}
                                className={`${obj.className} ${
                                    headerColor === obj.name
                                        ? 'chosen-color'
                                        : ''
                                }`}></li>
                        );
                    })}
                </ul>
            </div>
            <div className="font-size-themes-section">
                <h3 className="settings-title">Font Size</h3>
                <div className="font-slide-container">
                    <ul className="font-size-list-indicators">
                        <li className="light-font">Aa</li>
                        <li className="medium-font">Aa</li>
                        <li className="strong-font">Aa</li>
                    </ul>
                    <input
                        type="range"
                        min={FONTSIZE_INPUT_VALUES.SMALL}
                        max={FONTSIZE_INPUT_VALUES.LARGE}
                        value={fontSize}
                        onChange={onChangeFont}
                        className="font-slider"
                        id="fontRange"
                        step={
                            FONTSIZE_INPUT_VALUES.LARGE -
                            FONTSIZE_INPUT_VALUES.DEFAULT
                        }
                    />
                </div>
                <div className="font-size-content-test">
                    <h4
                        className="font-size-title-test"
                        style={{
                            fontSize: getFontSize(
                                FONT_SIZE_USE_TYPE.ENTRY_TITLE,
                                userFontSize
                            )
                        }}>
                        This is an example of an entry title
                    </h4>
                    <p className="font-size-paragraph-test">
                        And this is how your entries’ text will look. Make sure
                        you’re confortable with this text size!
                    </p>
                </div>
            </div>
            <div className="emoticons-themes-section">
                <h3 className="settings-title">Customize Emoticons</h3>
                {renderEmotionList()}
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    userFontSize: getFontSizeSelector,
    userHeaderColor: getUserHeaderColor,
    emotionsList: getUserEmotions,
    ui: getUI,
    user: getUser
});
export default connect(state)(ThemePane);
