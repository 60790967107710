import {
    INCOMING_EMAIL_ADDRESS_URL,
    JOURNAL_EMAIL_SENDER_API_URL
} from 'common/constant';
import buildAuthorization from 'common/utils/buildAuthorization';
import buildV2Authorization from 'common/utils/buildV2Authorization';
import {
    CREATE_JOURNAL_EMAIL,
    CREATE_JOURNAL_EMAIL_SENDER,
    DELETE_JOURNAL_EMAIL_SENDER,
    EDIT_JOURNAL_EMAIL_SENDER,
    FETCH_JOURNAL_EMAILS,
    DELETE_JOURNAL_EMAIL,
    FETCH_AUTHORISED_EMAILS
} from 'redux/actions/actionTypes';
import { UserSelector } from 'redux/selectors/UserSelector';

export const fetch_journal_emails = (pageUrl) => (dispatch, getState) => {
    const url = pageUrl || INCOMING_EMAIL_ADDRESS_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    dispatch({
        type: FETCH_JOURNAL_EMAILS,
        payload: {
            request: {
                url,
                method: 'GET',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};
export const fetch_authorised_emails = () => (dispatch, getState) => {
    const url = `${JOURNAL_EMAIL_SENDER_API_URL}`;
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);
    return dispatch({
        type: FETCH_AUTHORISED_EMAILS,
        payload: {
            request: {
                url,
                method: 'GET',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};
export const create_journal_email = (data) => (dispatch, getState) => {
    const url = INCOMING_EMAIL_ADDRESS_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);
    return dispatch({
        type: CREATE_JOURNAL_EMAIL,
        payload: {
            request: {
                url: `${url}`,
                method: 'POST',
                data,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};
export const create_sender_email = (data) => (dispatch, getState) => {
    const url = JOURNAL_EMAIL_SENDER_API_URL;
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);
    return dispatch({
        type: CREATE_JOURNAL_EMAIL_SENDER,
        payload: {
            request: {
                url,
                method: 'POST',
                data,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};
export const edit_sender_email = (id, data) => (dispatch, getState) => {
    const url = `${JOURNAL_EMAIL_SENDER_API_URL}${id}/`;
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);
    return dispatch({
        type: EDIT_JOURNAL_EMAIL_SENDER,
        payload: {
            request: {
                url,
                method: 'PATCH',
                data,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};
export const delete_journal_email = (id) => (dispatch, getState) => {
    const url = INCOMING_EMAIL_ADDRESS_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);
    return dispatch({
        type: DELETE_JOURNAL_EMAIL,
        payload: {
            request: {
                url: `${url}${id}/`,
                method: 'DELETE',
                headers: {
                    Authorization: auth
                }
            }
        },
        id
    });
};
export const delete_sender_email = (id) => (dispatch, getState) => {
    const url = `${JOURNAL_EMAIL_SENDER_API_URL}${id}/`;
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);
    return dispatch({
        type: DELETE_JOURNAL_EMAIL_SENDER,
        payload: {
            request: {
                url,
                method: 'DELETE',
                headers: {
                    Authorization: auth
                }
            }
        },
        id
    });
};
