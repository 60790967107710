import { createSelector } from 'reselect';

export const ShareContactListSelector = (state) => state.share_contact_list;

const sortByUser = (a, b) => {
    if (a?.user && !b?.user) {
        return -1;
    }
    if (b?.user && !a?.user) {
        return 1;
    }
    if (!a?.user && !b?.user) {
        return (a?.email || '').localeCompare(b?.email || '');
    }
    return (a?.user?.username || '').localeCompare(b?.user?.username || '');
};

export const getShareContactList = createSelector(
    [ShareContactListSelector],
    (state) => {
        return state.shareContactLists.sort(sortByUser);
    }
);
export const getSearchedContactList = createSelector(
    [ShareContactListSelector],
    (state) => {
        return state.searchedContactList.sort(sortByUser);
    }
);
export const getSelectedContact = createSelector(
    [ShareContactListSelector],
    (state) => {
        return state.selectedContact;
    }
);
