const en = {
    JRNL_101: 'JRNL 101',
    Blog: 'Blog',
    HTML: 'HTML',
    CSS: 'CSS',
    JavaScript: 'JavaScript',
    Support: 'Support',
    Error_Username_Form: 'Sorry that username is taken, try another',
    Error_Password_Form: 'Enter at least 8 characters',
    Remember_me: 'Remember me',
    Remember_me_careful_box:
        "Be careful:don't use this option if you're on a shared or public device ",
    LOGIN: 'LOGIN',
    Forgot_password: 'Forgot password?',
    LoginForm_Error_Msg: 'Username or password is incorrect',
    Email: 'Email',
    EmailForm_Error_Msg: 'please enter a valid email',
    New_Password: 'New Password',
    New_PasswordForm_Error_Msg: 'Enter at least 8 characters',
    CREATE_MY_FREE_ACCOUNT: 'CREATE MY FREE ACCOUNT',
    Username: 'Username',
    Verify_its_you: "Verify it's you",
    Enter_your_2_step_verification_code:
        'Enter your 2-Step Verification code below',
    Invalid_Verification_Code_Message:
        'Invalid Code: please check and try again ',
    Verify: 'Verify',
    I_dont_have_a_code: 'I don’t have a code',
    Capture_Meaningful_Moments: 'Capture Meaningful Moments',
    We_make_it_easy_to_record_the_memories_you_never_want_to_forget:
        'We make it easy to record the memories you never want to forget.',
    HOW_IT_WORKS: 'HOW IT WORKS',
    SIGNUP: 'SIGNUP',
    ABOUT: 'ABOUT',
    Publishing: 'Publishing',
    SOCIAL: 'SOCIAL',
    Facebook: 'Facebook',
    Instagram: 'Instagram',
    Twitter: 'Twitter',
    SUPPORT: 'SUPPORT',
    Knowledgebase: 'Knowledge Base',
    Helpdesk: 'Helpdesk',
    Create_a_ticket: 'Email Support',
    PRIVACY_POLICY: 'PRIVACY POLICY',
    TERMS_OF_SERVICE: 'TERMS OF SERVICE',
    JRNL_Inc_All_Rights_Reserved: '©2020 Keizu, LLC All Rights Reserved.'
};

export default en;
