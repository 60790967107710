import React, { useCallback, useState, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { useLocation } from 'react-router';
import { URLS } from 'common/urls';

import { connect } from 'react-redux';
import ChangePasswordDialog from 'pages/Dialogs/SettingsDialog/ChangePasswordDialog';
import StepVerificationDialog from 'pages/Dialogs/SettingsDialog/StepVerificationDialog';
import { openDialog } from 'common/utils/dialog-utils';
import PhoneInput from 'react-phone-number-input';
import { getUser } from 'redux/selectors';

const SecurityTabPane = ({ dispatch, user }) => {
    const [activeStepVerification, setActiveStepVerification] = useState(
        user.twofa_is_enabled
    );
    const [phone, setPhone] = useState(null);
    const location = useLocation();
    const activeClassName =
        location.pathname === URLS.SETTINGS.SECURITY ? 'active show' : '';

    useEffect(() => {
        const phoneNumber = user.phone;
        if (phoneNumber && user.twofa_is_enabled) {
            setActiveStepVerification(true);
            setPhone(phoneNumber);
        }

        setActiveStepVerification(user.twofa_is_enabled);
    }, [user.phone, user.twofa_is_enabled]);

    const openChangePassword = useCallback(() => {
        openDialog(ChangePasswordDialog);
    }, []);

    const openStepVerification = useCallback(() => {
        openDialog(StepVerificationDialog, {
            isDeleting: user.twofa_is_enabled,
            setActive: (phoneData) => {
                setActiveStepVerification(true);
                setPhone(phoneData);
            }
        });
    }, [user.twofa_is_enabled]);

    const openEditStepVerification = useCallback(() => {
        openDialog(StepVerificationDialog, {
            phone,
            setActive: (phoneData) => {
                setActiveStepVerification(true);
                setPhone(phoneData);
            }
        });
    }, [phone]);

    const openDeleteStepVerification = useCallback(() => {
        openDialog(StepVerificationDialog, {
            isDeleting: true,
            setDelete: () => {
                setActiveStepVerification(false);
                setPhone(null);
            }
        });
    }, []);

    return (
        <div
            className={`tab-pane security-settings fade ${activeClassName}`}
            id="security"
            role="tabpanel"
            aria-labelledby="security-tab">
            <div className="settings-password-settings">
                <p className="settings-title">Password Settings</p>
                <button
                    className="btn btn-change-pass"
                    onClick={openChangePassword}>
                    Change Password
                </button>
            </div>
            <div className="settings-step-verification">
                <p className="settings-title">2-Step Verification</p>
                <p>
                    Protect your account with 2-Step Verification: we will send
                    you a code to your phone every time you log in. This will
                    prevent unauthorized access to your account if someone gets
                    your password.
                </p>
                <div className="list">
                    <div className="list__item">
                        <div className="list__content">
                            <div className="list__title">
                                Enable 2-Step Verification
                            </div>
                        </div>
                        <div className="toggle">
                            <input
                                type="checkbox"
                                id="enable2StepVerification"
                                checked={activeStepVerification}
                                onClick={openStepVerification}
                            />
                            <label
                                className="settings-toggle-desc"
                                htmlFor="enable2StepVerification"></label>
                        </div>
                    </div>
                </div>
                {activeStepVerification && (
                    <div className="settings-step-verified">
                        <p className="settings-toggle-desc">Your Device</p>
                        <div className="settings-step-phone">
                            <div className="icon-mobile"></div>
                            <div className="settings-step-phone-content">
                                <PhoneInput
                                    international
                                    className="settings-phone-number"
                                    defaultCountry="US"
                                    showCountrySelect={false}
                                    disabled
                                    value={phone}
                                />
                                <p className="settings-verification-date">
                                    Added: Feb 21, 2020
                                </p>
                            </div>
                            <span
                                className="icon-pencil"
                                onClick={openEditStepVerification}></span>
                            <span
                                className="icon-trash"
                                onClick={openDeleteStepVerification}></span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    user: getUser
});
export default connect(state)(SecurityTabPane);
