import React from 'react';

export const HeaderCard = ({ isBasic }) => {
    return (
        <React.Fragment>
            {isBasic ? (
                <section className="thank-you-section">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-12 place-welcome-card">
                                <h3 className="place-welcome-card-title">
                                    A Place For Yourself
                                </h3>
                                <p className="place-welcome-card-text">
                                    Write your thoughts, save your photos and
                                    <br />
                                    videos, share your emotions
                                </p>
                                <p className="place-welcome-card-text">
                                    <span>JRNL</span> is a safe place to start
                                    your Journey
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                <section className="thank-you-section">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-12 thank-you-card">
                                <h3 className="thank-you-card-title">
                                    Thank You!
                                </h3>
                                <p className="thank-you-card-text">
                                    We want to thank you for your support and
                                    the trust you have placed in us with this
                                    offer.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </React.Fragment>
    );
};
