import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { renderSummaryText } from 'common/utils/renderSummaryText';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CircularProgress } from '@material-ui/core';
import { useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as EntryActions from 'redux/actions/entry';
import { createStructuredSelector } from 'reselect';
import { getMediaEntries } from '../../redux/selectors/EntrySelector';

const EntriesWithThisImage = ({ open, onClose, entryList, media }) => {
    const dispatch = useDispatch();
    const renderEntry = useCallback((entry, index) => {
        return (
            <li className="media-entry" key={index}>
                <div className="media-entry-header">
                    <img src={entry.user.avatar_image_url} alt="avatar" />
                    <div className="media-entry-header-content">
                        <h3 className="media-entry-title">
                            <span>{entry.user.username}</span> in{' '}
                            {entry.journal.title}
                        </h3>
                        <span className="media-entry-info">
                            {entry.entry_date}
                        </span>
                    </div>
                </div>
                <div className="media-entry-content">
                    <h3 className="media-entry-content-title">{entry.title}</h3>
                    <span className="media-entry-content-text">
                        {renderSummaryText(entry?.content, true, entry?.id)}
                    </span>
                </div>
                <div className="media-entry-actions">
                    <div className="media-entry-comments">
                        <span className="icon-comments" />
                        {entry.comments_count}
                    </div>
                    <button className="btn btn-sharing">
                        <span className="fa fa-share-alt blue-color" />
                    </button>
                </div>
            </li>
        );
    }, []);

    const fetchMoreData = useCallback(() => {
        dispatch(
            EntryActions.fetchMediaEntry(media, entryList?.mediaMeta.next)
        );
    }, [entryList, dispatch, media]);

    return (
        <Dialog open={open} onClose={onClose} style={{ zIndex: 99999 }}>
            <div id="various-entries-modal" className="various-entries-modal">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8 col-xl-7 various-entries-content">
                            <div className="various-entries-content-header">
                                <h3 className="various-entries-content-title">
                                    Entries With This Image{' '}
                                    {`(${
                                        entryList?.mediaMeta?.total_count || 0
                                    })`}
                                </h3>
                                <span
                                    className="import-file-content-close icon-times-light"
                                    onClick={onClose}
                                />
                            </div>
                            <ul
                                className="various-entries-list"
                                id="scrollableDiv">
                                <InfiniteScroll
                                    scrollableTarget="scrollableDiv"
                                    style={{ flexDirection: 'row' }}
                                    className="media-gallery-list"
                                    dataLength={
                                        entryList?.mediaEntries?.length || 20
                                    }
                                    next={fetchMoreData}
                                    hasMore={entryList?.mediaMeta?.next}
                                    loader={<CircularProgress />}>
                                    {(
                                        entryList?.mediaEntries || []
                                    ).map((item, index) =>
                                        renderEntry(item, index)
                                    )}
                                </InfiniteScroll>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

const state = createStructuredSelector({
    entryList: getMediaEntries
});

export default connect(state)(EntriesWithThisImage);
