import React from 'react';
import verificationImage from 'assets/images/verification.png';
import { closeDialog } from 'common/utils/dialog-utils';
import { PLAN_LABELS } from 'common/constant';
// Icons
import iconCloseSm from 'assets/images/icons_svg/icon_close-sm.svg';

const UpgradeSuccessDialog = () => {
    return (
        <div id="upgrade-pro-welcome-modal" className="modal-frame">
            <div className="modal-box modal-box--600">
                <div className="modal__header">
                    <button
                        className="modal__close btn-icon btn-icon--3"
                        onClick={closeDialog}>
                        <img src={iconCloseSm} alt="" />
                    </button>
                </div>
                <div className="modal__body pb-3">
                    <div className="upgrade-pro-welcome-card">
                        <img src={verificationImage} alt="verification" />
                        <h3 className="modal-title text-center">
                            Welcome to {PLAN_LABELS.PRO}!
                        </h3>
                        <p className="upgrade-pro-welcome-text">
                            You can now enjoy Pro features like sharing, PDF
                            downloads and up to 1 TB of cloud storage. Start
                            journaling now!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpgradeSuccessDialog;
