import arrayUtils from 'common/utils/arrayUtils';
import { getLocalTime } from 'common/utils/dateUtils';
import { createSelector } from 'reselect';

export const ReminderSelector = (state) => state.reminders;

export const getSettingsRemindersList = createSelector(
    [ReminderSelector],
    (state) => {
        const newState = arrayUtils.deepClone(state);

        const reminders = newState?.reminders?.results;
        (reminders || []).forEach((item) => {
            const localTime = getLocalTime(
                `${item.day_of_the_week} ${item.time}`
            );
            item.day_of_the_week = localTime.format('dddd').toLowerCase();
            item.time = localTime.format('HH:mm:ss');
        });

        return newState;
    }
);
