import _ from 'underscore';

import {
    createSelector
} from 'reselect';
import {
    JournalSelector
} from 'redux/selectors/JournalSelector';
import {
    TagSelector
} from 'redux/selectors/TagSelector';

export const FiltersSelector = (state) => state.filters;

export const getFilters = createSelector(
    [FiltersSelector],
    (filters) => _.extend({}, filters)
);

export const getFilterByJournal = createSelector(
    [FiltersSelector, JournalSelector],
    (filters, journal) => {
        let filterJournal;
        if (filters.filterByJournal)
            filterJournal = _.findWhere(journal.journals, { resource_uri: filters.filterByJournal });
        return filterJournal;
    }
);

export const getFilterByTag = createSelector(
    [FiltersSelector, TagSelector],
    (filters, tag) => {
        let filterTag;
        if (filters.filterByTag)
            filterTag = _.findWhere(tag.tags, { resource_uri: filters.filterByTag });
        return filterTag
    }
);