import React, { Fragment, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// Actions
import * as InstagramActions from 'redux/actions/instagram';
// Constants
import {
    INSTAGRAM_O_AUTHORIZATION_URL,
    SOCIAL_TYPE_INSTAGRAM
} from 'common/constant';
// Selectors
import { getInstagramAccessToken, getUser } from 'redux/selectors';
// Icons
import iconCloseSm from 'assets/images/icons_svg/icon_close-sm.svg';
import iconInstagram from 'assets/images/icons_svg/icon_social-instagram.svg';
import { socialConnect } from 'common/utils/social-connect-utils';

const InstagramConnect = ({
    instagram_access_token,
    dispatch,
    user,
    close
}) => {
    useEffect(() => {
        dispatch(InstagramActions.fetchInstagramAccessToken());
    }, [dispatch]);

    const connectAccount = useCallback(() => {
        const titleURL = `${INSTAGRAM_O_AUTHORIZATION_URL}&state={"provider": "${SOCIAL_TYPE_INSTAGRAM}", "userid": ${user.id}}`;
        // In popup window, will call this function to connect
        window.location.connectSocialConnect = socialConnect;
        window.open(titleURL);

        close();
    }, [user, close]);

    return (
        <Fragment>
            <div className="modal-box modal__box--dropzone modal-box--800">
                <div className="modal__header">
                    Add Photos from Instagram
                    <img
                        className="modal__close"
                        onClick={close}
                        src={iconCloseSm}
                        alt=""
                    />
                </div>
                <div className="modal__body">
                    <div className="social-media-connection">
                        <div className="social-icon social-icon--lg instagram-bg--gradient">
                            <img src={iconInstagram} alt="Instagram" />
                        </div>
                        <p>
                            You haven&#39;t connected your Instagram account
                            yet. Connect your account to upload your Instagram
                            photos to&nbsp;JRNL
                        </p>
                        <button
                            onClick={connectAccount}
                            className="btn btn-gradient"
                            id="social-media-connect">
                            Connect Account
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

const state = createStructuredSelector({
    user: getUser,
    instagram_access_token: getInstagramAccessToken
});

export default connect(state)(InstagramConnect);
