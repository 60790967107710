import React, { useCallback, useMemo, useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import * as EntryActions from 'redux/actions/entry';
import { updateUI } from 'redux/actions/ui';
import {
    getJournalList,
    getUI,
    getUser,
    getFilteredEntries
} from 'redux/selectors';
import { JOURNAL_STATE, JOURNAL_AAM } from 'common/constant';

// Icons
import iconJournal from 'assets/images/icons_svg/icon_journal.svg';
import iconLock from 'assets/images/icons_svg/icon_lock.svg';
import iconShare from 'assets/images/icons_svg/icon_share.svg';
import iconHide from 'assets/images/icons_svg/icon_hide.svg';

const MoveEntryDialog = ({
    close,
    entry,
    user,
    entries,
    journals,
    dispatch,
    ui,
    open
}) => {
    const [createEntry, setCreateEntry] = React.useState(false);
    const [selectedJournals, setSelectedJournals] = useState(
        entry ? entry.extra_journals : []
    );
    const defaultJournal = useMemo(
        () => (journals.journals || []).find((obj) => obj.is_default === true),
        [journals.journals]
    );
    const normalJournals = useMemo(
        () =>
            (journals.journals || []).filter(
                (obj) => obj.id !== defaultJournal?.id
            ),
        [defaultJournal, journals.journals]
    );

    useEffect(() => {
        setTimeout(() => {
            selectedJournals &&
                selectedJournals.forEach((journal) => {
                    const element = document.getElementById(
                        `journal-item${journal.id}`
                    );
                    element && (element.className = 'active');
                });
        }, 200);
    });

    const _close = useCallback(() => {
        if (ui.bulkMode) {
            dispatch(
                updateUI({
                    bulkMode: false,
                    bulkEntries: []
                })
            );
        }
        if (close) {
            close();
        }
    }, [ui.bulkMode, close, dispatch]);

    const onFinish = useCallback(() => {
        const updateEntry = (entry) => {
            const journal =
                selectedJournals.find((journal) => journal.id === entry.id) ||
                selectedJournals[0];
            const newEntry = Object.assign({}, entry, {
                extra_journals: createEntry
                    ? selectedJournals.map((item) => item.id)
                    : selectedJournals,
                journal: createEntry ? journal.id : journal
            });

            if (createEntry) {
                dispatch(EntryActions.create(newEntry)).then((response) => {
                    if (response.payload?.status === 201) {
                        dispatch(
                            EntryActions.fetchIndividualEntry(
                                response.payload.data.id
                            )
                        );
                    }
                });
            } else {
                dispatch(EntryActions.update(newEntry, entry.id));
            }
        };
        if (ui.bulkMode) {
            ui.bulkEntries.map((bulkEntry) => {
                return updateEntry(bulkEntry);
            });
        } else {
            updateEntry(entry);
        }
        _close();
    }, [
        entry,
        selectedJournals,
        _close,
        dispatch,
        ui.bulkMode,
        ui.bulkEntries,
        createEntry
    ]);

    const onSelected = useCallback(
        (journal) => {
            const _selected = selectedJournals;
            const el = document.getElementById(`journal-item${journal.id}`);
            const index = _selected.findIndex(
                (item) => item.id === journal.id,
                10
            );

            if (index > -1) {
                _selected.splice(index, 1);
                el && (el.className = '');
            } else {
                _selected.push(journal);
                el && (el.className = 'active');
            }

            setSelectedJournals(_selected);
        },
        [selectedJournals]
    );

    const renderJournalListItem = (journal) => {
        // render journal list item
        const entry_count = journal.entry_count || 0;
        const shareDropdownAnchor =
            (journal.invitations || []).length > 0 ? (
                <div
                    role="button"
                    id="sharingModal"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true">
                    <img
                        src={iconShare}
                        alt=""
                        className="icon-static btn-icon--6"
                    />
                </div>
            ) : (
                <div
                    role="button"
                    id="sharingModal"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true">
                    <img
                        src={iconLock}
                        alt=""
                        className="icon-static btn-icon--6"
                    />
                </div>
            );

        return (
            <div key={journal.id} id={`journal-item${journal.id}`}>
                <div className="list__item" onClick={() => onSelected(journal)}>
                    <div className="list__img list__img--icon-md">
                        <img
                            src={iconJournal}
                            alt=""
                            className="icon-static btn-icon--6"
                        />
                    </div>
                    <div className="list__content">
                        <div className="list__title">
                            {journal.title}
                            {journal.is_default && (
                                <span className="default-journal-tag">
                                    Default Journal
                                </span>
                            )}
                            {journal.user.id !== user.id && (
                                <span className="shared-journal-tag">
                                    <img
                                        src={journal.user.avatar_image_url}
                                        alt="avatar"
                                    />
                                    {journal.user.public_display_name}
                                </span>
                            )}
                        </div>
                        <div className="list__subtitle">
                            {entry_count} Entries
                        </div>
                    </div>
                    <div className="list__options">
                        {journal.state === JOURNAL_STATE.HIDDEN && (
                            <img
                                src={iconHide}
                                alt=""
                                className="icon-static btn-icon--6"
                            />
                        )}
                        {shareDropdownAnchor}
                    </div>
                </div>
            </div>
        );
    };
    return (
        <Dialog
            fullWidth={true}
            open={open}
            onClose={_close}
            aria-labelledby="form-dialog-title">
            <div id="move-entries-modal" className="modal-frame">
                <div className="modal-box modal-box--600">
                    <div className="modal__header">
                        Move <span className="entriesNo"></span> Entry
                    </div>
                    <div className="modal__body scrollable">
                        <div className="list">
                            {defaultJournal &&
                                renderJournalListItem(defaultJournal)}
                            {normalJournals &&
                                normalJournals.map((journal) => {
                                    if (journal.journal_type !== JOURNAL_AAM) {
                                        return renderJournalListItem(journal);
                                    }
                                    return null;
                                })}
                        </div>
                    </div>
                    <div className="move-entries-choice">
                        <div className="checkbox-item">
                            <input
                                className="checkbox"
                                type="checkbox"
                                id="copyEntries"
                                name="copyEntries"
                                checked={createEntry}
                                onClick={() => setCreateEntry((prev) => !prev)}
                            />
                            <label htmlFor="copyEntries">
                                Keep a copy of the entries on its origin
                            </label>
                        </div>
                    </div>
                    <div className="modal__footer">
                        <button
                            className="btn btn-move-cancel btn-outline"
                            onClick={_close}>
                            Cancel
                        </button>
                        <button
                            className="btn btn-move btn-gradient"
                            onClick={onFinish}>
                            Move
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
const state = createStructuredSelector({
    user: getUser,
    journals: getJournalList,
    entries: getFilteredEntries,
    ui: getUI
});

export default connect(state)(MoveEntryDialog);
