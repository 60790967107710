import React, { useCallback } from 'react';

// Icons
import iconSearch from 'assets/images/icons_svg/icon_search.svg';
import iconCloseSm from 'assets/images/icons_svg/icon_close-sm.svg';

const MediaSearchInput = ({ onChange, onEnterKeyDown, query }) => {
    const focusInput = useCallback((input) => {
        if (input) {
            input.focus();
        }
    }, []);

    const onChangeQuery = useCallback(
        (e) => {
            if (onChange) {
                onChange(e.target.value);
            }
        },
        [onChange]
    );
    const onRemoveQuery = useCallback(() => {
        if (onChange) {
            onChange('');
        }
    }, [onChange]);
    const onKeyDown = useCallback(
        (e) => {
            if (e.key === 'Enter') {
                if (onEnterKeyDown) {
                    onEnterKeyDown();
                }
            }
        },
        [onEnterKeyDown]
    );

    return (
        <section className="media-navbar-section">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div
                        className="search-bar col-12 media-search-navbar"
                        id="expandable-search-bar">
                        <button className="search-bar__btn btn-icon btn-icon--4">
                            <img src={iconSearch} alt="" />
                        </button>
                        <input
                            onKeyDown={onKeyDown}
                            ref={focusInput}
                            type="text"
                            value={query || ''}
                            onChange={onChangeQuery}
                            className="search-bar__input"
                            placeholder="Search People, Location, Entry..."
                        />
                        {query?.length > 0 && (
                            <button
                                className="search-bar__close btn-icon btn-icon--sm btn-icon--3"
                                onClick={onRemoveQuery}>
                                <img src={iconCloseSm} alt="" />
                            </button>
                        )}
                    </div>
                    {/* <div className="media-search-filter">
                        <Select
                            className="media-select"
                            options={journalOptions}
                            placeholder="Search by"
                            onChange={onChangeJournal}
                            styles={customStyles}
                        />
                    </div> */}
                </div>
            </div>
        </section>
    );
};

export default MediaSearchInput;
