import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import * as PaymentActions from 'redux/actions/paymentprofile';
import { closeDialog } from 'common/utils/dialog-utils';
import ExpirationDateInput from 'components/CreditCardForm/ExpirationDateInput';

const PaymentCardDialog = ({ card, dispatch }) => {
    const [userFullName, setUserFullName] = useState(
        card?.user_full_name || ''
    );
    const [creditCardNumber, setCreditCardNumber] = useState(
        card?.safe_credit_card_number || ''
    );
    const [expireDateTxt, setExpireDateTxt] = useState('');
    const [expireDateTxtError, setExpireDateTxtError] = useState(false);
    const [cvv, setCvv] = useState('');
    const [defaultCard, setDefaultCard] = useState(card?.default || false);

    const createPayment = useCallback(() => {
        dispatch(
            PaymentActions.create({
                card_number: creditCardNumber,
                cvv,
                expiration_month: expireDateTxt
                    .split('/')[0]
                    .replace(/^0+/, ''),
                expiration_year: `20${expireDateTxt.split('/')[1]}`,
                user_full_name: userFullName,
                default: defaultCard
            })
        );
        closeDialog();
    }, [
        creditCardNumber,
        cvv,
        dispatch,
        userFullName,
        defaultCard,
        expireDateTxt
    ]);

    const editPayment = useCallback(() => {
        dispatch(
            PaymentActions.edit(card?.id, {
                default: defaultCard
            })
        );
        closeDialog();
    }, [dispatch, card, defaultCard]);

    return (
        <div id="add-card-modal" className="modal-frame">
            <div className="modal-box modal-box--400">
                <div className="modal__header">
                    {card ? 'Edit Card' : 'Add a Card'}
                </div>
                <div className="modal__body">
                    <div className="form-group">
                        <div className="form-group__item">
                            <label htmlFor="holderName">Holder Name</label>
                            <input
                                type="text"
                                id="holderName"
                                placeholder="Your name"
                                disabled={card}
                                value={userFullName}
                                onChange={(e) =>
                                    setUserFullName(e.target.value)
                                }
                            />
                        </div>
                        <div className="form-group__item">
                            <label
                                className="settings-label"
                                htmlFor="creditCardNumber">
                                Credit Card Number
                            </label>
                            <input
                                type="text"
                                className="settings-input"
                                id="creditCardNumber"
                                disabled={card}
                                placeholder="1234 4567 8901 34"
                                value={creditCardNumber}
                                onChange={(e) =>
                                    setCreditCardNumber(e.target.value)
                                }
                            />
                        </div>
                    </div>
                    <div className="form-group mb-0">
                        <div className="form-group__row form-group__row--exp-cvv">
                            <ExpirationDateInput
                                expireDateTxt={expireDateTxt}
                                setExpireDateTxt={setExpireDateTxt}
                                expireDateTxtError={expireDateTxtError}
                                setExpireDateTxtError={setExpireDateTxtError}
                            />
                            <div className="form-group__item">
                                <label className="settings-label" htmlFor="CVV">
                                    CVV
                                </label>
                                <div className="form-group__cvv">
                                    <input
                                        type="text"
                                        disabled={card}
                                        className="settings-input"
                                        id="CVV"
                                        placeholder=""
                                        value={cvv}
                                        onChange={(e) => setCvv(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="checkbox-item">
                            <input
                                type="checkbox"
                                id="defaultCard"
                                className="checkbox"
                                name="defaultCard"
                                checked={defaultCard}
                                onChange={(e) =>
                                    setDefaultCard(e.target.checked)
                                }
                            />
                            <label htmlFor="defaultCard">
                                Make it my default card
                            </label>
                        </div>
                    </div>
                </div>
                <div className="modal__footer">
                    <button className="btn btn-outline" onClick={closeDialog}>
                        Cancel
                    </button>
                    <button
                        disabled={
                            creditCardNumber === '' ||
                            cvv === '' ||
                            userFullName === ''
                        }
                        className="btn btn-gradient"
                        onClick={!card ? createPayment : editPayment}>
                        {card ? 'Edit' : 'Add'} Card
                    </button>
                </div>
            </div>
        </div>
    );
};

export default connect()(PaymentCardDialog);
