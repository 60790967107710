import {
    SET_ACTIVE_TAB,
    SET_SETTINGS_ACTIVE_TAB_BY_PATH,
    SET_SETTINGS_SIDEBAR_MOBILE_STATUS,
    SET_SELECTED_PLAN_DURATION
} from 'redux/actions/actionTypes';

export const setSettingsActiveTab = (payload) => {
    return {
        type: SET_ACTIVE_TAB,
        payload
    };
};

export const setSettingsPageByPath = (payload) => {
    return {
        type: SET_SETTINGS_ACTIVE_TAB_BY_PATH,
        payload
    };
};

export const setSettgsSidebarMobileStatus = (payload) => {
    return {
        type: SET_SETTINGS_SIDEBAR_MOBILE_STATUS,
        payload
    };
};

export const setSelectedPlanDuration = (payload) => {
    return {
        type: SET_SELECTED_PLAN_DURATION,
        payload
    };
};
