import _ from 'underscore';
import buildAuthorization from 'common/utils/buildAuthorization';

import { UserSelector } from 'redux/selectors/UserSelector';
import {
    FETCH_TAG_LIST,
    CREATE_TAG,
    UPDATE_TAG,
    REMOVE_TAG,
    SET_TAG_LOADINGPROGRESS
} from 'redux/actions/actionTypes';
import { TagSelector } from 'redux/selectors/TagSelector';
import { TAG_URL } from 'common/constant';

export const fetch = (options = {}) => (dispatch, getState) => {
    const tag = TagSelector(getState());
    if (tag.isPending === true) {
        return;
    }

    const url = TAG_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    options = _.defaults(options, {
        order_by: '-name',
        limit: 1000
    });

    return dispatch({
        type: FETCH_TAG_LIST,
        payload: {
            request: {
                url,
                method: 'GET',
                params: options,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const create = (data, tempTag) => (dispatch, getState) => {
    const url = TAG_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);
    let options = {};
    if (tempTag) {
        options = {
            tempTag
        };
    }

    return dispatch({
        type: CREATE_TAG,
        payload: {
            request: {
                url,
                method: 'POST',
                data,
                options,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const update = (data) => (dispatch, getState) => {
    const url = `${TAG_URL}${data.id}/`;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: UPDATE_TAG,
        payload: {
            request: {
                url,
                method: 'PATCH',
                data,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const remove = (data) => (dispatch, getState) => {
    const url = `${TAG_URL}${data.id}/`;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: REMOVE_TAG,
        payload: {
            request: {
                url,
                method: 'DELETE',
                options: {
                    id: data.id
                },
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const setLoadingProgress = () => (dispatch, getState) => {
    return dispatch({
        type: SET_TAG_LOADINGPROGRESS
    });
};
