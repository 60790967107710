import _ from 'underscore';

import { createSelector } from 'reselect';

export const EditorStateSelector = (state) => state.editor;

export const getEditorState = createSelector([EditorStateSelector], (editor) =>
    _.extend({}, editor)
);

export const getEditorNewData = createSelector(
    [EditorStateSelector],
    (editor) => Object.assign({}, editor.newData)
);

export const getFilesListWhenUpload = createSelector(
    EditorStateSelector,
    (editor_state) => {
        return editor_state.files;
    }
);
export const getImageUploadProgress = createSelector(
    getFilesListWhenUpload,
    (files_list) => {
        const files =
            Object.values(files_list).length > 0
                ? Object.values(files_list)
                : [0];
        return Math.ceil(files.reduce((a, b) => a + b, 0) / files.length);
    }
);
