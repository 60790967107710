import _ from 'underscore';
import settings from 'common/configs/setting';
import buildAuthorization from 'common/utils/buildAuthorization';

import { UserSelector } from 'redux/selectors/UserSelector';
import { CalendarSelector } from 'redux/selectors/CalendarSelector';
import { FiltersSelector } from 'redux/selectors/FiltersSelector';

import buildCalendarOptionsFromFilters from 'common/utils/buildCalendarOptionsFromFilters';
import getResourceType from 'common/utils/getResourceType';
import {
    FETCH_CALENDAR,
    SET_CALENDAR_LOADINGPROGRESS,
    UPDATE_CALENDAR
} from 'redux/actions/actionTypes';

export const fetch = (options = {}) => (dispatch, getState) => {
    const calendar = CalendarSelector(getState());
    if (calendar.isPending === true) {
        return;
    }

    const user = UserSelector(getState());
    const filters = FiltersSelector(getState());
    const auth = buildAuthorization(user);
    const url = `${settings.api}/${getResourceType(
        filters
    )}/calendar/?status=published`;

    options = _.extend(options, buildCalendarOptionsFromFilters(filters));
    return dispatch({
        type: FETCH_CALENDAR,
        payload: {
            request: {
                url,
                method: 'GET',
                params: options,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const setLoadingProgress = () => (dispatch, getState) => {
    return dispatch({
        type: SET_CALENDAR_LOADINGPROGRESS
    });
};

export const updateCalendar = (payload) => {
    return {
        type: UPDATE_CALENDAR,
        payload
    };
};
