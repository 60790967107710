import React, { useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// core components
import CheckBox from 'components/CheckBox/CheckBox';

// image
import plusIcon from 'assets/images/icons_svg/icon_plus.svg';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import { NO_STATE } from 'common/constant';

import { updateUI } from 'redux/actions/ui';
import DeleteShippingAddressDialog from 'pages/Dialogs/DeleteShippingAddressDialog';
import withBookAddress from 'common/utils/components/withBookAddress';

// redux selector
import { getShippingAddresses } from 'redux/selectors/BookShippingAddressSelector';

const AddressItem = ({
    first_name,
    last_name,
    id,
    street,
    state,
    country,
    city,
    checked,
    onChange,
    onClickModal,
    address,
    ...props
}) => {
    return (
        <CheckBox
            onChange={onChange}
            onClickModal={onClickModal}
            checked={checked}
            address={address}
            label={
                <div>
                    <div>
                        {first_name} {last_name}
                    </div>
                    {state === NO_STATE ? (
                        <div>
                            {street} {city} {country}
                        </div>
                    ) : (
                        <div>
                            {street} {city} {state} {country}
                        </div>
                    )}
                </div>
            }
        />
    );
};
const ShippingAddressList = ({
    shippingAddress,
    shippingAddresses,
    onAddNewAddress,
    onUpdateBookData,
    onShowMore,
    errMsg,
    book
    // onDelete,
}) => {
    const dispatch = useDispatch();
    const [activeAddress, setActiveAddress] = React.useState(null);
    const [initialAddressFlag, setInitialAddressFlag] = React.useState(false);

    const onChange = React.useCallback(
        (address) => {
            if (initialAddressFlag) {
                if (address === activeAddress) {
                    setInitialAddressFlag(false);
                    setActiveAddress(null);
                    onUpdateBookData && onUpdateBookData(null);
                } else {
                    setInitialAddressFlag(true);
                    setActiveAddress(address);
                    onUpdateBookData && onUpdateBookData(address);
                }
            } else {
                setInitialAddressFlag(true);
                setActiveAddress(address);
                onUpdateBookData && onUpdateBookData(address);
            }
        },
        [onUpdateBookData, initialAddressFlag, activeAddress]
    );
    useDeepCompareEffectNoCheck(() => {
        if (shippingAddress) {
            setActiveAddress(shippingAddress);
            setInitialAddressFlag(true);
            onUpdateBookData && onUpdateBookData(shippingAddress);
        } else {
            (
                shippingAddresses.book_shipping_address.shippingAddresses || []
            ).forEach((element) => {
                if (element.default) {
                    setActiveAddress(element);
                    setInitialAddressFlag(true);
                    onUpdateBookData && onUpdateBookData(element);
                }
            });
        }
    }, []);

    const onClickModal = useCallback(
        (modalOption, address) => {
            if (modalOption === 'edit') {
                setActiveAddress(address);
                setInitialAddressFlag(true);
                onUpdateBookData && onUpdateBookData(address);
                onAddNewAddress && onAddNewAddress();
            } else if (modalOption === 'delete') {
                dispatch(
                    updateUI({
                        dialogs: [
                            withBookAddress(
                                DeleteShippingAddressDialog,
                                book.id,
                                address
                            )
                        ]
                    })
                );
            }
        },
        [onAddNewAddress, onUpdateBookData, dispatch, book]
    );

    const renderAddressItem = React.useCallback(() => {
        return (
            shippingAddresses?.book_shipping_address?.shippingAddresses || []
        ).map((address) => (
            <AddressItem
                {...address}
                key={address.id}
                address={address}
                checked={activeAddress?.id === address.id && initialAddressFlag}
                onChange={() => onChange(address)}
                onClickModal={onClickModal}
            />
        ));
    }, [
        shippingAddresses,
        activeAddress,
        initialAddressFlag,
        onChange,
        onClickModal
    ]);

    const onAdd = useCallback(() => {
        setInitialAddressFlag(false);
        setActiveAddress(null);
        onUpdateBookData && onUpdateBookData(null);
        onAddNewAddress && onAddNewAddress();
    }, [onAddNewAddress, onUpdateBookData]);

    return (
        <React.Fragment>
            {renderAddressItem()}
            {shippingAddresses?.book_shipping_address?.meta?.next && (
                <div
                    className="jrnl-checkbox"
                    onClick={() =>
                        onShowMore &&
                        onShowMore(
                            shippingAddresses.book_shipping_address.meta.next
                        )
                    }>
                    Show More
                </div>
            )}

            <div className="add-new-item btn btn-outline btn--sm">
                <CheckBox
                    onChange={onAdd}
                    inActiveMark={plusIcon}
                    label="Add a new Address"
                />
            </div>
            {errMsg ? <p>{errMsg}</p> : ''}
        </React.Fragment>
    );
};

const state = createStructuredSelector({
    shippingAddresses: getShippingAddresses
});
export default connect(state)(ShippingAddressList);
