import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// Actions
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getUI } from 'redux/selectors';
import * as InvitationActions from 'redux/actions/invitation';

// Components
import Dialog from '@material-ui/core/Dialog';

const ShareEntryInvitationDialog = ({ close, open, invitationId }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const _close = useCallback(() => {
        if (close) {
            close();
        }
    }, [close]);

    const _done = useCallback(
        (e) => {
            dispatch(
                InvitationActions.update(
                    {
                        accept_status: true
                    },
                    invitationId
                )
            ).then((response) => {
                // navigate to show entry page
                history.push(`/app/invitation/${invitationId}`);
                _close();
            });
        },
        [_close, dispatch, invitationId, history]
    );

    return (
        <Dialog
            fullWidth={true}
            open={open}
            onClose={_close}
            aria-labelledby="form-dialog-title">
            <div
                id="delete-modal"
                className="modal-box modal-box--delete modal-box--500">
                <div className="delete-modal">
                    <div className="delete-modal__content">
                        <div className="delete-modal__title">
                            Accept the entry invitation to be able to comment.
                        </div>
                    </div>
                    <div className="delete-modal__actions">
                        <button className="btn btn-outline" onClick={_close}>
                            Cancel
                        </button>
                        <button className="btn btn-delete" onClick={_done}>
                            Accept
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

const state = createStructuredSelector({
    ui: getUI
});

export default connect(state)(ShareEntryInvitationDialog);
