import React, { useEffect, useState } from 'react';

// images
import iconEllipsisVert from 'assets/images/icons_svg/icon_ellipsis-vert.svg';

import { DropdownMenu } from 'components';

const CheckBox = ({
    checked,
    activeMark,
    inActiveMark,
    label,
    onChange,
    onClickModal,
    address
}) => {
    const [dropdownFlag, setDropdownFlag] = useState(true);
    useEffect(() => {
        setDropdownFlag(Boolean(label.type));
    }, [label]);
    return (
        <div
            className="list__item"
            onClick={() => onChange && onChange(!checked)}>
            <div className="list__img">
                {checked ? (
                    <span className="fal fa-check bulk-select active"></span>
                ) : (
                    <span className="fal fa-check bulk-select"></span>
                )}
            </div>

            <div className="list__content">
                <div className="list__title">{label}</div>
            </div>

            {dropdownFlag && (
                <div className="list__actions">
                    <DropdownMenu
                        anchor={
                            <img
                                src={iconEllipsisVert}
                                className="dropdown-trigger btn-icon btn-icon--4"
                                alt=""
                            />
                        }
                        placement="bottom">
                        <span
                            key={'edit'}
                            onClick={() =>
                                onClickModal && onClickModal('edit', address)
                            }>
                            Edit
                        </span>
                        <div
                            key={'delete'}
                            onClick={() =>
                                onClickModal && onClickModal('delete', address)
                            }>
                            Delete
                        </div>
                    </DropdownMenu>
                </div>
            )}
        </div>
    );
};

export default CheckBox;
