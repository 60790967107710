import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// Actions
import * as EntryMediaActions from 'redux/actions/entrymedia';
import * as EditorActions from 'redux/actions/editor';
import * as GoogleActions from 'redux/actions/google';
// Utils
import formatBytes from 'common/utils/formatBytes';
// Images
import googleDriveImage from 'assets/images/google-drive.png';
import jrnlLogoGraphic from 'assets/images/jrnl-logo-graphic.png';
import loadingBar from 'assets/images/loading-bar.gif';
import { getUI } from 'redux/selectors';
import { ENTRYEDITOR_NEWDATA_TYPES } from 'common/constant';
import { checkResponseSuccess } from 'common/utils/responseUtils';

const UploadingBarFromSocialDialog = ({ file, dispatch, close, ui }) => {
    const uploadToServer = useRef(false);
    useEffect(() => {
        if (!uploadToServer.current) {
            const entryId = ui.newEntry ? ui.newEntry.id : ui.editEntry.id;
            const fileType = file.mimeType.slice(0, file.mimeType.indexOf('/'));
            dispatch(EntryMediaActions.setMediaType({ type: fileType }));
            dispatch(
                GoogleActions.uploadFile({ file_id: file?.id, entry: entryId })
            ).then((response) => {
                if (checkResponseSuccess(response.payload.status)) {
                    const data = response.payload.data;
                    data.file_formatted_size = formatBytes(data.file_size);
                    if (fileType !== ENTRYEDITOR_NEWDATA_TYPES.IMAGE) {
                        dispatch(EditorActions.insertMediaBlock(data));
                    } else {
                        data.src = data.file;
                        data.type = ENTRYEDITOR_NEWDATA_TYPES.IMAGE;
                        dispatch(EditorActions.insertImageBlock([data]));
                    }

                    close();
                }
            });

            uploadToServer.current = true;
        }
    }, [file, close, dispatch, uploadToServer, ui]);
    return (
        <div className="modal-frame" id="social-media-modal">
            <div className="modal-box modal__box--dropzone modal-box--600">
                <div className="modal__header">Add Files from Google Drive</div>
                <div className="modal__body">
                    <div className="social-media-info"></div>
                    <div className="social-media-uploading">
                        <div className="uploading-from">
                            <img
                                src={googleDriveImage}
                                alt="dropbox"
                                className="social-upload"
                            />
                            <i className="icon-fat-arrow-right"></i>
                            <img
                                src={jrnlLogoGraphic}
                                alt="logo"
                                className="jrnl-upload"
                            />
                        </div>

                        <p className="uploading-text">Uploading to JRNL...</p>

                        <div className="jnrl-uploading-bar">
                            <img
                                src={loadingBar}
                                alt="loading-bar"
                                className="loading-bar-gif"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    ui: getUI
});
export default connect(state)(UploadingBarFromSocialDialog);
