import React from 'react';
import formatBytes from 'common/utils/formatBytes';

// Icons
import iconFile from 'assets/images/icons_svg/icon_file.svg';
import iconOpenInNewSm from 'assets/images/icons_svg/icon_open-in-new-sm.svg';

const DisplayDocument = ({ file }) => {
    const openDocument = () => {
        const pdfWindow = window.open('');
        pdfWindow.document.write(
            `<iframe width='100%' height='100%' src="${file.src}"'></iframe>`
        );
    };

    return (
        <div className="add-media">
            <img
                className="add-media__icon"
                src={iconFile}
                alt=""
                onClick={openDocument}
            />
            <div className="add-media__content">
                <div className="add-media__title truncate">
                    <span>{file.name}</span>
                </div>
                <div className="add-media__subtitle">
                    {formatBytes(file.size)} -{' '}
                    <span onClick={openDocument}>
                        View File <img src={iconOpenInNewSm} alt="" />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default DisplayDocument;
