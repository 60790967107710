import { createSelector } from 'reselect';

export const TaggedUserSelector = (state) => state.tagged_users;

export const getTaggedUsersList = createSelector(
    [TaggedUserSelector],
    (state) => {
        return state?.tagged_users?.results;
    }
);

export const TaggedUserOnlySelector = (state) => state.tagged_users_only;

export const getTaggedUsersOnlyList = createSelector(
    [TaggedUserSelector],
    (state) => {
        return state?.tagged_users_only?.results;
    }
);
