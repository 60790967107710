import {
    ACTION_USER_AUTHENTICATE,
    ACTION_USER_FETCH,
    ACTION_USER_UPDATE_PASSWORD,
    ACTION_USER_LOGOUT,
    ACTION_USER_UPDATE,
    CREATE_INVITATION,
    CREATE_JOURNAL,
    CREATE_ENTRY_MEDIA,
    FACEBOOK_LOGIN,
    GOOGLE_LOGIN
} from 'redux/actions/actionTypes';
import { logout } from 'redux/actions/user';
import { SUCCESS_SUFFIX, ERROR_SUFFIX, ERROR_MESSAGES } from 'common/constant';
import { actions } from 'react-redux-form';
import { updateUI } from 'redux/actions/ui';

const initState = {
    isAuthorized: false
};

const UserReducer = (state = { ...initState }, action) => {
    switch (action.type) {
        case ACTION_USER_AUTHENTICATE + SUCCESS_SUFFIX: {
            return {
                ...state,
                isAuthorized: true,
                ...action.payload.data
            };
        }
        case GOOGLE_LOGIN + SUCCESS_SUFFIX:
        case FACEBOOK_LOGIN + SUCCESS_SUFFIX: {
            const { api_v1_key, key, username } = action.payload.data;

            return Object.assign({}, state, {
                isAuthorized: true,
                api_key: api_v1_key,
                api_v2_key: key,
                username
            });
        }
        case ACTION_USER_AUTHENTICATE + ERROR_SUFFIX: {
            const newState = { ...state };
            action.asyncDispatch(
                actions.setValidity('login.email_username', {
                    isCorrect: false
                })
            );
            action.asyncDispatch(
                actions.setValidity('login.password', { isCorrect: false })
            );

            return {
                ...newState,
                isAuthorized: false
            };
        }
        case ACTION_USER_FETCH + SUCCESS_SUFFIX:
        case ACTION_USER_UPDATE_PASSWORD + SUCCESS_SUFFIX: {
            return {
                ...state,
                isAuthorized: true,
                ...action.payload.data
            };
        }
        case ACTION_USER_LOGOUT: {
            return {
                isAuthorized: false,
                ...initState
            };
        }
        case ACTION_USER_FETCH + ERROR_SUFFIX: {
            action.asyncDispatch(logout());
            return {
                ...state
            };
        }
        case ACTION_USER_UPDATE + SUCCESS_SUFFIX: {
            return {
                ...state,
                ...action.payload.data
            };
        }

        case CREATE_INVITATION + ERROR_SUFFIX:
        case CREATE_JOURNAL + ERROR_SUFFIX:
        case CREATE_ENTRY_MEDIA + ERROR_SUFFIX: {
            if (state.is_free === true) {
                action.asyncDispatch(
                    updateUI({
                        errors: {
                            type: ERROR_MESSAGES.FREE_ACCOUNT_PERMISSION_ERROR
                        }
                    })
                );
            }
            return state;
        }

        default: {
            return {
                ...state
            };
        }
    }
};
export default UserReducer;
