import React from 'react';
import Dialog from '@material-ui/core/Dialog';

// images
import deleteImage from 'assets/images/banners/banner-delete.png';

// icons
import iconClose from 'assets/images/icons_svg/icon_close.svg';

const BookDeleteDialog = ({ open, onCancel, onDelete, book }) => {
    return (
        <Dialog
            fullWidth={true}
            open={open}
            aria-labelledby="form-dialog-title">
            <div id="delete-modal" className="delete-modal">
                <div className="container delete-modal-content">
                    <div className="row justify-content-center">
                        <div className="banner-card banner-card--light delete-card-bg">
                            <div className="banner-card__img">
                                <img
                                    src={deleteImage}
                                    alt="delete"
                                    className="img-fluid"
                                />
                            </div>
                            <div className="banner-card__content">
                                <div className="banner-card__title">
                                    Delete Book?
                                </div>
                                <div className="banner-card__subtitle">
                                    Are you sure you want to delete{' '}
                                    <span>{book?.title}</span> book?
                                </div>
                                <div className="banner-card__actions">
                                    <button
                                        className="btn btn-outline"
                                        onClick={() => onCancel()}>
                                        Cancel
                                    </button>
                                    <button
                                        className="btn btn-delete"
                                        onClick={() => onDelete(book?.id)}>
                                        Delete
                                    </button>
                                </div>
                            </div>
                            <button
                                onClick={() => onCancel()}
                                className="banner-card__close btn-close">
                                <img src={iconClose} alt="" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default BookDeleteDialog;
