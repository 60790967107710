import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { updateUI } from 'redux/actions/ui';
import { getUI, getUser } from 'redux/selectors';
import { createStructuredSelector } from 'reselect';
import iconEmail from 'assets/images/icons_svg/icon_email.svg';
import iconMobile from 'assets/images/icons_svg/icon_mobile.svg';
import { URLS } from 'common/urls';
import { useLocation } from 'react-router';
import * as UserActions from 'redux/actions/user';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';

const NotificationsPane = ({ dispatch, ui, user }) => {
    const location = useLocation();

    useDeepCompareEffectNoCheck(() => {
        const {
            user_settings: { notifications }
        } = user;
        dispatch(
            updateUI({
                notifications
            })
        );
    }, []);

    const changeSettings = useCallback(
        (notifications) => {
            dispatch(
                updateUI({
                    notifications
                })
            );

            dispatch(
                UserActions.update({
                    user_settings: {
                        ...user.user_settings,
                        notifications
                    }
                })
            );
        },
        [dispatch, user.user_settings]
    );

    const activeClassName =
        location.pathname === URLS.SETTINGS.NOTIFICATION ? 'active show' : '';

    return (
        <>
            <div
                className={`tab-pane fade notifications-settings ${activeClassName}`}
                id="notifications"
                role="tabpanel"
                aria-labelledby="notifications-tab">
                <h3 className="settings-title">Journaling Notifications</h3>
                <div className="settings-section">
                    <div className="list">
                        <div className="list__item">
                            <div className="list__content">
                                <div className="list__title">
                                    When someone shares a Journal with me
                                </div>
                            </div>
                            <div className="toggle-group">
                                <div className="toggle toggle--icon">
                                    <img
                                        className="toggle__icon"
                                        src={iconEmail}
                                        aria-labelledby={iconEmail}
                                        alt="Email"
                                    />
                                    <input
                                        type="checkbox"
                                        id="mailShare"
                                        checked={
                                            ui.notifications &&
                                            ui.notifications.email
                                        }
                                        onChange={(e) => {
                                            changeSettings({
                                                ...ui.notifications,
                                                email: e.target.checked
                                            });
                                        }}
                                    />
                                    <label
                                        className="settings-toggle-desc"
                                        htmlFor="mailShare"
                                    />
                                </div>
                                <div className="toggle toggle--icon">
                                    <img
                                        className="toggle__icon"
                                        src={iconMobile}
                                        aria-labelledby={iconMobile}
                                        alt="Mobile"
                                    />
                                    <input
                                        type="checkbox"
                                        id="mobileShare"
                                        checked={
                                            ui.notifications &&
                                            ui.notifications.push
                                        }
                                        onChange={(e) =>
                                            changeSettings({
                                                ...ui.notifications,
                                                push: e.target.checked
                                            })
                                        }
                                    />
                                    <label
                                        className="settings-toggle-desc"
                                        htmlFor="mobileShare"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="list__item">
                            <div className="list__content">
                                <div className="list__title">
                                    When someone shares an entry with me
                                </div>
                            </div>
                            <div className="toggle-group">
                                <div className="toggle toggle--icon">
                                    <img
                                        className="toggle__icon"
                                        src={iconEmail}
                                        aria-labelledby={iconEmail}
                                        alt="Email"
                                    />
                                    <input
                                        type="checkbox"
                                        id="mailInvite"
                                        checked={
                                            ui.notifications &&
                                            ui.notifications.invite &&
                                            ui.notifications.invite.email
                                        }
                                        onChange={(e) =>
                                            changeSettings({
                                                ...ui.notifications,
                                                invite: {
                                                    ...ui.notifications.invite,
                                                    email: e.target.checked
                                                }
                                            })
                                        }
                                    />
                                    <label
                                        className="settings-toggle-desc"
                                        htmlFor="mailInvite"
                                    />
                                </div>
                                <div className="toggle toggle--icon">
                                    <img
                                        className="toggle__icon"
                                        src={iconMobile}
                                        aria-labelledby={iconMobile}
                                        alt="Mobile"
                                    />
                                    <input
                                        type="checkbox"
                                        id="mobileInvite"
                                        checked={
                                            ui.notifications &&
                                            ui.notifications.invite &&
                                            ui.notifications.invite.push
                                        }
                                        onChange={(e) =>
                                            changeSettings({
                                                ...ui.notifications,
                                                invite: {
                                                    ...ui.notifications.invite,
                                                    push: e.target.checked
                                                }
                                            })
                                        }
                                    />
                                    <label
                                        className="settings-toggle-desc"
                                        htmlFor="mobileInvite"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h3 className="settings-title">Security Notifications</h3>
                <div className="settings-section">
                    <div className="list">
                        <div className="list__item">
                            <div className="list__content">
                                <div className="list__title">
                                    When I change my security information
                                </div>
                            </div>
                            <div className="toggle-group">
                                <div className="toggle toggle--icon">
                                    <img
                                        className="toggle__icon"
                                        src={iconEmail}
                                        aria-labelledby={iconEmail}
                                        alt="Email"
                                    />
                                    <input
                                        type="checkbox"
                                        id="mailSecurity"
                                        checked={
                                            ui?.notifications?.security?.email
                                        }
                                        onChange={(e) =>
                                            changeSettings({
                                                ...ui.notifications,
                                                security: {
                                                    ...ui.notifications
                                                        .security,
                                                    email: e.target.checked
                                                }
                                            })
                                        }
                                    />
                                    <label
                                        className="settings-toggle-desc"
                                        htmlFor="mailSecurity"
                                    />
                                </div>
                                <div className="toggle toggle--icon">
                                    <img
                                        className="toggle__icon"
                                        src={iconMobile}
                                        aria-labelledby={iconMobile}
                                        alt="Mobile"
                                    />
                                    <input
                                        type="checkbox"
                                        id="mobileSecurity"
                                        checked={
                                            ui?.notifications?.security?.push
                                        }
                                        onChange={(e) =>
                                            changeSettings({
                                                ...ui.notifications,
                                                security: {
                                                    ...ui.notifications
                                                        .security,
                                                    push: e.target.checked
                                                }
                                            })
                                        }
                                    />
                                    <label
                                        className="settings-toggle-desc"
                                        htmlFor="mobileSecurity"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="list__item">
                            <div className="list__content">
                                <div className="list__title">
                                    When I update my payment information
                                </div>
                            </div>
                            <div className="toggle-group">
                                <div className="toggle toggle--icon">
                                    <img
                                        className="toggle__icon"
                                        src={iconEmail}
                                        aria-labelledby={iconEmail}
                                        alt="Email"
                                    />
                                    <input
                                        type="checkbox"
                                        id="mailPayment"
                                        checked={
                                            ui?.notifications?.payment?.email
                                        }
                                        onChange={(e) =>
                                            changeSettings({
                                                ...ui.notifications,
                                                payment: {
                                                    ...ui.notifications.payment,
                                                    email: e.target.checked
                                                }
                                            })
                                        }
                                    />
                                    <label
                                        className="settings-toggle-desc"
                                        htmlFor="mailPayment"
                                    />
                                </div>
                                <div className="toggle toggle--icon">
                                    <img
                                        className="toggle__icon"
                                        src={iconMobile}
                                        aria-labelledby={iconMobile}
                                        alt="Mobile"
                                    />
                                    <input
                                        type="checkbox"
                                        id="mobilePayment"
                                        checked={
                                            ui?.notifications?.payment?.push
                                        }
                                        onChange={(e) =>
                                            changeSettings({
                                                ...ui.notifications,
                                                payment: {
                                                    ...ui.notifications.payment,
                                                    push: e.target.checked
                                                }
                                            })
                                        }
                                    />
                                    <label
                                        className="settings-toggle-desc"
                                        htmlFor="mobilePayment"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const state = createStructuredSelector({
    ui: getUI,
    user: getUser
});

export default connect(state)(NotificationsPane);
