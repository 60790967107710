import React, { useState } from 'react';
import { URLS } from 'common/urls';
import { Alert } from '@material-ui/lab';
import { buttonStatus } from './modals-utils';
import { Link } from 'react-router-dom';
import { PLAN_LABELS, PLAN_PRICES } from 'common/constant';
export const ProAccountDialog = ({
    onCloseDialogs,
    formData,
    onChange,
    setFormData
}) => {
    const [steps, setSteps] = useState(1);
    const renderAlertMessage = (name) => {
        let message;
        if (name === 'email') {
            message = `Sorry, this ${name} is already taken. Please enter another one.`;
        }
        return (
            <Alert severity="error" style={{ padding: '0 16px' }}>
                {message}
            </Alert>
        );
    };
    const createAccForm = () => {
        return (
            <form className="checkout-plans__form">
                <h3>1. Create your account</h3>
                <div
                    className={`form-group checkout-plans__form-group ${
                        formData.username.isValid && 'validation-graphic-valid'
                    }`}>
                    <label
                        className="checkout-plans__form-label"
                        htmlFor="name">
                        Name
                    </label>
                    <input
                        className="checkout-plans__form-input"
                        type="text"
                        name="username"
                        id="name"
                        value={formData.username.value}
                        onChange={(e) =>
                            onChange(e.target.value, e.target.name)
                        }
                    />
                </div>
                <div
                    className={`form-group checkout-plans__form-group ${
                        formData.email.isValid && 'validation-graphic-valid'
                    }`}>
                    <label
                        className="checkout-plans__form-label"
                        htmlFor="email">
                        Email
                    </label>
                    <input
                        className="checkout-plans__form-input"
                        type="email"
                        name="email"
                        id="email"
                        value={formData.email.value}
                        onChange={(e) =>
                            onChange(e.target.value, e.target.name)
                        }
                    />
                </div>
                {formData.email.error && renderAlertMessage('email')}
                <div
                    className={`form-group checkout-plans__form-group ${
                        formData.password.isValid && 'validation-graphic-valid'
                    }`}>
                    <label
                        className="checkout-plans__form-label"
                        htmlFor="password">
                        Password
                    </label>
                    <input
                        className="checkout-plans__form-input"
                        type="password"
                        name="password"
                        id="password"
                        value={formData.password.value}
                        onChange={(e) =>
                            onChange(e.target.value, e.target.name)
                        }
                    />
                </div>
                <div
                    className={`form-group checkout-plans__form-group ${
                        formData.confirm_password.isValid &&
                        'validation-graphic-valid'
                    }`}>
                    <label
                        className="checkout-plans__form-label"
                        htmlFor="repeatPassword">
                        Repeat Password
                    </label>
                    <input
                        className="checkout-plans__form-input"
                        type="password"
                        name="confirm_password"
                        id="repeatPassword"
                        value={formData.confirm_password.value}
                        onChange={(e) =>
                            onChange(e.target.value, e.target.name)
                        }
                    />
                </div>
                <div className="form-group checkout-plans__form-group d-flex checkmark-spacing">
                    <input
                        type="checkbox"
                        id="terms"
                        name="terms"
                        className="checkout-plans__checkmark"
                        onChange={() =>
                            setFormData({
                                ...formData,
                                privacy: {
                                    value: !formData.privacy.value,
                                    isValid: !formData.privacy.isValid
                                }
                            })
                        }
                        checked={formData.privacy.value}
                    />
                    <label
                        className="checkout-plans__checkmark-info"
                        htmlFor="terms">
                        I Have Read And Accept JRNL’s{' '}
                        <span>Terms of Service</span> &{' '}
                        <span>Privacy Policy</span>
                    </label>
                </div>
                <button
                    disabled={!buttonStatus(formData)}
                    type="submit"
                    className="btn btn-checkout-plans"
                    onClick={(e) => {
                        setSteps(2);
                    }}>
                    Create Account
                </button>
                <Link className="checkout-plans_link" to={URLS.LOGIN}>
                    Already have an account? Log in
                </Link>
            </form>
        );
    };
    const payDetailsForm = () => {
        return (
            <form className="checkout-plans__form">
                <h3>2. Payment Details</h3>
                <div className="form-group checkout-plans__form-group validation-graphic-valid">
                    <label
                        className="checkout-plans__form-label"
                        htmlFor="name">
                        Name on Card
                    </label>
                    <input
                        className="checkout-plans__form-input valid"
                        type="text"
                        name="name"
                    />
                </div>
                <div className="form-group checkout-plans__form-group validation-graphic-valid">
                    <label
                        className="checkout-plans__form-label"
                        htmlFor="email">
                        Card Number
                    </label>
                    <input
                        className="checkout-plans__form-input"
                        type="email"
                        name="email"
                    />
                </div>
                <div className="checkout-plans__form-group-card-info">
                    <div className="form-group checkout-plans__form-group">
                        <label
                            className="checkout-plans__form-label"
                            htmlFor="ExpiryDate">
                            Expiry Date
                        </label>
                        <div className="expiry-date-input">
                            <input
                                type="text"
                                name="ExpiryDateMonth"
                                id="ExpiryDateMonth"
                            />
                            <span>/</span>
                            <input
                                type="text"
                                name="ExpiryDateYear"
                                id="ExpiryDateYear"
                            />
                        </div>
                    </div>
                    <div className="form-group checkout-plans__form-group validation-graphic-valid">
                        <label
                            className="checkout-plans__form-label"
                            htmlFor="CVV">
                            CVV
                        </label>
                        <input
                            className="checkout-plans__form-input cvv-input-w"
                            type="text"
                            name="CVV"
                            id="CVV"
                        />
                    </div>
                </div>
                <div className="checkout-plans__cost">
                    <div className="checkout-plans__order-summary">
                        <p className="order-title">Order Summary</p>
                        <p className="checkout-package-summary">
                            {PLAN_LABELS.PRO} · ${PLAN_PRICES.PRO.DEFAULT} /
                            year
                        </p>
                    </div>
                    <div className="checkout-plans__order-receipt">
                        <p className="order-cost">
                            Now: ${PLAN_PRICES.PRO.DEFAULT}
                        </p>
                        <p className="next-payment">
                            Next Payment (${PLAN_PRICES.PRO.EXTRA}) on 22th
                            September 2021
                        </p>
                    </div>
                </div>
                <button className="btn btn-checkout-plans">
                    Confirm & Pay
                </button>
            </form>
        );
    };
    return (
        <div id="checkout-paid-modal" className="checkout-paid-modal">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 checkout-plans-content">
                        {steps === 1 ? createAccForm() : payDetailsForm()}
                        <div className="checkout-plans__information">
                            <div className="checkout-plans__information-header">
                                <h3>
                                    {PLAN_LABELS.PRO}
                                    <span>
                                        ${PLAN_PRICES.PRO.DEFAULT} / year
                                    </span>
                                </h3>
                                <button
                                    className="btn btn-close-checkout"
                                    onClick={() => onCloseDialogs()}>
                                    <i className="icon-times-light" />
                                </button>
                            </div>
                            <h5 className="checkout-plans__information-list-title">
                                All Free Features PLUS
                            </h5>
                            <ul className="checkout-plans__information-list">
                                <li>Unlimited Journals</li>
                                <li>1 TB of storage</li>
                                <li>12 PDF Downloads per year</li>
                                <li>Shared Entries & Journals</li>
                                <li>Audio & Video Upload</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
