export default {
    url: process.env.REACT_APP_URL,
    api: process.env.REACT_APP_API,
    api_v2: process.env.REACT_APP_API_V2,
    authType: process.env.REACT_APP_AUTHTYPE,
    env: process.env.NODE_ENV,
    redirect_url: process.env.REACT_APP_REDIRECT_URI,
    instagram_client_id: process.env.REACT_APP_INSTAGRAM_CLIENT_ID,
    facebook_client_id: process.env.REACT_APP_FACEBOOK_CLIENT_ID,
    google_client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    google_devkey: process.env.REACT_APP_GOOGLE_DEVKEY,
    google_recaptchakey: process.env.REACT_APP_GOOGLE_RECAPTCHAKEY,
    MAPBOX_ACCESS_TOKEN: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
    map_default_coords: { latitude: 45.4211, longitude: -75.6903 },
    firebase_user: {
        email: process.env.REACT_APP_FIREBASE_USER_EMAIL,
        password: process.env.REACT_APP_FIREBASE_USER_PASSWORD
    },
    PASSWORD_MIN_LENGTH: process.env.REACT_APP_PASSWORD_LENGTH || 8,
    SIGN_UP_PAGE_PASSWORD_MIN_LENGTH:
        process.env.REACT_APP_PASSWORD_LENGTH_FOR_REGISTER || 8
};
