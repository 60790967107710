import React, { useCallback } from 'react';
import defaultUser from 'assets/images/default-user.jpg';
import * as FilterActions from 'redux/actions/filter';
import * as EntryActions from 'redux/actions/entry';
import { useDispatch } from 'react-redux';

export const ListContactItem = ({ contact }) => {
    const dispatch = useDispatch();
    const filterByContact = useCallback(() => {
        // update filter reducer
        dispatch(
            FilterActions.update({
                filterByContact: contact
            })
        );

        dispatch(EntryActions.fetch());
    }, [dispatch, contact]);
    return (
        <div className="list__item">
            <div className="list__img list__img--avatar">
                <img
                    src={
                        contact.user?.avatar_image_url
                            ? contact.user?.avatar_image_url
                            : defaultUser
                    }
                    alt="avatar"
                />
            </div>
            <div className="list__content" onClick={filterByContact}>
                {contact.user?.public_display_name && (
                    <div className="list__title">
                        {contact.user.public_display_name}
                    </div>
                )}

                <div className="list__subtitle">{contact.email}</div>
            </div>
        </div>
    );
};
