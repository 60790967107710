import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    SETTING_CONNECT_URLS,
    SETTING_LIST,
    SUPPORT_SETTING_LIST
} from 'common/constant';
import { URLS } from 'common/urls';
import * as UserActions from 'redux/actions/user';
import * as JournalActions from 'redux/actions/journal';
import { SettingsListItem } from 'components/SettingsListItem';
import {
    getSettingsPageActiveTabIndex,
    isMobileSettingBarOpen
} from 'redux/selectors';
import { setSettingsActiveTab } from 'redux/actions/settings_page';
import withMemo from 'components/HOC/withMemo';
import donateIcon from 'assets/images/donate_icon.svg';
import { Link } from 'react-router-dom';
// import axios from 'axios';
import { getUser } from 'redux/selectors';
// import { Guid } from 'js-guid';

const SettingList = ({
    dispatch,
    // checkFreeAccount,
    active_tab,
    // user,
    openSettingsSidebar
}) => {
    const [isFetched, setIsFetched] = useState(false);
    const settingLists = SETTING_LIST;
    const settingSupportLists = SUPPORT_SETTING_LIST;
    useEffect(() => {
        if (!isFetched) {
            dispatch(JournalActions.fetch());
            setIsFetched(true);
        }
    }, [isFetched, dispatch]);

    const listClick = useCallback(
        (e, id, idx) => {
            switch (id) {
                case 'logout-tab': {
                    dispatch(UserActions.logout());
                    break;
                }

                // connect to other pages
                case 'billing-tab': {
                    if (
                        !window.location.href.includes('localhost') ||
                        window.location.href.includes('localhost:')
                    ) {
                        return;
                    }
                    e.preventDefault();
                    const url_ = SETTING_CONNECT_URLS['card-payments'];
                    window.open(url_);
                    break;
                }
                case 'account-tabx': {
                    if (
                        !window.location.href.includes('localhost') ||
                        window.location.href.includes('localhost:')
                    ) {
                        return;
                    }
                    e.preventDefault();
                    const url_ = SETTING_CONNECT_URLS['account-payments'];

                    // const data = {
                    //     token: user,
                    //     id: 'c26b589b-4d2e-4449-90cc-11f35cb7d983',
                    //     method: 'save'
                    // }

                    // axios.post(
                    //     `${settings.api}/stored_token/`,
                    //     user,
                    //     {
                    //         headers: {
                    //             'Content-Type': 'application/json'
                    //         }
                    //     })
                    window.open(url_);
                    break;
                }
                case 'contactSupport-tab':
                case 'knowledge-tab':
                case 'jrnlBlog-tab':
                case 'chrome-extension-tab': {
                    e.preventDefault();
                    const url_ =
                        navigator.userAgent.indexOf('Firefox') !== -1
                            ? SETTING_CONNECT_URLS['firefox-extension-tab']
                            : SETTING_CONNECT_URLS[id];
                    window.open(url_);

                    return;
                }

                default: {
                    break;
                }
            }
            dispatch(setSettingsActiveTab(idx));
        },
        [dispatch]
    );
    const renderSettingListItem = useCallback(
        (item, idx) => {
            return (
                <SettingsListItem
                    active_tab={active_tab}
                    item={item}
                    listClick={listClick}
                    idx={idx}
                    key={item.id}
                />
            );
        },
        [listClick, active_tab]
    );

    return (
        <div
            // className="settings-list"
            className={
                openSettingsSidebar
                    ? 'full-width-mobile settings-list'
                    : 'wrapped-settings-list-mobile-hide settings-list'
            }>
            <h3 className="settings-list-title">Settings</h3>
            <ul
                className="nav flex-column settings-list-bullets"
                id="settingsTab"
                role="tablist">
                {settingLists.map((item, idx) =>
                    renderSettingListItem(item, idx)
                )}
            </ul>
            <h3 className="settings-list-title">Support</h3>
            <ul
                className="nav flex-column settings-list-bullets"
                id="settingsTab"
                role="tablist">
                {settingSupportLists.map((item) => renderSettingListItem(item))}
            </ul>
            <div className="donate-button">
                <Link
                    to={''}
                    onClick={() => {
                        if (
                            !window.location.href.includes('localhost') ||
                            window.location.href.includes('localhost:')
                        ) {
                            window.location.href = URLS.DONATION;
                        } else {
                            window.open('https://jrnl.com/donation');
                        }
                    }}
                    className="btn btn-reminder btn-gradient btn-donate">
                    <img src={donateIcon} alt="donate icon" />
                    <span>DONATE</span>
                </Link>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    active_tab: getSettingsPageActiveTabIndex,
    openSettingsSidebar: isMobileSettingBarOpen,
    user: getUser
});
export default withMemo(connect(state)(SettingList));
