import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
// images
import logo from 'assets/images/jrnl-logo-graphic.png';
// constant
import { SUCCESS_BOOK_BUILDER_STATUS } from 'common/constant';
// actions
import * as BookActions from 'redux/actions/book';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';

const BookCreateSuccessDialog = ({ close, bookId }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const timer = React.useRef(null);
    const pollAttemps = React.useRef(0);
    const pollAttempRate = React.useRef(2000);

    // handle polled book data
    const handleWithPollData = React.useCallback(
        (book) => {
            // if book builder status is success, then move to book-preview
            if (
                SUCCESS_BOOK_BUILDER_STATUS.includes(book.book_builder_status)
            ) {
                clearTimeout(timer.current);
                history.push(`/book-preview/${book.id}`);
            }
        },
        [history]
    );

    // implement book poll by id
    const pollBook = React.useCallback(
        (bookId) => {
            // fetch book data
            dispatch(BookActions.poll({ id: bookId })).then((response) => {
                if (response?.payload?.status === 200) {
                    handleWithPollData(response?.payload?.data);
                } else {
                    clearTimeout(timer.current);
                }
            });

            if (timer.current) {
                clearTimeout(timer.current);
            }
            timer.current = setTimeout(
                () => pollBook(bookId),
                pollAttempRate.current
            );

            pollAttemps.current++;

            if (pollAttemps.current >= 15) {
                pollAttempRate.current =
                    pollAttempRate.current <= 60000
                        ? parseInt(pollAttempRate.current * 1.25)
                        : 60000;
            }
        },
        [dispatch, handleWithPollData]
    );

    // cancel all poll book timers on close
    const onClose = () => {
        // clear time out
        clearTimeout(timer.current);
        // close modal
        close();
    };

    useDeepCompareEffectNoCheck(() => {
        // poll book data
        pollBook(bookId);
    }, [bookId]);

    return (
        <div className="modal-frame" id="finished-book-process-modal">
            <div className="modal-box modal-box--600">
                <div className="modal__header">
                    <div className="finished-book-process-header">
                        <h3 className="title">Create New Book</h3>
                    </div>
                    <h5 className="subtitle">
                        {"You've completed the process!"}
                    </h5>
                </div>
                <div className="modal__body finished-book-process-content p-3">
                    <img src={logo} alt="jrnl logo" />
                    <p className="paragraph">
                        Now our robots are working hard to create your new book.
                        This could take a while, depending on how big your
                        selection is.
                    </p>
                    <p className="paragraph">
                        {`You can wait here and we will update this screen once
                        it's done, or exit and we will send you a notification
                        once your book is ready for review.`}
                    </p>
                    <button
                        className="btn btn-modal-outline-primary btn-return-menu ml-auto"
                        onClick={onClose}>
                        {' '}
                        Return to Menu{' '}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BookCreateSuccessDialog;
