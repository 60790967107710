import buildAuthorization from 'common/utils/buildAuthorization';
import axios from 'axios';

import { UserSelector } from 'redux/selectors/UserSelector';
import {
    FETCH_INSTAGRAM_ACCESS_TOKEN,
    FETCH_INSTAGRAM_AUTHORIZATION_URL,
    AUTHORIZE_INSTAGRAM_ACCESS_TOKEN,
    START_MEDIA_IMPORT,
    FETCH_INSTAGRAM_MEDIA_LIST,
    REMOVE_INSTAGRAM_ACCESS_TOKEN,
    UPDATE_INSTAGRAM_ACCESS_TOKEN,
    UPDATE_SELECTED_INSTAGRAM_MEDIA,
    BULK_SELECT_INSTAGRAM_MEDIA,
    UPDATE_IMPORT_FILTER_VALUE,
    UPDATE_SEARCH_QUERY,
    SET_INSTAGRAM_LOADINGPROGRESS,
    INSTAGRAM_CONNECT,
    INSTAGRAM_FETCH_MEDIA,
    INSTAGRAM_FETCH_PROFILE,
    INSTAGRAM_DISCONNECT
} from 'redux/actions/actionTypes';
import {
    INSTAGRAM_ACCESS_TOKEN_URL,
    INSTAGRAM_AUTHORIZATION_URL,
    INSTAGRAM_MEDIA_SEARCH_URL,
    INSTAGRAM_MEDIA_URL,
    INSTAGRAM_CONNECT_API_V2_URL,
    INSTAGRAM_MEDIA_API_V2_URL,
    INSTAGRAM_PROFILE_API_V2_URL,
    SUCCESS_SUFFIX,
    ERROR_SUFFIX,
    INSTAGRAM_DISCONNECT_API_V2_URL
} from 'common/constant';

import buildV2Authorization from 'common/utils/buildV2Authorization';

export const fetchInstagramAccessToken = (options = {}) => (
    dispatch,
    getState
) => {
    const url = INSTAGRAM_ACCESS_TOKEN_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: FETCH_INSTAGRAM_ACCESS_TOKEN,
        payload: {
            request: {
                url: url,
                method: 'GET',
                params: options,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const fetchInstagramAuthorizationUrl = (options = {}) => (
    dispatch,
    getState
) => {
    const url = INSTAGRAM_AUTHORIZATION_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: FETCH_INSTAGRAM_AUTHORIZATION_URL,
        payload: {
            request: {
                url: url,
                method: 'GET',
                params: options,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const authorizeInstagramAccessCode = (code) => (dispatch, getState) => {
    const url = INSTAGRAM_ACCESS_TOKEN_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: AUTHORIZE_INSTAGRAM_ACCESS_TOKEN,
        payload: {
            request: {
                url: url,
                method: 'POST',
                data: {
                    code: code
                },
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const startMediaImport = () => (dispatch, getState) => {
    const url = INSTAGRAM_MEDIA_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: START_MEDIA_IMPORT,
        payload: {
            request: {
                url: url,
                method: 'POST',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const removeInstagramAccessToken = (data, options = {}) => (
    dispatch,
    getState
) => {
    const url = `${INSTAGRAM_ACCESS_TOKEN_URL}${data.id}/`;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: REMOVE_INSTAGRAM_ACCESS_TOKEN,
        payload: {
            request: {
                url: url,
                method: 'DELETE',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const updateInstagramAccessToken = (data, options = {}) => (
    dispatch,
    getState
) => {
    const url = `${INSTAGRAM_ACCESS_TOKEN_URL}${data.id}/`;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: UPDATE_INSTAGRAM_ACCESS_TOKEN,
        payload: {
            request: {
                url: url,
                data: data,
                method: 'PATCH',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const updateSelectedInstagramMedia = (media, checked) => (
    dispatch,
    getState
) => {
    return dispatch({
        type: UPDATE_SELECTED_INSTAGRAM_MEDIA,
        payload: {
            data: {
                media: media,
                checked: checked
            }
        }
    });
};

export const bulkSelectInstagramMedia = (media) => (dispatch, getState) => {
    return dispatch({
        type: BULK_SELECT_INSTAGRAM_MEDIA,
        payload: {
            data: media
        }
    });
};

export const updateImportFilterValue = (filter) => (dispatch, getState) => {
    return dispatch({
        type: UPDATE_IMPORT_FILTER_VALUE,
        payload: {
            data: filter
        }
    });
};

export const loadMoreInstagramMedia = (resource_uri) => (
    dispatch,
    getState
) => {
    const url = resource_uri;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: FETCH_INSTAGRAM_MEDIA_LIST,
        payload: {
            request: {
                url: url,
                method: 'GET',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const updateSearchQuery = (query) => (dispatch, getState) => {
    dispatch({
        type: UPDATE_SEARCH_QUERY,
        payload: {
            data: query
        }
    });
};

export const searchInstagramMedia = (options = {}) => (dispatch, getState) => {
    const url = INSTAGRAM_MEDIA_SEARCH_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: FETCH_INSTAGRAM_MEDIA_LIST,
        payload: {
            request: {
                url: url,
                method: 'GET',
                params: options,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const setLoadingProgress = () => (dispatch, getState) => {
    return dispatch({
        type: SET_INSTAGRAM_LOADINGPROGRESS
    });
};

export const connectInstagram = (code) => (dispatch, getState) => {
    const url = INSTAGRAM_CONNECT_API_V2_URL;
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);

    return dispatch({
        type: INSTAGRAM_CONNECT,
        payload: {
            request: {
                url: url,
                method: 'POST',
                data: {
                    code: code
                },
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const fetchInstagramProfile = () => (dispatch, getState) => {
    const url = INSTAGRAM_PROFILE_API_V2_URL;
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);

    return dispatch({
        type: INSTAGRAM_FETCH_PROFILE,
        payload: {
            request: {
                url: url,
                method: 'GET',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const fetchInstagramMedia = () => (dispatch, getState) => {
    const url = INSTAGRAM_MEDIA_API_V2_URL;
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);

    return dispatch({
        type: INSTAGRAM_FETCH_MEDIA,
        payload: {
            data: {
                clear: true
            },
            request: {
                url: url,
                method: 'GET',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const fetchInstagramNextMedia = (pageUrl) => (dispatch, getState) => {
    dispatch({
        type: INSTAGRAM_FETCH_MEDIA
    });

    axios
        .get(pageUrl)
        .then((response) => {
            dispatch({
                type: INSTAGRAM_FETCH_MEDIA + SUCCESS_SUFFIX,
                payload: {
                    data: {
                        media: response.data
                    }
                }
            });
        })
        .catch((err) => {
            dispatch({
                type: INSTAGRAM_FETCH_MEDIA + ERROR_SUFFIX
            });
        });
};

export const disconnectConnect = (social_id, provider) => (
    dispatch,
    getState
) => {
    const url = INSTAGRAM_DISCONNECT_API_V2_URL;
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);

    return dispatch({
        type: INSTAGRAM_DISCONNECT,
        payload: {
            data: {
                clear: true
            },
            request: {
                url: url,
                method: 'POST',
                headers: {
                    Authorization: auth
                },
                params: { social_id, provider }
            }
        }
    });
};
