import React from 'react'
import {Link} from 'react-router-dom';
import {URLS} from 'common/urls';

export const PasswordChangeModal = ({change_password, closeModal}) => {
    const [formData, setFormData] = React.useState({
        new_password1: '',
        new_password2: ''
    })
    const passwordValidate = () => {
        if (formData.new_password1.trim().length && formData.new_password2.trim()) {
            if (formData.new_password1.trim().length >= 8) {
                return (formData.new_password1.trim() === formData.new_password2.trim())
            }
        } else {
            return false
        }
    }
    return (
        <section className="forgot-pass" style={{width: '100%', height: '1.8rem'}}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6 col-lg-3 forgot-pass-card p-4">
                        <div className="forgot-pass-content">
                            <h3 id="forgotHeading">Please enter a new password</h3>
                        </div>
                        <form className="forgot-pass-form">
                            <input
                                value={formData.new_password1}
                                className="forgot-pass-input"
                                type="password"
                                placeholder="Password"
                                onChange={(e) => setFormData({...formData, new_password1: e.target.value})}/>
                            <input
                                value={formData.new_password2}
                                className="forgot-pass-input"
                                type="password"
                                placeholder="Confirm Password"
                                onChange={(e) => setFormData({...formData, new_password2: e.target.value})}/>
                            <button
                                disabled={!passwordValidate()}
                                className="btn btn-reset-password"
                                id="resetBtn"
                                onClick={(e) => change_password(e,formData)}
                                style={passwordValidate()
                                    ? {backgroundColor: ' var(--primary-color)'}
                                    : {backgroundColor: 'rgb(166, 166, 166)', cursor: 'not-allowed'}}>
                                RESET PASSWORD
                            </button>
                        </form>
                        <div className="forgot-pass-links" >
                            <Link to={URLS.LOGIN} className="reset-link" onClick={() => closeModal()}>LOGIN TO MY ACCOUNT</Link>
                            <Link to={URLS.SIGNUP} className="reset-link" onClick={() => closeModal()}>SIGN UP HERE</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}