// all about me
export const FETCH_RANDOM_QUESTION = 'FETCH_RANDOM_QUESTION';
export const FETCH_AAM_QUESTION = 'FETCH_AAM_QUESTION';
export const FETCH_AAM_QUESTION_LIST = 'FETCH_AAM_QUESTION_LIST';
export const FETCH_AAM_CATEGORY = 'FETCH_AAM_CATEGORY';
export const FETCH_AAM_CATEGORY_LIST = 'FETCH_AAM_CATEGORY_LIST';
export const CREATE_AAM_QUESTION = 'CREATE_AAM_QUESTION';
export const UPDATE_AAM_QUESTION = 'UPDATE_AAM_QUESTION';
export const DELETE_AAM_QUESTION = 'DELETE_AAM_QUESTIONF';
export const CLEAR_RANDOM_QUESTION = 'CLEAR_RANDOM_QUESTION';

// calendar
export const FETCH_CALENDAR = 'FETCH_CALENDAR';
export const SET_CALENDAR_LOADINGPROGRESS = 'SET_CALENDAR_LOADINGPROGRESS';
export const UPDATE_CALENDAR = 'UPDATE_CALENDAR';

// comment
export const REMOVE_COMMENT = 'REMOVE_COMMENT';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const CREATE_COMMENT = 'CREATE_COMMENT';
export const FETCH_SHARED_COMMENT_LIST = 'FETCH_SHARED_COMMENT_LIST';
export const FETCH_COMMENT_LIST = 'FETCH_COMMENT_LIST';
export const SET_COMMENT_LOADINGPROGRESS = 'SET_COMMENT_LOADINGPROGRESS';
export const SAVE_DRAFT_COMMENT = 'SAVE_DRAFT_COMMENT';

//editor
export const UPDATE_EDITOR_STATE = 'UPDATE_EDITOR_STATE';
export const INSERT_IMAGE_BLOCK = 'INSERT_IMAGE_BLOCK';
export const RESET_IMAGE_STATE = 'RESET_IMAGE_STATE';
export const UNSTAGE_ENTRY_IMAGE = 'UNSTAGE_ENTRY_IMAGE';
export const UNSTAGE_ALL_IMAGES = 'UNSTAGE_ALL_IMAGES';
export const REMOVE_EDITOR_NEWDATA = 'REMOVE_EDITOR_NEWDATA';
export const INSERT_INSTAGRAM_CONTENT = 'INSERT_INSTAGRAM_CONTENT';
export const UPDATE_IMAGE_PROGRESS = 'UPDATE_IMAGE_PROGRESS';
export const UPDATE_FILES_LIST = 'UPDATE_FILES_LIST';
export const RESET_FILES_LIST = 'RESET_FILES_LIST';

// entry
export const FETCH_ENTRY_LIST = 'FETCH_ENTRY_LIST';
export const FETCH_ENTRY = 'FETCH_ENTRY';
export const CREATE_ENTRY = 'CREATE_ENTRY';
export const UPDATE_ENTRY = 'UPDATE_ENTRY';
export const REMOVE_ENTRY = 'REMOVE_ENTRY';
export const PATCH_ENTRY = 'PATCH_ENTRY';
export const SET_ENTRY_LOADINGPROGRESS = 'SET_ENTRY_LOADINGPROGRESS';
export const FETCH_DRAFT_ENTRY = 'FETCH_DRAFT_ENTRY';
export const CHANGE_SORT_ENTRY_COMMENT = 'CHANGE_SORT_ENTRY_COMMENT';
export const FETCH_ENTRY_WITH_OPTIONS = 'FETCH_ENTRY_WITH_OPTIONS';
export const FETCH_ENTRY_WITH_OPTIONS_SUCCESS =
    'FETCH_ENTRY_WITH_OPTIONS_SUCCESS';
export const FETCH_ENTRY_BY_URL = 'FETCH_ENTRY_BY_URL';
export const SET_ENTRY_POSITION = 'SET_ENTRY_POSITION';
export const FETCH_SHARED_JOURNAL_ENTRY = 'FETCH_SHARED_JOURNAL_ENTRY';
export const RESET_ENTRIES = 'RESET_ENTRIES';
export const COPY_ENTRY = 'COPY_ENTRY';
export const FETCH_MEDIA_ENTRY = 'FETCH_MEDIA_ENTRY';

// entryimage
export const CREATE_ENTRY_IMAGE = 'CREATE_ENTRY_IMAGE';
export const UPDATE_ENTRY_IMAGE = 'UPDATE_ENTRY_IMAGE';
export const CREATE_ENTRY_IMAGE_PROGRESS = 'CREATE_ENTRY_IMAGE_PROGRESS';
export const SET_INSTAGRAM_LOADINGPROGRESS = 'SET_INSTAGRAM_LOADINGPROGRESS';
export const SOCIALIMAGE_UPLOAD = 'SOCIALIMAGE_UPLOAD';

// entrymedia
export const SET_MEDIA_TYPE = 'SET_MEDIA_TYPE';
export const CREATE_ENTRY_MEDIA = 'CREATE_ENTRY_MEDIA';
export const CREATE_ENTRY_MEDIA_PROGRESS = 'CREATE_ENTRY_MEDIA_PROGRESS';
export const UPDATE_ENTRY_MEDIA = 'UPDATE_ENTRY_MEDIA';
export const DELETE_ENTRY_MEDIA = 'DELETE_ENTRY_MEDIA';
export const INSERT_MEDIA_BLOCK = 'INSERT_MEDIA_BLOCK';
export const UNSTAGE_ENTRY_MEDIA = 'UNSTAGE_ENTRY_MEDIA';
export const FILTER_ENTRY_MEDIA = 'FILTER_ENTRY_MEDIA';
export const RESET_FILTER_ENTRY_MEDIA = 'RESET_FILTER_ENTRY_MEDIA';
export const FETCH_ENTRY_MEDIA = 'FETCH_ENTRY_MEDIA';
export const SEARCH_ENTRY_MEDIA = 'SEARCH_ENTRY_MEDIA';
export const FETCH_ENTRY_MEDIA_INDIVIDUAL = 'FETCH_ENTRY_MEDIA_INDIVIDUAL';
export const DETECT_FACES = 'DETECT_FACES';
export const CREATE_MEDIA_INVITATION = 'CREATE_MEDIA_INVITATION';

// filter
export const UPDATE_ENTRY_SORT = 'UPDATE_ENTRY_SORT';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';

// invitation
export const FETCH_INVITATION_LIST = 'FETCH_INVITATION_LIST';
export const CREATE_INVITATION = 'CREATE_INVITATION';
export const REMOVE_INVITATION = 'REMOVE_INVITATION';
export const FETCH_PUBLIC_ENTRY_SHARING_INVITATION =
    'FETCH_PUBLIC_ENTRY_SHARING_INVITATION';
export const FETCH_ENTRY_SHARING_INVITATION_LIST =
    'FETCH_ENTRY_SHARING_INVITATION_LIST';
export const UPDATE_INVITATION = 'UPDATE_INVITATION';
export const SET_INVITE_ID = 'SET_INVITE_ID';
export const SEND_INVITATION_EMAIL = 'SEND_INVITATION_EMAIL';
export const FETCH_INVIATAION_WITH_UNIQUE_KEY =
    'FETCH_INVIATAION_WITH_UNIQUE_KEY';
export const FETCH_INDIVIDUAL_INVITATION = 'FETCH_INDIVIDUAL_INVITATION';

// journal
export const FETCH_JOURNAL_LIST = 'FETCH_JOURNAL_LIST';
export const FETCH_JOURNAL_DETAIL = 'FETCH_JOURNAL_DETAIL';
export const CREATE_JOURNAL = 'CREATE_JOURNAL';
export const UPDATE_JOURNAL = 'UPDATE_JOURNAL';
export const REMOVE_JOURNAL = 'REMOVE_JOURNAL';
export const PATCH_JOURNAL = 'PATCH_JOURNAL';
export const DOWNLOAD_VCARD = 'DOWNLOAD_VCARD';
export const RESET_VCARD = 'RESET_VCARD';
export const GENERATE_EMAIL_TO_JRNL_ADDRESS = 'GENERATE_EMAIL_TO_JRNL_ADDRESS';
export const SET_JOURNAL_LOADINGPROGRESS = 'SET_JOURNAL_LOADINGPROGRESS';

// publicuser
export const FETCH_PUBLIC_USER_LIST = 'FETCH_PUBLIC_USER_LIST';
export const FETCH_PUBLIC_USER = 'FETCH_PUBLIC_USER';
export const FETCH_PUBLIC_USER_DETAIL = 'FETCH_PUBLIC_USER_DETAIL';
export const FETCH_PUBLIC_USERS_LIST = 'FETCH_PUBLIC_USERS_LIST';

// v2publicuser
export const FETCH_V2_PUBLIC_USER = 'FETCH_V2_PUBLIC_USER';
export const FETCH_V2_PUBLIC_USER_INDIVIDUAL =
    'FETCH_V2_PUBLIC_USER_INDIVIDUAL';
export const COMPARE_FACE = 'COMPARE_FACE';

// registration
export const ACTION_REGISTRATION_REGISTER = 'REGISTER';
export const ACTION_REGISTERATION_ACTIVATE = 'ACTIVE';
export const ACTION_REGISTERATION_CHECK_USERNAME_AVAILABILITY =
    'CHECK_USERNAME_AVAILABILITY';
export const ACTION_REGISTERATION_CHECK_EMAIL_AVAILABILITY =
    'CHECK_EMAIL_AVAILABILITY';

// tag
export const FETCH_TAG_LIST = 'FETCH_TAG_LIST';
export const CREATE_TAG = 'CREATE_TAG';
export const UPDATE_TAG = 'UPDATE_TAG';
export const REMOVE_TAG = 'REMOVE_TAG';
export const SET_TAG_LOADINGPROGRESS = 'SET_TAG_LOADINGPROGRESS';

// timelineapproval
export const FETCH_TIMELINE_APPROVAL_LIST = 'FETCH_TIMELINE_APPROVAL_LIST';

// user
export const ACTION_USER_LOGOUT = 'USER_LOGOUT';
export const ACTION_USER_SAVE = 'USER_SAVE';
export const ACTION_USER_AUTHENTICATE = 'USER_AUTHENTICATE';
export const ACTION_USER_FETCH = 'USER_FETCH';
export const ACTION_USER_UPDATE = 'USER_UPDATE';
export const ACTION_USER_RESET_STATUS_MESSAGE = 'RESET_STATUS_MESSAGE';
export const ACTION_USER_REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET';
export const ACTION_USER_RESET_PASSWORD = 'RESET_PASSWORD';
export const ACTION_USER_UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const ACTION_CHANGE_ACCOUNT_DEFAULT = 'ACTION_CHANGE_ACCOUNT_DEFAULT';
export const ACTION_USER_SUBSCRIBE_PRO = 'ACTION_USER_SUBSCRIBE_PRO';
export const ACTION_USER_UNSUBSCRIBE_PRO = 'ACTION_USER_UNSUBSCRIBE_PRO';
export const ACTION_USER_ENABLE_2FA = 'ACTION_USER_ENABLE_2FA';
export const ACTION_USER_RENEW_PLAN = 'ACTION_USER_RENEW_PLAN';

// ui
export const ACTION_UI_UPDATE = 'UI_UPDATE';
export const UPDATE_STEPS_FILLED_IN = 'UPDATE_STEPS_FILLED_IN';

// avatarimage
export const CREATE_AVATAR_IMAGE = 'CREATE_AVATAR_IMAGE';

// instagram
export const FETCH_INSTAGRAM_ACCESS_TOKEN = 'FETCH_INSTAGRAM_ACCESS_TOKEN';
export const FETCH_INSTAGRAM_AUTHORIZATION_URL =
    'FETCH_INSTAGRAM_AUTHORIZATION_URL';
export const AUTHORIZE_INSTAGRAM_ACCESS_TOKEN =
    'AUTHORIZE_INSTAGRAM_ACCESS_TOKEN';
export const START_MEDIA_IMPORT = 'START_MEDIA_IMPORT';
export const FETCH_INSTAGRAM_MEDIA_LIST = 'FETCH_INSTAGRAM_MEDIA_LIST';
export const REMOVE_INSTAGRAM_ACCESS_TOKEN = 'REMOVE_INSTAGRAM_ACCESS_TOKEN';
export const UPDATE_INSTAGRAM_ACCESS_TOKEN = 'UPDATE_INSTAGRAM_ACCESS_TOKEN';
export const UPDATE_SELECTED_INSTAGRAM_MEDIA =
    'UPDATE_SELECTED_INSTAGRAM_MEDIA';
export const BULK_SELECT_INSTAGRAM_MEDIA = 'BULK_SELECT_INSTAGRAM_MEDIA';
export const UPDATE_IMPORT_FILTER_VALUE = 'UPDATE_IMPORT_FILTER_VALUE';
export const UPDATE_SEARCH_QUERY = 'UPDATE_SEARCH_QUERY';
export const RESET_SEARCH_PAGE = 'RESET_SEARCH_PAGE';

// books
export const FETCH_BOOK_LIST = 'FETCH_BOOK_LIST';
export const FETCH_BOOK = 'FETCH_BOOK';
export const CREATE_BOOK = 'CREATE_BOOK';
export const UPDATE_BOOK = 'UPDATE_BOOK';
export const REMOVE_BOOK = 'REMOVE_BOOK';
export const POLL_BOOK = 'POLL_BOOK';
export const ADD_BOOK_TIMER = 'ADD_BOOK_TIMER';
export const REMOVE_BOOK_TIMER = 'REMOVE_BOOK_TIMER';
export const REMOVE_ALL_BOOK_TIMERS = 'REMOVE_ALL_BOOK_TIMERS';
export const SET_BOOK_LOADINGPROGRESS = 'SET_BOOK_LOADINGPROGRESS';
export const SET_BOOK_DOWNLOAD_PROGRESS = 'SET_BOOK_DOWNLOAD_PROGRESS';
export const UPDATE_AND_FETCH = 'UPDATE_AND_FETCH';
export const BOOK_BUIDER_FULL_VIEW = 'BOOK_BUIDER_FULL_VIEW';

// book order
export const FETCH_BOOK_ORDER_LIST = 'FETCH_BOOK_ORDER_LIST';
export const CREATE_BOOK_ORDER = 'CREATE_BOOK_ORDER';

// book shipping address
export const FETCH_BOOK_SHIPPING_ADDRESS_LIST =
    'FETCH_BOOK_SHIPPING_ADDRESS_LIST';
export const CREATE_BOOK_SHIPPING_ADDRESS = 'CREATE_BOOK_SHIPPING_ADDRESS';
export const UPDATE_BOOK_SHIPPING_ADDRESS = 'UPDATE_BOOK_SHIPPING_ADDRESS';
export const CREATE_BOOK_SHIPPING_ADDRESS_LIST =
    'CREATE_BOOK_SHIPPING_ADDRESS_LIST';
export const UPDATE_BOOK_SHIPPING_ADDRESS_LIST =
    'UPDATE_BOOK_SHIPPING_ADDRESS_LIST';

// search
export const UPDATE_QUERY = 'UPDATE_QUERY';
export const FETCH_RESULTS = 'FETCH_RESULTS';
export const FETCH_ADDITIONAL_RESULTS = 'FETCH_ADDITIONAL_RESULTS';
export const SET_SEARCH_LOADINGPROGRESS = 'SET_SEARCH_LOADINGPROGRESS';

// loading progress bar
export const CLEAR_LOADINGPROGRESS = 'CLEAR_LOADINGPROGRESS';

// sharing contact list
export const FETCH_SHARING_CONTACT_LIST = 'FETCH_SHARING_CONTACT_LIST';
export const FETCH_DETAILED_CONTACT_LIST = 'FETCH_DETAILED_CONTACT_LIST';
export const CREATE_EMPTY_CONTACT = 'CREATE_EMPTY_CONTACT';
export const SET_SELECTED_CONTACT = 'SET_SELECTED_CONTACT';

// payment profile
export const FETCH_PAYMENT_PROFILE = 'FETCH_PAYMENT_PROFILE';
export const FETCH_PAYMENT_PROFILE_LIST = 'FETCH_PAYMENT_PROFILE_LIST';
export const FETCH_USER_PAYMENT_PROFILE = 'FETCH_USER_PAYMENT_PROFILE';
export const CREATE_PAYMENT_PROFILE = 'CREATE_PAYMENT_PROFILE';
export const DELETE_PAYMENT_PROFILE = 'DELETE_PAYMENT_PROFILE';
export const EDIT_PAYMENT_PROFILE = 'EDIT_PAYMENT_PROFILE';

// instagram connect
export const INSTAGRAM_CONNECT = 'INSTAGRAM_CONNECT';
export const INSTAGRAM_FETCH_PROFILE = 'INSTAGRAM_FETCH_PROFILE';
export const INSTAGRAM_FETCH_MEDIA = 'INSTAGRAM_FETCH_MEDIA';
export const INSTAGRAM_DISCONNECT = 'INSTAGRAM_DISCONNECT';
// Facebook connect
export const FACEBOOK_CONNECT = 'FACEBOOK_CONNECT';
export const FACEBOOK_FETCH_PROFILE = 'FACEBOOK_FETCH_PROFILE';
export const FACEBOOK_FETCH_MEDIA = 'FACEBOOK_FETCH_MEDIA';
export const FACEBOOK_DISCONNECT = 'FACEBOOK_DISCONNECT';
export const FACEBOOK_LOGIN = 'FACEBOOK_LOGIN';
// Google
export const GOOGLE_CONNECT = 'GOOGLE_CONNECT';
export const GOOGLE_UPLOAD = 'GOOGLE_UPLOAD';
export const GOOGLE_LOGIN = 'GOOGLE_LOGIN';

// journal sharing
export const CRATE_JOURNAL_INVITATION = 'CRATE_JOURNAL_INVITATION';
export const REMOVE_JOURNAL_INVITATION = 'REMOVE_JOURNAL_INVITATION';
export const FETCH_JOURNAL_INVIATAION_WITH_UNIQUE_KEY =
    'FETCH_JOURNAL_INVIATAION_WITH_UNIQUE_KEY';
export const UPDATE_JOURNAL_INVITATION = 'UPDATE_JOURNAL_INVITATION';

// Reminder
export const SETTINGS_REMINDER_FETCH = 'SETTINGS_REMINDER_FETCH';
export const SETTINGS_REMINDER_TOGGLE = 'SETTINGS_REMINDER_TOGGLE';
export const SETTINGS_REMINDER_CREATE = 'SETTINGS_REMINDER_CREATE';
export const SETTINGS_REMINDER_EDIT = 'SETTINGS_REMINDER_EDIT';
export const SETTINGS_REMINDER_DELETE = 'SETTINGS_REMINDER_DELETE';

// journal email
export const FETCH_JOURNAL_EMAILS = 'FETCH_JOURNAL_EMAILS';
export const FETCH_AUTHORISED_EMAILS = 'FETCH_AUTHORISED_EMAILS';
export const CREATE_JOURNAL_EMAIL = 'CREATE_JOURNAL_EMAIL';
export const RESET_JOURNAL_EMAIL = 'RESET_JOURNAL_EMAIL';
export const CREATE_JOURNAL_EMAIL_SENDER = 'CREATE_JOURNAL_EMAIL_SENDER';
export const EDIT_JOURNAL_EMAIL_SENDER = 'EDIT_JOURNAL_EMAIL_SENDER';
export const DELETE_JOURNAL_EMAIL_SENDER = 'DELETE_JOURNAL_EMAIL_SENDER';
export const DELETE_JOURNAL_EMAIL = 'DELETE_JOURNAL_EMAIL';

// family accounts
export const FETCH_FAMILY_ACCOUNTS = 'FETCH_FAMILY_ACCOUNTS';
export const CREATE_FAMILY_ACCOUNT = 'CREATE_FAMILY_ACCOUNT';
export const EDIT_FAMILY_ACCOUNT = 'EDIT_FAMILY_ACCOUNT';
export const DELETE_FAMILY_ACCOUNT = 'DETELE_FAMILY_ACCOUNT';

// beneficiaries
export const FETCH_BENEFICIARIES_ACCOUNTS = 'FETCH_BENEFICIARIES_ACCOUNTS';
export const CREATE_BENEFICIARIES_ACCOUNT = 'CREATE_BENEFICIARIES_ACCOUNT';
export const EDIT_BENEFICIARIES_ACCOUNT = 'EDIT_BENEFICIARIES_ACCOUNT';
export const DELETE_BENEFICIARIES_ACCOUNT = 'DELETE_BENEFICIARIES_ACCOUNT';

// background images
export const FETCH_BACKGROUND_IMAGES = 'FETCH_BACKGROUND_IMAGES';

// notification
export const ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS';
export const SET_NOTIFICATIONS_LIMIT = 'SET_NOTIFICATIONS_LIMIT';

// contacts

export const FETCH_CONTACTS = 'FETCH_CONTACTS';
export const ADD_CONTACT = 'ADD_CONTACT';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const FETCH_SEARCHED_CONTACT_LIST = 'FETCH_SEARCHED_CONTACT_LIST';
export const EDIT_CONTACT = 'EDIT_CONTACT';

//settings page

export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const SET_SETTINGS_ACTIVE_TAB_BY_PATH =
    'SET_SETTINGS_ACTIVE_TAB_BY_PATH';

export const SET_SELECTED_PLAN_DURATION = 'SET_SELECTED_PLAN_DURATION';
export const SET_SETTINGS_SIDEBAR_MOBILE_STATUS =
    'SET_SETTINGS_SIDEBAR_STATUS_MOBILE';

//tagged user
export const TAGGED_USER_FETCH = 'TAGGED_USER_FETCH';
export const TAGGED_USER_CREATE = 'TAGGED_USER_CREATE';
export const TAGGED_USER_UPDATE = 'TAGGED_USER_UPDATE';
export const TAGGED_USER_DELETE = 'TAGGED_USER_DELETE';
export const TAGGED_USER_FETCH_INDIVIDUAL = 'TAGGED_USER_FETCH_INDIVIDUAL';
export const TAGGED_USER_SEARCH = 'TAGGED_USER_SEARCH';
export const TAGGED_USER_ONLY_ALL_FETCH = 'TAGGED_USER_ONLY_ALL_FETCH';

// v2 user
export const FETCH_V2_USER = 'FETCH_V2_USER';
export const FETCH_V2_USER_ME = 'FETCH_V2_USER_ME';
export const FETCH_V2_USER_INDIVIDUAL = 'FETCH_V2_USER_INDIVIDUAL';
export const CREATE_V2_USER = 'CREATE_V2_USER';
export const UPDATE_V2_USER = 'UPDATE_V2_USER';
export const DELETE_V2_USER = 'DELETE_V2_USER';

export const EXPORT_JOURNAL_DATA = 'EXPORT_JOURNAL_DATA';
