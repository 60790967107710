import {
    ACTION_REGISTERATION_ACTIVATE,
    ACTION_REGISTERATION_CHECK_USERNAME_AVAILABILITY,
    ACTION_REGISTERATION_CHECK_EMAIL_AVAILABILITY,
    ACTION_USER_LOGOUT,
    ACTION_REGISTRATION_REGISTER
} from 'redux/actions/actionTypes';
import { SUCCESS_SUFFIX, ERROR_SUFFIX } from 'common/constant';
import { actions } from 'react-redux-form';

const initState = {
    is_active: undefined,
    activation_key: undefined,
    error: undefined
};

const RegistrationReducer = (state = { ...initState }, action) => {
    switch (action.type) {
        case ACTION_REGISTERATION_ACTIVATE: {
            return {
                ...state,
                ...action.payload.request.data.activation_key
            };
        }
        case ACTION_REGISTERATION_ACTIVATE + SUCCESS_SUFFIX: {
            return {
                ...state,
                is_active: true,
                error: undefined
            };
        }
        case ACTION_REGISTERATION_CHECK_USERNAME_AVAILABILITY +
            SUCCESS_SUFFIX: {
            const data = action.payload.data;
            action.asyncDispatch(
                actions.setValidity('signup.username', {
                    isAvailable:
                        data && data.taken !== undefined ? !data.taken : false
                })
            );
            action.asyncDispatch(actions.setPending('signup.username', false));
            return {
                ...state
            };
        }
        case ACTION_REGISTERATION_CHECK_EMAIL_AVAILABILITY + SUCCESS_SUFFIX: {
            const data = action.payload.data;
            action.asyncDispatch(
                actions.setValidity('signup.email', {
                    isAvailable:
                        data && data.taken !== undefined ? !data.taken : false
                })
            );
            action.asyncDispatch(actions.setPending('signup.email', false));
            return {
                ...state
            };
        }
        case ACTION_REGISTERATION_ACTIVATE + ERROR_SUFFIX: {
            return {
                ...state,
                is_active: false,
                error: action.error.response.data
            };
        }
        case ACTION_USER_LOGOUT: {
            return {
                ...initState
            };
        }
        case ACTION_REGISTRATION_REGISTER + ERROR_SUFFIX: {
            if (action.error.response.data.validation_errors) {
                alert(
                    `Error: ${action.error.response.data.validation_errors.__all__}`
                );
            } else {
                alert(
                    'Error: Data validation failed. Make sure you entered everything correctly.'
                );
            }
            return {
                ...state
            };
        }
        default: {
            return {
                ...state
            };
        }
    }
};

export default RegistrationReducer;
