import React, { useRef, useState, lazy, Suspense } from 'react';
import settings from 'common/configs/setting';
import { useDispatch } from 'react-redux';
import { geoGoderApi } from 'redux/actions/entry';
import withMemo from './HOC/withMemo';

import { isEmpty } from 'common/utils/arrayUtils';

const mapStyle = {
    default: 'mapbox://styles/mapbox/streets-v9'
};
const ReactMapGL = lazy(async () => await import('react-map-gl'));

const MapboxContainer = ({
    viewport,
    setViewPort,
    isCurrent,
    setIsCurrent,
    showOnly
}) => {
    const currentPosMarkerStyle = {
        position: 'absolute',
        left: '50%',
        top: '50%',
        cursor: 'auto'
    };
    const dispatch = useDispatch();
    const mapRef = useRef();
    const [results, setResults] = useState([]);
    const [geoCodeValue, setGeoCodeValue] = useState('');

    const onSelected = (newViewPort) => {
        const [lng, lat] = newViewPort.geometry.coordinates;
        setGeoCodeValue(newViewPort.place_name);
        setViewPort({
            ...viewport,
            latitude: lat,
            longitude: lng,
            address: newViewPort.place_name
        });
        setResults([]);
    };

    const onChangeInputValue = (value) => {
        setGeoCodeValue(value);
        geoCodeValue.trim() &&
            setTimeout(
                () =>
                    dispatch(geoGoderApi(geoCodeValue)).then((response) => {
                        setResults(response);
                    }),
                1000
            );
    };

    return (
        <Suspense
            fallback={
                <div
                    style={{
                        position: 'fixed',
                        top: '20%'
                    }}>
                    Map is Loading...
                </div>
            }>
            <ReactMapGL
                ref={mapRef}
                {...viewport}
                mapboxApiAccessToken={settings.MAPBOX_ACCESS_TOKEN}
                mapStyle={mapStyle.default}
                onViewportChange={(newViewPort) => {
                    const viewPort = showOnly
                        ? { ...viewport, zoom: newViewPort.zoom }
                        : newViewPort;
                    isCurrent && setIsCurrent(false);
                    setViewPort(viewPort);
                }}>
                {isCurrent && (
                    <div
                        className="mapboxgl-marker"
                        // @ts-ignore
                        style={currentPosMarkerStyle}>
                        <div
                            className="mapboxgl-user-location-dot"
                            style={{ transform: 'translate(-50%, -50%)' }}
                        />
                    </div>
                )}
            </ReactMapGL>
            {!showOnly && (
                <>
                    <div className="react-geocoder ">
                        <input
                            value={geoCodeValue}
                            onClick={() => {
                                isCurrent && setIsCurrent(false);
                            }}
                            onChange={(e) => onChangeInputValue(e.target.value)}
                            placeholder="Search a place..."
                        />
                    </div>
                    {!isEmpty(results) && (
                        <div className="react-geocoder-results">
                            {results.map((item) => (
                                <div
                                    key={item}
                                    className="react-geocoder-item"
                                    onClick={() => onSelected(item)}>
                                    {item.place_name}
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}
        </Suspense>
    );
};

export default withMemo(MapboxContainer);
