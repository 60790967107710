import { createSelector } from 'reselect';

export const PaymentProfileSelector = (state) => state.payment_profile;

export const getPaymentProfiles = createSelector(
    [PaymentProfileSelector],
    (state) => state
);

export const getDefaultPaymentProfile = createSelector(
    [PaymentProfileSelector],
    (state) => {
        let paymentProfiles = state.paymentProfiles;
        const defaultPaymentProfile =
            state.userPaymentProfile?.default_payment_profile;
        paymentProfiles = (paymentProfiles || []).find(
            (paymentProfile) =>
                paymentProfile?.resource_uri === defaultPaymentProfile
        );

        return paymentProfiles;
    }
);

export const getPaymentProfileList = createSelector(
    [PaymentProfileSelector],
    (state) => state.paymentProfiles
);
