// @ts-nocheck
import { closeDialog, openDialog } from 'common/utils/dialog-utils';
import React, { useCallback, useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
// Icons
import iconCloseSm from 'assets/images/icons_svg/icon_close-sm.svg';
import { useDispatch } from 'react-redux';
// Images
import upgradeImage from 'assets/images/settings/upgrade-power.jpg';
// Actions
import * as PaymentActions from 'redux/actions/paymentprofile';
import * as UserActions from 'redux/actions/user';
import UpgradeSuccessDialog from 'pages/Dialogs/UpgradeToProDialog/UpgradeSucessDialog';
import UpgradeFailDialog from 'pages/Dialogs/UpgradeToProDialog/UpgradeFailDialog';
import {
    PLAN_PRICES,
    PLAN_SUBSCRIPTION_PRICES,
    SUBSCRIPTIONS_FREE,
    SUBSCRIPTIONS_MONTHLY,
    SUBSCRIPTIONS_QUARTERLY,
    SUBSCRIPTIONS_YEARLY,
    SUBSCRIPTION_LABELS
} from 'common/constant';
// Selectors
import { getUser, getSelectedPlanDuretion } from 'redux/selectors';
import { setSelectedPlanDuration } from 'redux/actions/settings_page';
import { JRNL_PLANS } from 'ssot/publicJRNLSSOT';
import ExpirationDateInput from 'components/CreditCardForm/ExpirationDateInput';

// @ts-ignore
const UpgradeToProDialog = ({ user: authUser, selectedPlanDuration }) => {
    const [isSaving, setSaving] = useState(false); // indicates whether saving or not
    const [userFullName, setUserFullName] = useState(''); //
    const [creditCardNumber, setCreditCardNumber] = useState('');
    const [cvv, setCvv] = useState('');
    const dispatch = useDispatch();
    const [jsonPlans, setJsonPlans] = useState([]);

    const [expireDateTxt, setExpireDateTxt] = useState('');
    const [expireDateTxtError, setExpireDateTxtError] = useState(false);

    useEffect(() => {
        setJsonPlans(JRNL_PLANS.PublicJRNL.Plans.Plan);
    }, [jsonPlans, authUser]);

    const handleCheckout = useCallback(
        async (e) => {
            e.preventDefault();

            if (expireDateTxtError) {
                return;
            }

            setSaving(true);

            try {
                const response = await dispatch(
                    PaymentActions.create({
                        card_number: creditCardNumber,
                        cvv,
                        expiration_month: parseInt(expireDateTxt.split('/')[0]),
                        expiration_year: `20${expireDateTxt.split('/')[1]}`,
                        user_full_name: userFullName,
                        default: false
                    })
                );

                if (response?.payload.status === 201) {
                    await dispatch(
                        UserActions.subscribeToPro(
                            response?.payload.data.id,
                            selectedPlanDuration
                        )
                    );

                    dispatch(UserActions.fetch());

                    openDialog(UpgradeSuccessDialog);
                }
            } catch (e) {
                openDialog(UpgradeFailDialog);
            } finally {
                setSaving(false);
            }
        },
        [
            creditCardNumber,
            cvv,
            expireDateTxt,
            userFullName,
            dispatch,
            selectedPlanDuration,
            expireDateTxtError
        ]
    );

    const currentPlan = useCallback(() => {
        if (authUser.is_free && !authUser.is_ty) {
            return SUBSCRIPTIONS_FREE;
        } else if (authUser.is_free && authUser.is_ty) {
            return 'ty';
        } else if (
            (authUser.is_ty && !authUser.is_pro) ||
            authUser.subscription?.plan_name.includes('oyalty')
        ) {
            return 'ty';
        } else if (
            authUser.is_pro &&
            authUser.subscription?.plan_name.includes('monthly')
        ) {
            return SUBSCRIPTIONS_MONTHLY;
        } else if (
            authUser.is_pro &&
            authUser.subscription?.plan_name.includes('quarterly')
        ) {
            return SUBSCRIPTIONS_QUARTERLY;
        } else if (
            authUser.is_pro &&
            (authUser.subscription?.plan_name.includes('yearly') ||
                authUser.subscription?.plan_name.includes('Discounting') ||
                authUser.subscription?.plan_name.includes('fake pro plan'))
        ) {
            return SUBSCRIPTIONS_YEARLY;
        } else {
            return SUBSCRIPTIONS_FREE;
        }
    }, [authUser]);

    const isNewMember = useCallback(() => {
        const currentDate = moment();
        const dateJoined = moment(authUser.date_joined);
        if (
            currentDate.diff(dateJoined, 'day') <= 30 &&
            !authUser.is_pro &&
            !authUser.is_ty
        ) {
            return true;
        }
        return false;
    }, [authUser.date_joined, authUser.is_pro, authUser.is_ty]);

    const getDefaultTitle = (data) => {
        switch (data) {
            case SUBSCRIPTIONS_FREE:
                return '';
            case SUBSCRIPTIONS_MONTHLY:
                return <>${PLAN_PRICES.PRO_MONTHLY.DEFAULT}</>;
            case SUBSCRIPTIONS_QUARTERLY:
                return <>${PLAN_PRICES.PRO_QUARTERLY.DEFAULT}</>;
            case SUBSCRIPTIONS_YEARLY:
                if (
                    authUser.subscription?.plan_name?.includes('20') ||
                    (authUser.is_free && !authUser.is_ty)
                ) {
                    return (
                        <>
                            $
                            {
                                jsonPlans.filter((plan) => {
                                    return plan.Name === 'POWER JRNLer - 20%';
                                })[0]?.PlanPaymentOptions?.PlanPaymentOption[2]
                                    ?.Price
                            }
                        </>
                    );
                } else if (
                    authUser.subscription?.plan_name?.includes('50') ||
                    authUser.is_ty
                ) {
                    return (
                        <>
                            $
                            {
                                jsonPlans.filter((plan) => {
                                    return plan.Name === 'POWER JRNLer - 50%';
                                })[0]?.PlanPaymentOptions?.PlanPaymentOption[2]
                                    ?.Price
                            }
                        </>
                    );
                } else if (authUser.subscription?.plan_name?.includes('10')) {
                    return <> ${PLAN_PRICES.PRO_YEARLY.DEFAULT}</>;
                }
                return (
                    <>
                        $
                        {
                            jsonPlans.filter((plan) => {
                                return plan.Name === 'POWER JRNLer';
                            })[0]?.PlanPaymentOptions?.PlanPaymentOption[2]
                                .Price
                        }
                    </>
                );
            default:
                return;
        }
    };

    const hideFreeTrial = useCallback(() => {
        return !!authUser.subscription;
    }, [authUser.subscription]);
    const selectSubscription = useCallback(
        (duration) => {
            //   setfakeUserSubscriptions(duration)
            dispatch(setSelectedPlanDuration(duration));
        },
        [dispatch]
    );

    const renderLabel = useCallback(() => {
        const userName = authUser.short_display_name;
        let label;
        switch (selectedPlanDuration) {
            case SUBSCRIPTIONS_FREE:
                label = '';
                break;
            case SUBSCRIPTIONS_MONTHLY:
                label = SUBSCRIPTION_LABELS.MONTH;
                break;
            case SUBSCRIPTIONS_QUARTERLY:
                label = SUBSCRIPTION_LABELS.QUARTER;
                break;
            case SUBSCRIPTIONS_YEARLY:
                label = SUBSCRIPTION_LABELS.YEAR;
                break;
            default:
                break;
        }
        return (
            <p>
                {userName}, start your{' '}
                {hideFreeTrial() ? (
                    'subscription'
                ) : (
                    <>
                        {' '}
                        <span>1-{label} free trial</span>{' '}
                    </>
                )}{' '}
                now!
            </p>
        );
    }, [selectedPlanDuration, authUser, hideFreeTrial]);

    const renderPlanSubscription = useCallback(() => {
        return PLAN_SUBSCRIPTION_PRICES.filter(
            (price) => price.title === 'Free'
        ).map((price, index) => {
            return (
                <div
                    onClick={() => {
                        // setSelectsubsctiption(price.title)
                        selectSubscription(price.title);
                    }}
                    className={`subscriptions-title-box ${
                        price.title === selectedPlanDuration ? 'active' : ''
                    }`}
                    key={index}>
                    <b>{price.title}</b>
                    <p>${price.value}</p>
                </div>
            );
        });
    }, [selectedPlanDuration, selectSubscription]);

    const renderPlanSubscriptionV2 = useCallback(() => {
        return jsonPlans
            .filter((plan) => {
                return plan.Name === 'POWER JRNLer';
            })[0]
            ?.PlanPaymentOptions?.PlanPaymentOption?.map((option, index) => (
                <div
                    onClick={() => {
                        selectSubscription(option.Duration);
                    }}
                    className={`subscriptions-title-box ${
                        selectedPlanDuration === option.Duration ? 'active' : ''
                    }`}
                    key={index}>
                    <b>{option.Duration}</b>
                    <p>
                        {isNewMember() &&
                        currentPlan() !== 'ty' &&
                        option.Duration === 'Annually' ? (
                            <>
                                <span id="cross-price-tab">
                                    ${option.Price}
                                </span>
                                -$
                                {
                                    jsonPlans.filter((plan) => {
                                        return (
                                            plan.Name === 'POWER JRNLer - 20%'
                                        );
                                    })[0]?.PlanPaymentOptions
                                        ?.PlanPaymentOption[2].Price
                                }
                            </>
                        ) : currentPlan() === 'ty' &&
                          option.Duration === 'Annually' ? (
                            <>
                                <span id="cross-price-tab">
                                    ${option.Price}
                                </span>
                                -$
                                {
                                    jsonPlans.filter((plan) => {
                                        return (
                                            plan.Name === 'POWER JRNLer - 50%'
                                        );
                                    })[0]?.PlanPaymentOptions
                                        ?.PlanPaymentOption[2].Price
                                }
                            </>
                        ) : (
                            <> ${option.Price}</>
                        )}{' '}
                    </p>
                </div>
            ));
    }, [
        selectedPlanDuration,
        selectSubscription,
        jsonPlans,
        isNewMember,
        currentPlan
    ]);

    return (
        <div id="upgrade-pro-checkout-modal" className="modal-frame">
            <div className="modal-box modal-box--600">
                <div className="modal__header">
                    {renderLabel()}
                    <button
                        className="modal__close btn-icon btn-icon--3"
                        onClick={closeDialog}>
                        <img src={iconCloseSm} alt="" />
                    </button>
                </div>
                <div className="settings-title-section">
                    {renderPlanSubscription()}
                    {renderPlanSubscriptionV2()}
                </div>

                <div className="modal__body pb-3">
                    <div className="col-12 upgrade-pro-checkout-card">
                        <div className="plan-info">
                            <div className="plan-info__img">
                                <img src={upgradeImage} alt="" />
                            </div>
                            <div className="plan-info__copy">
                                <div className="plan-info__title">
                                    {selectedPlanDuration}
                                </div>
                                <div className="plan-info__desc">
                                    Express your thoughts with audio and video,
                                    and share it with others
                                </div>
                            </div>
                        </div>
                        <form className="upgrade-form">
                            <h3 className="settings-list-title section-title">
                                Credit Card Details
                            </h3>
                            <div className="form-group settings-inputs">
                                <div className="form-group__row">
                                    <div className="form-group__item form-group__item--6">
                                        <label
                                            className="settings-label"
                                            htmlFor="cname">
                                            Name on Card
                                        </label>
                                        <input
                                            className="settings-input"
                                            type="text"
                                            id="cname"
                                            name="cardname"
                                            placeholder="Cardholder Name"
                                            value={userFullName}
                                            onChange={(e) =>
                                                setUserFullName(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="form-group__item form-group__item--6">
                                        <label
                                            className="settings-label"
                                            htmlFor="cnumber">
                                            Card Number
                                        </label>
                                        <input
                                            className="settings-input"
                                            type="text"
                                            id="cnumber"
                                            name="cardnumber"
                                            placeholder="0000 0000 0000 0000"
                                            value={creditCardNumber}
                                            onChange={(e) =>
                                                setCreditCardNumber(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="form-group__row form-group__row--exp-cvv">
                                    <ExpirationDateInput
                                        expireDateTxt={expireDateTxt}
                                        setExpireDateTxt={setExpireDateTxt}
                                        expireDateTxtError={expireDateTxtError}
                                        setExpireDateTxtError={
                                            setExpireDateTxtError
                                        }
                                    />

                                    <div className="form-group__item">
                                        <label
                                            className="settings-label"
                                            htmlFor="cvv">
                                            CVV
                                        </label>
                                        <div className="form-group__cvv">
                                            <input
                                                className="settings-input"
                                                type="text"
                                                id="cvv"
                                                name="cvv"
                                                placeholder="000"
                                                value={cvv}
                                                onChange={(e) =>
                                                    setCvv(e.target.value)
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="purchase-table">
                                    <div className="purchase-table__item">
                                        <div className="purchase-table__label">
                                            <div>
                                                Total amount to be charged:
                                            </div>
                                        </div>
                                        <div className="purchase-table__value">
                                            {' '}
                                            {getDefaultTitle(
                                                selectedPlanDuration
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group__row">
                                    <button
                                        disabled={isSaving}
                                        onClick={handleCheckout}
                                        className="btn btn-wide btn-gradient btn--md">
                                        {isSaving && (
                                            <span className="saving-text pr-2">
                                                <span className="saving-gif pr-3"></span>
                                                Subscribing...
                                            </span>
                                        )}
                                        Checkout
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
const state = createStructuredSelector({
    user: getUser,
    selectedPlanDuration: getSelectedPlanDuretion
});
export default connect(state)(UpgradeToProDialog);
