import FreeAccountPermissionDialog from '../../pages/Dialogs/ErrorModals/FreeAccountPermissionDialog';

export const iosMediaPermission = (dialog) => {
    if (
        window.location.href.includes('capacitor://') ||
        window.location.href.includes('ionic://')
        // || window.location.href.includes('localhost')
    ) {
        return FreeAccountPermissionDialog;
    } else {
        return dialog;
    }
};
