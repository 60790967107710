import _ from 'underscore';
import { SOCIAL_TYPE_FACEBOOK } from 'common/constant';
import { UserSelector } from 'redux/selectors/UserSelector';
import { createSelector } from 'reselect';

export const FacebookSelector = (state) => state.facebook;

export const getFacebookState = createSelector(
    [FacebookSelector],
    (facebook) => facebook
);

export const getFacebookProfile = createSelector([UserSelector], (user) => {
    const { social_connects } = user;

    const social_connect = _.find(
        social_connects,
        (item) => item.provider === SOCIAL_TYPE_FACEBOOK
    );
    return social_connect;
});

export const getFacebookMedia = createSelector(
    [FacebookSelector],
    (facebook) => facebook.facebook_media
);

export const getFacebookMediaPaging = createSelector(
    [FacebookSelector],
    (facebook) => facebook.paging
);
