import buildAuthorization from 'common/utils/buildAuthorization';

import { UserSelector } from 'redux/selectors/UserSelector';

import {
    FETCH_BOOK_SHIPPING_ADDRESS_LIST,
    CREATE_BOOK_SHIPPING_ADDRESS,
    UPDATE_BOOK_SHIPPING_ADDRESS
} from 'redux/actions/actionTypes';
import { BOOK_SHIPPING_ADDRESS_URL } from 'common/constant';

export const fetch = (options = {}, pageUrl) => (dispatch, getState) => {
    const user = UserSelector(getState());
    const url = pageUrl || BOOK_SHIPPING_ADDRESS_URL;
    const auth = buildAuthorization(user);

    return dispatch({
        type: FETCH_BOOK_SHIPPING_ADDRESS_LIST,
        payload: {
            request: {
                url,
                method: 'GET',
                params: options,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const create = (data) => (dispatch, getState) => {
    const user = UserSelector(getState());
    const url = BOOK_SHIPPING_ADDRESS_URL;
    const auth = buildAuthorization(user);

    return dispatch({
        type: CREATE_BOOK_SHIPPING_ADDRESS,
        payload: {
            request: {
                url,
                method: 'POST',
                data,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const update = (data) => (dispatch, getState) => {
    const user = UserSelector(getState());
    const url = `${BOOK_SHIPPING_ADDRESS_URL}${data.id}/`;
    const auth = buildAuthorization(user);

    return dispatch({
        type: UPDATE_BOOK_SHIPPING_ADDRESS,
        payload: {
            request: {
                url,
                method: 'PATCH',
                data,
                options: {
                    id: data.id,
                    hidden: data.hidden
                },
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};
