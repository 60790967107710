import React, { useEffect, useCallback } from 'react';
import EntryCreateAAM from '../Entry/EntryCreateAAM';

import { connect } from 'react-redux';

// selectors
import { createStructuredSelector } from 'reselect';
import {
    getRandomQuestion,
    getAamJournal,
    getJournalList
} from 'redux/selectors';
import * as JournalActions from 'redux/actions/journal';
import * as AllAboutMeActions from 'redux/actions/allaboutme';
import { NavLink } from 'react-router-dom';
import { URLS } from 'common/urls';

// assets
import jrnlPreloader from 'assets/images/preloader.gif';
import iconAngleLeft from 'assets/images/icons_svg/icon_angle-left.svg';

const AllAboutMeRandomQuestion = ({ question, aamJournal, dispatch }) => {
    useEffect(() => {
        // clear random question
        dispatch(AllAboutMeActions.clearRandomQuestion());
        dispatch(JournalActions.fetch());
        dispatch(AllAboutMeActions.fetchRandomQuestion());
    }, [dispatch]);

    useEffect(() => {
        dispatch(JournalActions.fetch());
    }, [dispatch]);

    const onSave = useCallback(
        (finish = false) => {
            if (finish === true) {
                dispatch(AllAboutMeActions.fetchRandomQuestion());
            }
        },
        [dispatch]
    );

    return (
        <div className="page-container">
            {!question || !aamJournal ? (
                <section className="loading">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-auto loading-img">
                                <img
                                    src={jrnlPreloader}
                                    alt="loading"
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                <>
                    <section className="back-to-all-about-me">
                        <div className="back-to-all-about-me-content">
                            <NavLink to={URLS.AAM.ROOT}>
                                <img
                                    src={iconAngleLeft}
                                    alt=""
                                    className="icon-static btn-icon--6"
                                />
                                Back to All About Me
                            </NavLink>
                        </div>
                    </section>
                    <EntryCreateAAM
                        questionId={question?.id}
                        _onSave={onSave}
                    />
                </>
            )}
        </div>
    );
};

const state = createStructuredSelector({
    question: getRandomQuestion,
    aamJournal: getAamJournal,
    journal: getJournalList
});
export default connect(state)(AllAboutMeRandomQuestion);
