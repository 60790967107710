import React, { useEffect } from 'react';
// redux
import { connect } from 'react-redux';
// selecor
import { createStructuredSelector } from 'reselect';
import { getUI } from 'redux/selectors';
// Actions
import { updateUI } from 'redux/actions/ui';
// components
import { RadioList } from 'components';
import AccountStatus from './AccountStatus';
// constant
import { BOOK_EXPORT_FORMAT } from 'common/constant';

// book types constants
const bookTypes = [
    {
        label: '6" x 9" Printed Book (20-900 page limit)',
        value: BOOK_EXPORT_FORMAT['6_9_FORMAT']
    },
    {
        label: 'PDF Download Only',
        value: BOOK_EXPORT_FORMAT['PDF']
    }
];

const ExportFormatStep = ({ display, dispatch, ui }) => {
    // radio values
    const radioValues = React.useMemo(
        () =>
            bookTypes.map((type) => ({
                ...type,
                checked: ui.export_format === type.value
            })),
        [ui.export_format]
    );

    const onChangeBookType = (status) => {
        // update export format
        dispatch(
            updateUI({
                export_format: status
            })
        );
    };

    // component did mount
    useEffect(() => {
        if (display === true) {
            dispatch(
                updateUI({
                    canGoNext: true
                })
            );
        }
        return () => {};
    }, [dispatch, display]);

    return (
        <div
            className={`new-book-step-1 new-book-step ${
                display ? 'active-form' : ''
            }`}>
            <div className="section-title">Export Format</div>
            <div className="new-book-step-form">
                <p>Printed books include a downloadable PDF after purchase.</p>
                <div className="form-group">
                    <RadioList
                        radios={radioValues}
                        onUpdate={(type) => onChangeBookType(type)}
                    />
                </div>
                <AccountStatus />
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    ui: getUI
});
export default connect(state)(ExportFormatStep);
