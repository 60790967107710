import _ from 'underscore';
import { createSelector } from 'reselect';

export const TimelineApprovalSelector = (state) => state.timeline_approval;

export const getBlockedUsers = createSelector(
    [TimelineApprovalSelector],
    (state) => {
        const { timelineApprovals } = state;
        let blockedUsers;

        if (timelineApprovals && !_.isEmpty(timelineApprovals)) {
            blockedUsers = timelineApprovals.filter(
                (timeline_approval) => !timeline_approval.approval_status
            );
            blockedUsers = _.pluck(blockedUsers, 'sharing_user');
        }
        return blockedUsers;
    }
);
