import React, { memo, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import MapboxContainer from 'components/mapbox-container';
import { useDispatch, useSelector } from 'react-redux';
import { getEntryLocation } from 'redux/selectors/EntrySelector';
import settings from 'common/configs/setting';
import { getEntryPositionFeatures } from 'redux/actions/entry';
// Icons
import iconCloseSm from 'assets/images/icons_svg/icon_close-sm.svg';
import iconLocate from 'assets/images/icons_svg/icon_locate.svg';
import { checkResponseSuccess } from 'common/utils/responseUtils';
import { isEmpty } from 'common/utils/arrayUtils';

export const AddLocationDialog = memo(
    ({
        open,
        close,
        entry,
        setEntryLocation,
        canDelete,
        showOnly,
        current,
        setCurrent
    }) => {
        const dispatch = useDispatch();
        const entryPosition = useSelector(getEntryLocation);
        const [isCurrent, setIsCurrent] = useState(false);
        const getCurrentLocation = () => {
            navigator.geolocation.getCurrentPosition((currentPos) => {
                setViewPort({
                    ...viewport,
                    latitude: currentPos.coords.latitude,
                    longitude: currentPos.coords.longitude
                });
            });
            setIsCurrent(true);
        };

        const [viewport, setViewPort] = useState({
            ...current,
            latitude: current?.latitude || settings.map_default_coords.latitude,
            longitude:
                current?.longitude || settings.map_default_coords.longitude,
            width: current?.width || '100%',
            height: current?.height || '100%',
            zoom: current?.zoom || 10
        });
        const onSaveEntryLocation = () => {
            dispatch(
                getEntryPositionFeatures({
                    lat: viewport.latitude,
                    lng: viewport.longitude
                })
            ).then((response) => {
                if (checkResponseSuccess(response.status)) {
                    const { features } = response.data;
                    if (isEmpty(features)) {
                        const text = features
                            .map(function (item) {
                                return item.text;
                            })
                            .join(',');
                        setEntryLocation &&
                            setEntryLocation((prev) => {
                                return { ...prev, address: text };
                            });
                    }
                }
            });
            setEntryLocation &&
                setEntryLocation({
                    address: viewport.address,
                    lat: viewport.latitude,
                    lng: viewport.longitude
                });
            setCurrent &&
                setCurrent({
                    ...current,
                    ...viewport
                });
            close();
        };
        const onDeleteLocation = () => {
            setEntryLocation({ lat: '', lng: '', address: '' });
            close();
        };
        useEffect(() => {
            if (entryPosition && !isEmpty(entryPosition)) {
                setViewPort({
                    ...viewport,
                    latitude: entryPosition.lat,
                    longitude: entryPosition.lng
                });
            }
            // eslint-disable-next-line
        }, [entryPosition]);
        const clearAndClose = () => {
            close();
        };
        return (
            <Dialog
                open={open}
                fullWidth={true}
                onClose={close}
                style={{ zIndex: 9999999 }}>
                <div id="location-modal" className="modal-frame">
                    <div className="modal-box modal-box--map modal-box--800">
                        <div className="modal__header">
                            {canDelete ? 'Edit Location' : 'Add Location'}
                            <img
                                className="modal__close"
                                onClick={clearAndClose}
                                src={iconCloseSm}
                                alt=""
                            />
                        </div>
                        <div className="modal__body location-modal-content-map">
                            <MapboxContainer
                                showOnly={showOnly}
                                entry={entry}
                                viewport={viewport}
                                setViewPort={setViewPort}
                                isCurrent={isCurrent || current}
                                setIsCurrent={setIsCurrent}
                            />
                        </div>
                        <div className="modal__footer">
                            <button
                                className="btn btn-outline"
                                onClick={getCurrentLocation}
                                disabled={showOnly}>
                                Locate Me
                                <img
                                    src={iconLocate}
                                    alt=""
                                    className="right"
                                />
                            </button>
                            {canDelete && (
                                <button
                                    className="btn btn-delete"
                                    onClick={onDeleteLocation}>
                                    Delete Location
                                </button>
                            )}
                            <button
                                className="btn btn-gradient"
                                onClick={onSaveEntryLocation}
                                disabled={showOnly}>
                                {canDelete ? 'Save' : 'Save'}
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }
);
