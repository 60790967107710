import React, { useCallback } from 'react';
// Actions
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getUI } from 'redux/selectors';

// Components
import Dialog from '@material-ui/core/Dialog';

const EntrySaveIssueDialog = ({
    close,
    entry,
    ui,
    dispatch,
    onUpdate,
    open,
    errorMsg
}) => {
    const _close = useCallback(() => {
        if (close) {
            close();
        }
    }, [close]);

    return (
        <Dialog
            fullWidth={true}
            open={open}
            onClose={_close}
            aria-labelledby="form-dialog-title">
            <div
                id="delete-modal"
                className="modal-box modal-box--delete modal-box--500">
                <div className="delete-modal">
                    <div className="delete-modal__content">
                        <div className="delete-modal__title">{errorMsg}</div>
                        <div className="delete-modal__subtitle"></div>
                    </div>
                    <div className="delete-modal__actions">
                        <button className="btn btn-outline" onClick={_close}>
                            Cancel
                        </button>
                        <button className="btn btn-delete" onClick={_close}>
                            Retry
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
const state = createStructuredSelector({
    ui: getUI
});
export default connect(state)(EntrySaveIssueDialog);
