import React, {
    Fragment,
    useCallback,
    useEffect,
    useRef,
    useState
} from 'react';
import moment from 'moment';
import { createStructuredSelector } from 'reselect';
// @ts-ignore
import { connect } from 'react-redux';
// @ts-ignore
import { stateToHTML } from 'addon-draft-js-export-html';
// @ts-ignore
import { stateFromHTML } from 'addon-draft-js-import-html';
import ExtendedRichUtils, {
    ALIGNMENT_DATA_KEY,
    INDENT_DATA_KEY
} from 'components/Editor/utils/ExtendedRichUtils';
// @ts-ignore
import { EditorState, Modifier } from 'addon-draft-js';
import DraftJSEditor from 'components/Editor/components/DraftJSEditor/DraftJSEditor';
// import _ from 'underscore';
import JournalDropdownList from 'components/JournalDropdownList/JournalDropdownList';
import TagDropdownList from 'components/TagDropdownList/TagDropdownList';
import {
    getDefaultJournal,
    getFontSize as getFontSizeSelector,
    getJournalList,
    getTagsForEntry,
    getUser,
    getUI
} from 'redux/selectors';
import LinkButton from 'components/Editor/components/Buttons/Link';
import LinkDecorator from 'components/Editor/decorators/LinkDecorator';

// Dialogs
import EditImageDataDialog from 'pages/Dialogs/EditImageDataDialog';

// Actions
import * as InvitationActions from 'redux/actions/invitation';

// Components
import { styleMap } from 'components/Editor/components/Buttons/Fonts/FontsDropdown';
import ShareDialog from 'pages/Dialogs/InvitationDialog/ShareDialog';
import { DropDown, InvitationListButton } from 'components';
import AAMCategoryImage from 'common/utils/images/AAMCategoryImage';
// @ts-ignore
import { useLocation } from 'react-router-dom';
import { AddLocationDialog } from 'pages/Dialogs/add-location-dialog';

import * as EntryActions from 'redux/actions/entry';

// Selectors
import { getEntryInvitations } from 'redux/selectors/InvitationSelector';

// Constants
import {
    DEFAULT_DATETIME_FORMAT,
    ENTRY_QA,
    ENTRY_SHOWN,
    FONT_SIZE_USE_TYPE,
    options
} from 'common/constant';
import { URLS } from 'common/urls';

import EntryEditorTitle from './Entities/EntryEditorTitle';
import ImportFileDialog from 'pages/Dialogs/ImportFileDialog';
// @ts-ignore
import { useParams } from 'react-router';
import getFontSize from 'common/utils/getFontSize';

// Icons
import iconFormatting from 'assets/images/icons_svg/icon_formatting.svg';
import iconAttachment from 'assets/images/icons_svg/icon_attachment.svg';
import iconPin from 'assets/images/icons_svg/icon_pin.svg';
import iconLock from 'assets/images/icons_svg/icon_lock.svg';
import iconCaretDown from 'assets/images/icons_svg/icon_caret-down.svg';
import iconCheckSm from 'assets/images/icons_svg/icon_check-sm.svg';
import iconShare from 'assets/images/icons_svg/icon_share.svg';

// Decoration
import InputLabel from '@material-ui/core/InputLabel';
// @ts-ignore
import OutsideClickHandler from 'react-outside-click-handler/esm/OutsideClickHandler';
import { setEntryPosition } from 'redux/actions/entry';
import { getFilters } from 'redux/selectors/FiltersSelector';
// import withFreeAccountCheck from 'common/utils/components/withFreeAccountCheck';
// import * as EditorActions from 'redux/actions/editor';
import { openDialog } from 'common/utils/dialog-utils';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import arrayUtils from 'common/utils/arrayUtils';
// import { updateUI } from 'redux/actions/ui';
import { entryShared } from 'common/utils/entry_utils';
import withFreeAccountCheck from 'common/utils/components/withFreeAccountCheck';
import { iosMediaPermission } from '../../media/Permission';
import EntryTile from 'components/Entry/EntryTile';

// TODO: move to utils
const getDefaultDate = (date, format) => {
    return format
        ? moment(date, format).utc().format(DEFAULT_DATETIME_FORMAT)
        : moment(date).utc().format(DEFAULT_DATETIME_FORMAT);
};

const EntryEditor = ({
    isEdit,
    onCancel,
    didMount,
    setExactJournal,
    entry,
    onChange,
    onSave,
    question,
    selectedTags,
    journal,
    onEntryTitleUpdate,
    onInsertImage,
    onInsertInstagram,
    onInsertFacebook,
    onInsertGoogle,
    onEntryDateClick,
    user,
    // @ts-ignore
    displayMode,
    isSaving,
    onEntryJournalUpdate,
    onEntryTagUpdate,
    autoSave,
    isSaved,
    dispatch,
    entryInvitations,
    userFontSize,
    allJournals,
    created,
    filters,
    me,
    defaultJournal,
    canSave,
    isError,
    ui
}) => {
    const location = useLocation();
    const { journalId } = useParams();
    const [entry_title, setEntryTitle] = useState(entry?.title || '');
    const [date, setDate] = useState(
        moment.utc(entry?.entry_date).local() || moment().local()
    );
    const [contentIsRendered, setContentIsRendered] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [showEditButtons, setShowEditButtons] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [invitations, setInvitations] = useState([]);
    const contentRef = useRef(null);
    const editorRef = useRef(null); // ref to editor container
    const editorStateRef = useRef(null);
    const [extraJournalTittle, setExtraJournalTittle] = useState(null);
    const titleRef = useRef(null);
    const categoryName = question?.category?.name;
    const [showJournalList, setShowJournalList] = useState(false);
    const [entryLocation, setEntryLocation] = useState({
        lat: '',
        lng: '',
        address: ''
    });
    const [editorDate, setEditorDate] = useState('');
    const isOnEdit = window.location.href.includes('entry/edit/');
    // const [entryDateChangeFlag, setEntryDateChangeFlag] = React.useState(false);

    const entryIDRef = React.useRef(entry?.id);

    // shared users
    const [sharedUsers, setSharedUsers] = useState([]);

    // extra journals
    const [extraJournals, setExtraJournals] = useState(
        arrayUtils.getSetOfArray(
            created
                ? [
                      ...(entry.extra_journals || []),
                      ...(entry.journal ? [entry.journal] : [])
                  ]
                : [
                      ...(journal
                          ? journal
                          : defaultJournal
                          ? [defaultJournal]
                          : [])
                  ],
            (array, item) => array.find((obj) => obj.id === item.id)
        )
    );

    const editorEntryInvitations = React.useMemo(
        () =>
            (entry?.id && invitations.length > 0 ? invitations : sharedUsers) ||
            [],
        [entry, invitations, sharedUsers]
    );

    useDeepCompareEffectNoCheck(() => {
        if (!created) {
            setExtraJournals([
                ...(journal ? journal : defaultJournal ? [defaultJournal] : [])
            ]);
        }
    }, [journal, defaultJournal, created]);

    const saveEntryDraft_To_LS = useCallback((data, id) => {
        const obj = JSON.stringify(data);
        if (!id || id === undefined || id === null) {
            localStorage.setItem('entry-draft', obj);
        } else {
            localStorage.setItem(`entry-draft-${id}`, obj);
        }
    }, []);

    const saveEntryDraftTitle_To_LS = useCallback((data, id) => {
        const obj = JSON.stringify(data);
        if (!id || id === undefined || id === null) {
            localStorage.setItem('entry-draft-title', obj);
        } else {
            localStorage.setItem(`entry-draft-title-${id}`, obj);
        }
    }, []);

    useDeepCompareEffectNoCheck(() => {
        if (entryIDRef.current !== entry.id) {
            entryIDRef.current = entry.id;
        }
    }, [entry]);

    // emotion
    const [emotion, setEmotion] = useState(null);

    // TODO: Let's remove ui in this components
    // 1. ui.shared_users: Done
    // 2. ui.extra_journals
    // 3. emotion

    useEffect(() => {
        const entry_ = entry?.id ? entry : ui.newEntry;
        const date_ = entry_?.dateChanged
            ? entry?.id
                ? moment(entry.entry_date)
                : moment(ui.newEntry.entry_date)
            : date;

        const date_string = moment().isSame(date_, 'day')
            ? 'Today'
            : entry_.display_time
            ? moment(date_).format('MMM D, YYYY, h:mma')
            : moment(date_).format('MMM D, YYYY');

        const date_string_edit = entry_.display_time
            ? moment(date_).format('MMM D, YYYY, h:mma')
            : moment(date_).format('MMM D, YYYY');

        setEditorDate(isEdit ? date_string_edit : date_string);
        // setEditorDate(date_string);
    }, [filters, date, entry, ui.newEntry, isEdit]);

    const onOpenLocationDialog = React.useCallback(() => {
        // Removed updateUI
        openDialog(AddLocationDialog, { entry, setEntryLocation });
    }, [entry]);

    const onImageDataUpdate = (contentState, image, imageData) => {
        const newEditorState = ExtendedRichUtils.toggleImageContentChange(
            contentState,
            image,
            imageData
        );
        setEditorState(newEditorState);
    };

    const onShowEditButtons = useCallback(() => {
        setShowEditButtons(!showEditButtons);
    }, [showEditButtons]);

    const onEditImage = useCallback(
        (contentState, image, imageCaption, title = 'Image') => {
            // Done: remove updateUI
            openDialog(EditImageDataDialog, {
                onUpdate: onImageDataUpdate,
                contentState,
                image,
                imageData: imageCaption,
                title
            });
        },
        []
    );

    const onDeleteImage = useCallback((contentState, image) => {
        const newEditorState = ExtendedRichUtils.toggleImageDelete(
            contentState,
            image
        );
        setEditorState(newEditorState);
    }, []);

    const getContentFromState = useCallback((state) => {
        const options = {
            inlineStyleFn: (styles) => {
                const key = 'font-';
                const fontFamily = styles
                    .filter((value) => value?.startsWith(key))
                    .last();

                if (fontFamily) {
                    return {
                        element: 'span',
                        style: {
                            ...styleMap[fontFamily]
                        }
                    };
                }
            },
            blockStyleFn: (block) => {
                let blockStyle = {};
                if (block.getData().get(INDENT_DATA_KEY)) {
                    blockStyle = {
                        attributes: {
                            offset: block.getData().get(INDENT_DATA_KEY)
                        },
                        style: {
                            paddingLeft: block.getData().get(INDENT_DATA_KEY)
                        }
                    };
                }
                if (block.getData().get(ALIGNMENT_DATA_KEY)) {
                    const textAlignStyle = block
                        .getData()
                        .get(ALIGNMENT_DATA_KEY);
                    switch (textAlignStyle) {
                        case 'right': {
                            blockStyle = {
                                attributes: {
                                    ...blockStyle.attributes,
                                    //class: 'align-right'
                                    style: 'text-align: right;'
                                },
                                ...blockStyle
                            };
                            break;
                        }
                        case 'center': {
                            blockStyle = {
                                attributes: {
                                    ...blockStyle.attributes,
                                    // class: 'align-center',
                                    style: 'text-align: center;'
                                },
                                ...blockStyle
                            };
                            break;
                        }
                        case 'left': {
                            blockStyle = {
                                attributes: {
                                    ...blockStyle.attributes,
                                    // class: 'align-left'
                                    style: 'text-align: left'
                                },
                                ...blockStyle
                            };
                            break;
                        }
                        case 'justify': {
                            blockStyle = {
                                attributes: {
                                    ...blockStyle.attributes,
                                    // class: 'align-justify'
                                    style: 'text-align: justify'
                                },
                                ...blockStyle
                            };
                            break;
                        }
                        default: {
                            break;
                        }
                    }
                }
                return blockStyle;
            }
        };

        return state && stateToHTML(state.getCurrentContent(), options);
    }, []);

    const canSaveEntry = useCallback(() => {
        if (!editorState) {
            return false;
        }
        const hasMedia =
            Boolean(editorState) &&
            Boolean(
                stateToHTML(editorState.getCurrentContent()).includes('figure')
            );
        const title = (titleRef.current && titleRef.current.value) || '';
        const contentState = !(
            ((entry.entry_type === ENTRY_QA || question) &&
                editorState.getCurrentContent().hasText()) ||
            (editorState.getCurrentContent().hasText() &&
                !!editorState.getCurrentContent().getPlainText().trim()) ||
            hasMedia
        );
        const titleState = title === '' ? true : false;
        return contentState && titleState;
    }, [editorState, entry, question]);

    const canAutoSaveEntry = useCallback(
        (state) => {
            const title = (titleRef.current && titleRef.current.value) || '';
            const hasMedia = editorState
                ? !!stateToHTML(editorState.getCurrentContent()).includes(
                      'figure'
                  )
                : false;
            return (
                (!!editorState.getCurrentContent().getPlainText().trim() &&
                    editorState.getCurrentContent().hasText() &&
                    (title !== entry_title ||
                        getContentFromState(editorStateRef.current) !==
                            getContentFromState(state))) ||
                hasMedia
            );
        },
        [entry_title, getContentFromState, editorState]
    );

    const deleteLocalDrafts = useCallback(() => {
        if (
            created &&
            (entry.id !== undefined || entry.id !== null || entry.id !== '')
        ) {
            localStorage.removeItem(`entry-draft-${entry.id}`);
            localStorage.removeItem(`entry-draft-title-${entry.id}`);
        } else {
            localStorage.removeItem('entry-draft');
            localStorage.removeItem('entry-draft-title');
        }
    }, [created, entry.id]);

    const _onSave = useCallback(
        // @ts-ignore
        (ev, finish = true) => {
            const entryType = entry.entry_type;
            //let tags = undefined;

            if (
                (finish === true && canSaveEntry()) ||
                (finish === false && !canAutoSaveEntry())
            ) {
                return true;
            }
            const content = getContentFromState(editorState);
            const entryLocationStr = String(
                entryLocation.lat &&
                    entryLocation.lng &&
                    entryLocation.address &&
                    `${String(entryLocation.lat)};${String(
                        entryLocation.lng
                    )};${String(entryLocation.address)}`
            );
            const _entry = entry.question
                ? {
                      content,
                      state: ENTRY_SHOWN,
                      id: entry?.id,
                      title: question.text,
                      entry_type: entryType
                  }
                : {
                      title: titleRef?.current?.value || question?.text || '',
                      content,
                      tags: selectedTags,
                      question: question && question.id,
                      entry_type: entryType,
                      id: entry?.id,
                      emotion,
                      state: ENTRY_SHOWN,
                      location: entryLocationStr,
                      entry_date:
                          editorDate === 'Today'
                              ? editorDate
                              : entry.display_time
                              ? getDefaultDate(editorDate, 'MMM D, YYYY, h:mma')
                              : getDefaultDate(editorDate, 'MMM D, YYYY')
                  };
            if (onSave) {
                onSave(_entry, finish, extraJournals, sharedUsers);

                if (finish) {
                    // user click save button
                    setEntryLocation({
                        lat: '',
                        lng: '',
                        address: ''
                    });
                    setEntryTitle('');
                    saveEntryDraftTitle_To_LS('');
                    // TODO: remove updateUI
                    setEmotion(null);
                    // setEntryDateChangeFlag(false);
                    setDate(
                        moment.utc(entry?.entry_date).local() ||
                            moment().local()
                    );
                    deleteLocalDrafts();
                }
            }
            dispatch(EntryActions.fetch());
            if (finish === true) {
                setLoaded(false);
            }
        },
        [
            dispatch,
            saveEntryDraftTitle_To_LS,
            deleteLocalDrafts,
            entryLocation.address,
            entryLocation.lat,
            entryLocation.lng,
            onSave,
            editorState,
            entry,
            setEntryTitle,
            question,
            selectedTags,
            getContentFromState,
            canSaveEntry,
            emotion,
            canAutoSaveEntry,
            extraJournals,
            sharedUsers,
            editorDate
        ]
    );

    const _onInsertImage = useCallback(() => {
        if (onInsertImage) {
            onInsertImage();
        }
    }, [onInsertImage]);

    const onInsertLink = useCallback(
        (linkObj) => {
            const url = linkObj?.url.includes('https')
                ? linkObj?.url
                : `https://${linkObj?.url}`;
            if (linkObj.isNew === true) {
                const content = stateToHTML(
                    ExtendedRichUtils.toggleAddLink(
                        editorState,
                        linkObj?.text || linkObj?.value,
                        url
                    ).getCurrentContent()
                );
                const contentState = stateFromHTML(content);
                setEditorState(() =>
                    EditorState.createWithContent(contentState, LinkDecorator)
                );
            } else {
                setEditorState((prevState) =>
                    ExtendedRichUtils.toggleInsertLink(prevState, linkObj)
                );
            }
        },
        [editorState]
    );

    const [hasLoadedLocalEntry, setHasLoadedLocalEntry] = useState(false);

    const onEditorChange = useCallback(
        (state) => {
            state && setEditorState(state);

            if (state.getCurrentContent().getPlainText().length > 0) {
                const content = getContentFromState(state);
                saveEntryDraft_To_LS(content, entry.id);
            }

            if (
                onChange &&
                canAutoSaveEntry(state) &&
                state?.getCurrentContent().getPlainText()
            ) {
                editorStateRef.current = state;
                onChange();
            }
        },
        [
            onChange,
            // editorState,
            canAutoSaveEntry,
            getContentFromState,
            saveEntryDraft_To_LS,
            entry.id
        ]
    );

    const handlePastedText = useCallback(
        (text, html, editorState) => {
            const newText = text;
            const content = Modifier.replaceText(
                editorState.getCurrentContent(),
                editorState.getSelection(),
                newText
            );
            const newState = EditorState.push(
                editorState,
                content,
                'insert-fragment'
            );
            onEditorChange(newState);

            return 'handled';
        },
        [onEditorChange]
    );

    const [hasLocalChanges, setHasLocalChanges] = useState(false);
    const loadLocalDraft = useCallback(() => {
        var entryLocalDraft =
            JSON.parse(localStorage.getItem('entry-draft')) || null;
        // if (`${entryLocalDraft}`.length > 10000) {
        //     entryLocalDraft = entryLocalDraft.substring(0, 10000);
        // }
        let entryLocalTitle =
            JSON.parse(localStorage.getItem('entry-draft-title')) || '';

        if (
            created &&
            (entry.id !== undefined || entry.id !== null || entry.id !== '')
        ) {
            entryLocalDraft =
                JSON.parse(localStorage.getItem(`entry-draft-${entry.id}`)) ||
                null;
            entryLocalTitle =
                JSON.parse(
                    localStorage.getItem(`entry-draft-title-${entry.id}`)
                ) || '';
        }
        if (!hasLoadedLocalEntry && entryLocalDraft) {
            setHasLocalChanges(true);

            contentRef.current = entryLocalDraft;
            options.onEditImage = onEditImage;
            options.onDeleteImage = onDeleteImage;
            options.onEditVideo = onEditImage;
            options.onDeleteVideo = onDeleteImage;
            options.onEditAudio = onEditImage;
            options.onDeleteAudio = onDeleteImage;

            setEntryTitle(entryLocalTitle);

            const contentState = stateFromHTML(entryLocalDraft, options);

            setEditorState((prevState) => {
                if (contentState !== prevState) {
                    return EditorState.moveFocusToEnd(
                        EditorState.createWithContent(
                            contentState,
                            LinkDecorator
                        )
                    );
                }
            });

            const newState = EditorState.push(
                editorState,
                contentState,
                'insert-fragment'
            );
            if (newState.getCurrentContent().getPlainText().length <= 2) {
                localStorage.removeItem('entry-draft');
            }
            if (
                editorState.getCurrentContent().getPlainText() ||
                newState.getCurrentContent().getPlainText().length === 0
            ) {
                setHasLoadedLocalEntry(true);
            }
            setContentIsRendered(true);
        }
    }, [
        created,
        entry.id,
        hasLoadedLocalEntry,
        // loaded,
        editorState,
        // entry_title,
        onDeleteImage,
        onEditImage
        // entryLocalDraft
    ]);

    const saveOriginalEntryData = useCallback(() => {
        localStorage.setItem(
            `original-entry-${entry.id}`,
            JSON.stringify(entry.content)
        );
        localStorage.setItem(
            `original-entry-title-${entry.id}`,
            JSON.stringify(entry.title)
        );
        localStorage.setItem('original-entry-id', entry.id);
    }, [entry.content, entry.id, entry.title]);

    const onTitleChange = useCallback(() => {
        setEntryTitle(titleRef.current.value);
        if (onEntryTitleUpdate && !canSaveEntry()) {
            onEntryTitleUpdate(titleRef.current.value);
        }
        saveEntryDraftTitle_To_LS(titleRef.current.value, entry.id);
    }, [onEntryTitleUpdate, entry.id, canSaveEntry, saveEntryDraftTitle_To_LS]);

    useEffect(() => {
        // update options
        options.onEditImage = onEditImage;
        options.onDeleteImage = onDeleteImage;
        options.onEditVideo = onEditImage;
        options.onDeleteVideo = onDeleteImage;
        options.onEditAudio = onEditImage;
        options.onDeleteAudio = onDeleteImage;

        // container width
        // we will use this option for old image entry width
        // old images use static width and height
        // our editor use percent width, so we will switch static width to percent w/ entry editor width
        options.containerWidth =
            editorRef.current && editorRef.current.offsetWidth;

        if (entry.content && entry.content !== contentRef.current && !loaded) {
            contentRef.current = entry.content;
            // Load EditorState from html
            setEntryTitle(entry.title);
            // convert html to state

            if (
                created &&
                (!localStorage.getItem(`entry-draft-title-${entry.id}`) ||
                    !localStorage.getItem(`entry-draft-${entry.id}`))
            ) {
                const contentState = stateFromHTML(entry.content, options);

                // addNewImageIfFormatIsWrong(entry.content);
                setEditorState((prevState) => {
                    if (contentState !== prevState) {
                        return EditorState.moveFocusToEnd(
                            EditorState.createWithContent(
                                contentState,
                                LinkDecorator
                            )
                        );
                    }
                });
                saveEntryDraft_To_LS(entry.content, entry.id);
                saveEntryDraftTitle_To_LS(titleRef.current.value, entry.id);
            }
            saveOriginalEntryData();

            setLoaded(true);
        } else if (
            stateToHTML(editorState.getCurrentContent()) !==
                contentRef.current &&
            entry.content &&
            !contentIsRendered
        ) {
            const contentState = stateFromHTML(entry.content, options);
            // addNewImageIfFormatIsWrong(entry.content);
            setEditorState((prevState) => {
                if (contentState !== prevState) {
                    return EditorState.moveFocusToEnd(
                        EditorState.createWithContent(
                            contentState,
                            LinkDecorator
                        )
                    );
                }
            });
        }
        setContentIsRendered(true);
        // eslint-disable-next-line
    }, [entry.content, entry.title, loaded, onDeleteImage, onEditImage]);

    useEffect(() => {
        if (
            location.pathname.includes(URLS.ENTRY.EDIT) &&
            entry?.location?.trim()
        ) {
            const coords = entry.location.split(';');
            setEntryLocation({
                // @ts-ignore
                lat: Number(coords[0]),
                // @ts-ignore
                lng: Number(coords[1]),
                address: coords[2]
            });
        }
    }, [location.pathname, entry]);

    const onSharedUserUpdate = useCallback(
        (sharedUsers, added_users, removed_users, customMessage) => {
            // Done: remove updateUI
            if (entryIDRef.current) {
                // create invitations
                dispatch(
                    InvitationActions.inviteUsers(
                        added_users,
                        entryIDRef.current,
                        customMessage
                    )
                );
                // remove invitations
                dispatch(
                    InvitationActions.removeUsers(
                        removed_users,
                        invitations,
                        entryIDRef.current
                    )
                );

                // update shared users
                setSharedUsers([]);
            } else {
                setSharedUsers([...sharedUsers]);
            }
        },
        [dispatch, invitations]
    );

    const onUpdateFiles = useCallback(() => {
        openDialog(withFreeAccountCheck(iosMediaPermission(ImportFileDialog)));
    }, []);

    const onPublicEntry = useCallback(
        // @ts-ignore
        (isPublic, entry) => {
            isPublic
                ? openDialog(ShareDialog, {
                      sharedUsers: editorEntryInvitations,
                      onUpdate: onSharedUserUpdate,
                      publicEntry: true
                  })
                : onSharedUserUpdate([], [], invitations);
        },
        [onSharedUserUpdate, invitations, editorEntryInvitations]
    );

    const checkInvitationExists = useCallback(() => {
        const entryInvitations = [...invitations, ...sharedUsers];

        return (
            entryInvitations &&
            Array.isArray(entryInvitations) &&
            entryInvitations.length > 0
        );
    }, [sharedUsers, invitations]);

    const renderJournalBadge = useCallback(() => {
        return extraJournals.length > 1 ? `+${extraJournals.length - 1}` : '';
    }, [extraJournals]);

    const journalTitle = useCallback(() => {
        const { journals } = allJournals;
        let individualJournalTitle = null;
        if (journals && journalId) {
            if (user.is_free) {
                for (const journal of journals) {
                    if (journal.is_default === true) {
                        setExactJournal && setExactJournal(journal);
                        individualJournalTitle = journal.title;
                    }
                }
            } else {
                for (const journal of journals) {
                    if (journal.id === Number(journalId)) {
                        setExactJournal && setExactJournal(journal);
                        individualJournalTitle = journal.title;
                    }
                }
            }
            return individualJournalTitle;
        }

        if (didMount && entry?.journal?.title?.trim()) {
            return entry.journal.title;
        } else {
            return categoryName
                ? categoryName
                : extraJournalTittle?.trim()
                ? extraJournalTittle
                : entry?.journal?.title;
        }
        //eslint-disable-next-line
    }, [
        journalId,
        extraJournalTittle,
        categoryName,
        allJournals,
        defaultJournal,
        didMount,
        me.id
    ]);

    const shareDropdownAnchor = () => {
        if (checkInvitationExists()) {
            return (
                <InvitationListButton invitations={editorEntryInvitations} />
            );
        } else if (
            entryShared({
                extra_journals: extraJournals
            })
        ) {
            return (
                <React.Fragment>
                    <img
                        src={iconShare}
                        className="left btn-icon btn-icon--6"
                        alt=""
                    />
                    <span>Shared</span>
                    <img className="right caret" src={iconCaretDown} alt="" />
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <img
                        className="left btn-icon btn-icon--6"
                        src={iconLock}
                        alt=""
                    />
                    <span>Private</span>
                    <img className="right caret" src={iconCaretDown} alt="" />
                </React.Fragment>
            );
        }
    };

    const toggleJournalList = () => {
        setShowJournalList(!showJournalList);
    };

    const entryHasLocation = useCallback(() => {
        return Boolean(
            entryLocation.lat && entryLocation.lng && entryLocation.address
        );
    }, [entryLocation.lat, entryLocation.lng, entryLocation.address]);

    const onOpenMap = () => {
        // Done: remove updateUI
        openDialog(AddLocationDialog, {
            entry,
            setEntryLocation,
            canDelete: true
        });
        dispatch(setEntryPosition(entryLocation));
    };

    const onUpdateEditorRef = React.useCallback((ref) => {
        editorRef.current = ref;
    }, []);

    useEffect(() => {
        setContentIsRendered(false);
    }, [location.pathname]);

    // update extra journal title
    useDeepCompareEffectNoCheck(() => {
        extraJournals &&
            setExtraJournalTittle(
                extraJournals.map((items) => items?.title).join()
            );
    }, [extraJournals]);

    useEffect(() => {
        if (entry.content && entry.content !== contentRef.current && !loaded) {
            contentRef.current = entry.content;
            // Load EditorState from html
            setEntryTitle(entry.title);
            // convert html to state
            options.onEditImage = onEditImage;
            options.onDeleteImage = onDeleteImage;
            options.onEditVideo = onEditImage;
            options.onDeleteVideo = onDeleteImage;
            options.onEditAudio = onEditImage;
            options.onDeleteAudio = onDeleteImage;
            if (created) {
                const contentState = stateFromHTML(entry.content, options);
                // addNewImageIfFormatIsWrong(entry.content);
                setEditorState((prevState) => {
                    if (contentState !== prevState) {
                        return EditorState.moveFocusToEnd(
                            EditorState.createWithContent(
                                contentState,
                                LinkDecorator
                            )
                        );
                    }
                });
            }
            setLoaded(true);
        } else if (
            stateToHTML(editorState.getCurrentContent()) !==
                contentRef.current &&
            entry.content &&
            !contentIsRendered
        ) {
            options.onEditImage = onEditImage;
            options.onDeleteImage = onDeleteImage;
            options.onEditVideo = onEditImage;
            options.onDeleteVideo = onDeleteImage;
            options.onEditAudio = onEditImage;
            options.onDeleteAudio = onDeleteImage;
            const contentState = stateFromHTML(entry.content, options);
            // addNewImageIfFormatIsWrong(entry.content);

            setEditorState((prevState) => {
                if (contentState !== prevState) {
                    return EditorState.moveFocusToEnd(
                        EditorState.createWithContent(
                            contentState,
                            LinkDecorator
                        )
                    );
                }
            });
        }
        setContentIsRendered(true);
        // eslint-disable-next-line
    }, [entry.content, entry.title, loaded, onDeleteImage, onEditImage]);

    useEffect(() => {
        // if (autoSave) {
        //     _onSave(null, false);
        // }
    }, [autoSave, _onSave]);

    useEffect(() => {
        setEditorState((prevState) =>
            EditorState.set(prevState, { decorator: LinkDecorator })
        );
        return () => {};
    }, []);

    useDeepCompareEffectNoCheck(() => {
        setInvitations(
            arrayUtils.getSetOfArray(
                [
                    ...(entryInvitations || []),
                    ...(entry?.invitations ? entry?.invitations : [])
                ],
                (array, b) => array.find((a) => a.id === b.id)
            )
        );
    }, [entryInvitations, entry?.invitations]);

    useEffect(() => {
        setDate(moment.utc(entry.entry_date).local());
        // setEntryDateChangeFlag(true);
    }, [entry]);

    useEffect(() => {
        loadLocalDraft();
    });

    return (
        <div className="post">
            <div>
                {hasLocalChanges && isOnEdit && (
                    <div
                        style={{
                            position: 'relative',
                            width: '100%',
                            background: '#878787'
                        }}>
                        <div
                            style={{
                                padding: '0.5em',
                                border: '0px solid gray',
                                color: 'white',
                                // float: 'left',
                                width: '100%'
                            }}>
                            <b>Local Draft</b>
                        </div>
                    </div>
                )}
            </div>

            <div className="post-info pt-3 px-3">
                <div className="post-info__date-journal">
                    <div className="post-date">
                        <span
                            className="date"
                            onClick={(e) => {
                                onEntryDateClick(e);
                                // setEntryDateChangeFlag(true);
                            }}>
                            {editorDate}&nbsp;
                        </span>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <OutsideClickHandler
                            onOutsideClick={() => {
                                if (showJournalList) {
                                    setShowJournalList(false);
                                }
                            }}>
                            <div className="post-journal">
                                in
                                <span
                                    className="journal-used  mr-2 pl-1"
                                    style={{ cursor: 'pointer' }}
                                    onClick={toggleJournalList}>
                                    <span>{journalTitle()}</span>
                                </span>
                                {renderJournalBadge() && (
                                    <span
                                        className="tags-badge"
                                        style={{ width: '4rem' }}>
                                        {renderJournalBadge()}
                                    </span>
                                )}
                                {!(
                                    entry.entry_type === ENTRY_QA || question
                                ) && (
                                    <JournalDropdownList
                                        showJournalList={showJournalList}
                                        setShowJournalList={setShowJournalList}
                                        entry={entry}
                                        journal={journal}
                                        onUpdate={onEntryJournalUpdate}
                                        extraJournals={extraJournals}
                                        setExtraJournals={setExtraJournals}
                                    />
                                )}
                            </div>
                        </OutsideClickHandler>
                    </div>
                </div>
                {!(entry.entry_type === ENTRY_QA || question) && (
                    <TagDropdownList
                        entry={entry}
                        onUpdate={onEntryTagUpdate}
                        shouldRenderTagsName={true}
                    />
                )}
            </div>

            {entry.entry_type === ENTRY_QA || question ? (
                <Fragment>
                    {location.pathname === URLS.AAM.QUESTION ? (
                        <div className="answering-card-header px-3 posted-title">
                            <img
                                src={
                                    !categoryName
                                        ? user?.avatar_image_url
                                        : AAMCategoryImage(
                                              categoryName
                                                  .toLowerCase()
                                                  .split(' ')
                                                  .join('-')
                                          )
                                }
                                className="post-avatar display-inline"
                                alt=""
                            />
                            <div
                                className={
                                    categoryName && 'category-post-wrapper'
                                }>
                                {categoryName && (
                                    <p className="category-title">
                                        {categoryName}
                                    </p>
                                )}
                                <p className="post-title-fix display-inline">
                                    {question?.text}
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div className="answering-card-header px-3">
                            <div
                                className="answering-card-title"
                                style={{
                                    fontSize: getFontSize(
                                        FONT_SIZE_USE_TYPE.ENTRY_TITLE,
                                        userFontSize
                                    )
                                }}>
                                <p>{question?.text}</p>
                            </div>
                        </div>
                    )}
                </Fragment>
            ) : (
                <EntryEditorTitle
                    setEntryLocation={setEntryLocation}
                    entryLocation={entryLocation}
                    entry={entry}
                    user={user}
                    titleRef={titleRef}
                    entry_title={entry_title}
                    onTitleChange={onTitleChange}
                    emotion={emotion}
                    setEmotion={setEmotion}
                />
            )}

            <div
                className="post-body empty-body"
                style={{
                    fontSize: getFontSize(
                        FONT_SIZE_USE_TYPE.ENTRY,
                        userFontSize
                    )
                }}>
                {!canAutoSaveEntry() && (
                    <InputLabel>What’s on your mind?</InputLabel>
                )}
                <DraftJSEditor
                    onUpdateRef={onUpdateEditorRef}
                    editorState={editorState}
                    onChange={onEditorChange}
                    onEditImage={onEditImage}
                    showEditButtons={showEditButtons}
                    onDeleteImage={onDeleteImage}
                    handlePastedText={handlePastedText}
                />
            </div>
            {entryHasLocation() && (
                <div className="entry-address">
                    <img
                        src={iconPin}
                        alt=""
                        className="entry-address__icon icon-static btn-icon--6"
                    />
                    <div
                        onClick={onOpenMap}
                        className="entry-address__title truncate">
                        <span>{entryLocation.address}</span>
                    </div>
                </div>
            )}
            <div className="post-actions">
                <ul className="social-connections w-auto">
                    <li
                        onClick={onShowEditButtons}
                        className={`${
                            showEditButtons ? 'format_btn_active' : ''
                        }`}>
                        <img
                            className="btn-icon btn-icon--6"
                            src={iconFormatting}
                            alt=""
                        />
                    </li>
                    <li>
                        <DropDown
                            dropDownMenuProps={{
                                className: 'dropdown-menu--media',
                                // @ts-ignore
                                'aria-labelledby': 'mediaUpload',
                                style: { height: 'auto' }
                            }}
                            classes={{ dropdown_list: 'media-dropdown-list' }}
                            anchor={
                                <img
                                    src={iconAttachment}
                                    className="btn-icon btn-icon--6 dropdown show"
                                    role="button"
                                    id="mediaUpload"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    alt=""
                                />
                            }>
                            <li onClick={_onInsertImage}>
                                <span className="fa fa-camera" />
                                Upload images from your device
                            </li>
                            <li className="dropdown-menu__divider">
                                <hr />
                            </li>
                            <li onClick={onUpdateFiles}>
                                <span className="fa fa-upload" />
                                Upload media from your device
                            </li>
                            {/* <li onClick={onInsertGoogle}>
                                <span className="icon-google-drive" />
                                Upload from Google Drive
                            </li> */}
                            <li className="dropdown-menu__divider">
                                <hr />
                            </li>
                            {/*<li>*/}
                            {/*    <span className="fa fa-dropbox" />*/}
                            {/*    Upload from Dropbox*/}
                            {/*</li>*/}
                            <li onClick={onInsertInstagram}>
                                <span className="fa fa-instagram" />
                                Upload from Instagram
                            </li>
                            {/* <li onClick={onInsertFacebook}>
                                <span className="fa fa-facebook" />
                                Upload from Facebook
                            </li> */}
                            {/*<li>*/}
                            {/*    <span className="fa fa-twitter" />*/}
                            {/*    Upload from Twitter*/}
                            {/*</li>*/}
                        </DropDown>
                    </li>
                    <LinkButton
                        onChange={onInsertLink}
                        editorState={editorState}
                    />
                    <li onClick={onOpenLocationDialog}>
                        <img
                            className="btn-icon btn-icon--6"
                            src={iconPin}
                            alt=""
                        />
                    </li>
                </ul>
                <div className="actions-btns w-auto ">
                    {isSaving && !isError && (
                        <div className="saving-text pr-2">
                            <div className="saving-gif pr-3" />
                            Saving...
                        </div>
                    )}
                    {!isSaving && isSaved && !isError && (
                        <div className="saving-text pr-2">
                            <img src={iconCheckSm} alt="" />
                            Saved Locally!
                        </div>
                    )}
                    {user.sharing && (
                        <div className="dropdown">
                            <DropDown
                                className={`btn btn-clear ${
                                    checkInvitationExists()
                                        ? 'invitationList'
                                        : ''
                                }`}
                                style={{
                                    backgroundColor: 'transparent',
                                    border: 'none'
                                }}
                                classes={{
                                    dropdown_list: 'dropdown-sharing-list'
                                }}
                                dropDownMenuProps={{
                                    className: 'sharing-dropdown-menu'
                                }}
                                id="sharingModal"
                                anchor={shareDropdownAnchor()}>
                                <li onClick={() => onPublicEntry(false, entry)}>
                                    <div className="sharing-point-header">
                                        <img
                                            src={iconLock}
                                            className="btn-icon--6"
                                            alt=""
                                        />
                                        <h3 className="sharing-point-title">
                                            Private
                                        </h3>
                                    </div>
                                    <p className="sharing-point-text">
                                        You will be the only one able to see
                                        this journal
                                    </p>
                                </li>
                                <li onClick={() => onPublicEntry(true, entry)}>
                                    <div className="sharing-point-header">
                                        <img
                                            src={iconShare}
                                            className="btn-icon--6"
                                            alt=""
                                        />
                                        <h3 className="sharing-point-title">
                                            Shared
                                        </h3>
                                    </div>
                                    <p className="sharing-point-text">
                                        Only people with which you have shared
                                        will be able to see your post.
                                    </p>
                                </li>
                            </DropDown>
                        </div>
                    )}
                    <div onClick={_onSave}>
                        <button
                            className="btn btn-publish"
                            disabled={canSaveEntry() || !canSave}>
                            Save
                        </button>
                    </div>
                </div>
            </div>

            {hasLocalChanges && isOnEdit && (
                <EntryTile key={entry.id} entry={entry} isDetailView={null} />
            )}
        </div>
    );
};

const state = createStructuredSelector({
    selectedTags: getTagsForEntry,
    entryInvitations: getEntryInvitations,
    userFontSize: getFontSizeSelector,
    allJournals: getJournalList,
    filters: getFilters,
    me: getUser,
    defaultJournal: getDefaultJournal,
    ui: getUI
});
export default connect(state)(EntryEditor);
