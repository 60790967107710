import axios from 'axios';
import { UserSelector } from 'redux/selectors/UserSelector';
import {
    CREATE_ENTRY_IMAGE,
    UPDATE_ENTRY_IMAGE,
    CREATE_ENTRY_IMAGE_PROGRESS,
    SOCIALIMAGE_UPLOAD,
    UPDATE_IMAGE_PROGRESS,
    UPDATE_FILES_LIST,
    RESET_FILES_LIST
} from 'redux/actions/actionTypes';
import { SUCCESS_SUFFIX, SOCIALIMAGE_UPLOAD_API_V2_URL } from 'common/constant';
import { URLS } from 'common/urls';
import settings from 'common/configs/setting';
// import buildAuthorization from 'common/utils/buildAuthorization';
import buildV2Authorization from 'common/utils/buildV2Authorization';

export const updateImageProgress = (payload) => {
    return {
        type: UPDATE_IMAGE_PROGRESS,
        payload
    };
};
export const updateFilesList = (payload) => {
    return {
        type: UPDATE_FILES_LIST,
        payload
    };
};
export const resetFilesList = () => {
    return {
        type: RESET_FILES_LIST
    };
};

export const create = (data, fileName) => (dispatch, getState) => {
    const url = URLS.API.MEDIA.ROOT;
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);
    const options = {
        fileName
    };

    return dispatch({
        type: CREATE_ENTRY_IMAGE,
        payload: {
            request: {
                url,
                method: 'POST',
                data,
                options,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const createWithProgress = (data, fileName, file, dropzone, file_id) => (
    dispatch,
    getState
) => {
    // const url = `${settings.url}${settings.api}/entry_image/`;
    let url = `${localStorage.getItem('API_URL')}${
        settings.api_v2
    }/entry_media/`;
    if (url.includes('null/')) {
        if (
            window.location.href.includes('dev.') ||
            window.location.href.includes('localhost')
        ) {
            url = 'https://dev.jrnl.com/api/v2/entry_media/';
        } else if (window.location.href.includes('stage.')) {
            url = 'https://stage.jrnl.com/api/v2/entry_media/';
        } else {
            url = 'https://jrnl.com/api/v2/entry_media/';
        }
    }

    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);

    const options = {
        fileName
    };

    if (!data?.entry) {
        data.entry = null;
    }

    dispatch({
        type: CREATE_ENTRY_IMAGE_PROGRESS,
        payload: {
            data,
            options
        }
    });

    return axios
        .post(url, data, {
            headers: {
                Authorization: auth
            },
            onUploadProgress: (progress) => {
                const { loaded, total } = progress;
                const percentageProgress = Math.floor((loaded / total) * 100);
                file.upload.progress = percentageProgress;
                file.upload.bytesSent = loaded;
                dispatch(
                    updateImageProgress({
                        file_id,
                        progress: percentageProgress
                    })
                );
                dropzone.emit(
                    'uploadprogress',
                    file,
                    percentageProgress,
                    loaded
                );
            }
        })
        .then((response) => {
            if ((response && response.status) === 201) {
                dropzone.emit('success', file);
                dropzone.emit('complete', file);
                response.data.file_name = fileName;
                dispatch({
                    type: CREATE_ENTRY_IMAGE_PROGRESS + SUCCESS_SUFFIX,
                    payload: {
                        data: response.data,
                        options: {
                            fileName
                        }
                    }
                });
            } else {
                dropzone.emit('error', file);
            }
        });
};

export const update = (data, id) => (dispatch, getState) => {
    const url = `${URLS.API.MEDIA.ROOT}${id}/`;
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);
    dispatch({
        type: UPDATE_ENTRY_IMAGE,
        payload: {
            request: {
                url,
                method: 'PATCH',
                data,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const uploadSocialImage = (data) => (dispatch, getState) => {
    const url = SOCIALIMAGE_UPLOAD_API_V2_URL;
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);

    return dispatch({
        type: SOCIALIMAGE_UPLOAD,
        payload: {
            request: {
                url,
                method: 'POST',
                data,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};
