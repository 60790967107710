import _ from 'underscore';
import moment from 'moment';
import { createSelector } from 'reselect';

import { FiltersSelector } from 'redux/selectors/FiltersSelector';
import { InvitationForUUIDSelector } from 'redux/selectors/InvitationSelector';
import {
    getMyPublicUser,
    getPublicUserList
} from 'redux/selectors/PublicUserSelector';
import { getBlockedUsers } from 'redux/selectors/TimelineApprovalSelector';
import { getUser } from 'redux/selectors/UserSelector';
import { getHiddenJournal } from 'redux/selectors/JournalSelector';
import {
    DISPLAY_FILTER,
    ENTRY_DRAFT,
    ENTRY_HIDDEN,
    SORT
} from 'common/constant';
import arrayUtils from 'common/utils/arrayUtils';
import { checkUserSame } from 'common/utils/resourceUtils';
import {
    isEntryForUser,
    sharedByMe,
    sharedWithMe
} from 'common/utils/entry_utils';
import { getFileType } from 'common/utils/file_utils';

export const EntryStateSelector = (state) => state.entry;

export const EntrySelector = (state, props) => {
    return props.trial ? state.entry.trialEntries : state.entry.entries;
};

export const EntryDetailSelector = (state, props) => {
    if (props.entry) {
        return props.entry;
    }

    const entryId =
        props.match?.params && props.match?.params.entryId
            ? props.match?.params.entryId
            : props.entryId;

    if (entryId) {
        const { entries } = state.entry;

        return (entries || []).find((obj) => obj.id === parseInt(entryId));
    }
    return undefined;
};

export const AnswersForQuestion = (state, props) => {
    const questionId = props.match?.params.questionId || props.questionId;
    const filters = FiltersSelector(state);
    const { minDate, maxDate } = filters;
    if (questionId) {
        const { entries } = state.entry;
        return (entries || []).filter((answer) => {
            if (minDate && maxDate && questionId) {
                return (
                    moment(moment.utc(answer['entry_date']).local()).isBetween(
                        minDate,
                        maxDate,
                        undefined,
                        '[]'
                    ) && parseInt(answer?.question?.id) === parseInt(questionId)
                );
            } else {
                return parseInt(answer?.question?.id) === parseInt(questionId);
            }
        });
    }
    return undefined;
};

export const getQADraftEntry = createSelector(
    [AnswersForQuestion],
    (entries) => {
        return (entries || []).find((entry) => entry.status === ENTRY_DRAFT);
    }
);

export const getEntryById = createSelector(
    [EntryDetailSelector, getUser, getMyPublicUser],
    (entryDetail, me, publicMe) => {
        if (entryDetail) {
            if (
                [
                    me && me.resource_uri,
                    me && me.id,
                    publicMe && publicMe.resource_uri,
                    publicMe && publicMe.me
                ].includes(entryDetail.user)
            ) {
                entryDetail.isOwner = true;
            }
            return Object.assign({}, entryDetail);
        } else {
            return undefined;
        }
    }
);

export const getEntryState = createSelector([EntryStateSelector], (entry) =>
    Object.assign({}, entry)
);

export const getDraftEntry = createSelector([EntryStateSelector], (entry) => {
    const { entries } = entry;
    return (entries || []).find(
        (entry) => !entry.question && entry.status === ENTRY_DRAFT
    );
});

export const getFilteredLastEntry = createSelector(
    [EntrySelector, FiltersSelector],
    (entries, filters) => {
        const { entrySort } = filters;
        const lastEntry =
            entrySort.substring(0, 1) === '-'
                ? entries[0]
                : entries[entries.length - 1];
        return lastEntry;
    }
);
export const getFilteredEntries = createSelector(
    [
        EntryStateSelector,
        EntrySelector,
        FiltersSelector,
        getPublicUserList,
        getBlockedUsers,
        getUser,
        getMyPublicUser,
        getHiddenJournal
    ],
    (
        entry,
        entries,
        filters,
        public_user,
        blockedUsers,
        me,
        publicMe,
        hiddenJournals
    ) => {
        const {
            entrySort,
            minDate,
            maxDate,
            entryViewMode,
            filterByJournal,
            filterByTag,
            filterByContact
        } = filters;
        const unfilteredEntryCount = entries && entries.length;
        // only get published entries
        /*
        entries = _.filter(
            entries,
            (entry) => entry.status === ENTRY_PUBLISHED
        );
        */
        if (filterByJournal) {
            entries = (entries || []).filter((entry) => {
                const filteredJournalIds = filterByJournal.map(
                    (journal) => journal.id
                );
                const { extra_journals } = entry;
                if (filteredJournalIds.includes(entry.journal.id)) {
                    return true;
                }

                return extra_journals.some((journal) => {
                    return filteredJournalIds.includes(journal.id);
                });
            });
        }
        if (entryViewMode === DISPLAY_FILTER.HIDDEN) {
            entries = (entries || []).filter((entry) => {
                return entry.state === ENTRY_HIDDEN;
            });
        }
        if (filterByTag) {
            entries = (entries || []).filter((entry) => {
                const filteredTagsIds = filterByTag.map((tag) => tag.id);

                return entry.tags.some((tag) =>
                    filteredTagsIds.includes(tag.id)
                );
            });
        }

        if (filterByContact) {
            // filter by shared contact
            entries = (entries || []).filter((entry) => {
                const cond0 = entry.user.id === filterByContact?.user?.id;
                const cond1 = isEntryForUser(entry, filterByContact?.user);

                return cond0 || cond1;
            });
        }

        if (minDate && maxDate) {
            entries = (entries || []).filter((entry) => {
                if (
                    moment(moment.utc(entry['entry_date']).local()).isBetween(
                        minDate,
                        maxDate,
                        undefined,
                        '[]'
                    )
                ) {
                    if (entry.status !== ENTRY_DRAFT) {
                        if (!filterByJournal) {
                            // show hidden entry for journal selector
                            // eject hidden journals' entries
                            if (
                                (Array.isArray(hiddenJournals) &&
                                    (hiddenJournals || []).find(
                                        (obj) => obj.id === entry.journal
                                    )) ||
                                (typeof hiddenJournals === 'object' &&
                                    hiddenJournals.id === entry.journal)
                            ) {
                                return false;
                            }
                        }
                        // show filterByJournal or not hidden entries
                        return true;
                    }
                }
                return false;
            });
        }
        if (me.sharing) {
            if (!blockedUsers) {
                blockedUsers = [];
            }

            switch (entryViewMode) {
                case DISPLAY_FILTER.ALL: {
                    entries = (entries || []).filter((entry) => {
                        return (
                            !blockedUsers.includes(
                                entry && entry.public_user
                            ) && entry.state !== ENTRY_HIDDEN
                        );
                    });
                    break;
                }
                case DISPLAY_FILTER.JUST_ME: {
                    entries = (entries || []).filter(
                        (entry) =>
                            checkUserSame(me, entry.user) &&
                            !blockedUsers.includes(entry && entry.public_user)
                    );
                    break;
                }
                case DISPLAY_FILTER.SHARED_WITH_ME: {
                    entries = (entries || []).filter((entry) =>
                        sharedWithMe(entry, me)
                    );
                    break;
                }
                case DISPLAY_FILTER.SHARED_BY_ME: {
                    entries = (entries || []).filter((entry) =>
                        sharedByMe(entry, me)
                    );
                    break;
                }
                case DISPLAY_FILTER.HIDDEN: {
                    entries = (entries || []).filter(
                        (entry) => entry.state === ENTRY_HIDDEN
                    );
                    break;
                }
                case DISPLAY_FILTER.DRAFT: {
                    entries = (entries || []).filter(
                        (entry) => entry.status === ENTRY_DRAFT
                    );
                    break;
                }
                default: {
                    break;
                }
            }
        } else if (me.sharing && entryViewMode !== DISPLAY_FILTER.HIDDEN) {
            // eject hidden entries
            entries = (entries || []).filter(
                (entry) => entry.state === ENTRY_HIDDEN
            );
        }

        if (entrySort) {
            let filterBy = SORT.CREATED_NEWEST;
            if (entrySort.substring(0, 1) === '-') {
                filterBy = entrySort.substring(1);
            } else {
                filterBy = entrySort;
            }
            if (entrySort.substring(0, 1) === '-') {
                entries = (entries || []).sort(
                    (a, b) =>
                        new Date(b[filterBy]).getTime() -
                        new Date(a[filterBy]).getTime()
                );
            } else {
                entries = (entries || []).sort(
                    (a, b) =>
                        new Date(a[filterBy]).getTime() -
                        new Date(b[filterBy]).getTime()
                );
            }
        }

        const entryUserResourceUriList = _.uniq(_.pluck(entries, 'user'));
        const { public_users } = public_user;

        let entryUsers = undefined;

        if (public_users) {
            entryUsers = entryUserResourceUriList.map((user) =>
                (public_users || []).find((obj) => obj.resource_uri === user)
            );
        }

        return Object.assign({}, entry, filters, {
            entries,
            entryUsers,
            unfilteredEntryCount
        });
    }
);

export const getEntryForInvitationUUID = createSelector(
    [EntryStateSelector, InvitationForUUIDSelector],
    (entry, invitation) => undefined
);

export const getAnswersForQuestion = createSelector(
    [
        AnswersForQuestion,
        EntryStateSelector,
        getPublicUserList,
        FiltersSelector
    ],
    (answers, entry, public_user, filters) => {
        const { entries } = entry;
        const entryUserResourceUriList = _.uniq(_.pluck(entries, 'user'));
        const { public_users } = public_user;

        let entryUsers = undefined;

        if (public_users) {
            entryUsers = entryUserResourceUriList.map((user) =>
                (public_users || []).find((obj) => obj.resource_uri === user)
            );
        }

        return {
            entries: answers,
            entryUsers
        };
    }
);

export const getMediaEntries = createSelector(EntryStateSelector, (entry) => {
    return {
        mediaMeta: entry.mediaMeta,
        mediaEntries: entry.mediaEntries
    };
});

export const getEntriesMediaImages = createSelector(
    EntryStateSelector,
    (entry) => {
        if (!arrayUtils.isEmpty(entry.media.results)) {
            for (const element of entry.media.results) {
                const validEle = element?.file || element?.image;
                if (validEle) {
                    element['type'] = getFileType(validEle);
                }
            }
            return entry.media;
        }
    }
);

export const getEntryLocation = createSelector(EntryStateSelector, (entry) => {
    return entry.coords;
});
