import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// core components
import CheckBox from 'components/CheckBox/CheckBox';

// redux selector
import { getPaymentProfileList } from 'redux/selectors/PaymentProfileSelector';

// image
import plusIcon from 'assets/images/icons_svg/icon_plus.svg';

const PaymentProfileItem = ({
    card_type,
    id,
    safe_credit_card_number,
    onChange,
    onCreateBookOrder,
    checked
}) => {
    const ConvertCardText = (type) => {
        switch (type) {
            case 'visa':
                return 'Visa';
            case 'mc':
                return 'MasterCard';
            default:
                return 'UnknownCardType';
        }
    };
    return (
        <CheckBox
            onChange={onChange}
            checked={checked}
            label={
                <div>
                    <div>{ConvertCardText(card_type)}</div>
                    <div>{safe_credit_card_number}</div>
                </div>
            }
        />
    );
};
const PaymentProfileList = ({
    paymentProfiles,
    book,
    bookTotalPrice,
    onAddNewPaymentProfile,
    onCreateBookOrder
}) => {
    const [activePaymentProfile, setActivePaymentProfile] = React.useState(
        null
    );
    const [processing, setProcessing] = React.useState(false);

    const onNext = React.useCallback(() => {
        if (onCreateBookOrder(activePaymentProfile)) {
            setProcessing(true);
        } else {
            setProcessing(false);
        }
    }, [activePaymentProfile, onCreateBookOrder]);

    const onChange = React.useCallback((paymentProfile) => {
        setActivePaymentProfile(paymentProfile);
        // onCreateBookOrder && onCreateBookOrder(paymentProfile);
    }, []);

    return (
        <React.Fragment>
            {(paymentProfiles || [])
                .filter((profile) => !profile?.deleted)
                .map((paymentProfile) => (
                    <PaymentProfileItem
                        {...paymentProfile}
                        key={paymentProfile?.id}
                        checked={
                            activePaymentProfile?.id === paymentProfile?.id
                        }
                        onChange={() => onChange(paymentProfile)}
                    />
                ))}

            <div className="add-new-item btn btn-outline btn--sm">
                <CheckBox
                    onChange={onAddNewPaymentProfile}
                    inActiveMark={plusIcon}
                    label="Add a new Credit Card"
                />
            </div>

            <div className="payment-checkout-btn">
                <button
                    disabled={processing}
                    className="btn btn-gradient btn--md"
                    onClick={onNext}>
                    Checkout
                </button>
            </div>
        </React.Fragment>
    );
};

const state = createStructuredSelector({
    paymentProfiles: getPaymentProfileList
});
export default connect(state)(PaymentProfileList);
