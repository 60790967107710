import React, { useState, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { getUser } from 'redux/selectors';
import { connect } from 'react-redux';

// images
import oopsImage from 'assets/images/banners/banner-oops.png';
import helloImage from 'assets/images/banners/banner-hello.jpg';
import BannerCard from './BannerCard';

const EmptyListIndicator = ({
    user,
    onViewRecent,
    recentContent,
    onRemoveFilters,
    filters,
    filterJournal,
    filterTag,
    hasNoEntries
}) => {
    const [message, setMessage] = useState('');

    useEffect(() => {
        let message = 'There are no journal entries ',
            formattedDate;

        if (filterJournal) {
            message = `${message} in journal ${filterJournal.title}`;
        }

        if (filterTag) {
            message = `${message} tagged with ${filterTag.name}`;
        }

        if (filters.minDate) {
            switch (filters.dateViewMode) {
                case 'year': {
                    formattedDate = filters.minDate.format('YYYY');
                    break;
                }
                case 'month': {
                    formattedDate = filters.minDate.format('MMMM YYYY');
                    break;
                }
                default: {
                    formattedDate = filters.minDate.format('MMM D, YYYY');
                    break;
                }
            }
        }

        if (formattedDate && recentContent) {
            message = `${message} for ${formattedDate}`;
        } else {
            if (!hasNoEntries) {
                message = message = `${message} that match your filters`;
            } else {
                message =
                    'You have no entries. Add an entry using the editor below';
            }
        }

        message = `${message}.`;
        setMessage(message);
    }, [filterJournal, filterTag, filters, recentContent, hasNoEntries]);

    const isFiltered = filters.filtered;

    return (
        <>
            {isFiltered ? (
                <BannerCard
                    bannerClassName={'oops-card banner-card--dark'}
                    image={oopsImage}
                    title={"Oops! We didn't find anything."}
                    text={
                        "We couldn't find any post matching your search. Try changing your filters and search again."
                    }
                    id={'oops-card'}
                />
            ) : (
                <BannerCard
                    bannerClassName={'hello-card banner-card--light white-bg'}
                    image={helloImage}
                    title={`Hello, ${user.username}`}
                    text={message}
                    id={'hello-card'}
                />
            )}
        </>
    );
};

const state = createStructuredSelector({
    user: getUser
});
export default connect(state)(EmptyListIndicator);
