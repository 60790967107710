import _ from 'underscore';
const { SUCCESS_SUFFIX } = require('common/constant');
const {
    FETCH_BACKGROUND_IMAGES,
    ACTION_USER_LOGOUT
} = require('redux/actions/actionTypes');

const initialState = {
    background_images: [],
    is_pending: true
};
const BackgroundImageReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BACKGROUND_IMAGES: {
            return {
                ...state,
                is_pending: true
            };
        }
        case FETCH_BACKGROUND_IMAGES + SUCCESS_SUFFIX: {
            return {
                ...state,
                is_pending: false,
                background_images: action.payload.data.objects
            };
        }

        case ACTION_USER_LOGOUT: {
            return _.extend({}, initialState);
        }
        default: {
            return state;
        }
    }
};

export default BackgroundImageReducer;
