import axios from 'axios';

import { URLS } from 'api/urls';

export const donate = async (props) => {
    const { mode, priceId, qty, user } = props;
    const url = URLS.v2.stripe.donate;
    const result = await axios.post(
        url,
        {
            mode,
            price_id: priceId,
            quantity: qty
        },
        {
            headers: { Authorization: `Token ${user.api_v2_key}` }
        }
    );
    return (result.status === 201 && result.data) || null;
};
