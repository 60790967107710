import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { updateUI } from 'redux/actions/ui';
import BookCreateDialog from 'pages/Dialogs/BookCreateDialog';
import { getUI } from 'redux/selectors';
import { createStructuredSelector } from 'reselect';

// Icons
import iconJournal from 'assets/images/icons_svg/icon_journal.svg';

const JournalIndicator = ({ journals, dispatch, ui }) => {
    const history = useHistory();

    const setTitle = useCallback(() => {
        switch (journals.length) {
            case undefined:
            case null:
                return '';
            case 1:
                return journals[0].title;
            case 2:
                return `${journals[0].title}, ${journals[1].title}...`;
            default:
                return `${journals[0].title}, ${journals[1].title} and ${
                    journals.length - 2
                } more...`;
        }
    }, [journals]);

    const openBookCreateModal = useCallback(() => {
        history.push('/books/');
        setTimeout(() => {
            dispatch(
                updateUI({
                    selectedJournal: journals[0],
                    dialogs: [...ui.dialogs, BookCreateDialog]
                })
            );
        }, 500);
    }, [history, dispatch, journals, ui.dialogs]);

    return (
        <div className="timeline-heading">
            <div className="timeline-heading__icon static-icon btn-icon--6">
                <img src={iconJournal} alt="" />
            </div>
            <div className="timeline-heading__title truncate">
                <span>{setTitle()}</span>
            </div>
            {journals.length === 1 && (
                <button
                    className="timeline-heading__btn btn btn-outline"
                    onClick={openBookCreateModal}>
                    Create Book
                </button>
            )}
        </div>
    );
};

const state = createStructuredSelector({
    ui: getUI
});
export default connect(state)(JournalIndicator);
