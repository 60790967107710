import settings from 'common/configs/setting';

export const URLS = {
    API: {
        USER: {
            V1: `${settings.api}/user/`,
            V1_ME: `${settings.api}/user/me/`,
            ROOT: `${settings.api_v2}/user/`,
            PUBLIC: `${settings.api_v2}/public_user/`
        },
        MEDIA: {
            ROOT: `${settings.api_v2}/entry_media/`,
            INVITATION: `${settings.api_v2}/entry_media_invitation/`,
            TAG_USER: `${settings.api_v2}/tagged_user/`,
            TAG_USER_ALL: `${settings.api_v2}/public_user/tagged/`
        }
    },
    ROOT: '/',
    HOME: '/home',
    TIMELINE: '/timeline/',
    ENTRY: {
        ROOT: '/entry/',
        EDIT: '/entry/edit/',
        CREATE: '/create-entry/'
    },
    AAM: {
        ROOT: '/all-about-me/',
        QUESTION: '/all-about-me/question/',
        RANDOM_QUESTION: '/all-about-me/random-question'
    },
    JOURNAL: '/journals/',
    BOOK: {
        ROOT: '/books/',
        ORDER: '/book-order/',
        ORDERS: '/book-orders/',
        PREVIEW: '/book-preview/'
    },
    TAG: '/tags/',
    SEARCH: '/search/',
    LOGIN: '/login',
    SIGNUP: '/signup',
    VERIFY: '/verify',
    FORGOT_PASS: '/forgot_password',
    SETTINGS: {
        ROOT: '/settings/:page',
        PROFILE: '/settings/profile/',
        SOCIALCONNECT: '/settings/socialconnect/',
        SECURITY: '/settings/security/',
        REMINDER: '/settings/reminder/',
        EMAILJRNL: '/settings/emailjrnl/',
        BILLING: '/settings/billing/',
        THEMES: '/settings/themes/',
        NOTIFICATION: '/settings/notification/',
        INVITATIONS: '/settings/invitations/',
        IMPORTEXPORT: '/settings/importexport/',
        ACCOUNT: '/settings/account/'
    },
    PRICING: {
        ROOT: '/pricing',
        THANKYOUPAGE: '/pricing/thank-you'
    },
    CONTACTS: {
        ROOT: '/contacts/'
    },
    MEDIA: {
        ROOT: '/media/',
        VIEW: '/media/view/',
        SEARCH: '/media/search/'
    },
    MEMBERSHIP_ALERT: '/v3',
    THANKYOU: '/thank-you',
    ENTRY_SHARING: '/app/invitation',
    ENTRY_MEDIA: '/entry_media',
    MOBILE_IMPORT_CONTACTS: '/mobile_contact_cs',
    REDIRECT_CONTACTS: '/redirect/contacts',
    NOTIFICATIONS: '/notification',
    ITUNES: 'https://itunes.apple.com/us/app/jrnl.com/id1036338627?mt=8',
    GPLAY: 'https://play.google.com/store/apps/details?id=com.jrnl',
    REMOVE_MY_DATA: '/remove-my-data',
    DONATION: '/donation',
    PRIVACY: '/privacy',
    TERMS: '/terms'
};

// API urls
export const USER_API_URL = `${settings.api}/user/`;
