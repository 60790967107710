import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as JournalEmailActions from 'redux/actions/journalEmail';
import { closeDialog } from 'common/utils/dialog-utils';

// icons
import iconCloseSm from 'assets/images/icons_svg/icon_close-sm.svg';
import TextField from 'components/TextField';
import { Email } from 'components/TextField/validators';

// utils
import arrayUtils from 'common/utils/arrayUtils';

const SenderEmailDialog = ({ sender, dispatch }) => {
    const [email, setEmail] = useState(sender?.email || '');
    const [isDisabled, setIsDisabled] = useState(true);
    const [error, setError] = useState({});
    const [submitted, setSubmitted] = useState(!!sender);

    useEffect(() => {
        const validateEmail = new RegExp(
            /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
        ).test(email);
        setIsDisabled(!validateEmail);
    }, [email]);

    const createSenderEmail = useCallback(
        (e) => {
            e.preventDefault();

            setSubmitted(true);
            if (!arrayUtils.checkErrorObjValidated(error)) {
                return;
            }

            if (sender) {
                dispatch(
                    JournalEmailActions.edit_sender_email(sender.id, {
                        email
                    })
                );
            } else {
                dispatch(
                    JournalEmailActions.create_sender_email({
                        email
                    })
                );
            }

            dispatch(JournalEmailActions.fetch_journal_emails());
            closeDialog();
        },
        [dispatch, email, sender, error]
    );

    const deleteSenderEmail = useCallback(() => {
        if (sender) {
            dispatch(JournalEmailActions.delete_sender_email(sender.id));
        }
        closeDialog();
    }, [sender, dispatch]);

    return (
        <div id="add-sender-modal" className="modal-frame">
            <div className="modal-box modal-box--600">
                <div className="modal__header">
                    {sender ? 'Edit a sender' : 'Add a sender'}
                </div>
                <div className="modal__body">
                    <form>
                        <div className="form-group form-group--single">
                            <div className="form-group__item">
                                <TextField
                                    label="Email Address"
                                    submitted={submitted}
                                    value={email}
                                    validator={Email}
                                    onValidated={(name, e) =>
                                        setError((prev) => ({
                                            ...prev,
                                            email: e
                                        }))
                                    }
                                    onChange={(e) =>
                                        setEmail(e.target.value)
                                    }></TextField>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal__footer">
                    {sender?.email ? (
                        <button
                            onClick={deleteSenderEmail}
                            className="btn btn-delete">
                            Delete
                        </button>
                    ) : (
                        <button
                            className="btn btn-outline"
                            onClick={closeDialog}>
                            Cancel
                        </button>
                    )}
                    <button
                        className="btn btn-gradient"
                        disabled={isDisabled}
                        onClick={createSenderEmail}>
                        {sender ? 'Save' : 'Add sender'}
                    </button>
                </div>

                <div
                    id="sender-email-close"
                    className="modal__close btn-icon btn-icon--3"
                    onClick={closeDialog}>
                    <img src={iconCloseSm} alt="" />
                </div>
            </div>
        </div>
    );
};

export default connect()(SenderEmailDialog);
