import React, { useCallback, useState, useEffect } from 'react';
import ExtendedRichUtils from 'components/Editor/utils/ExtendedRichUtils';

// Icons
import iconLink from 'assets/images/icons_svg/icon_link.svg';
import iconAddSm from 'assets/images/icons_svg/icon_add-sm.svg';

// utils
import { isDescendant } from 'common/utils/elementUtils';

const LinkButton = ({ onChange, editorState }) => {
    // states
    const [text, setText] = useState('');
    const [url, setUrl] = useState('');
    const [newLink, setNewLink] = useState(false);
    const [openLinkDropdown, setOpenLinkDropdown] = useState(false);

    const dropdownRef = React.useRef(null);

    const toggleLinkDropdown = useCallback((e) => {
        e.stopPropagation();
        setOpenLinkDropdown((prev) => !prev);
    }, []);
    // handlers
    const onChangeText = useCallback((e) => {
        setText(e.target.value);
    }, []);

    const onChangeURL = useCallback((e) => {
        setUrl(e.target.value);
    }, []);

    const onAddLink = useCallback(() => {
        const value = text?.trim()
            ? text
            : url.startsWith('https://')
            ? url
            : `https://${url}`;
        onChange({ value, url, isNew: newLink });
        setText('');
        setUrl('');
        setOpenLinkDropdown(false);
    }, [onChange, text, url, newLink]);

    useEffect(() => {
        const linkobj = ExtendedRichUtils.getLinkFromSelection(editorState);
        if (linkobj?.url && linkobj.url.length) {
            setUrl(linkobj.url);
        }
        if (linkobj?.text && linkobj.text.length) {
            setText(linkobj.text);
            setNewLink(false);
        } else {
            setNewLink(true);
            setUrl('');
            setText('');
        }
        return () => {};
    }, [editorState]);

    const onClick = useCallback((e) => {
        if (!isDescendant(dropdownRef.current, e.target)) {
            setOpenLinkDropdown(false);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('click', onClick);
        return () => {
            window.removeEventListener('click', onClick);
        };
    }, [onClick]);

    return (
        <li>
            <img
                src={iconLink}
                className="btn-icon btn-icon--6 dropdown-toggle dropdown show"
                role="button"
                id="linkShared"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
                alt="Link"
                onClick={toggleLinkDropdown}
            />
            <div
                ref={dropdownRef}
                className={`dropdown-menu dropdown-menu--form ${
                    openLinkDropdown ? 'show' : ''
                }`}
                aria-labelledby="linkShared"
                x-placement="top-start">
                <div className="form-group">
                    <div className="form-group__item">
                        <label htmlFor="text">Link Text</label>
                        <input
                            type="text"
                            value={text}
                            onChange={onChangeText}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="form-group__item">
                        <label htmlFor="text">Link URL</label>
                        <input
                            type="text"
                            value={url}
                            onChange={onChangeURL}
                            placeholder="https://example.com"
                        />
                    </div>
                    <button
                        className="btn btn-gradient btn--sq"
                        onClick={onAddLink}>
                        Add URL
                        <img src={iconAddSm} className="right" alt="Add URL" />
                    </button>
                </div>
            </div>
        </li>
    );
};

export default LinkButton;
