/**
 * Convert facebook posts into image array
 */
export const getFacebookPhotos = (posts) => {
    let photos = [];

    (posts || []).forEach((post) => {
        const message = post.message;

        // let's loop all attachments
        ((post.attachments && post.attachments.data) || []).forEach(
            (attachment) => {
                if (attachment.type === 'album') {
                    // if album, add sub attachments
                    photos = photos.concat(
                        attachment.subattachments.data
                            .filter(
                                (subattachment) =>
                                    subattachment.type === 'photo'
                            )
                            .map((item) => {
                                return {
                                    name: message,
                                    images: [item.media.image],
                                    ...item.target
                                };
                            })
                    );
                } else if (attachment.type === 'photo') {
                    // if photo, add this image
                    photos.push({
                        name: message,
                        images: [attachment.media.image],
                        ...attachment.target
                    });
                }
            }
        );
    });

    return photos;
};
