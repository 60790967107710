import _ from 'underscore';

import {
    createSelector
} from 'reselect';

export const InvitationForJournalSelector = (state, props) => {
    let journalId = props.match?.params.journalId || props.journal?.id || undefined;

    if (!journalId) return undefined;

    const { journals } = state.journal;
    const journal = _.findWhere(journals, { id: parseInt(journalId) });

    return journal?.invitations;
}

export const getJournalInvitations = createSelector(
    [InvitationForJournalSelector],
    (journalInvitations) => journalInvitations
)