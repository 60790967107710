import React from 'react';
import iosAppLogo from 'assets/images/ios-app-logo.png';
import iosAppRating from 'assets/images/ios-rating.png';
import { Link } from 'react-router-dom';
export const IosBanner = ({ onClose }) => {
    return (
        <div className="ios-banner-install">
            <div className="ios-app-store">
                <span
                    className="ios-close"
                    id="banner-close"
                    onClick={() => onClose()}>
                    &times;
                </span>
                <div className="ios-app-logo">
                    <img src={iosAppLogo} alt="ios-app-logo" />
                </div>
                <div className="ios-app-desc">
                    <p className="ios-app-title">JRNL</p>
                    <p className="ios-app-subtitle">JRNL.com</p>
                    <img src={iosAppRating} alt="ios-rating" />
                    <p className="ios-app-msg">GET - On the App Store</p>
                </div>
            </div>
            <Link to="#" className="ios-link">
                View
            </Link>
        </div>
    );
};
