import React from 'react';

const OutdentButton = ({ onClick }) => {
    return (
        <li onClick={onClick}>
            <span className="icon-indent-less"></span>
        </li>
    );
};

export default OutdentButton;
