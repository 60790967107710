import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

const MobileContactImport = () => {
    const [isFetchedContacts, setIsFetchedContacts] = useState(false);
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (window.cloudsponge) {
            if (!location.pathname.includes('redirect/contacts')) {
                window.cloudsponge.init({
                    afterSubmitContacts: (contacts, source, owner) => {
                        const list = [];
                        contacts.map((contact) => {
                            return list.push({
                                email: contact.selectedEmail()
                            });
                        });
                        setIsFetchedContacts(true);
                        history.push(
                            `/redirect/contacts?emails:${JSON.stringify(list)}`
                        );
                    },
                    afterInit: () => {
                        window.cloudsponge.launch(location.hash.substring(1));
                    },
                    beforeClosing: () => {
                        if (!isFetchedContacts) {
                            history.push('/redirect/contacts');
                        }
                    }
                });
            }
        }
    }, [location.hash, history, location.pathname, isFetchedContacts]);

    return (
        <div>
            <textarea className="cloudsponge-contacts" />
        </div>
    );
};

export default MobileContactImport;
