import React, { useCallback, useState } from 'react';
// Actions
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getUI } from 'redux/selectors';
import * as UserActions from 'redux/actions/user';
import { updateUI } from 'redux/actions/ui';

// Components
import Dialog from '@material-ui/core/Dialog';
import { DELETE_VALUE } from 'common/constant';

const DeleteAccountDialog = ({ close, ui, dispatch, open }) => {
    const [canDelete, setCanDelete] = useState(false);
    const _close = useCallback(() => {
        if (ui.bulkMode) {
            dispatch(
                updateUI({
                    bulkMode: false,
                    bulkEntries: []
                })
            );
        }
        close && close();
    }, [close, ui.bulkMode, dispatch]);

    const _done = useCallback(
        (e) => {
            dispatch(UserActions.unsubscribeToFree()).then((unsubResponse) => {
                dispatch(UserActions.deleteAccount()).then((response) => {
                    _close();
                    dispatch(UserActions.logout());
                });
            });
        },
        [_close, dispatch]
    );

    const onChangeConfirmCode = useCallback((e) => {
        setCanDelete(Boolean(e.target.value === DELETE_VALUE));
    }, []);

    return (
        <Dialog
            fullWidth={true}
            open={open}
            onClose={_close}
            aria-labelledby="form-dialog-title">
            <div
                id="delete-modal"
                className="modal-box modal-box--delete modal-box--500">
                <div className="delete-modal">
                    <div className="delete-modal__content">
                        <div className="delete-modal__title">
                            Are your sure?
                        </div>
                        <div className="delete-modal__subtitle">
                            Once you confirm, all of your account data will be
                            permanently deleted.
                        </div>
                        <input
                            type="text"
                            className="delete-moadl_text"
                            onChange={onChangeConfirmCode}
                            id="confirmCodeTitle"
                            name="cofirm-code"
                            placeholder={`Type ${DELETE_VALUE} to confirm`}
                        />
                    </div>
                    <div className="delete-modal__actions">
                        <button className="btn btn-outline" onClick={_close}>
                            Cancel
                        </button>
                        <button
                            className="btn btn-delete"
                            onClick={_done}
                            disabled={!canDelete}>
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
const state = createStructuredSelector({
    ui: getUI
});
export default connect(state)(DeleteAccountDialog);
