import React, { useCallback, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import MediaManager from '../MediaManager/MediaManager';
// redux
import { connect } from 'react-redux';
// selecor
import { createStructuredSelector } from 'reselect';
import { getImageUploadProgress, getUI } from 'redux/selectors';

import { resetFilesList, updateFilesList } from 'redux/actions/entryimage';
import { UploadProgress } from 'pages/Dialogs/UploadProgress';
import { resizeImageFile } from 'common/utils/file_utils';
import { updateUI } from 'redux/actions/ui';
import * as EntryMediaActions from 'redux/actions/entrymedia';
import AddJournalEntry from './AddJournalEntry';

const AddImageUpload = ({ open, entry, close, dispatch, progress }) => {
    const mode = 'media';
    const [imageElement, setImageElement] = useState(null);
    const [selectedEntryList, setSelectedEntryList] = useState([null]);

    const setFileWhenUploading = useCallback(
        (id, percentage) => {
            dispatch(updateFilesList({ id, percentage }));
        },
        [dispatch]
    );

    const handleFileAdded = useCallback(async (paramFile, dropzone) => {
        let file = paramFile;

        if (file.size > 1000000) {
            file = await resizeImageFile(file);
        }

        if (file.type !== '') {
            dropzone.emit('processing', file);
            setImageElement(file);
            dropzone.emit('success', file);
            dropzone.emit('complete', file);
        } else {
            dropzone.emit('error', file);
        }
    }, []);
    const onProcessing = useCallback(() => {
        dispatch(
            updateUI({
                disableButton: true
            })
        );
    }, [dispatch]);

    const onSuccess = useCallback(() => {
        dispatch(
            updateUI({
                disableButton: false
            })
        );
        const dzHIs = [...document.getElementsByClassName('dz-hidden-input')];
        dzHIs.forEach((element) => {
            element.setAttribute('disabled', '1');
        });
    }, [dispatch]);

    useEffect(() => {
        if (progress === 100) {
            dispatch(resetFilesList());
        }
    }, [progress, dispatch]);

    useEffect(() => {
        dispatch(
            updateUI({
                disableButton: true
            })
        );
    }, [dispatch]);

    const _close = useCallback(() => {
        // dispatch(EditorActions.resetImageState());
        if (close) {
            close();
        }
    }, [close]);

    const done = useCallback(
        (e) => {
            if (imageElement) {
                const form = new FormData();
                form.append('image', imageElement, imageElement.name);

                const mediaCreate = (entry) => {
                    // if (entry && entry.id) {
                    //     form.append('entry', entry.id);
                    // }
                    form.append('entry', entry?.id || '');
                    dispatch(EntryMediaActions.create(form));
                };

                (selectedEntryList || []).map((entry) => {
                    return mediaCreate(entry);
                });
                _close();
            }
        },
        [dispatch, imageElement, selectedEntryList, _close]
    );

    const changeEntryStatus = useCallback(
        (entry, status, key) => {
            setSelectedEntryList((prevState) => {
                if (status !== true && prevState.length === key) {
                    return [...prevState, entry];
                }
                const entryList = [...prevState];
                let newEntryList = [];
                const selectEntry =
                    status === true
                        ? selectedEntryList[key] &&
                          selectedEntryList[key]?.id === entry.id
                            ? null
                            : entry
                        : null;
                newEntryList = entryList.map((entryEle, index) => {
                    return index === key ? selectEntry : entryEle;
                });
                return newEntryList;
            });
        },
        [selectedEntryList]
    );

    const deleteJournalEntry = useCallback((index) => {
        setSelectedEntryList((prevState) => {
            const entryList = [...prevState];
            entryList.splice(index, 1);
            return entryList;
        });
    }, []);

    const renderAddJournalEntry = useCallback(() => {
        return (selectedEntryList || []).map((selectedEntry, index) => {
            return (
                <AddJournalEntry
                    key={index}
                    index={index}
                    selectedEntry={selectedEntry}
                    changeEntryStatus={changeEntryStatus}
                    deleteJournalEntry={deleteJournalEntry}
                />
            );
        });
    }, [selectedEntryList, changeEntryStatus, deleteJournalEntry]);

    return (
        <Dialog open={open} style={{ zIndex: 99999 }} onClose={_close}>
            <div id="import-file-modal" className="import-file-modal">
                {progress > 0 ? <UploadProgress progress={progress} /> : null}
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8 col-xl-7 import-file-content">
                            <div className="import-file-content-header">
                                <h3 className="import-file-content-title">
                                    Add Files
                                </h3>
                            </div>

                            <MediaManager
                                setFileWhenUploading={setFileWhenUploading}
                                onFileAdded={handleFileAdded}
                                onProcessing={onProcessing}
                                onSuccess={onSuccess}
                                mediaType={mode}></MediaManager>

                            {renderAddJournalEntry()}

                            <div className="add-photos-media-actions">
                                <button
                                    className="btn btn-cancel"
                                    onClick={() => close()}>
                                    Cancel
                                </button>
                                <button
                                    onClick={done}
                                    className="btn btn-add-photos">
                                    <span className="fa fa-upload"></span>
                                    Add Photos
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

const state = createStructuredSelector({
    ui: getUI,
    progress: getImageUploadProgress
});

export default connect(state)(AddImageUpload);
