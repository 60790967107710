import settings from 'common/configs/setting';

// const v1 = settings.url + settings.api;
// const v2 = settings.url + settings.api_v2;

const v1 = localStorage.getItem('API_URL') + settings.api;
const v2 = localStorage.getItem('API_URL') + settings.api_v2;

localStorage.setItem('Test:', 'hello');

export const URLS = {
    v1: {
        base: v1
    },
    v2: {
        base: v2,
        stripe: {
            base: `${v2}/stripe/`,
            donate: `${v2}/stripe/donate/`
        }
    }
};
