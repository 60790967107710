import React, { useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { getUI } from 'redux/selectors';
import { createStructuredSelector } from 'reselect';
import {
    MEDIA_OPTIONS_DO_NOT_PRINT_QR_CODE,
    MEDIA_OPTIONS_PRINT_QR_CODE,
    SHOW_MEDIA_QR_CODE,
    SHOW_ENTRY_QR_CODE
} from 'common/constant';
import { isEqual } from 'common/utils/arrayUtils';
import { updateUI, updateStepsFilledIn } from 'redux/actions/ui';
// Actions

const MediaOptionsStep = ({ display, ui, dispatch, initCall }) => {
    const { mediaOptions, mediaPrivacyOptions } = ui;
    const privacyOptionDisabled =
        mediaOptions === MEDIA_OPTIONS_DO_NOT_PRINT_QR_CODE;

    const updateStepsFilledIn_MOS = useCallback(
        (val) => {
            dispatch(updateStepsFilledIn('MediaOptionsStep', val));
        },
        [dispatch]
    );
    const initialRenderRef = useRef(true);
    useEffect(() => {
        if (initialRenderRef.current) {
            initialRenderRef.current = false;
            initCall();
            updateStepsFilledIn_MOS(true);
        }
    }, [initCall, updateStepsFilledIn_MOS]);

    const onChangeMediaOption = useCallback(
        (option) => {
            updateStepsFilledIn_MOS(true);
            dispatch(
                updateUI({
                    mediaOptions: option
                })
            );
        },
        [dispatch, updateStepsFilledIn_MOS]
    );
    const onChangeQROptions = useCallback(
        (option) => {
            updateStepsFilledIn_MOS(true);
            if (!privacyOptionDisabled) {
                dispatch(updateUI({ mediaPrivacyOptions: option }));
            }
        },
        [dispatch, privacyOptionDisabled, updateStepsFilledIn_MOS]
    );

    useEffect(() => {
        if (!mediaOptions) {
            dispatch(
                updateUI({
                    mediaOptions: MEDIA_OPTIONS_PRINT_QR_CODE
                })
            );
        }
    }, [dispatch, mediaOptions]);

    useEffect(() => {
        if (!mediaPrivacyOptions) {
            dispatch(
                updateUI({
                    mediaPrivacyOptions: SHOW_ENTRY_QR_CODE
                })
            );
        }
    }, [dispatch, mediaPrivacyOptions]);
    return (
        <div
            className={`new-book-step-6 new-book-step ${
                display ? 'active-form' : ''
            }`}>
            <div className="section-title">Media Options</div>
            <div className="new-book-step-form">
                <div className="photos-options">
                    <div
                        className="form-group"
                        onClick={() =>
                            onChangeMediaOption(MEDIA_OPTIONS_PRINT_QR_CODE)
                        }>
                        <div className="radio-item">
                            <input
                                className="radio"
                                onChange={() => {}}
                                type="radio"
                                id="media"
                                name="media-options"
                                checked={
                                    mediaOptions === MEDIA_OPTIONS_PRINT_QR_CODE
                                }
                            />
                            <label htmlFor="media">
                                Print Media (with QR Codes)
                            </label>
                        </div>
                    </div>
                    <div
                        className="form-group"
                        onClick={() =>
                            onChangeMediaOption(
                                MEDIA_OPTIONS_DO_NOT_PRINT_QR_CODE
                            )
                        }>
                        <div className="radio-item">
                            <input
                                className="radio"
                                onChange={() => {}}
                                type="radio"
                                id="noMedia"
                                name="media-options"
                                checked={
                                    mediaOptions ===
                                    MEDIA_OPTIONS_DO_NOT_PRINT_QR_CODE
                                }
                            />
                            <label htmlFor="noMedia">Do not print media</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-title">Media Privacy Options</div>
            <div
                className={`new-book-step-form ${
                    privacyOptionDisabled ? 'new-book-step-form-disabled' : ''
                }`}>
                <div className="photos-options">
                    <div
                        className="form-group"
                        onClick={() => onChangeQROptions(SHOW_ENTRY_QR_CODE)}>
                        <div className="radio-item">
                            <input
                                className="radio"
                                onChange={() => {}}
                                type="radio"
                                id="requireAccess"
                                name="requireAccess"
                                value="0"
                                checked={
                                    mediaPrivacyOptions === SHOW_ENTRY_QR_CODE
                                }
                            />
                            <label htmlFor="requireAccess">
                                Require JRNL Account Credentials to Access
                            </label>
                        </div>
                    </div>
                    <div
                        className="form-group"
                        onClick={() => onChangeQROptions(SHOW_MEDIA_QR_CODE)}>
                        <div className="radio-item">
                            <input
                                className="radio"
                                onChange={() => {}}
                                type="radio"
                                id="requireNoAccess"
                                name="requireAccess"
                                checked={
                                    mediaPrivacyOptions === SHOW_MEDIA_QR_CODE
                                }
                            />
                            <label htmlFor="requireNoAccess">
                                {' '}
                                Do not require JRNL Account Credentials to
                                Access
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    ui: getUI
});
export default connect(state)(
    React.memo(MediaOptionsStep, (prev, next) => isEqual(prev, next))
);
