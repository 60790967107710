// @ts-nocheck
import React from 'react';

// Global
const CC_CHARACTER_LIMITER = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;

const ExpirationDateInput = ({
    expireDateTxt,
    setExpireDateTxt,
    expireDateTxtError,
    setExpireDateTxtError
}) => {
    const validateExpirationDate = (expireDate) => {
        if (!CC_CHARACTER_LIMITER.test(expireDate)) {
            setExpireDateTxtError(true);
        }
    };

    const formatExpireDate = (expireDateTxt) => {
        let revisedExpireDate = expireDateTxt.replace(/\D/g, '');
        if (revisedExpireDate.length > 2) {
            revisedExpireDate = `${revisedExpireDate.slice(0, 2)}/${
                revisedExpireDate.length > 2 ? revisedExpireDate.slice(2) : ''
            }`;
        }
        // validateExpirationDate(revisedExpireDate)
        setExpireDateTxt(revisedExpireDate);
    };

    return (
        <div className="form-group__item">
            <label className="settings-label" htmlFor="expireDate">
                Expiration
            </label>
            <div className="form-group__expiration">
                <input
                    className="settings-input"
                    maxLength={5}
                    type="text"
                    id="expireDate"
                    name="expiredate"
                    placeholder="MM/YY"
                    onChange={(e) => {
                        formatExpireDate(e.target.value);
                        setExpireDateTxtError(false);
                    }}
                    onBlur={(e) => {
                        validateExpirationDate(expireDateTxt);
                    }}
                    value={expireDateTxt}
                />
                {expireDateTxtError && (
                    <div className="form__error">
                        The expiration date is invalid. Please try again. Also
                        note that the format has to be MM/YY.
                    </div>
                )}
            </div>
        </div>
    );
};

export default ExpirationDateInput;
