/**
 * Dialog when user checkout in book order dialog page has been succeed.
 */

import React from 'react';
import { useHistory } from 'react-router';
import { URLS } from 'common/urls';

// Images
import verificationImage from 'assets/images/books/checkout-success.jpg';

// Icons
import iconCloseSm from 'assets/images/icons_svg/icon_close-sm.svg';

const CheckoutSuccessDialog = ({
    order,
    paymentProfile,
    shippingAddress,
    close,
    bookTotalPrice,
    bookPrice,
    bookQuantity,
    discount,
    basePrices,
    shipping
}) => {
    const history = useHistory();

    const onGoHome = React.useCallback(() => {
        if (close) {
            close();
        }
        history.push(URLS.BOOK.ORDERS);
    }, [close, history]);

    return (
        <div className="modal-frame" id="completed-purchase-modal">
            <div className="modal-box modal-box--600">
                <div className="modal__header">
                    <button
                        className="modal__close btn-icon btn-icon--3"
                        onClick={onGoHome}>
                        <img src={iconCloseSm} alt="" />
                    </button>
                </div>
                <div className="modal__body">
                    <img
                        className="verification-image"
                        src={verificationImage}
                        alt="verificaiton"
                    />

                    <div className="modal-message">
                        <div className="modal-message__title">
                            Your purchase is completed!
                        </div>
                        <div className="modal-message__details">
                            We’re working on delivering your book as fast as
                            possible. You can check the details below, and on an
                            email we just sent you.
                        </div>
                    </div>

                    <div className="settings-list-title section-title">
                        Payment information
                    </div>

                    <div className="purchase-table">
                        <div className="purchase-table__item">
                            <div className="purchase-table__label purchase-table__label--card-type">
                                {paymentProfile.card_type}
                            </div>
                            <div className="purchase-table__value">
                                {paymentProfile.safe_credit_card_number}
                            </div>
                        </div>
                        <div className="purchase-table__item">
                            <div className="purchase-table__label">
                                <span>{order.title}</span>
                            </div>
                            <div className="purchase-table__value">
                                {bookQuantity} x ${basePrices.fullPrice}
                            </div>
                        </div>
                        <div className="purchase-table__item">
                            <div className="purchase-table__label">
                                Shipping
                            </div>
                            <div className="purchase-table__value">
                                ${shipping}
                            </div>
                        </div>
                        {discount && (
                            <div className="purchase-table__item">
                                <div className="purchase-table__label">
                                    PowerJRNLer Discount
                                </div>
                                <div className="purchase-table__value">
                                    -${basePrices.discountAmount.toFixed(2)} (
                                    {discount * 100}%)
                                </div>
                            </div>
                        )}
                        <div className="purchase-table__item purchase-table__item--total">
                            <div className="purchase-table__label">Total:</div>
                            <div className="purchase-table__value">
                                ${bookTotalPrice}
                            </div>
                        </div>
                    </div>

                    <div className="settings-list-title section-title">
                        Shipping to
                    </div>

                    <ul className="buyer-info">
                        <li>
                            {shippingAddress.first_name}{' '}
                            {shippingAddress.last_name}
                        </li>
                        <li>
                            {shippingAddress.street} {shippingAddress.street2}
                        </li>
                        <li>
                            {shippingAddress.city}, {shippingAddress.zip_code}
                        </li>
                        <li>
                            {shippingAddress.state}, {shippingAddress.country}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default CheckoutSuccessDialog;
