import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getJournalList, getUI } from 'redux/selectors';
import { createStructuredSelector } from 'reselect';
import { closeDialog } from 'common/utils/dialog-utils';
import { updateUI } from 'redux/actions/ui';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import * as BeneficiariesActions from 'redux/actions/beneficiaries';

const BeneficiaryDialog = ({ dispatch, journals, ui, account, index }) => {
    const [selectAll, setSelectAll] = useState(account?.isSelectedAll || false);
    const [name, setName] = useState(account?.name || '');
    const [email, setEmail] = useState(account?.email || '');
    const [phoneNumber, setPhoneNumber] = useState(account?.phoneNumber || '');

    useEffect(() => {
        if (selectAll) {
            dispatch(
                updateUI({
                    journalsChecked: journals.journals.map(
                        (journal) => journal.id
                    )
                })
            );
        } else {
            dispatch(
                updateUI({
                    journalsChecked: []
                })
            );
        }
    }, [selectAll, dispatch, journals.journals]);

    useEffect(() => {
        if (account) {
            dispatch(
                updateUI({
                    journalsChecked: account.journals
                })
            );
        }
    }, [account, dispatch]);

    const onCancel = useCallback(() => {
        dispatch(
            updateUI({
                journalsChecked: []
            })
        );
        closeDialog();
    }, [dispatch]);

    const addJournal = useCallback(
        (id) => {
            const data = ui.journalsChecked || [];
            const index = data.indexOf(id);
            if (index >= 0) {
                data.splice(index, 1);
            } else {
                data.push(id);
            }

            dispatch(
                updateUI({
                    journalsChecked: data
                })
            );
        },
        [dispatch, ui.journalsChecked]
    );

    const createAccount = useCallback(
        (e) => {
            e.preventDefault();
            if (account) {
                dispatch(
                    BeneficiariesActions.edit(
                        {
                            name,
                            email,
                            phoneNumber,
                            journals: ui.journalsChecked,
                            isSelectedAll: selectAll
                        },
                        index
                    )
                );
            } else {
                dispatch(
                    BeneficiariesActions.create_account({
                        name,
                        email,
                        phoneNumber,
                        journals: ui.journalsChecked,
                        isSelectedAll: selectAll
                    })
                );
            }
            dispatch(
                updateUI({
                    journalsChecked: []
                })
            );
            dispatch(BeneficiariesActions.fetch());
            closeDialog();
        },
        [
            dispatch,
            email,
            selectAll,
            name,
            account,
            index,
            phoneNumber,
            ui.journalsChecked
        ]
    );

    return (
        <div id="add-beneficiary-modal" className="add-beneficiary-modal">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 beneficiary-modal-content">
                        <h3 className="settings-modal-title">
                            {account ? 'Edit' : 'Add'} Beneficiary
                        </h3>
                        <form className="settings-form settings-inputs beneficiary-form">
                            <div className="form-groups">
                                <div className="form-group">
                                    <label
                                        className="settings-label"
                                        htmlFor="name">
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        value={name}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        className="settings-input"
                                        id="name"
                                        placeholder=""
                                    />
                                </div>
                            </div>
                            <div className="form-groups">
                                <div className="form-group">
                                    <label
                                        className="settings-label"
                                        htmlFor="email">
                                        Email
                                    </label>
                                    <input
                                        type="text"
                                        className="settings-input"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        id="email"
                                        placeholder="david@saasuma.com"
                                    />
                                </div>
                                <div className="form-group">
                                    <label
                                        className="settings-label"
                                        htmlFor="email">
                                        Phone number
                                    </label>
                                    <PhoneInput
                                        international
                                        defaultCountry="RU"
                                        value={phoneNumber}
                                        onChange={(val) => {
                                            setPhoneNumber(val);
                                        }}
                                    />
                                </div>
                            </div>
                            <p className="settings-form-desc">
                                We will send an email to this contact to notify
                                them
                            </p>
                            <div className="available-beneficiary-content">
                                <div className="available-content-extra">
                                    <p>Available Content</p>
                                    <div className="form-group">
                                        <input
                                            type="checkbox"
                                            id="accessContent"
                                            name="accessContent"
                                            className="edit-default-journal-checkmark"
                                            checked={selectAll}
                                            onChange={(e) =>
                                                setSelectAll(e.target.checked)
                                            }
                                        />
                                        <label
                                            className="default-card-label"
                                            htmlFor="accessContent">
                                            Give full access to your content
                                        </label>
                                    </div>
                                </div>
                                <ul className="available-content-list scrollable-dropdown-list">
                                    {journals.journals?.map(
                                        (journal, index) => (
                                            <div
                                                key={index}
                                                className="journal-row">
                                                <li>
                                                    <span className="icon-journals"></span>
                                                    <div className="journal-post-item">
                                                        <div className="journal-post-title">
                                                            {journal.title}
                                                        </div>
                                                        <div className="journal-post-description">
                                                            {
                                                                journal.entry_count
                                                            }{' '}
                                                            Entries
                                                        </div>
                                                    </div>
                                                    <span
                                                        onClick={() =>
                                                            addJournal(
                                                                journal.id
                                                            )
                                                        }
                                                        className={`fa fa-check-circle ${
                                                            !ui.journalsChecked?.includes(
                                                                journal.id
                                                            )
                                                                ? 'checked'
                                                                : ''
                                                        }`}></span>
                                                </li>
                                                <li>
                                                    <span className="icon-journals"></span>
                                                    <div className="journal-post-item">
                                                        <div className="journal-post-title">
                                                            <h3>
                                                                {journal.title}
                                                            </h3>
                                                        </div>
                                                        <div className="journal-post-description">
                                                            {
                                                                journal.entry_count
                                                            }{' '}
                                                            Entries
                                                        </div>
                                                    </div>
                                                    <span
                                                        onClick={() =>
                                                            addJournal(
                                                                journal.id
                                                            )
                                                        }
                                                        className={`fa fa-check-circle ${
                                                            ui.journalsChecked?.includes(
                                                                journal.id
                                                            )
                                                                ? 'checked'
                                                                : ''
                                                        }`}></span>
                                                </li>
                                            </div>
                                        )
                                    )}
                                </ul>
                            </div>
                            <div className="form-card-actions justify-content-end">
                                <button
                                    className="btn btn-form-cancel"
                                    onClick={onCancel}>
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-form-add-card"
                                    onClick={createAccount}>
                                    <span className="fa fa-plus-circle"> </span>
                                    {account ? 'Edit' : 'Add'} Beneficiary
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    journals: getJournalList,
    ui: getUI
});

export default connect(state)(BeneficiaryDialog);
