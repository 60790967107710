import { PLAN_LABELS } from 'common/constant';
import React from 'react';
import { ModalForm, renderAlertMessage, buttonStatus } from './modals-utils';

export const FreeAccountDialog = ({
    onCloseDialogs,
    formData,
    onChange,
    register,
    setFormData
}) => {
    const title = 'Create your free account';
    return (
        <div id="checkout-free-modal" className="checkout-free-modal">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 checkout-plans-content">
                        <ModalForm
                            title={title}
                            renderAlertMessage={renderAlertMessage}
                            formData={formData}
                            setFormData={setFormData}
                            onChange={onChange}
                            buttonStatus={buttonStatus}
                            register={register}
                        />
                        <div className="checkout-plans__information">
                            <div className="checkout-plans__information-header">
                                <h3>
                                    {PLAN_LABELS.FREE}
                                    <span>On the house</span>
                                </h3>
                                <button
                                    className="btn btn-close-checkout"
                                    onClick={() => onCloseDialogs()}>
                                    <i className="icon-times-light" />
                                </button>
                            </div>
                            <ul className="checkout-plans__information-list">
                                <li>1 Journal with Unlimited Entries</li>
                                <li>Multiple Photos per Entry</li>
                                <li>2 GB of Storage</li>
                                <li>
                                    Share your Entries with other JRNL Users
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
