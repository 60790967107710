import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';

// Actions
import { connect } from 'react-redux';
import * as BookActions from 'redux/actions/book';
import * as BookOrderActions from 'redux/actions/bookorder';

// Selectors
import { createStructuredSelector } from 'reselect';
import {
    getBook,
    getUser,
    getBookList,
    getBookOrderList,
    getUI
} from 'redux/selectors';
import { updateUI } from 'redux/actions/ui';

import { isEmpty } from 'common/utils/arrayUtils';

// Dialogs
import BookCreateDialog from 'pages/Dialogs/BookCreateDialog';

// images
import bookBuilderImage from 'assets/images/banners/banner-books.png';
import noBookOrdersImage from 'assets/images/banners/banner-no-book-orders.png';
import withBook from 'common/utils/components/withBook';
import BannerCard from 'components/BannerCard';
import { DropdownMenu } from 'components';
import BookDeleteDialog from './book-delete-dialog';

// Icons
import iconAdd from 'assets/images/icons_svg/icon_add-sm.svg';
import iconEllipsisVert from 'assets/images/icons_svg/icon_ellipsis-vert.svg';
import iconBook from 'assets/images/icons_svg/icon_book.svg';
import iconBookDraft from 'assets/images/icons_svg/icon_book-draft.svg';
import EditBookDialog from 'pages/Dialogs/EditBookDialog';

const BookAndBookOrders = ({ dispatch, book, books, bookOrders, ui }) => {
    const [activeTab, setActiveTab] = useState(0);
    // router hooks
    const history = useHistory();
    const location = useLocation();
    const [bookDeleteModalData, setBookDeleteModalData] = useState({
        open: false,
        chosenBook: undefined
    });
    // set Active Tab according to the location path name
    useEffect(() => {
        switch (location.pathname) {
            case '/books/': {
                setActiveTab(0);
                break;
            }
            case '/book-orders/': {
                setActiveTab(1);
                break;
            }
            default: {
                break;
            }
        }
        return () => {};
    }, [location]);

    // handlers
    const onTabChange = useCallback(
        (tab) => {
            switch (tab) {
                case 0: {
                    history.push('/books/');
                    break;
                }
                case 1: {
                    history.push('/book-orders/');
                    break;
                }
                default: {
                    break;
                }
            }
        },
        [history]
    );

    const onNewBook = useCallback(() => {
        // TODO: open dialog for new book
        dispatch(
            updateUI({
                dialogs: [...ui.dialogs, BookCreateDialog]
            })
        );
    }, [dispatch, ui.dialogs]);

    const isBottom = (el) => {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    };
    const onBookScrolling = useCallback(() => {
        const wrappedElement = document.getElementById('myTabContent');
        if (isBottom(wrappedElement)) {
            if (book.meta?.next && book.isPending === false) {
                dispatch(
                    BookActions.fetch(
                        {
                            jrnlbookorder__isnull: true
                        },
                        book.meta?.next
                    )
                );
            }
        }
    }, [book, dispatch]);

    // component did mount
    useEffect(() => {
        window.addEventListener('scroll', onBookScrolling);
        return () => {
            window.removeEventListener('scroll', onBookScrolling);
        };
    }, [onBookScrolling]);

    useEffect(() => {
        dispatch(
            BookActions.fetch({
                jrnlbookorder__isnull: true,
                order_by: '-created'
            })
        );
        dispatch(BookOrderActions.fetch({ order_by: '-created' }));

        return () => {};
    }, [dispatch]);

    // render elements

    const renderTabs = useCallback(() => {
        const bookCount = books?.length;
        const bookOrderCount = bookOrders?.length;
        const bookActiveClassName = activeTab === 0 ? 'active' : '';
        const bookOrderActiveClassName = activeTab === 1 ? 'active' : '';
        return (
            <ul
                className="nav nav-tabs justify-content-around"
                id="myTab"
                role="tablist">
                <li
                    className={`nav-item journal-post-nav ${bookActiveClassName} w-50`}
                    id="journals-tab-fix">
                    <div
                        className={`nav-link ${
                            activeTab === 0 ? 'active' : ''
                        } journal-tabs`}
                        id="journals-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="books"
                        aria-selected="true"
                        onClick={() => onTabChange(0)}>
                        <img
                            src={iconBookDraft}
                            className="btn-icon btn-icon--6 mr-2"
                            alt="Drafts"
                        />
                        Drafts &nbsp;
                        <span className="journal-badge">{bookCount || 0}</span>
                    </div>
                </li>
                <li
                    className={`nav-item journal-post-nav ${bookOrderActiveClassName} w-50`}
                    id="tags-tab-fix">
                    <div
                        className="nav-link journal-tabs active"
                        id="tags-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="orders"
                        aria-selected="true"
                        onClick={() => onTabChange(1)}>
                        <img
                            src={iconBook}
                            className="btn-icon btn-icon--6 mr-2"
                            alt="Books"
                        />
                        Orders
                        <span className="journal-badge">
                            {' '}
                            {bookOrderCount || 0}
                        </span>
                    </div>
                </li>
            </ul>
        );
    }, [activeTab, bookOrders, books, onTabChange]);
    const handleDeleteBook = (bookId) => {
        dispatch(BookActions.remove({ id: bookId }));
        setBookDeleteModalData((prevState) => {
            return { ...prevState, open: false };
        });
    };

    const openBookCreationDialog = useCallback(
        (book) => {
            dispatch(
                updateUI({
                    dialogs: [...ui.dialogs, withBook(EditBookDialog, book.id)]
                })
            );
        },
        [dispatch, ui.dialogs]
    );
    const renderBookListItem = useCallback(
        (book) => {
            const created = moment
                .utc(book.created)
                .local()
                .format('M/DD/YYYY');
            const modified = moment
                .utc(book.modified)
                .local()
                .format('M/DD/YYYY');
            return (
                <div className="list__item" key={book.id}>
                    <div className="list__img list__img--icon-md">
                        <img
                            src={iconBookDraft}
                            className="btn-icon btn-icon--6"
                            alt=""
                        />
                    </div>
                    <div className="list__content">
                        <div
                            className="list__title list__title--link truncate"
                            onClick={() => openBookCreationDialog(book)}>
                            <span>{book.title}</span>
                        </div>
                        <div className="list__subtitle">
                            Created: {created} - Modified: {modified}
                        </div>
                    </div>
                    <div className="list__options">
                        <DropdownMenu
                            anchor={
                                <img
                                    src={iconEllipsisVert}
                                    className="dropdown-trigger btn-icon btn-icon--4"
                                    alt=""
                                />
                            }
                            placement="bottom">
                            <span
                                key={'edit'}
                                onClick={() => openBookCreationDialog(book)}>
                                Edit Book
                            </span>
                            <div
                                key={'delete'}
                                onClick={() =>
                                    setBookDeleteModalData({
                                        ...bookDeleteModalData,
                                        open: true,
                                        chosenBook: book
                                    })
                                }>
                                Delete Book
                            </div>
                        </DropdownMenu>
                    </div>
                </div>
            );
        },
        // eslint-disable-next-line
        [openBookCreationDialog]
    );
    const renderBookTabPanel = useCallback(() => {
        const activeClassName = activeTab === 0 ? 'show active' : '';
        return (
            <div
                className={`tab-pane book-tab-pane fade ${activeClassName}`}
                id="books"
                role="tabpanel"
                aria-labelledby="journals-tab">
                <div className="tab-pane__actions">
                    <button
                        onClick={onNewBook}
                        className="btn btn-books btn-gradient"
                        id="newBook">
                        New Book
                        <img className="right" src={iconAdd} alt="" />
                    </button>
                </div>
                <div className="list list--page">
                    {books && books.map((book) => renderBookListItem(book))}
                </div>
            </div>
        );
    }, [activeTab, books, renderBookListItem, onNewBook]);

    const renderBookOrderListItem = useCallback((bookOrder) => {
        const created = moment
            .utc(bookOrder.created)
            .local()
            .format('M/DD/YYYY');

        const showPDF = () => {
            window.open(bookOrder.downloadable_pdf, '_blank');
        };

        return (
            <div key={bookOrder.id} className="list__item">
                <div onClick={showPDF} className="list__img list__img--icon-md">
                    <img
                        src={iconBook}
                        className="btn-icon btn-icon--6"
                        alt=""
                    />
                </div>
                <div className="list__content">
                    <div
                        onClick={showPDF}
                        className="list__title list__title--link ">
                        <span>{bookOrder.title}</span>
                    </div>
                    <div className="list__subtitle">Ordered: {created}</div>
                </div>
            </div>
        );
    }, []);
    const renderBookOrderTabPanel = useCallback(() => {
        const activeClassName = activeTab === 1 ? 'show active' : '';
        return (
            <div
                className={`tab-pane book-tab-pane fade ${activeClassName}`}
                id="orders"
                role="tabpanel"
                aria-labelledby="tags-tab">
                <div className="list list--page">
                    {bookOrders &&
                        bookOrders.map((bookOrder) =>
                            renderBookOrderListItem(bookOrder)
                        )}
                </div>
            </div>
        );
    }, [activeTab, bookOrders, renderBookOrderListItem]);

    const showBooksBanner = useCallback(() => {
        const bookOrderEmpty = !bookOrders || isEmpty(bookOrders);
        if (activeTab === 1 && bookOrderEmpty) {
            return (
                <BannerCard
                    bannerClassName={
                        'no-orders banner-card--dark no-books-card'
                    }
                    image={noBookOrdersImage}
                    title={'No Book Orders Yet'}
                    text={
                        'Your book orders will appear here. Create a new Book to start an Order.'
                    }
                    id={'no-books-card'}
                />
            );
        }
        return (
            <BannerCard
                bannerClassName={'bg-books banner-card--dark bookbuilder-card'}
                image={bookBuilderImage}
                title={'Bookbuilder'}
                text={'Publish beautiful archival-quality books.'}
                id={'bookbuilder-card'}
            />
        );
    }, [activeTab, bookOrders]);

    return (
        <>
            <div onScroll={onBookScrolling}>
                <section
                    className="books-section centered-items"
                    id="books-section">
                    <div className={`page-container ${ui.viewWidth}`}>
                        {showBooksBanner()}
                        <div className="page">
                            {renderTabs()}
                            <div className="tab-content" id="myTabContent">
                                {renderBookTabPanel()}
                                {renderBookOrderTabPanel()}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <BookDeleteDialog
                open={bookDeleteModalData.open}
                onDelete={handleDeleteBook}
                onCancel={() =>
                    setBookDeleteModalData((prevState) => {
                        return { ...prevState, open: false };
                    })
                }
                book={bookDeleteModalData.chosenBook}
            />
        </>
    );
};

const state = createStructuredSelector({
    user: getUser,
    book: getBook,
    books: getBookList,
    bookOrders: getBookOrderList,
    ui: getUI
});
export default connect(state)(BookAndBookOrders);
