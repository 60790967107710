import React, { useEffect, useCallback, useRef } from 'react';
// redux
import { connect } from 'react-redux';
// Selectors
import { getUI } from 'redux/selectors';
import { createStructuredSelector } from 'reselect';
// Actions
import { updateUI, updateStepsFilledIn } from 'redux/actions/ui';
// utils
import { checkPhotoSetup } from 'common/utils/validatecheck/bookcreate';

const PhotosOptionsStep = ({ display, ui, dispatch }) => {
    // check validability
    const photos = ui.photos;
    const comments = ui.comments;

    const updateStepsFilledIn_POS = useCallback(
        (val = false) => {
            dispatch(updateStepsFilledIn('PhotosOptionsStep', val));
        },
        [dispatch]
    );

    const initialRenderRef = useRef(true);

    useEffect(() => {
        if (initialRenderRef.current) {
            initialRenderRef.current = false;
            updateStepsFilledIn_POS(true);
        }
    }, [updateStepsFilledIn_POS]);

    useEffect(() => {
        if (display) {
            dispatch(
                updateUI({
                    canGoNext: checkPhotoSetup(photos, comments)
                })
            );
        }
        return () => {};
    }, [display, photos, dispatch, comments]);

    // handlers
    const onChangePhoto = useCallback(
        (photos) => {
            dispatch(
                updateUI({
                    photos
                })
            );
        },
        [dispatch]
    );

    const onChangeComment = useCallback(
        (comment) => {
            dispatch(
                updateUI({
                    comments: comment
                })
            );
        },
        [dispatch]
    );

    return (
        <div
            className={`new-book-step-6 new-book-step ${
                display ? 'active-form' : ''
            }`}>
            <div className="section-title">Photos Options</div>
            <div className="new-book-step-form">
                <div className="photos-options">
                    <div className="form-group">
                        <div className="radio-item">
                            <input
                                onClick={() => onChangePhoto(true)}
                                onChange={() => {}}
                                className="radio"
                                type="radio"
                                id="photos"
                                name="photos-options"
                                checked={ui.photos === true}
                            />
                            <label
                                onClick={() => onChangePhoto(true)}
                                htmlFor="photos">
                                Print photos
                            </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="radio-item">
                            <input
                                onClick={() => onChangePhoto(false)}
                                onChange={() => {}}
                                className="radio"
                                type="radio"
                                id="noPhotos"
                                name="photos-options"
                                checked={ui.photos === false}
                            />
                            <label
                                onClick={() => onChangePhoto(false)}
                                htmlFor="noPhotos">
                                Do not print photos
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-title">Comment Options</div>
            <div className="new-book-step-form">
                <div className="photos-options">
                    <div className="form-group">
                        <div className="radio-item">
                            <input
                                onClick={() => onChangeComment(true)}
                                onChange={() => {}}
                                className="radio"
                                type="radio"
                                id="comments"
                                name="comments-options"
                                checked={ui.comments === true}
                            />
                            <label
                                onClick={() => onChangeComment(true)}
                                htmlFor="comments">
                                Print Entry comments
                            </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="radio-item">
                            <input
                                onClick={() => onChangeComment(false)}
                                onChange={() => {}}
                                className="radio"
                                type="radio"
                                id="noComments"
                                name="comments-options"
                                checked={
                                    ui.comments === false ||
                                    ui.comments === undefined
                                }
                            />
                            <label
                                onClick={() => onChangeComment(false)}
                                htmlFor="noComments">
                                Do not print Entry comments
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    ui: getUI
});
export default connect(state)(PhotosOptionsStep);
