import {
    INSERT_IMAGE_BLOCK,
    UPDATE_EDITOR_STATE,
    CREATE_ENTRY_IMAGE,
    UPDATE_ENTRY_IMAGE,
    RESET_IMAGE_STATE,
    UNSTAGE_ENTRY_IMAGE,
    UNSTAGE_ALL_IMAGES,
    ACTION_USER_LOGOUT,
    CREATE_ENTRY_IMAGE_PROGRESS,
    REMOVE_EDITOR_NEWDATA,
    CREATE_ENTRY_MEDIA,
    SET_MEDIA_TYPE,
    INSERT_MEDIA_BLOCK,
    UNSTAGE_ENTRY_MEDIA,
    INSERT_INSTAGRAM_CONTENT,
    UPDATE_IMAGE_PROGRESS,
    UPDATE_FILES_LIST,
    RESET_FILES_LIST
} from 'redux/actions/actionTypes';
import {
    SUCCESS_SUFFIX,
    ERROR_SUFFIX,
    ENTRYEDITOR_NEWDATA_TYPES
} from 'common/constant';
import { EditorState } from 'addon-draft-js';

const initialState = {
    editorState: EditorState.createEmpty(),
    isPending: false,
    newData: {},
    entry_images: [],
    staged_entry_images: [],
    entry_images_order: [],
    staged_entry_media: [],
    entry_media_order: [],
    mediaType: null,
    progress: 0,
    files: {}
};

const EditorReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_FILES_LIST: {
            return {
                ...state,
                files: {}
            };
        }
        case UPDATE_FILES_LIST: {
            return {
                ...state,
                files: {
                    ...state.files,
                    [action.payload.id]: action.payload.percentage
                }
            };
        }
        case UPDATE_IMAGE_PROGRESS: {
            return {
                ...state,
                files: {
                    ...state.files,
                    [action.payload.file_id]: action.payload.progress
                }
            };
        }

        case UPDATE_EDITOR_STATE: {
            return {
                ...state,
                newData: {
                    type: 'clear'
                }
            };
        }

        case REMOVE_EDITOR_NEWDATA: {
            return {
                ...state,
                newData: {}
            };
        }

        case INSERT_IMAGE_BLOCK: {
            return {
                ...state,
                newData: {
                    type: ENTRYEDITOR_NEWDATA_TYPES.IMAGE,
                    data: action.payload.images
                }
            };
        }

        case INSERT_INSTAGRAM_CONTENT: {
            return {
                ...state,
                newData: {
                    type: ENTRYEDITOR_NEWDATA_TYPES.INSTAGRAM,
                    data: action.payload.content
                }
            };
        }

        case CREATE_ENTRY_IMAGE: {
            let fileName;
            try {
                fileName = action.payload?.request.data.get('file_name');
            } catch (e) {
                if (action?.payload?.request?.options?.fileName) {
                    fileName =
                        action &&
                        action.payload &&
                        action.payload.request &&
                        action.payload.request.options &&
                        action.payload.request.options.fileName;
                } else {
                    fileName = undefined;
                }
            }
            const entryOrder = state.entry_images_order;
            entryOrder.push(fileName);
            return {
                ...state,
                isPending: true,
                entry_images_order: entryOrder
            };
        }

        case CREATE_ENTRY_IMAGE_PROGRESS: {
            let fileName;
            try {
                fileName = action.payload.data.get('file_name');
            } catch (e) {
                if (action?.payload?.options?.fileName) {
                    fileName = action.payload.options.fileName;
                } else {
                    fileName = undefined;
                }
            }
            const entryOrder = state.entry_images_order;
            entryOrder.push(fileName);
            return {
                ...state,
                isPending: true,
                entry_images_order: entryOrder
            };
        }

        case CREATE_ENTRY_IMAGE_PROGRESS + SUCCESS_SUFFIX:
        case CREATE_ENTRY_IMAGE + SUCCESS_SUFFIX: {
            const index = state.entry_images.find(
                (image) => action.payload.data.id === image.id
            );
            let images, staged_entry_images;

            if (index >= 0) {
                images = [].concat(
                    state.entry_images.slice(0, index),
                    [action.payload.data],
                    state.entry_images.slice(index + 1)
                );
            } else {
                images = [].concat(
                    [action.payload.data],
                    state.entry_images || []
                );
            }

            if (action.payload.data.entry === null) {
                staged_entry_images = state.staged_entry_images || [];
                staged_entry_images.push(action.payload.data);
            } else {
                staged_entry_images = state.staged_entry_images.filter(
                    (image) => image.id !== action.payload.data.id
                );
            }

            return {
                ...state,
                isPending: false,
                entry_images: images,
                staged_entry_images
            };
        }

        case UPDATE_ENTRY_IMAGE + SUCCESS_SUFFIX: {
            const images = state.entry_images.filter(
                (image) => image.id !== action.payload.data.id
            );
            const staged_images = state.entry_images.filter(
                (image) => image.id !== action.payload.data.id
            );
            return {
                ...state,
                entry_images: images,
                staged_entry_images: staged_images
            };
        }

        case RESET_IMAGE_STATE: {
            return {
                ...state,
                entry_images: [],
                entry_images_order: []
            };
        }

        case UNSTAGE_ENTRY_IMAGE: {
            const staged_entry_images = state.staged_entry_images.filter(
                (image) => image.image !== action.payload.data.src
            );
            return {
                ...state,
                staged_entry_images
            };
        }

        case UNSTAGE_ALL_IMAGES: {
            return {
                ...state,
                staged_entry_images: []
            };
        }

        case ACTION_USER_LOGOUT: {
            return initialState;
        }

        case INSERT_MEDIA_BLOCK: {
            return {
                ...state,
                newData: {
                    type: state.mediaType,
                    data: action.payload.file
                }
            };
        }

        case SET_MEDIA_TYPE: {
            return {
                ...state,
                mediaType: action.data.type
            };
        }

        case CREATE_ENTRY_MEDIA: {
            return {
                ...state,
                isPending: true,
                entry_media_order: state.entry_media_order
            };
        }

        case CREATE_ENTRY_MEDIA + SUCCESS_SUFFIX: {
            state.entry_media_order.push(action.payload.data);
            state.staged_entry_media.push(action.payload.data);
            return {
                ...state,
                isPending: false,
                entry_media_order: state.entry_media_order,
                staged_entry_media: state.staged_entry_media
            };
        }

        case CREATE_ENTRY_MEDIA + ERROR_SUFFIX: {
            return {
                ...state,
                isPending: false
            };
        }

        case UNSTAGE_ENTRY_MEDIA: {
            return {
                ...state,
                staged_entry_media: []
            };
        }

        default: {
            return state;
        }
    }
};

export default EditorReducer;
