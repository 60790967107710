import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import _ from 'underscore';
import { EntryEditor } from 'components';
import {
    ENTRY_DRAFT,
    ENTRY_PUBLISHED,
    ENTRY_SHOWN,
    SOCIAL_TYPE_FACEBOOK,
    SOCIAL_TYPE_GOOGLE,
    SOCIAL_TYPE_INSTAGRAM,
    HTTP_CODES
} from 'common/constant';
import arrayUtils from 'common/utils/arrayUtils';
import {
    compareDate,
    dateISO,
    nowIso,
    ISO_DATE_FORMAT
} from 'common/utils/dateUtils';
import { openDialog, openDialogWithUIProps } from 'common/utils/dialog-utils';
import withSocialType from 'common/utils/components/withSocialType';
import withFreeAccountCheck from 'common/utils/components/withFreeAccountCheck';
import {
    writeSentryLog,
    writeSentryLogForValidation
} from 'common/utils/sentryErrorUtils';

import { updateUI } from 'redux/actions/ui';
import * as EntryActions from 'redux/actions/entry';
import * as EditorActions from 'redux/actions/editor';
import * as EntryImageActions from 'redux/actions/entryimage';
import * as InvitationActions from 'redux/actions/invitation';
import * as JournalActions from 'redux/actions/journal';
import {
    getDefaultJournal,
    getEditorState,
    getEntryState,
    getFilters,
    getJournalList,
    getTagList,
    getUI,
    getUser,
    getFilteredLastEntry
} from 'redux/selectors';
import { getUINewEntry } from 'redux/selectors/UISelector';

import MediaManagerDialog from 'pages/Dialogs/MediaManagerDialog';
import SocialMediaPhotosDialog from 'pages/Dialogs/SocialMedia/SocialMediaPhotosDialog';
import DateTimeDialog from 'pages/Dialogs/DateTimeDialog';
import SocialConnectDialog from 'pages/Dialogs/SocialMedia/SocialConnectDialog';
import EntrySaveIssueDialog from 'pages/Dialogs/EntrySaveIssueDialog';
import { iosMediaPermission } from '../../components/media/Permission';

const EntryCreate = ({
    ui,
    user,
    journal,
    defaultJournal,
    filters,
    editor,
    draftEntry,
    dispatch,
    entry,
    tag,
    reading_type,
    entryDefaultJournal,
    newEntry: propsNewEntry,
    lastEntry,
    ...props
}) => {
    const [newEntryJournal, setNewEntryJournal] = useState(null);
    // autosave parameters
    const [autoSave, setAutoSave] = useState(false); // the param to inform entryeditor to save the current content
    const [isSaved, setIsSaved] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [created, setCreated] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);
    const [didMount, setDidMount] = useState(false);
    const [exactJournal, setExactJournal] = useState({});
    const [journalID, setJournalID] = useState('');
    const [isError, setIsError] = useState(false);

    const uiNewEntry = React.useMemo(() => {
        return propsNewEntry ? JSON.parse(propsNewEntry) : null;
    }, [propsNewEntry]);

    const uiNewEntryTagsSZ =
        uiNewEntry && uiNewEntry.tags ? JSON.stringify(uiNewEntry.tags) : null;
    const stagedEntryImagesSZ =
        editor && editor.staged_entry_images
            ? JSON.stringify(editor.staged_entry_images)
            : null;

    // new entry tags
    const uiEntryTags = React.useMemo(() => {
        return uiNewEntryTagsSZ ? JSON.parse(uiNewEntryTagsSZ) : null;
    }, [uiNewEntryTagsSZ]);

    // staged Entry images
    const stagedEntryImages = React.useMemo(() => {
        return stagedEntryImagesSZ ? JSON.parse(stagedEntryImagesSZ) : null;
    }, [stagedEntryImagesSZ]);

    const autoSaveTimer = useRef(null);

    useEffect(() => {
        if (!didMount) {
            // Reset Editor
            dispatch(EditorActions.clearEditor());
            dispatch(EditorActions.resetImageState());
            dispatch(EditorActions.unstageImages());

            dispatch(
                updateUI({
                    showSnackbar: false,
                    showSnackbarFor: undefined,
                    newEntry: {
                        display_time: true,
                        entry_date: nowIso(),
                        journal: defaultJournal,
                        state: ENTRY_SHOWN
                    }
                })
            );
            setDidMount(true);
        }
        return function () {
            if (!didMount) {
                dispatch(
                    updateUI({
                        fullView: false
                    })
                );
            }
        };
    }, [dispatch, defaultJournal, didMount]);

    useEffect(() => {
        if (
            !uiNewEntry?.dateChanged &&
            filters?.maxDate &&
            uiNewEntry?.entry_date &&
            filters.maxDate.format(ISO_DATE_FORMAT) !== uiNewEntry?.entry_date
        ) {
            dispatch(
                updateUI({
                    newEntry: {
                        entry_date: filters.maxDate
                            .utc()
                            .format(ISO_DATE_FORMAT),
                        display_time: uiNewEntry?.display_time
                    }
                })
            );
        }
    }, [dispatch, filters, uiNewEntry]);

    useEffect(() => {
        const journal_id = new URLSearchParams(props.location?.search).get(
            'journal'
        );
        setJournalID(journal_id);
    }, [props.location]);

    const newEntryId = uiNewEntry?.id;
    useEffect(() => {
        if (newEntryId) {
            setCreated(true);
        }
    }, [newEntryId]);

    useEffect(() => {
        if (!journal?.journals) {
            dispatch(JournalActions.fetch());
        }
    }, [dispatch, journal.journals]);

    const getJournal = useCallback(
        (jrnlId) => {
            if (journal?.journals) {
                const jid = Number(jrnlId || journalID);
                return (
                    (journal?.journals || []).find(
                        (journal) => journal.id === jid
                    ) || defaultJournal
                );
            }
        },
        [journal.journals, defaultJournal, journalID]
    );

    const onEditorChange = useCallback(() => {
        setHasChanged((prev) => {
            if (!prev) {
                setIsSaved(false);
                return true;
            }
            return prev;
        });
    }, []);

    const onInsertImage = useCallback(() => {
        dispatch(
            updateUI({
                dialogs: [iosMediaPermission(MediaManagerDialog)]
            })
        );
    }, [dispatch]);

    const onInsertInstagram = useCallback(() => {
        dispatch(
            updateUI({
                dialogs: [
                    withSocialType(
                        iosMediaPermission(SocialMediaPhotosDialog),
                        SOCIAL_TYPE_INSTAGRAM
                    )
                ]
            })
        );
    }, [dispatch]);

    const onInsertFaceBook = useCallback(() => {
        dispatch(
            updateUI({
                dialogs: [
                    withSocialType(
                        iosMediaPermission(SocialMediaPhotosDialog),
                        SOCIAL_TYPE_FACEBOOK
                    )
                ]
            })
        );
    }, [dispatch]);

    const onInsertGoogle = useCallback(() => {
        dispatch(
            updateUI({
                dialogs: [
                    withFreeAccountCheck(
                        withSocialType(
                            iosMediaPermission(SocialConnectDialog),
                            SOCIAL_TYPE_GOOGLE
                        ),
                        true
                    )
                ]
            })
        );
    }, [dispatch]);

    const onRemoveImage = useCallback(
        (src) => {
            dispatch(EditorActions.unstageEntryImage({ src }));
        },
        [dispatch]
    );

    const setEntryDate = useCallback(
        (date) => {
            dispatch(
                updateUI({
                    newEntry: {
                        entry_date: dateISO(date)
                    }
                })
            );
        },
        [dispatch]
    );

    const onEntryDateUpdate = useCallback(
        (entry) => {
            dispatch(
                updateUI(
                    {
                        newEntry: {
                            display_time: entry.display_time,
                            entry_date: entry.entry_date,
                            dateChanged: entry?.dateChanged || false
                        }
                    },
                    false
                )
            );
        },
        [dispatch]
    );

    const onEntryDateClick = useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();

            openDialogWithUIProps(
                DateTimeDialog,
                { onUpdate: onEntryDateUpdate },
                [
                    {
                        propField: 'entry',
                        uiField: 'newEntry'
                    }
                ]
            );
        },
        [onEntryDateUpdate]
    );

    const onEntryJournalUpdate = useCallback(
        (journal) => {
            dispatch(
                updateUI(
                    {
                        newEntry: {
                            journal
                        }
                    },
                    false
                )
            );
        },
        [dispatch]
    );

    const onEntryTagUpdate = useCallback(
        (tag, isAdd) => {
            let tags = uiEntryTags || [];
            if (isAdd) {
                tags.push(tag.resource_uri);
            } else {
                tags = tag?.map((items) => items.resource_uri);
            }

            dispatch(
                updateUI(
                    {
                        newEntry: {
                            tags
                        }
                    },
                    false
                )
            );
        },
        [dispatch, uiEntryTags]
    );

    const onEntryTitleUpdate = useCallback(() => {
        setHasChanged(true);
        setIsSaved(false);
    }, []);

    const clearAutoSaving = useCallback((finish = false) => {
        setAutoSave(false);
        if (finish === false) {
            setIsSaved(true);
        } else {
            setIsSaved(false);
        }
        setIsSaving(false);
        setHasChanged(false);
    }, []);

    const handleWithUpdateEntry = useCallback(
        (entry, staged_entry_images, response, finish, sharedUsers) => {
            if (finish === true) {
                dispatch(
                    updateUI({
                        newEntry: {
                            display_time: entry.display_time,
                            entry_date: entry.entry_date,
                            state: ENTRY_SHOWN
                        }
                    })
                );

                dispatch(EditorActions.clearEditor());
                dispatch(EditorActions.resetImageState());
                clearInterval(autoSaveTimer.current);
                setCreated(false);

                const entryInvitations = response?.payload?.data?.invitations;
                if (entryInvitations) {
                    dispatch(
                        InvitationActions.sendInvitationEmails([
                            ...entryInvitations
                        ])
                    );
                }

                dispatch(
                    updateUI({
                        publicEntry: false
                    })
                );
            } else {
                dispatch(
                    updateUI(
                        {
                            newEntry: {
                                id: response?.payload?.data?.id
                            }
                        },
                        false
                    )
                );
                setCreated(true);
            }

            // invite all the users
            const shared_users = sharedUsers || [];

            if (shared_users.length > 0) {
                dispatch(
                    InvitationActions.inviteUsers(
                        [...shared_users],
                        response?.payload?.data?.id
                    )
                );
            }

            if (staged_entry_images.length > 0) {
                let _i, _len;
                const _ref = staged_entry_images;
                for (_i = 0, _len = _ref.length; _i < _len; _i++) {
                    const image = _ref[_i];
                    dispatch(
                        EntryImageActions.update(
                            { entry: response?.payload?.data?.id },
                            image.id
                        )
                    );
                }
            }

            // clear auto saving features
            clearAutoSaving(finish);
        },
        [clearAutoSaving, dispatch]
    );

    // set timer for auto saving
    const saveIfChange = useCallback(() => {
        if (
            hasChanged &&
            !isSaved &&
            !isSaving &&
            !tag?.isPending &&
            !entry?.isPending
        ) {
            if (!autoSave) {
                setAutoSave(true);
            }
            setHasChanged(false);
            setIsSaved(true);
        }
    }, [hasChanged, isSaved, isSaving, autoSave, entry, tag]);

    useEffect(() => {
        clearInterval(autoSaveTimer.current);
        const timer = setInterval(saveIfChange, 10000);
        autoSaveTimer.current = timer;
    }, [saveIfChange]);

    const entryDateForSocial = useCallback(
        (finish) => {
            if (finish) {
                if (ui.use_instagram_date || ui.use_facebook_date) {
                    dispatch(
                        updateUI({
                            newEntry: {
                                entry_date: nowIso()
                            }
                        })
                    );
                }
            }
        },
        [ui, dispatch]
    );

    const getHandleUpdateFlag = useCallback(
        (data) => {
            switch (filters.entrySort) {
                case 'entry_date':
                case '-entry_date':
                    return compareDate(data.entry_date, lastEntry?.entry_date);
                case 'created':
                case '-created':
                    return compareDate(data.created, lastEntry?.entry_date);
                default:
                    return true;
            }
        },
        [filters.entrySort, lastEntry]
    );

    const setRequestError = useCallback(
        (err) => {
            if (
                err.error?.status === 0 ||
                HTTP_CODES.error.includes(err.error?.response?.status)
            ) {
                openDialog(EntrySaveIssueDialog, {
                    errorMsg:
                        'Poor connection: Saving is not possible at the moment.'
                });
                setIsSaving(false);
                setIsError(true);
            } else if (err.error?.status === HTTP_CODES.BAD_REQUEST) {
                writeSentryLogForValidation(
                    entry,
                    user,
                    ui,
                    journal,
                    defaultJournal,
                    filters,
                    editor,
                    tag,
                    propsNewEntry,
                    err
                );
            } else {
                writeSentryLog(err);
            }
        },
        [
            defaultJournal,
            editor,
            entry,
            journal,
            filters,
            propsNewEntry,
            tag,
            ui,
            user
        ]
    );

    const entryCreate = useCallback(
        (entry, finish, sharedUsers) => {
            dispatch(EntryActions.create(entry))
                .then((response) => {
                    if (response?.payload?.status === HTTP_CODES.CREATED) {
                        const handleUpdateFlag = getHandleUpdateFlag(
                            response?.payload?.data
                        );

                        if (handleUpdateFlag) {
                            dispatch(
                                EntryActions.fetchIndividualEntry(
                                    response.payload.data.id
                                )
                            ).then((res) => {
                                if (res?.payload?.status === HTTP_CODES.OK) {
                                    setIsSaving(false);
                                    handleWithUpdateEntry(
                                        entry,
                                        stagedEntryImages,
                                        res,
                                        finish,
                                        sharedUsers
                                    );
                                    dispatch(EditorActions.unstageImages());
                                    entryDateForSocial(finish);
                                }
                            });
                        } else {
                            setIsSaving(false);

                            if (finish === true) {
                                dispatch(
                                    updateUI({
                                        newEntry: {
                                            display_time: entry.display_time,
                                            entry_date: entry.entry_date,
                                            state: ENTRY_SHOWN
                                        }
                                    })
                                );

                                dispatch(EditorActions.clearEditor());
                                dispatch(EditorActions.resetImageState());
                                clearInterval(autoSaveTimer.current);
                                dispatch(updateUI({ publicEntry: false }));
                            } else {
                                dispatch(
                                    updateUI(
                                        {
                                            newEntry: {
                                                id: response?.payload?.data?.id
                                            }
                                        },
                                        false
                                    )
                                );
                            }
                            setCreated(!finish);
                            // clear auto saving features
                            clearAutoSaving(finish);
                            entryDateForSocial(finish);
                        }
                    }
                })
                .catch((err) => {
                    console.error('Error::', err);
                    setRequestError(err);
                });
        },
        [
            dispatch,
            handleWithUpdateEntry,
            stagedEntryImages,
            entryDateForSocial,
            clearAutoSaving,
            getHandleUpdateFlag,
            setRequestError
        ]
    );

    const onSave = useCallback(
        (entry, finish = false, journals, sharedUsers) => {
            const journals_ = journals.filter((j) => j?.id);
            setIsSaving(true);
            setAutoSave(false);

            let entryDate =
                (uiNewEntry && uiNewEntry.entry_date) || entry.entry_date;
            if (entryDate === 'Today') {
                entryDate = nowIso();
            }

            entry = {
                ...entry,
                entry_date: entryDate,
                display_time: uiNewEntry.display_time
            };

            if (entry.tags && !arrayUtils.isEmpty(entry.tags)) {
                entry.tags = created
                    ? entry.tags.map((tag) => tag.resource_uri)
                    : entry.tags.map((tag) => tag.id);
            } else {
                entry.tags = [];
            }

            if (!user.is_free) {
                // set journal for entry : different between create and update entry
                entry.extra_journals = (journals_ || []).map(
                    (extra_journal) => {
                        return created
                            ? extra_journal?.resource_uri
                            : extra_journal?.id;
                    }
                );
            }

            if (!arrayUtils.isEmpty(exactJournal)) {
                // @ts-ignore
                entry.journal = exactJournal.id;
            } else if (!arrayUtils.isEmpty(entry.extra_journals)) {
                entry.journal = entry.extra_journals[0];
            }
            entry.status = finish ? ENTRY_PUBLISHED : ENTRY_DRAFT;

            if (created === true) {
                if (!arrayUtils.isEmpty(exactJournal)) {
                    // @ts-ignore
                    entry.journal = exactJournal.resource_uri;
                }
                dispatch(EntryActions.update(entry, entry.id))
                    .then((response) => {
                        setIsSaving(false);
                        if (
                            HTTP_CODES.success.includes(
                                response?.payload?.status
                            )
                        ) {
                            const handleUpdateFlag = getHandleUpdateFlag(
                                response.payload.data
                            );
                            if (handleUpdateFlag) {
                                dispatch(
                                    EntryActions.fetchIndividualEntry(
                                        response.payload.data.id
                                    )
                                ).then((res) => {
                                    if (
                                        HTTP_CODES.success.includes(
                                            res?.payload?.status
                                        )
                                    ) {
                                        handleWithUpdateEntry(
                                            entry,
                                            stagedEntryImages,
                                            res,
                                            finish,
                                            sharedUsers
                                        );
                                        dispatch(EditorActions.unstageImages());
                                        if (finish) {
                                            if (
                                                ui.use_instagram_date ||
                                                ui.use_facebook_date
                                            ) {
                                                setEntryDate();
                                            }
                                        }
                                    }
                                });
                            } else {
                                setIsSaving(false);
                                if (
                                    ui.use_instagram_date ||
                                    ui.use_facebook_date
                                ) {
                                    setEntryDate();
                                }

                                if (finish === true) {
                                    dispatch(EditorActions.clearEditor());
                                    dispatch(EditorActions.resetImageState());
                                    clearInterval(autoSaveTimer.current);
                                    dispatch(updateUI({ publicEntry: false }));
                                }
                                setCreated(!finish);
                                // clear auto saving features
                                clearAutoSaving(finish);
                                entryDateForSocial(finish);
                            }
                        }
                    })
                    .catch((err) => {
                        setRequestError(err);
                    });
            } else {
                entryCreate(entry, finish, sharedUsers);
            }
        },
        [
            user.is_free,
            entryCreate,
            exactJournal,
            dispatch,
            stagedEntryImages,
            uiNewEntry,
            created,
            handleWithUpdateEntry,
            ui,
            clearAutoSaving,
            entryDateForSocial,
            setEntryDate,
            setRequestError,
            getHandleUpdateFlag
        ]
    );

    // set newEntryJournal
    useDeepCompareEffectNoCheck(() => {
        const defaultJournal = getJournal();
        // only when filter is active
        setNewEntryJournal(
            (entryDefaultJournal && [entryDefaultJournal]) ||
                filters.filterByJournal || [defaultJournal]
        );
    }, [filters.filterByJournal, getJournal, entryDefaultJournal]);

    const isFreeUserAndNot_on_DefaultJournal = useCallback(() => {
        return (
            _.findIndex(
                filters.filterByJournal || [],
                (journal) => journal?.journal_type !== 'Default'
            ) >= 0 && user.is_free
        );
    }, [filters.filterByJournal, user]);
    return (
        <div className="entry-editor">
            {!isFreeUserAndNot_on_DefaultJournal() ? (
                <EntryEditor
                    created={created}
                    setExactJournal={setExactJournal}
                    entry={uiNewEntry || {}}
                    onSave={onSave}
                    journal={newEntryJournal}
                    user={user}
                    isSaving={isSaving}
                    isSaved={isSaved}
                    isError={isError}
                    canSave={!isSaving || created}
                    autoSave={autoSave}
                    onEntryDateClick={onEntryDateClick}
                    onEntryJournalUpdate={onEntryJournalUpdate}
                    onEntryTagUpdate={onEntryTagUpdate}
                    onEntryTitleUpdate={onEntryTitleUpdate}
                    onChange={onEditorChange}
                    onInsertImage={onInsertImage}
                    onInsertInstagram={onInsertInstagram}
                    onInsertFacebook={onInsertFaceBook}
                    onInsertGoogle={onInsertGoogle}
                    onRemoveImage={onRemoveImage}
                />
            ) : null}
        </div>
    );
};

const state = createStructuredSelector({
    user: getUser,
    ui: getUI,
    journal: getJournalList,
    defaultJournal: getDefaultJournal,
    filters: getFilters,
    editor: getEditorState,
    entry: getEntryState,
    tag: getTagList,
    newEntry: getUINewEntry,
    lastEntry: getFilteredLastEntry
});
export default React.memo(connect(state)(EntryCreate));
