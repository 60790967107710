import { checkUserSame } from 'common/utils/resourceUtils';
import arrayUtils from 'common/utils/arrayUtils';

export const entryShared = (entry) => {
    return (
        // entry sharing itself
        (Array.isArray(entry.invitations) && entry.invitations.length > 0) ||
        // journal sharing
        [entry.journal, ...(entry.extra_journals || [])].some(
            (journal) =>
                Array.isArray(journal?.invitations) &&
                journal?.invitations.length > 0
        )
    );
};

export const isEntryOwner = (entry, me) => {
    return checkUserSame(me, entry.user);
};

export const sharedByMe = (entry, me) => {
    return entryShared(entry) && isEntryOwner(entry, me);
};

export const sharedWithMe = (entry, me) => {
    return entryShared(entry) && !isEntryOwner(entry, me);
};

export const isEntryForUser = (entry, user) => {
    return (
        checkUserSame(entry.user, user) ||
        (entry.shared_with || []).some((shared_user) =>
            checkUserSame(shared_user, user)
        ) ||
        [entry.journal, ...(entry.extra_journals || [])].some(
            (journal) =>
                checkUserSame(journal.user, user) ||
                (journal.shared_with || []).some((shared_user) =>
                    checkUserSame(shared_user, user)
                )
        )
    );
};

export const getEntryAndJournalInvitations = (entry, user) => {
    let invitations = entry.invitations;
    if (invitations && invitations.length > 0) {
        return invitations;
    }

    invitations = [];
    [entry.journal, ...entry.extra_journals].forEach((item) => {
        if (item.shared_with) {
            invitations = [].concat(
                invitations,
                item.shared_with.map((user) => ({
                    email: user.email,
                    user
                }))
            );

            invitations.push({
                email: item.user.email,
                user: item.user
            });
        }

        // not accepted invitations
        if (item.invitations) {
            invitations = [].concat(invitations, item.invitations);
        }
    });

    return (
        arrayUtils.getSetOfArray(invitations, (items, b) =>
            items.find((item) => item.email === b.email)
        ) || []
    ).filter((item) => item.email !== user.email);
};
