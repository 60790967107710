import settings from "common/configs/setting";

export default (user) => {
    const {
        access_token,
        username,
        api_key
    } = user;

    switch (settings.authType) {
        case 'bearer': {
            return `bearer ${access_token}`;
        }
        case 'apikey': {
            return `ApiKey ${username}:${api_key}`;
        }
        default: {
            return "";
        }
    }
}