import moment from 'moment';
import { ENTRY_HIDDEN } from 'common/constant';

export default (filter) => {
    const {
        minDate,
        maxDate,
        entrySort,
        filterByJournal,
        entryViewMode,
        filterByTag,
        filterByContact
    } = filter;

    const options = {};
    let filterBy = 'entry_date';

    if (entrySort) {
        if (entrySort.substring(0, 1) === '-') {
            filterBy = entrySort.substring(1);
        } else {
            filterBy = entrySort;
        }
    }
    options.order_by = entrySort;

    if (minDate) {
        options[`${filterBy}__gte`] = moment(minDate).utc().local().format();
    }

    if (maxDate) {
        options[`${filterBy}__lte`] = moment(maxDate).utc().local().format();
    }

    if (filterByTag && filterByTag.length > 0) {
        options['tags__id__in'] = filterByTag.map((item) => item.id).join(',');
    }

    if (filterByJournal && filterByJournal.length > 0) {
        options['by_journal'] = filterByJournal
            .map((item) => {
                return item.id;
            })
            .join(',');
    }

    if (filterByContact && filterByContact?.user?.id) {
        options['shared_with_user'] = filterByContact?.user?.id;
    }

    if (entryViewMode) {
        switch (entryViewMode) {
            case 'shared_by_me':
            case 'shared_with_me': {
                options[entryViewMode] = true;
                break;
            }
            case 'hidden': {
                options['state'] = ENTRY_HIDDEN;
                break;
            }
            default: {
                break;
            }
        }
    }

    return options;
};
