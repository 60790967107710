export default (resource, resource_uri) => {
    const pattern = `/api/v1/${resource}/([0-9]+)/$`;

    if (resource_uri && typeof resource_uri === 'string') {
        const matches = resource_uri.match(new RegExp(pattern));
        if (matches) {
            return parseInt(matches[1]);
        }
    } else if (typeof resource_uri === 'number') {
        return resource_uri;
    }
    return undefined;
};
