import React from 'react';

import imageAlert from 'assets/images/banners/banner-alert.jpg';
import { SOCIAL_CONNECT } from 'common/constant';

const AccountDuplicateDialog = ({ close, type }) => {
    return (
        <div
            id="duplicated-account-modal"
            className="duplicated-account-modal modal-frame">
            <div className="modal-box modal-box--600">
                <div className="banner-card banner-card--light white-bg">
                    <div className="banner-card__img">
                        <img src={imageAlert} alt="" />
                    </div>
                    <div className="banner-card__content">
                        <div className="banner-card__title">
                            Account already in use
                        </div>
                        <div className="banner-card__text">
                            This {SOCIAL_CONNECT[type]?.name} account is already
                            being used by another JRNL account. To connect this{' '}
                            {SOCIAL_CONNECT[type]?.name} account to this JRNL
                            account, please disconnect the previous connection.
                        </div>
                        <div className="banner-card__actions">
                            <button onClick={close} className="btn btn-outline">
                                Okay
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountDuplicateDialog;
