import React, { useState, useCallback } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Avatar } from '@material-ui/core';
import {
    getFontSize as getFontSizeSelector,
    getUserEmotions
} from 'redux/selectors';

import emotions from 'common/emotions';

// emoticons
import FaceWithHearts from 'assets/images/emoticons/smiling-face-with-hearts.png';
import PensiveFace from 'assets/images/emoticons/pensive-face.png';
import WorriedFace from 'assets/images/emoticons/worried-face.png';
import CryingFace from 'assets/images/emoticons/loudly-crying-face.png';
import getFontSize from 'common/utils/getFontSize';
import { FONT_SIZE_USE_TYPE } from 'common/constant';

// icons
import iconClose from 'assets/images/icons_svg/icon_close.svg';
import withMemo from 'components/HOC/withMemo';

const EntryEditorTitle = ({
    user,
    titleRef,
    entry_title,
    onTitleChange,
    emotionsList,
    userFontSize,
    emotion: propEmotion,
    setEmotion
}) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [showEmotions, setShowEmotions] = useState(false);

    const onSelectEmotion = useCallback(
        // TODO: remove update UI here.
        (emotion) => {
            setEmotion(emotion);
            setShowEmotions(false);
        },
        [setEmotion]
    );

    const renderEmotions = useCallback(() => {
        return emotionsList.map((e, index) => {
            const emotion = emotions.find((item) => item.id === e);
            return (
                <div
                    key={`emotion-list-${index}`}
                    className={`avatar-emoticons__item ${
                        propEmotion === emotion.id ? 'active' : ''
                    }`}>
                    <img
                        src={emotion.img}
                        alt={emotion.id}
                        onClick={() => onSelectEmotion(emotion.id)}
                    />
                </div>
            );
        });
    }, [onSelectEmotion, propEmotion, emotionsList]);

    return (
        <div className="post-title-section position-relative">
            <div className="dropdown show">
                <span
                    className="dropdown-toggle"
                    id="mood-dropdown"
                    onMouseEnter={() => setShowDropdown(true)}
                    onMouseLeave={() => setShowDropdown(false)}
                    onClick={() => setShowEmotions(true)}>
                    <Avatar alt="User Avatar" src={user?.avatar_image_url} />{' '}
                    &nbsp;
                    {propEmotion && (
                        <div className="emoji-avatar-mood">
                            <img
                                src={
                                    emotions[
                                        emotions.findIndex(
                                            (emotion) =>
                                                emotion.id === propEmotion
                                        )
                                    ].img
                                }
                                alt="hearts"
                            />
                        </div>
                    )}
                </span>
                <div
                    className={`dropdown-menu mood-dropdown-menu ${
                        showDropdown ? 'show' : ''
                    }`}>
                    <div className="mood-dropdown">
                        <ul className="emoticons-gallery">
                            <li className="emoticon-used">
                                <img src={FaceWithHearts} alt="hearts" />
                            </li>
                            <li className="emoticon-used">
                                <img src={PensiveFace} alt="hearts" />
                            </li>
                            <li className="emoticon-used">
                                <img src={WorriedFace} alt="hearts" />
                            </li>
                            <li className="emoticon-used">
                                <img src={CryingFace} alt="hearts" />
                            </li>
                        </ul>
                        <div className="mood-content">
                            <div className="mood-title">
                                Add your mood to your entries
                            </div>
                            <div className="mood-text">
                                Press on your avatar and select the emoji that
                                better represents your feelings
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`avatar-emoticons ${showEmotions ? 'show' : ''}`}>
                <div className="avatar-emoticons__container">
                    <div className="avatar-emoticons__avatar">
                        <img
                            src={user?.avatar_image_url}
                            className="post-avatar"
                            alt="avatar"
                            onClick={() => setShowEmotions(false)}
                        />{' '}
                        &nbsp;
                    </div>
                    {renderEmotions()}
                    <div className="avatar-emoticons__close">
                        <img
                            alt="close"
                            src={iconClose}
                            className="btn-icon btn-icon--6"
                            onClick={() => onSelectEmotion(null)}
                        />
                    </div>
                </div>
            </div>
            <input
                ref={titleRef}
                className="post-title"
                placeholder="Untitled"
                value={entry_title}
                maxLength={200}
                onChange={onTitleChange}
                style={{
                    fontSize: getFontSize(
                        FONT_SIZE_USE_TYPE.ENTRY_TITLE,
                        userFontSize
                    )
                }}
            />
        </div>
    );
};

const state = createStructuredSelector({
    emotionsList: getUserEmotions,
    userFontSize: getFontSizeSelector
});

export default connect(state)(withMemo(EntryEditorTitle));
