import React, { useEffect, useCallback, useRef } from 'react';
import moment from 'moment';
// redux
import { connect } from 'react-redux';
// Selectors
import { getUI } from 'redux/selectors';
import { createStructuredSelector } from 'reselect';
// Actions
import { updateUI, updateStepsFilledIn } from 'redux/actions/ui';
// utils
import { checkSubtitleSetup } from 'common/utils/validatecheck/bookcreate';
import { SUBTITLE_OPTION_CUSTOM, SUBTITLE_OPTION_RANGE } from 'common/constant';

const SubTitleStep = ({ display, dispatch, ui }) => {
    // check validability
    const subtitle = ui.subtitle;
    const subtitleOption = ui.subtitleOption;

    const updateStepsFilledIn_STS = useCallback(
        (val = false) => {
            dispatch(updateStepsFilledIn('SubTitleStep', val));
        },
        [dispatch]
    );

    const initialRenderRef = useRef(true);
    const ref_subtitleText = useRef();

    useEffect(() => {
        if (initialRenderRef.current) {
            initialRenderRef.current = false;
            if (subtitle !== '') {
                updateStepsFilledIn_STS(true);
            }
        }
    }, [updateStepsFilledIn_STS, subtitle]);

    useEffect(() => {
        if (display) {
            dispatch(
                updateUI({
                    canGoNext: checkSubtitleSetup(subtitleOption, subtitle)
                })
            );
        }
        return () => {};
    }, [display, subtitle, subtitleOption, dispatch]);

    // handlers
    const onChangeCustomSubtitleText = useCallback(
        (e) => {
            if (
                e.target.value !== '' &&
                ui.stepsFilledIn.SubTitleStep === false
            ) {
                updateStepsFilledIn_STS(true);
            } else if (
                e.target.value === '' &&
                ui.stepsFilledIn.SubTitleStep === true
            ) {
                updateStepsFilledIn_STS(false);
            }
            dispatch(
                updateUI({
                    subtitle: e.target.value,
                    subtitleOption: SUBTITLE_OPTION_CUSTOM
                })
            );
        },
        [dispatch, ui.stepsFilledIn.SubTitleStep, updateStepsFilledIn_STS]
    );

    const onChangeSubtitleOption = useCallback(
        (option) => {
            if (option === SUBTITLE_OPTION_RANGE) {
                updateStepsFilledIn_STS(true);
            } else if (option === SUBTITLE_OPTION_CUSTOM) {
                // @ts-ignore
                updateStepsFilledIn_STS(
                    // @ts-ignore
                    ref_subtitleText?.current?.value.length > 0 || false
                );
            }
            dispatch(
                updateUI({
                    subtitleOption: option
                })
            );
        },
        [dispatch, updateStepsFilledIn_STS]
    );

    // utils
    const getMinDate = useCallback(() => {
        if (ui.minDate) {
            return moment(ui.minDate).local().format('YYYY-MM-DD');
        } else if (ui.selectedJournal) {
            return moment
                .utc(ui.selectedJournal?.first_entry_date)
                .local()
                .format('YYYY-MM-DD');
        }
    }, [ui]);

    const getMaxDate = useCallback(() => {
        if (ui.maxDate) {
            return moment(ui.maxDate).local().format('YYYY-MM-DD');
        } else if (ui.selectedJournal) {
            return moment
                .utc(ui.selectedJournal?.last_entry_date)
                .local()
                .format('YYYY-MM-DD');
        }
    }, [ui]);

    return (
        <div
            className={`new-book-step-8 new-book-step ${
                display ? 'active-form' : ''
            }`}>
            <div className="section-title">Subtitle</div>
            <div
                className="form-group"
                onClick={() => onChangeSubtitleOption(SUBTITLE_OPTION_RANGE)}>
                <div className="radio-item">
                    <input
                        className="radio"
                        type="radio"
                        id="subtitleDateRange"
                        name="subtitle-options"
                        checked={ui.subtitleOption === SUBTITLE_OPTION_RANGE}
                        onChange={() => {}}
                    />
                    <label htmlFor="subtitle-options">
                        Show a date range ({getMinDate()} to {getMaxDate()})
                    </label>
                </div>
            </div>
            <div
                className="form-group"
                onClick={() => onChangeSubtitleOption(SUBTITLE_OPTION_CUSTOM)}>
                <div className="radio-item">
                    <input
                        className="radio"
                        type="radio"
                        id="subtitleCustom"
                        name="subtitle-options"
                        checked={ui.subtitleOption === SUBTITLE_OPTION_CUSTOM}
                        onChange={() => {}}
                    />
                    <label htmlFor="subtitle-options">
                        Show a custom subtitle
                    </label>
                </div>
            </div>
            <div className="form-group mt-4">
                <div className="form-group__item pb-0">
                    <label htmlFor="subtitle">Subtitle</label>
                    <input
                        ref={ref_subtitleText}
                        disabled={ui.subtitleOption !== SUBTITLE_OPTION_CUSTOM}
                        onChange={onChangeCustomSubtitleText}
                        value={ui.subtitle || ''}
                        type="text"
                        id="subtitleText"
                        name="subtitle"
                        placeholder="Enter Subtitle"
                    />
                </div>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    ui: getUI
});
export default connect(state)(SubTitleStep);
