// import qs from 'qs';
import React, { useEffect, useMemo } from 'react';
import qs from 'qs';
import TimelineView from 'pages/Timeline/TimelineView';
import { connect, useStore } from 'react-redux';
import {
    getFilters,
    getLoadingProgress,
    getUI,
    getUser
} from 'redux/selectors';
import { createStructuredSelector } from 'reselect';

import { useParams, useLocation } from 'react-router-dom';
import { Backdrop } from '@material-ui/core';
// Actions
import * as EntryActions from 'redux/actions/entry';
import { fetchJournal } from 'redux/actions/journal';
import * as FilterActions from 'redux/actions/filter';
import * as JournalInvitationActions from 'redux/actions/journalinvitation';
// image
import jrnlPreloader from 'assets/images/preloader.gif';
import OptionsBar from './OptionsBar';
import { updateUI } from 'redux/actions/ui';

const Timeline = (
    { dispatch, store, ui, user, loadingProgress, filters },
    context
) => {
    const { journalId } = useParams();
    const [entryDefaultJournal, setEntryDefaultJournal] = React.useState(null);
    const [isTrial, setIsTrial] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const location = useLocation();
    // remove for temporarily
    /*
    const userId = useMemo(() => user?.id, [user]),
        userSharing = useMemo(() => user?.sharing, [user]);        
        */
    const fullView = useMemo(() => ui.fullView, [ui.fullView]);

    useEffect(() => {
        document.title = 'JRNL | Timeline';
    }, []);

    useEffect(() => {
        if (journalId) {
            const queryObj = qs.parse(location.search || location.hash, {
                ignoreQueryPrefix: true
            });

            setLoading(true);

            const getJournalIfExist = queryObj.invitation
                ? dispatch(
                      JournalInvitationActions.fetchInvitationWithUniqueKey(
                          queryObj.invitation
                      )
                  )
                : dispatch(fetchJournal(`/api/v1/journal/${journalId}/`));

            getJournalIfExist.then((response) => {
                const journal = queryObj.invitation
                    ? response.payload.data.journal
                    : response.payload.data;
                dispatch(
                    FilterActions.update({
                        filterByJournal: [journal],
                        minDate: undefined,
                        maxDate: undefined
                    })
                );
                if (
                    queryObj.invitation &&
                    !response.payload.data.accept_status
                ) {
                    dispatch(
                        EntryActions.fetchSharedJournalEntries(
                            queryObj.invitation
                        )
                    ).then(() => setLoading(false));

                    setIsTrial(true);
                } else {
                    dispatch(EntryActions.fetch({})).then(() =>
                        setLoading(false)
                    );
                }

                // update UI extra_journals
                dispatch(updateUI({ extra_journals: [journal] }));
                setEntryDefaultJournal(journal);
            });
        }
        // eslint-disable-next-line
    }, [journalId, dispatch]);

    const renderLoader = React.useCallback(() => {
        return (
            <Backdrop open style={{ zIndex: 9999 }}>
                <section className="loader-section">
                    <div className="loader">
                        <img src={jrnlPreloader} alt="loader" />
                    </div>
                </section>
            </Backdrop>
        );
    }, []);

    return (
        <div>
            <main
                id="main"
                style={{ marginLeft: 0 }}
                className={`app-main ${fullView ? 'full-view' : ''}`}>
                <OptionsBar
                    // @ts-ignore
                    filters={filters}
                />

                {loading &&
                    parseInt(loadingProgress) === 100 &&
                    !fullView &&
                    renderLoader()}
                <TimelineView
                    store={useStore()}
                    entryDefaultJournal={entryDefaultJournal}
                    trial={isTrial}
                    loading={loading}
                />
            </main>
        </div>
    );
};

const state = createStructuredSelector({
    filters: getFilters,
    ui: getUI,
    user: getUser,
    loadingProgress: getLoadingProgress
});
export default connect(state)(Timeline);
