import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import { closeDialog } from 'common/utils/dialog-utils';
import * as ShareContactListsActions from 'redux/actions/sharecontactlist';

import DefaultUser from 'assets/images/default-user.jpg';

// Icons
import iconCloseSm from 'assets/images/icons_svg/icon_close-sm.svg';

const EditContactDialog = ({ contact, dispatch }) => {
    const [email, setEmail] = useState(contact.email || '');

    const editContact = useCallback(() => {
        dispatch(ShareContactListsActions.editContact(contact.id, email));
        closeDialog();
    }, [contact, email, dispatch]);

    return (
        <div id="edit-contact-modal" className="modal-frame">
            <div className="modal-box modal-box--600">
                <div className="modal__header">
                    Edit Contact
                    <div className="modal__close btn-icon">
                        <img
                            src={iconCloseSm}
                            onClick={closeDialog}
                            alt="close"
                        />
                    </div>
                </div>
                <div className="modal__body">
                    <div className="form-group">
                        <div className="form-group__avatar">
                            <img
                                className="avatar"
                                src={DefaultUser}
                                alt="avatar"
                            />
                            <button className="btn btn-outline">
                                Edit Avatar
                            </button>
                        </div>
                    </div>
                    <div className="form-group m-0">
                        <div className="form-group__item m-0">
                            <label>Email</label>
                            <input
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="modal__footer">
                    <button className="btn btn-gradient" onClick={editContact}>
                        Save Changes
                    </button>
                </div>
            </div>
        </div>
    );
};

export default connect()(EditContactDialog);
