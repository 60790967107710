import _ from 'underscore';
import React, { useCallback, useState, useEffect } from 'react';

// Icons
import iconTag from 'assets/images/icons_svg/icon_tag.svg';
import iconAdd from 'assets/images/icons_svg/icon_add.svg';
import iconRemove from 'assets/images/icons_svg/icon_remove.svg';
import iconCloseSm from 'assets/images/icons_svg/icon_close-sm.svg';

const TagFilterList = ({ tags, onChange, filterTags }) => {
    const [selectedId, setSelectedId] = useState('all');
    const [showAllTags, setShowAllTags] = useState(false);
    const [filteredTags, setFilteredTags] = useState([]);

    const onSelectTag = useCallback(
        (tag) => {
            if (tag === 'all') {
                onChange(tag);
                return;
            }
            let newFilterTags = [...(filterTags || [])];

            const index = _.findIndex(
                newFilterTags,
                (item) => item.id === tag.id
            );
            if (index >= 0) {
                newFilterTags = newFilterTags.slice(index);
            } else {
                newFilterTags.push(tag);
            }
            if (onChange) {
                onChange(newFilterTags);
            }
        },
        [filterTags, onChange]
    );

    useEffect(() => {
        if (filterTags && !_.isEmpty(filterTags)) {
            setSelectedId(filterTags.map((item) => item.id));
        } else {
            setSelectedId('all');
        }
        return () => {};
    }, [filterTags]);

    useEffect(() => {
        if (tags) {
            setFilteredTags(tags.slice(0, 4));
        }
    }, [tags]);

    const onRemoveTagFilter = useCallback(
        (tag) => {
            const newFilterTags = [...(filterTags || [])];

            const index = _.findIndex(
                newFilterTags,
                (item) => item.id === tag.id
            );
            if (index >= 0) {
                newFilterTags.splice(index);
            }
            if (onChange) {
                onChange(newFilterTags.length ? newFilterTags : 'all');
            }
        },
        [onChange, filterTags]
    );
    const SetShowAllTags = useCallback(
        (value) => {
            if (value) {
                setShowAllTags(true);
                setFilteredTags(tags);
            } else {
                setShowAllTags(false);
                setFilteredTags(tags?.slice(0, 4));
            }
        },
        [tags]
    );

    return (
        <ul className="filter-list">
            <li
                className={selectedId === 'all' ? 'active-item-list' : ''}
                key={'all'}>
                <div
                    className="filter-list__content"
                    onClick={() => {
                        onSelectTag('all');
                    }}>
                    <img
                        src={iconTag}
                        className="btn-icon btn-icon--6"
                        alt=""
                    />
                    All Tags
                </div>
            </li>
            {filteredTags &&
                filteredTags.map((tag) => {
                    const activeItem =
                        Array.isArray(selectedId) &&
                        selectedId.includes(tag.id);
                    return (
                        <li
                            className={activeItem ? 'active-item-list' : ''}
                            key={tag.id}>
                            <div
                                className="filter-list__content"
                                onClick={() => {
                                    onSelectTag(tag);
                                }}>
                                <img
                                    src={iconTag}
                                    className="btn-icon btn-icon--6"
                                    alt=""
                                />
                                <span className="truncate">
                                    <span>{tag.name}</span>
                                </span>
                            </div>
                            <img
                                src={iconCloseSm}
                                className="close btn-icon btn-icon--4"
                                onClick={() => onRemoveTagFilter(tag)}
                                alt=""
                            />
                        </li>
                    );
                })}
            <>
                {!showAllTags ? (
                    <li className="btn-show-all">
                        <div
                            className="filter-list__content"
                            onClick={() => SetShowAllTags(true)}>
                            <img
                                src={iconAdd}
                                className="btn-icon btn-icon--6"
                                alt=""
                            />
                            Show all tags ({tags?.length})
                        </div>
                    </li>
                ) : (
                    <li className="btn-show-all">
                        <div
                            className="filter-list__content"
                            onClick={() => SetShowAllTags(false)}>
                            <img
                                src={iconRemove}
                                className="btn-icon btn-icon--6"
                                alt=""
                            />
                            Hide all tags
                        </div>
                    </li>
                )}
            </>
        </ul>
    );
};

export default TagFilterList;
