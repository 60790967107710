import _ from 'underscore';

import { createSelector } from 'reselect';

export const AllAboutMeSelector = (state) => state.aam;

export const RandomQuestion = (state) => state.aam.randomQuestion;

export const CategoryForId = (state, props) => {
    if (props.match?.params.categoryId) {
        return _.findWhere(state.aam.categories, {
            id: parseInt(props.match?.params.categoryId)
        });
    } else {
        return undefined;
    }
};

export const QuestionsForCategory = (state, props) => {
    let questions = state.aam.questions;
    if (props.match?.params.categoryId) {
        questions = _.filter(
            questions,
            (question) =>
                question.category.id ===
                parseInt(props.match?.params.categoryId)
        );
        return questions;
    }
    return undefined;
};

export const QuestionForId = (state, props) => {
    const questions = state.aam.questions;
    if (props.match?.params.questionId) {
        const question = _.findWhere(questions, {
            id: parseInt(props.match?.params.questionId)
        });
        return question;
    }
    return undefined;
};

export const getAAM = createSelector([AllAboutMeSelector], (state) => state);

export const getCategoryList = createSelector([AllAboutMeSelector], (state) => {
    const categories = state.categories;
    return categories;
});

export const getCategoryForId = createSelector(
    [CategoryForId],
    (category) => category
);

export const getQuestionsForCategory = createSelector(
    [QuestionsForCategory],
    (questions) => questions
);

export const getQuestionForId = createSelector(
    [QuestionForId],
    (question) => question
);

export const getRandomQuestion = createSelector(
    [RandomQuestion],
    (question) => question
);

export const getCategoryForQuestion = createSelector(
    [QuestionForId, AllAboutMeSelector],
    (question, aamState) => {
        if (question && aamState) {
            const category = _.findWhere(aamState.categories, {
                id: question?.category?.id
            });
            return category;
        }
        return undefined;
    }
);
