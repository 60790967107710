import React, { useEffect, useCallback } from 'react';
import moment from 'moment';
// redux
import { connect } from 'react-redux';
// Selectors
import { getUI } from 'redux/selectors';
import { createStructuredSelector } from 'reselect';
// Actions
import { updateUI } from 'redux/actions/ui';
// utils
import { checkSubtitleSetup } from 'common/utils/validatecheck/bookcreate';
import { SUBTITLE_OPTION_CUSTOM, SUBTITLE_OPTION_RANGE } from 'common/constant';

const SubTitleStep = ({ display, dispatch, ui }) => {
    // check validability
    const subtitle = ui.subtitle;
    const subtitleOption = ui.subtitleOption;
    useEffect(() => {
        if (display) {
            dispatch(
                updateUI({
                    canGoNext: checkSubtitleSetup(subtitleOption, subtitle)
                })
            );
        }
        return () => {};
    }, [display, subtitle, subtitleOption, dispatch]);

    // handlers
    const onChangeCustomSubtitleText = useCallback(
        (e) => {
            dispatch(
                updateUI({
                    subtitle: e.target.value,
                    subtitleOption: SUBTITLE_OPTION_CUSTOM
                })
            );
        },
        [dispatch]
    );

    const onChangeSubtitleOption = useCallback(
        (option) => {
            dispatch(
                updateUI({
                    subtitleOption: option
                })
            );
        },
        [dispatch]
    );

    // utils
    const getMinDate = useCallback(() => {
        if (ui.minDate) {
            return moment(ui.minDate).local().format('YYYY-MM-DD');
        } else if (ui.selectedJournal) {
            return moment
                .utc(ui.selectedJournal?.first_entry_date)
                .local()
                .format('YYYY-MM-DD');
        }
    }, [ui]);

    const getMaxDate = useCallback(() => {
        if (ui.maxDate) {
            return moment(ui.maxDate).local().format('YYYY-MM-DD');
        } else if (ui.selectedJournal) {
            return moment
                .utc(ui.selectedJournal?.last_entry_date)
                .local()
                .format('YYYY-MM-DD');
        }
    }, [ui]);

    return (
        <div
            className={`new-book-step-8 new-book-step ${
                display ? 'active-form' : ''
            }`}>
            <div className="section-title">Subtitle</div>
            <div
                className="form-group"
                onClick={() => onChangeSubtitleOption(SUBTITLE_OPTION_RANGE)}>
                <div className="radio-item">
                    <input
                        className="radio"
                        type="radio"
                        id="subtitleDateRange"
                        name="subtitle-options"
                        checked={ui.subtitleOption === SUBTITLE_OPTION_RANGE}
                        onChange={() => {}}
                    />
                    <label htmlFor="subtitle-options">
                        Show a date range ({getMinDate()} to {getMaxDate()})
                    </label>
                </div>
            </div>
            <div
                className="form-group"
                onClick={() => onChangeSubtitleOption(SUBTITLE_OPTION_CUSTOM)}>
                <div className="radio-item">
                    <input
                        className="radio"
                        type="radio"
                        id="subtitleCustom"
                        name="subtitle-options"
                        checked={ui.subtitleOption === SUBTITLE_OPTION_CUSTOM}
                        onChange={() => {}}
                    />
                    <label htmlFor="subtitle-options">
                        Show a custom subtitle
                    </label>
                </div>
            </div>
            <div className="form-group mt-4">
                <div className="form-group__item pb-0">
                    <label htmlFor="subtitle">Subtitle</label>
                    <input
                        disabled={ui.subtitleOption !== SUBTITLE_OPTION_CUSTOM}
                        onChange={onChangeCustomSubtitleText}
                        value={ui.subtitle || ''}
                        type="text"
                        id="subtitleText"
                        name="subtitle"
                        placeholder="Enter Subtitle"
                    />
                </div>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    ui: getUI
});
export default connect(state)(SubTitleStep);
