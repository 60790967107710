export const JRNL_PLANS = {
    PublicJRNL: {
        Plans: {
            Plan: [
                {
                    Name: 'JRNLer',
                    Id: 1,
                    Notes: 'This is the free plan that everyone starts with.',
                    Status: 'Active',
                    Price: '0',
                    StorageGB: '2',
                    Journals: '1',
                    SharedJournals: '0',
                    JrnlersIncluded: '5',
                    PlanRules: {
                        PlanRule: [
                            {
                                OptionName: 'includesaam',
                                OptionDescription: 'Includes AAM',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'unlimitedtextentries',
                                OptionDescription: 'Unlimited Text Entries*',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'price',
                                OptionDescription: 'Price',
                                TextValue: '0.00'
                            },
                            {
                                OptionName: 'numofjournals',
                                OptionDescription: '# of Journals',
                                TextValue: '1'
                            },
                            {
                                OptionName: 'storagegb',
                                OptionDescription: 'Storage',
                                TextValue: '2'
                            },
                            {
                                OptionName: 'canpurchasejrnltypes',
                                OptionDescription: 'Can purchase JRNL Types',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'multiplephotosentry',
                                OptionDescription: 'Multiple Photos/Entry',
                                TextValue: 'true'
                            }
                        ]
                    },
                    PlanPaymentOptions: [
                        {
                            Name: 'JRNLer',
                            Title: 'JRNLer',
                            AnnualPrice: '0',
                            Duration: 'Free',
                            Price: '0',
                            Months: ''
                        }
                    ]
                },
                {
                    Name: 'JRNLer (legacy)',
                    Id: 2,
                    Status: 'Inactive',
                    Price: '34.99',
                    StorageGB: '500',
                    Journals: '-1',
                    SharedJournals: '1',
                    JrnlersIncluded: '5',
                    PlanRules: {
                        PlanRule: [
                            {
                                OptionName: 'freepdfyr',
                                OptionDescription: 'Free PDF/yr',
                                TextValue: '1'
                            },
                            {
                                OptionName: 'price',
                                OptionDescription: 'Price',
                                TextValue: '34.99'
                            },
                            {
                                OptionName: 'numofsharedjournals',
                                OptionDescription: '# of Shared Journals***',
                                TextValue: '1'
                            },
                            {
                                OptionName: 'unlimitedtextentries',
                                OptionDescription: 'Unlimited Text Entries*',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'aamownquestions',
                                OptionDescription: 'AAM Own Questions',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'multiplephotosentry',
                                OptionDescription: 'Multiple Photos/Entry',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'emailtojrnl',
                                OptionDescription: 'Email to JRNL',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'canpurchasejrnltypes',
                                OptionDescription: 'Can purchase JRNL Types',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'includesaam',
                                OptionDescription: 'Includes AAM',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'socialconnect',
                                OptionDescription: 'Social Connect',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'storagegb',
                                OptionDescription: 'Storage',
                                TextValue: '500'
                            },
                            {
                                OptionName: 'sharedentries',
                                OptionDescription: 'Shared Entries',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'numofjournals',
                                OptionDescription: '# of Journals',
                                TextValue: '-1'
                            },
                            {
                                OptionName: 'discount',
                                OptionDescription: 'Discount',
                                TextValue: '0.1'
                            },
                            {
                                OptionName: 'custombookcover',
                                OptionDescription: 'Custom Book Cover**',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'createsharedjournals',
                                OptionDescription: 'Create Shared Journals',
                                TextValue: '1'
                            }
                        ]
                    },
                    PlanPaymentOptions: {
                        PlanPaymentOption: [
                            {
                                Name: 'JRNLer (legacy)-Monthly-5',
                                Title: 'JRNLer Legacy Monthly',
                                AnnualPrice: '34.99',
                                Duration: 'Monthly',
                                Price: '5',
                                Months: '1'
                            },
                            {
                                Name: 'JRNLer (legacy)-Quarterly-12',
                                Title: 'JRNLer Legacy Quarterly',
                                AnnualPrice: '34.99',
                                Duration: 'Quarterly',
                                Price: '12',
                                Months: '3'
                            },
                            {
                                Name: 'JRNLer (legacy)-Annually-34.99',
                                Title: 'JRNLer Legacy Annually',
                                AnnualPrice: '34.99',
                                Duration: 'Annually',
                                Price: '34.99',
                                Months: '12'
                            }
                        ]
                    }
                },
                {
                    Name: 'POWER JRNLer - Loyalty',
                    Id: 3,
                    Notes:
                        'This is a plan for early adopters.  Pro features, free price. This plan is going away.',
                    Status: 'Inactive',
                    Price: '0',
                    StorageGB: '1000',
                    Journals: '-1',
                    SharedJournals: '10',
                    JrnlersIncluded: '25',
                    PlanRules: {
                        PlanRule: [
                            {
                                OptionName: 'storagegb',
                                OptionDescription: 'Storage',
                                TextValue: '1000'
                            },
                            {
                                OptionName: 'freepdfyr',
                                OptionDescription: 'Free PDF/yr',
                                TextValue: '12'
                            },
                            {
                                OptionName: 'unlimitedtextentries',
                                OptionDescription: 'Unlimited Text Entries*',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'discount',
                                OptionDescription: 'Discount',
                                TextValue: '0.2'
                            },
                            {
                                OptionName: 'numofsharedjournals',
                                OptionDescription: '# of Shared Journals***',
                                TextValue: '10'
                            },
                            {
                                OptionName: 'canpurchasejrnltypes',
                                OptionDescription: 'Can purchase JRNL Types',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'audioupload',
                                OptionDescription: 'Audio Upload',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'socialconnect',
                                OptionDescription: 'Social Connect',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'numofjournals',
                                OptionDescription: '# of Journals',
                                TextValue: '-1'
                            },
                            {
                                OptionName: 'emailtojrnl',
                                OptionDescription: 'Email to JRNL',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'includesaam',
                                OptionDescription: 'Includes AAM',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'createsharedjournals',
                                OptionDescription: 'Create Shared Journals',
                                TextValue: '-1'
                            },
                            {
                                OptionName: 'multiplephotosentry',
                                OptionDescription: 'Multiple Photos/Entry',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'custombookcover',
                                OptionDescription: 'Custom Book Cover**',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'aamownquestions',
                                OptionDescription: 'AAM Own Questions',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'videoupload',
                                OptionDescription: 'Video Upload',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'sharedentries',
                                OptionDescription: 'Shared Entries',
                                TextValue: 'true'
                            }
                        ]
                    },
                    PlanPaymentOptions: {
                        PlanPaymentOption: [
                            {
                                Name: 'POWER JRNLer - Loyalty-Annually-0',
                                Title: 'POWER JRNLer Loyalty',
                                AnnualPrice: '0',
                                Duration: 'Annually',
                                Price: '0',
                                Months: '12'
                            }
                        ]
                    }
                },
                {
                    Name: 'POWER JRNLer - 10%',
                    Id: 4,
                    Notes: 'This is a discounted pro plan.',
                    Status: 'Active',
                    Price: '45',
                    StorageGB: '1000',
                    Journals: '-1',
                    SharedJournals: '10',
                    JrnlersIncluded: '25',
                    PlanRules: {
                        PlanRule: [
                            {
                                OptionName: 'createsharedjournals',
                                OptionDescription: 'Create Shared Journals',
                                TextValue: '-1'
                            },
                            {
                                OptionName: 'freepdfyr',
                                OptionDescription: 'Free PDF/yr',
                                TextValue: '12'
                            },
                            {
                                OptionName: 'aamownquestions',
                                OptionDescription: 'AAM Own Questions',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'numofjournals',
                                OptionDescription: '# of Journals',
                                TextValue: '-1'
                            },
                            {
                                OptionName: 'custombookcover',
                                OptionDescription: 'Custom Book Cover**',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'socialconnect',
                                OptionDescription: 'Social Connect',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'includesaam',
                                OptionDescription: 'Includes AAM',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'emailtojrnl',
                                OptionDescription: 'Email to JRNL',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'sharedentries',
                                OptionDescription: 'Shared Entries',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'unlimitedtextentries',
                                OptionDescription: 'Unlimited Text Entries*',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'numofsharedjournals',
                                OptionDescription: '# of Shared Journals***',
                                TextValue: '10'
                            },
                            {
                                OptionName: 'discount',
                                OptionDescription: 'Discount',
                                TextValue: '0.2'
                            },
                            {
                                OptionName: 'storagegb',
                                OptionDescription: 'Storage',
                                TextValue: '1000'
                            },
                            {
                                OptionName: 'multiplephotosentry',
                                OptionDescription: 'Multiple Photos/Entry',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'audioupload',
                                OptionDescription: 'Audio Upload',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'price',
                                OptionDescription: 'Price',
                                TextValue: '45'
                            },
                            {
                                OptionName: 'videoupload',
                                OptionDescription: 'Video Upload',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'canpurchasejrnltypes',
                                OptionDescription: 'Can purchase JRNL Types',
                                TextValue: 'true'
                            }
                        ]
                    },
                    PlanPaymentOptions: {
                        PlanPaymentOption: [
                            {
                                Name: 'POWER JRNLer - 10%-Monthly-8',
                                Title: 'POWER JRNLer Monthly',
                                AnnualPrice: '45',
                                Duration: 'Monthly',
                                Price: '8',
                                Months: '1'
                            },
                            {
                                Name: 'POWER JRNLer - 10%-Quarterly-18',
                                Title: 'POWER JRNLer Quarterly',
                                AnnualPrice: '45',
                                Duration: 'Quarterly',
                                Price: '18',
                                Months: '3'
                            },
                            {
                                Name: 'POWER JRNLer - 10%-Annually-45',
                                Title: 'POWER JRNLer Annually',
                                AnnualPrice: '45',
                                Duration: 'Annually',
                                Price: '45',
                                Months: '12'
                            }
                        ]
                    }
                },
                {
                    Name: 'POWER JRNLer - 20%',
                    Id: 5,
                    Status: 'Active',
                    Price: '40',
                    StorageGB: '1000',
                    Journals: '-1',
                    SharedJournals: '10',
                    JrnlersIncluded: '25',
                    PlanRules: {
                        PlanRule: [
                            {
                                OptionName: 'includesaam',
                                OptionDescription: 'Includes AAM',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'aamownquestions',
                                OptionDescription: 'AAM Own Questions',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'numofjournals',
                                OptionDescription: '# of Journals',
                                TextValue: '-1'
                            },
                            {
                                OptionName: 'audioupload',
                                OptionDescription: 'Audio Upload',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'freepdfyr',
                                OptionDescription: 'Free PDF/yr',
                                TextValue: '12'
                            },
                            {
                                OptionName: 'unlimitedtextentries',
                                OptionDescription: 'Unlimited Text Entries*',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'videoupload',
                                OptionDescription: 'Video Upload',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'createsharedjournals',
                                OptionDescription: 'Create Shared Journals',
                                TextValue: '-1'
                            },
                            {
                                OptionName: 'socialconnect',
                                OptionDescription: 'Social Connect',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'custombookcover',
                                OptionDescription: 'Custom Book Cover**',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'numofsharedjournals',
                                OptionDescription: '# of Shared Journals***',
                                TextValue: '10'
                            },
                            {
                                OptionName: 'multiplephotosentry',
                                OptionDescription: 'Multiple Photos/Entry',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'discount',
                                OptionDescription: 'Discount',
                                TextValue: '0.2'
                            },
                            {
                                OptionName: 'canpurchasejrnltypes',
                                OptionDescription: 'Can purchase JRNL Types',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'sharedentries',
                                OptionDescription: 'Shared Entries',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'price',
                                OptionDescription: 'Price',
                                TextValue: '40'
                            },
                            {
                                OptionName: 'storagegb',
                                OptionDescription: 'Storage',
                                TextValue: '1000'
                            },
                            {
                                OptionName: 'emailtojrnl',
                                OptionDescription: 'Email to JRNL',
                                TextValue: 'true'
                            }
                        ]
                    },
                    PlanPaymentOptions: {
                        PlanPaymentOption: [
                            {
                                Name: 'POWER JRNLer - 20%-Monthly-7',
                                Title: 'POWER JRNLer Monthly',
                                AnnualPrice: '40',
                                Duration: 'Monthly',
                                Price: '7',
                                Months: '1'
                            },
                            {
                                Name: 'POWER JRNLer - 20%-Quarterly-16',
                                Title: 'POWER JRNLer Quarterly',
                                AnnualPrice: '40',
                                Duration: 'Quarterly',
                                Price: '16',
                                Months: '3'
                            },
                            {
                                Name: 'POWER JRNLer - 20%-Annually-40',
                                Title: 'POWER JRNLer Annually',
                                AnnualPrice: '40',
                                Duration: 'Annually',
                                Price: '40',
                                Months: '12'
                            }
                        ]
                    }
                },
                {
                    Name: 'POWER JRNLer - 50%',
                    Id: 5,
                    Status: 'Active',
                    Price: '25',
                    StorageGB: '1000',
                    Journals: '-1',
                    SharedJournals: '10',
                    JrnlersIncluded: '25',
                    PlanRules: {
                        PlanRule: [
                            {
                                OptionName: 'includesaam',
                                OptionDescription: 'Includes AAM',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'aamownquestions',
                                OptionDescription: 'AAM Own Questions',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'numofjournals',
                                OptionDescription: '# of Journals',
                                TextValue: '-1'
                            },
                            {
                                OptionName: 'audioupload',
                                OptionDescription: 'Audio Upload',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'freepdfyr',
                                OptionDescription: 'Free PDF/yr',
                                TextValue: '12'
                            },
                            {
                                OptionName: 'unlimitedtextentries',
                                OptionDescription: 'Unlimited Text Entries*',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'videoupload',
                                OptionDescription: 'Video Upload',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'createsharedjournals',
                                OptionDescription: 'Create Shared Journals',
                                TextValue: '-1'
                            },
                            {
                                OptionName: 'socialconnect',
                                OptionDescription: 'Social Connect',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'custombookcover',
                                OptionDescription: 'Custom Book Cover**',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'numofsharedjournals',
                                OptionDescription: '# of Shared Journals***',
                                TextValue: '10'
                            },
                            {
                                OptionName: 'multiplephotosentry',
                                OptionDescription: 'Multiple Photos/Entry',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'discount',
                                OptionDescription: 'Discount',
                                TextValue: '0.2'
                            },
                            {
                                OptionName: 'canpurchasejrnltypes',
                                OptionDescription: 'Can purchase JRNL Types',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'sharedentries',
                                OptionDescription: 'Shared Entries',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'price',
                                OptionDescription: 'Price',
                                TextValue: '40'
                            },
                            {
                                OptionName: 'storagegb',
                                OptionDescription: 'Storage',
                                TextValue: '1000'
                            },
                            {
                                OptionName: 'emailtojrnl',
                                OptionDescription: 'Email to JRNL',
                                TextValue: 'true'
                            }
                        ]
                    },
                    PlanPaymentOptions: {
                        PlanPaymentOption: [
                            {
                                Name: 'POWER JRNLer - Monthly',
                                Title: 'POWER JRNLer Monthly',
                                AnnualPrice: '49.99',
                                Duration: 'Monthly',
                                Price: '8.99',
                                Months: '1'
                            },
                            {
                                Name: 'POWER JRNLer - Quarterly',
                                Title: 'POWER JRNLer Quarterly',
                                AnnualPrice: '49.99',
                                Duration: 'Quarterly',
                                Price: '19.99',
                                Months: '3'
                            },
                            {
                                Name: 'POWER JRNLer - Annually',
                                Title: 'POWER JRNLer Annually',
                                AnnualPrice: '25',
                                Duration: 'Annually',
                                Price: '25',
                                Months: '12'
                            }
                        ]
                    }
                },
                {
                    Name: 'POWER JRNLer',
                    Id: 6,
                    Notes: 'This is the pro-plan',
                    Status: 'Active',
                    Price: '49.99',
                    StorageGB: '1000',
                    Journals: '-1',
                    SharedJournals: '10',
                    JrnlersIncluded: '25',
                    PlanRules: {
                        PlanRule: [
                            {
                                OptionName: 'numofsharedjournals',
                                OptionDescription: '# of Shared Journals***',
                                TextValue: '10'
                            },
                            {
                                OptionName: 'sharedentries',
                                OptionDescription: 'Shared Entries',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'multiplephotosentry',
                                OptionDescription: 'Multiple Photos/Entry',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'includesaam',
                                OptionDescription: 'Includes AAM',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'socialconnect',
                                OptionDescription: 'Social Connect',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'custombookcover',
                                OptionDescription: 'Custom Book Cover**',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'aamownquestions',
                                OptionDescription: 'AAM Own Questions',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'canpurchasejrnltypes',
                                OptionDescription: 'Can purchase JRNL Types',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'numofjournals',
                                OptionDescription: '# of Journals',
                                TextValue: '-1'
                            },
                            {
                                OptionName: 'videoupload',
                                OptionDescription: 'Video Upload',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'unlimitedtextentries',
                                OptionDescription: 'Unlimited Text Entries*',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'discount',
                                OptionDescription: 'Discount',
                                TextValue: '0.2'
                            },
                            {
                                OptionName: 'audioupload',
                                OptionDescription: 'Audio Upload',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'emailtojrnl',
                                OptionDescription: 'Email to JRNL',
                                TextValue: 'true'
                            },
                            {
                                OptionName: 'storagegb',
                                OptionDescription: 'Storage',
                                TextValue: '1000'
                            },
                            {
                                OptionName: 'price',
                                OptionDescription: 'Price',
                                TextValue: '49.99'
                            },
                            {
                                OptionName: 'createsharedjournals',
                                OptionDescription: 'Create Shared Journals',
                                TextValue: '-1'
                            },
                            {
                                OptionName: 'freepdfyr',
                                OptionDescription: 'Free PDF/yr',
                                TextValue: '12'
                            }
                        ]
                    },
                    PlanPaymentOptions: {
                        PlanPaymentOption: [
                            {
                                Name: 'POWER JRNLer-Monthly-8.99',
                                Title: 'POWER JRNLer Monthly',
                                AnnualPrice: '49.99',
                                Duration: 'Monthly',
                                Price: '8.99',
                                Months: '1'
                            },
                            {
                                Name: 'POWER JRNLer-Quarterly-19.99',
                                Title: 'POWER JRNLer Quarterly',
                                AnnualPrice: '49.99',
                                Duration: 'Quarterly',
                                Price: '19.99',
                                Months: '3'
                            },
                            {
                                Name: 'POWER JRNLer-Annually-49.99',
                                Title: 'POWER JRNLer Annually',
                                AnnualPrice: '49.99',
                                Duration: 'Annually',
                                Price: '49.99',
                                Months: '12'
                            }
                        ]
                    }
                }
            ]
        }
    }
};
