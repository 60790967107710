import { ERROR_MESSAGES } from 'common/constant';
import React from 'react';
import { connect } from 'react-redux';
import { updateUI } from 'redux/actions/ui';
import { getUser } from 'redux/selectors';
import { createStructuredSelector } from 'reselect';

const withFreeAccountCheck = (Component, isDialog = true, isOnlyPro = true) => {
    class NewComponent extends React.Component {
        componentDidMount() {
            if (!isDialog) {
                return;
            }
            this.checkFreeAccount();
        }

        checkFreeAccount = () => {
            const { dispatch, user } = this.props;
            if ((isOnlyPro && !user.is_pro) || (!isOnlyPro && user.is_free)) {
                dispatch(
                    updateUI({
                        errors: {
                            type: ERROR_MESSAGES.FREE_ACCOUNT_PERMISSION_ERROR
                        }
                    })
                );
                return true;
            }
            return false;
        };

        render() {
            const props = this.props;
            return (
                <Component
                    checkFreeAccount={this.checkFreeAccount}
                    {...props}
                />
            );
        }
    }

    const state = createStructuredSelector({
        user: getUser
    });
    return connect(state)(NewComponent);
};

export default withFreeAccountCheck;
