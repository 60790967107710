import React from 'react';
import pdf_icon from 'assets/images/pdf_icon.svg';
const add_media_box_file_style = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1.25rem',
    width: '100%',
    height: '3.75rem',
    borderRadius: '0.3125rem',
    border: 'solid 0.0625rem #d5d5d5',
    backgroundColor: '#ffffff'
};

const add_media_box_file_info_style = {
    cursor: 'pointer',
    paddingLeft: '1.275rem',
    overflow: 'hidden'
};

const file_info_name_style = {
    whiteSpace: 'pre',
    paddingBottom: '0.3125rem',
    marginBottom: 0,
    fontFamily: 'Roboto',
    fontSize: '1.0625rem',
    fontWeight: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
};

const file_info_size_style = {
    display: 'inline-block',
    marginBottom: 0,
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    fontWeight: 'normal',
    color: '#2f5f81',
    borderBottom: '1px solid #2f5f81'
};

const media_file_icon_style = {
    color: '#2f5f81',
    width: '1.9563rem',
    height: '2.4438rem',
    fontSize: '2.5rem',
    marginLeft: '1.25rem',
    marginTop: '0px !important',
    minWidth: '0px'
};

const DocumentFile = ({ block, contentState }) => {
    const _contentState$getEnti = contentState
            .getEntity(block.getEntityAt(0))
            .getData(),
        src = _contentState$getEnti.src,
        formatted_size = _contentState$getEnti.formatted_size,
        name = _contentState$getEnti.name;

    const openDocument = () => {
        let pdfWindow = window.open('');
        pdfWindow.document.write(
            `<iframe width='100%' height='100%' src="${src}"'></iframe>`
        );
    };

    return (
        <div className="document-file">
            <div
                className="add-media-box-file"
                style={add_media_box_file_style}>
                <img
                    src={pdf_icon}
                    alt="pdf icon"
                    className="media-file-icon"
                    style={media_file_icon_style}
                />
                <div style={add_media_box_file_info_style}>
                    <p style={file_info_name_style}>{name}</p>
                    <p style={file_info_size_style} onClick={openDocument}>
                        Open File ({formatted_size}){' '}
                    </p>
                </div>
            </div>
            <figcaption>{_contentState$getEnti.caption}</figcaption>
        </div>
    );
};

export default DocumentFile;
