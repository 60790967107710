// sentry
import * as Sentry from '@sentry/react';
import moment from 'moment';

export const writeSentryLog = (err) => {
    Sentry.captureMessage(
        `JRNL TEAM => [status]:${err.error?.status}[error]:${JSON.stringify(
            err
        )}`
    );
};
export const writeSentryLogForValidation = (
    entry,
    user,
    ui,
    journal,
    defaultJournal,
    filters,
    editor,
    tag,
    propsNewEntry,
    err
) => {
    const errTime = moment().local().utc().format('YYYY-MM-DDTHH:mm:ss');
    const errEntry = JSON.stringify(entry);
    const errUser = JSON.stringify(user);
    const errUI = JSON.stringify(ui);
    const errJournal = JSON.stringify(journal);
    const errDefaultJournal = JSON.stringify(defaultJournal);
    const errFilters = JSON.stringify(filters);
    const errEditor = JSON.stringify(editor);
    const errTag = JSON.stringify(tag);
    const errNewEntry = JSON.stringify(propsNewEntry);
    const errMessage = `[time]:${errTime}[entry]:${errEntry}[user]:${errUser}[ui]:${errUI}[journal]:${errJournal}[defaultJournal]:${errDefaultJournal}[filters]:${errFilters}[editor]:${errEditor}[tag]:${errTag}[newEntry]:${errNewEntry}`;
    Sentry.captureMessage(
        `JRNL TEAM => ${errMessage}[error]:${JSON.stringify(err)}`
    );
};
