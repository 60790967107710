import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// actions
import * as PaymentActions from 'redux/actions/paymentprofile';
import { getPaymentProfiles } from 'redux/selectors/PaymentProfileSelector';
import PaymentCardDialog from 'pages/Dialogs/SettingsDialog/PaymentCardDialog';
import moment from 'moment';
import { openDialog, closeDialog } from 'common/utils/dialog-utils';
import DeleteDialog from 'components/DeleteDialog';

// icons
import iconAddSm from 'assets/images/icons_svg/icon_add-sm.svg';
import iconDelete from 'assets/images/icons_svg/icon_delete.svg';
import iconEdit from 'assets/images/icons_svg/icon_edit.svg';

// images
import cardAmex from 'assets/images/card-icons/card_amex.svg';
import cardDebit from 'assets/images/card-icons/card_debit.svg';
import cardDiners from 'assets/images/card-icons/card_diners.svg';
import cardDiscover from 'assets/images/card-icons/card_discover.svg';
import cardMastercard from 'assets/images/card-icons/card_mastercard.svg';
import cardVisa from 'assets/images/card-icons/card_visa.svg';
import { URLS } from 'common/urls';
import { useLocation } from 'react-router-dom';

const BillingPane = ({ dispatch, cards }) => {
    const [isFetched, setIsFetched] = useState(false);
    const location = useLocation();
    useEffect(() => {
        if (!isFetched) {
            dispatch(PaymentActions.fetch());
            setIsFetched(true);
        }
    }, [dispatch, isFetched]);

    const openAddBillingDialog = useCallback(() => {
        openDialog(PaymentCardDialog);
    }, []);

    const editCardDialog = useCallback((card) => {
        openDialog(PaymentCardDialog, { card });
    }, []);

    const deleteCard = useCallback(
        (card) => {
            dispatch(PaymentActions.detele_card({ id: card.id }));
            closeDialog();
        },
        [dispatch]
    );

    const openDeleteCardDialog = useCallback(
        (card) => {
            openDialog(DeleteDialog, {
                title: 'Delete Card?',
                text: 'You are about to delete this card. Are you sure?',
                onDelete: () => deleteCard(card)
            });
        },
        [deleteCard]
    );

    const setCreditCardName = useCallback((type) => {
        switch (type) {
            case 'mc':
                return 'Mastercard';
            case 'visa':
                return 'Visa';
            case 'amex':
                return 'American Express';
            case 'discover':
                return 'Discover';
            case 'diners':
                return 'Diners Club';
            default:
                return 'Debit';
        }
    }, []);

    const setCreditCardIcon = useCallback((type) => {
        switch (type) {
            case 'mc':
                return <img src={cardMastercard} alt="" />;
            case 'discover':
                return <img src={cardDiscover} alt="" />;
            case 'visa':
                return <img src={cardVisa} alt="" />;
            case 'amex':
                return <img src={cardAmex} alt="" />;
            case 'diners':
                return <img src={cardDiners} alt="" />;
            default:
                return <img src={cardDebit} alt="" />;
        }
    }, []);

    const activeClassName =
        location.pathname === URLS.SETTINGS.BILLING ? 'active show' : '';

    const checkPaymentProfiles = () => {
        let paymentProfileFound = false;
        for (let i = 0; i < cards.paymentProfiles.length; i++) {
            if (!cards?.paymentProfiles[i]?.deleted) {
                paymentProfileFound = true;
            }
        }
        return paymentProfileFound;
    };

    return (
        <div
            className={`tab-pane fade billing-settings ${activeClassName}`}
            id="billing"
            role="tabpanel"
            aria-labelledby="billing-tab">
            <h3 className="settings-title">Your Cards</h3>
            {checkPaymentProfiles() ? (
                <ul className="list">
                    {cards.paymentProfiles?.map(
                        (card) =>
                            !card?.deleted && (
                                <li className="list__item" key={card?.id}>
                                    <div className="list__img list__img--card">
                                        {setCreditCardIcon(card?.card_type)}
                                    </div>
                                    <div className="list__content">
                                        <div className="list__title">
                                            {setCreditCardName(card?.card_type)}
                                            &nbsp;
                                            <span className="card-last-digit">
                                                ending in{' '}
                                                {card?.safe_credit_card_number.slice(
                                                    card
                                                        ?.safe_credit_card_number
                                                        .length - 4
                                                )}
                                            </span>
                                        </div>
                                        <div className="card-subtitle-info list__subtitle">
                                            {card?.default && (
                                                <span className="default-card-tag">
                                                    Default Card
                                                </span>
                                            )}
                                            Expires on{' '}
                                            {card?.expiration_date &&
                                                moment(
                                                    card?.expiration_date
                                                ).format('MM/YY')}
                                        </div>
                                    </div>
                                    <div className="list__actions">
                                        <button
                                            className="list__button btn-icon btn-icon--6"
                                            onClick={() =>
                                                editCardDialog(card)
                                            }>
                                            <img
                                                src={iconEdit}
                                                aria-labelledby={iconEdit}
                                                alt=""
                                            />
                                        </button>
                                        <button
                                            className="list__button btn-icon btn-icon--6"
                                            onClick={() =>
                                                openDeleteCardDialog(card)
                                            }>
                                            <img
                                                src={iconDelete}
                                                aria-labelledby={iconDelete}
                                                alt=""
                                            />
                                        </button>
                                    </div>
                                </li>
                            )
                    )}
                </ul>
            ) : (
                <>
                    <p className="settings-desc">
                        There is no credit cards on file
                    </p>
                    <p className="settings-info">
                        Add one to order books and more
                    </p>
                </>
            )}

            <button
                className="btn btn-add-card btn-gradient"
                onClick={openAddBillingDialog}>
                Add a credit card
                <img
                    className="right"
                    src={iconAddSm}
                    aria-labelledby={iconAddSm}
                    alt=""
                />
            </button>
        </div>
    );
};

const state = createStructuredSelector({
    cards: getPaymentProfiles
});

export default connect(state)(BillingPane);
