import React, { useEffect, useCallback, useState } from 'react';
// redux
import { connect } from 'react-redux';
// Selectors
import { getUI } from 'redux/selectors';
import { createStructuredSelector } from 'reselect';
// Actions
import { updateUI } from 'redux/actions/ui';
// utils
import { checkSpineSetup } from 'common/utils/validatecheck/bookcreate';

import TextField from 'components/TextField';

const SpineStep = ({ display, dispatch, ui }) => {
    // check validability
    const spine = ui.spine;
    const [submitted, setSubmitted] = useState(false);
    const [nameError, setNameError] = useState('');

    useEffect(() => {
        if (display) {
            dispatch(
                updateUI({
                    canGoNext: checkSpineSetup(spine)
                })
            );
        }
        return () => {};
    }, [display, spine, dispatch]);

    // handlers
    const onChangeSpineText = useCallback(
        (e) => {
            const text = e.target.value;
            if (checkSpineSetup(text)) {
                setSubmitted(false);
            } else {
                setSubmitted(true);
                setNameError('Ensure this value has at most 75 characters');
            }
            dispatch(
                updateUI({
                    spine: text
                })
            );
        },
        [dispatch]
    );

    return (
        <div
            className={`new-book-step-9 new-book-step ${
                display ? 'active-form' : ''
            }`}>
            <div className="section-title">Spine</div>
            <div className="new-book-step-form">
                <p>Choose what you want to appear on the book spine.</p>
                <div className="form-group">
                    <div className="form-group__item p-0">
                        <TextField
                            type="text"
                            value={ui.spine || ''}
                            onChange={onChangeSpineText}
                            id="spine"
                            required={true}
                            name="spine"
                            placeholder="Enter Spine Text"
                            submitted={submitted}
                            errorProps={{
                                style: { color: '#f00' }
                            }}
                            error={nameError}
                            label={undefined}
                            validator={undefined}
                            onValidated={undefined}
                            labelProps={undefined}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    ui: getUI
});
export default connect(state)(SpineStep);
