import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { createStructuredSelector } from 'reselect';
import {
    getInstagramProfile,
    getSocialAccounts,
    getUser
} from 'redux/selectors';
import * as InstagramActions from 'redux/actions/instagram';
import * as UserActions from 'redux/actions/user';
import {
    FACEBOOK_O_AUTHORIZATION_URL,
    INSTAGRAM_O_AUTHORIZATION_URL,
    SOCIAL_CONNECT,
    SOCIAL_TYPE_FACEBOOK,
    SOCIAL_TYPE_GOOGLE,
    SOCIAL_TYPE_INSTAGRAM
} from 'common/constant';
import { URLS } from 'common/urls';
import GoogleLogin from 'react-google-login';
import settings from 'common/configs/setting';
import * as GoogleActions from 'redux/actions/google';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

// icons
import iconDelete from 'assets/images/icons_svg/icon_delete.svg';
import iconAngleRight from 'assets/images/icons_svg/icon_angle-right.svg';
import iconSocialFacebook from 'assets/images/icons_svg/icon_social-facebook.svg';
import iconSocialDrive from 'assets/images/icons_svg/icon_social-drive.svg';
// import iconSocialTwitter from 'assets/images/icons_svg/icon_social-twitter.svg';
import iconSocialInstagram from 'assets/images/icons_svg/icon_social-instagram.svg';
import { socialConnect } from 'common/utils/social-connect-utils';

const SocialConnectTabPane = ({
    instagramProfile,
    dispatch,
    socialAccounts,
    user,
    checkFreeAccount
}) => {
    const [instagramIsConnected, setInstagramIsConected] = useState(false);
    const [facebookIsConnected, setFacebookIsConnected] = useState(false);
    const [googleIsConnected, setGoogleIsConnected] = useState(false);
    const [socialErrors, setSocialErrors] = useState({
        hasError: false,
        message: ''
    });
    useEffect(() => {
        dispatch(InstagramActions.fetchInstagramProfile());
    }, [dispatch]);

    useEffect(() => {
        if (socialAccounts?.length !== 0) {
            socialAccounts.forEach((accounts) => {
                switch (accounts.provider) {
                    case SOCIAL_CONNECT.fb_instagram.user_acc: {
                        setInstagramIsConected(true);
                        break;
                    }
                    case SOCIAL_CONNECT.facebook.user_acc: {
                        setFacebookIsConnected(true);
                        break;
                    }
                    case SOCIAL_CONNECT.google.user_acc: {
                        setGoogleIsConnected(true);
                        break;
                    }
                    default: {
                        break;
                    }
                }
            });
        }
        // eslint-disable-next-line
    }, [dispatch, socialAccounts]);
    const connectInstagram = useCallback(() => {
        if (checkFreeAccount()) {
            return;
        }
        const titleURL = `${INSTAGRAM_O_AUTHORIZATION_URL}&state={"provider": "${SOCIAL_TYPE_INSTAGRAM}", "userid": ${user.id}, "position": "setting"}`;
        // In popup window, will call this function to connect
        window.location.connectSocialConnect = socialConnect;
        window.open(titleURL);
    }, [user, checkFreeAccount]);

    const connectFacebook = useCallback(() => {
        if (checkFreeAccount()) {
            return;
        }
        const titleURL = `${FACEBOOK_O_AUTHORIZATION_URL}&state={"provider": "${SOCIAL_TYPE_FACEBOOK}", "userid": ${user.id}, "position": "setting"}`;
        window.location.connectSocialConnect = socialConnect;
        window.open(titleURL);
    }, [user, checkFreeAccount]);

    const onGoogleLogin = useCallback(
        (connectFunc) => {
            if (checkFreeAccount()) {
                return;
            }
            connectFunc();
        },
        [checkFreeAccount]
    );

    const removeSocialConnect = useCallback(
        (account) => {
            dispatch(
                InstagramActions.disconnectConnect(
                    account.id,
                    account?.provider
                )
            ).then((response) => {
                if (response.payload.status === 200) {
                    dispatch(UserActions.fetch());
                }
            });
        },
        [dispatch]
    );

    const userNameFromJSON = useCallback((provider, data) => {
        switch (provider?.user_acc) {
            case SOCIAL_TYPE_INSTAGRAM: {
                return data?.username;
            }

            case SOCIAL_TYPE_FACEBOOK: {
                return data?.name;
            }

            case SOCIAL_TYPE_GOOGLE: {
                return data?.name;
            }

            default: {
                return undefined;
            }
        }
    }, []);

    const changeAccountDefault = useCallback(
        (account) => {
            dispatch(
                UserActions.updateAccountDefault({
                    social_account: account.id,
                    default: !account?.default
                })
            ).then((response) => {
                dispatch(UserActions.fetch());
            });
        },
        [dispatch]
    );

    const changeAccountAutoimport = useCallback(
        (account) => {
            dispatch(
                UserActions.updateAccountDefault({
                    social_account: account.id,
                    autoimport: !account?.autoimport
                })
            ).then((response) => {
                dispatch(UserActions.fetch());
            });
        },
        [dispatch]
    );

    const renderSocialAccounts = useCallback(() => {
        return (socialAccounts || []).map((account, index) => {
            const jsonStr = account.extra_data_as_json;
            const data = JSON.parse(jsonStr);
            const provider = SOCIAL_CONNECT[account.provider];
            const username =
                userNameFromJSON(provider, data) || user.public_display_name;

            if (!username) {
                return undefined;
            }
            return (
                <div key={index}>
                    <Fragment>
                        <div className={'list__item list__item--stack'}>
                            <div className="list__row">
                                <div
                                    className={`list__img list__img--social ${provider?.icon_name}`}>
                                    {!provider.hasCssIcon && provider.icon}
                                </div>
                                <div className="list__content">
                                    <div className="list__title">
                                        @{username}
                                    </div>
                                    <div className="list__subtitle">
                                        {provider?.name || ''} Account
                                    </div>
                                </div>
                                <div className="list__actions">
                                    <button
                                        className="list__button icon-static btn-icon--6"
                                        onClick={() =>
                                            removeSocialConnect(account)
                                        }>
                                        <img
                                            src={iconDelete}
                                            alt="Delete"
                                            aria-labelledby={iconDelete}
                                        />
                                    </button>
                                </div>
                            </div>
                            <div className="list__row">
                                <div className="list-toggles">
                                    <div className="list-toggles__item">
                                        <div className="list-toggles__content">
                                            <div className="list-toggles__title">
                                                Use this account as a default{' '}
                                            </div>
                                        </div>
                                        <div className="toggle">
                                            <input
                                                type="checkbox"
                                                id={`default-account-checkbox-${account?.id}`}
                                                checked={!!account?.default}
                                                onChange={() =>
                                                    changeAccountDefault(
                                                        account
                                                    )
                                                }
                                            />
                                            <label
                                                className="settings-toggle-desc"
                                                htmlFor={`default-account-checkbox-${account?.id}`}>
                                                {' '}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="list-toggles__item">
                                        <div className="list-toggles__content">
                                            <div className="list-toggles__title">
                                                Automatically import content
                                                from this account
                                            </div>
                                            <div className="list-toggles__subtitle">
                                                JRNL will automatically import
                                                your photos, videos and posts
                                                from your connected social media
                                                accounts if you enable the
                                                option.
                                            </div>
                                        </div>
                                        <div className="toggle">
                                            <input
                                                type="checkbox"
                                                id={`autoimport-account-checkbox-${account?.id}`}
                                                checked={!!account?.autoimport}
                                                onChange={() =>
                                                    changeAccountAutoimport(
                                                        account
                                                    )
                                                }
                                            />
                                            <label
                                                className="settings-toggle-desc"
                                                htmlFor={`autoimport-account-checkbox-${account?.id}`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                </div>
            );
        });
    }, [
        user,
        removeSocialConnect,
        socialAccounts,
        userNameFromJSON,
        changeAccountDefault,
        changeAccountAutoimport
    ]);

    const location = useLocation();
    const activeClassName =
        location.pathname === URLS.SETTINGS.SOCIALCONNECT ? 'active show' : '';
    const responseGoogle = (response) => {
        dispatch(
            GoogleActions.connectGoogle(response.tokenObj.access_token)
        ).then((response) => {
            if (response.payload.status === 200) {
                dispatch(UserActions.fetch());
            }
        });
    };
    const onGoogleLoginError = (error) => {
        if (error.message) {
            setSocialErrors({
                hasError: true,
                message: error.message
            });
        }
    };
    return (
        <div
            className={`tab-pane fade social-connect-settings ${activeClassName}`}
            id="socialConnect"
            role="tabpanel"
            aria-labelledby="socialConnect-tab">
            <h3 className="settings-title">Available Accounts</h3>
            <div className="available-accounts-section">
                <ul className="list">
                    <li className="list__item" onClick={connectInstagram}>
                        <div className="list__img list__img--social instagram-bg--gradient">
                            <img src={iconSocialInstagram} alt="Instagram" />
                        </div>
                        <div className="list__content">
                            <div className="list__title">Instagram</div>
                            <div className="list__subtitle">
                                {instagramIsConnected
                                    ? 'Connected'
                                    : 'Unconnected'}
                            </div>
                        </div>
                        <img
                            src={iconAngleRight}
                            alt=""
                            className="icon-static btn-icon--4"
                        />
                    </li>
                    {/*<li className="list__item">*/}
                    {/*    <div className="list__img list__img--social twitter-bg">*/}
                    {/*        <img src={iconSocialTwitter} alt="Twitter" />*/}
                    {/*    </div>*/}
                    {/*    <div className="list__content">*/}
                    {/*        <div className="list__title">Twitter</div>*/}
                    {/*        <div className="list__subtitle">Unconnected</div>*/}
                    {/*    </div>*/}
                    {/*    <img*/}
                    {/*        src={iconAngleRight}*/}
                    {/*        alt=""*/}
                    {/*        className="icon-static btn-icon--4"*/}
                    {/*    />*/}
                    {/*</li>*/}
                    <li className="list__item" onClick={connectFacebook}>
                        <div className="list__img list__img--social facebook-bg">
                            <img src={iconSocialFacebook} alt="Facebook" />
                        </div>
                        <div className="list__content">
                            <div className="list__title">Facebook</div>
                            <div className="list__subtitle">
                                {facebookIsConnected
                                    ? 'Connected'
                                    : 'Unconnected'}
                            </div>
                        </div>
                        <img
                            src={iconAngleRight}
                            alt=""
                            className="icon-static btn-icon--4"
                        />
                    </li>
                    <li className="list__item">
                        <div className="list__img list__img--social google-bg">
                            <img src={iconSocialDrive} alt="Google Drive" />
                        </div>
                        <div className="list__content">
                            <div className="list__title">Google Drive</div>
                            <div className="list__subtitle">
                                {googleIsConnected
                                    ? 'Connected'
                                    : 'Unconnected'}
                            </div>
                        </div>
                        <img
                            src={iconAngleRight}
                            alt=""
                            className="icon-static btn-icon--4"
                        />
                        <GoogleLogin
                            clientId={settings.google_client_id}
                            render={(renderProps) => (
                                <div
                                    className="list__hotspot"
                                    onClick={() =>
                                        onGoogleLogin(renderProps.onClick)
                                    }
                                    disabled={renderProps.disabled}></div>
                            )}
                            buttonText="Login"
                            onSuccess={responseGoogle}
                            onFailure={onGoogleLoginError}
                            cookiePolicy={'single_host_origin'}
                        />
                    </li>
                </ul>
            </div>
            <div className="connected-accounts-section">
                <h3 className="settings-title">Connected Accounts</h3>
                <div className="list">{renderSocialAccounts()}</div>
            </div>
            <Snackbar
                open={socialErrors.hasError}
                onClose={() =>
                    setSocialErrors({ hasError: false, message: '' })
                }
                autoHideDuration={6000}>
                <Alert severity="error">{socialErrors.message}</Alert>
            </Snackbar>
        </div>
    );
};

const state = createStructuredSelector({
    instagramProfile: getInstagramProfile,
    socialAccounts: getSocialAccounts,
    user: getUser
});

export default connect(state)(SocialConnectTabPane);
