import { URLS } from 'common/urls';
import buildV2Authorization from 'common/utils/buildV2Authorization';
import { UserSelector } from 'redux/selectors/UserSelector';
import {
    FETCH_V2_PUBLIC_USER,
    FETCH_V2_PUBLIC_USER_INDIVIDUAL,
    COMPARE_FACE
} from 'redux/actions/actionTypes';

export const compare_face = (id, data) => (dispatch, getState) => {
    const url = `${URLS.API.USER.PUBLIC}${id}/compare_face/`;
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);

    return dispatch({
        type: COMPARE_FACE,
        payload: {
            request: {
                url,
                method: 'POST',
                data,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const fetchAll = (pageUrl) => (dispatch, getState) => {
    const url = pageUrl ? pageUrl : URLS.API.USER.PUBLIC;
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);

    return dispatch({
        type: FETCH_V2_PUBLIC_USER,
        payload: {
            request: {
                url,
                method: 'GET',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const fetchIndividualUser = (id, options = {}) => (
    dispatch,
    getState
) => {
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);
    const url = `${URLS.API.USER.PUBLIC}${id}/`;

    return dispatch({
        type: FETCH_V2_PUBLIC_USER_INDIVIDUAL,
        payload: {
            request: {
                url,
                method: 'GET',
                params: options,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};
