import _ from 'underscore';

import { createSelector } from 'reselect';

export const InvitationSelector = (state) => state.invitation;

export const InvitationForUUIDSelector = (state, props) => {
    if (props.match?.params.invitationId) {
        const { invitations } = state.invitation;

        const invitation = _.findWhere(invitations, {
            unique_id: props.match?.params.invitationId
        });
        return invitation;
    }
    return undefined;
};

export const InvitationForEntrySelector = (state, props) => {
    const entryId = props.match?.params.entryId || props.entry?.id || undefined;

    if (!entryId) {
        return undefined;
    }

    const { entries } = state.entry;
    const entry = _.findWhere(entries, { id: parseInt(entryId) });

    return entry?.invitations;
};

export const getInvitations = createSelector(
    [InvitationSelector],
    (invitation) => invitation.invitations
);

export const getOutstandingInvitations = createSelector(
    [InvitationSelector],
    (invitation) => {
        return invitation.invitations.filter(
            (invitation) => invitation.deleted === false
        );
    }
);

export const getInvitationForUUID = createSelector(
    [InvitationForUUIDSelector],
    (invitation) => invitation
);

export const getInviteId = createSelector(
    [InvitationSelector],
    (invitation) => invitation.invite_id
);

export const getEntryInvitations = createSelector(
    [InvitationForEntrySelector],
    (entryInvitations) => entryInvitations
);
