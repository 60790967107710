import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';

// components
import withBook from 'common/utils/components/withBook';

// selectors
import { createStructuredSelector } from 'reselect';
import {
    getUI,
    getDefaultJournal,
    getJournalList,
    getBookById,
    getAamJournal
} from 'redux/selectors';

// Actions
import { connect } from 'react-redux';
import { updateUI } from 'redux/actions/ui';
import * as BookActions from 'redux/actions/book';
import * as JournalActions from 'redux/actions/journal';
import * as TagActions from 'redux/actions/tag';
import * as EntryActions from 'redux/actions/entry';

// Dialogs
import BookSaveDialog from 'pages/Dialogs/BookSaveDialog';
import BookCreateDialog2 from 'pages/Dialogs/BookCreateDialog2';

// utils
import serializeUIToBook from 'common/utils/bookcreation/serializeUIToBook';
import deserializeUIToBook from 'common/utils/bookcreation/deserializeUIToBook';

// constants
import {
    book_creation_steps,
    BOOK_DEFAULT_TITLE,
    ENTRY_ALL,
    ENTRY_DO_NOT_PRINT,
    BOOK_STATUS_BUILDING,
    ENTRY_MR,
    BOOK_EXPORT_FORMAT,
    DEFAULT_TIMEZONE
} from 'common/constant';

// steps
import ExportFormatStep from 'pages/Dialogs/BookCreateDialog/ExportFormatStep';
import JournalSelectStep from 'pages/Dialogs/BookCreateDialog/JournalSelectStep';
import EntriesStep from 'pages/Dialogs/BookCreateDialog/EntriesStep';
import AllAboutMeStep from 'pages/Dialogs/BookCreateDialog/AllAboutMeStep';
import ColorOptionsStep from 'pages/Dialogs/BookCreateDialog/ColorOptionsStep';
import PhotosOptionsStep from 'pages/Dialogs/BookCreateDialog/PhotosOptionsStep';
import FrontCoverStep from 'pages/Dialogs/BookCreateDialog/FrontCoverStep';
import SubTitleStep from 'pages/Dialogs/BookCreateDialog/SubTitleStep';
import SpineStep from 'pages/Dialogs/BookCreateDialog/SpineStep';
import BackCoverStep from 'pages/Dialogs/BookCreateDialog/BackCoverStep';
import CustomIntroductionPageStep from 'pages/Dialogs/BookCreateDialog/CustomIntroductionPageStep';
import MediaOptionsStep from 'pages/Dialogs/BookCreateDialog/MediaOptionsStep';
import { openDialog } from 'common/utils/dialog-utils';
import BookCreateSuccessDialog from 'pages/Dialogs/BookCreateSuccessDialog';

const BookCreateDialog = ({
    open,
    _close,
    dispatch,
    defaultJournal,
    journal,
    ui,
    book,
    aamJournal,
    step: propsStep = 1
}) => {
    const [step, setStep] = useState(propsStep);
    const [isCreating, seIsCreating] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [uiInitialized, setUIInitialized] = useState(false);

    // component did mount
    useEffect(() => {
        // fetch data
        dispatch(JournalActions.fetch());
        dispatch(TagActions.fetch());
        return () => {};
    }, [dispatch]);

    useEffect(() => {
        if (book && !uiInitialized && journal?.journals) {
            const newUI = deserializeUIToBook(book, journal?.journals || []);

            dispatch(updateUI(Object.assign({}, newUI)));

            setStep(propsStep);
            setUIInitialized(true);
        } else if (!book && !uiInitialized) {
            // default book creation params
            dispatch(
                updateUI({
                    aam: ENTRY_MR,
                    entryRange: ENTRY_ALL,
                    minDate: undefined,
                    maxDate: undefined,
                    color: true,
                    photos: true,
                    selectedJournal: undefined,
                    title: BOOK_DEFAULT_TITLE,
                    subtitle: '',
                    spine: BOOK_DEFAULT_TITLE,
                    back_cover: '',
                    custom_introduction: '',
                    export_format: BOOK_EXPORT_FORMAT['6_9_FORMAT'],
                    timezone: DEFAULT_TIMEZONE
                })
            );

            setUIInitialized(true);
        }
    }, [book, ui, uiInitialized, dispatch, journal, propsStep]);

    useEffect(() => {
        // initiate ui.newBook
        dispatch(
            updateUI({
                newBook: {
                    export_format: '6X9',
                    title: 'My JRNL Book',
                    book_builder_status: 26,
                    timezone_offset: moment().format('ZZ'),
                    journal: defaultJournal?.id
                }
            })
        );
        return () => {};
    }, [defaultJournal, dispatch]);

    const bookCreationHandler = useCallback(
        (response) => {
            dispatch(
                updateUI({
                    book_extra_journals: []
                })
            );
            seIsCreating(true);

            // open book creation success modal
            openDialog(BookCreateSuccessDialog, {
                bookId: response.payload.data.id
            });
        },
        [dispatch]
    );

    const createBook = useCallback(() => {
        const newBook = serializeUIToBook(ui, !book?.id, false);

        if (book?.id && book?.book_builder_status !== BOOK_STATUS_BUILDING) {
            dispatch(
                BookActions.update(
                    {
                        ...book,
                        ...newBook
                    },
                    { freeze_status: true }
                )
            ).then((response) => {
                if (response?.payload?.status === 202) {
                    bookCreationHandler(response);
                }
            });
        } else {
            dispatch(BookActions.create(newBook)).then((response) => {
                if (response?.payload?.status === 201) {
                    bookCreationHandler(response);
                }
            });
        }
    }, [ui, dispatch, bookCreationHandler, book]);

    const fetchEntryCount = useCallback(() => {
        const {
            book_extra_journals = [],
            book_tags = [],
            book_select_journal = true
        } = ui;

        const handler = (response) => {
            if (response.payload.status === 200) {
                dispatch(
                    updateUI({
                        selected_entries_count_fetching: false,
                        selected_entries_count:
                            response.payload?.data?.meta?.total_count || 0
                    })
                );
            }
        };

        if (book_select_journal === true) {
            dispatch(
                EntryActions.fetchEntriesWithOptions({
                    by_journal: book_extra_journals
                        .map((item) => item.id)
                        .join(',')
                })
            ).then(handler);
        } else {
            dispatch(
                EntryActions.fetchEntriesWithOptions({
                    tags__in: book_tags.map((item) => item.id).join(',')
                })
            ).then(handler);
        }

        dispatch(
            updateUI({
                selected_entries_count_fetching: true
            })
        );
    }, [ui, dispatch]);

    const fetchEntryMinAndMaxDate = useCallback(() => {
        const {
            entryRange,
            book_extra_journals = [],
            book_tags = [],
            book_select_journal = true
        } = ui;
        const options = {};
        if (entryRange === ENTRY_ALL) {
            if (book_select_journal) {
                // options['extra_journals'] = book_extra_journals
                //     .map((item) => item.id)
                //     .join(',');
                options['by_journal'] = book_extra_journals
                    .map((item) => item.id)
                    .join(',');
            } else {
                options['tags__in'] = book_tags
                    .map((item) => item.id)
                    .join(',');
            }
        } else if (entryRange === ENTRY_DO_NOT_PRINT) {
            options['entry_type'] = 'QA';
        }

        if (Object.keys(options).length > 0) {
            dispatch(
                EntryActions.fetchEntriesWithOptions({
                    ...options,
                    order_by: '-entry_date',
                    limit: 1
                })
            ).then((response) => {
                if (
                    response.payload.status === 200 &&
                    response.payload?.data?.objects.length > 0
                ) {
                    dispatch(
                        updateUI({
                            maxDate:
                                response.payload.data.objects[0].entry_date,
                            isSetIfNull: true
                        })
                    );
                }
            });

            dispatch(
                EntryActions.fetchEntriesWithOptions({
                    ...options,
                    order_by: 'entry_date',
                    limit: 1
                })
            ).then((response) => {
                if (
                    response.payload.status === 200 &&
                    response.payload?.data?.objects.length > 0
                ) {
                    dispatch(
                        updateUI({
                            minDate:
                                response.payload.data.objects[0].entry_date,
                            isSetIfNull: true
                        })
                    );
                }
            });
        }
    }, [ui, dispatch]);

    const fetchEntryMinAndMaxDateForAAM = useCallback(() => {
        const { aamMinDate, aamMaxDate } = ui;
        if (!aamMaxDate || !aamMinDate) {
            const options = {};
            if (aamJournal) {
                // options['extra_journals'] = book_extra_journals
                //     .map((item) => item.id)
                //     .join(',');
                options['by_journal'] = aamJournal.id;
            }

            if (Object.keys(options).length > 0) {
                dispatch(
                    EntryActions.fetchEntriesWithOptions({
                        ...options,
                        order_by: '-entry_date',
                        limit: 1
                    })
                ).then((response) => {
                    if (
                        response.payload.status === 200 &&
                        response.payload?.data?.objects.length > 0
                    ) {
                        const aamMaxDate =
                            response.payload.data.objects[0].entry_date;
                        const aamMaxDateConv = moment(aamMaxDate).toDate();
                        dispatch(
                            updateUI({
                                aamMaxDate: aamMaxDateConv
                            })
                        );
                    }
                });

                dispatch(
                    EntryActions.fetchEntriesWithOptions({
                        ...options,
                        order_by: 'entry_date',
                        limit: 1
                    })
                ).then((response) => {
                    if (
                        response.payload.status === 200 &&
                        response.payload?.data?.objects.length > 0
                    ) {
                        const aamMinDate =
                            response.payload.data.objects[0].entry_date;
                        const aamMinDateConv = moment(aamMinDate).toDate();
                        dispatch(
                            updateUI({
                                aamMinDate: aamMinDateConv
                            })
                        );
                    }
                });
            }
        }
    }, [ui, dispatch, aamJournal]);

    // handlers
    const onNextStep = useCallback(() => {
        if (step <= 11) {
            if (step === 2) {
                fetchEntryCount();
                fetchEntryMinAndMaxDate();
            }
            if (step === 3) {
                fetchEntryMinAndMaxDate();
            }
            if (step === 4) {
                fetchEntryMinAndMaxDateForAAM();
            }
            setStep(step + 1);
        } else {
            createBook();
        }
    }, [
        step,
        createBook,
        fetchEntryCount,
        fetchEntryMinAndMaxDate,
        fetchEntryMinAndMaxDateForAAM
    ]);

    const onPrevStep = useCallback(() => {
        if (step > 1) {
            setStep(step - 1);
        }
    }, [step]);

    const onChangeJournal = useCallback(
        (journal) => {
            dispatch(
                updateUI({
                    newBook: {
                        journal
                    }
                })
            );
        },
        [dispatch]
    );

    const bookCancelHandler = useCallback(
        (response) => {
            dispatch(
                updateUI({
                    dialogs: [
                        withBook(BookSaveDialog, response?.payload?.data.id)
                    ]
                })
            );
        },
        [dispatch]
    );

    const onCloseDialog = useCallback(() => {
        if (isCreating === true) {
            return;
        }
        const newBook = serializeUIToBook(
            ui,
            !book?.id,
            true,
            book_creation_steps[step - 1]
        );

        setIsSaving(true);
        if (book?.id && book.book_builder_status !== BOOK_STATUS_BUILDING) {
            dispatch(
                BookActions.update(
                    {
                        ...book,
                        ...newBook
                    },
                    { freeze_status: true }
                )
            ).then((response) => {
                if (response?.payload?.status === 202) {
                    bookCancelHandler(response);
                }
            });
        } else {
            dispatch(BookActions.create(newBook)).then((response) => {
                if (response?.payload?.status === 201) {
                    bookCancelHandler(response);
                }
            });
        }
    }, [dispatch, step, ui, bookCancelHandler, book, isCreating]);

    const renderLineStepItem = useCallback((step, current) => {
        const className = `${step >= current ? 'done-step' : ''} ${
            step === current ? 'active-step' : ''
        }`;
        return (
            <li key={current} className={className}>
                <div></div>
            </li>
        );
    }, []);

    const onSummeryView = useCallback(() => {
        const s = 1;
        openDialog(BookCreateDialog2, {
            match: {
                params: {
                    bookId: book?.id
                }
            },
            s
        });
    }, [book]);

    const onKeyDown = useCallback((e) => {
        const isEnterPress = e.code === 'Enter' || e?.keyCode === 13;
        if (isEnterPress && e.target.tagName.toLowerCase() === 'button') {
            e.preventDefault();
        }
    }, []);
    // Keydown handler
    useEffect(() => {
        document.addEventListener('keydown', onKeyDown);

        return () => {
            document.removeEventListener('keydown', onKeyDown);
        };
    }, [onKeyDown]);
    return (
        <div className="modal-frame">
            <div className="modal-box modal-box--600">
                <div className="modal__header modal__header--button">
                    Create New Book
                    {isCreating && (
                        <div className="saving-text">
                            <div className="saving-gif pr-3"></div>
                            Creating...
                        </div>
                    )}
                    {isSaving && (
                        <div className="saving-text">
                            <div className="saving-gif pr-3"></div>
                            Saving...
                        </div>
                    )}
                </div>
                <div className="new-book-timeline">
                    <ol className="new-book-timeline-steps">
                        {[...book_creation_steps, null].map((item, index) =>
                            renderLineStepItem(step, index + 1)
                        )}
                    </ol>
                </div>
                <div className="modal__body">
                    <ExportFormatStep display={step === 1} />
                    <JournalSelectStep
                        onChange={onChangeJournal}
                        display={step === 2}
                    />
                    <EntriesStep display={step === 3} />
                    <MediaOptionsStep display={step === 4} />
                    <AllAboutMeStep display={step === 5} />
                    <ColorOptionsStep display={step === 6} />
                    <PhotosOptionsStep display={step === 7} />
                    <FrontCoverStep display={step === 8} />
                    <SubTitleStep display={step === 9} />
                    <SpineStep display={step === 10} />
                    <BackCoverStep display={step === 11} />
                    <CustomIntroductionPageStep display={step === 12} />
                </div>
                <div className="modal__footer">
                    {step > 1 && (
                        <button
                            className="btn btn-outline"
                            onClick={onPrevStep}>
                            Back
                        </button>
                    )}
                    <button onClick={onCloseDialog} className="btn btn-outline">
                        Save and exit
                    </button>
                    <button onClick={onSummeryView} className="btn btn-outline">
                        Full View
                    </button>
                    <button
                        disabled={!ui.canGoNext}
                        className="btn btn-gradient"
                        onClick={onNextStep}>
                        {step === 12 ? 'Preview' : 'Next Step'}
                    </button>
                </div>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    ui: getUI,
    defaultJournal: getDefaultJournal,
    journal: getJournalList,
    book: getBookById,
    aamJournal: getAamJournal
});
export default connect(state)(BookCreateDialog);
