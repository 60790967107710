import React, { useEffect } from 'react';
import Search from 'pages/Search/Search';
import EntryListViewMode from 'components/EntryListViewMode';

// image

const SearchPage = () => {
    useEffect(() => {
        document.title = 'JRNL | Search';
    }, []);

    return (
        <div>
            <section className="drawer drawer--left">
                <EntryListViewMode showAllAboutMe={false}></EntryListViewMode>
            </section>
            <main id="main" style={{ marginLeft: 0 }} className="app-main">
                <Search />
            </main>
        </div>
    );
};

export default SearchPage;
