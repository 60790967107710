import {
    TAGGED_USER_FETCH,
    TAGGED_USER_ONLY_ALL_FETCH,
    TAGGED_USER_CREATE
} from 'redux/actions/actionTypes';
import { SUCCESS_SUFFIX } from 'common/constant';

const initialState = {
    isEnabled: true,
    isPending: false,
    tagged_users: null,
    tagged_users_only: null
};

const TaggedUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case TAGGED_USER_FETCH: {
            return {
                ...state,
                isPending: true
            };
        }
        case TAGGED_USER_ONLY_ALL_FETCH + SUCCESS_SUFFIX: {
            return {
                ...state,
                tagged_users_only: action.payload.data
            };
        }
        case TAGGED_USER_FETCH + SUCCESS_SUFFIX: {
            return {
                ...state,
                isPending: false,
                tagged_users: action.payload.data
            };
        }
        case TAGGED_USER_CREATE + SUCCESS_SUFFIX: {
            const tu = action.payload.data;
            const res = {
                ...state,
                tagged_users: {
                    ...state.tagged_users,
                    results: [...state.tagged_users.results, tu]
                }
            };

            const idx =
                res?.tagged_users_only?.results?.length > 0
                    ? res.tagged_users_only.results.findIndex(
                          (obj) => obj.id === tu.user_object.id
                      )
                    : -1;

            if (idx < 0) {
                res.tagged_users_only.results = [
                    ...res.tagged_users_only.results,
                    tu.user_object
                ];
            }

            return res;
        }
        default: {
            break;
        }
    }
    return state;
};

export default TaggedUserReducer;
