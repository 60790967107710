import {
    FETCH_INVITATION_LIST,
    FETCH_ENTRY_SHARING_INVITATION_LIST,
    CREATE_INVITATION,
    FETCH_PUBLIC_ENTRY_SHARING_INVITATION,
    UPDATE_INVITATION,
    REMOVE_INVITATION,
    SET_INVITE_ID,
    ACTION_USER_LOGOUT
} from 'redux/actions/actionTypes';
import { SUCCESS_SUFFIX } from 'common/constant';

const initialState = {
    isPending: false,
    invitations: undefined,
    invite_id: undefined
};

const InvitationReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_INVITATION_LIST + SUCCESS_SUFFIX:
        case FETCH_ENTRY_SHARING_INVITATION_LIST + SUCCESS_SUFFIX: {
            let invitations = state.invitations;
            let _i, _len;
            const _ref = action.payload.data.objects;

            for (_i = 0, _len = _ref.length; _i < _len; _i++) {
                const object = _ref[_i];

                const index = (invitations || []).findIndex(
                    (invitation) =>
                        object.unique_id === parseInt(invitation.unique_id)
                );

                if (index >= 0) {
                    invitations = [].concat(
                        invitations.slice(0, index),
                        [object],
                        invitations.slice(index + 1)
                    );
                } else {
                    invitations = [].concat([object], invitations || []);
                }
            }

            if (action.payload.data.meta.next !== null) {
                //    action.asyncDispatch(InvitationActions.fetch(action.payload.data.meta.next));
            }

            return Object.assign({}, state, {
                invitations,
                isPending: false
            });
        }

        case CREATE_INVITATION + SUCCESS_SUFFIX:
        case FETCH_PUBLIC_ENTRY_SHARING_INVITATION + SUCCESS_SUFFIX:
        case UPDATE_INVITATION + SUCCESS_SUFFIX: {
            let invitations = state.invitations;
            const object = action.payload.data;

            const index = (invitations || []).findIndex(
                (invitation) => object.id === parseInt(invitation.id)
            );

            if (index >= 0) {
                invitations = [].concat(
                    invitations.slice(0, index),
                    [object],
                    invitations.slice(index + 1)
                );
            } else {
                invitations = [].concat([object], invitations || []);
            }

            return Object.assign({}, state, {
                invitations,
                isPending: false
            });
        }

        case REMOVE_INVITATION + SUCCESS_SUFFIX: {
            const invitations = (state.invitations || []).filter(
                (invitation) =>
                    invitation.unique_id !==
                    action.payload.config.options.unique_id
            );

            return Object.assign({}, state, {
                invitations
            });
        }

        case SET_INVITE_ID: {
            return Object.assign({}, state, {
                invite_id: action.payload.data
            });
        }

        case ACTION_USER_LOGOUT: {
            return Object.assign({}, initialState);
        }

        default: {
            return state;
        }
    }
};

export default InvitationReducer;
