import React, { useCallback } from 'react';
import _ from 'underscore';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getFilters } from 'redux/selectors';
import * as FilterActions from 'redux/actions/filter';

// Icons
import iconSearch from 'assets/images/icons_svg/icon_search.svg';
import iconCloseSm from 'assets/images/icons_svg/icon_close-sm.svg';

const SearchInput = ({
    filters,
    dispatch,
    onChange,
    searchId,
    onEnterKeyDown,
    query
}) => {
    const focusInput = useCallback((input) => {
        if (input) {
            input.focus();
        }
    }, []);

    const onChangeQuery = useCallback(
        (e) => {
            if (onChange) {
                onChange(e.target.value);
            }
        },
        [onChange]
    );
    const onRemoveQuery = useCallback(() => {
        if (onChange) {
            onChange('');
        }
    }, [onChange]);
    const onKeyDown = useCallback(
        (e) => {
            if (e.key === 'Enter') {
                if (onEnterKeyDown) {
                    onEnterKeyDown();
                }
            }
        },
        [onEnterKeyDown]
    );

    const removeJournal = useCallback(
        (id) => {
            const filterJournals = filters.filterByJournal;
            const index = _.findIndex(
                filters.filterByJournal,
                (item) => item.id === id
            );
            filters.filterByJournal.splice(index, 1);

            dispatch(
                FilterActions.update({
                    filterByJournal: filterJournals,
                    minDate: undefined,
                    maxDate: undefined
                })
            );
        },
        [filters.filterByJournal, dispatch]
    );

    const showSelectedJournals = useCallback(() => {
        if (filters.filterByJournal) {
            return filters.filterByJournal.map((journal) => {
                return (
                    <div key={journal.id} className="search-prompt">
                        <div className="search-prompt-journal">
                            {journal.title}
                        </div>
                        <button
                            className="btn-icon btn-icon--sm btn-icon--3"
                            onClick={() => removeJournal(journal.id)}>
                            <img src={iconCloseSm} alt="" />
                        </button>
                    </div>
                );
            });
        }
        return null;
    }, [filters.filterByJournal, removeJournal]);

    return (
        <div className="page-container">
            <div className="page" id="expandable-container">
                <div className="search-bar" id="expandable-search-bar">
                    <button className="search-bar__btn btn-icon btn-icon--4">
                        <img src={iconSearch} alt="" />
                    </button>
                    {showSelectedJournals()}
                    <input
                        onKeyDown={onKeyDown}
                        ref={focusInput}
                        type="text"
                        value={query || ''}
                        onChange={onChangeQuery}
                        className="search-bar__input"
                        placeholder="Search entries, headlines..."
                    />
                    {query?.length > 0 && (
                        <button
                            className="search-bar__close btn-icon btn-icon--sm btn-icon--3"
                            onClick={onRemoveQuery}>
                            <img src={iconCloseSm} alt="" />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    filters: getFilters
});

export default connect(state)(SearchInput);
