import React from 'react';
import PropTypes from 'prop-types';

const IconError = ({ error, errorProps }) => {
    return (
        <p className="input-error-msg" {...errorProps}>
            {error}
        </p>
    );
};

IconError.propTypes = {
    error: PropTypes.string,
    errorProps: PropTypes.object
};
export default IconError;
