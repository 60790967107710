import React, { useCallback, useState, useEffect } from 'react';

// Actions
import { connect } from 'react-redux';
import * as TagActions from 'redux/actions/tag';
import { updateUI } from 'redux/actions/ui';

import DeleteTagDialog from 'pages/Dialogs/DeleteTagDialog';

// Icons
import iconCheckSm from 'assets/images/icons_svg/icon_check-sm.svg';

const withTag = (Component, tag) => (props) => {
    return <Component tag={tag} {...props} />;
};

const EditTagDialog = ({ close, dispatch, tag, open }) => {
    const [tagName, setTagName] = useState();
    const [isSaving, setIsSaving] = useState(false);
    const [isSaved, setIsSaved] = useState(false);

    const _close = useCallback(() => {
        if (close) {
            close();
        }
    }, [close]);

    const _done = useCallback(() => {
        const newTag = { ...tag };

        newTag.name = tagName;

        setIsSaving(true);
        setIsSaved(false);

        dispatch(TagActions.update(newTag, newTag.id)).then((response) => {
            setIsSaving(false);
            setIsSaved(true);

            _close();
        });
    }, [_close, dispatch, tag, tagName]);

    const onChangeTagName = useCallback((e) => {
        setTagName(e.target.value);
    }, []);

    const onDeleteTag = useCallback(
        (tag) => {
            // Show Delete Tag Dialog
            dispatch(
                updateUI({
                    dialogs: [withTag(DeleteTagDialog, tag)]
                })
            );
        },
        [dispatch]
    );

    // load tag name
    useEffect(() => {
        setTagName(tag?.name);
        return () => {};
    }, [tag]);

    return (
        <div className="add-tag-modal modal-frame">
            <div className="modal-box modal-box--600">
                <div className="modal__header modal__header--button">
                    Edit Tag
                    {isSaving && (
                        <div className="saving-text pr-2">
                            <div className="saving-gif pr-3"></div>Saving...
                        </div>
                    )}
                    {!isSaving && isSaved && (
                        <div className="saving-text pr-2">
                            <img src={iconCheckSm} alt="" /> Saved!
                        </div>
                    )}
                </div>
                <div className="modal__body">
                    <div className="form-group form-group--single">
                        <div className="form-group__item p-0">
                            <input
                                type="text"
                                id="edit-tag"
                                onChange={onChangeTagName}
                                value={tagName}
                                name="name"
                                placeholder={'Tag name'}
                            />
                        </div>
                    </div>
                </div>
                <div className="modal__footer">
                    <button className="btn btn-outline" onClick={_close}>
                        Cancel
                    </button>
                    {tag.entry_count === 0 && (
                        <button
                            className="btn btn-gradient"
                            onClick={() => onDeleteTag(tag)}>
                            Delete
                        </button>
                    )}
                    <button className="btn btn-publish" onClick={_done}>
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

export default connect()(EditTagDialog);
