import * as actionTypes from 'redux/actions/actionTypes';
import { ERROR_SUFFIX, SUCCESS_SUFFIX } from 'common/constant';
import { getFacebookPhotos } from 'common/utils/facebook_utils';
import arrayUtils from 'common/utils/arrayUtils';
const initialState = {
    isPending: false,
    facebook_media: undefined,
    facebook_media_meta: undefined
};

const FacebookReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FACEBOOK_CONNECT: {
            return Object.assign({}, state, {
                isPending: true
            });
        }

        case actionTypes.FACEBOOK_FETCH_MEDIA: {
            if (action.payload?.data?.clear) {
                return Object.assign({}, state, {
                    isPending: true,
                    facebook_media: []
                });
            } else {
                return Object.assign({}, state, {
                    isPending: true
                });
            }
        }

        case actionTypes.FACEBOOK_CONNECT + SUCCESS_SUFFIX:
        case actionTypes.FACEBOOK_CONNECT + ERROR_SUFFIX: {
            return Object.assign({}, state, {
                isPending: false
            });
        }

        case actionTypes.FACEBOOK_FETCH_MEDIA + SUCCESS_SUFFIX: {
            if (
                action.payload.data &&
                action.payload.data.error === undefined
            ) {
                let facebook_media = state.facebook_media || [];
                const payloadMedias = getFacebookPhotos(
                    action.payload.data.data || action.payload.data.media.data
                );

                facebook_media = [].concat(facebook_media);
                return Object.assign({}, state, {
                    isPending: false,
                    facebook_media: arrayUtils.mergeArrayByField(
                        facebook_media,
                        payloadMedias,
                        'id',
                        false,
                        true
                    ),
                    paging:
                        action.payload.data?.paging ||
                        action.payload.data?.media?.paging
                });
            }
            return state;
        }

        case actionTypes.FACEBOOK_FETCH_MEDIA + ERROR_SUFFIX: {
            return Object.assign({}, state, {
                isPending: false
            });
        }

        case actionTypes.ACTION_USER_LOGOUT: {
            return Object.assign({}, initialState);
        }

        default: {
            return Object.assign({}, state);
        }
    }
};

export default FacebookReducer;
