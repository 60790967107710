import { createSelector } from 'reselect';

export const getSettingsState = (state) => state.settings;

export const getSettingsPageActiveTabIndex = createSelector(
    getSettingsState,
    (settings_page) => settings_page.active_tab
);
export const getSettingsPageActiveTab = createSelector(
    [getSettingsState, getSettingsPageActiveTabIndex],
    (settings_page, active_tab) => {
        return (
            settings_page.Tabs[active_tab] &&
            settings_page.Tabs[active_tab].component
        );
    }
);

export const isMobileSettingBarOpen = createSelector(
    getSettingsState,
    (settings_page) => {
        return settings_page.openSettingSideBarMobile;
    }
);

export const getSelectedPlanDuretion = createSelector(
    getSettingsState,
    (settings_page) => {
        return settings_page.selectedPlanDuration;
    }
);
