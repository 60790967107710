import {
    store as developmentStore,
    loadStateFromStorage as developmentLoadStateStorage
} from 'redux/store/configureStore.development';
import {
    store as productStore,
    loadStateFromStorage as productLoadStateStorage
} from 'redux/store/configureStore.product';

export const store =
    process.env.NODE_ENV === 'development' ? developmentStore : productStore;

export const loadStateFromStorage =
    process.env.NODE_ENV === 'development'
        ? developmentLoadStateStorage
        : productLoadStateStorage;
