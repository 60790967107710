import React from 'react';

export default (
    Component,
    entry,
    setEntryLocation,
    canDelete,
    showOnly,
    current
) => (props) => (
    <Component
        entry={entry}
        setEntryLocation={setEntryLocation}
        canDelete={canDelete}
        showOnly={showOnly}
        current={current}
        {...props}
    />
);
