import settings from 'common/configs/setting';

import _ from 'underscore';
import { UserSelector } from 'redux/selectors/UserSelector';
import { FiltersSelector } from 'redux/selectors/FiltersSelector';
import * as JournalActions from 'redux/actions/journal';

import buildEntryOptionsFromFilters from 'common/utils/buildEntryOptionsFromFilters';
import buildAuthorization from 'common/utils/buildAuthorization';
import getResourceType from 'common/utils/getResourceType';
import {
    SET_ENTRY_POSITION,
    CHANGE_SORT_ENTRY_COMMENT,
    CREATE_ENTRY,
    FETCH_DRAFT_ENTRY,
    FETCH_ENTRY,
    FETCH_MEDIA_ENTRY,
    FETCH_ENTRY_LIST,
    FETCH_ENTRY_WITH_OPTIONS,
    FETCH_ENTRY_BY_URL,
    PATCH_ENTRY,
    REMOVE_ENTRY,
    SET_ENTRY_LOADINGPROGRESS,
    UPDATE_ENTRY,
    FETCH_SHARED_JOURNAL_ENTRY,
    RESET_ENTRIES,
    COPY_ENTRY
} from 'redux/actions/actionTypes';
import {
    ENTRY_QA,
    ENTRY_URL,
    MAPBOX_API_URL,
    MAPBOX_PLACES_METHOD,
    TIMELINE_ENTRY_URL,
    JOURNAL_SHARING_INVITATION
} from 'common/constant';
import axios from 'axios';
import { updateCalendar } from './calendar';
import { dateISO } from 'common/utils/dateUtils';

export const fetch = (options = {}, pageUrl, forceResourceType) => (
    dispatch,
    getState
) => {
    const user = UserSelector(getState());

    const filters = FiltersSelector(getState());
    let url;
    if (!forceResourceType) {
        url = pageUrl || `${settings.api}/${getResourceType(filters)}/`;
    } else {
        url = pageUrl || ENTRY_URL;
    }
    const auth = buildAuthorization(user);

    if (
        (options.id === undefined && pageUrl === undefined) ||
        filters.filtered
    ) {
        options = buildEntryOptionsFromFilters(filters);
    }
    return dispatch({
        type: FETCH_ENTRY_LIST,
        payload: {
            request: {
                url,
                method: 'GET',
                params: options,
                headers: {
                    Authorization: auth
                }
            }
        }
    }).then((response) => {
        if (response.payload && response.payload.status === 200) {
            // Get the calendar
            const entries = response.payload.data.objects;
            // Get all the journals
            const journals = _.uniq(_.pluck(entries, 'journal'));
            journals.map((journal) => {
                if (typeof journal === 'string') {
                    dispatch(JournalActions.fetchJournal(journal));
                }
                return journal;
            });
        }
        return response;
    });
};

export const fetchWithDate = (options = {}, pageUrl, forceResourceType) => (
    dispatch,
    getState
) => {
    const user = UserSelector(getState());

    const filters = FiltersSelector(getState());
    let url;
    if (!forceResourceType) {
        url = pageUrl || `${settings.api}/${getResourceType(filters)}/`;
    } else {
        url = pageUrl || ENTRY_URL;
    }
    const auth = buildAuthorization(user);

    if (
        (options.id === undefined && pageUrl === undefined) ||
        filters.filtered
    ) {
        options = buildEntryOptionsFromFilters(filters);
    }
    return dispatch({
        type: FETCH_ENTRY_LIST,
        payload: {
            request: {
                url,
                method: 'GET',
                params: options,
                headers: {
                    Authorization: auth
                }
            }
        }
    }).then((response) => {
        if (response.payload && response.payload.status === 200) {
            // Get the calendar
            const entries = response.payload.data.objects;
            // Get all the journals
            const journals = _.uniq(_.pluck(entries, 'journal'));
            journals.map((journal) => {
                if (typeof journal === 'string') {
                    dispatch(JournalActions.fetchJournal(journal));
                }
                return journal;
            });
        }
        return response;
    });
};

export const fetchSharedJournalEntries = (invitationKey) => (
    dispatch,
    getState
) => {
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);
    const url = `${JOURNAL_SHARING_INVITATION}${invitationKey}/`;

    return dispatch({
        type: FETCH_SHARED_JOURNAL_ENTRY,
        payload: {
            request: {
                url,
                method: 'GET',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const fetchIndividualEntry = (id, options = {}) => (
    dispatch,
    getState
) => {
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);
    const url = `${TIMELINE_ENTRY_URL}${id}/`;

    return dispatch({
        type: FETCH_ENTRY,
        payload: {
            request: {
                url,
                method: 'GET',
                params: options,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const fetchMediaEntry = (id, pageURL) => (dispatch, getState) => {
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);
    const url = pageURL || `${ENTRY_URL}?media_files=${id}`;

    return dispatch({
        type: FETCH_MEDIA_ENTRY,
        payload: {
            request: {
                url,
                method: 'GET',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const fetchDraftEntries = () => (dispatch, getState) => {
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);
    const url = `${ENTRY_URL}`;
    const options = {
        status: 'draft'
    };

    return dispatch({
        type: FETCH_DRAFT_ENTRY,
        payload: {
            request: {
                url,
                method: 'GET',
                params: options,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const create = (data) => (dispatch, getState) => {
    const url = ENTRY_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: CREATE_ENTRY,
        payload: {
            request: {
                url,
                method: 'POST',
                data,
                headers: {
                    Authorization: auth
                }
            }
        }
    }).then((res) => {
        dispatch(
            updateCalendar({
                date: dateISO(res.payload.data.entry_date),
                increment: true
            })
        );
        return res;
    });
};

export const update = (data, id, tags) => (dispatch, getState) => {
    const url = `${ENTRY_URL}${id}/`;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);
    if (data.entry_date) {
        dispatch(
            updateCalendar({
                date: dateISO(data.last_date),
                increment: false
            })
        );
    }
    delete data.last_date;
    return dispatch({
        type: UPDATE_ENTRY,
        payload: {
            request: {
                url,
                method: 'PATCH',
                data: tags ? { tags: data } : data,
                headers: {
                    Authorization: auth
                }
            }
        }
    }).then((res) => {
        if (data.entry_date) {
            dispatch(
                updateCalendar({
                    date: dateISO(res.payload.data.entry_date),
                    increment: true
                })
            );
        }
        return res;
    });
};

export const patch = (data, id) => (dispatch, getState) => {
    const url = `${TIMELINE_ENTRY_URL}${id}/`;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: PATCH_ENTRY,
        payload: {
            request: {
                url,
                method: 'PATCH',
                data,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const remove = (data) => (dispatch, getState) => {
    const url = `${ENTRY_URL}${data.id}/`;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: REMOVE_ENTRY,
        payload: {
            request: {
                url,
                method: 'DELETE',
                options: {
                    id: data.id
                },
                headers: {
                    Authorization: auth
                }
            }
        }
    }).then(() => {
        dispatch(
            updateCalendar({
                date: dateISO(data.entry_date),
                increment: false
            })
        );
    });
};

export const fetchAnswers = (options = {}) => (dispatch, getState) => {
    const url = ENTRY_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    const dispatch_options = _.defaults(options, {
        entry_type: ENTRY_QA,
        question__isnull: false
    });

    dispatch({
        type: FETCH_ENTRY_LIST,
        payload: {
            request: {
                url,
                method: 'GET',
                params: dispatch_options,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const setLoadingProgress = () => (dispatch, getState) => {
    return dispatch({
        type: SET_ENTRY_LOADINGPROGRESS
    });
};

export const entryCommentSort = (entryId, oldest = true) => (
    dispatch,
    getState
) => {
    return dispatch({
        type: CHANGE_SORT_ENTRY_COMMENT,
        payload: {
            sort: oldest,
            entryId
        }
    });
};

export const fetchEntriesWithOptions = (options) => (dispatch, getState) => {
    // const url = ENTRY_URL;
    const url = TIMELINE_ENTRY_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: FETCH_ENTRY_WITH_OPTIONS,
        payload: {
            request: {
                url,
                method: 'GET',
                params: options,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const fetchByURL = (url) => (dispatch, getState) => {
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: FETCH_ENTRY_BY_URL,
        payload: {
            request: {
                url,
                method: 'GET',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const setEntryPosition = (coords) => {
    return {
        type: SET_ENTRY_POSITION,
        coords
    };
};

export const getEntryPositionFeatures = (coords) => async (dispatch) => {
    try {
        const lat = coords.lat;
        const lng = coords.lng;
        const url = `${MAPBOX_API_URL}${MAPBOX_PLACES_METHOD}${lng}%2C${lat}.json?access_token=${settings.MAPBOX_ACCESS_TOKEN}&cachebuster=1605784824857&autocomplete=true`;
        return axios.get(url);
    } catch (err) {
        //
    }
};

export const geoGoderApi = (value) => async (dispatch) => {
    try {
        const response = await axios.get(
            `${MAPBOX_API_URL}${MAPBOX_PLACES_METHOD}${value}.json?access_token=${settings.MAPBOX_ACCESS_TOKEN}&limit=5`
        );
        return response.data.features;
    } catch (err) {
        //
    }
};

export const reset = () => (dispatch, getState) => {
    dispatch({
        type: RESET_ENTRIES,
        payload: {
            data: true
        }
    });
};

export const copy = (param, id) => (dispatch, getState) => {
    const url = `${TIMELINE_ENTRY_URL}${id}/copy/`;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: COPY_ENTRY,
        payload: {
            request: {
                url,
                method: 'POST',
                data: param,
                headers: {
                    Authorization: auth
                }
            }
        }
    }).then((res) => {
        dispatch(
            updateCalendar({
                date: res.payload.data.entry_date,
                increment: true
            })
        );
        return res;
    });
};
