import React, { useEffect, useCallback, useRef, useState } from 'react';
import _ from 'underscore';

// router
import { useHistory } from 'react-router-dom';

// Actions
import { connect, useSelector } from 'react-redux';
import * as SearchActions from 'redux/actions/search';
import * as FilterActions from 'redux/actions/filter';
import * as JournalActions from 'redux/actions/journal';
import * as PublicUserActions from 'redux/actions/publicuser';
import * as TagActions from 'redux/actions/tag';
import * as TimelineActions from 'redux/actions/timelineapproval';
import * as AllAboutMeActions from 'redux/actions/allaboutme';
import { updateUI } from 'redux/actions/ui';

// Selectors
import { createStructuredSelector } from 'reselect';
import {
    getUser,
    getSearch,
    getJournalList,
    getDefaultJournal,
    getCalendar,
    getTagList,
    getCommentList,
    getFilters,
    getMyPublicUser,
    getAAM
} from 'redux/selectors';

// Components
import withEntry from 'common/utils/components/withEntry';
import EntryList from 'components/Entry/EntryList2';

// Dialogs
import DeleteEntryDialog from 'pages/Dialogs/DeleteEntryDialog';
import InvitationDialog from 'pages/Dialogs/InvitationDialog';
import SearchInput from 'components/Subheader/SearchInput';
import EmptyListIndicator from 'components/EmptyListIndicator';
import jrnlPreloader from 'assets/images/preloader.gif';

const Search = ({ dispatch, defaultJournal, search, filters, ...props }) => {
    const history = useHistory();
    const entryList = useRef();
    const [isSearchEntered, setIsSearchEntered] = useState(false);
    const { results } = useSelector(getSearch);
    useEffect(() => {
        dispatch(JournalActions.fetch());
        dispatch(PublicUserActions.fetch());
        dispatch(TagActions.fetch());
        //dispatch(InvitationActions.fetch());
        dispatch(TimelineActions.fetch());
        dispatch(AllAboutMeActions.fetchQuestionList());

        return () => {};
    }, [dispatch]);

    const onEntryDelete = useCallback(
        (entry) => {
            dispatch(
                updateUI({
                    dialogs: [withEntry(DeleteEntryDialog, entry)]
                })
            );
        },
        [dispatch]
    );

    const onShare = useCallback(
        (entry) => {
            dispatch(
                updateUI({
                    dialogs: [withEntry(InvitationDialog, entry)]
                })
            );
        },
        [dispatch]
    );

    const onJournalTitleClick = useCallback(
        (journal) => {
            if (journal) {
                if (typeof journal === 'string') {
                    dispatch(
                        FilterActions.update({ filterByJournal: [journal] })
                    );
                } else {
                    const filterByJournal = _.findWhere(
                        props.journal.journals,
                        { id: journal }
                    );
                    if (filterByJournal) {
                        dispatch(
                            FilterActions.update({
                                filterByJournal: [filterByJournal]
                            })
                        );
                    }
                }
            }
        },
        [dispatch, props.journal]
    );

    const onComment = useCallback(
        (entry) => {
            if (entry?.id) {
                history.push('/entry/view/#{entry.id}?comments=1');
            }
        },
        [history]
    );

    const updateQuery = useCallback(
        (query) => {
            dispatch(SearchActions.updateQuery(query));
        },
        [dispatch]
    );

    const searchEntries = useCallback(() => {
        if (search?.query && !_.isEmpty(search?.query)) {
            setIsSearchEntered(true);
            dispatch(SearchActions.searchEntries({ q: search.query }));
        }
    }, [search, dispatch]);

    const { user, public_user, aam } = props;

    return (
        <>
            <SearchInput
                onEnterKeyDown={searchEntries}
                onChange={updateQuery}
                searchId={null}
                query={search.query}
            />
            <div className="timeline-view-container">
                <section className="timeline">
                    <div className="page-container">
                        {isSearchEntered && search.isPending && (
                            <section className="loading">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-auto loading-img">
                                            <img
                                                src={jrnlPreloader}
                                                alt="loading"
                                                className="img-fluid"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )}
                        {isSearchEntered &&
                            !search.results &&
                            !search.isPending && (
                                <EmptyListIndicator filters={filters} />
                            )}
                        {isSearchEntered &&
                            search.results &&
                            !search.isPending && (
                                <EntryList
                                    ref={entryList}
                                    entries={results}
                                    entryUsers={search.entryUsers}
                                    journals={props.journal.journals}
                                    onEntryDelete={onEntryDelete}
                                    onJournalTitleClick={onJournalTitleClick}
                                    onComment={onComment}
                                    onEntryShare={onShare}
                                    user={user}
                                    publicUser={public_user}
                                    questions={aam.questions}
                                />
                            )}
                    </div>
                </section>
            </div>
        </>
    );
};

const state = createStructuredSelector({
    user: getUser,
    search: getSearch,
    journal: getJournalList,
    defaultJournal: getDefaultJournal,
    calendar: getCalendar,
    tag: getTagList,
    comment: getCommentList,
    filters: getFilters,
    public_user: getMyPublicUser,
    aam: getAAM
});

export default connect(state)(Search);
