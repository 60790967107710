import React, { useEffect, useCallback, useState, useRef } from 'react';
// Actions
import { connect } from 'react-redux';
import * as AllAboutMeActions from 'redux/actions/allaboutme';

// Selectors
import { createStructuredSelector } from 'reselect';
import { getCategoryList, getUI, getUser } from 'redux/selectors';

// Components
import { useHistory } from 'react-router-dom';

//images
import AAMImage from 'assets/images/banners/banner-aam.png';
import AAMCategoryImage from 'common/utils/images/AAMCategoryImage';
import BannerCard from 'components/BannerCard';
import { URLS } from 'common/urls';

// Onboarding Flow
import 'intro.js/introjs.css';
import { Steps } from 'intro.js-react';
import { updateUI } from 'redux/actions/ui';

import { isOldUser } from 'common/utils/newUser';

const AllAboutMeContainer = ({ ui, dispatch, categories, user }) => {
    // history
    const history = useHistory();
    const [displayOnboarding, setDisplayOnboarding] = useState(false);
    const [backStatus, setBackStatus] = useState(0);
    const stpesRef = useRef(null);
    // const timerRef = useRef(null);

    const steps = [
        {
            title: '',
            intro: ''
        },
        {
            title: 'Categories',
            element: '.card-grid__item:first-child',
            intro:
                'Having a difficult time getting started? All About Me will help with the ‘blank page problem.’ Simply select a category to view a list of questions you can answer.',
            position: 'right'
        },
        {
            title: 'Random Question',
            element: '.btn-random-question',
            intro:
                'Click this button to trigger a new random question from the All About Me section.',
            position: 'right'
        },
        {
            title: 'Back to Main Menu',
            element: '.dropdown--more',
            intro: 'Now click on ‘Contacts’ in the dropdown menu. ',
            position: 'left'
        },
        {
            title: '',
            intro: ''
        }
    ];
    const [initialStep, setInitialStep] = useState(0);
    const [stepsEnabled, setStepsEnabled] = useState(false);
    const [continueFlag, setContinueFlag] = useState(false);

    const onExit = useCallback(() => {
        setStepsEnabled(false);
        if (backStatus === 2) {
            localStorage.setItem('skipOnboarding', '1');
            dispatch(
                updateUI({
                    onBoarding: false,
                    onBoardingEnd: true
                })
            );
        }
    }, [dispatch, backStatus]);

    const options = {
        hidePrev: false,
        hideNext: false,
        prevLabel: 'Back',
        nextLabel: 'Next',
        showBullets: false,
        doneLabel: 'Next',
        exitOnOverlayClick: false
    };

    const removeElementsByClass = (className) => {
        const elements = document.getElementsByClassName(className);
        while (elements.length > 0) {
            elements[0].parentNode.removeChild(elements[0]);
        }
    };
    const [nextStep, setNextStep] = useState(1);
    const onBeforeChange = useCallback(
        (nextStepIndex) => {
            switch (nextStepIndex) {
                case 0:
                    setBackStatus(1);
                    setStepsEnabled(false);
                    dispatch(
                        updateUI({
                            onBoardingMoreMenu: true,
                            onBoardingAddMenu: false,
                            filterDrawerOpen: true
                        })
                    );
                    dispatch(
                        updateUI({
                            bigStep: 2
                        })
                    );
                    history.push(URLS.TIMELINE);
                    removeElementsByClass('introjs-helperLayer');
                    return false;
                case 1:
                    setBackStatus(2);
                    setNextStep(nextStepIndex);
                    break;
                case 2:
                    setBackStatus(2);
                    dispatch(
                        updateUI({
                            onBoardingMoreMenu: false
                        })
                    );
                    setNextStep(nextStepIndex);
                    break;
                case 3:
                    setBackStatus(2);
                    dispatch(
                        updateUI({
                            onBoardingMoreMenu: true
                        })
                    );
                    setNextStep(nextStepIndex);
                    // return false;
                    break;
                case 4:
                    setBackStatus(3);
                    dispatch(
                        updateUI({
                            onBoardingMoreMenu: false
                        })
                    );
                    history.push(URLS.CONTACTS.ROOT);
                    return false;
                default:
                    setBackStatus(2);
                    break;
            }
        },
        [dispatch, history]
    );

    useEffect(() => {
        setContinueFlag(true);
    }, [nextStep]);

    useEffect(() => {
        dispatch(AllAboutMeActions.fetchCategoryList());
    }, [dispatch]);

    const moveToCategory = useCallback(
        (categoryId) => {
            history.push(`/all-about-me/category/${categoryId}`);
        },
        [history]
    );

    useEffect(() => {
        if (isOldUser(user)) {
            return;
        }
        if (localStorage.getItem('skipOnboarding') === '1') {
            return;
        }

        setTimeout(() => {
            setDisplayOnboarding(Boolean(!user.has_seen_welcome_web));
        }, 1000);
    }, [user]);

    const renderCategoryList = useCallback(() => {
        if (categories) {
            return categories.map((category) => (
                <div
                    key={category.id}
                    onClick={() => moveToCategory(category.id)}
                    className="card-grid__item">
                    <div className="card-grid__img">
                        <img
                            src={AAMCategoryImage(
                                category?.name
                                    ?.toLowerCase()
                                    .split(' ')
                                    .join('-')
                            )}
                            alt="aam-icon"
                        />
                    </div>
                    <div className="card-grid__text">
                        <div className="card-grid__title">{category.name}</div>
                        <div className="card-grid__subtitle">
                            {`${category.answered_count}`} /{' '}
                            {`${category.question_count}`} Answered
                        </div>
                    </div>
                </div>
            ));
        }
    }, [categories, moveToCategory]);

    useEffect(() => {
        if (displayOnboarding) {
            if (ui.bigStep === 3) {
                setStepsEnabled(true);
                setInitialStep(3);
                setContinueFlag(true);
            } else {
                if (backStatus !== 1) {
                    setStepsEnabled(true);
                    setInitialStep(1);
                    setContinueFlag(true);
                }
            }
        }
    }, [ui.bigStep, displayOnboarding, backStatus]);

    return (
        <div>
            {categories &&
                categories.length !== 0 &&
                displayOnboarding &&
                continueFlag && (
                    <Steps
                        ref={stpesRef}
                        enabled={stepsEnabled}
                        initialStep={initialStep}
                        steps={steps}
                        onExit={onExit}
                        options={options}
                        onBeforeChange={onBeforeChange}
                    />
                )}
            <section className="page-container">
                <BannerCard
                    bannerClassName={'all-about-me-bg banner-card--dark'}
                    image={AAMImage}
                    title={'All About Me'}
                    text={
                        'Take one more step in your journey: reply to questions about the present and the past to understand you better.'
                    }
                    id={'all-about-me'}
                />
                <div className="btn-wrapper">
                    <button
                        className="btn btn-gradient btn-answer-random"
                        onClick={() => history.push(URLS.AAM.RANDOM_QUESTION)}>
                        Answer random question
                    </button>
                </div>
                <div className="card-grid">{renderCategoryList()}</div>
            </section>
        </div>
    );
};

const state = createStructuredSelector({
    ui: getUI,
    categories: getCategoryList,
    user: getUser
});

export default connect(state)(AllAboutMeContainer);
