import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getUI } from 'redux/selectors';

// icons
import iconClose from 'assets/images/icons_svg/icon_close.svg';
import { showBanner, hideBanner } from 'common/utils/dialog-utils';

const BannerCard = ({ bannerClassName, image, title, text, id }) => {
    if (showBanner(id)) {
        return (
            <section className="banner" id={id}>
                <div className={`banner-card ${bannerClassName}`}>
                    <div className="banner-card__img">
                        <img
                            src={image}
                            alt="bookbuilder"
                            className="img-fluid"
                        />
                    </div>
                    <div className="banner-card__content">
                        <div className="banner-card__title">{title}</div>
                        <div className="banner-card__subtitle">{text}</div>
                    </div>
                    <button
                        className="banner-card__close btn-close"
                        onClick={() => hideBanner(id)}>
                        <img src={iconClose} alt="" />
                    </button>
                </div>
            </section>
        );
    } else {
        return <> </>;
    }
};

const state = createStructuredSelector({
    ui: getUI
});

export default connect(state)(BannerCard);
