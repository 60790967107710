import React, { useCallback, useState } from 'react';

// Actions
import { connect } from 'react-redux';
import * as JournalActions from 'redux/actions/journal';
import * as JournalInviteActions from 'redux/actions/journalinvitation';

// Selectors
import { createStructuredSelector } from 'reselect';
import { getDefaultJournal, getUI, getUser } from 'redux/selectors';

import { updateUI } from 'redux/actions/ui';
import withSharedUsers from 'common/utils/components/withSharedUsers';
import ShareDialog from 'pages/Dialogs/InvitationDialog/ShareDialog';
import { InvitationListButton } from 'components';
import {
    JOURNAL_DESC_TEXT_WORD_LIMIT,
    JOURNAL_PATTERN,
    JOURNAL_STATE,
    JOURNAL_TITLE_LIMIT_OF_CHARACTERS
} from 'common/constant';

// Icons
import iconLock from 'assets/images/icons_svg/icon_lock.svg';
// import iconCaretDown from 'assets/images/icons_svg/icon_caret-down.svg';
import iconCheckSm from 'assets/images/icons_svg/icon_check-sm.svg';
// import withFreeAccountCheck from 'common/utils/components/withFreeAccountCheck';

const NewJournalDialog = ({ close, dispatch, user, defaultJournal, ui }) => {
    const [journalTitle, setJournalTitle] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [isDefault, setIsDefault] = useState(false);
    const [journalSharedUsers, setJournalSharedUsers] = useState([]);
    const [journalDesc, setJournalDesc] = useState('');
    const [customMessage, setCustomMessage] = useState('');

    const _close = useCallback(() => {
        if (close) {
            close();
        }
    }, [close]);

    const onShareJournalUserUpdate = useCallback(
        (sharedUsers, added_users, removed_users, customMessage) => {
            setJournalSharedUsers(sharedUsers);
            setCustomMessage(customMessage);
        },
        []
    );

    const _done = useCallback(() => {
        if (!journalTitle || journalTitle.length === 0) {
            return;
        }

        const journal = {
            title: journalTitle,
            title_page_content: journalDesc,
            journal_pattern: JOURNAL_PATTERN.STANDARD,
            state:
                journalSharedUsers.length > 0
                    ? JOURNAL_STATE.PUBLIC_SHOWN
                    : JOURNAL_STATE.PRIVATE_SHOWN
        };

        setIsSaving(true);
        setIsSaved(false);

        if (isDefault === true) {
            journal.is_default = true;
        }

        if (defaultJournal && isDefault === true) {
            // reset current default defaultJournal
            defaultJournal.is_default = false;
            dispatch(JournalActions.update(defaultJournal, defaultJournal.id));
        }
        dispatch(JournalActions.create(journal)).then((response) => {
            setIsSaving(false);
            setIsSaved(true);

            if (response.payload?.status === 201) {
                dispatch(
                    JournalInviteActions.inviteUsers(
                        journalSharedUsers,
                        response.payload?.data?.id,
                        customMessage
                    )
                );

                _close();
            }
        });
    }, [
        _close,
        dispatch,
        journalTitle,
        defaultJournal,
        isDefault,
        journalSharedUsers,
        journalDesc,
        customMessage
    ]);

    const onChangeDefaultJournal = useCallback(() => {
        setIsDefault(!isDefault);
    }, [isDefault]);
    const openShareDialog = useCallback(() => {
        dispatch(
            updateUI({
                dialogs: [
                    ...ui.dialogs,
                    withSharedUsers(
                        ShareDialog,
                        journalSharedUsers || [],
                        onShareJournalUserUpdate
                    )
                ]
            })
        );
    }, [dispatch, ui.dialogs, onShareJournalUserUpdate, journalSharedUsers]);

    const renderDescriptionCharacters = useCallback(() => {
        return `${journalDesc.length}/${JOURNAL_DESC_TEXT_WORD_LIMIT}`;
    }, [journalDesc.length]);
    const onChangeJournalDescription = (e) => {
        if (e.target.value.length > JOURNAL_DESC_TEXT_WORD_LIMIT) {
            return;
        }
        setJournalDesc(e.target.value);
    };
    const renderTitleCharacters = useCallback(() => {
        return `${journalTitle.length}/${JOURNAL_TITLE_LIMIT_OF_CHARACTERS}`;
    }, [journalTitle.length]);
    const onChangeJournalTitle = (e) => {
        if (e.target.value.length > JOURNAL_TITLE_LIMIT_OF_CHARACTERS) {
            return;
        }
        setJournalTitle(e.target.value);
    };
    return (
        <div id="add-journal-modal" className="modal-frame">
            <div className="modal-box modal-box--600">
                <div className="modal__header">Add Journal</div>
                <div className="modal__body">
                    <div className="form-group">
                        <div className="form-group__item">
                            <label htmlFor="edit-journal">Journal Name</label>
                            <div className="edit-limit-word">
                                {renderTitleCharacters()}
                            </div>
                            <input
                                type="text"
                                id="edit-journal"
                                onChange={onChangeJournalTitle}
                                value={journalTitle}
                                name="name"
                                placeholder={'New Journal Name'}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-group__item">
                            <label htmlFor="edit-journal-desc">
                                Journal Description
                            </label>

                            <div className="edit-limit-word">
                                {renderDescriptionCharacters()}
                            </div>
                            <textarea
                                onChange={onChangeJournalDescription}
                                value={journalDesc}
                                className="edit-journal-input"
                                name="Description"
                                placeholder="Decribe your new journal"
                                rows="3"
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-group__row">
                            {/* <div className="form-group__item form-group__item--6">
                                <label htmlFor="selectJournalType">
                                    Journal Type
                                </label>
                                <div className="dropdown show">
                                    <div
                                        className="dropdown-toggle dropdown-toggle--select"
                                        role="button"
                                        id="journalType"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false">
                                        Photo Journal
                                        <img
                                            src={iconCaretDown}
                                            alt=""
                                            className="right"
                                        />
                                    </div>
                                    <div
                                        className="dropdown-menu dropdown-menu--detailed"
                                        aria-labelledby="journalType">
                                        <ul>
                                            <li>
                                                <div className="dropdown-menu__title">
                                                    Standard Journal
                                                </div>
                                                <div className="dropdown-menu__description">
                                                    A journal to write down your
                                                    day, or anything that
                                                    crosses your mind
                                                </div>
                                            </li>
                                            <li className="active-sharing-item">
                                                <div className="dropdown-menu__title">
                                                    Photo Journal
                                                </div>
                                                <div className="dropdown-menu__description">
                                                    A perfect journal to upload
                                                    photos with captions
                                                </div>
                                            </li>
                                            <li>
                                                <div className="dropdown-menu__title">
                                                    Journal Without Dates
                                                </div>
                                                <div className="dropdown-menu__description">
                                                    Don&#39;t know what to write
                                                    about? We will ask you
                                                    questions about yourself
                                                </div>
                                            </li>
                                            <li>
                                                <div className="dropdown-menu__title">
                                                    Questions Journal
                                                </div>
                                                <div className="dropdown-menu__description">
                                                    Don&#39;t know what to write
                                                    about? We will ask you
                                                    questions about yourself
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
                            {user.sharing && (
                                <div className="form-group__item form-group__item--6">
                                    <label htmlFor="edit-journal">
                                        Privacy
                                    </label>
                                    <button
                                        className="btn btn-private-shared"
                                        onClick={openShareDialog}>
                                        {journalSharedUsers.length > 0 ? (
                                            <InvitationListButton
                                                invitations={
                                                    journalSharedUsers
                                                }></InvitationListButton>
                                        ) : (
                                            <>
                                                <img
                                                    src={iconLock}
                                                    alt=""
                                                    className="left icon-static btn-icon--6"
                                                />
                                                Private
                                            </>
                                        )}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="checkbox-item mb-3">
                        <input
                            type="checkbox"
                            id="defaultJournal"
                            name="defaultJournal"
                            className="checkbox"
                            checked={isDefault}
                            onChange={onChangeDefaultJournal}
                        />
                        <label htmlFor="defaultJournal">
                            Make it my default journal
                        </label>
                    </div>
                </div>
                <div className="modal__footer">
                    <button className="btn btn-outline" onClick={_close}>
                        Cancel
                    </button>
                    <button className="btn btn-gradient" onClick={_done}>
                        Add
                    </button>
                    {isSaving && (
                        <div className="saving-text pr-2">
                            <div className="saving-gif pr-3"></div>
                            Saving...
                        </div>
                    )}
                    {!isSaving && isSaved && (
                        <div className="saving-text pr-2">
                            <img src={iconCheckSm} alt="" />
                            Saved!
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    defaultJournal: getDefaultJournal,
    ui: getUI,
    user: getUser
});
export default connect(state)(NewJournalDialog);
