import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import * as BackgroundImageActions from 'redux/actions/backgroundimage';
import { getBackgroundImages, getUser } from 'redux/selectors';
import { createStructuredSelector } from 'reselect';
import { USER_SETTINGS, WALPAPERS_ENDPOINTS } from 'common/constant';

import * as UserActions from 'redux/actions/user';
import { closeDialog } from 'common/utils/dialog-utils';

const ChangeBackgroundDialog = ({ dispatch, backgroundImages, user }) => {
    const [selectedImage, setSelectedImage] = useState(
        user.wallpaper_image || ''
    );

    useEffect(() => {
        dispatch(BackgroundImageActions.fetch());
    }, [dispatch]);

    const saveChanges = useCallback(() => {
        dispatch(
            UserActions.update(
                { wallpaper_image: selectedImage },
                USER_SETTINGS
            )
        );
        closeDialog();
    }, [dispatch, selectedImage]);

    return (
        <div id="change-background-modal" className="modal-frame">
            <div className="modal-box modal-box--gallery modal-box--800">
                <div className="modal__header">Change Background</div>
                {!backgroundImages.is_pending ? (
                    <div className="modal__body">
                        <div className="section-title">Artistic</div>
                        <div className="gallery-unsplash">
                            {backgroundImages?.images.map((image) => {
                                if (
                                    WALPAPERS_ENDPOINTS.ACRILIC.includes(
                                        image.category
                                    )
                                ) {
                                    return (
                                        <img
                                            src={image.image}
                                            alt="artistic-photos"
                                            key={image.id}
                                            className={
                                                selectedImage ===
                                                image.resource_uri
                                                    ? 'selected-photo'
                                                    : ''
                                            }
                                            onClick={() =>
                                                setSelectedImage(
                                                    image.resource_uri
                                                )
                                            }
                                        />
                                    );
                                }
                                return null;
                            })}
                        </div>
                        <div className="section-title">Blur</div>
                        <div className="gallery-unsplash">
                            {backgroundImages?.images.map((image) => {
                                if (
                                    WALPAPERS_ENDPOINTS.BLUR.includes(
                                        image.category
                                    )
                                ) {
                                    return (
                                        <img
                                            src={image.image}
                                            key={image.id}
                                            alt="artistic-photos"
                                            className={
                                                selectedImage ===
                                                image.resource_uri
                                                    ? 'selected-photo'
                                                    : ''
                                            }
                                            onClick={() =>
                                                setSelectedImage(
                                                    image.resource_uri
                                                )
                                            }
                                        />
                                    );
                                }
                                return null;
                            })}
                        </div>
                        <div className="section-title">Photos</div>
                        <div className="gallery-unsplash">
                            {backgroundImages?.images.map((image) => {
                                if (
                                    WALPAPERS_ENDPOINTS.PHOTOS.includes(
                                        image.category
                                    )
                                ) {
                                    return (
                                        <img
                                            src={image.image}
                                            key={image.id}
                                            alt="artistic-photos"
                                            className={
                                                selectedImage ===
                                                image.resource_uri
                                                    ? 'selected-photo'
                                                    : ''
                                            }
                                            onClick={() =>
                                                setSelectedImage(
                                                    image.resource_uri
                                                )
                                            }
                                        />
                                    );
                                }
                                return null;
                            })}
                        </div>
                    </div>
                ) : (
                    'Loading...'
                )}
                <div className="modal__footer">
                    <button className="btn btn-outline" onClick={closeDialog}>
                        Cancel
                    </button>
                    <button className="btn btn-gradient" onClick={saveChanges}>
                        Save Changes
                    </button>
                </div>
            </div>
        </div>
    );
};
const state = createStructuredSelector({
    backgroundImages: getBackgroundImages,
    user: getUser
});
export default connect(state)(ChangeBackgroundDialog);
