import React from 'react';
import { getUI } from 'redux/selectors';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// actions
import * as EditorActions from 'redux/actions/editor';
import { uploadSocialImage } from 'redux/actions/entryimage';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import { checkResponseSuccess } from 'common/utils/responseUtils';

const SocialImageUploadDialog = ({
    images,
    ui,
    close,
    media_view = false,
    selectedEntry
}) => {
    const [uploadedImageCnt, setUploadedImageCnt] = React.useState(0);
    const dispatch = useDispatch();

    useDeepCompareEffectNoCheck(() => {
        // Upload social images to server
        images.forEach((image, index) => {
            dispatch(
                uploadSocialImage({
                    url: image.src,
                    entry: selectedEntry?.id || ''
                })
            ).then((response) => {
                if (checkResponseSuccess(response.payload.status)) {
                    // set image data
                    image.src = response.payload.data.file;
                    image.uploaded = true;
                } else {
                    image.uploaded = false;
                }

                setUploadedImageCnt((prev) => prev + 1);
            });
        });
    }, [selectedEntry]);

    useDeepCompareEffectNoCheck(() => {
        // if uploading has been completed
        if (uploadedImageCnt === images.length) {
            if (!media_view) {
                dispatch(EditorActions.insertImageBlock(images));
            }
            close();
        }
    }, [uploadedImageCnt]);

    return (
        <div id="importing-img-modal" className="importing-img-modal">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8 col-xl-7 importing-img-content">
                        <i className="icon-jrnl-logo"></i>
                        <h3 className="importing-img-blurb">
                            We are importing your image.
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    ui: getUI
});
export default connect(state)(SocialImageUploadDialog);
