import React from 'react';
import { connect } from 'react-redux';

import { Footer } from 'components';

const Privacy = () => {
    React.useEffect(() => {
        document.title = 'Privacy Policy | JRNL.com';
    });
    return (
        <div className="page-about" id="subpage">
            <div id="content">
                <div className="container">
                    <main>
                        <div className="page-header">
                            <h1>Privacy Policy</h1>
                        </div>
                        <div className="type">
                            <p>
                                JRNL recognizes that privacy is important. This
                                Policy applies to all of the services offered by
                                JRNL Inc., or its affiliated companies
                                (collectively, JRNL&apos;s
                                &quot;services&quot;).
                            </p>
                            <p>
                                If you have any questions about this Policy,
                                please feel free to contact us through our
                                website or write to us at:
                            </p>
                            <p>
                                <strong>Privacy Protection</strong>
                                <br />
                                c/o JRNL Inc.
                                <br />
                                7437 S. Eastern, #264
                                <br />
                                Las Vegas, NV, 89123 USA
                            </p>
                            <p>
                                Information you share with JRNL is used for
                                internal business purposes only. JRNL is not in
                                the business of selling information about you.
                                This notice tells you what information we
                                collect, steps we take to protect and secure it,
                                how we use and share the information, and
                                finally, how you can access that information or
                                contact us with questions or concerns.
                            </p>
                            <h4>Information We Gather</h4>
                            <p>
                                JRNL collects personal information you provide
                                when you register with JRNL and use the services
                                found on www.JRNL.com, or by downloading any
                                JRNL Web or Mobile App (collectively, the
                                &quot;Service&quot;).
                            </p>
                            <p>
                                JRNL automatically receives and records
                                information on our server logs from your
                                browser, including your IP address, cookies, and
                                the pages you request.
                            </p>
                            <h4>How we use IP Address Information</h4>
                            <p>
                                JRNL may occasionally obtain IP addresses from
                                users depending upon how you access the service.
                                IP addresses, browser and session information
                                may be used for various purposes, including:
                            </p>
                            <ul>
                                <li>
                                    Diagnosing and preventing service or
                                    technology problems reported by our users or
                                    engineers that are associated with the IP
                                    address(es) controlled by a specific Web
                                    company or ISP.
                                </li>
                                <li>
                                    Estimating the total number of users
                                    visiting JRNL from specific geographic
                                    regions.
                                </li>
                                <li>
                                    Helping determine which users have access
                                    privileges to certain content or services
                                    that we offer.
                                </li>
                                <li>
                                    Monitoring and preventing fraud and abuse.
                                </li>
                                <li>
                                    Delivering more relevant advertisements to
                                    you.
                                </li>
                            </ul>
                            <h4>How We Use Cookies</h4>
                            <p>
                                When you visit JRNL, we send one or more cookies
                                - a small file containing a string of characters
                                - to your computer that uniquely identifies your
                                browser. We use cookies to improve the quality
                                of our service by storing user preferences and
                                tracking user trends, such as how people use the
                                service. Most browsers are initially set up to
                                accept cookies, but you can reset your browser
                                to refuse all cookies or to indicate when a
                                cookie is being sent. However, some JRNL
                                features and services may not function properly
                                if your cookies are disabled.
                            </p>
                            <p>
                                JRNL uses its own cookies for a number of
                                purposes, including:
                            </p>
                            <ul>
                                <li>
                                    Requiring you to re-enter your JRNL password
                                    after a certain period of time has elapsed
                                    to protect you against unauthorized access
                                    to your journal.
                                </li>
                                <li>
                                    Keeping track of preferences you specify
                                    while you are using JRNL&apos;s services.
                                </li>
                                <li>
                                    Estimating and reporting our total audience
                                    size and traffic.
                                </li>
                                <li>
                                    Conducting research to improve JRNL&apos;s
                                    content and services.
                                </li>
                            </ul>
                            <p>
                                Some third party advertisers and services
                                advertising on both the Web and Mobile versions
                                of JRNL (the Services), may employ the use of
                                cookies. JRNL is not responsible for the use of
                                such cookies, and users should configure the
                                privacy settings of their browser accordingly.
                            </p>
                            <h4>
                                How We Use, Share and Disclose Information We
                                Collect
                            </h4>
                            <p>
                                JRNL does not share personal information we
                                learn about you from your use of the Services
                                with others except as described in this policy.
                                We do not share your information with unrelated
                                third parties for their direct marketing
                                purposes.
                            </p>
                            <p>
                                It is important to note that JRNL, along with
                                its shareholders, staff, divisions,
                                subsidiaries, and any affiliated entities are
                                likewise bound by this policy, and the use of
                                any personal information gathered through use of
                                the Services is accordingly protected by the
                                terms herein.
                            </p>
                            <p>
                                JRNL may, however, display targeted
                                advertisements based on personally
                                non-identifiable information (such as browser
                                type and IP address) and/or personal selection
                                (See the Section entitled &quot;Third Party
                                Advertising&quot; below for more information on
                                how your information is shared with
                                advertisers).
                            </p>
                            <p>
                                JRNL may use your personal information where it
                                is necessary for us to complete a transaction,
                                to operate or improve JRNL and related products
                                and services, to do something that you have
                                asked us to do, or tell you of products and
                                services that we think may be of interest.
                            </p>
                            <p>
                                JRNL may use your email address for service
                                enhancements or to contact you with required
                                email messages about the Service.
                            </p>
                            <p>
                                Where you are contacted with an offer or service
                                based on information you have shared with JRNL,
                                we will do our best to provide you with an
                                opportunity to opt-out of receiving such further
                                communications at the time you are contacted.
                            </p>

                            <h4>
                                Disclosing Personally Identifiable Information
                            </h4>
                            <p>
                                In the course of operating our business it may
                                be necessary or appropriate for us to provide
                                access to your personal information to others
                                such as our service providers, contractors and
                                select vendors so that we can operate the
                                Service and our business. Where practical, we
                                seek to obtain confidentiality agreements that
                                are consistent with this policy and that limit
                                others&apos; use or disclosure of the
                                information you have shared.
                            </p>
                            <p>
                                We may transfer personal information to certain
                                advertising partners that you have explicitly
                                requested to receive information from. It will
                                be clear at the point of collection who is
                                collecting the personal information and whose
                                privacy statement will apply.
                            </p>

                            <p>
                                We may share your personal information to
                                respond to subpoenas, court orders, or legal
                                process, to establish or exercise our legal
                                rights or defend against legal claims, if in our
                                judgment, disclosure is required or appropriate
                                in such circumstances.
                            </p>

                            <p>
                                We may share your personal information if we
                                believe it is necessary in order to investigate,
                                prevent, or take action regarding illegal
                                activities, suspected fraud, situations
                                involving potential threats to the physical
                                safety of any person, violations of JRNL&apos;s
                                Terms of Use, or as otherwise required by law.
                            </p>

                            <p>
                                As we continue to develop our business, we might
                                also sell all or part of our business. In such
                                transactions, personally identifiable
                                information you have shared with us is generally
                                one of the business assets that may be
                                transferred. The transferred personally
                                identifiable information will remain subject to
                                the promises made in this privacy policy or
                                subsequent policies to which you have consented.
                            </p>

                            <p>
                                If there is ever a change to how JRNL uses or
                                intends to use personal information, we will
                                post that change to this policy and, if the new
                                policy is materially less restrictive than this
                                policy, we will seek your consent.
                            </p>

                            <h4>Third Party Advertising</h4>

                            <p>
                                Advertisements appearing on JRNL may be
                                delivered to users by JRNL or one of its
                                advertising partners. Our advertising partners
                                may set cookies. These cookies allow the ad
                                server to recognize your computer each time they
                                send you an online advertisement. In this way,
                                advertising partners (or &quot;ad
                                networks&quot;) may compile information about
                                where you, or others who are using your
                                computer, saw their advertisements and determine
                                which ads are clicked on. This information
                                allows an ad network to deliver targeted
                                advertisements that they believe will be of most
                                interest to you. This privacy policy covers the
                                use of cookies by JRNL and does not cover the
                                use of cookies by any advertisers.
                            </p>

                            <p>
                                JRNL does not share any personally identifiable
                                information with advertisers. JRNL may share
                                general demographic information (such as
                                information about members&apos; aggregate
                                interests and occupations) and non-personally
                                identifiable information (such as browser type
                                and IP addresses) with advertisers and partners.
                                JRNL will never give advertisers access to your
                                private personal account information (such as
                                email address or username). However, by
                                selecting, interacting with or viewing an ad you
                                are consenting to the possibility that the
                                advertiser will make the assumption that you
                                meet the targeting criteria used to display the
                                ad.
                            </p>

                            <h4>
                                Remarketing with Google AdSense &amp; Other
                                External Ad Networks
                            </h4>
                            <p>
                                At times, we may use Google AdSense Advertising
                                or other external Ad Services on our website.
                                Google, as a third party vendor, uses cookies to
                                serve ads on our site. Google&apos;s use of the
                                DART cookie enables it to serve ads to our users
                                based on their visit to our site and other sites
                                on the Internet. Users may opt out of the use of
                                the DART cookie by visiting the Google ad and
                                content network privacy policy.
                            </p>

                            <p>
                                JRNL makes use of Remarketing with Google
                                AdSense. As part of this service we, along with
                                third-party vendors such as Google, use
                                first-party cookies (such as the Google
                                Analytics cookies) and third-party cookies (such
                                as the DoubleClick cookie) or other third-party
                                identifiers together to compile data regarding
                                user interactions with ad impressions, and other
                                ad service functions as they relate to our
                                website.
                            </p>

                            <p>
                                <strong>Opting out:</strong>
                                <br />
                                Users can set preferences for how Google
                                advertises to you using the Google Ad Settings
                                page. Alternatively, you can opt out by visiting
                                the Network Advertising initiative opt out page
                                or permanently using the Google Analytics Opt
                                Out Browser add on.
                            </p>

                            <h4>Confidentiality and Security</h4>

                            <p>
                                No data transmissions over the Internet or via
                                wireless networks can be guaranteed to be 100%
                                secure. Consequently, we cannot ensure or
                                warrant the security of any information you
                                transmit to us and you do so at your own risk.
                                Once we receive your transmission, we make
                                efforts to ensure security on our systems.
                            </p>

                            <p>
                                We use physical, electronic, and procedural
                                safeguards to protect personal information about
                                you.
                            </p>

                            <h4>
                                Your JRNL Accounts and Related Information are
                                Password Protected.
                            </h4>

                            <p>
                                In certain areas, JRNL uses industry-standard
                                SSL-encryption to protect data transmissions.
                                However, please note that this is not a
                                guarantee that such information may not be
                                accessed, disclosed, altered or destroyed by
                                breach of such firewalls and secure server
                                software.
                            </p>

                            <p>
                                If JRNL learns of a security systems breach we
                                will notify you electronically so that you can
                                take appropriate protective steps, however, we
                                will never ask you to submit additional personal
                                information or to verify the status of or
                                information related to your account in any
                                electronic communication email. By using this
                                web site or providing personal information to us
                                you agree that we can communicate with you
                                electronically regarding security, privacy and
                                administrative issues relating to your use of
                                this site. JRNL may post a notice on our web
                                site if a security breach occurs. If this
                                happens, you will need a web browser enabling
                                you to view the JRNL.com web site. JRNL may also
                                send an email to you at the email address you
                                have provided to us in these circumstances,
                                however, we will never ask you to submit
                                additional personal information or to verify the
                                status of or information related to your account
                                in any such email.
                            </p>

                            <h4>
                                Changing your Preferences and Personal
                                Information
                            </h4>

                            <p>
                                We are happy to help you edit your JRNL Account
                                Profile at any time. The account profile
                                information provided by you is required for
                                operation of your JRNL account. Should you
                                choose to discontinue using your JRNL account,
                                please visit helpdesk.jrnl.com to get help
                                deleting your JRNL account; however, please note
                                that some personal information, primarily your
                                contact information, may remain in JRNL&apos;s
                                records to the extent necessary to protect
                                JRNL&apos;s legal interests or document
                                compliance with regulatory requirements.
                            </p>
                        </div>
                    </main>

                    <Footer />
                </div>
            </div>
        </div>
    );
};

const states = (state) => ({
    ...state
});
export default connect(states)(Privacy);
