import React from 'react';
import { Alert } from '@material-ui/lab';
import { URLS } from 'common/urls';
import { Link } from 'react-router-dom';

const emailValidate = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\]) |(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const useSignUpForm = (checkEmail, checkUserName) => async (
    value,
    name,
    setFormData,
    formData
) => {
    const data = { ...formData };
    switch (name) {
        case 'username': {
            data[name].value = value;
            if (value.trim()) {
                const { payload: userNameAvailable } = await checkUserName(
                    value
                );
                if (userNameAvailable?.data?.taken) {
                    data[name].error = true;
                    data[name].isValid = false;
                } else {
                    data[name].error = false;
                    data[name].isValid = true;
                }

                setFormData(data);
            } else {
                data[name].isValid = false;
            }
            break;
        }
        case 'email': {
            data[name].value = value;
            const { payload: emailAvailable } = await checkEmail(value);
            if (emailAvailable?.data?.taken) {
                data[name].error = true;
                data[name].isValid = false;
            } else {
                data[name].error = false;
                data[name].isValid = emailValidate.test(
                    String(value).toLowerCase()
                );
            }
            setFormData(data);
            break;
        }
        case 'password': {
            data[name].value = value;
            data[name].isValid = value.length >= 8;
            setFormData(data);
            break;
        }
        case 'confirm_password': {
            data[name].value = value;
            data[name].isValid =
                String(value) === String(formData.password.value);
            setFormData(data);
            break;
        }
        default:
            return { formData };
    }
};

const ModalForm = ({
    formData,
    renderAlertMessage,
    setFormData,
    buttonStatus,
    register,
    onChange,
    title
}) => {
    return (
        <form className="checkout-plans__form">
            <h3>{title}</h3>
            <div
                className={`form-group checkout-plans__form-group ${
                    formData?.username?.isValid && 'validation-graphic-valid'
                }`}>
                <label className="checkout-plans__form-label" htmlFor="name">
                    Name
                </label>
                <input
                    className="checkout-plans__form-input"
                    type="text"
                    name="username"
                    id="name"
                    value={formData.username.value}
                    onChange={(e) => onChange(e.target.value, e.target.name)}
                />
            </div>
            {formData.username.error && renderAlertMessage('User name')}
            <div
                className={`form-group checkout-plans__form-group ${
                    formData?.email?.isValid && 'validation-graphic-valid'
                }`}>
                <label className="checkout-plans__form-label" htmlFor="email">
                    Email
                </label>
                <input
                    className="checkout-plans__form-input"
                    type="email"
                    name="email"
                    id="email"
                    value={formData.email.value}
                    onChange={(e) => onChange(e.target.value, e.target.name)}
                />
            </div>
            {formData.email.error && renderAlertMessage('email')}
            <div
                className={`form-group checkout-plans__form-group ${
                    formData?.password?.isValid && 'validation-graphic-valid'
                }`}>
                <label
                    className="checkout-plans__form-label"
                    htmlFor="password">
                    Password
                </label>
                <input
                    className="checkout-plans__form-input"
                    type="password"
                    name="password"
                    id="password"
                    value={formData.password.value}
                    onChange={(e) => onChange(e.target.value, e.target.name)}
                />
            </div>
            <div
                className={`form-group checkout-plans__form-group ${
                    formData?.confirm_password?.isValid &&
                    'validation-graphic-valid'
                }`}>
                <label
                    className="checkout-plans__form-label"
                    htmlFor="repeatPassword">
                    Repeat Password
                </label>
                <input
                    className="checkout-plans__form-input"
                    type="password"
                    name="confirm_password"
                    id="repeatPassword"
                    value={formData.confirm_password.value}
                    onChange={(e) => onChange(e.target.value, e.target.name)}
                />
            </div>
            <div className="form-group checkout-plans__form-group d-flex checkmark-spacing">
                <input
                    type="checkbox"
                    id="terms"
                    name="terms"
                    className="checkout-plans__checkmark"
                    onChange={() =>
                        setFormData({
                            ...formData,
                            privacy: {
                                value: !formData.privacy.value,
                                isValid: !formData.privacy.isValid
                            }
                        })
                    }
                    checked={formData.privacy.value}
                />
                <label
                    className="checkout-plans__checkmark-info"
                    htmlFor="terms">
                    I Have Read And Accept JRNL’s <span>Terms of Service</span>{' '}
                    & <span>Privacy Policy</span>
                </label>
            </div>
            <button
                type="submit"
                className="btn btn-checkout-plans"
                disabled={!buttonStatus(formData)}
                onClick={(e) => register(e)}>
                Create Account
            </button>
            <Link className="checkout-plans_link" to={URLS.LOGIN}>
                Already have an account? Log in
            </Link>
        </form>
    );
};
const renderAlertMessage = (name) => {
    const message = `Sorry, this ${name} is already taken. Please enter another one.`;

    return (
        <Alert severity="error" style={{ padding: '0 16px' }}>
            {message}
        </Alert>
    );
};
const buttonStatus = (formData) => {
    let status = true;
    for (const item in formData) {
        if (!formData[item].isValid) {
            status = false;
        }
    }
    return status;
};

export { buttonStatus, renderAlertMessage, useSignUpForm, ModalForm };
