import _ from 'underscore';
import buildAuthorization from 'common/utils/buildAuthorization';

import { UserSelector } from 'redux/selectors/UserSelector';
import {
    FETCH_INVITATION_LIST,
    CREATE_INVITATION,
    REMOVE_INVITATION,
    FETCH_PUBLIC_ENTRY_SHARING_INVITATION,
    FETCH_ENTRY_SHARING_INVITATION_LIST,
    UPDATE_INVITATION,
    SET_INVITE_ID,
    SEND_INVITATION_EMAIL,
    FETCH_INVIATAION_WITH_UNIQUE_KEY,
    FETCH_INDIVIDUAL_INVITATION
} from 'redux/actions/actionTypes';

import * as ShareContactListActions from 'redux/actions/sharecontactlist';
import * as EntryActions from 'redux/actions/entry';
import {
    SHARED_ENTRY_SHARING_INVITATION_URL,
    PUBLIC_ENTRY_SHARING_INVIATAION_URL,
    ENTRY_SHARING_INVITATION_URL
} from 'common/constant';

export const fetch = (pageUrl, options = {}) => (dispatch, getState) => {
    const url = pageUrl || SHARED_ENTRY_SHARING_INVITATION_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: FETCH_INVITATION_LIST,
        payload: {
            request: {
                url,
                method: 'GET',
                params: options,
                options: {
                    type: 'shared_entry_sharing_invitation'
                },
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const inviteUsers = (users, entryId, customMessage) => async (
    dispatch,
    getState
) => {
    let i = 0;

    if (!(users && Array.isArray(users))) {
        return;
    }
    for (i = 0; i < users.length; i++) {
        const user = users[i];

        await dispatch(
            create({
                shared_email: user.email,
                entry: entryId,
                display_channel: 'group',
                granted_permissions: [
                    'view_entry',
                    'view_entry_comments',
                    'add_entry_comments'
                ],
                custom_message: customMessage
            })
        );
    }

    dispatch(ShareContactListActions.fetch());
    dispatch(EntryActions.fetchIndividualEntry(entryId));
};

export const removeUsers = (removed_users, invitations, entryId) => async (
    dispatch,
    getState
) => {
    if (!(removed_users && Array.isArray(removed_users))) {
        return;
    }
    for (let i = 0; i < removed_users.length; i++) {
        const user = removed_users[i];

        const invitaiton = _.findWhere(invitations, { email: user.email });
        if (invitaiton) {
            await dispatch(remove(invitaiton));
        }
    }

    dispatch(ShareContactListActions.fetch());
    dispatch(EntryActions.fetchIndividualEntry(entryId));
};

export const sendInvitationEmails = (invitations) => (dispatch, getState) => {
    let i = 0;

    if (!(invitations && Array.isArray(invitations))) {
        return;
    }
    for (i = 0; i < invitations.length; i++) {
        const invitation = invitations[i];

        dispatch(sendEmail(invitation.unique_id));
    }
};

export const sendEmail = (id) => (dispatch, getState) => {
    const url = `${SHARED_ENTRY_SHARING_INVITATION_URL}${id}/`;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: SEND_INVITATION_EMAIL,
        payload: {
            request: {
                url,
                method: 'PATCH',
                data: {},
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const create = (data) => (dispatch, getState) => {
    const url = SHARED_ENTRY_SHARING_INVITATION_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: CREATE_INVITATION,
        payload: {
            request: {
                url,
                method: 'POST',
                data,
                options: {
                    type: 'shared_entry_sharing_invitation'
                },
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const remove = (data, entryId) => (dispatch, getState) => {
    const url = `${SHARED_ENTRY_SHARING_INVITATION_URL}${data.unique_id}/`;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: REMOVE_INVITATION,
        payload: {
            request: {
                url,
                method: 'DELETE',
                options: {
                    unique_id: data.unique_id
                },
                headers: {
                    Authorization: auth
                }
            },
            entryId
        }
    });
};

export const fetch_public = (invitationId) => (dispatch, getState) => {
    const url = `${PUBLIC_ENTRY_SHARING_INVIATAION_URL}${invitationId}/`;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    dispatch({
        type: FETCH_PUBLIC_ENTRY_SHARING_INVITATION,
        payload: {
            request: {
                url,
                method: 'GET',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const fetch_entry_sharing_invitation = (invitationId) => (
    dispatch,
    getState
) => {
    const url = ENTRY_SHARING_INVITATION_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: FETCH_ENTRY_SHARING_INVITATION_LIST,
        payload: {
            request: {
                url,
                method: 'GET',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const update = (data = {}, invitationId) => (dispatch, getState) => {
    const url = `${ENTRY_SHARING_INVITATION_URL}${invitationId}/`;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: UPDATE_INVITATION,
        payload: {
            request: {
                url,
                method: 'PATCH',
                data,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const setInviteId = (invitationId) => (dispatch, getState) => {
    return dispatch({
        type: SET_INVITE_ID,
        payload: {
            data: invitationId
        }
    });
};

export const fetchInvitationWithUniqueKey = (uniqueKey) => (
    dispatch,
    getState
) => {
    const url = `${ENTRY_SHARING_INVITATION_URL}${uniqueKey}/`;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: FETCH_INVIATAION_WITH_UNIQUE_KEY,
        payload: {
            request: {
                url,
                method: 'GET',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const fetchIndividual = (id) => (dispatch, getState) => {
    const url = `${SHARED_ENTRY_SHARING_INVITATION_URL}${id}/`;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: FETCH_INDIVIDUAL_INVITATION,
        payload: {
            request: {
                url,
                method: 'GET',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};
