import _ from 'underscore';
import React, { useCallback, useState, useRef } from 'react';
import { getShareContactList, getUI } from 'redux/selectors';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { updateUI } from 'redux/actions/ui';
import DefaultUser from 'assets/images/default-user.jpg';
// Icons
import iconCheckSm from 'assets/images/icons_svg/icon_check-sm.svg';
import iconAddSm from 'assets/images/icons_svg/icon_add-sm.svg';

const ShareWithDialog = ({
    share_contact_lists,
    ui,
    dispatch,
    close,
    onUpdate,
    ...rest
}) => {
    const originalSharedUsersRef = useRef(rest.sharedUsers || []);
    const [sharedUsers, setSharedUsers] = useState(rest.sharedUsers || []);
    const [userEmail, setUserEmail] = useState('');

    const onClickContactList = useCallback(
        (list) => {
            let shared_users = [...sharedUsers];

            if (!shared_users) {
                shared_users = [];
            }
            const index = _.findIndex(shared_users, { email: list.email });

            if (index >= 0) {
                shared_users = shared_users.filter(
                    (shared_user) => shared_user.email !== list.email
                );
            } else {
                shared_users.push(list);
            }

            setSharedUsers(shared_users);
        },
        [sharedUsers]
    );

    const onAddNewUser = useCallback(() => {
        // TODO: check if the email is validate
        if (userEmail.length) {
            let added_users = ui.added_users;
            if (!added_users) {
                added_users = [];
            }
            added_users.push({ email: userEmail });

            const shared_users = [...sharedUsers] || [];
            shared_users.push({ email: userEmail });

            dispatch(
                updateUI({
                    added_users
                })
            );

            setUserEmail('');
            setSharedUsers(shared_users);
        }
    }, [userEmail, dispatch, ui, sharedUsers]);

    const _close = useCallback(() => {
        if (close) {
            close();
        }
    }, [close]);

    const done = useCallback(() => {
        const added_users = [],
            removed_users = [];

        sharedUsers.forEach((user) => {
            const index = _.findIndex(originalSharedUsersRef.current, {
                email: user.email
            });

            if (index < 0) {
                added_users.push(user);
            }
        });

        originalSharedUsersRef.current.forEach((user) => {
            const index = _.findIndex(sharedUsers, { email: user.email });

            if (index < 0) {
                removed_users.push(user);
            }
        });

        onUpdate(sharedUsers, added_users, removed_users);
        _close();
    }, [_close, sharedUsers, onUpdate]);

    const renderShareContactLists = useCallback(() => {
        return share_contact_lists?.map((list) => {
            const checked =
                sharedUsers?.findIndex((item) => {
                    return item.email === list.email;
                }) >= 0;

            if (list.user) {
                return (
                    <li
                        key={list.email}
                        className="list__item"
                        onClick={() => onClickContactList(list)}>
                        <div className="list__check">
                            <div
                                className={`check-icon ${
                                    checked ? 'check-icon--selected' : ''
                                }`}>
                                <img src={iconCheckSm} alt="Selected" />
                            </div>
                        </div>
                        <div className="list__img list__img--avatar">
                            <img
                                src={list.user.avatar_image_url || DefaultUser}
                                alt="avatar"
                            />
                        </div>
                        <div className="list__content">
                            <div className="list__title">
                                {list.user.public_display_name || list.email}
                            </div>
                        </div>
                    </li>
                );
            } else {
                return (
                    <li
                        key={list.email}
                        className="list__item"
                        onClick={() => onClickContactList(list)}>
                        <div className="list__check">
                            <div
                                className={`check-icon ${
                                    checked ? 'check-icon--selected' : ''
                                }`}>
                                <img src={iconCheckSm} alt="Selected" />
                            </div>
                        </div>
                        <div className="list__img list__img--avatar">
                            <img
                                src={DefaultUser}
                                alt="avatar"
                                className="circle-img"
                            />
                        </div>
                        <div className="list__content">
                            <div className="list__title">{list.email}</div>
                        </div>
                    </li>
                );
            }
        });
    }, [share_contact_lists, onClickContactList, sharedUsers]);

    const renderAddedContactLists = useCallback(() => {
        const added_users = ui.added_users;
        if (!(added_users && Array.isArray(added_users))) {
            return;
        }
        return added_users.map((user) => {
            const checked =
                _.findIndex(sharedUsers, { email: user.email }) >= 0;

            return (
                <li
                    key={user.email}
                    className="list__item"
                    onClick={() => onClickContactList(user)}>
                    <div className="list__check">
                        <div
                            className={`check-icon ${
                                checked ? 'check-icon--selected' : ''
                            }`}>
                            <img src={iconCheckSm} alt="Selected" />
                        </div>
                    </div>
                    <div className="list__content">
                        <div className="list__title truncate">
                            <span>{user.email}</span>
                        </div>
                    </div>
                </li>
            );
        });
    }, [ui, onClickContactList, sharedUsers]);

    return (
        <div id="share-with-modal" className="modal-frame">
            <div className="modal-box modal__box--dropzone modal-box--600">
                <div className="modal__header">Share With...</div>
                <div className="modal__body">
                    <div className="list list--select">
                        {renderShareContactLists()}
                    </div>
                    <div className="share-modal-add-user">
                        <div className="form-group form-group--single">
                            <div className="form-group__item">
                                <div className="form-input-btn">
                                    <input
                                        className="form-input-btn__input"
                                        type="text"
                                        value={userEmail}
                                        onChange={(e) => {
                                            setUserEmail(e.target.value);
                                        }}
                                        placeholder="Add another user..."
                                    />
                                    <button
                                        className="form-input-btn__btn"
                                        onClick={onAddNewUser}>
                                        <img src={iconAddSm} alt="" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <ul className="list list--select">
                            {renderAddedContactLists()}
                        </ul>
                    </div>
                </div>
                <div className="modal__footer">
                    <button className="btn btn-outline" onClick={_close}>
                        Cancel
                    </button>
                    <button
                        className="btn btn-gradient"
                        onClick={userEmail ? onAddNewUser : done}>
                        Add Users
                    </button>
                </div>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    share_contact_lists: getShareContactList,
    ui: getUI
});

export default connect(state)(ShareWithDialog);
