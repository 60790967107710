import React, { useCallback } from 'react';

export const fontsToStyle = {
    'Roboto': 'font-roboto',
    '"Open Sans", sans-serif': 'font-open-sans',
    '"Lato", sans-serif': 'font-lato',
    '"Abril Fatface", cursive': 'font-abril',
    '"Gravitas" One, cursive': 'font-gravitas',
    '"Old Standard TT", serif': 'font-old-standard',
    '"Poppins", sans-serif': 'font-poppins',
    '"PT Mono", monospace': 'font-pt-mono',
    '"PT Serif", serif': 'font-pt-serif',
    '"Ubuntu", sans-serif': 'font-ubuntu',
    '"Vollkorn", serif': 'font-vollkorn'
}

export const styleMap = {
    'font-roboto': {
        fontFamily: "Roboto"
    },
    'font-open-sans': {
        fontFamily: 'Open Sans, sans-serif'
    },
    'font-lato': {
        fontFamily: 'Lato, sans-serif'
    },
    'font-abril': {
        fontFamily: 'Abril Fatface, cursive'
    },
    'font-gravitas': {
        fontFamily: 'Gravitas One, cursive'
    },
    'font-old-standard': {
        fontFamily: 'Old Standard TT, serif'
    },
    'font-poppins': {
        fontFamily: 'Poppins, sans-serif'
    },
    'font-pt-mono': {
        fontFamily: 'PT Mono, monospace'
    },
    'font-pt-serif': {
        fontFamily: 'PT Serif, serif'
    },
    'font-ubuntu': {
        fontFamily: 'Ubuntu, sans-serif'
    },
    'font-vollkorn': {
        fontFamily: 'Vollkorn, serif'
    }
}

const textMap = {
    'font-roboto': 'Roboto',
    'font-open-sans':  'Open Sans',
    'font-lato': 'Lato',
    'font-abril': 'Abril Fatface',
    'font-gravitas': 'Gravitas One',
    'font-old-standard': 'Old Standard TT',
    'font-poppins': 'Poppins',
    'font-pt-mono': 'PT Mono',
    'font-pt-serif': 'PT Serif',
    'font-ubuntu': 'Ubuntu',
    'font-vollkorn': 'Vollkorn'
}

const FontsDropdown = ({
    onChange,
    value,
    onClick
}) => {

    const changeFont = useCallback(
        (font) => {
            onChange(font);
        },
        [onChange],
    )

    const getText = useCallback(
        () => {
            return textMap[value] || '';
        },
        [value],
    )

    const renderFonts = useCallback(
        () => {
            return Object.keys(textMap).map(key => {
                return (
                    <li onClick={() => changeFont(key)} key={key} font={key}>
                        <p className={key}>{ textMap[key] }</p>
                    </li>  
                )
            })
        },
        [changeFont],
    )
    
    const onChangeFont = useCallback(
        (e) => {
            e.preventDefault();
            onClick(e);
        },
        [onClick],
    );

    return (
        <li className="dropdown" onMouseDown={onChangeFont} >
            <div className="btn-font dropdown-toggle" onMouseDown={onChangeFont} role="button" id="tagsDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-offset="10,20">
                { getText() }
                <span className="fa fa-caret-down"></span>
            </div>
            <div className="dropdown-menu tags-dropdown-menu" aria-labelledby="tagsDropdown">
                <ul className="dropdown-tags-list scrollable-dropdown-list">
                    { renderFonts() }
                </ul>
            </div>
        </li>
    )
}

export default FontsDropdown;