import React, {
    // useEffect,
    useCallback
} from 'react';
// redux
import { connect } from 'react-redux';
// Selectors
import { getUI } from 'redux/selectors';
import { createStructuredSelector } from 'reselect';
// Actions
import { updateUI } from 'redux/actions/ui';

const CustomIntroductionPageStep = ({ display, dispatch, ui }) => {
    // handlers
    const onChangeCustomIntroductionText = useCallback(
        (e) => {
            dispatch(
                updateUI({
                    custom_introduction: e.target.value
                })
            );
        },
        [dispatch]
    );

    return (
        <div
            className={`new-book-step-11 new-book-step ${
                display ? 'active-form' : ''
            }`}>
            <div className="section-title">Custom Introduction Page</div>
            <div className="new-book-step-form">
                <form>
                    <div className="form-group">
                        <div className="form-group__item">
                            <label htmlFor="custom-introduction">
                                Custom Introduction
                            </label>
                            <textarea
                                value={ui.custom_introduction || ''}
                                onChange={onChangeCustomIntroductionText}
                                id="custom-introduction"
                                name="custom-introduction"
                                placeholder="Custom Introduction Page (Optional)"
                                rows={4}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    ui: getUI
});
export default connect(state)(CustomIntroductionPageStep);
