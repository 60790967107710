import { useContext } from 'react';
import { LanguageContext } from "containers/Language";

const Text = (props) => {

    const languageContext = useContext(LanguageContext);
    if (languageContext.dictionary[props.tid])
        return languageContext.dictionary[props.tid];
    else

        return props.tid;
};

export default Text;

