import moment from 'moment';
import { OLD_USER_TERM } from 'common/constant';

export const isOldUser = (user) => {
    const notNewUser = Boolean(
        Math.abs(
            moment(user.date_joined, 'YYYY-MM-DDTHH:mm:ss')
                .utc()
                .diff(moment().utc(), 'days')
        ) > OLD_USER_TERM
    );
    return notNewUser;
};
