import {
    FETCH_FAMILY_ACCOUNTS,
    CREATE_FAMILY_ACCOUNT,
    EDIT_FAMILY_ACCOUNT,
    DELETE_FAMILY_ACCOUNT
} from 'redux/actions/actionTypes';

export const fetch = () => (dispatch) => {
    const familyAccounts = JSON.parse(localStorage.getItem('familyAccounts'));

    return dispatch({
        type: FETCH_FAMILY_ACCOUNTS,
        data: familyAccounts || {
            familyAccounts: []
        }
    });
};

export const create_account = (data) => (dispatch) => {
    return dispatch({
        type: CREATE_FAMILY_ACCOUNT,
        data: data
    });
};

export const edit = (account, index) => (dispatch) => {
    return dispatch({
        type: EDIT_FAMILY_ACCOUNT,
        data: {
            account,
            index
        }
    });
};

export const delete_account = (index) => (dispatch) => {
    return dispatch({
        type: DELETE_FAMILY_ACCOUNT,
        data: { index }
    });
};
