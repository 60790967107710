import _ from 'underscore';
import {
    FETCH_TAG_LIST,
    CREATE_TAG,
    REMOVE_TAG,
    ACTION_USER_LOGOUT,
    UPDATE_TAG,
    SET_TAG_LOADINGPROGRESS,
    CLEAR_LOADINGPROGRESS
} from 'redux/actions/actionTypes';
import { SUCCESS_SUFFIX, ERROR_SUFFIX } from 'common/constant';

const initialState = {
    tags: undefined,
    meta: undefined,
    isPending: false
};

const TagReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TAG_LIST:
        case CREATE_TAG: {
            return _.extend({}, state, {
                isPending: true
            });
        }

        case FETCH_TAG_LIST + SUCCESS_SUFFIX: {
            return _.extend({}, state, {
                isPending: false,
                tags: action.payload.data?.objects,
                meta: action.payload.data?.meta
            });
        }

        case FETCH_TAG_LIST + ERROR_SUFFIX: {
            return _.extend({}, state, {
                isPending: false
            });
        }

        case REMOVE_TAG + SUCCESS_SUFFIX: {
            const tags = state.tags.filter(
                (tag) => tag.id !== action.payload.config.options.id
            );

            return _.extend({}, state, {
                tags,
                isPending: false
            });
        }

        case CREATE_TAG + SUCCESS_SUFFIX:
        case UPDATE_TAG + SUCCESS_SUFFIX: {
            let tags = state.tags;
            const index = _.findIndex(
                tags,
                (tag) => action.payload.data?.id === tag.id
            );

            if (index >= 0) {
                tags = [].concat(
                    tags.slice(0, index),
                    [action.payload.data],
                    tags.slice(index + 1)
                );
            } else {
                tags = [].concat([action.payload.data], tags || []);
            }

            return _.extend({}, state, {
                tags,
                isPending: false
            });
        }

        case CREATE_TAG + ERROR_SUFFIX: {
            return _.extend({}, state, {
                isPending: false
            });
        }

        case SET_TAG_LOADINGPROGRESS: {
            return _.extend({}, state, {
                loading: true,
                isPending: undefined
            });
        }

        case CLEAR_LOADINGPROGRESS: {
            return _.extend({}, state, {
                loading: false
            });
        }

        case ACTION_USER_LOGOUT: {
            return _.extend({}, initialState);
        }

        default: {
            return state;
        }
    }
};

export default TagReducer;
