import React from 'react';
import Button from '@material-ui/core/Button';
import { PLAN_LABELS, PLAN_PRICES } from 'common/constant';

export const ChooseAccountPlan = ({ isBasic, classes, setDialogs }) => {
    const basicHtml = () => (
        <section className="choose-account-plans">
            <div className="container">
                <div className="row justify-content-lg-center justify-content-around">
                    <div className="col-md-12 col-lg-5 choose-account-plan-card first-card-mr">
                        <div style={{ height: '23rem' }}>
                            <div className="choose-account-header">
                                <h2 className="choose-account-plan">
                                    {PLAN_LABELS.FREE}
                                </h2>
                                <p className="choose-account-plan-info">
                                    On the house
                                </p>
                            </div>
                            <p className="choose-account-desc">
                                The perfect way to start writing your ideas &
                                thoughts
                            </p>
                            <ul className="account-features account-features-spacing">
                                <li>
                                    <span className="fa fa-bullet" />1 journal
                                </li>
                                <li>
                                    <span className="fa fa-bullet" />2 GB of
                                    Storage
                                </li>
                                <li>
                                    <span className="fa fa-bullet" />
                                    Unlimited Text Entries
                                </li>
                                <li>
                                    <span className="fa fa-bullet" />
                                    Multiple Photos per Entry
                                </li>
                            </ul>
                        </div>
                        <Button
                            className={classes.btn}
                            variant="outlined"
                            color="inherit"
                            fullWidth
                            onClick={() =>
                                setDialogs({
                                    free: true
                                })
                            }>
                            Try for Free Now
                        </Button>
                    </div>
                    <div className="col-md-12 col-lg-5 choose-account-plan-card">
                        <div style={{ height: '23rem' }}>
                            <div className="choose-account-header">
                                <h2 className="choose-account-plan">
                                    {PLAN_LABELS.PRO}
                                </h2>
                                <p className="choose-account-plan-info">
                                    ${PLAN_PRICES.PRO.DEFAULT}
                                    <span className="choose-account-plan-info-medium">
                                        / year
                                    </span>
                                </p>
                            </div>
                            <div className="choose-account-desc">
                                <p className="choose-account-desc-info">
                                    Express yourself with video, save your
                                    memories and more
                                </p>
                            </div>
                            <h3 className="accounts-features-header">
                                All Free Features PLUS
                            </h3>
                            <ul className="account-features">
                                <li>
                                    <span className="fa fa-bullet" />
                                    Unlimited Journals
                                </li>
                                <li>
                                    <span className="fa fa-bullet" />1 TB of
                                    storage
                                </li>
                                <li>
                                    <span className="fa fa-bullet" />
                                    12 PDF Downloads per year
                                </li>
                                <li>
                                    <span className="fa fa-bullet" />
                                    Shared Entries & Journals
                                </li>
                                <li>
                                    <span className="fa fa-bullet" />
                                    Audio & Video Upload
                                </li>
                            </ul>

                            <Button
                                className={classes.btn}
                                variant="outlined"
                                color="inherit"
                                fullWidth
                                onClick={() => setDialogs({ pro: true })}>
                                Upgrade to Pro
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

    const proHtml = () => (
        <section className="choose-account">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-5 choose-account-card">
                        <div className="choose-account-header">
                            <h2 className="choose-account-plan">
                                “{PLAN_LABELS.LOYALTY}”
                            </h2>
                            <p className="choose-account-plan-info">
                                On the house
                                <br />
                                <span className="choose-account-discount">
                                    ${PLAN_PRICES.LOYALTY.DEFAULT}
                                </span>
                            </p>
                        </div>
                        <p className="choose-account-desc">
                            The perfect way to start writing your ideas &
                            thoughts
                        </p>
                        <h3 className="accounts-features-header">
                            All “JRNL Free” Features PLUS:
                        </h3>
                        <ul className="account-features">
                            <li>
                                <span className="fa fa-bullet" />1 TB of Storage
                            </li>
                            <li>
                                <span className="fa fa-bullet" />1 Free PDF
                                Download per Year
                            </li>
                            <li>
                                <span className="fa fa-bullet" />
                                Shared Entries
                            </li>
                            <li>
                                <span className="fa fa-bullet" />
                                10% Discount on JRNL Purchases
                            </li>
                        </ul>
                        <button
                            className="btn btn-plan btn-get-free"
                            onClick={() =>
                                setDialogs({
                                    thank_you: true
                                })
                            }>
                            Get It For Free
                        </button>
                    </div>
                    <div className="col-2 choose-account-divider">
                        <div className="or-card">
                            <p>or</p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-5 choose-account-card">
                        <div className="choose-account-header">
                            <h2 className="choose-account-plan">
                                {PLAN_LABELS.PRO} Account
                            </h2>
                            <p className="choose-account-plan-info">
                                ${PLAN_PRICES.PRO.DEFAULT}
                                <br />
                                <span className="choose-account-plan-info-medium">
                                    / year
                                </span>
                            </p>
                        </div>
                        <p className="choose-account-desc">
                            Save your memories, share them with your family and
                            more
                        </p>
                        <h3 className="accounts-features-header">
                            “{PLAN_LABELS.PRO}” Features At a Discount:
                        </h3>
                        <ul className="account-features">
                            <li>
                                <span className="fa fa-bullet" />1 TB of Storage
                            </li>
                            <li>
                                <span className="fa fa-bullet" />
                                12 Free PDF Downloads per Year
                            </li>
                            <li>
                                <span className="fa fa-bullet" />
                                Shares Entries & Journals
                            </li>
                            <li>
                                <span className="fa fa-bullet" />
                                25% Discount on JRNL Purchases
                            </li>
                        </ul>
                        <button
                            className="btn btn-plan btn-get-upgrade"
                            onClick={() =>
                                setDialogs({
                                    pro: true
                                })
                            }>
                            Upgrade Now
                            <span className="get-save-text">Save $79</span>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
    return <React.Fragment>{isBasic ? basicHtml() : proHtml()}</React.Fragment>;
};
