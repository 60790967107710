import React, { useState, useCallback, useEffect, useRef } from 'react';
import { EditorState, RichUtils } from 'addon-draft-js';
import moment from 'moment';
// redux
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    getEditorNewData,
    getFontSize as getFontSizeSelector
} from 'redux/selectors';
import Editor, { composeDecorators } from 'addon-plugins-editor';

// draft-js plugins
import createAlignmentPlugin from 'addon-draft-js-alignment-plugin';
import createImagePlugin from 'addon-draft-js-image-plugin';
import createFocusPlugin from 'addon-draft-js-focus-plugin';
import createResizeablePlugin from 'addon-draft-js-resizeable-plugin';
import createBlockDndPlugin from 'addon-draft-js-drag-n-drop-plugin';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import createEmojiPlugin from 'addon-draft-js-emoji-plugin';
import createLinkPlugin from 'addon-draft-js-link-plugin';
import createCounterPlugin from 'draft-js-counter-plugin';
import createVideoPlugin from 'addon-draft-js-video-plugin';
import createAudioPlugin from 'addon-draft-js-audio-plugin';
import createBlockPlugin from 'addon-draft-js-block-plugin';

// Icons
/*
import iconFormatBold from 'assets/images/icons_svg/icon_format-bold.svg';
import iconFormatItalic from 'assets/images/icons_svg/icon_format-italic.svg';
import iconFormatTextSize from 'assets/images/icons_svg/icon_format-text-size.svg';
import iconFormatUnderline from 'assets/images/icons_svg/icon_format-underline.svg';
import iconFormatAlignCenter from 'assets/images/icons_svg/icon_format-align-center.svg';
import iconFormatAlignLeft from 'assets/images/icons_svg/icon_format-align-left.svg';
import iconFormatAlignRight from 'assets/images/icons_svg/icon_format-align-right.svg';
import iconFormatIndentLess from 'assets/images/icons_svg/icon_format-indent-less.svg';
import iconFormatIndentMore from 'assets/images/icons_svg/icon_format-indent-more.svg';
import iconFormatListNumbered from 'assets/images/icons_svg/icon_format-list-numbered.svg';
import iconFormatListBullets from 'assets/images/icons_svg/icon_format-list-bullets.svg';
import iconFormatEmoji from 'assets/images/icons_svg/icon_format-emoji.svg';
*/

// draft-js buttons
import {
    ItalicButton,
    BoldButton,
    UnderlineButton,
    // HeadlineTwoButton,
    OrderedListButton,
    UnorderedListButton,
    BlockquoteButton
} from 'addon-draft-js-buttons';
import {
    IndentButton,
    OutdentButton
} from 'components/Editor/components/Buttons/Padding';

// extended Rich utils
import ExtendedRichUtils, {
    ALIGNMENTS,
    ALIGNMENT_DATA_KEY
} from 'components/Editor/utils/ExtendedRichUtils';

// actions
import * as EditorActions from 'redux/actions/editor';
import { setLoadingProgress } from 'redux/actions/entry';
import { clearLoadingProgress } from 'redux/actions/loading';

// plugin css
import 'addon-draft-js-alignment-plugin/lib/plugin.css';
import 'addon-draft-js-image-plugin/lib/plugin.css';
import 'draft-js-static-toolbar-plugin/lib/plugin.css';
import 'addon-draft-js-emoji-plugin/lib/plugin.css';
import 'addon-draft-js-link-plugin/lib/plugin.css';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css';
import { styleMap } from 'components/Editor/components/Buttons/Fonts/FontsDropdown';
import {
    ENTRYEDITOR_NEWDATA_TYPES,
    DRAFTJS_PLUGINS,
    FONT_SIZE_USE_TYPE
} from 'common/constant';
import DocumentFile from 'components/Editor/components/DocumentFIle';
import { URLS } from 'common/urls';
import { useLocation } from 'react-router';
import getFontSize from 'common/utils/getFontSize';

// create plugins
const focusPlugin = createFocusPlugin();
const alignmentPlugin = createAlignmentPlugin();
const { AlignmentTool } = alignmentPlugin;
const resizeablePlugin = createResizeablePlugin();
const blockDndPlugin = createBlockDndPlugin();
const toolbarPlugin = createToolbarPlugin();
const { Toolbar } = toolbarPlugin;
const emojiPlugin = createEmojiPlugin();
const { EmojiSelect } = emojiPlugin;
const linkPlugin = createLinkPlugin();
const counterPlugin = createCounterPlugin();
const videoPlugin = createVideoPlugin();
const audioPlugin = createAudioPlugin();
const blockPlugin = createBlockPlugin({
    blockComponent: DocumentFile,
    type: DRAFTJS_PLUGINS.DOCUMENT
});

const { WordCounter } = counterPlugin;

// decorator for image plugin
const decorator = composeDecorators(
    resizeablePlugin.decorator,
    alignmentPlugin.decorator,
    focusPlugin.decorator,
    blockDndPlugin.decorator
);
const imagePlugin = createImagePlugin({ decorator });

const plugins = [
    focusPlugin,
    alignmentPlugin,
    imagePlugin,
    resizeablePlugin,
    blockDndPlugin,
    toolbarPlugin,
    emojiPlugin,
    linkPlugin,
    counterPlugin,
    videoPlugin,
    audioPlugin,
    blockPlugin
];

// constants
export const alignmentProperty = {
    default: ALIGNMENTS.LEFT
};
// component
const DraftJSEditor = ({
    newData,
    onChange,
    handlePastedText,
    editorState,
    dispatch,
    onEditImage,
    onDeleteImage,
    showEditButtons,
    userFontSize,
    onUpdateRef
}) => {
    const [alignment, setAlignment] = useState(alignmentProperty.default);
    const [editor, setEditor] = useState(null);
    const editorRef = useRef();
    const location = useLocation();
    const newDataRef = useRef(null);
    const onEditorChange = useCallback(
        (state) => {
            onChange(state);

            if (onChange) {
                onChange(state);
            }
        },
        [onChange]
    );
    useEffect(() => {
        const figureList =
            editorRef?.current && editorRef.current.querySelectorAll('figure');
        if (location.pathname.includes(URLS.ENTRY.EDIT)) {
            for (const element of figureList) {
                if (!element.getAttribute('data-binded')) {
                    element.setAttribute('data-binded', true);
                    const imgInContent = element.querySelectorAll('img');
                    for (const img of imgInContent) {
                        if (!Number(img.getAttribute('data-binded'))) {
                            img.setAttribute('data-binded', 1);
                            dispatch(setLoadingProgress());
                            img.onload = () => {
                                dispatch(clearLoadingProgress());
                            };
                        }
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [editorState, editorRef.current]);
    const onIndent = useCallback(() => {
        const newEditorState = ExtendedRichUtils.toggleIndent(editorState, 30);
        onChange(newEditorState);
    }, [editorState, onChange]);

    const onOutdent = useCallback(() => {
        const newEditorState = ExtendedRichUtils.toggleIndent(editorState, -30);
        onChange(newEditorState);
    }, [editorState, onChange]);

    const onTextAlignChange = useCallback(
        (alignmentType, automatic = false) => {
            const alignmentData = ExtendedRichUtils.toggleAlignment(
                editorState,
                alignmentType,
                automatic
            );
            if (alignmentData.alignment) {
                setAlignment(alignmentData.alignment);
            } else {
                setAlignment(alignmentProperty.default);
            }
            onChange(alignmentData.editorState);

            if (onChange) {
                onChange(alignmentData.editorState);
            }
        },
        [editorState, onChange]
    );

    useEffect(() => {
        const textAlignment = ExtendedRichUtils.getTextAlignmentOfSelection(
            editorState
        );

        if (textAlignment) {
            setAlignment(textAlignment);
        } else {
            onTextAlignChange(alignment);
        }
    }, [editorState, alignment, onTextAlignChange]);

    useEffect(() => {
        if (newDataRef.current === newData) {
            return;
        }
        newDataRef.current = newData;

        switch (newData.type) {
            case ENTRYEDITOR_NEWDATA_TYPES.IMAGE: {
                let _i, _len;
                const _ref = newData.data;

                let newEditorState = editorState;
                for (_i = 0, _len = _ref.length; _i < _len; _i++) {
                    const imageData = _ref[_i];
                    newEditorState = ExtendedRichUtils.insertImageBlock(
                        newEditorState,
                        {
                            ...imageData,
                            onEditImage,
                            onDeleteImage
                        }
                    );
                }
                onChange(RichUtils.insertSoftNewline(editorState));
                onChange(newEditorState);
                dispatch(EditorActions.removeNewData());
                break;
            }
            case ENTRYEDITOR_NEWDATA_TYPES.VIDEO: {
                let newEditorState = editorState;
                newEditorState = ExtendedRichUtils.insertMediaBlock(
                    newEditorState,
                    {
                        src: newData.data.file,
                        caption: newData.data.description,
                        entry_link: newData.data.link,
                        video_play_poster: newData.data.video_play_poster,
                        qrcode: newData.data.qrcode,
                        entry_qrcode: newData.data.entry?.qrcode,
                        onEditVideo: (contentState, image, imageCaption) =>
                            onEditImage(
                                contentState,
                                image,
                                imageCaption,
                                'Video'
                            ),
                        onDeleteVideo: onDeleteImage
                    },
                    DRAFTJS_PLUGINS.VIDEO
                );
                onChange(RichUtils.insertSoftNewline(editorState));
                onChange(newEditorState);
                dispatch(EditorActions.removeNewData());
                break;
            }
            case ENTRYEDITOR_NEWDATA_TYPES.AUDIO: {
                // Create a non-dom allocated Audio element
                var au = document.createElement('audio');

                // Define the URL of the MP3 audio file
                au.src = newData.data.file;

                // Once the metadata has been loaded, display the duration in the console
                au.addEventListener(
                    'loadedmetadata',
                    function () {
                        // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
                        var duration = au.duration;
                        // Alternatively, just display the integer value with
                        // parseInt(duration)
                        // 12 seconds
                        let newEditorState = editorState;
                        const createdDate = moment(newData.data.created).format(
                            'dddd, D MMMM YYYY'
                        );
                        newEditorState = ExtendedRichUtils.insertMediaBlock(
                            newEditorState,
                            {
                                src: newData.data.file,
                                caption: newData.data.description,
                                entry_link: newData.data.link,
                                file_name: newData.data.file_name,
                                qrcode: newData.data.qrcode,
                                entry_qrcode: newData.data.entry?.qrcode,
                                audio_duration: duration,
                                created_date: createdDate,
                                onEditAudio: onEditImage,
                                onDeleteAudio: onDeleteImage
                            },
                            DRAFTJS_PLUGINS.AUDIO
                        );
                        onChange(RichUtils.insertSoftNewline(editorState));
                        onChange(newEditorState);
                        dispatch(EditorActions.removeNewData());
                    },
                    false
                );

                break;
            }
            case ENTRYEDITOR_NEWDATA_TYPES.APPLICATION: {
                let newEditorState = editorState;
                const createdDate = moment(newData.data.created).format(
                    'dddd, D MMMM YYYY'
                );
                newEditorState = ExtendedRichUtils.insertMediaBlock(
                    newEditorState,
                    {
                        src: newData.data.file,
                        size: newData.data.file_size,
                        formatted_size: newData.data.file_formatted_size,
                        caption: newData.data.description,
                        name: newData.data.file_name,
                        entry_link: newData.data.link,
                        created_date: createdDate,
                        qrcode: newData.data.qrcode,
                        entry_qrcode: newData.data.entry?.qrcode,
                        onEditDocument: onEditImage,
                        onDeleteDocument: onDeleteImage
                    },
                    DRAFTJS_PLUGINS.DOCUMENT
                );
                onChange(RichUtils.insertSoftNewline(editorState));
                onChange(newEditorState);
                dispatch(EditorActions.removeNewData());
                break;
            }
            case ENTRYEDITOR_NEWDATA_TYPES.CLEAR: {
                onChange(EditorState.createEmpty());
                setAlignment(alignmentProperty.default);
                dispatch(EditorActions.removeNewData());
                break;
            }
            default: {
                break;
            }
        }
    }, [newData, dispatch, editorState, onChange, onDeleteImage, onEditImage]);

    const getBlockStyle = useCallback((block) => {
        if (typeof block.getData().get !== 'function') {
            return null;
        }
        const textAlignStyle = block.getData().get(ALIGNMENT_DATA_KEY);
        switch (textAlignStyle) {
            default:
                return null;
        }
    }, []);

    const focus = useCallback(
        (e) => {
            e.preventDefault();
            if (editor) {
                editor.focus();
                setTimeout(() => {
                    editor.focus();
                }, 100);
            }
        },
        [editor]
    );

    // return handler
    const handleReturn = (e) => {
        if (e.shiftKey) {
            onChange(RichUtils.insertSoftNewline(editorState));
            return 'handled';
        }
        return 'not-handled';
    };

    React.useEffect(() => {
        // on update ref
        onUpdateRef(editorRef.current);
    }, [onUpdateRef]);

    return (
        <div>
            <div
                className="draftjs-content scrollable"
                ref={editorRef}
                style={{
                    fontSize: getFontSize(
                        FONT_SIZE_USE_TYPE.ENTRY,
                        userFontSize
                    )
                }}>
                <Editor
                    editorState={editorState}
                    onChange={onEditorChange}
                    blockStyleFn={getBlockStyle}
                    customStyleMap={styleMap}
                    plugins={plugins}
                    ref={(element) => {
                        setEditor(element);
                    }}
                    handleReturn={handleReturn}
                    spellCheck={true}
                    handlePastedText={handlePastedText}
                />
                <AlignmentTool />
            </div>
            <div onMouseDown={focus}>
                {showEditButtons ? (
                    <Toolbar>
                        {(externalProps) => (
                            <ul className="post-editor">
                                <BoldButton {...externalProps} />
                                <ItalicButton {...externalProps} />
                                <UnderlineButton {...externalProps} />
                                <IndentButton onClick={onIndent} />
                                <OutdentButton onClick={onOutdent} />
                                <OrderedListButton {...externalProps} />
                                <UnorderedListButton {...externalProps} />
                                <BlockquoteButton {...externalProps} />
                                <EmojiSelect />
                                <li className="word-count">
                                    <WordCounter editorState={editorState} />{' '}
                                    words
                                </li>
                            </ul>
                        )}
                    </Toolbar>
                ) : (
                    <ul className="post-editor">
                        <li className="word-count">
                            <WordCounter editorState={editorState} /> words
                        </li>
                    </ul>
                )}
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    newData: getEditorNewData,
    userFontSize: getFontSizeSelector
});
export default connect(state)(DraftJSEditor);
