import { createSelector } from 'reselect';

export const JournalEmailSelector = (state) => state.journal_email;

export const getJournalEmails = createSelector(
    [JournalEmailSelector],
    (state) => state.journalEmails
);
export const getSenderEmails = createSelector(
    [JournalEmailSelector],
    (state) => state.senderEmails
);
