import React from 'react';

// icons
import iconCaretDown from 'assets/images/icons_svg/icon_caret-down.svg';
import iconAngleLeft from 'assets/images/icons_svg/icon_angle-left.svg';
import iconAngleRight from 'assets/images/icons_svg/icon_angle-right.svg';

const CalendarHeader = ({
    onPrevMonth,
    currentDate,
    onNextMonth,
    showTabs,
    toggleTabs
}) => {
    return (
        <ul className="calendar-header">
            <li
                className="calendar-header__arrow calendar-header__arrow--left"
                onClick={onPrevMonth}>
                <img src={iconAngleLeft} alt="" />
            </li>
            <li>
                <div className="dropdown show" onClick={() => toggleTabs()}>
                    <span
                        className="calendar-header__title dropdown-toggle"
                        id="calendarFilter">
                        {currentDate.format('MMMM YYYY')}
                        <img src={iconCaretDown} alt="" />
                    </span>
                </div>
            </li>
            <li
                className="calendar-header__arrow calendar-header__arrow--right"
                onClick={onNextMonth}>
                <img src={iconAngleRight} alt="" />
            </li>
        </ul>
    );
};

export default CalendarHeader;
