import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEntriesMediaImages } from 'redux/selectors/EntrySelector';
import { useLocation, useParams } from 'react-router-dom';

import { openDialog } from 'common/utils/dialog-utils';
import { URLS } from 'common/urls';

import MediaNavbar from 'pages/Media/MediaNavbar';
import MediaGallery from 'pages/Media/MediaGallery';
import MediaSearch from 'pages/Search/MediaSearch';

import * as EntryMediaActions from 'redux/actions/entrymedia';
import * as JournalActions from 'redux/actions/journal';
import * as TaggedUsersAction from 'redux/actions/taggedUser';
import * as V2PublicUsersAction from 'redux/actions/v2publicuser';
import { getUI } from 'redux/selectors';

import MediaViewer from './MediaViewer';
import { getFileType } from 'common/utils/file_utils';

const Media = () => {
    const FETCH_STATUSES = {
        INIT: 0,
        LOADING: 1,
        DONE: 2
    };
    const [fetchingMedia, setFetchingMedia] = React.useState(0);
    const ui = useSelector(getUI);
    const param = useParams();
    const location = useLocation();
    const mediaID = param?.mediaID;
    const dispatch = useDispatch();
    const media = useSelector(getEntriesMediaImages);

    useEffect(() => {
        dispatch(EntryMediaActions.fetchAll());
        dispatch(TaggedUsersAction.fetchAll());
        dispatch(TaggedUsersAction.userOnlyAll());
        dispatch(V2PublicUsersAction.fetchAll());
        dispatch(JournalActions.fetch());
    }, [dispatch]);

    useEffect(() => {
        if (
            location.pathname.includes(URLS.MEDIA.VIEW) &&
            mediaID &&
            media &&
            media?.results
        ) {
            const item = media.results.find(
                (obj) => Number(mediaID) === obj.id
            );
            if (ui?.dialogs?.length === FETCH_STATUSES.INIT) {
                if (item && fetchingMedia !== FETCH_STATUSES.LOADING) {
                    openDialog(MediaViewer, { media: item });
                } else if (fetchingMedia === FETCH_STATUSES.INIT) {
                    setFetchingMedia(FETCH_STATUSES.LOADING);
                    dispatch(EntryMediaActions.fetchIndividual(mediaID)).then(
                        (r) => {
                            const d = r.payload.data;
                            const m = {
                                ...d,
                                type: getFileType(d?.file || d?.image)
                            };
                            setFetchingMedia(FETCH_STATUSES.DONE);
                            openDialog(MediaViewer, { media: m });
                        }
                    );
                }
            }
        }
    }, [dispatch, fetchingMedia, FETCH_STATUSES, mediaID, media, ui, location]);

    // const clickUser = React.useCallback(
    //     (user) => {
    //     },[]
    // )

    return (
        <div>
            {/* <MediaNavbar _onClick={clickUser} />         */}
            <MediaSearch />
            <MediaNavbar />
            <MediaGallery />
        </div>
    );
};

export default Media;
