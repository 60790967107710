import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { FaBookOpen } from 'react-icons/fa';

// import AddTagUserIcon from 'assets/images/media/icon_add_tag_user.svg';
//actions
import * as EntryActions from 'redux/actions/entry';
import * as EntryMediaActions from 'redux/actions/entrymedia';
import * as ShareContactListsActions from 'redux/actions/sharecontactlist';
// import * as V2PublicUserActions from 'redux/actions/v2publicuser';
import * as TaggedUser from 'redux/actions/taggedUser';
import { updateUI } from 'redux/actions/ui';
import { getUI, getShareContactList } from 'redux/selectors';
import { ShareContactListSelector } from 'redux/selectors/ShareContactListSelector';
import { getMediaEntries } from 'redux/selectors/EntrySelector';
//components
import DisplayVideo from 'components/DisplayFile/DisplayVideo';
import DisplayAudio from 'components/DisplayFile/DisplayAudio';
import AddTagUser from 'components/media/addTagUser';
import { AddToEntry } from 'components/media/addToEntry';
import { MediaDetails } from 'components/media/media-details';
import EntriesWithThisImage from 'components/media/entries-with-this-image';
import { AddLocationDialog } from 'pages/Dialogs/add-location-dialog';

import { HTTP_CODES } from 'common/constant';
import settings from 'common/configs/setting';
import { URLS } from 'common/urls';
// import { closeDialog, openDialog } from 'common/utils/dialog-utils';
import { closeDialog } from 'common/utils/dialog-utils';
import { renderSummaryText } from 'common/utils/renderSummaryText';
import { getCroppedImg } from 'common/utils/imageCrop';
import { getInfoForCircle } from 'common/utils/calculateCoordinateUtils';
import widthGeoLocation from 'common/utils/components/widhGeoLocation';
// import { DataURIToBlob } from 'common/utils/file_utils';
import MapboxContainer from 'components/mapbox-container';

const MediaViewer = ({
    ShareContactListSelector,
    contacts,
    media,
    mediaEntryList,
    ui
}) => {
    const history = useHistory();
    const [isEditing, setIsEditing] = useState(false);
    const [content, setContent] = useState('');
    const dispatch = useDispatch();
    const [openAddToEntryDialog, setOpenAddToEntryDialog] = useState(false);
    const [openWithThisImageDialog, setOpenWithThisImageDialog] = useState(
        false
    );
    const [detectFaces, setDetectFaces] = useState([]);
    const [imageSize, setImageSize] = useState({
        width: 0,
        height: 0
    });
    const [ratio, setRatio] = useState({
        wRatio: 1.0,
        hRatio: 1.0
    });
    const [entryLocation, setEntryLocation] = useState({
        address: media.location,
        lat: '',
        lng: ''
    });

    const [viewport, setViewPort] = useState({
        address: media.location,
        latitude: settings.map_default_coords.latitude,
        longitude: settings.map_default_coords.longitude,
        width: '100%',
        height: '100%',
        zoom: 10
    });
    const [isCurrent, setIsCurrent] = useState(true);
    const [openTagUserModalDialog, setOpenTagUserModalDialog] = useState(false);
    const [currentDetectFace, setCurrentDetectFaces] = useState(null);

    useEffect(() => {
        // must wait a bit for a first fetching...
        setTimeout(() => {
            if (
                !ShareContactListSelector.isDetailedFetched &&
                !ShareContactListSelector.isPending
            ) {
                dispatch(ShareContactListsActions.fetchDetailed());
            }
        }, 99);
    }, [
        ShareContactListSelector.isDetailedFetched,
        ShareContactListSelector.isPending,
        dispatch
    ]);

    useEffect(() => {
        if (!media?.meta_data) {
            dispatch(EntryMediaActions.fetchIndividual(media.id)).then(
                (response) => {
                    if (response.payload.status === HTTP_CODES.OK) {
                        media.meta_data = response.payload.data.meta_data;
                    }
                }
            );
        }
    }, [media, dispatch]);

    useEffect(() => {
        if (entryLocation.address !== media.location) {
            const coords =
                entryLocation?.lat || entryLocation?.lng
                    ? `${entryLocation?.lat || ''},${entryLocation.lng || ''}`
                    : '';
            dispatch(
                EntryMediaActions.update(
                    {
                        location: entryLocation.address,
                        meta_data: {
                            ...media.meta_data,
                            location: coords
                        }
                    },
                    media.id
                )
            ).then(() => {
                media.location = entryLocation.address;
                media.meta_data.location = coords;
                setViewPort({
                    ...viewport,
                    address: entryLocation.address,
                    latitude: Number(entryLocation?.lat || ''),
                    longitude: Number(entryLocation.lng || '')
                });
            });
        }
    }, [entryLocation, media, dispatch, viewport]);

    const onOpenLocationDialog = () => {
        dispatch(
            updateUI({
                dialogs: [
                    ...(ui.dialogs || []),
                    widthGeoLocation(
                        AddLocationDialog,
                        null,
                        setEntryLocation,
                        true,
                        false,
                        viewport
                    )
                ]
            })
        );
    };

    const updateDetectFace = useCallback(
        (data) => {
            const newList = detectFaces.map((item) => {
                if (
                    item.coordinates[0] === data.coordinates.y1 &&
                    item.coordinates[0] === data.coordinates.y2 &&
                    item.coordinates[1] === data.coordinates.x3 &&
                    item.coordinates[1] === data.coordinates.x2 &&
                    item.coordinates[2] === data.coordinates.y3 &&
                    item.coordinates[2] === data.coordinates.y4 &&
                    item.coordinates[3] === data.coordinates.x1 &&
                    item.coordinates[3] === data.coordinates.x4
                ) {
                    return {
                        ...item,
                        tag_user_object: data,
                        user: data.user_object
                    };
                }
                return item;
            });

            setDetectFaces(newList);
        },
        [detectFaces]
    );

    const getTaggedUser = useCallback(
        async (obj) => {
            const r = await dispatch(
                TaggedUser.search({
                    emf: media.id,
                    x1: obj.coordinates[3],
                    y1: obj.coordinates[0],
                    x2: obj.coordinates[1],
                    y2: obj.coordinates[0],
                    x3: obj.coordinates[1],
                    y3: obj.coordinates[2],
                    x4: obj.coordinates[3],
                    y4: obj.coordinates[2]
                })
            );
            if (
                r.payload.status === HTTP_CODES.OK &&
                r.payload.data?.results?.length > 0
            ) {
                const tuo = r.payload.data.results[0];
                obj.user = tuo.user_object;
                obj.tag_user_object = tuo;
            }
        },
        [media.id, dispatch]
    );

    /*
    useEffect(() => {
        if (detectFaces.length > 0) {
            const compareFace = (image) => {
                const formData = new FormData();
                formData.append('image', image);
                dispatch(
                    V2PublicUserActions.compare_face(media.user.id, formData)
                ).then((response) => {
                    if (response.payload.status === HTTP_CODES.CREATED) {
                        return response.payload.data;
                    }
                    return;
                });
            };

            const r = (detectFaces || []).map((cropImage) => {
                // const file = DataURIToBlob(cropImage.image);
                // const public_user = compareFace(file);
                //return compareFace(file);
            });
        }
    }, [detectFaces, dispatch, media.id, media.user.id, getTaggedUser]);
    */

    const mapDetectFaces = useCallback(
        (items) => {
            const results = items.map((item) => {
                const obj = {
                    coordinates: item
                };
                getTaggedUser(obj);
                return obj;
            });
            setDetectFaces(results);
        },
        [getTaggedUser]
    );

    useEffect(() => {
        if (media.type === 'image' && detectFaces.length < 1) {
            if (media?.meta_data?.detected_faces) {
                mapDetectFaces(media?.meta_data?.detected_faces);
            } else {
                dispatch(
                    EntryMediaActions.detect_faces(media.image || media.file)
                ).then((res) => {
                    if (res.payload.status === 200) {
                        mapDetectFaces(res.payload.data.result);
                        dispatch(
                            EntryMediaActions.update(
                                {
                                    meta_data: {
                                        ...media.meta_data,
                                        detected_faces: res.payload.data.result
                                    }
                                },
                                media.id
                            )
                        );
                    }
                });
            }
        }
    }, [media, dispatch, detectFaces.length, mapDetectFaces]);

    useEffect(() => {
        (detectFaces || []).map(async (item) => {
            if (!item?.image) {
                const crop = {
                    x: item.coordinates[3],
                    y: item.coordinates[0],
                    width: Math.abs(
                        parseInt(item.coordinates[1]) -
                            parseInt(item.coordinates[3])
                    ),
                    height: Math.abs(
                        parseInt(item.coordinates[0]) -
                            parseInt(item.coordinates[2])
                    )
                };
                item.image = await getCroppedImg(
                    media.image || media.file,
                    crop
                );
            }
        });
    }, [detectFaces, media]);

    const onClose = useCallback(() => {
        history.push(URLS.MEDIA.ROOT);
        closeDialog();
    }, [history]);

    const onTagUserClose = useCallback(() => {
        setCurrentDetectFaces(null);
        setOpenTagUserModalDialog(false);
    }, []);

    const pickPicture = useCallback(async (detectFace) => {
        setCurrentDetectFaces(detectFace);
        setOpenTagUserModalDialog(true);
    }, []);

    const setImageSizeFunc = useCallback(
        ({ target: img }) => {
            const imgHeight = img.offsetHeight;
            const imgWidth = img.offsetWidth;
            setImageSize({
                width: imgWidth,
                height: imgHeight
            });
            const rImg = new Image();
            rImg.addEventListener('load', function () {
                setRatio({
                    wRatio: imgWidth / this.naturalWidth,
                    hRatio: imgHeight / this.naturalHeight
                });
            });
            rImg.src = media.image || media.file;
        },
        [media]
    );

    const renderDetectFace = useCallback(() => {
        return (
            imageSize.width !== 0 &&
            detectFaces.map((item, key) => {
                const circleData = getInfoForCircle(item.coordinates, ratio);
                const txt = item?.user
                    ? item?.user?.short_public_display_name
                    : 'Who is this person?';
                const txtLen = txt.length;
                const txtRectW = Math.max(
                    154,
                    2 * circleData.radius,
                    8.1 * txtLen
                );
                const txtX = circleData.cx - 4 * txtLen;
                const txtY_ = circleData.cy + circleData.radius + 10;
                const txtY =
                    txtY_ < imageSize.height
                        ? txtY_
                        : circleData.cy - circleData.radius - 50;
                const txtCls = item?.user
                    ? 'media-detect-face-text'
                    : 'media-detect-face-text unknown';
                return (
                    <Fragment key={key}>
                        <circle
                            className="media-detect-face-img"
                            cx={circleData.cx}
                            cy={circleData.cy}
                            r={circleData.radius}
                            onClick={() => pickPicture(item)}
                        />
                        <rect
                            className="media-detect-face-wrapper"
                            x={circleData.cx - txtRectW / 2}
                            y={txtY}
                            width={txtRectW}
                            height="32"
                            rx="6"
                            ry="6"
                            onClick={() => pickPicture(item)}
                        />
                        <text
                            className={txtCls}
                            x={txtX + 4}
                            y={txtY + 22}
                            onClick={() => pickPicture(item)}>
                            {txt}
                        </text>
                    </Fragment>
                );
            })
        );
    }, [detectFaces, pickPicture, imageSize, ratio]);

    const renderMedia = useCallback(() => {
        const tagClassName = 'tag-content d-none';
        switch (media.type) {
            case 'image': {
                return (
                    <>
                        <div className="position-relative img-container">
                            <span className={tagClassName} />
                            <img
                                alt="media"
                                onLoad={setImageSizeFunc}
                                style={{
                                    maxHeight: '85vh',
                                    height: 'auto'
                                }}
                                src={media.image || media.file}
                            />
                        </div>
                        <svg
                            width={imageSize.width}
                            height={imageSize.height}
                            viewBox={`0 0 ${imageSize.width} ${imageSize.height}`}
                            id="draw"
                            className="face_detection_svg"
                            xmlns="http://www.w3.org/2000/svg">
                            {renderDetectFace()}
                            <g id="boxes"></g>
                        </svg>
                    </>
                );
            }
            case 'video': {
                return <DisplayVideo file={media.file} />;
            }
            case 'audio': {
                return <DisplayAudio file={media.file} />;
            }
            case 'file': {
                return (
                    <div className="media-file-showcase">
                        <span className="icon-file" />
                        <p className="media-file-desc">
                            File Size ({media.file_size / 1000} MB)
                        </p>
                        <a
                            href={media.file}
                            rel="noopener noreferrer"
                            target="_blank"
                            download>
                            <button className="btn btn-download-file">
                                <FaBookOpen />
                                <span>Download File</span>
                            </button>
                        </a>
                    </div>
                );
            }
            default:
                return null;
        }
    }, [media, imageSize, renderDetectFace, setImageSizeFunc]);

    const onOpenAddToEntryDialog = () => {
        setOpenAddToEntryDialog(true);
    };

    useEffect(() => {
        dispatch(EntryActions.fetchMediaEntry(media.id));
    }, [media, dispatch]);

    const onOpenWithThisImageDialog = useCallback(() => {
        setOpenWithThisImageDialog(true);
    }, []);

    useEffect(() => {
        moment.updateLocale('en', {
            relativeTime: {
                future: '%s',
                past: '%s',
                s: '1m',
                ss: '1m',
                m: '1m',
                mm: '%dm',
                h: '1h',
                hh: '%dh',
                d: '1d',
                dd: '%dd',
                M: '1mo',
                MM: '%dmo',
                y: '1y',
                yy: '%dy'
            }
        });
    }, []);

    useEffect(() => {
        const documents = document.querySelectorAll('q');
        for (const element of documents) {
            if (!element.getAttribute('data-binded')) {
                element.setAttribute('data-binded', 'true');
                const caption = element.getAttribute('caption');
                setContent(caption);
            }
        }
    }, []);

    useEffect(() => {
        setContent(media.content);
    }, [media.content]);

    useEffect(() => {
        if (media?.meta_data?.location) {
            const mediaLocation = media?.meta_data?.location.split(',');
            setViewPort((prevState) => {
                return {
                    ...prevState,
                    address: media.location,
                    latitude: Number(mediaLocation[0]),
                    longitude: Number(mediaLocation[1])
                };
            });
        }
    }, [media]);

    const renderMediaEntry = useCallback(() => {
        const entryDateConverter = (entryDate) => {
            return moment.utc(entryDate).local().format('MMM D, YYYY, h:mma');
        };
        if (mediaEntryList?.mediaEntries.length < 1) {
            return <div></div>;
        }
        const mediaList = mediaEntryList.mediaEntries.slice(0, 2);
        return mediaList.map((mediaEle, index) => {
            return (
                <div className="media-entry" key={index}>
                    <div className="media-entry-header">
                        <img
                            src={media.user.public_avatar_image_url}
                            alt="avatar"
                        />
                        <div className="media-entry-header-content">
                            <h3 className="media-entry-title">
                                <span>
                                    {media.user.short_public_display_name}
                                </span>{' '}
                                in {mediaEle?.journal?.title}
                            </h3>
                            <p className="media-entry-info">
                                {moment
                                    .utc(mediaEle?.entry_date)
                                    .local()
                                    .fromNow()}{' '}
                                {entryDateConverter(mediaEle?.entry_date)}
                            </p>
                        </div>
                    </div>
                    <div className="media-entry-content">
                        <h3 className="media-entry-content-title">
                            {mediaEle?.title}
                        </h3>
                        <div className="media-entry-content-text">
                            {renderSummaryText(
                                mediaEle?.content,
                                true,
                                mediaEle?.id
                            ) || ''}
                        </div>
                    </div>
                    <div className="media-entry-actions">
                        <div className="media-entry-comments">
                            <span className="media-icon-comments icon-comments" />
                            {mediaEle?.comments_count === 0 ? (
                                <span>No comment</span>
                            ) : (
                                <span>{mediaEle?.comments_count}</span>
                            )}
                        </div>
                        <button className="btn btn-sharing">
                            <span className="fa fa-share-alt blue-color" />
                        </button>
                    </div>
                </div>
            );
        });
    }, [mediaEntryList.mediaEntries, media.user]);

    const onSaveDescription = useCallback(() => {
        setIsEditing(!isEditing);
        dispatch(EntryMediaActions.update({ content }, media.id));
    }, [isEditing, content, dispatch, media]);

    const onExit = useCallback(() => {
        onClose();
        history.push(URLS.MEDIA.ROOT);
    }, [history, onClose]);

    return (
        <section className="media-viewer-section" id="media-viewer-section">
            {openTagUserModalDialog && (
                <AddTagUser
                    contacts={contacts}
                    detectFace={currentDetectFace}
                    onUpdate={updateDetectFace}
                    media={media}
                    open={true}
                    onClose={onTagUserClose}
                />
            )}
            <div className="container-fluid">
                <div className="row justify-content-between align-items-center">
                    <div className="col-12 col-md-6 col-xl-9 media-fullscreen">
                        {renderMedia()}
                    </div>
                    <div
                        className="col-12 col-md-6 col-xl-3 media-sidebar"
                        style={{
                            paddingTop: 0
                        }}>
                        <div
                            className={`${
                                media.type === 'image' ? '' : 'btn-right'
                            } media-sidebar-header`}>
                            {media.type === 'image' && (
                                <Fragment>
                                    <div className="add__entry">
                                        {/* <BsTag
                                            className="tag-icon"
                                            onClick={() =>
                                                setToggleTag(!toggleTag)
                                            }
                                            style={
                                                toggleTag && {
                                                    color:
                                                        'var(--primary-color)'
                                                }
                                            }
                                        /> */}
                                        <button
                                            className="btn btn-add-to-entry"
                                            onClick={onOpenAddToEntryDialog}>
                                            Add to an entry
                                            <span className="fa fa-plus-circle" />
                                        </button>
                                    </div>
                                    {/* <div>
                                        <button
                                            className="btn btn-add-to-entry"
                                            onClick={onOpenWithThisImageDialog}>
                                            Entries With This Image
                                            <span className="fas fa-info-circle" />
                                        </button>
                                    </div> */}
                                </Fragment>
                            )}
                            <div>
                                <button
                                    className="btn btn-exit-media"
                                    onClick={onExit}>
                                    <small>
                                        <span className="icon-times-light"></span>
                                    </small>
                                </button>
                            </div>
                        </div>

                        {renderMediaEntry()}

                        {mediaEntryList?.mediaEntries?.length > 1 && (
                            <button
                                className="btn various-entries"
                                onClick={onOpenWithThisImageDialog}>
                                {' '}
                                +{mediaEntryList?.mediaMeta.total_count}{' '}
                                {mediaEntryList?.mediaMeta.total_count === 1
                                    ? 'entry'
                                    : 'entries'}
                            </button>
                        )}

                        <div className="media-desc-section">
                            <div className="media-sidebar-header-content justify-content-between">
                                <h3 className="media-sidebar-title">
                                    Description
                                </h3>
                                {isEditing ? (
                                    <button
                                        className="btn btn-edit-media"
                                        onClick={onSaveDescription}>
                                        <>
                                            Save
                                            <span className="icon-edit"></span>
                                        </>
                                    </button>
                                ) : (
                                    <button
                                        className="btn btn-edit-media"
                                        onClick={() =>
                                            setIsEditing(!isEditing)
                                        }>
                                        <>
                                            Edit
                                            <span className="icon-edit"></span>
                                        </>
                                    </button>
                                )}
                            </div>
                            {isEditing ? (
                                <textarea
                                    className="media-input-desc"
                                    name="mediaInputDesc"
                                    placeholder="Add a description"
                                    value={content}
                                    onChange={(e) => setContent(e.target.value)}
                                />
                            ) : (
                                <p className="media-desc-content">{content}</p>
                            )}
                        </div>

                        {media.type === 'image' && detectFaces && (
                            <div className="media-desc-section">
                                <div className="media-sidebar-header-content justify-content-between">
                                    <h3 className="media-sidebar-title">
                                        People
                                    </h3>
                                </div>
                                <div className="crop_image_list">
                                    {detectFaces.map((item, index) => (
                                        <div
                                            className="circle_image_text"
                                            key={index}
                                            style={{
                                                display:
                                                    item?.user || item?.image
                                                        ? 'block'
                                                        : 'none'
                                            }}>
                                            <img
                                                alt=""
                                                src={
                                                    item?.user?.show_avatar
                                                        ? item?.user
                                                              ?.public_avatar_image_url
                                                        : item?.image
                                                }
                                                width="60"
                                                height="60"
                                                className="circle_image"
                                                onClick={() =>
                                                    pickPicture(item)
                                                }
                                            />
                                            <p>
                                                {item?.user
                                                    ?.short_public_display_name ||
                                                    ''}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        <div className="media-location-section">
                            <div className="media-sidebar-header-content justify-content-between">
                                <h3 className="media-sidebar-title">
                                    Location
                                    <span className="location-unknown">
                                        {media.location || 'Unknown'}
                                    </span>
                                </h3>
                                <button
                                    className="btn btn-add-location"
                                    onClick={onOpenLocationDialog}>
                                    {media.location !== '' ? (
                                        <Fragment>
                                            Edit Location
                                            <span className="icon-edit" />
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            Add Location
                                            <span className="fa fa-plus-circle" />
                                        </Fragment>
                                    )}
                                </button>
                            </div>
                            {media.meta_data?.location && (
                                <div className="media_map_view">
                                    <MapboxContainer
                                        showOnly={true}
                                        viewport={viewport}
                                        setViewPort={setViewPort}
                                        isCurrent={isCurrent}
                                        setIsCurrent={setIsCurrent}
                                    />
                                </div>
                            )}
                        </div>

                        {media?.meta_data && <MediaDetails media={media} />}
                        <AddToEntry
                            // @ts-ignore
                            open={openAddToEntryDialog}
                            media={media}
                            onClose={() => setOpenAddToEntryDialog(false)}
                        />
                        <EntriesWithThisImage
                            open={openWithThisImageDialog}
                            media={media.id}
                            onClose={() => setOpenWithThisImageDialog(false)}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

const state = createStructuredSelector({
    ShareContactListSelector,
    contacts: getShareContactList,
    mediaEntryList: getMediaEntries,
    ui: getUI
});

export default connect(state)(MediaViewer);
