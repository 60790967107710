import { URLS } from 'common/urls';
import buildV2Authorization from 'common/utils/buildV2Authorization';
import { UserSelector } from 'redux/selectors/UserSelector';
import {
    TAGGED_USER_CREATE,
    TAGGED_USER_FETCH,
    TAGGED_USER_FETCH_INDIVIDUAL,
    TAGGED_USER_SEARCH,
    TAGGED_USER_UPDATE,
    TAGGED_USER_DELETE,
    TAGGED_USER_ONLY_ALL_FETCH
} from 'redux/actions/actionTypes';
import { makeDispatch } from 'redux/utils';

export const create = (data) => (dispatch, getState) => {
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);

    return dispatch({
        type: TAGGED_USER_CREATE,
        payload: {
            request: {
                url: URLS.API.MEDIA.TAG_USER,
                method: 'POST',
                data,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const userOnlyAll = () => (dispatch, getState) => {
    return makeDispatch({
        data: null,
        dispatch,
        method: 'GET',
        params: null,
        state: getState(),
        type: TAGGED_USER_ONLY_ALL_FETCH,
        url: URLS.API.MEDIA.TAG_USER_ALL
    });
};

export const fetchAll = (pageUrl) => (dispatch, getState) => {
    const url = pageUrl || URLS.API.MEDIA.TAG_USER;
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);
    return dispatch({
        type: TAGGED_USER_FETCH,
        payload: {
            request: {
                url,
                method: 'GET',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const fetchIndividual = (id) => (dispatch, getState) => {
    const url = `${URLS.API.MEDIA.TAG_USER}${id}/`;
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);
    return dispatch({
        type: TAGGED_USER_FETCH_INDIVIDUAL,
        payload: {
            request: {
                url,
                method: 'GET',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};
export const search = (params, pageURL) => (dispatch, getState) => {
    const url = pageURL || URLS.API.MEDIA.TAG_USER;
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);
    return dispatch({
        type: TAGGED_USER_SEARCH,
        payload: {
            request: {
                url,
                method: 'GET',
                params,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const update = (options, id, objURL) => (dispatch, getState) => {
    const url = objURL || `${URLS.API.MEDIA.TAG_USER}${id}/`;
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);
    return dispatch({
        type: TAGGED_USER_UPDATE,
        payload: {
            request: {
                url,
                method: 'PATCH',
                data: options,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const deleteUser = (id) => (dispatch, getState) => {
    const url = `${URLS.API.MEDIA.TAG_USER}${id}/`;
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);
    return dispatch({
        type: TAGGED_USER_DELETE,
        payload: {
            request: {
                url,
                method: 'DELETE',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};
