import buildAuthorization from 'common/utils/buildAuthorization';
import { UserSelector } from 'redux/selectors/UserSelector';
import {
    FETCH_ADDITIONAL_RESULTS,
    FETCH_RESULTS,
    SET_SEARCH_LOADINGPROGRESS,
    UPDATE_QUERY,
    RESET_SEARCH_PAGE
} from 'redux/actions/actionTypes';
import { ENTRY_SEARCH_URL } from 'common/constant';
import { FiltersSelector } from 'redux/selectors/FiltersSelector';

export const updateQuery = (newValue) => (dispatch, getState) => {
    return dispatch({
        type: UPDATE_QUERY,
        payload: {
            value: newValue
        }
    });
};

export const searchEntries = (options = {}) => (dispatch, getState) => {
    const url = ENTRY_SEARCH_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);
    const { journal: journalId } = FiltersSelector(getState());
    if (journalId) {
        options['journal'] = journalId;
    }
    return dispatch({
        type: FETCH_RESULTS,
        payload: {
            request: {
                url,
                method: 'GET',
                params: options,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const page = (page) => (dispatch, getState) => {
    const url = page;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: FETCH_ADDITIONAL_RESULTS,
        payload: {
            request: {
                url,
                method: 'GET',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const setLoadingProgress = () => (dispatch, getState) => {
    return dispatch({
        type: SET_SEARCH_LOADINGPROGRESS
    });
};

export const reset_search_page = () => (dispatch) => {
    return dispatch({
        type: RESET_SEARCH_PAGE
    });
};
