import React, { useCallback, useEffect, useState, useRef } from 'react';
// Actions
import { updateUI } from 'redux/actions/ui';
import * as FilterActions from 'redux/actions/filter';
// Selector
import { getUser, getUserHeaderColor } from 'redux/selectors/UserSelector';
// Redux
// @ts-ignore
import { connect } from 'react-redux';
// Router
// @ts-ignore
import { Link, useHistory, useLocation } from 'react-router-dom';
// Dialogs
import { createStructuredSelector } from 'reselect';
import { getLoadingProgress, getNotifications, getUI } from 'redux/selectors';
import { clearLoadingProgress } from 'redux/actions/loading';
import NewJournalDialog from 'pages/Dialogs/NewJournalDialog';
import NewTagDialog from 'pages/Dialogs/NewTagDialog';
// Components
import { Avatar } from '@material-ui/core';
// Constants
import { URLS } from 'common/urls';
// images
import defaultUser from 'assets/images/default-user.jpg';
import { HEADER_COLORS } from 'common/constant';
import iconTimeline from 'assets/images/icons_svg/icon_timeline.svg';
import iconJournal from 'assets/images/icons_svg/icon_journal.svg';
import iconBooks from 'assets/images/icons_svg/icon_books.svg';
import iconAAM from 'assets/images/icons_svg/icon_all-about-me.svg';
import iconContacts from 'assets/images/icons_svg/icon_contacts.svg';
import iconSearch from 'assets/images/icons_svg/icon_search.svg';
import iconNotifications from 'assets/images/icons_svg/icon_notifications.svg';
import iconAdd from 'assets/images/icons_svg/icon_add.svg';
import iconMenu from 'assets/images/icons_svg/icon_menu.svg';
// import iconEdit from 'assets/images/icons_svg/icon_edit.svg';
// import iconFilters from 'assets/images/icons_svg/icon_filters.svg';
import iconNewEntry from 'assets/images/icons_svg/icon_new-entry.svg';
import iconTags from 'assets/images/icons_svg/icon_tag.svg';
// import iconMedia from 'assets/images/media/icon_media.svg';
import withFreeAccountCheck from 'common/utils/components/withFreeAccountCheck';
import NotificationsList from 'components/notifications_list';
// import _ from 'underscore';
import { setSettgsSidebarMobileStatus } from 'redux/actions/settings_page';

import iconAngleLeft from 'assets/images/icons_svg/icon_angle-left.svg';

const AuthHeader = ({
    user,
    dispatch,
    ui,
    loadingProgress,
    // @ts-ignore
    userHeaderColor,
    notifications
}) => {
    const [activeTab, setActiveTab] = useState(0);
    const [openAddSettings, setOpenAddSettings] = useState(false);
    const [openNotification, setOpenNotification] = useState(false);
    const [openMore, setOpenMore] = useState(false);

    // location
    const location = useLocation();
    const history = useHistory();
    const notificationsRef = useRef();
    const openMoreRef = useRef();
    const addMenuRef = useRef();
    // clear loading progress bar
    useEffect(() => {
        if (
            parseInt(loadingProgress) === 100 &&
            ui.showingProgressBar === true
        ) {
            dispatch(clearLoadingProgress());
            setTimeout(() => {
                dispatch(
                    updateUI({
                        showingProgressBar: false
                    })
                );
            }, 1500);
        }
    }, [loadingProgress, dispatch, ui.showingProgressBar]);

    useEffect(() => {
        switch (true) {
            case location.pathname === URLS.HOME: {
                return setActiveTab(0);
            }
            case location.pathname.includes(URLS.AAM.ROOT): {
                return setActiveTab(1);
            }
            case location.pathname.includes(URLS.JOURNAL):
            case location.pathname.includes(URLS.TAG): {
                return setActiveTab(2);
            }
            case location.pathname.includes(URLS.BOOK.ROOT):
            case location.pathname.includes(URLS.BOOK.PREVIEW):
            case location.pathname.includes(URLS.BOOK.ORDERS): {
                return setActiveTab(3);
            }
            case location.pathname.includes(URLS.CONTACTS.ROOT): {
                return setActiveTab(4);
            }
            case location.pathname.includes(URLS.MEDIA.ROOT): {
                return setActiveTab(5);
            }
            case location.pathname.includes(URLS.SETTINGS.ROOT): {
                return setActiveTab(-1);
            }
            default: {
                return setActiveTab(0);
            }
        }
    }, [location.pathname]);

    // handlers
    const onTimelineNavigate = useCallback(() => {
        // clear filters
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        dispatch(FilterActions.reset());
        dispatch(
            updateUI({
                donationView: false
            })
        );
    }, [dispatch]);

    const goToSearch = useCallback(() => {
        history.push(URLS.SEARCH);
    }, [history]);

    const activeTabStyle = (value) => {
        return `header-nav__item ${activeTab === value ? 'active' : ''}`;
    };

    const showAddSettings = useCallback(() => {
        openNotification && setOpenNotification(false);
        setOpenAddSettings(!openAddSettings);
    }, [openAddSettings, openNotification]);

    useEffect(() => {
        setOpenAddSettings(Boolean(ui.onBoardingAddMenu));
    }, [ui.onBoardingAddMenu, openNotification]);

    useEffect(() => {
        setOpenMore(Boolean(ui.onBoardingMoreMenu));
    }, [ui.onBoardingMoreMenu]);

    const showNotification = useCallback(() => {
        openAddSettings && setOpenAddSettings(false);
        setOpenNotification(!openNotification);
    }, [openNotification, openAddSettings]);

    const showMore = useCallback(() => {
        setOpenMore(!openMore);
    }, [openMore]);

    const openCreateEntry = useCallback(() => {
        history.push(URLS.ENTRY.CREATE);
    }, [history]);

    const openCreateJournal = useCallback(() => {
        dispatch(
            updateUI({
                dialogs: [withFreeAccountCheck(NewJournalDialog)]
            })
        );
    }, [dispatch]);

    const openCreateTag = useCallback(() => {
        dispatch(
            updateUI({
                dialogs: [NewTagDialog]
            })
        );
    }, [dispatch]);

    const openCreateRandomQuestion = useCallback(() => {
        history.push(URLS.AAM.RANDOM_QUESTION);
    }, [history]);

    // render notification dropdown

    const [showNotificationBadge, setShowNotificationBadge] = useState(false);
    const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);

    // get unread notification count
    const getUnreadNotificationCount = useCallback(() => {
        const unreadNotifications = notifications.filter(
            (item) => !item?.data?.read
        );

        return (unreadNotifications || []).length;
    }, [notifications]);

    useEffect(() => {
        const count = getUnreadNotificationCount();
        setUnreadNotificationCount(count);
        if (count > 0) {
            setShowNotificationBadge(true);
        } else {
            setShowNotificationBadge(false);
        }
    }, [notifications, getUnreadNotificationCount]);

    const navbarClassName = `navbar-app ${
        HEADER_COLORS[user.header_color]?.navBarClassName
    }`;

    const handleOutsideClick = (e) => {
        // @ts-ignore
        if (notificationsRef?.current?.contains(e.target)) {
            return;
        }

        setOpenNotification(false);
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleOutsideClickMore = (e) => {
        // @ts-ignore
        if (openMoreRef?.current?.contains(e.target)) {
            return;
        }
        setOpenMore(false);
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClickMore);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClickMore);
        };
    }, []);

    const handleOutsideClickAdd = (e) => {
        // @ts-ignore
        if (addMenuRef?.current?.contains(e.target)) {
            return;
        }
        setOpenAddSettings(false);
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClickAdd);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClickAdd);
        };
    }, []);

    const logo = HEADER_COLORS[user.header_color]?.logo;

    return (
        <header className={navbarClassName}>
            <nav className="header-nav">
                <ul>
                    <li className="header-logo">
                        <button onClick={onTimelineNavigate}>
                            <Link to={URLS.TIMELINE}>
                                <img src={logo} alt="JRNL logo" title="JRNL" />
                            </Link>
                        </button>
                    </li>
                    {ui.donationView && (
                        <>
                            <li className="spacer"></li>
                            <li
                                style={{ float: 'left' }}
                                className="header-nav__link">
                                <Link
                                    className="header-nav__link"
                                    to={URLS.TIMELINE}>
                                    <img
                                        className="wrapped-settings__angle-left"
                                        src={iconAngleLeft}
                                        alt=""
                                    />

                                    <span className="header-nav__title">
                                        Back
                                    </span>
                                </Link>
                            </li>
                        </>
                    )}
                    {!ui.donationView && (
                        <>
                            <li className="spacer"></li>
                            <li
                                className={activeTabStyle(0)}
                                onClick={onTimelineNavigate}>
                                <Link
                                    className="header-nav__link"
                                    to={URLS.TIMELINE}>
                                    <img
                                        src={iconTimeline}
                                        alt="Timeline"
                                        title="Timeline"
                                    />
                                    <span className="header-nav__title">
                                        Timeline
                                    </span>
                                </Link>
                            </li>
                            <li className={activeTabStyle(2)}>
                                <Link
                                    className="header-nav__link"
                                    to={URLS.JOURNAL}>
                                    <img
                                        src={iconJournal}
                                        alt="Journals"
                                        title="Journals"
                                    />
                                    <span className="header-nav__title">
                                        Journals
                                    </span>
                                </Link>
                            </li>
                            <li className={activeTabStyle(3)}>
                                <Link
                                    className="header-nav__link"
                                    to={URLS.BOOK.ROOT}>
                                    <img
                                        src={iconBooks}
                                        alt="Books"
                                        title="Books"
                                    />
                                    <span className="header-nav__title">
                                        Books
                                    </span>
                                </Link>
                            </li>
                            <li
                                className="header-nav__item header-nav__item--notifications"
                                ref={notificationsRef}
                                onClick={showNotification}>
                                <button className="header-nav__link dropdown-toggle">
                                    <img
                                        src={iconNotifications}
                                        alt="Notifications"
                                        title="Notifications"
                                    />
                                    <span className="header-nav__title">
                                        Notifications
                                    </span>
                                    {showNotificationBadge && (
                                        <span className="badge">
                                            {unreadNotificationCount}
                                        </span>
                                    )}
                                </button>

                                <NotificationsList
                                    notifications={notifications}
                                    openNotification={openNotification}
                                />
                            </li>
                            <li
                                className="header-nav__item header-nav__item--more"
                                onClick={showMore}
                                ref={openMoreRef}>
                                <button className="header-nav__link ">
                                    <img
                                        src={iconMenu}
                                        alt="More"
                                        title="More"
                                        id="more-img"
                                    />
                                </button>

                                <div
                                    className={`dropdown-menu dropdown--more ${
                                        openMore ? 'show' : ''
                                    }`}>
                                    <ul>
                                        <li
                                            className={activeTabStyle(1)}
                                            onClick={onTimelineNavigate}>
                                            <Link
                                                className="header-nav__link"
                                                to={URLS.AAM.ROOT}>
                                                <img
                                                    src={iconAAM}
                                                    alt="All About Me"
                                                    title="All About Me"
                                                />
                                                <span className="header-nav__title">
                                                    All About Me
                                                </span>
                                            </Link>
                                        </li>
                                        <li className={activeTabStyle(4)}>
                                            <Link
                                                className="header-nav__link"
                                                to={URLS.CONTACTS.ROOT}>
                                                <img
                                                    src={iconContacts}
                                                    alt="Contacts"
                                                    title="Contacts"
                                                />
                                                <span className="header-nav__title">
                                                    Contacts
                                                </span>
                                            </Link>
                                        </li>

                                        {/* Media page link will be commented out for now, since it hasen't been fully implemented */}
                                        {/* <li className={activeTabStyle(5)}>
                                            <Link
                                                className="header-nav__link"
                                                to={URLS.MEDIA.ROOT}>
                                                <img
                                                    src={iconMedia}
                                                    alt="Media"
                                                    title="Media"
                                                />
                                                <span className="header-nav__title">
                                                    Media
                                                </span>
                                            </Link>
                                        </li> */}
                                        <li className="header-nav__item header-nav__item--settings">
                                            <Link
                                                to={URLS.SETTINGS.ROOT}
                                                onClick={() => {
                                                    dispatch(
                                                        setSettgsSidebarMobileStatus(
                                                            true
                                                        )
                                                    );
                                                }}
                                                className="header-nav__link">
                                                <img
                                                    src={
                                                        user?.avatar_image_url ||
                                                        defaultUser
                                                    }
                                                    alt="User Avatar"
                                                />
                                                <span className="header-nav__title">
                                                    Settings
                                                </span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            <li className="spacer" />
                            <li
                                ref={addMenuRef}
                                className="nav__tools nav__tools--icon nav__tools--add"
                                id="add-menu-button">
                                <button onClick={showAddSettings}>
                                    <img
                                        src={iconAdd}
                                        alt="Add Menu"
                                        title="Add Menu"
                                        id="add-menu-img"
                                    />
                                </button>
                                <div
                                    className={`dropdown-menu dropdown-menu--add ${
                                        openAddSettings ? 'show' : ''
                                    }`}>
                                    <ul>
                                        <li onClick={openCreateEntry}>
                                            <img
                                                src={iconNewEntry}
                                                alt="New Entry"
                                                title="New Entry"
                                            />
                                            New Entry
                                        </li>

                                        <li onClick={openCreateJournal}>
                                            <img
                                                src={iconJournal}
                                                alt="New Journal"
                                            />
                                            New Journal
                                        </li>

                                        <li onClick={openCreateTag}>
                                            <img src={iconTags} alt="New Tag" />
                                            New Tag
                                        </li>

                                        <li onClick={openCreateRandomQuestion}>
                                            <img
                                                src={iconAAM}
                                                alt="New Random Question"
                                            />
                                            New Random Question
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="nav__tools nav__tools--icon nav__tools--search">
                                <button onClick={goToSearch}>
                                    <img
                                        src={iconSearch}
                                        alt="Search"
                                        title="Search"
                                    />
                                </button>
                            </li>
                            <li className="nav__tools nav__tools--settings">
                                <Link
                                    to={URLS.SETTINGS.PROFILE}
                                    className="header-profile">
                                    <Avatar
                                        alt="User Avatar"
                                        src={
                                            user?.avatar_image_url ||
                                            defaultUser
                                        }
                                    />
                                </Link>
                            </li>
                        </>
                    )}
                </ul>
            </nav>
        </header>
    );
};

const state = createStructuredSelector({
    user: getUser,
    loadingProgress: getLoadingProgress,
    ui: getUI,
    userHeaderColor: getUserHeaderColor,
    notifications: getNotifications
});
export default React.memo(connect(state)(AuthHeader));
