import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
// firebase
import { db, FIREBASE_DB_ROOT, uid } from 'common/firebase';
// icon book finished
import iconBookFinished from 'assets/images/timeline-images/finished-book.svg';
// constant
import { URLS } from 'common/urls';
import { getUser } from 'redux/selectors';
import { getDifferenceDateStr } from 'common/utils/dateUtils';
import moment from 'moment';

const PDFReadyNotification = ({
    data,
    user: authUser,
    notificationKey,
    fullView
}) => {
    const { read, book_id, created_at } = data;
    const history = useHistory();

    const showPdfPreview = () => {
        // update the notification read
        db.ref(
            `${FIREBASE_DB_ROOT}${uid}/${authUser.id}/${notificationKey}`
        ).set({
            ...data,
            read: true
        });

        // navigate to pdf-preview page
        history.push(`/book-preview/${book_id}`);
    };
    return (
        <li className="alert-card__item alert-card__item--buttons">
            <div className="alert-card__img">
                <img src={iconBookFinished} alt="book-finished" />
            </div>
            <div className="alert-card__content">
                Your JRNL Book is ready! You can now check it
                <div className="alert-card__actions">
                    <button
                        onClick={() => showPdfPreview()}
                        className="alert-card__btn">
                        View Book
                    </button>
                    <Link to={URLS.BOOK.ROOT}>
                        <button className="alert-card__btn">Go to Books</button>
                    </Link>
                </div>
            </div>
            {!read && (
                <div className="notification-active-bullet-dropdown"></div>
            )}
            {fullView && created_at && (
                <p className="notification-timestamp ml-auto">
                    {getDifferenceDateStr(
                        moment.utc(created_at).local(),
                        moment(new Date())
                    )}
                </p>
            )}
        </li>
    );
};

const state = createStructuredSelector({
    user: getUser
});
export default connect(state)(PDFReadyNotification);
