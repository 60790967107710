import React, { Fragment, useCallback } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// Hooks
import { useHistory } from 'react-router';
// Selectors
import { getUser } from 'redux/selectors';
// firebase
import { db, FIREBASE_DB_ROOT, uid } from 'common/firebase';
// images
import defaultUser from 'assets/images/default-user.jpg';
import { updateUI } from 'redux/actions/ui';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import { Avatar } from '@material-ui/core';
import { getDifferenceDateStr } from 'common/utils/dateUtils';
import moment from 'moment';

import { openDialog } from 'common/utils/dialog-utils';
import EnableSharingConfirmationDialog from 'pages/Dialogs/SettingsDialog/EnableSharingConfirmationDialog';

const EntrySharingNotification = ({
    notificationKey,
    data,
    user: authUser,
    dispatch,
    type,
    fullView
}) => {
    const [publicUser, setPublicUser] = React.useState();
    const { user, unique_id, read, created_at } = data;
    const history = useHistory();

    // show entry
    const showEntry = useCallback(
        (add = true) => {
            // update the notification read
            db.ref(
                `${FIREBASE_DB_ROOT}${uid}/${authUser.id}/${notificationKey}`
            ).set({
                ...data,
                read: true
            });

            dispatch(
                updateUI({
                    selectedNotification: data
                })
            );
            if (!authUser.sharing && add === true) {
                openDialog(EnableSharingConfirmationDialog, {
                    invitationId: unique_id
                });
            } else {
                // navigate to show entry page
                history.push(
                    `/app/invitation/${unique_id}${add ? '?add=true' : ''}`
                );
            }
        },
        [history, unique_id, authUser, data, notificationKey, dispatch]
    );

    useDeepCompareEffectNoCheck(() => {
        setPublicUser(data.user);
    }, [data.user]);

    return (
        <Fragment>
            <li className="alert-card__item alert-card__item--buttons">
                <div className="alert-card__img">
                    <Avatar
                        src={
                            (publicUser && publicUser.avatar_image_url) ||
                            defaultUser
                        }
                        alt=""
                    />
                </div>
                <div className="alert-card__content">
                    <span className="alert-card__name">
                        {user?.public_display_name}{' '}
                    </span>
                    has shared {type === 'entry_share' ? 'entry' : 'journal'}{' '}
                    with you
                    <div className="alert-card__actions">
                        <button
                            onClick={() => showEntry(false)}
                            className="alert-card__btn">
                            View
                        </button>
                        <button
                            onClick={() => showEntry(true)}
                            className="alert-card__btn">
                            Add+
                        </button>
                    </div>
                </div>
                {!read && (
                    <div className="notification-active-bullet-dropdown"></div>
                )}
                {fullView && created_at && (
                    <p className="notification-timestamp ml-auto">
                        {getDifferenceDateStr(
                            moment.utc(created_at).local(),
                            moment(new Date())
                        )}
                    </p>
                )}
            </li>
        </Fragment>
    );
};

const state = createStructuredSelector({
    user: getUser
});
export default connect(state)(EntrySharingNotification);
