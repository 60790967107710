import _ from 'underscore';
import {
    FETCH_PUBLIC_USER,
    FETCH_PUBLIC_USER_LIST,
    ACTION_USER_LOGOUT,
    FETCH_PUBLIC_USERS_LIST
} from 'redux/actions/actionTypes';
import { SUCCESS_SUFFIX } from 'common/constant';

const initialState = {
    public_users: undefined,
    isPending: false,
    public_user_list: []
};

const PublicUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PUBLIC_USERS_LIST: {
            return {
                ...state,
                public_user_list: action.payload
            };
        }
        case FETCH_PUBLIC_USER + SUCCESS_SUFFIX: {
            let public_users = state.public_users;
            const object = action.payload.data;
            const index = public_users?.findIndex(
                (public_user) => object.id === parseInt(public_user.id)
            );
            public_users =
                index >= 0
                    ? [].concat(
                          public_users.slice(0, index),
                          [object],
                          public_users.slice(index + 1)
                      )
                    : [].concat([object], public_users || []);
            return _.extend({}, state, {
                public_users,
                isPending: false
            });
        }

        case FETCH_PUBLIC_USER_LIST + SUCCESS_SUFFIX: {
            let public_users = state.public_users;

            for (const _i in action.payload.data.objects) {
                const object = action.payload.data.objects[_i];

                const index = public_users?.findIndex(
                    (public_user) => object.id === parseInt(public_user.id)
                );
                public_users =
                    index >= 0
                        ? [].concat(
                              public_users.slice(0, index),
                              [object],
                              public_users.slice(index + 1)
                          )
                        : [].concat([object], public_users || []);
            }

            return _.extend({}, state, {
                public_users,
                isPending: false
            });
        }
        case ACTION_USER_LOGOUT: {
            return _.extend({}, initialState);
        }
        default: {
            return state;
        }
    }
};

export default PublicUserReducer;
