import { UserSelector } from 'redux/selectors/UserSelector';
import buildAuthorization from 'common/utils/buildAuthorization';

import {
    FETCH_PAYMENT_PROFILE,
    FETCH_PAYMENT_PROFILE_LIST,
    CREATE_PAYMENT_PROFILE,
    FETCH_USER_PAYMENT_PROFILE,
    DELETE_PAYMENT_PROFILE,
    EDIT_PAYMENT_PROFILE
} from 'redux/actions/actionTypes';
import { PAYMENT_PROFILE_URL, PAYMENT_USER_ME_URL } from 'common/constant';

export const fetch_detail = (options = {}) => (dispatch, getState) => {
    const url = `${PAYMENT_PROFILE_URL}${options.id}`;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: FETCH_PAYMENT_PROFILE,
        payload: {
            request: {
                url,
                method: 'GET',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const fetch = (options = {}) => (dispatch, getState) => {
    const url = PAYMENT_PROFILE_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);
    return dispatch({
        type: FETCH_PAYMENT_PROFILE_LIST,
        payload: {
            request: {
                url,
                method: 'GET',
                params: options,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const fetch_default = (options = {}) => (dispatch, getState) => {
    const url = PAYMENT_USER_ME_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: FETCH_USER_PAYMENT_PROFILE,
        payload: {
            request: {
                url,
                method: 'GET',
                params: options,
                headers: {
                    Authorization: auth
                }
            }
        }
    }).then((response) => {
        if (response?.payload?.status === 200) {
            if (response?.payload?.data?.default_payment_profile) {
                return dispatch({
                    type: FETCH_PAYMENT_PROFILE,
                    payload: {
                        request: {
                            url: response.payload.data.default_payment_profile,
                            method: 'GET',
                            params: options,
                            headers: {
                                Authorization: auth
                            }
                        }
                    }
                });
            }
        }
    });
};

export const create = (data) => (dispatch, getState) => {
    const url = PAYMENT_PROFILE_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: CREATE_PAYMENT_PROFILE,
        payload: {
            request: {
                url,
                method: 'POST',
                data,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const detele_card = (data) => (dispatch, getState) => {
    const url = `${PAYMENT_PROFILE_URL}${data.id}/`;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: DELETE_PAYMENT_PROFILE,
        payload: {
            request: {
                url,
                method: 'DELETE',
                options: {
                    cardId: data.id
                },
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};
export const edit = (id, data) => (dispatch, getState) => {
    const url = `${PAYMENT_PROFILE_URL}${id}/`;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: EDIT_PAYMENT_PROFILE,
        payload: {
            request: {
                url,
                method: 'PATCH',
                data,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};
