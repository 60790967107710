import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { createStructuredSelector } from 'reselect';
import {
    getDefaultPaymentProfile,
    getShippingAddressForBook,
    getUI,
    getUser,
    getBookById,
    isPriceCalculating
} from 'redux/selectors';
// Components
// import 'react-datepicker/dist/react-datepicker.css';
// import { Snackbar } from '@material-ui/core';
// import { Alert } from '@material-ui/lab';
import ShippingAddress from './ShippingAddress.jsx';
import NewCreditCard from './NewCreditCard.jsx';
import ShippingAddressList from './ShippingAddressList.jsx';

// Actions
import { updateUI } from 'redux/actions/ui';
import * as BookActions from 'redux/actions/book';

// import { ZIP_CODE_ERROR } from 'common/constant';
import withBook from 'common/utils/components/withBook';
import BookPreviewDialog from 'pages/Dialogs/BookPreviewDialog';
import { URLS } from 'common/urls';
// import TextField from 'components/TextField';
//import { Zipcode } from 'components/TextField/validators';
// import arrayUtils from 'common/utils/arrayUtils';

// country lists
import countryList from 'react-select-country-list';

// utils
import { openDialog } from 'common/utils/dialog-utils';

// Dialog
import CheckoutSuccessDialog from 'pages/Dialogs/CheckoutSuccessDialog';

import * as BookOrderActions from 'redux/actions/bookorder';
import CreditCardList from './CreditCardList.jsx';
import * as BookShippingAddressActions from 'redux/actions/bookshippingaddress';

// Icons
import iconCloseSm from 'assets/images/icons_svg/icon_close-sm.svg';

// set United States Label to US
countryList().setLabel('US', 'US');

const BookOrderDialog = ({
    book,
    ui,
    paymentProfile,
    dispatch,
    shipping,
    user,
    isPriceCalculating
}) => {
    // const [error, setError] = React.useState(false);
    const [modalClosed, setModalClosed] = React.useState(true);
    const [isAddNewAddress, setIsAddNewAddress] = React.useState(false);
    const [isAddNewPaymentProfile, setIsAddNewPaymentProfile] = React.useState(
        false
    );
    const [bookOrderErrMsg, setBookOrderErrMsg] = React.useState(null);
    const [shippingAddressErrMsg, setShippingAddressErrMsg] = React.useState(
        ''
    );
    const [bookTotalPrice, setBookTotalPrice] = React.useState('');
    const [bookQuantity, setBookQuantity] = React.useState(1);
    const [basePrices, setBasePrices] = React.useState({
        fullPrice: 0,
        discountAmount: 0
    });

    const checkoutRef = React.useRef(null);

    const history = useHistory();

    useEffect(() => {
        setModalClosed(false);
    }, []);

    function calcBasePrice(totalPrice, discount, quantity) {
        const indPrice = parseFloat((totalPrice / quantity).toFixed(2));
        if (discount === 0) {
            return { fullPrice: indPrice, discountAmount: 0 };
        } else {
            if (indPrice === 31.95 && discount === 0.2) {
                return { fullPrice: 39.95, discountAmount: 8 * quantity };
            } else {
                const multiplier = 1 - parseFloat(discount);
                const fullPrice = parseFloat(
                    (totalPrice / multiplier).toFixed(2)
                );
                const indFullPrice = parseFloat(
                    (fullPrice / quantity).toFixed(2)
                );
                return {
                    fullPrice: indFullPrice,
                    discountAmount: fullPrice - totalPrice
                };
            }
        }
    }

    useEffect(() => {
        const bookPrice = book.shipping_cost
            ? parseFloat(book?.total_amount).toFixed(2)
            : parseFloat(book?.price).toFixed(2);
        setBookTotalPrice(bookPrice);
        setBookQuantity(book.quantity);
        setBasePrices(
            calcBasePrice(book?.price, user.prices?.DISCOUNT, book.quantity)
        );
    }, [book, user]);

    const closeModal = () => {
        setTimeout(() => {
            history.push(`${URLS.BOOK.PREVIEW}${book.id}`);
            dispatch(
                updateUI({
                    dialogs: [withBook(BookPreviewDialog, book.id)]
                })
            );
        }, 1000);
        setModalClosed(true);
    };

    const onUpdateBookData = React.useCallback(
        (shipping) => {
            dispatch(updateUI({ shipping }));
            dispatch(
                BookActions.updateAndFetch({
                    id: book?.id,
                    shipping_address: shipping?.resource_uri
                })
            )
                .then((response) => {
                    if (response?.payload?.data) {
                        // scroll down to checkout section
                        if (checkoutRef.current) {
                            // checkoutRef.current.scrollIntoView({
                            //     behavior: 'smooth',
                            //     block: 'start'
                            // });
                        }
                        setShippingAddressErrMsg(null);
                    }
                })
                .catch((error) => {
                    setShippingAddressErrMsg(
                        error.error?.response?.data?.user_message
                    );
                });
        },
        [dispatch, book]
    );

    const onCreateBookOrder = React.useCallback(
        (paymentProfile) => {
            if (paymentProfile) {
                return dispatch(
                    BookOrderActions.create({
                        book: book.id,
                        payment_profile: paymentProfile.id
                    })
                )
                    .then((response) => {
                        if (response?.payload?.data) {
                            openDialog(CheckoutSuccessDialog, {
                                order: response.payload.data,
                                paymentProfile,
                                shippingAddress: ui.shipping,
                                bookTotalPrice,
                                bookPrice: book?.price,
                                bookQuantity,
                                discount: user.prices?.DISCOUNT,
                                basePrices,
                                shipping: book?.shipping_cost
                            });
                        }
                        return true;
                    })
                    .catch((error) => {
                        const ve =
                            error?.error?.response?.data?.validation_errors;
                        if (ve) {
                            setBookOrderErrMsg(ve);
                        }
                        return false;
                    });
            }
        },
        [
            dispatch,
            book,
            ui.shipping,
            bookTotalPrice,
            bookQuantity,
            user,
            basePrices
        ]
    );

    const onSave = useCallback(
        (flag) => {
            dispatch(BookShippingAddressActions.fetch()).then((response) => {
                setIsAddNewAddress(flag);
            });
        },
        [dispatch]
    );

    const onShowMore = useCallback(
        (url) => {
            dispatch(BookShippingAddressActions.fetch(null, url));
        },
        [dispatch]
    );

    const renderShippingAddress = useCallback(() => {
        return (
            <ShippingAddressList
                book={book}
                shippingAddress={shipping}
                onUpdateBookData={onUpdateBookData}
                onAddNewAddress={() => setIsAddNewAddress((prev) => !prev)}
                // onDelete={onDelete}
                errMsg={shippingAddressErrMsg}
                onShowMore={onShowMore}
            />
        );
    }, [
        book,
        // onDelete,
        onShowMore,
        onUpdateBookData,
        shipping,
        shippingAddressErrMsg
    ]);

    const changeBookQuantity = useCallback(
        async (e) => {
            let bookCount = e.target.value;
            var letters = /^[A-Za-z]+$/;
            if (bookCount.includes('-') || bookCount.match(letters)) {
                bookCount = 1;
            }
            await setBookQuantity((prevState) => {
                return bookCount;
            });

            if (bookCount === '') {
                return;
            }

            dispatch({
                type: 'SET_IS_CALC',
                payload: {
                    value: true
                }
            });
            bookCount = parseInt(bookCount);
            dispatch(
                BookActions.updateAndFetch({
                    id: book?.id,
                    quantity: bookCount
                })
            );
        },
        [dispatch, setBookQuantity, book]
    );

    const onBlurBookQuantity = useCallback(
        (e) => {
            if (e.target.value === '') {
                setBookQuantity(1);
                dispatch(
                    BookActions.updateAndFetch({
                        id: book?.id,
                        quantity: 1
                    })
                );
            }
        },
        [book, dispatch]
    );

    return (
        <div
            className="modal-frame"
            id="checkoutBookModal"
            style={modalClosed ? { display: 'none' } : { display: 'block' }}>
            <div className="modal-box modal-box--600">
                <div className="modal__header">
                    Checkout
                    <button
                        className="modal__close btn-icon btn-icon--3"
                        onClick={() => closeModal()}>
                        <img src={iconCloseSm} alt="" />
                    </button>
                </div>
                <div className="modal__body">
                    <div className="purchase-table">
                        <div className="purchase-table__item">
                            <div className="purchase-table__label">
                                <div>{book?.title || ''}</div>
                            </div>
                            <div className="purchase-table__value">
                                <input
                                    type="text"
                                    className="purchase-table__quantity"
                                    value={
                                        // isPriceCalculating ? '' :
                                        bookQuantity
                                    }
                                    onChange={changeBookQuantity}
                                    onBlur={onBlurBookQuantity}
                                />
                                <span>x</span>{' '}
                                {isPriceCalculating ? (
                                    '...'
                                ) : (
                                    <>${basePrices.fullPrice}</>
                                )}
                            </div>
                        </div>
                        <div className="purchase-table__item">
                            <div className="purchase-table__label">
                                Shipping{' '}
                            </div>
                            <div className="purchase-table__value">
                                {isPriceCalculating ? (
                                    '...'
                                ) : (
                                    <>${book?.shipping_cost}</>
                                )}
                            </div>
                        </div>
                        {!isPriceCalculating && basePrices.discountAmount > 0 && (
                            <div className="purchase-table__item">
                                <div className="purchase-table__label">
                                    PowerJRNLer Discount{' '}
                                </div>
                                <div className="purchase-table__value">
                                    {isPriceCalculating ? (
                                        '...'
                                    ) : (
                                        <>
                                            -$
                                            {basePrices.discountAmount.toFixed(
                                                2
                                            )}{' '}
                                            ({user.prices?.DISCOUNT * 100}%)
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                        <div className="purchase-table__item purchase-table__item--total">
                            <div className="purchase-table__label">Total:</div>
                            <div className="purchase-table__value">
                                {isPriceCalculating ? (
                                    <span style={{ fontSize: '15px' }}>
                                        Calculating...
                                    </span>
                                ) : (
                                    <> ${bookTotalPrice}</>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="settings-list-title section-title">
                        Coupon Code
                    </div>

                    <div className="coupon-code">
                        <input
                            type="text"
                            className="coupon-input"
                            placeholder="Enter code"
                        />
                        <button className="coupon-code__btn">Apply</button>
                    </div>

                    <div className="settings-list-title section-title">
                        Select a shipping address
                    </div>

                    <div className="list list--shipping-address">
                        {isAddNewAddress ? (
                            <ShippingAddress
                                book={book}
                                shipping={shipping}
                                onUpdateBookData={onUpdateBookData}
                                onSave={onSave}
                            />
                        ) : (
                            renderShippingAddress()
                        )}
                    </div>

                    <div className="settings-list-title section-title">
                        Select a payment method
                    </div>

                    <div
                        ref={checkoutRef}
                        className="list checkout-payment-info">
                        {isAddNewPaymentProfile ? (
                            <NewCreditCard
                                book={book}
                                bookTotalPrice={bookTotalPrice}
                                paymentProfile={paymentProfile}
                                onCreateBookOrder={onCreateBookOrder}
                            />
                        ) : (
                            <CreditCardList
                                book={book}
                                bookTotalPrice={bookTotalPrice}
                                onCreateBookOrder={onCreateBookOrder}
                                onAddNewPaymentProfile={() =>
                                    setIsAddNewPaymentProfile((prev) => !prev)
                                }
                            />
                        )}

                        {
                            /* TODO: move it to component */
                            bookOrderErrMsg && (
                                <div
                                    style={{
                                        color: 'red',
                                        marginTop: '1.875rem',
                                        backgroundColor: 'indianred',
                                        borderRadius: '.2rem'
                                    }}>
                                    {Object.entries(bookOrderErrMsg || {}).map(
                                        (value, index) => {
                                            return (
                                                <p
                                                    key={index}
                                                    style={{
                                                        padding: '.3rem'
                                                    }}>
                                                    {value[1]}
                                                </p>
                                            );
                                        }
                                    )}
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            {/* <Snackbar open={error}>
                <Alert severity="error">{ZIP_CODE_ERROR}</Alert>
            </Snackbar> */}
        </div>
    );
};

const state = createStructuredSelector({
    user: getUser,
    shipping: getShippingAddressForBook,
    paymentProfile: getDefaultPaymentProfile,
    ui: getUI,
    book: getBookById,
    isPriceCalculating
});
export default connect(state)(BookOrderDialog);
