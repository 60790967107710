import _ from 'underscore';
import buildAuthorization from 'common/utils/buildAuthorization';
import { UserSelector } from 'redux/selectors/UserSelector';
import {
    CRATE_JOURNAL_INVITATION,
    REMOVE_JOURNAL_INVITATION,
    FETCH_JOURNAL_INVIATAION_WITH_UNIQUE_KEY,
    UPDATE_JOURNAL_INVITATION
} from 'redux/actions/actionTypes';
import { SHARED_JOURNAL_SHARING_INVITATION_URL } from 'common/constant';
import * as ShareContactListActions from 'redux/actions/sharecontactlist';
import * as JournalActions from 'redux/actions/journal';
import { openDialog } from 'common/utils/dialog-utils';
import ProAccountJournalLimitDialog from 'pages/Dialogs/ProAccountJournalLimitDialog';

export const inviteUsers = (users, journalId, customMessage) => async (
    dispatch,
    getState
) => {
    let i = 0;
    const responses = [];

    if (!(users && Array.isArray(users))) {
        return;
    }
    for (i = 0; i < users.length; i++) {
        const user = users[i];

        try {
            const response = await dispatch(
                create({
                    shared_email: user.email,
                    journal: journalId,
                    custom_message: customMessage
                })
            );
            responses.push(response);
        } catch (e) {
            // if invites are failed because of limit, show modals.
            if (
                e?.error?.response?.data?.validation_errors?.journal[0] ===
                'Pro User can share 10 journals only.'
            ) {
                openDialog(ProAccountJournalLimitDialog);
                return true;
            }
            responses.push(e);
        }
    }

    dispatch(ShareContactListActions.fetch());
    dispatch(
        JournalActions.fetch({
            id: journalId
        })
    );

    return responses;
};

export const removeUsers = (removed_users, invitations, journalId) => async (
    dispatch,
    getState
) => {
    if (!(removed_users && Array.isArray(removed_users))) {
        return;
    }
    for (let i = 0; i < removed_users.length; i++) {
        const user = removed_users[i];

        const invitaiton = _.findWhere(invitations, { email: user.email });
        if (invitaiton) {
            await dispatch(remove(invitaiton));
        }
    }

    dispatch(ShareContactListActions.fetch());
    dispatch(
        JournalActions.fetch({
            id: journalId
        })
    );
};

export const create = (data) => (dispatch, getState) => {
    const url = SHARED_JOURNAL_SHARING_INVITATION_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: CRATE_JOURNAL_INVITATION,
        payload: {
            request: {
                url,
                method: 'POST',
                data,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const remove = (data, journalID) => (dispatch, getState) => {
    const url = `${SHARED_JOURNAL_SHARING_INVITATION_URL}${data.unique_id}/`;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: REMOVE_JOURNAL_INVITATION,
        payload: {
            request: {
                url,
                method: 'DELETE',
                options: {
                    unique_id: data.unique_id
                },
                headers: {
                    Authorization: auth
                }
            },
            journal: journalID
        }
    });
};

export const fetchInvitationWithUniqueKey = (uniqueKey) => (
    dispatch,
    getState
) => {
    const url = `${SHARED_JOURNAL_SHARING_INVITATION_URL}${uniqueKey}/`;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: FETCH_JOURNAL_INVIATAION_WITH_UNIQUE_KEY,
        payload: {
            request: {
                url,
                method: 'GET',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const update = (data = {}, invitationId) => (dispatch, getState) => {
    const url = `${SHARED_JOURNAL_SHARING_INVITATION_URL}${invitationId}/`;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: UPDATE_JOURNAL_INVITATION,
        payload: {
            request: {
                url,
                method: 'PATCH',
                data,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};
