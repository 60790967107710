import _ from 'underscore';
import buildAuthorization from 'common/utils/buildAuthorization';

import { UserSelector } from 'redux/selectors/UserSelector';
import {
    FETCH_BOOK_LIST,
    FETCH_BOOK,
    CREATE_BOOK,
    UPDATE_BOOK,
    REMOVE_BOOK,
    POLL_BOOK,
    ADD_BOOK_TIMER,
    REMOVE_BOOK_TIMER,
    REMOVE_ALL_BOOK_TIMERS,
    SET_BOOK_LOADINGPROGRESS,
    SET_BOOK_DOWNLOAD_PROGRESS,
    UPDATE_AND_FETCH,
    BOOK_BUIDER_FULL_VIEW
} from 'redux/actions/actionTypes';
import { BookSelector } from 'redux/selectors/BookSelector';
import { BOOK_URL } from 'common/constant';
import axios from 'axios';

//const SUCCESS_BOOK_BUILDER_STATUS = [100, 99, 98];

export const setBookDownloadProgress = (percentage) => {
    return {
        type: SET_BOOK_DOWNLOAD_PROGRESS,
        payload: percentage
    };
};
export const getFileFromPDF = (file) => (dispatch) => {
    return axios
        .get(file, {
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                dispatch(setBookDownloadProgress(percentCompleted));
            }
        })
        .then((response) => {
            const toBase64 = (file) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = (error) => reject(error);
                });

            const blobToFile = (theBlob, fileName) => {
                theBlob.lastModifiedDate = new Date();
                theBlob.name = fileName;
                return theBlob;
            };
            return toBase64(blobToFile(response.data, 'file.pdf'));
        });
};
export const fetch = (options = {}, pageUrl) => (dispatch, getState) => {
    const book = BookSelector(getState());
    if (book.isPending === true) {
        return;
    }

    const user = UserSelector(getState());
    const url = pageUrl || BOOK_URL;
    const auth = buildAuthorization(user);

    return dispatch({
        type: FETCH_BOOK_LIST,
        payload: {
            request: {
                url,
                method: 'GET',
                params: options,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const fetch_detail = (data = {}) => (dispatch, getState) => {
    const user = UserSelector(getState());
    const url = `${BOOK_URL}${data.id}/`;
    const auth = buildAuthorization(user);

    return dispatch({
        type: FETCH_BOOK,
        payload: {
            request: {
                url,
                method: 'GET',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const create = (book) => (dispatch, getState) => {
    const user = UserSelector(getState());
    const url = BOOK_URL;
    const auth = buildAuthorization(user);

    return dispatch({
        type: CREATE_BOOK,
        payload: {
            request: {
                url,
                method: 'POST',
                data: book,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const update = (book, options = {}) => (dispatch, getState) => {
    const user = UserSelector(getState());
    const url = `${BOOK_URL}${book.id}/`;
    const auth = buildAuthorization(user);

    return dispatch({
        type: UPDATE_BOOK,
        payload: {
            request: {
                url,
                method: 'PATCH',
                data: book,
                params: options,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const updateAndFetch = (book, options = {}) => (dispatch, getState) => {
    const user = UserSelector(getState());
    const url = `${BOOK_URL}${book.id}/`;
    const auth = buildAuthorization(user);

    return dispatch({
        type: UPDATE_AND_FETCH,
        payload: {
            request: {
                url,
                method: 'PATCH',
                data: book,
                params: options,
                headers: {
                    Authorization: auth
                }
            }
        }
    }).then((response) => {
        dispatch({
            type: FETCH_BOOK,
            payload: {
                request: {
                    url,
                    method: 'GET',
                    headers: {
                        Authorization: auth
                    }
                }
            }
        }).then((response) => {
            dispatch({
                type: 'SET_IS_CALC',
                payload: {
                    value: false
                }
            });
        });
    });
};

export const setIsBookBuilderFullView = (bool) => (dispatch) => {
    return dispatch({
        type: BOOK_BUIDER_FULL_VIEW,
        payload: {
            data: bool
        }
    });
};

export const remove = (book) => (dispatch, getState) => {
    const user = UserSelector(getState());
    const url = `${BOOK_URL}${book.id}/`;
    const auth = buildAuthorization(user);

    return dispatch({
        type: REMOVE_BOOK,
        payload: {
            request: {
                url,
                method: 'DELETE',
                options: {
                    bookId: book.id
                },
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const poll = (book, options = {}) => (dispatch, getState) => {
    const user = UserSelector(getState());

    const url = `${BOOK_URL}${book.id}/`;
    const auth = buildAuthorization(user);

    const options = _.extend(
        {},
        {
            modified__gt: book.modified,
            show_all_fields: 1
        }
    );

    return dispatch({
        type: POLL_BOOK,
        payload: {
            request: {
                url,
                method: 'GET',
                params: options,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const addTimer = (timer) => (dispatch, getState) => {
    return dispatch({
        type: ADD_BOOK_TIMER,
        payload: {
            data: timer
        }
    });
};

export const removeTimer = (timer) => (dispatch, getState) => {
    return dispatch({
        type: REMOVE_BOOK_TIMER,
        payload: {
            data: timer
        }
    });
};

export const removeAllTimers = () => (dispatch, getState) => {
    return dispatch({
        type: REMOVE_ALL_BOOK_TIMERS,
        payload: {
            data: undefined
        }
    });
};

export const setLoadingProgress = () => (dispatch, getState) => {
    return dispatch({
        type: SET_BOOK_LOADINGPROGRESS
    });
};
