import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import * as FilterActions from 'redux/actions/filter';
import * as EntryActions from 'redux/actions/entry';
import { createStructuredSelector } from 'reselect';
import {
    getFilters,
    getUser,
    getJournalList,
    getTagList,
    getUI
} from 'redux/selectors';
import { TagFilterList, JournalFilterList } from 'components';
import { updateUI } from 'redux/actions/ui';
import Calendar from 'components/Calendar/CustomCalendar';
import NewJournalDialog from 'pages/Dialogs/NewJournalDialog';
import NewTagDialog from 'pages/Dialogs/NewTagDialog';

// icons
import iconAddSm from 'assets/images/icons_svg/icon_add-sm.svg';
import withFreeAccountCheck from 'common/utils/components/withFreeAccountCheck';

const TimelineFiltersContainer = ({
    dispatch,
    filters,
    journals,
    ui,
    tags
}) => {
    const onJournalFilter = useCallback(
        (journals) => {
            const value = journals === 'all' ? undefined : journals;

            dispatch(
                FilterActions.update({
                    filterByJournal: value
                })
            );
            dispatch(EntryActions.setLoadingProgress());
            dispatch(
                updateUI({
                    showingProgressBar: true
                })
            );
            dispatch(EntryActions.fetch());
            if (value === undefined) {
                window.location.href = '/';
            }
        },
        [dispatch]
    );

    const onTagFilter = useCallback(
        (tags) => {
            const value = tags === 'all' ? undefined : tags;

            dispatch(
                FilterActions.update({
                    filterByTag: value,
                    minDate: undefined,
                    maxDate: undefined
                })
            );
            dispatch(EntryActions.setLoadingProgress());
            dispatch(
                updateUI({
                    showingProgressBar: true
                })
            );
            dispatch(EntryActions.fetch());
        },
        [dispatch]
    );

    const onNewJournal = useCallback(() => {
        // Show New Entry Dialog
        dispatch(
            updateUI({
                dialogs: [
                    ...(ui.dialogs || []),
                    withFreeAccountCheck(NewJournalDialog, true, false)
                ]
            })
        );
    }, [dispatch, ui]);
    const onNewTag = useCallback(() => {
        // Show New Entry Dialog
        dispatch(
            updateUI({
                dialogs: [...(ui.dialogs || []), NewTagDialog]
            })
        );
    }, [dispatch, ui]);

    return (
        <div>
            <div className="p-3">
                <Calendar />
            </div>
            <div className="journals-nav drawer__section">
                <div className="drawer__heading">
                    <div className="drawer__title">Journals</div>
                    <button className="btn-add" onClick={onNewJournal}>
                        <img src={iconAddSm} alt="" />
                    </button>
                </div>
                <JournalFilterList
                    onChange={onJournalFilter}
                    filterJournals={filters.filterByJournal}
                    journals={journals.journals}
                />
            </div>
            <div className="tags-nav drawer__section">
                <div className="drawer__heading">
                    <div className="drawer__title">Tags</div>
                    <button className="btn-add" onClick={onNewTag}>
                        <img src={iconAddSm} alt="" />
                    </button>
                </div>
                <TagFilterList
                    onChange={onTagFilter}
                    filterTags={filters.filterByTag}
                    tags={tags.tags}
                />
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    user: getUser,
    ui: getUI,
    filters: getFilters,
    journals: getJournalList,
    tags: getTagList
});

export default connect(state)(TimelineFiltersContainer);
