import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { updateUI } from 'redux/actions/ui';

// images
import deleteImage from 'assets/images/banners/banner-delete.png';

const DeleteDialog = ({ dispatch, title, text, onDelete }) => {
    const closeDialog = useCallback(() => {
        dispatch(
            updateUI({
                dialogs: []
            })
        );
    }, [dispatch]);

    return (
        <div id="delete-sender-modal" className="modal-frame">
            <div className="modal-box modal-box--delete modal-box--500">
                <div className="delete-modal">
                    <div className="delete-modal__img">
                        <img
                            src={deleteImage}
                            alt="banner-img"
                            className="img-fluid"
                        />
                    </div>
                    <div className="delete-modal__content">
                        <div className="delete-modal__title">{title}</div>
                        <div className="delete-modal__subtitle">{text}</div>
                    </div>
                    <div className="delete-modal__actions">
                        <button
                            className="btn btn-outline"
                            onClick={closeDialog}>
                            Cancel
                        </button>
                        <button className="btn btn-delete" onClick={onDelete}>
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect()(DeleteDialog);
