import {
    SET_ACTIVE_TAB,
    SET_SETTINGS_ACTIVE_TAB_BY_PATH,
    SET_SETTINGS_SIDEBAR_MOBILE_STATUS,
    SET_SELECTED_PLAN_DURATION
} from 'redux/actions/actionTypes';
import {
    AccountPane,
    BillingPane,
    EmailToJRNLPane,
    ImportExportPane,
    NotificationsPane,
    ProfileTabPane,
    RemindersPane,
    SecurityTabPane,
    SocialConnectTabPane,
    ThemePane
} from 'pages/Settings/TabPanes';
import withFreeAccountCheck from 'common/utils/components/withFreeAccountCheck';
import { URLS } from 'common/urls';
// import { CANCELED } from 'dropzone';
const WrappedSocialConnectTabPane = withFreeAccountCheck(
    SocialConnectTabPane,
    false
);
const initState = {
    Tabs: [
        {
            key: 0,
            component: ProfileTabPane,
            path: URLS.SETTINGS.PROFILE
        },
        {
            key: 1,
            component: WrappedSocialConnectTabPane,
            path: URLS.SETTINGS.SOCIALCONNECT
        },
        {
            key: 2,
            component: SecurityTabPane,
            path: URLS.SETTINGS.SECURITY
        },
        {
            key: 3,
            component: RemindersPane,
            path: URLS.SETTINGS.REMINDER
        },
        {
            key: 4,
            component: EmailToJRNLPane,
            path: URLS.SETTINGS.EMAILJRNL
        },
        {
            key: 5,
            component: BillingPane,
            path: URLS.SETTINGS.BILLING
        },
        {
            key: 6,
            component: ThemePane,
            path: URLS.SETTINGS.THEMES
        },
        {
            key: 7,
            component: AccountPane,
            path: URLS.SETTINGS.ACCOUNT
        },
        {
            key: 8,
            component: NotificationsPane,
            path: URLS.SETTINGS.NOTIFICATION
        },
        // {
        //     key: 9,
        //     component: InvitationsPane,
        //     path: URLS.SETTINGS.INVITATIONS
        // }
        {
            key: 9,
            component: ImportExportPane,
            path: URLS.SETTINGS.IMPORTEXPORT
        }
    ],
    active_tab: 0,
    openSettingSideBarMobile: true,
    selectedPlanDuration: 'Free'
};

export const settingsReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_SETTINGS_ACTIVE_TAB_BY_PATH: {
            return {
                ...state,
                active_tab: state.Tabs.find(
                    (item) => item.path === action.payload
                ).key
            };
        }

        case SET_SETTINGS_SIDEBAR_MOBILE_STATUS: {
            return {
                ...state,
                openSettingSideBarMobile: action.payload
            };
        }

        case SET_SELECTED_PLAN_DURATION: {
            return {
                ...state,
                selectedPlanDuration: action.payload
            };
        }

        case SET_ACTIVE_TAB: {
            return {
                ...state,
                active_tab: action.payload
            };
        }
        default: {
            return {
                ...state
            };
        }
    }
};
