import React, { useCallback, useEffect, useState } from 'react';

// Actions
import { connect, useSelector } from 'react-redux';
import * as JournalActions from 'redux/actions/journal';
import * as JournalInvitationActions from 'redux/actions/journalinvitation';

// selector
import { createStructuredSelector } from 'reselect';
import { getJournalInvitations, getUI, getUser } from 'redux/selectors';

import { updateUI } from 'redux/actions/ui';
import withSharedUsers from 'common/utils/components/withSharedUsers';
import DeleteJournalDialog from 'pages/Dialogs/DeleteJournalDialog';
import ShareDialog from 'pages/Dialogs/InvitationDialog/ShareDialog';
import { InvitationListButton } from 'components';
import { JOURNAL_AAM, JOURNAL_DESC_TEXT_WORD_LIMIT } from 'common/constant';
import { getDefaultJournal } from 'redux/selectors';
import { countWords } from 'common/utils/text-utils';

// Icons
import iconLock from 'assets/images/icons_svg/icon_lock.svg';
import iconCheckSm from 'assets/images/icons_svg/icon_check-sm.svg';
// import withFreeAccountCheck from 'common/utils/components/withFreeAccountCheck';

const withJournal = (Component, journal) => (props) => {
    return <Component journal={journal} {...props} />;
};

const EditJournalDialog = ({
    close,
    dispatch,
    journal,
    journalInvitations,
    ui,
    me
}) => {
    const [journalTitle, setJournalTitle] = useState();
    const [isSaving, setIsSaving] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [isDefault, setIsDefault] = useState(journal?.is_default);
    const [journalSharedUsers, setJournalSharedUsers] = useState([]);
    const oldDefaultJournal = useSelector(getDefaultJournal);
    const [journalDesc, setJournalDesc] = useState(journal?.title_page_content);

    useEffect(() => {
        if (
            JSON.stringify(journalSharedUsers) !==
            JSON.stringify(journalInvitations)
        ) {
            setJournalSharedUsers(journalInvitations);
        }
    }, [journalInvitations, journalSharedUsers]);

    const _close = useCallback(() => {
        if (close) {
            close();
        }
    }, [close]);

    const _done = useCallback(() => {
        const newJournal = { ...journal };
        newJournal.title = journalTitle;
        newJournal.title_page_content = journalDesc;
        if (isDefault === true && oldDefaultJournal.id) {
            newJournal.is_default = isDefault;
            dispatch(
                JournalActions.update({
                    journal_type: '',
                    is_default: false,
                    id: oldDefaultJournal.id
                })
            );
        }
        setIsSaving(true);
        setIsSaved(false);
        dispatch(JournalActions.update(newJournal, newJournal.id)).then(
            (response) => {
                if (response.payload.status === 202) {
                    setIsSaving(false);
                    setIsSaved(true);
                    _close();
                }
            }
        );
    }, [
        _close,
        dispatch,
        journal,
        journalTitle,
        isDefault,
        oldDefaultJournal,
        journalDesc
    ]);

    const onSharedUserUpdate = useCallback(
        (sharedUsers, added_users, removed_users, customMessage) => {
            if (journal?.id) {
                // create invitations
                dispatch(
                    JournalInvitationActions.inviteUsers(
                        added_users,
                        journal?.id,
                        customMessage
                    )
                );
                // remove invitations
                dispatch(
                    JournalInvitationActions.removeUsers(
                        removed_users,
                        journalSharedUsers,
                        journal?.id
                    )
                );

                dispatch(
                    updateUI({
                        shared_users: [],
                        sharedEntryId: undefined
                    })
                );
            }
        },
        [journal, dispatch, journalSharedUsers]
    );

    const onChangeJournalTitle = useCallback((e) => {
        setJournalTitle(e.target.value);
    }, []);

    const onChangeDefaultJournal = useCallback(() => {
        setIsDefault(!isDefault);
    }, [isDefault]);

    const openShareDialog = useCallback(() => {
        dispatch(
            updateUI({
                publicEntry: (journalSharedUsers || []).length,
                dialogs: [
                    ...ui.dialogs,
                    withSharedUsers(
                        ShareDialog,
                        journalSharedUsers || [],
                        onSharedUserUpdate
                    )
                ]
            })
        );
    }, [dispatch, ui.dialogs, onSharedUserUpdate, journalSharedUsers]);

    const onDeleteJournal = useCallback(
        (journal) => {
            // Show Delete Journal Dialog
            dispatch(
                updateUI({
                    dialogs: [
                        ...ui.dialogs,
                        withJournal(DeleteJournalDialog, journal)
                    ]
                })
            );
        },
        [dispatch, ui.dialogs]
    );

    const renderDescBadges = useCallback(() => {
        if (countWords(journalDesc) >= JOURNAL_DESC_TEXT_WORD_LIMIT) {
            return (
                <div className="edit-limit-word">
                    f{JOURNAL_DESC_TEXT_WORD_LIMIT}
                </div>
            );
        }
    }, [journalDesc]);

    const onChangeDesc = useCallback(
        (e) => {
            if (countWords(journalDesc) <= JOURNAL_DESC_TEXT_WORD_LIMIT) {
                setJournalDesc(e.target.value);
            }
        },
        [journalDesc]
    );

    const cond = journal.journal_type !== JOURNAL_AAM && !journal.is_default;
    // load journal title
    useEffect(() => {
        setJournalTitle(journal?.title);
        return () => {};
    }, [journal]);

    return (
        <div id="edit-journal-modal" className="edit-journal-modal modal-frame">
            <div className="modal-box modal-box--600">
                <div className="modal__header">Edit Journal</div>
                <div className="modal__body">
                    <div className="form-group">
                        <div className="form-group__item">
                            <label htmlFor="edit-journal">Journal Name</label>
                            <input
                                type="text"
                                id="edit-journal"
                                onChange={onChangeJournalTitle}
                                value={journalTitle}
                                name="name"
                                placeholder={journal?.title}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-group__item">
                            <label htmlFor="edit-journal-desc">
                                Journal Description
                            </label>
                            {renderDescBadges()}
                            <textarea
                                onChange={onChangeDesc}
                                value={journalDesc}
                                name="Description"
                                placeholder="Describe this journal"
                                rows="3"></textarea>
                        </div>
                    </div>
                    {!me?.is_free && (
                        <div className="form-group">
                            <div className="form-group__row">
                                <div className="form-group__item form-group__item--6">
                                    <label htmlFor="edit-journal">
                                        Privacy
                                    </label>
                                    <button
                                        className="btn btn-private-shared"
                                        onClick={openShareDialog}>
                                        {journalSharedUsers?.length > 0 ? (
                                            <InvitationListButton
                                                invitations={journalSharedUsers}
                                            />
                                        ) : (
                                            <>
                                                <img
                                                    src={iconLock}
                                                    alt=""
                                                    className="left icon-static btn-icon--6"
                                                />
                                                Private
                                            </>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    {cond && (
                        <div className="checkbox-item mb-3">
                            <input
                                type="checkbox"
                                id="defaultJournal"
                                name="defaultJournal"
                                className="checkbox"
                                checked={isDefault}
                                onChange={onChangeDefaultJournal}
                            />
                            <label htmlFor="defaultJournal">
                                Make it my default journal
                            </label>
                        </div>
                    )}
                </div>
                <div className="modal__footer">
                    <button className="btn btn-outline" onClick={_close}>
                        Cancel
                    </button>
                    {journal.entry_count === 0 && (
                        <button
                            className="btn btn-delete"
                            onClick={() => onDeleteJournal(journal)}>
                            Delete
                        </button>
                    )}
                    <button className="btn btn-gradient" onClick={_done}>
                        Update
                    </button>
                    {isSaving && (
                        <div className="saving-text pr-2">
                            <div className="saving-gif pr-3" />
                            Saving...
                        </div>
                    )}
                    {!isSaving && isSaved && (
                        <div className="saving-text pr-2">
                            <img src={iconCheckSm} alt="" />
                            Saved!
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    journalInvitations: getJournalInvitations,
    ui: getUI,
    me: getUser
});
export default connect(state)(EditJournalDialog);
