/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
    return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param sources
 */
export function mergeDeep(target, ...sources) {
    if (!sources.length) {
        return target;
    }
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) {
                    Object.assign(target, { [key]: {} });
                }
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeDeep(target, ...sources);
}

const flatArray = (array, field, objToArray) => {
    const initalValue = [];

    return (objToArray ? objToArray(array) : array).reduce((acc, cur) => {
        if (cur[field]) {
            const children = objToArray ? objToArray(cur[field]) : cur[field];
            // add caption data into children
            if (Array.isArray(children)) {
                children.forEach((item) => {
                    item['caption'] = cur['caption'];
                });
            }
            acc = [].concat(
                acc,
                flatArray(
                    objToArray ? objToArray(cur[field]) : cur[field],
                    field
                )
            );
        } else {
            acc.push(cur);
        }

        return acc;
    }, initalValue);
};

const deepClone = (data) => JSON.parse(JSON.stringify(data));

const mergeArrayByField = (
    original,
    upcoming,
    field = 'id',
    merge = false,
    pushEnd = false
) => {
    let result = original ? deepClone(original) : [];

    upcoming.forEach((obj) => {
        const index = (result || []).findIndex(
            (item) => item[field] === obj[field]
        );
        if (index >= 0) {
            const newObj = merge ? mergeDeep(result[index], obj) : obj;

            result = [].concat(
                result.slice(0, index),
                [newObj],
                result.slice(index + 1)
            );
        } else {
            result = pushEnd
                ? [].concat(result || [], [obj])
                : [].concat([obj], result || []);
        }
    });

    return result;
};

const checkErrorObjValidated = (error) => {
    const keys = Object.keys(error);
    return !keys.some((key) => !!error[key]);
};

/**
 * get set of array - which means removing duplicating items.
 * @param array
 * @param {*} checkIncludes function
 * @returns
 */
const getSetOfArray = (array, checkIncludes) => {
    const newArray = [];

    array.forEach((item) => {
        if (!checkIncludes(newArray, item)) {
            newArray.push(item);
        }
    });

    return newArray;
};

/**
 * Create a new object from original objects by extracting given properties.
 * @param obj
 * @param props
 */
const pick = (obj, props) => {
    const newObj = {};
    props.forEach((prop) => {
        newObj[prop] = obj[prop];
    });

    return newObj;
};

/**
 * Create a new object from original objects by ejecting given properties.
 * @param obj
 * @param props
 */
const eject = (obj, props) => {
    const newObj = {};
    Object.keys(obj)
        .filter((key) => !props.includes(key))
        .forEach((key) => {
            newObj[key] = obj[key];
        });

    return newObj;
};

export const isEmpty = (obj) => {
    if (!obj) {
        return true;
    }

    if (Array.isArray(obj)) {
        return obj.length === 0;
    }
    if (typeof obj === 'object') {
        // return Object.keys(obj).length === 0 && obj.constructor === Object;
        return Object.keys(obj).length === 0;
    }
    return false;
};

/**
 * Deep compare objects
 */
export const isEqual = (x, y) => {
    if (x === y) {
        return true;
    }
    // if both x and y are null or undefined and exactly the same

    if (!(x instanceof Object) || !(y instanceof Object)) {
        return false;
    }
    // if they are not strictly equal, they both need to be Objects

    if (x.constructor !== y.constructor) {
        return false;
    }
    // they must have the exact same prototype chain, the closest we can do is
    // test there constructor.

    for (var p in x) {
        if (!Object.prototype.hasOwnProperty.call(x, p)) {
            continue;
        }
        // other properties were tested using x.constructor === y.constructor

        if (!Object.prototype.hasOwnProperty.call(y, p)) {
            return false;
        }
        // allows to compare x[ p ] and y[ p ] when set to undefined

        if (x[p] === y[p]) {
            continue;
        }
        // if they have the same strict value or identity then they are equal

        if (typeof x[p] !== 'object') {
            return false;
        }
        // Numbers, Strings, Functions, Booleans must be strictly equal

        if (!isEqual(x[p], y[p])) {
            return false;
        }
        // Objects and Arrays must be tested recursively
    }

    for (p in y) {
        if (
            Object.prototype.hasOwnProperty.call(y, p) &&
            !Object.prototype.hasOwnProperty.call(x, p)
        ) {
            return false;
        }
    }
    // allows x[ p ] to be set to undefined

    return true;
};

export default {
    flatArray,
    mergeArrayByField,
    checkErrorObjValidated,
    getSetOfArray,
    pick,
    eject,
    isEqual,
    mergeDeep,
    deepClone,
    isEmpty
};
