import React, { Fragment, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import * as EntryMediaActions from 'redux/actions/entrymedia';

const EntryMediaFilePage = ({
    match: {
        params: { entryMediaId }
    },
    dispatch
}) => {
    const loadMediaFileData = useCallback(
        (objId) => {
            dispatch(EntryMediaActions.fetchIndividual(objId)).then(
                (response) => {
                    if (response.payload.status === 200) {
                        window.location.href = response.payload.data.file;
                    }
                }
            );
        },
        [dispatch]
    );

    useEffect(() => {
        if (entryMediaId) {
            loadMediaFileData(entryMediaId);
        }
    }, [entryMediaId, loadMediaFileData]);
    return <Fragment></Fragment>;
};

export default connect()(EntryMediaFilePage);
