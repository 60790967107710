import React, { useEffect, useCallback, useState, useRef } from 'react';
import moment from 'moment';
import _ from 'underscore';

import { useHistory, useLocation } from 'react-router-dom';

// redux
import { connect } from 'react-redux';

// Actions
import * as EditorActions from 'redux/actions/editor';
import * as EntryActions from 'redux/actions/entry';
import * as TagActions from 'redux/actions/tag';
import * as AllAboutMeActions from 'redux/actions/allaboutme';
import * as EntryImageActions from 'redux/actions/entryimage';
import { updateUI } from 'redux/actions/ui';

// Selectors
import { createStructuredSelector } from 'reselect';
import {
    getUser,
    getEntryState,
    getEditorState,
    getDefaultJournal,
    getJournalList,
    getTagList,
    getAAM,
    getAamJournal,
    getUI,
    getQADraftEntry
} from 'redux/selectors';

// Components
import withEntry from 'common/utils/components/withEntry';
import { EntryEditor } from 'components';

// Dialogs
import MediaManagerDialog from 'pages/Dialogs/MediaManagerDialog';
import DateTimeDialog from 'pages/Dialogs/DateTimeDialog';
import ImportDialog from 'pages/Dialogs/ImportDialog';

// Contants
import { ENTRY_QA, ENTRY_DRAFT, ENTRY_PUBLISHED } from 'common/constant';
import { URLS } from 'common/urls';
import { checkResponseSuccess } from 'common/utils/responseUtils';
import { iosMediaPermission } from '../../components/media/Permission';

const initialNewEntry = {
    entry_type: 'Default',
    display_time: true,
    entry_date: moment().utc().format('YYYY-MM-DDTHH:mm:ss'),
    journal: undefined
};

const EntryCreateAAM = ({
    categoryName,
    dispatch,
    ui,
    editor,
    defaultJournal,
    aamJournal,
    user,
    tags,
    journal,
    aam,
    questionId,
    qaDraftEntry,
    _onSave,
    ...props
}) => {
    // router history
    const history = useHistory();
    const location = useLocation();

    // states
    const [question, setQuestion] = useState(undefined);
    // autosave states
    const [autoSave, setAutoSave] = useState(false); // the param to inform entryeditor to save the current content
    const [isSaved, setIsSaved] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);
    const [created, setCreated] = useState(false);
    const autoSaveTimer = useRef(null);

    // componentDidMount
    useEffect(() => {
        //dispatch(JournalActions.fetch());
        dispatch(TagActions.fetch());
        dispatch(AllAboutMeActions.fetchQuestionList());

        // Reset Editor
        dispatch(EditorActions.clearEditor());
        dispatch(EditorActions.resetImageState());
        dispatch(EditorActions.unstageImages());

        if (questionId) {
            if (!isNaN(questionId)) {
                dispatch(
                    updateUI({
                        newEntry: {
                            ...initialNewEntry,
                            entry_type: ENTRY_QA,
                            question: parseInt(questionId)
                        }
                    })
                );
                return;
            } else {
                dispatch(
                    updateUI({
                        newEntry: {
                            ...initialNewEntry,
                            entry_type: ENTRY_QA
                        }
                    })
                );
                return;
            }
        }

        // update newEntry
        dispatch(
            updateUI({
                newEntry: {
                    ...initialNewEntry
                }
            })
        );
    }, [questionId, location, dispatch]);

    // check for draft entry
    useEffect(() => {
        if (questionId && qaDraftEntry) {
            dispatch(
                updateUI({
                    newEntry: {
                        ...qaDraftEntry,
                        question: parseInt(questionId)
                    }
                })
            );
            if (qaDraftEntry.id) {
                setCreated(true);
            }
        }
    }, [qaDraftEntry, dispatch, questionId]);

    useEffect(() => {
        const newEntry = ui.newEntry;
        let question = aam.randomQuestion;

        if (newEntry) {
            if (newEntry.entry_type === ENTRY_QA) {
                newEntry.title = aam?.randomQuestion?.text || '';
                if (newEntry.question) {
                    question =
                        (aam.questions || []).find(
                            (obj) => obj.id === newEntry.question
                        ) || aam.randomQuestion;
                }
            } else {
                question = undefined;
            }
        }

        setQuestion(question);
    }, [ui.newEntry, aam]);

    const clearAutoSaving = useCallback((finish = false, scndParam) => {
        setAutoSave(false);
        if (finish === false) {
            setIsSaved(true);
        } else {
            setIsSaved(false);
        }
        setIsSaving(false);
        setHasChanged(false);
    }, []);

    // auto saving callbacks
    const handleWithUpdateEntry = useCallback(
        (response, entry, staged_entry_images, finish, updateSuccess) => {
            if (finish === true) {
                // if user click the publish button

                // initiate the ui.newEntry
                dispatch(
                    updateUI({
                        newEntry: {
                            display_time: true,
                            state: 101
                        }
                    })
                );

                //update staged_entry_images(not implemented correctly as my view)
                if (staged_entry_images.length > 0) {
                    staged_entry_images.forEach((image) => {
                        dispatch(
                            EntryImageActions.update(
                                { entry: response.payload.data.id },
                                image.id
                            )
                        );
                    });
                }

                // clear image state for entry editor
                dispatch(EditorActions.resetImageState());
                dispatch(EntryActions.fetch());

                // navigate to the related page
                if (questionId) {
                    if (isNaN(parseInt(questionId))) {
                        history.push(`${URLS.AAM.ROOT}`);
                    } else {
                        const aamURL = location.pathname.includes(
                            URLS.AAM.QUESTION
                        )
                            ? `${URLS.AAM.QUESTION}${questionId}`
                            : URLS.AAM.RANDOM_QUESTION;
                        history.push(aamURL);
                    }
                } else {
                    history.push('/');
                }

                // clear auto saving parameters for new entry create
                clearInterval(autoSaveTimer.current);
                setCreated(false);
            } else {
                // auto saving

                // add entry id to ui.newEntry that is created
                dispatch(
                    updateUI({
                        newEntry: {
                            ...ui.newEntry,
                            id: response?.payload?.data?.id
                        }
                    })
                );

                setCreated(true);
            }

            // clear auto saving features
            clearAutoSaving(finish, updateSuccess);
        },
        [
            clearAutoSaving,
            autoSaveTimer,
            ui.newEntry,
            dispatch,
            history,
            questionId,
            location
        ]
    );

    const onSave = useCallback(
        (entry, finish = false) => {
            if (isSaving === true) {
                return;
            }

            setIsSaving(true);
            setAutoSave(false);

            const { staged_entry_images } = editor;

            let journal_ =
                (journal.journals || []).find(
                    (obj) => obj.resource_uri === ui.newEntry.journal
                ) || defaultJournal;

            if (entry.entry_type === ENTRY_QA) {
                journal_ = aamJournal;
            }

            const journalId = created ? journal_.resource_uri : journal_.id;

            entry = {
                ...entry,
                entry_date: ui.newEntry?.entry_date,
                display_time: ui.newEntry.display_time,
                journal: journalId
            };

            if (entry.tags && !_.isEmpty(entry.tags)) {
                entry.tags = entry.tags.map((tag) => tag.id);
            }
            // for update, question must be uri format and for create, question must be an id
            entry.question = created ? question.resource_uri : questionId;
            entry.status = finish !== false ? ENTRY_PUBLISHED : ENTRY_DRAFT;

            !isSaving && setIsSaving(true);

            if (created === false) {
                dispatch(EntryActions.create(entry)).then((response) => {
                    if (response.payload?.status === 201) {
                        dispatch(
                            EntryActions.fetchIndividualEntry(
                                response.payload.data.id
                            )
                        ).then((res) => {
                            if (res.payload && res.payload.status === 200) {
                                if (_onSave) {
                                    _onSave(finish);
                                }
                                handleWithUpdateEntry(
                                    res,
                                    entry,
                                    staged_entry_images,
                                    finish
                                );
                            }
                        });
                    }
                });
            } else {
                dispatch(EntryActions.update(entry, entry.id)).then(
                    (response) => {
                        if (checkResponseSuccess(response.payload?.status)) {
                            if (_onSave) {
                                _onSave(finish);
                            }
                            dispatch(
                                EntryActions.fetchIndividualEntry(
                                    response.payload.data.id
                                )
                            ).then((res) => {
                                if (res.payload && res.payload.status === 200) {
                                    handleWithUpdateEntry(
                                        res,
                                        entry,
                                        staged_entry_images,
                                        finish,
                                        { updateSuccess: true }
                                    );
                                }
                            });
                        }
                    }
                );
            }
        },
        [
            questionId,
            question,
            created,
            handleWithUpdateEntry,
            isSaving,
            aamJournal,
            defaultJournal,
            dispatch,
            editor,
            journal.journals,
            ui.newEntry,
            _onSave
        ]
    );

    // call auto save
    const saveIfChange = useCallback(() => {
        if (hasChanged && !isSaving && !isSaved) {
            if (!autoSave) {
                setAutoSave(true);
            }
            setHasChanged(false);
            setIsSaved(true);
        }
    }, [hasChanged, isSaving, isSaved, autoSave]);

    useEffect(() => {
        clearInterval(autoSaveTimer.current);
        const timer = setInterval(saveIfChange, 10000);
        autoSaveTimer.current = timer;
        return () => {
            clearInterval(autoSaveTimer.current);
        };
    }, [saveIfChange]);

    const onCancel = useCallback(
        (entry) => {
            dispatch(EditorActions.clearEditor());
            dispatch(EditorActions.resetImageState());
            dispatch(EditorActions.unstageImages());

            if (questionId) {
                if (isNaN(parseInt(questionId))) {
                    history.push(`${URLS.AAM.ROOT}`);
                } else {
                    history.push(`${URLS.AAM.QUESTION}${questionId}`);
                }
            } else {
                history.push('/');
            }
        },
        [dispatch, history, questionId]
    );

    const onInsertImage = useCallback(() => {
        dispatch(
            updateUI({
                dialogs: [iosMediaPermission(MediaManagerDialog)]
            })
        );
    }, [dispatch]);

    const onInsertInstagram = useCallback(() => {
        dispatch(
            updateUI({
                dialogs: [iosMediaPermission(ImportDialog)]
            })
        );
    }, [dispatch]);

    const onEntryDateUpdate = useCallback(
        (entry) => {
            dispatch(
                updateUI({
                    newEntry: {
                        ...ui.newEntry,
                        display_time: entry.display_time,
                        entry_date: entry.entry_date
                    }
                })
            );
        },
        [dispatch, ui.newEntry]
    );

    const onEntryDateClick = useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();

            dispatch(
                updateUI({
                    dialogs: [
                        withEntry(
                            DateTimeDialog,
                            ui.newEntry,
                            onEntryDateUpdate
                        )
                    ]
                })
            );
        },
        [dispatch, onEntryDateUpdate, ui.newEntry]
    );

    const onEntryJournalUpdate = useCallback(
        (journal) => {
            // const newEntry = _.extend({}, ui.newEntry, journal);
            const newEntry = {
                ...ui.newEntry,
                journal
            };
            dispatch(
                updateUI({
                    newEntry
                })
            );
        },
        [dispatch, ui.newEntry]
    );

    const onEntryTagUpdate = useCallback(
        (tag, isAdd) => {
            const newEntry = { ...ui.newEntry };

            if (isAdd) {
                if (!newEntry.tags) {
                    newEntry.tags = [];
                }
                newEntry.tags.push(tag.resource_uri);
            } else {
                newEntry.tags = ui.newEntry.tags.filter(
                    (option) => option !== tag.resource_uri
                );
            }

            dispatch(
                updateUI({
                    newEntry
                })
            );
        },
        [dispatch, ui.newEntry]
    );

    const getJournal = useCallback(
        (journal_) => {
            const { journals } = journal;

            if (ui.newEntry?.entry_type === ENTRY_QA) {
                return aamJournal;
            }

            let selectedJournal = undefined;
            if (journal) {
                selectedJournal = (journals || []).find(
                    (obj) => obj.resource_uri === journal_
                );
            }
            return selectedJournal || defaultJournal;
        },
        [aamJournal, defaultJournal, ui.newEntry, journal]
    );

    const onEditorChange = useCallback(() => {
        if (!hasChanged) {
            setHasChanged(true);
            setIsSaved(false);
        }
    }, [hasChanged]);

    const journals = React.useMemo(() => {
        const journal = getJournal(ui.newEntry?.journal);
        return journal ? [journal] : [];
    }, [ui, getJournal]);

    return (
        <EntryEditor
            categoryName={categoryName}
            created={created}
            entry={ui.newEntry || {}}
            onSave={onSave}
            onCancel={onCancel}
            onChange={onEditorChange}
            onInsertImage={onInsertImage}
            onInsertInstagram={onInsertInstagram}
            user={user}
            journal={journals}
            onEntryDateClick={onEntryDateClick}
            onEntryJournalUpdate={onEntryJournalUpdate}
            onEntryTagUpdate={onEntryTagUpdate}
            question={question}
            isSaving={isSaving}
            isSaved={isSaved}
            autoSave={autoSave}
            canSave={!isSaving || created}
        />
    );
};

const state = createStructuredSelector({
    user: getUser,
    entry: getEntryState,
    editor: getEditorState,
    defaultJournal: getDefaultJournal,
    aamJournal: getAamJournal,
    tags: getTagList,
    journal: getJournalList,
    aam: getAAM,
    ui: getUI,
    qaDraftEntry: getQADraftEntry
});

export default connect(state)(EntryCreateAAM);
