import buildAuthorization from 'common/utils/buildAuthorization';

import { UserSelector } from 'redux/selectors/UserSelector';
import {
    REMOVE_COMMENT,
    UPDATE_COMMENT,
    CREATE_COMMENT,
    FETCH_SHARED_COMMENT_LIST,
    FETCH_COMMENT_LIST,
    SET_COMMENT_LOADINGPROGRESS,
    SAVE_DRAFT_COMMENT
} from 'redux/actions/actionTypes';
import { COMMENT_URL, SHARED_COMMENT_URL } from 'common/constant';

export const fetch = (options) => (dispatch, getState) => {
    const url = COMMENT_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: FETCH_COMMENT_LIST,
        payload: {
            request: {
                url: url,
                method: 'GET',
                params: options,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const fetch_shared = (options) => (dispatch, getState) => {
    const url = SHARED_COMMENT_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: FETCH_SHARED_COMMENT_LIST,
        payload: {
            request: {
                url: url,
                method: 'GET',
                params: options,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const create = (data) => (dispatch, getState) => {
    const url = COMMENT_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: CREATE_COMMENT,
        payload: {
            request: {
                url: url,
                method: 'POST',
                data: data,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const saveDraftComment = (comment) => (dispatch, getstat) => {
    return dispatch({
        type: SAVE_DRAFT_COMMENT,
        payload: {
            data: comment
        }
    });
};

export const update = (data) => (dispatch, getState) => {
    const url = `${COMMENT_URL}${data.id}/`;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: UPDATE_COMMENT,
        payload: {
            request: {
                url: url,
                method: 'PATCH',
                data: data,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const remove = (data) => (dispatch, getState) => {
    const url = `${COMMENT_URL}${data.id}/`;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: REMOVE_COMMENT,
        payload: {
            request: {
                url: url,
                method: 'DELETE',
                options: {
                    commentId: data.id
                },
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const setLoadingProgress = () => (dispatch, getState) => {
    return dispatch({
        type: SET_COMMENT_LOADINGPROGRESS
    });
};
