import React, { useCallback, useEffect, Fragment } from 'react';
// @ts-ignore
import _ from 'underscore';

// Actions
// @ts-ignore
import { connect } from 'react-redux';
import * as EntryImageActions from 'redux/actions/entryimage';
import * as AvatarImageActions from 'redux/actions/avatarimage';
import * as UserActions from 'redux/actions/user';
import * as EditorActions from 'redux/actions/editor';
import * as V2UserActions from 'redux/actions/v2user';
import { updateUI } from 'redux/actions/ui';
import { UploadProgress } from 'pages/Dialogs/UploadProgress';
// Selectors
import { createStructuredSelector } from 'reselect';
import {
    getEditorState,
    getAvatarImage,
    getUI,
    getImageUploadProgress,
    getUser
} from 'redux/selectors';

import Dialog from '@material-ui/core/Dialog';
import MediaManager from 'components/MediaManager/MediaManager';
import { resetFilesList, updateFilesList } from 'redux/actions/entryimage';
import { resizeImageFile } from 'common/utils/file_utils';

const MediaManagerDialog = ({
    dispatch,
    close,
    mode,
    editor,
    onUpdate,
    avatar,
    entry,
    open,
    ui,
    user,
    progress,
    // @ts-ignore
    ...props
}) => {
    useEffect(() => {
        dispatch(
            updateUI({
                disableButton: true
            })
        );
    }, [dispatch]);
    const _close = useCallback(() => {
        dispatch(EditorActions.resetImageState());
        if (close) {
            close();
        }
    }, [dispatch, close]);

    const done = useCallback(() => {
        if (mode === 'avatar') {
            const data = {
                avatar_image:
                    avatar &&
                    avatar.avatar_image &&
                    avatar.avatar_image.resource_uri
            };
            dispatch(UserActions.update(data, 'AVATAR IMAGE'));
        } else {
            // @ts-ignore
            const orderedEntries = _.uniq(editor.entry_images_order).map(
                (fileName) => {
                    return editor.entry_images.find(
                        (entryImage) => entryImage.file_name === fileName
                    );
                }
            );

            if (orderedEntries) {
                let _i, _len;
                // @ts-ignore
                const _ref = _.compact(orderedEntries);
                const images = [];
                for (_i = 0, _len = _ref.length; _i < _len; _i++) {
                    const image = _ref[_i];
                    const data = {
                        src: image && image.image,
                        type: 'image'
                    };
                    images.push(data);
                }

                dispatch(EditorActions.insertImageBlock(images));
            }
        }

        _close();
    }, [_close, avatar, dispatch, editor, mode]);

    const onProcessing = useCallback(() => {
        dispatch(
            updateUI({
                disableButton: true
            })
        );
    }, [dispatch]);

    const onSuccess = useCallback(() => {
        dispatch(
            updateUI({
                disableButton: false
            })
        );
    }, [dispatch]);

    const handleFileAdded = useCallback(
        async (paramFile, dropzone) => {
            let file = paramFile;

            if (file.size > 1000000 && file?.dataURL) {
                file = await resizeImageFile(file);
            } else if (!file?.dataURL) {
                setTimeout(() => {
                    handleFileAdded(paramFile, dropzone);
                }, 99);
                return;
            }

            if (file.type !== '') {
                dropzone.emit('processing', file);

                const form = new FormData();
                const fileName = file.name;
                form.append('image', file, file.name);
                form.append('file_name', file.name);
                if (mode !== 'avatar') {
                    if (entry && entry.id) {
                        form.append('entry', entry.id);
                    }
                    dispatch(
                        EntryImageActions.createWithProgress(
                            form,
                            fileName,
                            file,
                            dropzone,
                            file.upload.uuid
                        )
                    );
                } else {
                    file.upload = {};
                    dispatch(AvatarImageActions.create(form, file, dropzone));
                    const newForm = new FormData();
                    newForm.append('face', file);
                    dispatch(V2UserActions.update(newForm, user.id));
                }
            } else {
                dropzone.emit('error', file);
            }
        },
        [dispatch, entry, mode, user]
    );

    const setFileWhenUploading = useCallback(
        (id, percentage) => {
            dispatch(updateFilesList({ id, percentage }));
        },
        [dispatch]
    );
    useEffect(() => {
        if (progress === 100) {
            dispatch(resetFilesList());
        }
    }, [progress, dispatch]);
    return (
        <Dialog
            fullWidth={true}
            open={open}
            onClose={_close}
            aria-labelledby="form-dialog-title">
            <div className="modal-box modal__box--dropzone modal-box--800">
                {progress > 0 ? <UploadProgress progress={progress} /> : null}
                <Fragment>
                    <div className="modal__header">Add Photos</div>
                    <MediaManager
                        setFileWhenUploading={setFileWhenUploading}
                        onFileAdded={handleFileAdded}
                        onProcessing={onProcessing}
                        onSuccess={onSuccess}
                        mediaType={mode}
                    />
                    <div className="modal__footer">
                        <div onClick={_close} className="btn btn-outline">
                            Cancel
                        </div>
                        <button
                            className="btn btn-gradient"
                            disabled={ui.disableButton}
                            onClick={done}>
                            Add Photos
                        </button>
                    </div>
                </Fragment>
            </div>
        </Dialog>
    );
};

const state = createStructuredSelector({
    user: getUser,
    editor: getEditorState,
    avatar: getAvatarImage,
    ui: getUI,
    progress: getImageUploadProgress
});
export default connect(state)(MediaManagerDialog);
