import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
// Actions
import * as AAMActions from 'redux/actions/allaboutme';
// Images
import iconCheckSm from 'assets/images/icons_svg/icon_check-sm.svg';

const AAMEditQuestionDialog = ({ close, question }) => {
    const [questionTitle, setQuestionTitle] = useState(question.text);
    const [isSaving, setIsSaving] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const dispatch = useDispatch();

    const updateQuestion = useCallback(async () => {
        if (questionTitle !== '') {
            setIsSaving(true);
            try {
                await dispatch(
                    AAMActions.updateAAMQuestion(question.id, {
                        text: questionTitle
                    })
                );
                setIsSaved(true);
                close();
            } catch (err) {
                //
            } finally {
                setIsSaving(false);
            }
        }
    }, [dispatch, question.id, questionTitle, close]);
    return (
        <div id="edit-question-modal" className="modal-frame">
            <div className="modal-box modal-box--600">
                <div className="modal__header modal__header--button">
                    Edit question
                    {isSaving && (
                        <div className="saving-text pr-2">
                            <div className="saving-gif pr-3" />
                            Saving...
                        </div>
                    )}
                    {!isSaving && isSaved && (
                        <div className="saving-text pr-2">
                            <img src={iconCheckSm} alt="" />
                            Saved!
                        </div>
                    )}
                </div>
                <div className="modal__body">
                    <div className="form-group">
                        <div className="form-group__item pt-0">
                            <input
                                type="text"
                                placeholder="Random Question That Anyone Could Ask?"
                                className="w-100"
                                value={questionTitle}
                                onChange={(e) =>
                                    setQuestionTitle(e.target.value)
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="modal__footer">
                    <button onClick={close} className="btn btn-modal-cancel">
                        Cancel
                    </button>
                    <button
                        disabled={isSaving}
                        onClick={updateQuestion}
                        className="btn btn-modal-primary">
                        Update
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AAMEditQuestionDialog;
