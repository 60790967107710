import React, { Fragment } from 'react';
import { createStructuredSelector } from 'reselect';
import { getFilters, getUI } from 'redux/selectors';
import { connect } from 'react-redux';
import { COMPACT_MODE, DEFAULT_MODE, EXPANDED_MODE } from 'common/constant';

const EntryListViewMode = ({ ui, mobileMode }) => {
    return (
        <Fragment>
            {mobileMode && (
                <div className="help mb-2 d-xl-none d-block help-nav">
                    <div className="container-fluid">
                        <div className="row justify-content-between help-nav-content">
                            <div className="col-md-3 reading-bar-small">
                                <form className="reading-type">
                                    <div className="form-group help-bar-group">
                                        <input
                                            className="help-bar-radio"
                                            type="radio"
                                            id="fullView"
                                            name="reading-type-options"
                                            onChange={() => {}}
                                            checked={!ui.summaryView}
                                        />
                                        <label
                                            className="help-bar-label"
                                            htmlFor="fullView">
                                            Full View
                                        </label>
                                    </div>
                                    <div className="form-group help-bar-group">
                                        <input
                                            className="help-bar-radio"
                                            type="radio"
                                            id="summary"
                                            name="reading-type-options"
                                            onChange={() => {}}
                                            checked={!!ui.summaryView}
                                        />
                                        <label
                                            className="help-bar-label"
                                            htmlFor="summary">
                                            Summary
                                        </label>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-auto reading-bar-small">
                                <form className="reading-view">
                                    <div className="form-group help-bar-group">
                                        <input
                                            className="help-bar-radio"
                                            type="radio"
                                            id="compact"
                                            name="reading-view-options"
                                            value="null"
                                            checked={
                                                ui.viewWidth === COMPACT_MODE
                                            }
                                        />
                                        <label
                                            className="help-bar-label"
                                            htmlFor="noEntries">
                                            Compact
                                        </label>
                                    </div>
                                    <div className="form-group help-bar-group">
                                        <input
                                            className="help-bar-radio"
                                            type="radio"
                                            id="default"
                                            name="reading-view-options"
                                            value="null"
                                            checked={
                                                ui.viewWidth === DEFAULT_MODE
                                            }
                                        />
                                        <label
                                            className="help-bar-label"
                                            htmlFor="default">
                                            Default
                                        </label>
                                    </div>
                                    <div className="form-group help-bar-group">
                                        <input
                                            className="help-bar-radio"
                                            type="radio"
                                            id="expanded"
                                            name="reading-view-options"
                                            value="null"
                                            checked={
                                                ui.viewWidth === EXPANDED_MODE
                                            }
                                        />
                                        <label
                                            className="help-bar-label"
                                            htmlFor="expanded">
                                            Expanded
                                        </label>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

const state = createStructuredSelector({
    ui: getUI,
    filters: getFilters
});

export default connect(state)(EntryListViewMode);
