import React, { useEffect, useRef, useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

// actions
import { connect } from 'react-redux';
import * as AllAboutMeActions from 'redux/actions/allaboutme';
import * as EntryActions from 'redux/actions/entry';
import * as JournalActions from 'redux/actions/journal';

// selectors
import { createStructuredSelector } from 'reselect';
import {
    getUser,
    getMyPublicUser,
    getAAM,
    getQuestionForId,
    getCategoryForQuestion,
    getAnswersForQuestion,
    getJournalList,
    getAamJournal,
    getUI
} from 'redux/selectors';

// components
import EntryList from 'components/Entry/EntryList2';
import EntryCreateAAM from 'pages/Entry/EntryCreateAAM';
import QuestionCard from './QuestionCard';

// Icons
import iconAngleLeft from 'assets/images/icons_svg/icon_angle-left.svg';

const AllAboutMeQuestion = ({
    dispatch,
    ui,
    question,
    category,
    answers,
    user,
    publicUser,
    journal,
    match,
    aamJournal
}) => {
    // refs
    const entryList = useRef();
    // router hooks
    const location = useLocation();

    const [isFetched, setIsFetched] = useState(false);

    // componentDidMount
    useEffect(() => {
        const { params } = match;

        if (!isFetched) {
            dispatch(AllAboutMeActions.fetchCategoryList());
            dispatch(AllAboutMeActions.fetchQuestion(params.questionId));
            dispatch(
                EntryActions.fetchAnswers({ question: params.questionId })
            );
            dispatch(JournalActions.fetch());

            setIsFetched(true);
        }
    }, [dispatch, isFetched, match]);

    // check if aamJournal exists
    const journalPending = useMemo(() => journal?.isPending, [journal]);
    useEffect(() => {
        if (journalPending === false && aamJournal === undefined) {
            // create aam journal
            //dispatch(JournalActions.createAAMJournal());
        }
        return () => {};
    }, [aamJournal, dispatch, journalPending]);

    const id_ = category?.id;
    const aamCatURL = `/all-about-me/category/${id_}`;
    const categoryName = useMemo(() => category?.name, [category]);
    return (
        <>
            <div className="page-container">
                <div className="back-to-all-about-me-content">
                    <NavLink to={aamCatURL}>
                        <img
                            src={iconAngleLeft}
                            alt=""
                            className="icon-static btn-icon--6"
                        />
                        Back to {categoryName}
                    </NavLink>
                </div>
                {location.pathname.includes('question') ? (
                    <EntryCreateAAM
                        questionId={match.params.questionId}
                        categoryName={categoryName}
                    />
                ) : (
                    <div>
                        {question && (
                            <QuestionCard
                                question={question}
                                answers={answers?.entries}
                            />
                        )}
                    </div>
                )}
                <EntryList
                    ref={entryList}
                    entries={answers?.entries}
                    entryUsers={answers?.entryUsers}
                    journals={journal?.journals}
                    questions={[question]}
                    user={user}
                    publicuser={publicUser}
                    compact={true}
                />
            </div>
        </>
    );
};

const state = createStructuredSelector({
    ui: getUI,
    user: getUser,
    publicUser: getMyPublicUser,
    aam: getAAM,
    category: getCategoryForQuestion,
    question: getQuestionForId,
    answers: getAnswersForQuestion,
    journal: getJournalList,
    aamJournal: getAamJournal
});
export default connect(state)(AllAboutMeQuestion);
