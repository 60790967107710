import _ from 'underscore';

import { createSelector } from 'reselect';

export const BookShippingAddressSelector = (state) => state;

export const BookShippingAddressForBook = (state, props) => {
    let book;
    if (props.match?.params.bookId) {
        const books = state.book.books;
        book = _.findWhere(books, { id: parseInt(props.match?.params.bookId) });
    } else {
        book = props.book;
    }

    if (book?.shipping_address) {
        const shippingAddress = state.book_shipping_address.shippingAddresses?.find(
            (shipping_adress) =>
                shipping_adress.resource_uri === book.shipping_address
        );

        return shippingAddress;
    }
    return undefined;
};

export const getShippingAddressForBook = createSelector(
    [BookShippingAddressForBook],
    (shippingAddress) => shippingAddress
);

export const getShippingAddresses = createSelector(
    [BookShippingAddressSelector],
    (state) => {
        let _shippingAddress = [];
        (state.book_shipping_address.shippingAddresses || []).sort(function (
            a,
            b
        ) {
            return b.default - a.default;
        });
        _shippingAddress = (
            state.book_shipping_address.shippingAddresses || []
        ).filter(onlyUnique);
        _shippingAddress = _shippingAddress.filter((x) => !x.hidden);

        return {
            ...state,
            book_shipping_address: {
                shippingAddresses: _shippingAddress,
                meta: state.book_shipping_address.meta
            }
        };
        // return _shippingAddress;
    }
);

const onlyUnique = (value, index, self) => {
    const findIndex = (element) =>
        element.first_name === value.first_name &&
        element.last_name === value.last_name &&
        element.street === value.street &&
        element.street2 === value.street2 &&
        element.state === value.state &&
        element.zip_code === value.zip_code &&
        element.city === value.city &&
        element.country === value.country;
    return self.findIndex(findIndex) === index;
};
