import { createSelector } from 'reselect';

export const NotificationSelector = (state) => state.notification;

export const getNotificationsLimit = createSelector(
    NotificationSelector,
    (state) => state.limit
);
export const getNotificationsTotalCount = createSelector(
    NotificationSelector,
    (state) => state.total_count
);
export const getNotifications = createSelector(
    [NotificationSelector, getNotificationsLimit],
    (notification, limit) => {
        return [...(notification.notifications || [])].filter(
            (item, idx) => idx <= limit
        );
    }
);
