import _ from 'underscore';
import moment from 'moment';
import { ENTRY_DO_NOT_PRINT, ENTRY_ALL, ENTRY_DATE } from 'common/constant';
import { SUBTITLE_OPTION_CUSTOM, SUBTITLE_OPTION_RANGE } from 'common/constant';

export default (book, journals) => {
    const {
        all_about_me,
        start_date,
        end_date,
        journal,
        color,
        photos,
        comments,
        title,
        subtitle,
        spine,
        back_cover,
        custom_introduction,
        extra_journals,
        tags,
        aam_start_datetime,
        aam_end_datetime,
        media_option: mediaOptions,
        media_privacy_permission: mediaPrivacyOptions,
        timezone
    } = book;

    const aam = _.isEmpty(all_about_me) ? ENTRY_DO_NOT_PRINT : all_about_me;

    const entryRange =
        !start_date && !end_date
            ? journal || (extra_journals && extra_journals.length > 0)
                ? ENTRY_ALL
                : ENTRY_DO_NOT_PRINT
            : ENTRY_DATE;

    let minDate = start_date || '',
        maxDate = end_date || '',
        aamMinDate,
        aamMaxDate;

    if (minDate !== '') {
        minDate = new Date(minDate);
    }
    if (maxDate !== '') {
        maxDate = new Date(maxDate);
    }

    const subtitleOption =
        subtitle !== '' ? SUBTITLE_OPTION_CUSTOM : SUBTITLE_OPTION_RANGE;

    if (aam_start_datetime) {
        aamMinDate = new Date(moment(aam_start_datetime).format('YYYY-MM-DD'));
        aamMaxDate = new Date(moment(aam_end_datetime).format('YYYY-MM-DD'));
    }

    const selectedJournal = _.findWhere(journals, { resource_uri: journal });

    const ui = {
        aam,
        entryRange,
        minDate,
        maxDate,
        color,
        photos,
        comments,
        selectedJournal,
        title,
        subtitle,
        subtitleOption,
        spine,
        back_cover,
        custom_introduction,
        book_extra_journals: extra_journals,
        book_tags: tags,
        book_select_journal: extra_journals.length ? true : false,
        aamMinDate,
        aamMaxDate,
        mediaOptions,
        mediaPrivacyOptions,
        timezone
    };

    return ui;
};
