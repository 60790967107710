import { createSelector } from 'reselect';

export const BackgroundImageSelector = (state) => state.background_images;

export const getBackgroundImages = createSelector(
    [BackgroundImageSelector],
    (data) => {
        return {
            is_pending: data.is_pending,
            images: data.background_images
        };
    }
);
