import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// Actions
import { updateUI } from 'redux/actions/ui';
// dialogs
import BookCreateDialog from 'pages/Dialogs/BookCreateDialog';
// Components
import withBook from 'common/utils/components/withBook';
// selectors
import { getBookById } from 'redux/selectors';

const BookSaveDialog = ({ dispatch, close, book }) => {
    const backHome = useCallback(() => {
        close();
    }, [close]);

    const continueEditing = useCallback(() => {
        dispatch(
            updateUI({
                dialogs: [withBook(BookCreateDialog, book.id)]
            })
        );
    }, [dispatch, book]);

    return (
        <div id="book-save-modal" className="modal-frame">
            <div className="modal-box modal-box--400">
                <div className="modal__header">Book Saved!</div>
                <div className="modal__body">
                    <p>
                        We’ve saved your book, so you can continue editing
                        anytime in the future. You will find your saved books on
                        the “Book” tab.
                    </p>
                </div>
                <div className="modal__footer">
                    <button
                        className="btn btn-outline"
                        onClick={continueEditing}>
                        Continue Editing
                    </button>
                    <button className="btn btn-gradient" onClick={backHome}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    book: getBookById
});
export default connect(state)(BookSaveDialog);
