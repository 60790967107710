import React, { useCallback, useEffect, useRef, useState } from 'react';
// @ts-ignore
import { connect } from 'react-redux';

// Actions
import * as EntryActions from 'redux/actions/entry';
import * as EditorActions from 'redux/actions/editor';
import * as TagActions from 'redux/actions/tag';
import * as AllAboutMeActions from 'redux/actions/allaboutme';
import * as PublicUserActions from 'redux/actions/publicuser';
import { updateUI } from 'redux/actions/ui';
// @ts-ignore
import { push } from 'react-router-redux';
// @ts-ignore
import { useHistory } from 'react-router-dom';

// Selectors
import { createStructuredSelector } from 'reselect';
import {
    getAAM,
    getDefaultJournal,
    getEditorState,
    getEntryById,
    getEntryState,
    getJournalForEntry,
    getTagList,
    getUI,
    getUser
} from 'redux/selectors';

// components
import { EntryEditor } from 'components/Editor';
import withEntry from 'common/utils/components/withEntry';

// Dialogs
import MediaManagerDialog from 'pages/Dialogs/MediaManagerDialog';
import DateTimeDialog from 'pages/Dialogs/DateTimeDialog';

// Constants
import {
    ENTRY_PUBLISHED,
    ENTRY_QA,
    SOCIAL_TYPE_FACEBOOK,
    SOCIAL_TYPE_GOOGLE,
    SOCIAL_TYPE_INSTAGRAM
} from 'common/constant';
// import withFreeAccountCheck from 'common/utils/components/withFreeAccountCheck';
import withSocialType from 'common/utils/components/withSocialType';
import SocialMediaPhotosDialog from 'pages/Dialogs/SocialMedia/SocialMediaPhotosDialog';
import SocialConnectDialog from 'pages/Dialogs/SocialMedia/SocialConnectDialog';
import BannerCard from 'components/BannerCard';

// images
import oopsImage from 'assets/images/banners/banner-oops.png';
import { checkResponseSuccess } from 'common/utils/responseUtils';
import withFreeAccountCheck from 'common/utils/components/withFreeAccountCheck';
import arrayUtils from 'common/utils/arrayUtils';
import moment from 'moment';
import * as CalendarActions from 'redux/actions/calendar';
import { openDialog } from 'common/utils/dialog-utils';
import EntrySaveIssueDialog from 'pages/Dialogs/EntrySaveIssueDialog';
import { iosMediaPermission } from '../../components/media/Permission';

const EntryEdit = ({
    router,
    route,
    user,
    selectedEntry,
    dispatch,
    aam,
    tags,
    ui,
    selectedJournal,
    ...props
}) => {
    const [hasChanged, setHasChanged] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [autoSave, setAutoSave] = useState(false); // the variable for letting client know about auto saving.
    const [saveSelectedEntry, setSaveSelectedEntry] = useState(null);
    const [loading, setLoading] = useState(true);
    const [newEntryJournal, setNewEntryJournal] = useState(selectedJournal);
    const [isFetched, setIsFetched] = useState(false);
    const autoSaveTimer = useRef(null);
    const [exactJournal, setExactJournal] = useState({
        resource_uri: null
    });
    const history = useHistory();
    const [didMount, setDidMount] = useState(true);
    const [updateSelectedEntry, setUpdateSelectedEntry] = useState(null);

    useEffect(() => {
        setHasChanged(false);
        setIsSaved(false);
        setIsSaving(false);
        setSaveSelectedEntry(null);
    }, []);

    useEffect(() => {
        if (selectedEntry) {
            const content = selectedEntry?.content;
            let newcontent = '';
            const regexp = /<img([\w\W]+?)\/>/gi;
            let match;
            let pIndex = 0;
            while ((match = regexp.exec(content)) !== null) {
                newcontent += content.substring(pIndex, match.index - 1);
                newcontent = `${newcontent}<p>${match[0]}</p>`;
                pIndex = regexp.lastIndex;
            }
            newcontent += content.substring(pIndex, content.length);
            const bufEntry = { ...selectedEntry, content: newcontent };
            setUpdateSelectedEntry(bufEntry);
        }
    }, [selectedEntry]);

    useEffect(() => {
        // update edit entry
        if (
            JSON.stringify(updateSelectedEntry) !==
            JSON.stringify(saveSelectedEntry)
        ) {
            setSaveSelectedEntry(updateSelectedEntry);

            dispatch(
                updateUI({
                    editEntry: {
                        ...updateSelectedEntry
                    }
                })
            );
            setLoading(false);
        } else {
            setLoading(false);
        }
    }, [updateSelectedEntry, dispatch, saveSelectedEntry]);

    const onEditorChange = useCallback(() => {
        if (!hasChanged) {
            setHasChanged(true);
            setIsSaved(false);
        }
    }, [hasChanged]);

    const onSave = useCallback(
        (entry, finish = false, journals) => {
            if (isSaving === true) {
                return;
            }
            setIsSaving(true);
            setAutoSave(false);

            const keys = ['title', 'content', 'tags'];
            let newEntry = Object.assign(
                {},
                arrayUtils.pick(ui.editEntry, keys),
                entry
            );

            newEntry.extra_journals = (journals || []).map((extra_journal) => {
                return extra_journal.resource_uri;
            });
            newEntry.journal = !arrayUtils.isEmpty(exactJournal)
                ? exactJournal?.resource_uri
                : !arrayUtils.isEmpty(journals)
                ? journals[0].resource_uri
                : selectedJournal.resource_uri;

            if (entry?.location?.trim()) {
                newEntry.location = entry.location;
            }

            if (newEntry.tags && !arrayUtils.isEmpty(newEntry.tags)) {
                newEntry.tags = newEntry.tags.filter((tag) => !tag.className);

                newEntry.tags = newEntry.tags.map((tag) => {
                    const keys = ['id', 'name'];
                    return arrayUtils.pick(tag, keys);
                });
            }

            if ((entry && entry).entry_type === ENTRY_QA) {
                newEntry = arrayUtils.pick(newEntry, [
                    'content',
                    'title',
                    'tags'
                ]);
            }

            if (newEntry.entry_date === 'Today') {
                newEntry.entry_date = moment()
                    .utc()
                    .format('YYYY-MM-DDTHH:mm:ss');
            }

            newEntry.status = ENTRY_PUBLISHED;

            dispatch(EntryActions.update(newEntry, updateSelectedEntry.id))
                .then((response) => {
                    if (checkResponseSuccess(response.payload?.status)) {
                        if (finish) {
                            // user click the save button
                            clearInterval(autoSaveTimer.current);
                            history.push('/');
                        } else {
                            // auto saving
                            setIsSaved(true);
                            setIsSaving(false);
                            setHasChanged(false);
                            setAutoSave(false);
                        }
                        dispatch(CalendarActions.fetch());
                    }
                })
                .catch(() => {
                    setIsSaving(false);
                    setIsSaved(false);
                    openDialog(EntrySaveIssueDialog, {
                        errorMsg:
                            'Poor connection: Saving is not possible at the moment.'
                    });
                });
        },
        [
            exactJournal,
            autoSaveTimer,
            ui.editEntry,
            isSaving,
            dispatch,
            history,
            updateSelectedEntry,
            selectedJournal
        ]
    );

    const saveEntry = useCallback(() => {
        // make the client call onSave
        if (!autoSave) {
            setAutoSave(true);
        }
    }, [autoSave]);

    const saveIfChange = useCallback(() => {
        if (
            hasChanged &&
            !isSaved &&
            !isSaving &&
            !(tags && tags).isPending &&
            !(props.entry && props.entry).isPending &&
            !ui.showDialog
        ) {
            saveEntry();
            setHasChanged(false);
            setIsSaved(true);
        }
    }, [
        hasChanged,
        isSaved,
        isSaving,
        tags,
        props.entry,
        ui.showDialog,
        saveEntry
    ]);

    useEffect(() => {
        clearInterval(autoSaveTimer.current);
        const timer = setInterval(saveIfChange, 10000);
        autoSaveTimer.current = timer;
    }, [saveIfChange]);

    useEffect(() => {
        const { params } = props.match;
        if (arrayUtils.isEmpty(updateSelectedEntry) && params.entryId) {
            if (!isFetched) {
                dispatch(EntryActions.fetch({ id: params.entryId }));
                //dispatch(JournalActions.fetch());
                dispatch(PublicUserActions.fetch());
                dispatch(TagActions.fetch());
                dispatch(AllAboutMeActions.fetchQuestionList());
                setIsFetched(true);
            }
        }
    }, [dispatch, props.match, updateSelectedEntry, isFetched]);

    const onCancel = useCallback(() => {
        dispatch(EditorActions.resetImageState());
        dispatch(push('/'));
    }, [dispatch]);

    const onInsertImage = useCallback(() => {
        const entry = ui.editEntry;
        dispatch(
            updateUI({
                dialogs: [
                    withEntry(iosMediaPermission(MediaManagerDialog), entry)
                ]
            })
        );
    }, [dispatch, ui.editEntry]);

    const onInsertInstagram = useCallback(() => {
        dispatch(
            updateUI({
                dialogs: [
                    withSocialType(
                        iosMediaPermission(SocialMediaPhotosDialog),
                        SOCIAL_TYPE_INSTAGRAM
                    )
                ]
            })
        );
    }, [dispatch]);

    const onInsertFaceBook = useCallback(() => {
        dispatch(
            updateUI({
                dialogs: [
                    withSocialType(
                        iosMediaPermission(SocialMediaPhotosDialog),
                        SOCIAL_TYPE_FACEBOOK
                    )
                ]
            })
        );
    }, [dispatch]);

    const onInsertGoogle = useCallback(() => {
        dispatch(
            updateUI({
                dialogs: [
                    withFreeAccountCheck(
                        withSocialType(
                            iosMediaPermission(SocialConnectDialog),
                            SOCIAL_TYPE_GOOGLE
                        ),
                        true
                    )
                ]
            })
        );
    }, [dispatch]);

    const onRemoveImage = useCallback(
        (src) => {
            dispatch(EditorActions.unstageEntryImage({ src }));
        },
        [dispatch]
    );

    const onEntryJournalUpdate = useCallback(
        (journal) => {
            const newEntry = Object.assign({}, ui.newEntry, journal);
            didMount && setDidMount(false);
            setNewEntryJournal(journal);
            dispatch(
                updateUI({
                    newEntry
                })
            );
        },
        [dispatch, ui.newEntry, didMount]
    );

    const onEntryDateUpdate = useCallback(
        (newEntry) => {
            const entry = ui.editEntry;
            const eDate = newEntry.entry_date;
            const eDT = newEntry.display_time;

            dispatch(
                EntryActions.update(
                    {
                        entry_date: eDate,
                        display_time: eDT,
                        dateChanged: newEntry?.dateChanged || false
                    },
                    entry?.id
                )
            ).then(() => {
                entry.entry_date = eDate;
                entry.display_time = eDT;
            });
        },
        [dispatch, ui.editEntry]
    );

    const onEntryDateClick = useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();

            const entry = ui.editEntry;

            dispatch(
                updateUI({
                    dialogs: [
                        withEntry(DateTimeDialog, entry, onEntryDateUpdate)
                    ]
                })
            );
        },
        [dispatch, ui.editEntry, onEntryDateUpdate]
    );

    const onEntryTitleUpdate = useCallback(
        // @ts-ignore
        (title) => {
            if (ui.editEntry.entry_type !== ENTRY_QA) {
                setHasChanged(true);
            }
        },
        [ui.editEntry]
    );

    const onEntryTagUpdate = useCallback(
        (tag, isAdd) => {
            const editEntry = { ...ui.editEntry };
            if (isAdd) {
                if (!editEntry.tags) {
                    editEntry.tags = [];
                }
                editEntry.tags.push(tag.resource_uri);
            } else {
                editEntry.tags = tag;
            }
            dispatch(
                updateUI({
                    editEntry
                })
            );

            setHasChanged(true);
            setIsSaved(false);
        },
        [ui.editEntry, dispatch]
    );

    const { entry } = props;

    const question = ui.editEntry.question;
    useEffect(() => {
        const { params } = props.match;
        dispatch(EntryActions.fetchIndividualEntry(params.entryId)).then(
            (response) => {
                if (response?.payload?.status === 200) {
                    setIsFetched(true);
                    dispatch(
                        updateUI({
                            editEntry: response.payload.data
                        })
                    );
                }
            }
        );
        // eslint-disable-next-line
    }, []);

    const entryLoaded = !entry?.isInitialLoad && !entry?.isPending && !loading;
    return (
        <div className="timeline-view-container timeline-edit-entry">
            <section className="timeline">
                <div className={`container centered-items ${ui.viewWidth}`}>
                    {!entryLoaded && (
                        <>
                            <div
                                style={{ marginLeft: '40%' }}
                                className="saving-text pr-2">
                                {' '}
                                <div className="saving-gif pr-3" /> Loading...
                            </div>
                        </>
                    )}
                    {entryLoaded && ui.editEntry.content && (
                        <EntryEditor
                            isEdit={true}
                            didMount={didMount}
                            setExactJournal={setExactJournal}
                            entry={ui.editEntry || {}}
                            user={user}
                            journal={newEntryJournal || {}}
                            onChange={onEditorChange}
                            onSave={onSave}
                            onCancel={onCancel}
                            onInsertImage={onInsertImage}
                            onInsertInstagram={onInsertInstagram}
                            onInsertFacebook={onInsertFaceBook}
                            onInsertGoogle={onInsertGoogle}
                            onRemoveImage={onRemoveImage}
                            onEntryDateClick={onEntryDateClick}
                            onEntryJournalUpdate={onEntryJournalUpdate}
                            onEntryTagUpdate={onEntryTagUpdate}
                            isTagsLoading={tags.isPending}
                            displayMode={'full'}
                            question={question}
                            onEntryTitleUpdate={onEntryTitleUpdate}
                            hasChangedSinceLastSave={hasChanged}
                            isSaving={isSaving}
                            isSaved={isSaved}
                            hideCancel={true}
                            autoSave={autoSave}
                            created={true}
                            canSave={!isSaving}
                        />
                    )}
                    {entryLoaded && !selectedEntry && (
                        <BannerCard
                            bannerClassName={'oops-card banner-card--dark'}
                            image={oopsImage}
                            title={"Oops! We didn't find anything"}
                            text={
                                "We couldn't find any post matching your search. Try changing your filters and search again"
                            }
                            id={undefined}
                        />
                    )}
                </div>
            </section>
        </div>
    );
};

const state = createStructuredSelector({
    user: getUser,
    entry: getEntryState,
    selectedEntry: getEntryById,
    editor: getEditorState,
    defaultJournal: getDefaultJournal,
    selectedJournal: getJournalForEntry,
    tags: getTagList,
    aam: getAAM,
    ui: getUI
});

export default connect(state)(EntryEdit);
