import _ from 'underscore';
import { createSelector } from 'reselect';
import { getPublicUserList } from 'redux/selectors/PublicUserSelector';
import { getFilters } from './FiltersSelector';
import { SORT } from 'common/constant';

export const SearchSelector = (state) => state.search;

export const getSearch = createSelector(
    [SearchSelector, getPublicUserList, getFilters],
    (search, public_user, filters) => {
        let results = search.results;
        if (filters?.filterByJournal?.length > 0 && search?.results) {
            const filterIds = filters.filterByJournal.map(
                (filter) => filter.id
            );
            results = _.filter(results, (entry) => {
                return filterIds.includes(entry.journal.id);
            });
        }
        const entryUserResourceUriList = _.uniq(_.pluck(results, 'user'));
        const { public_users } = public_user;

        let entryUsers = undefined;

        if (public_users) {
            entryUsers = entryUserResourceUriList.map((user) => {
                return public_users.find((item) => {
                    return item.resource_uri === user;
                });
            });
        }

        return _.extend({}, search, {
            fetched: search.fetched,
            entryUsers,
            results: _.sortBy(results, (entry) => {
                return -new Date(entry[SORT.ENTRY_DATE_OLDEST]).getTime();
            })
        });
    }
);
