import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// core components
import TextField from 'components/TextField';
import ExpirationDateInput from '../../../components/CreditCardForm/ExpirationDateInput';

// redux selectors
import { getUI } from 'redux/selectors';

// redux actions
import { updateUI } from 'redux/actions/ui';
import * as PaymentProfileActions from 'redux/actions/paymentprofile';

// utils
import arrayUtils from 'common/utils/arrayUtils';
import { useState } from 'react';

const NewCreditCard = ({
    ui,
    dispatch,
    paymentProfile,
    book,
    onCreateBookOrder
}) => {
    const [paymentSubmitted, setPaymentSubmitted] = React.useState(false);
    const [paymentError, setPaymentError] = React.useState({});
    const [expireDateTxt, setExpireDateTxt] = useState('');
    const [expireDateTxtError, setExpireDateTxtError] = useState(false);

    const updatePaymentProfile = React.useCallback(
        (e) => {
            const paymentProfile = { ...ui.paymentProfile };
            paymentProfile[e.target.name] = e.target.value;

            dispatch(
                updateUI({
                    paymentProfile
                })
            );
        },
        [dispatch, ui]
    );

    // payment form validated
    const onPaymentValidated = (name, error) => {
        setPaymentError((prev) => ({ ...prev, [name]: error }));
    };

    const createBookOrder = React.useCallback(
        async (createdPaymentProfile) => {
            try {
                onCreateBookOrder && onCreateBookOrder(createdPaymentProfile);
            } catch (e) {
                console.log(e);
            }
        },
        [onCreateBookOrder]
    );

    const onNext = React.useCallback(() => {
        if (paymentProfile) {
            createBookOrder(paymentProfile);
        } else {
            setPaymentSubmitted(true);

            if (arrayUtils.checkErrorObjValidated(paymentError)) {
                const newPaymentProfile = {
                    expiration_year: `20${expireDateTxt.split('/')[1]}`,
                    expiration_month: parseInt(expireDateTxt.split('/')[0]),
                    card_type: 'visa',
                    card_number: ui.paymentProfile.card_number,
                    default: true,
                    card_name: ui.paymentProfile.card_name,
                    cvv: ui.paymentProfile.cvv
                };

                dispatch(PaymentProfileActions.create(newPaymentProfile)).then(
                    async (response) => {
                        if (response?.payload?.status === 201) {
                            createBookOrder(response.payload.data);
                        }
                    },
                    (response) => {
                        const validation_errors =
                            response?.error?.response?.data?.validation_errors;

                        if (validation_errors) {
                            Object.keys(validation_errors).forEach((name) => {
                                setPaymentError((prev) => ({
                                    ...prev,
                                    [name]: validation_errors[name][0]
                                }));
                            });
                        }
                    }
                );
            }
        }
    }, [
        paymentProfile,
        createBookOrder,
        dispatch,
        ui,
        paymentError,
        expireDateTxt
    ]);

    const checkOrderValidity = React.useCallback(() => {
        return !(book?.shipping_address && book?.approved && ui.paymentProfile);
    }, [book, ui]);

    React.useEffect(() => {
        if (
            paymentProfile &&
            JSON.stringify(ui?.paymentProfile) !==
                JSON.stringify(paymentProfile)
        ) {
            dispatch(
                updateUI({
                    paymentProfile
                })
            );
        } else if (!ui.paymentProfile) {
            dispatch(
                updateUI({
                    paymentProfile: {}
                })
            );
        }
    }, [paymentProfile, ui, dispatch]);

    return (
        <React.Fragment>
            <div className="add-new-credit-card">
                <div className="settings-list-title">Credit Card Details</div>

                <div className="form-group">
                    <div className="form-group__row">
                        <div className="form-group__item">
                            <TextField
                                label="Name on Card"
                                className="payment-input"
                                value={ui.paymentProfile?.card_name || ''}
                                id="cname"
                                name="card_name"
                                onChange={updatePaymentProfile}
                                required={true}
                                submitted={paymentSubmitted}
                                onValidated={onPaymentValidated}
                                errorProps={{
                                    style: {}
                                }}
                                error={paymentError['card_name']}
                            />
                        </div>
                        <div className="form-group__item">
                            <TextField
                                label="Card Number"
                                className="payment-input"
                                value={ui.paymentProfile?.card_number || ''}
                                id="cnumber"
                                name="card_number"
                                onChange={updatePaymentProfile}
                                required={true}
                                submitted={paymentSubmitted}
                                onValidated={onPaymentValidated}
                                errorProps={{
                                    style: {}
                                }}
                                error={paymentError['card_number']}
                            />
                        </div>
                    </div>
                    <div className="form-group__row form-group__row--exp-cvv">
                        <ExpirationDateInput
                            expireDateTxt={expireDateTxt}
                            setExpireDateTxt={setExpireDateTxt}
                            expireDateTxtError={expireDateTxtError}
                            setExpireDateTxtError={setExpireDateTxtError}
                        />

                        <div className="form-group__item">
                            <TextField
                                label="CVV"
                                className="payment-input-half-size"
                                value={ui.paymentProfile?.cvv || ''}
                                id="book-order-payment-card-cvv"
                                name="cvv"
                                onChange={updatePaymentProfile}
                                required={true}
                                submitted={paymentSubmitted}
                                onValidated={onPaymentValidated}
                                errorProps={{
                                    style: { color: '#fff' }
                                }}
                                error={paymentError['cvv']}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className=" payment-checkout-btn">
                <button
                    className="btn btn-gradient btn--md"
                    onClick={onNext}
                    disabled={checkOrderValidity()}>
                    Checkout
                </button>
            </div>
        </React.Fragment>
    );
};

const state = createStructuredSelector({
    ui: getUI
});
export default connect(state)(NewCreditCard);
