import React, { useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import * as FilterActions from 'redux/actions/filter';
import * as EntryActions from 'redux/actions/entry';
import { updateUI } from 'redux/actions/ui';

// core components
import DropDown from 'components/DropDown';

import { ENTRY_DRAFT, ENTRY_HIDDEN, SORT } from 'common/constant';

// icons
import iconCaretDown from 'assets/images/icons_svg/icon_caret-down.svg';
import { checkResponseSuccess } from 'common/utils/responseUtils';

const SortFilter = ({ dispatch }) => {
    const values = useMemo(() => {
        return [
            {
                value: SORT.ENTRY_DATE_NEWEST,
                label: 'Entry Date (Newest First)'
            },
            {
                value: SORT.ENTRY_DATE_OLDEST,
                label: 'Entry Date (Older First)'
            },
            {
                value: SORT.CREATED_NEWEST,
                label: 'Date Created (Newest First)'
            },
            { value: SORT.CREATED_OLDEST, label: 'Date Created (Older First)' }
        ];
    }, []);
    const [selected, setSelected] = useState(values[0]);

    const onSort = useCallback(
        (sortValue) => {
            dispatch(
                FilterActions.update({
                    entrySort: sortValue
                })
            );
            dispatch(EntryActions.setLoadingProgress());
            dispatch(
                updateUI({
                    showingProgressBar: true
                })
            );
            dispatch(EntryActions.reset());
            dispatch(EntryActions.fetch()).then((response) => {
                if (checkResponseSuccess(response.payload.status)) {
                    for (const entry of response?.payload?.data?.objects) {
                        if (
                            entry.state !== ENTRY_HIDDEN &&
                            entry.status !== ENTRY_DRAFT
                        ) {
                            dispatch(
                                updateUI({
                                    activeDate: entry.entry_date
                                })
                            );
                            break;
                        }
                    }
                }
            });
        },
        [dispatch]
    );

    const onSelect = useCallback(
        (value) => {
            onSort(value.value);
            setSelected(value);
        },
        [onSort]
    );

    const createItems = useCallback(() => {
        return values.map((value) => (
            <li
                id={value.value}
                key={value.value}
                onClick={() => onSelect(value)}
                className={selected.value === value.value ? 'selected' : ''}>
                {value.label}
            </li>
        ));
    }, [onSelect, selected, values]);

    return (
        <div className="form-group">
            <div className="form-group__item">
                <label>Sort by</label>
                <DropDown
                    className="form-group__select"
                    id="sortByModal"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    anchor={
                        <React.Fragment>
                            <span className="form-group__select-text">
                                {selected.label}
                            </span>
                            <img src={iconCaretDown} alt="" />
                        </React.Fragment>
                    }
                    dropDownMenuProps={{
                        'aria-labelledby': 'sortByModal'
                    }}>
                    {createItems()}
                </DropDown>
            </div>
        </div>
    );
};

export default React.memo(connect()(SortFilter));
