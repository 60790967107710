import { MenuItem, Select } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import * as JournalActions from 'redux/actions/journal';
import { createStructuredSelector } from 'reselect';
import { getJournalList, getUser } from 'redux/selectors';
import * as JournalEmailActions from 'redux/actions/journalEmail';
import { closeDialog } from 'common/utils/dialog-utils';
import { AAM_JOURNAL_TYPES } from 'common/constant';

const JournalEmailDialog = ({ dispatch, journals: propsJournals, user }) => {
    const [selectedJournal, setSelectedJournal] = useState('select');
    const [isDisabled, setIsDisabled] = useState(true);
    const [isFetched, setIsFetched] = useState(false);
    const ref = React.useRef(null);

    const journals = useMemo(
        () =>
            (propsJournals?.journals || []).filter(
                (item) =>
                    !AAM_JOURNAL_TYPES.includes(item.journal_type) &&
                    item.user.id === user.id
            ),
        [propsJournals, user.id]
    );

    useEffect(() => {
        if (!isFetched) {
            dispatch(JournalActions.fetch());
            setIsFetched(true);
        }
    }, [dispatch, isFetched]);

    useEffect(() => {
        setIsDisabled(selectedJournal === 'select');
    }, [selectedJournal]);

    const createJournalEmail = useCallback(
        (e) => {
            e.preventDefault();
            dispatch(
                JournalEmailActions.create_journal_email({
                    journal_id: selectedJournal
                })
            );
            closeDialog();
        },
        [dispatch, selectedJournal]
    );

    return (
        <div id="add-address-modal" className="modal-frame">
            <div className="modal-box modal-box--600">
                <div className="modal__header">Change an address</div>
                <div className="modal__body">
                    <form>
                        <div className="form-group form-group--single">
                            <div className="form-group__item">
                                <label htmlFor="journals">Journals</label>
                                <Select
                                    id="mui-select"
                                    value={selectedJournal}
                                    onChange={(e) =>
                                        setSelectedJournal(e.target.value)
                                    }
                                    ref={ref}
                                    MenuProps={{
                                        anchorEl: ref.current,
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left'
                                        },
                                        className: 'select-dropdown'
                                    }}>
                                    <MenuItem value="select">Select</MenuItem>
                                    {journals.map((journal) => (
                                        <MenuItem
                                            key={journal.id}
                                            value={journal.id}>
                                            {journal.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal__footer">
                    <button className="btn btn-outline" onClick={closeDialog}>
                        Cancel
                    </button>
                    <button
                        className="btn btn-gradient"
                        onClick={createJournalEmail}
                        disabled={isDisabled}>
                        Change address
                    </button>
                </div>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    journals: getJournalList,
    user: getUser
});
export default connect(state)(JournalEmailDialog);
