import React from 'react';
// page images
import DonationView from 'pages/Donation/DonationView';

const donation = () => {
    return (
        <div>
            <main
                id="main"
                style={{ marginLeft: 0 }}
                className="app-main full-view">
                <DonationView />
            </main>
        </div>
    );
};

export default donation;
