import React, { useCallback, useState, useEffect } from 'react';
import { getJournalList, getUI } from 'redux/selectors';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { TextField } from '@material-ui/core';
// icons
import iconCaretDown from 'assets/images/icons_svg/icon_caret-down.svg';
import iconCheckSm from 'assets/images/icons_svg/icon_check-sm.svg';
// import iconCloseSm from 'assets/images/icons_svg/icon_close-sm.svg';

import * as JournalActions from 'redux/actions/journal';
import * as EntryActions from 'redux/actions/entry';

import { updateUI } from 'redux/actions/ui';

const CopyEntryDialog = ({
    journals,
    dispatch,
    ui,
    close,
    entry,
    editable = true,
    ...props
}) => {
    const [openJournalsDrop, setOpenJournalsDrop] = useState(false);
    const [copyEntryJournal, setCopyEntryJournal] = useState();
    const [sharedUsers, setSharedUsers] = useState([]);
    const [enableCopiedTitle, setEnableCopiedTitle] = useState(false);
    const [oneEntry, setOneEntry] = useState();
    const [copyEntryTitle, setCopyEntryTitle] = useState('');

    useEffect(() => {
        if (ui.bulkMode) {
            if (ui.bulkEntries && ui.bulkEntries.length === 1) {
                setEnableCopiedTitle(true);
                setOneEntry(ui.bulkEntries[0]);
            }
        } else {
            setEnableCopiedTitle(true);
            setOneEntry(entry);
        }
    }, [ui, entry]);

    useEffect(() => {
        oneEntry && oneEntry.title
            ? setCopyEntryTitle(`Copy of ${oneEntry.title}`)
            : setCopyEntryTitle('No title');
    }, [oneEntry]);

    const _close = useCallback(() => {
        if (ui.bulkMode) {
            dispatch(
                updateUI({
                    bulkMode: false,
                    bulkEntries: []
                })
            );
        }

        if (close) {
            close();
        }
    }, [close, dispatch, ui.bulkMode]);

    const done = useCallback(() => {
        let entryLength = 0;
        const copyEntry = (entry) => {
            dispatch(
                EntryActions.copy(
                    {
                        journal: entry.journal.id,
                        title: entry.title
                    },
                    entry.id
                )
            ).then((response) => {
                if (response.payload.status === 200) {
                    entryLength++;
                    if (ui.bulkMode && ui.bulkEntries.length > 1) {
                        if (entryLength === ui.bulkEntries.length) {
                            dispatch(EntryActions.fetch());
                        }
                    } else {
                        dispatch(EntryActions.fetch());
                    }
                }
            });
        };
        if (ui.bulkMode && ui.bulkEntries.length > 1) {
            ui.bulkEntries.map((bulkEntry) => {
                const newEntry = {
                    ...bulkEntry,
                    journal: copyEntryJournal,
                    extra_journals: copyEntryJournal
                };
                return copyEntry(newEntry);
            });
        } else {
            const newEntry = {
                ...oneEntry,
                title: copyEntryTitle,
                journal: copyEntryJournal,
                extra_journals: copyEntryJournal
            };
            copyEntry(newEntry);
        }
        _close();
    }, [_close, ui, dispatch, copyEntryJournal, oneEntry, copyEntryTitle]);

    const renderJournalsDropdownName = useCallback(() => {
        if (!copyEntryJournal) {
            return 'Select Journals';
        }
        return copyEntryJournal.title;
    }, [copyEntryJournal]);

    const changeJournals = useCallback(
        (journal) => {
            setCopyEntryJournal(journal);
            setOpenJournalsDrop(false);
            dispatch(JournalActions.fetchJournal(journal.resource_uri)).then(
                (response) => {
                    if (response.payload.status === 200) {
                        setSharedUsers(response.payload.data.invitations);
                    }
                }
            );
        },
        [dispatch]
    );

    const renderJournalItem = useCallback(
        (journal) => {
            const active = Boolean(copyEntryJournal?.id === journal.id);
            return (
                <li
                    key={`journal-list-${journal.id}`}
                    onClick={() => changeJournals(journal)}
                    className={`${active ? 'active' : ''}`}>
                    <div className="dropdown-selected-list__check">
                        <img src={iconCheckSm} alt="" />
                    </div>
                    <div className="dropdown-selected-list__title truncate">
                        <span>{journal.title}</span>
                    </div>
                </li>
            );
        },
        [copyEntryJournal, changeJournals]
    );
    // const removeSharedUser = useCallback((user) => {
    //     let shared_users = [...sharedUsers];
    //     shared_users = shared_users.filter(
    //         (shared_user) => shared_user.email !== user.email
    //     );
    //     setSharedUsers(shared_users);
    // }, []);

    const renderSharedUsers = useCallback(() => {
        // const shared_users = [...sharedUsers];

        return (sharedUsers || []).map((user, index) => {
            if (user.user) {
                return (
                    <div key={index} className="user-tags__item">
                        <img
                            className="user-tags__avatar"
                            src={user.user.avatar_image_url}
                            alt="user"
                        />
                        <div className="user-tags__title truncate">
                            <span>{user.user.public_display_name}</span>
                        </div>
                        {/* {editable && (
                            <img
                                className="user-tags__close"
                                src={iconCloseSm}
                                onClick={() => removeSharedUser(user)}
                                alt=""
                            />
                        )} */}
                    </div>
                );
            } else {
                return (
                    <div key={index} className="user-tags__item">
                        <div className="user-tags__title truncate">
                            <span>{user.email}</span>
                        </div>
                        {/* {editable && (
                            <img
                                className="user-tags__close"
                                src={iconCloseSm}
                                onClick={() => removeSharedUser(user)}
                                alt=""
                            />
                        )} */}
                    </div>
                );
            }
        });
    }, [sharedUsers]);

    return (
        <div id="share-modal" className="modal-frame">
            <div className="modal-box modal__box--dropzone modal-box--600">
                <div className="modal__header">Copy Entry</div>
                <div className="modal__subheader">
                    Please note: after copying the entry, every change you make
                    will be reflected on this entry only. The copy of this entry
                    will not reflect any new changes you make: they will be
                    treated separately.
                </div>
                <div className="modal__body">
                    {enableCopiedTitle && (
                        <div className="form-group">
                            <div className="form-group__item">
                                <label htmlFor="visibilityShare">
                                    Copied Entry Name
                                </label>
                                <TextField
                                    value={copyEntryTitle}
                                    onChange={(e) => {
                                        setCopyEntryTitle(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    <div className="form-group">
                        <div className="form-group__item">
                            <label htmlFor="visibilityShare">
                                Destination for Your Copy
                            </label>
                            <div
                                id="journal-tags-wrapper"
                                className="dropdown show">
                                <div
                                    onClick={() => setOpenJournalsDrop(true)}
                                    className="form-group__select dropdown-toggle truncate"
                                    role="button"
                                    aria-disabled={true}
                                    id="selectedTagsModal"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false">
                                    <span>{renderJournalsDropdownName()}</span>
                                    <img src={iconCaretDown} alt="" />
                                </div>
                                <div
                                    className={`dropdown-menu max-height--400 scrollable ${
                                        openJournalsDrop ? 'show' : ''
                                    }`}
                                    aria-labelledby="selectedJournalsModal">
                                    <ul className="dropdown-selected-list">
                                        {(
                                            journals?.journals || []
                                        ).map((journal) =>
                                            renderJournalItem(journal)
                                        )}
                                    </ul>
                                </div>
                            </div>
                            <div className="modal-tooltip">
                                <span></span> you choose the private/shared
                                journal, you can see list of people who have
                                access to your copy.
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-group__item">
                            <label htmlFor="visibilityShare">
                                Everyone who Have Access to this Journal
                            </label>
                            <div className="user-tags">
                                {renderSharedUsers()}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal__footer">
                    <button className="btn btn-outline" onClick={_close}>
                        Cancel
                    </button>
                    {editable && (
                        <button className="btn btn-gradient" onClick={done}>
                            Create a Copy
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    ui: getUI,
    journals: getJournalList
});
export default connect(state)(CopyEntryDialog);
