import moment from 'moment';

import { getRecentContentDate } from 'redux/selectors/CalendarSelector';
import {
    UPDATE_ENTRY_SORT,
    UPDATE_FILTERS,
    RESET_FILTERS
} from 'redux/actions/actionTypes';

export const updateSort = (sort) => (dispatch, getState) => {
    dispatch({
        type: UPDATE_ENTRY_SORT,
        payload: {
            data: sort
        }
    });
    return Promise.resolve();
};

export const update = (data) => (dispatch, getState) => {
    dispatch({
        type: UPDATE_FILTERS,
        payload: {
            data
        }
    });
};

export const reset = () => (dispatch, getState) => {
    dispatch({
        type: RESET_FILTERS,
        payload: {
            data: true
        }
    });
};

export const updateToRecent = (viewMode = 'month') => (dispatch, getState) => {
    const recent = getRecentContentDate(getState());
    if (recent) {
        const data = {
            minDate: moment(recent, 'X').startOf(viewMode),
            maxDate: moment(recent, 'X').endOf(viewMode)
        };

        dispatch({
            type: UPDATE_FILTERS,
            payload: {
                data
            }
        });
    }
};
