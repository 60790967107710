import { URLS } from 'common/urls';
import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router';

const NotAuthHeader = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            rest.user.isAuthorized === false ? (
                rest.is_lazy ? (
                    <Suspense
                        fallback={
                            <div
                                style={{
                                    position: 'fixed',
                                    top: '10%'
                                }}>
                                Page is Loading...
                            </div>
                        }>
                        <Component {...props} />
                    </Suspense>
                ) : (
                    <Component {...props} />
                )
            ) : (
                <Redirect
                    to={{
                        pathname: URLS.TIMELINE,
                        state: { from: props.location }
                    }}
                />
            )
        }
    />
);

const states = (state) => ({ ...state });
export default connect(states)(NotAuthHeader);
