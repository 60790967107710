import React, { useCallback } from 'react';
import { URLS } from 'common/urls';

import { connect } from 'react-redux';
import { getUser } from 'redux/selectors';
import { createStructuredSelector } from 'reselect';

// Actions
import * as UserActions from 'redux/actions/user';

const MembershipAlert = ({ dispatch, user }) => {
    const downgradeToJRNLFree = useCallback(async () => {
        // Make API Request to downgrade
        //setDowngradeClickFlag(true);
        if (!user.is_free && user.is_ty) {
            await dispatch(UserActions.unsubscribeToFree());
            dispatch(UserActions.fetch());
        }

        window.location.href = URLS.SETTINGS.ACCOUNT;
    }, [dispatch, user.is_free, user.is_ty]);

    return (
        <div>
            <main id="main" className="app-main">
                <div className="page-container">
                    <div className="page page--member-alert type">
                        <h4 className="intro-title">
                            {user.first_name}, we have some important news!
                        </h4>

                        <p>
                            First and foremost, we want to say{' '}
                            <em>thank you</em> to those users who have supported
                            us for more than a decade. We appreciate you more
                            than you know. We know choosing where to keep your
                            most private thoughts (and even your less private
                            thoughts) is not a decision anyone takes lightly,
                            and we appreciate you choosing us for this task.
                        </p>

                        <h5>
                            We remain committed to preserving your privacy and
                            respecting your content.
                        </h5>

                        <p>
                            However, as with nearly every business in the
                            country, with leftover challenges due to the
                            pandemic, and now a 40-year record high inflation,
                            we can unfortunately no longer continue to offer the
                            same services for the same price.
                        </p>

                        <p>
                            Don&apos;t panic, we will always offer a free
                            version of JRNL, however, due to circumstances
                            beyond our control we can no longer continue to
                            offer the expansive features that you have become
                            accustomed to at no charge.
                        </p>

                        <p>
                            All the content you have in our system will remain
                            accessible to you for reading and printing.
                        </p>

                        <p>
                            As of March 1, 2023, all users will be transitioned
                            to one of two accounts. Free, which offers our most
                            basic JRNL service, but still permits unlimited text
                            entries. Or the Power JRNLer account. Users that
                            choose to upgrade will have access to all our best
                            features and any additional features we add in the
                            future.
                        </p>

                        <p>
                            We are offering a free 30-day trial of our Power
                            JRNLer account, so you can see what functionality is
                            available there. If you upgrade within that 30-day
                            period, you will receive a discount on your
                            membership, and that discount will continue as long
                            as you renew your membership.
                        </p>

                        <p>
                            While we understand that this may not be the best
                            news, we hope that like us, you will be thrilled
                            that we are committed to keep JRNL moving forward
                            and develop new and exciting features that you will
                            love.
                        </p>

                        <p>
                            If there are any questions, please don&apos;t
                            hesitate to reach out to our support team at:
                            <a href="mailto:helpdesk@jrnl.help">
                                {' '}
                                helpdesk@jrnl.help{' '}
                            </a>
                            .
                        </p>

                        <button
                            className="btn btn-gradient btn--lg"
                            onClick={() => {
                                downgradeToJRNLFree();
                            }}>
                            Continue
                        </button>
                    </div>
                </div>
            </main>
        </div>
    );
};

const state = createStructuredSelector({
    user: getUser
});
export default connect(state)(MembershipAlert);
