import React, {
    // useEffect,
    useCallback,
    useState
} from 'react';
// redux
import { connect } from 'react-redux';
// Selectors
import { getUI } from 'redux/selectors';
import { createStructuredSelector } from 'reselect';
// Actions
import { updateUI } from 'redux/actions/ui';

const BackCoverStep = ({ display, dispatch, ui }) => {
    const [length, setLength] = useState(0);
    const [error, setError] = useState(false);
    // handlers
    const onChangeBackCoverText = useCallback(
        (e) => {
            dispatch(
                updateUI({
                    back_cover: e.target.value
                })
            );
            setLength(e.target.value.length);
            const isError = e.target.value.length > 1000;
            setError(isError);
            dispatch(
                updateUI({
                    canGoNext: !isError
                })
            );
        },
        [dispatch]
    );

    return (
        <div
            className={`new-book-step-10 new-book-step ${
                display ? 'active-form' : ''
            }`}>
            <div className="section-title">Back Cover</div>
            <div className="new-book-step-form">
                <div>
                    <div className="form-group">
                        <div className="form-group__item p-0">
                            <textarea
                                value={ui.back_cover || ''}
                                onChange={onChangeBackCoverText}
                                id="backCover"
                                name="back-cover"
                                placeholder="Back Cover (optional)"
                                rows={4}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {error && (
                <div className="error-message">
                    Max length exceeded. Ensure this value has at most 1000
                    characters (it has {length}).
                </div>
            )}
        </div>
    );
};

const state = createStructuredSelector({
    ui: getUI
});
export default connect(state)(BackCoverStep);
