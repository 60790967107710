import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

// Actions
// @ts-ignore
import { connect } from 'react-redux';
import * as EditorActions from 'redux/actions/editor';
import * as EntryMediaActions from 'redux/actions/entrymedia';
import { updateUI } from 'redux/actions/ui';

import DisplayFile from 'components/DisplayFile/DisplayFile';

// Selectors
import { createStructuredSelector } from 'reselect';
import { getUI } from 'redux/selectors';

import Dialog from '@material-ui/core/Dialog';

// Icons
import iconCloseSm from 'assets/images/icons_svg/icon_close-sm.svg';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { IMAGE_MAX_SIZE } from 'common/constant';

const AddMediaUpload = ({ dispatch, close, open, ui }) => {
    const [file, setFile] = useState(null);
    const [imageTooLarge, setImageTooLarge] = useState(false);
    // const [selectedEntry, setSelectedEntry] = useState();
    const [selectedEntryList, setSelectedEntryList] = useState([null]);
    const [mediaIdList, setMediaIdList] = useState([]);

    useEffect(() => {
        dispatch(
            updateUI({
                disableButton: !file || ui.editor?.isPending
            })
        );
    }, [dispatch, file, ui.editor]);

    const onDrop = useCallback(
        (acceptedFiles) => {
            acceptedFiles.forEach((file) => {
                const reader = new FileReader();
                const filesize = parseFloat(
                    Number(file.size / (1024 * 1024)).toFixed(2)
                );
                if (filesize > IMAGE_MAX_SIZE) {
                    setImageTooLarge(true);
                    return;
                }
                reader.onload = () => {
                    // Do whatever you want with the file contents
                    const entryId = ui.newEntry
                        ? ui.newEntry.id
                        : ui.editEntry.id;
                    const fileType = file.type.slice(0, file.type.indexOf('/'));
                    const binaryStr = URL.createObjectURL(
                        // @ts-ignore
                        new Blob([new Uint8Array(reader.result)], {
                            type: fileType
                        })
                    );
                    const formData = new FormData();
                    formData.append('file', file, file.name);
                    formData.append('entry', entryId || '');

                    dispatch(EntryMediaActions.create(formData)).then(
                        (response) => {
                            if (response?.payload?.status === 201) {
                                setMediaIdList((prevState) => {
                                    const mediaIDs = [...prevState];
                                    mediaIDs.push(response?.payload?.data.id);
                                    return mediaIDs;
                                });
                            }
                        }
                    );
                    dispatch(
                        EntryMediaActions.setMediaType({ type: fileType })
                    );

                    setFile({
                        src: binaryStr,
                        type: file.type,
                        name: file.name,
                        size: file.size,
                        file
                    });
                };

                reader.readAsArrayBuffer(file);
            });
        },
        [dispatch, ui.editEntry, ui.newEntry]
    );

    const _close = useCallback(() => {
        dispatch(EditorActions.resetImageState());
        if (close) {
            close();
        }
    }, [dispatch, close]);

    const done = useCallback(
        (description) => {
            const updateMedia = (entry) => {
                dispatch(
                    EntryMediaActions.update(
                        {
                            description,
                            entry: entry?.id || ''
                        },
                        mediaIdList[0]
                    )
                );
            };

            // (mediaIdList || []).map((mediaID) => {
            //     return updateMedia(mediaID);
            // });
            // @ts-ignore
            (selectedEntryList || []).map((entry, index) => {
                return updateMedia(entry);
            });
            _close();
        },
        [dispatch, _close, selectedEntryList, mediaIdList]
    );
    useEffect(() => {
        if (imageTooLarge) {
            setTimeout(() => setImageTooLarge(false), 3000);
        }
    }, [imageTooLarge]);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const changeEntryStatus = useCallback(
        (entry, status, key) => {
            setSelectedEntryList((prevState) => {
                if (status !== true && prevState.length === key) {
                    return [...prevState, entry];
                }
                const entryList = [...prevState];
                let newEntryList = [];
                const selectEntry =
                    status === true
                        ? selectedEntryList[key] &&
                          selectedEntryList[key]?.id === entry.id
                            ? null
                            : entry
                        : null;
                newEntryList = entryList.map((entryEle, index) => {
                    return index === key ? selectEntry : entryEle;
                });
                return newEntryList;
            });
        },
        [selectedEntryList]
    );

    const deleteJournalEntry = useCallback((index) => {
        setSelectedEntryList((prevState) => {
            const entryList = [...prevState];
            entryList.splice(index, 1);
            return entryList;
        });
    }, []);

    return (
        <Dialog
            fullWidth={true}
            open={open}
            onClose={_close}
            aria-labelledby="form-dialog-title">
            <div className="modal-box modal__box--dropzone modal-box--600">
                <div className="modal__header">
                    Add File
                    <img
                        className="modal__close"
                        onClick={_close}
                        src={iconCloseSm}
                        alt=""
                    />
                </div>
                {file ? (
                    <DisplayFile
                        file={file}
                        close={_close}
                        done={done}
                        changeEntryStatus={changeEntryStatus}
                        selectedEntryList={selectedEntryList}
                        deleteJournalEntry={deleteJournalEntry}
                        media_view={true}
                    />
                ) : (
                    <div className="modal__body" {...getRootProps()}>
                        <input
                            {...getInputProps()}
                            accept="audio/*,video/*,.pdf,.doc,.docx,.xlsm,.csv"
                        />
                        <div className="box-input mx-auto">
                            <div className="photos-action">
                                <div>
                                    <strong>Drop file here to upload</strong>
                                    <span className="btn btn-gradient">
                                        Select Files
                                    </span>
                                </div>
                            </div>
                            <div className="photo-details">
                                <p>Maximum upload size: 100 MB</p>
                                <p>
                                    Formats:
                                    .mp3,.mp4,.mov,.pdf,.doc.docx,.xlsm,.csv
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Snackbar
                open={imageTooLarge}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                    // @ts-ignore
                    backgroundColor: 'white'
                }}
                key={'top center'}
                // @ts-ignore
                style={{ width: '100%', zIndex: '9999' }}>
                <Alert severity="error">
                    The maximum size of file to upload should be 100 MB
                </Alert>
            </Snackbar>
        </Dialog>
    );
};

const state = createStructuredSelector({
    ui: getUI
});
export default connect(state)(AddMediaUpload);
