import React from 'react';
import ReactDOMServer from 'react-dom/server';

// Icons
import iconCheckSm from 'assets/images/icons_svg/icon_check-sm.svg';

const DropzoneConfigs = {
    default: {
        config: {
            postUrl: 'no-url',
            dropMessage: 'Drop Files Here to Upload',
            uploadButtonLabel: 'Select Photos'
        },
        djsConfig: {
            acceptedFiles: '.jpg, .jpeg, .png, .gif, .tiff',
            maxFilesize: 20,
            previewsContainer: '#previews',
            dictInvalidFileType: 'Invalid type!',
            dictFileTooBig: 'File too large!',
            dictResponseError: 'Upload failed!',
            dictMaxFilesExceeded: 'Max files uploaded!'
        },
        previewTemplate: () => {
            return ReactDOMServer.renderToStaticMarkup(
                <div className="dz-preview">
                    <div className="file uploading file--uploading">
                        <div className="single-photo uploading-photo">
                            <img
                                className="file__thumb "
                                data-dz-thumbnail
                                alt="file__thumb"
                            />
                            <div className="progress-bar m-2">
                                <span
                                    className="progress-bar-fill"
                                    data-dz-uploadprogress="true"></span>
                            </div>
                        </div>
                    </div>

                    <div className="file uploaded file--uploaded dz-details">
                        <div className="single-photo">
                            <img
                                className="file__thumb "
                                data-dz-thumbnail
                                alt="file__thumb"
                            />
                            <div className="file__check">
                                <img src={iconCheckSm} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="file upload-error file--upload-failed">
                        <div className="file__content">
                            <span
                                className="file__info dz-error-message"
                                data-dz-errormessage></span>
                        </div>
                    </div>
                </div>
            );
        }
    },
    media: {
        config: {
            postUrl: 'no-url',
            dropMessage: 'Drop Files Here to Upload',
            uploadButtonLabel: 'Select Photos'
        },
        djsConfig: {
            acceptedFiles: '.jpg, .jpeg, .png, .gif, .tiff',
            maxFilesize: 20,
            previewsContainer: '#previews',
            dictInvalidFileType: 'Invalid type!',
            dictFileTooBig: 'File too large!',
            dictResponseError: 'Upload failed!',
            dictMaxFilesExceeded: 'Max files uploaded!'
        },
        previewTemplate: () => {
            return ReactDOMServer.renderToStaticMarkup(
                <div className="dz-preview">
                    <div className="file uploading file--uploading">
                        <div className="single-photo uploading-photo">
                            <img
                                className="file__thumb "
                                data-dz-thumbnail
                                alt="file__thumb"
                            />
                            <div className="progress-bar m-2">
                                <span
                                    className="progress-bar-fill"
                                    data-dz-uploadprogress="true"></span>
                            </div>
                        </div>
                    </div>

                    <div className="file uploaded file--uploaded dz-details">
                        <div className="single-photo">
                            <img
                                className="file__thumb "
                                data-dz-thumbnail
                                alt="file__thumb"
                            />
                            <div className="file__check">
                                <img src={iconCheckSm} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="file upload-error file--upload-failed">
                        <div className="file__content">
                            <span
                                className="file__info dz-error-message"
                                data-dz-errormessage></span>
                        </div>
                    </div>
                </div>
            );
        }
    },
    avatar: {
        config: {
            postUrl: 'http://dev.jrnl.com:9100/v1/avatar_image/',
            dropMessage: 'Drop a File Here to Upload',
            uploadButtonLabel: 'Select Photos'
        },
        djsConfig: {
            acceptedFiles: '.jpg, .jpeg, .png, .gif, .tiff',
            paramName: 'image',
            maxFiles: 1,
            previewsContainer: '#previews',
            dictInvalidFileType: 'Invalid type!',
            dictFileTooBig: 'File too large!',
            dictResponseError: 'Upload failed!',
            dictMaxFilesExceeded: 'Max files uploaded!'
        },
        previewTemplate: () => {
            return ReactDOMServer.renderToStaticMarkup(
                <div className="dz-preview">
                    <div className="file uploading file--uploading">
                        <div className="single-photo uploading-photo">
                            <img
                                className="file__thumb "
                                data-dz-thumbnail
                                alt="file__thumb"
                            />
                            <div className="progress-bar m-2">
                                <span
                                    className="progress-bar-fill"
                                    data-dz-uploadprogress="true"></span>
                            </div>
                        </div>
                    </div>

                    <div className="file uploaded file--uploaded dz-details">
                        <div className="single-photo">
                            <img
                                className="file__thumb "
                                data-dz-thumbnail
                                alt="file__thumb"
                            />
                            <span className="check-icon fa fa-check-circle"></span>
                        </div>
                    </div>

                    <div className="file upload-error file--upload-failed">
                        <div className="file__content">
                            <span
                                className="file__info dz-error-message"
                                data-dz-errormessage></span>
                        </div>
                    </div>
                </div>
            );
        }
    },
    file: {
        config: {
            postUrl: 'no-url',
            dropMessage: 'Drop Files Here to Upload',
            uploadButtonLabel: 'Select Files'
        },
        djsConfig: {
            acceptedFiles: '',
            maxFilesize: 500,
            previewsContainer: '#previews',
            dictInvalidFileType: 'Invalid type!',
            dictFileTooBig: 'File too large!',
            dictResponseError: 'Upload failed!',
            dictMaxFilesExceeded: 'Max files uploaded!'
        },
        previewTemplate: () => {
            return ReactDOMServer.renderToStaticMarkup(
                <div className="dz-preview">
                    <div className="file uploading file--uploading">
                        <div className="single-photo uploading-photo">
                            <img
                                className="file__thumb "
                                data-dz-thumbnail
                                alt="file__thumb"
                            />
                            <div className="progress-bar m-2">
                                <span
                                    className="progress-bar-fill"
                                    data-dz-uploadprogress="true"></span>
                            </div>
                        </div>
                    </div>

                    <div className="file uploaded file--uploaded dz-details">
                        <div className="single-photo">
                            <img
                                className="file__thumb "
                                data-dz-thumbnail
                                alt="file__thumb"
                            />
                            <span className="check-icon fa fa-check-circle"></span>
                        </div>
                    </div>

                    <div className="file upload-error file--upload-failed">
                        <div className="file__content">
                            <span
                                className="file__info dz-error-message"
                                data-dz-errormessage></span>
                        </div>
                    </div>
                </div>
            );
        }
    }
};

export default DropzoneConfigs;
