import _ from 'underscore';
import React, { useCallback, useEffect, useState } from 'react';

const SELECTED_CONTACT_CLASSNAME = 'selected-contact';

// if comment is undefined, it should be adding new user and if not, it is just showing dialog
const CommentShareUserDialog = ({ invitations, comment, close, onUpdate }) => {
    const [localInvitations, setLocalInvitations] = useState(
        _.filter(invitations, (invitation) => !!invitation?.user)
    );

    useEffect(() => {
        if (comment) {
            const commentSharedUsers =
                comment?.shared_users.map((shared_user) => shared_user.id) ||
                [];

            setLocalInvitations((prevState) => {
                const newState = [...prevState].map((invitation) => {
                    const index = commentSharedUsers.findIndex(
                        (sharedUser) => sharedUser === invitation.user?.id
                    );

                    return { ...invitation, shared: index >= 0 };
                });

                return newState;
            });
        }
    }, [comment]);

    const onClickSharedUser = useCallback((userId) => {
        setLocalInvitations((prevState) => {
            return [...prevState].map((invitation) => {
                return invitation.user.id === userId
                    ? { ...invitation, shared: !invitation.shared }
                    : { ...invitation };
            });
        });
    }, []);

    const renderInvitationUsers = useCallback(() => {
        return localInvitations.map((invitation) => {
            const selectIconClassName = `fa fa-check-circle ml-auto ${
                invitation.shared ? SELECTED_CONTACT_CLASSNAME : ''
            }`;
            const { user } = invitation;

            return (
                <li
                    onClick={() => onClickSharedUser(invitation.user.id)}
                    key={invitation.id}>
                    <img
                        src={user.avatar_image_url}
                        alt="avatar"
                        className="circle-img"
                    />
                    <p className="comment-contact-name">
                        {user.public_display_name}
                    </p>
                    <span className={selectIconClassName}></span>
                </li>
            );
        });
    }, [localInvitations, onClickSharedUser]);

    const selectUser = useCallback(() => {
        if (onUpdate) {
            const selectedInvitations = localInvitations.filter(
                (invitation) => invitation.shared === true
            );

            onUpdate(selectedInvitations.map((invitation) => invitation.user));
        }
        close();
    }, [close, onUpdate, localInvitations]);

    return (
        <div id="send-comment-modal" className="send-comment-modal">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8 col-xl-6 send-comment-content">
                        <div className="send-comment-content-header">
                            <h3 className="send-comment-content-title">
                                Send to...
                            </h3>
                        </div>
                        <div className="send-comment-content-contacts">
                            <h4 className="send-comment-content-contacts-title">
                                Contacts on this conversation
                            </h4>
                            <ul className="send-comment-content-list">
                                {renderInvitationUsers()}
                            </ul>
                        </div>
                        <div className="send-comment-content-actions">
                            {!comment && (
                                <button
                                    onClick={selectUser}
                                    className="btn btn-select-user">
                                    <span className="fa fa-check-circle"></span>
                                    Select User
                                </button>
                            )}
                            <button onClick={close} className="btn btn-cancel">
                                {!comment ? 'Cancel' : 'Close'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommentShareUserDialog;
