import React, { useCallback, useState } from 'react';

// Actions
import { connect } from 'react-redux';
import * as JournalActions from 'redux/actions/journal';

// Coponents
import Dialog from '@material-ui/core/Dialog';
import deleteImage from 'assets/images/banners/banner-delete.png';
import { updateUI } from 'redux/actions/ui';

// Icons
import iconCheckSm from 'assets/images/icons_svg/icon_check-sm.svg';

const DeleteJournalDialog = ({ close, done, journal, open, dispatch }) => {
    const [isSaving, setIsSaving] = useState(false);
    const [isSaved, setIsSaved] = useState(false);

    const _close = useCallback(() => {
        if (close) {
            close();
        }
    }, [close]);

    const _done = useCallback(() => {
        setIsSaving(true);
        setIsSaved(false);

        dispatch(JournalActions.remove(journal)).then((response) => {
            if (response.payload.status === 204) {
                setIsSaved(false);
                setIsSaved(true);
                _close();
                dispatch(
                    updateUI({
                        dialogs: []
                    })
                );
            }
        });
    }, [journal, _close, dispatch]);

    return (
        <Dialog
            fullWidth={true}
            open={open}
            onClose={_close}
            aria-labelledby="form-dialog-title">
            <div
                id="delete-modal"
                className="modal-box modal-box--delete modal-box--500">
                <div className="delete-modal">
                    <div className="delete-modal__img">
                        <img
                            src={deleteImage}
                            alt="delete"
                            className="img-fluid"
                        />
                    </div>
                    <div className="delete-modal__content">
                        <div className="delete-modal__title">
                            Delete Journal?
                        </div>
                        <div className="delete-modal__text">
                            Are you sure you want to delete{' '}
                            <strong>&quot;{journal.title}&quot;</strong>{' '}
                            journal?
                        </div>
                    </div>
                    <div className="delete-modal__actions">
                        <button className="btn btn-outline" onClick={_close}>
                            Cancel
                        </button>
                        <button className="btn btn-delete" onClick={_done}>
                            Delete
                        </button>
                        {isSaving && (
                            <div className="saving-text pr-2">
                                <div className="saving-gif pr-3"></div>
                                Saving...
                            </div>
                        )}
                        {!isSaving && isSaved && (
                            <div className="saving-text pr-2">
                                <img src={iconCheckSm} alt="" />
                                Saved!
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default connect()(DeleteJournalDialog);
