import React, { useCallback } from 'react';
// Actions
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getUI } from 'redux/selectors';
import * as EntryActions from 'redux/actions/entry';
import * as CalendarActions from 'redux/actions/calendar';
import { updateUI } from 'redux/actions/ui';

// Components
import Dialog from '@material-ui/core/Dialog';

// images
import deleteImage from 'assets/images/banners/banner-delete.png';

const DeleteEntryDialog = ({ close, entry, ui, dispatch, onUpdate, open }) => {
    const _close = useCallback(() => {
        if (ui.bulkMode) {
            dispatch(
                updateUI({
                    bulkMode: false,
                    bulkEntries: []
                })
            );
        }
        if (close) {
            close();
        }
    }, [close, ui.bulkMode, dispatch]);

    const _done = useCallback(
        (e) => {
            if (onUpdate) {
                onUpdate();
            } else {
                if (ui.bulkMode) {
                    ui.bulkEntries.map((bulkEntry) => {
                        return dispatch(EntryActions.remove(bulkEntry));
                    });
                } else {
                    dispatch(EntryActions.remove(entry)).then((response) => {
                        dispatch(CalendarActions.fetch());
                    });
                }

                _close();
            }
        },
        [_close, dispatch, onUpdate, entry, ui.bulkEntries, ui.bulkMode]
    );

    return (
        <Dialog
            fullWidth={true}
            open={open}
            onClose={_close}
            aria-labelledby="form-dialog-title">
            <div
                id="delete-modal"
                className="modal-box modal-box--delete modal-box--500">
                <div className="delete-modal">
                    <div className="delete-modal__img">
                        <img
                            src={deleteImage}
                            alt="delete"
                            className="img-fluid"
                        />
                    </div>
                    <div className="delete-modal__content">
                        <div className="delete-modal__title">
                            Delete{' '}
                            {ui.bulkEntries?.length > 1
                                ? `${ui.bulkEntries?.length} entries?`
                                : 'entry?'}
                        </div>
                        <div className="delete-modal__subtitle">
                            Are you sure you want to delete this entry?
                        </div>
                    </div>
                    <div className="delete-modal__actions">
                        <button className="btn btn-outline" onClick={_close}>
                            Cancel
                        </button>
                        <button className="btn btn-delete" onClick={_done}>
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
const state = createStructuredSelector({
    ui: getUI
});
export default connect(state)(DeleteEntryDialog);
