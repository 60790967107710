import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
// Firebase
// Reselects
import { getUser } from 'redux/selectors';

// icons
import iconCheckSm from 'assets/images/icons_svg/icon_check-sm.svg';
import { getDifferenceDateStr } from 'common/utils/dateUtils';
import moment from 'moment';

const PasswordChangedNotification = ({
    data: { read, created_at },
    fullView
}) => {
    return (
        <li className="alert-card__item alert-card__item--buttons">
            <div className="alert-card__img alert-card__img--icon">
                <img src={iconCheckSm} alt="" />
            </div>
            <div className="alert-card__content">
                Your password has been changed successfully!
            </div>
            {!read && (
                <div className="notification-active-bullet-dropdown"></div>
            )}
            {fullView && created_at && (
                <p className="notification-timestamp ml-auto">
                    {getDifferenceDateStr(
                        moment.utc(created_at).local(),
                        moment(new Date())
                    )}
                </p>
            )}
        </li>
    );
};

const state = createStructuredSelector({
    user: getUser
});
export default connect(state)(PasswordChangedNotification);
