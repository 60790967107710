import React from 'react';
import { store } from 'redux/store/configureStore';
import { updateUI } from 'redux/actions/ui';
// components
import withBook from 'common/utils/components/withBook';
// Dialogs
import BookPreviewDialog from 'pages/Dialogs/BookPreviewDialog';

const withDialog = (Component, data) => (props) => {
    return <Component {...data} {...props} />;
};

export const openDialog = (Component, props) => {
    return store.dispatch(
        updateUI({
            dialogs: [withDialog(Component, props)]
        })
    );
};

export const closeDialog = () => {
    return store.dispatch(
        updateUI({
            dialogs: []
        })
    );
};

export const showBanner = (banner_field) => {
    const ui = store.getState().ui;
    return Boolean(ui[banner_field] !== false);
};

export const hideBanner = (banner_field) => {
    store.dispatch(
        updateUI({
            [banner_field]: false
        })
    );
};

//
export const openDialogWithUIProps = (Component, originalProps, fields) => {
    const ui = store.getState().ui;
    const newProps = originalProps;
    fields.forEach((field) => {
        newProps[field.propField] = ui[field.uiField];
    });

    return store.dispatch(
        updateUI({
            dialogs: [withDialog(Component, newProps)]
        })
    );
};

// we should keep pdf content loaded
// on close modal, instead of removing html, only display hidden
// to do that, we keep bookPreviewDialogs and only change status for show/hide
export const showBookPreviewDialog = (bookId, show = true) => {
    const ui = store.getState().ui;
    if (ui.bookPreviewDialogShow[bookId] === undefined) {
        // add new BookPreviewDialog
        ui.bookPreviewDialogs[bookId] = withBook(BookPreviewDialog, bookId);
        store.dispatch(
            updateUI({
                // book preview dialogs
                bookPreviewDialogs: ui.bookPreviewDialogs,
                // show book preview dialog
                bookPreviewDialogShow: {
                    ...ui.bookPreviewDialogShow,
                    [bookId]: show
                }
            })
        );
    } else {
        store.dispatch(
            updateUI({
                // show book preview dialog
                bookPreviewDialogShow: {
                    ...ui.bookPreviewDialogShow,
                    [bookId]: show
                }
            })
        );
    }
};
