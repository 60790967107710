import _ from 'underscore';
import moment from 'moment';
import {
    UPDATE_ENTRY_SORT,
    UPDATE_FILTERS,
    RESET_FILTERS,
    ACTION_USER_LOGOUT
} from 'redux/actions/actionTypes';

const initialState = {
    entrySort: '-entry_date',
    filterByJournal: undefined,
    filterByTag: undefined,
    minDate: undefined,
    maxDate: undefined,
    dateViewMode: '',
    entryViewMode: 'all',
    filtered: false,
    journal: undefined,
    calendar_min_date: moment().startOf('year').format(),
    calendar_max_date: moment().endOf('year').format()
};

const FilterReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_ENTRY_SORT: {
            return _.extend({}, state, {
                entrySort: action.payload.data
            });
        }

        case UPDATE_FILTERS: {
            return _.extend({}, state, action.payload.data, { filtered: true });
        }

        case RESET_FILTERS: {
            return _.extend({}, state, _.omit(initialState), ['entrySort']);
        }

        case ACTION_USER_LOGOUT: {
            return _.extend({}, initialState);
        }

        default: {
            return state;
        }
    }
};

export default FilterReducer;
