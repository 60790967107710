import _ from 'underscore';
import {
    FETCH_BOOK_ORDER_LIST,
    CREATE_BOOK_ORDER,
    ACTION_USER_LOGOUT
} from 'redux/actions/actionTypes';

import {
    SUCCESS_SUFFIX
} from 'common/constant';

const initialState = {
    bookOrders: undefined
};

const BookOrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BOOK_ORDER_LIST + SUCCESS_SUFFIX: {
            let bookOrders = state.bookOrders;

            action.payload.data.objects.forEach((object) => {
                const index = _.findIndex(
                    bookOrders,
                    (bookOrder) => object.id === bookOrder.id
                );

                if (index >= 0)
                    bookOrders = [].concat(
                        bookOrders.slice(0, index),
                        [object],
                        bookOrders.slice(index + 1)
                    );
                else bookOrders = [].concat([object], bookOrders || []);
            });

            return _.extend({}, state, {
                bookOrders: bookOrders
            });
        }

        case CREATE_BOOK_ORDER + SUCCESS_SUFFIX: {
            let bookOrders = state.bookOrders;
            let object = action.payload.data;

            const index = _.findIndex(
                bookOrders,
                (bookOrder) => object.id === bookOrder.id
            );

            if (index >= 0)
                bookOrders = [].concat(
                    bookOrders.slice(0, index),
                    [object],
                    bookOrders.slice(index + 1)
                );
            else bookOrders = [].concat([object], bookOrders || []);

            return _.extend({}, state, {
                bookOrders: bookOrders
            });
        }

        case ACTION_USER_LOGOUT: {
            return _.extend({}, initialState);
        }

        default: {
            break;
        }
    }
    return state
}

export default BookOrderReducer;