import buildV2Authorization from 'common/utils/buildV2Authorization';
import { USER_EXPORT_DATA } from 'common/constant';
import { UserSelector } from 'redux/selectors/UserSelector';
import { EXPORT_JOURNAL_DATA } from './actionTypes';

export const create = (data) => (dispatch, getState) => {
    const url = USER_EXPORT_DATA;
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);

    return dispatch({
        type: EXPORT_JOURNAL_DATA,
        payload: {
            request: {
                url,
                method: 'POST',
                data,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const fetch = (id) => (dispatch, getState) => {
    const url = `${USER_EXPORT_DATA}${id}`;
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);

    return dispatch({
        type: EXPORT_JOURNAL_DATA,
        payload: {
            request: {
                url,
                method: 'Get',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};
