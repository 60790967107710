import iconAccomplishments from 'assets/images/all-about-me-images/aam-accomplishments.jpg';
import iconChallenges from 'assets/images/all-about-me-images/aam-challenges.jpg';
import iconEducation from 'assets/images/all-about-me-images/aam-education.jpg';
import iconFamily from 'assets/images/all-about-me-images/aam-family.jpg';
import iconGeneral from 'assets/images/all-about-me-images/aam-general.jpg';
import iconGrowingUp from 'assets/images/all-about-me-images/aam-growingup.jpg';
import iconParenthood from 'assets/images/all-about-me-images/aam-parenthood.jpg';
import iconRelationShips from 'assets/images/all-about-me-images/aam-relationships.jpg';
import iconSpiritual from 'assets/images/all-about-me-images/aam-spiritual.jpg';
import iconTheWorldAroundMe from 'assets/images/all-about-me-images/aam-world.jpg';
import iconMarriage from 'assets/images/all-about-me-images/aam-marriage.jpg';
import iconPersonal from 'assets/images/all-about-me-images/aam-personal.jpg';

export default (category) => {
    switch (category) {
        case 'accomplishments':
            return iconAccomplishments;
        case 'challenges':
            return iconChallenges;
        case 'education':
            return iconEducation;
        case 'family':
            return iconFamily;
        case 'general':
            return iconGeneral;
        case 'growing-up':
            return iconGrowingUp;
        case 'marriage':
            return iconMarriage;
        case 'parenthood':
            return iconParenthood;
        case 'personal':
            return iconPersonal;
        case 'relationships':
            return iconRelationShips;
        case 'spiritual':
            return iconSpiritual;
        case 'the-world-around-me':
            return iconTheWorldAroundMe;
        default:
            return null;
    }
};
