import React from 'react';
import verificationImg from 'assets/images/welcome-to-jrnl.png';
import { Link } from 'react-router-dom';
import { URLS } from 'common/urls';

export const ActivatedModal = ({ closeModal, userName }) => {
    return (
        <section className="verification">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8 col-xl-7 verification-banner">
                        <div className="verification-banner-img">
                            <img
                                src={verificationImg}
                                alt="verification"
                                className="img-fluid"
                            />
                        </div>
                        <div className="verification-banner-content">
                            <h3>Welcome to JRNL, {userName}!</h3>
                            <p style={{ lineHeight: '25px' }}>
                                Your account has been activated. You can now log
                                in and start saving your stories.
                            </p>
                            <button
                                className="btn btn-gradient"
                                onClick={() => closeModal()}>
                                <Link
                                    to={URLS.LOGIN}
                                    className="redirect-login">
                                    Log In
                                </Link>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
