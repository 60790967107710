import React from 'react';

export const MediaDetails = ({media}) => {
    return (
        <div className="media-details-section">
            <div className="media-sidebar-header-content">
                <h3 className="media-sidebar-title">
                    Image Details
                </h3>
            </div>
            <ul className="media-details-list">
                <li>
                    <span className="icon-media-calendar"/>
                    <p className="media-detail">
                        Tuesday, 14 July 2020
                        <span>13:36 (GMT +02) </span>
                    </p>
                </li>
                <li>
                    <span className="icon-media-img"/>
                    <p className="media-detail">
                        IMG_1234.JPG
                        <span>
                            3.32 MB · 5 MP (1932 × 2576)
                        </span>
                    </p>
                </li>
                <li>
                    <span className="icon-media-camera"/>
                    <p className="media-detail">
                        Apple iPhone 11 Pro Max
                        <span>
                            ƒ/2,2 · 1/17 · 2,65mm · ISO 640
                        </span>
                    </p>
                </li>
                <li>
                    <span className="icon-media-upload"/>
                    <p className="media-detail">
                        Uploaded from JRNL for iOS
                    </p>
                </li>
            </ul>
        </div>
    );
};