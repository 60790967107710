import React from 'react';
import AAMImage from 'assets/images/banners/banner-aam.png';
import mediaImg from 'assets/images/photo.png';
import textImg from 'assets/images/text.png';
import jrnlCircleLogo from 'assets/images/jrnl-logo-circle.png';
export const IncludedAccounts = () => {
    return (
        <section className="all-accounts-include">
            <div className="container">
                <div className="row justify-content-center all-accounts-header">
                    <h3 className="all-accounts-title">All Accounts Include</h3>
                </div>
                <div className="row justify-content-center all-accounts-features">
                    <div className="col-12 col-md-6 col-xl-4 feature-card">
                        <img src={AAMImage} alt="feature" />
                        <div className="feature-card-content">
                            <h3 className="feature-card-title">AAM</h3>
                            <p className="feature-card-text">
                                ALL ABOUT ME SECTION
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-4 feature-card">
                        <img src={mediaImg} alt="feature" />
                        <div className="feature-card-content">
                            <h3 className="feature-card-title">Photos</h3>
                            <p className="feature-card-text">
                                Multiple Photos/Entry
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-4 feature-card">
                        <img src={textImg} alt="feature" />
                        <div className="feature-card-content">
                            <h3 className="feature-card-title">Entries</h3>
                            <p className="feature-card-text">
                                Unlimited Text Entries
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-4 feature-card">
                        <img src={jrnlCircleLogo} alt="feature" />
                        <div className="feature-card-content">
                            <h3 className="feature-card-title">Types</h3>
                            <p className="feature-card-text">
                                Can purchase JRNL Types
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
