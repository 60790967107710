import { SETTINGS_REMINDER_URL } from 'common/constant';
import buildV2Authorization from 'common/utils/buildV2Authorization';
import { UserSelector } from 'redux/selectors/UserSelector';
import {
    SETTINGS_REMINDER_FETCH,
    SETTINGS_REMINDER_CREATE,
    SETTINGS_REMINDER_EDIT,
    SETTINGS_REMINDER_DELETE
} from 'redux/actions/actionTypes';

export const create = (reminder) => (dispatch, getState) => {
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);
    return dispatch({
        type: SETTINGS_REMINDER_CREATE,
        payload: {
            request: {
                url: SETTINGS_REMINDER_URL,
                method: 'POST',
                data: reminder,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};
export const edit = (reminder, index) => (dispatch, getState) => {
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);
    return dispatch({
        type: SETTINGS_REMINDER_EDIT,
        payload: {
            request: {
                url: `${SETTINGS_REMINDER_URL}${reminder.id}/`,
                method: 'PUT',
                data: reminder,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const delete_reminder = (id) => (dispatch, getState) => {
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);
    return dispatch({
        type: SETTINGS_REMINDER_DELETE,
        payload: {
            request: {
                url: `${SETTINGS_REMINDER_URL}${id}/`,
                method: 'DELETE',
                options: {
                    id
                },
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};
export const fetch = () => (dispatch, getState) => {
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);
    return dispatch({
        type: SETTINGS_REMINDER_FETCH,
        payload: {
            request: {
                url: SETTINGS_REMINDER_URL,
                method: 'GET',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};
