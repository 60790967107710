import React, { useMemo, useState, useCallback } from 'react';
import { connect } from 'react-redux';

//core components
import DropDown from 'components/DropDown';

import * as FilterActions from 'redux/actions/filter';
import * as EntryActions from 'redux/actions/entry';
import { updateUI } from 'redux/actions/ui';
import { DISPLAY_FILTER, ENTRY_DRAFT } from 'common/constant';

// icons
import iconCaretDown from 'assets/images/icons_svg/icon_caret-down.svg';

const DisplayFilter = ({ dispatch }) => {
    const values = useMemo(
        () => [
            { value: DISPLAY_FILTER.ALL, label: 'All Entries' },
            { value: DISPLAY_FILTER.JUST_ME, label: 'Just My Entries' },
            {
                value: DISPLAY_FILTER.SHARED_WITH_ME,
                label: 'Entries Shared With Me'
            },
            {
                value: DISPLAY_FILTER.SHARED_BY_ME,
                label: 'Entries I Have Shared'
            },
            { value: DISPLAY_FILTER.HIDDEN, label: 'View Hidden Entries' },
            { value: DISPLAY_FILTER.DRAFT, label: 'View Draft' }
        ],
        []
    );
    const [selected, setSelected] = useState(values[0]);

    const onDisplayFilter = useCallback(
        (value) => {
            dispatch(
                FilterActions.update({
                    entryViewMode: value,
                    minDate: undefined,
                    maxDate: undefined
                })
            );
            dispatch(EntryActions.setLoadingProgress());
            dispatch(
                updateUI({
                    showingProgressBar: true
                })
            );
            if (value === ENTRY_DRAFT) {
                dispatch(EntryActions.fetchDraftEntries());
            } else {
                dispatch(EntryActions.fetch());
            }
        },
        [dispatch]
    );

    const onSelect = useCallback(
        (value) => {
            onDisplayFilter(value.value);
            setSelected(value);
        },
        [onDisplayFilter]
    );

    const showItems = useCallback(() => {
        return values.map((value) => (
            <li
                id={value.value}
                key={value.value}
                onClick={() => onSelect(value)}
                className={selected.value === value.value ? 'selected' : ''}>
                {value.label}
            </li>
        ));
    }, [onSelect, selected, values]);

    return (
        <div className="form-group">
            <div className="form-group__item">
                <label>Display</label>
                <DropDown
                    className="form-group__select"
                    id="displayModal"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    anchor={
                        <React.Fragment>
                            <span className="form-group__select-text">
                                {selected.label}
                            </span>
                            <img src={iconCaretDown} alt="" />
                        </React.Fragment>
                    }
                    dropDownMenuProps={{
                        'aria-labelledby': 'displayModal'
                    }}>
                    {showItems()}
                </DropDown>
            </div>
        </div>
    );
};

export default connect()(DisplayFilter);
