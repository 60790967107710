import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// core components
import { DropDown } from 'components';

// Selectors
import { getNotifications, getUI } from 'redux/selectors';
import NotificationCard from 'components/NotificationCard';

// Actions
import * as NotificationActions from 'redux/actions/notification';
import { Link } from 'react-router-dom';
import { URLS } from 'common/urls';

// Icons
import iconEllipsisVert from 'assets/images/icons_svg/icon_ellipsis-vert.svg';

const Notifications = ({ notifications, dispatch, ui }) => {
    // render notification dropdown
    const renderNotifications = useCallback(() => {
        return notifications.map((item) => (
            <NotificationCard
                fullView={true}
                key={item.key}
                notification={item}
            />
        ));
    }, [notifications]);

    const markAllRead = useCallback(() => {
        dispatch(NotificationActions.markReadAll());
    }, [dispatch]);

    return (
        <main id="main" className="app-main">
            <div className={`page-container ${ui.viewWidth}`}>
                <div className="page page--notifications">
                    <div className="page__header">
                        <div className="page__title">Notifications</div>
                        <DropDown
                            dropDownMenuProps={{
                                className: 'dropdown-menu',
                                'aria-labelledby': 'notificationsMenu',
                                style: { height: 'auto' }
                            }}
                            anchor={
                                <img
                                    className="btn-icon btn-icon--4"
                                    src={iconEllipsisVert}
                                    alt=""
                                />
                            }>
                            <li onClick={markAllRead}>Mark all as read</li>
                            <li>
                                <Link to={URLS.SETTINGS.NOTIFICATION}>
                                    Notification settings
                                </Link>
                            </li>
                        </DropDown>
                    </div>
                    <div className="notifications-list">
                        <div className="alert-cards">
                            {renderNotifications()}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

const state = createStructuredSelector({
    notifications: getNotifications,
    ui: getUI
});
export default connect(state)(Notifications);
