import React, { useCallback, useEffect, useState } from 'react';
// @ts-ignore
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';

import { EntryInfiniteScrollList } from 'components/Entry/EntryList2';

import * as EntryActions from 'redux/actions/entry';
import { getJournalList } from 'redux/selectors';
// icons
import iconCaretDown from 'assets/images/icons_svg/icon_caret-down.svg';
import iconCheckSm from 'assets/images/icons_svg/icon_check-sm.svg';
import addIcon from 'assets/images/media/icon-plus-circle.svg';
import deleteIcon from 'assets/images/media/icon-delete.svg';
const AddJournalEntry = ({
    selectedEntry,
    changeEntryStatus,
    dispatch,
    journals,
    index,
    deleteJournalEntry
}) => {
    const [entries, setEntries] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [openEntriesDrop, setOpenEntriesDrop] = useState(false);
    const [openJournalsDrop, setOpenJournalsDrop] = useState(false);
    const [selectedJournal, setSelectedJournal] = useState(null);
    const [deleteIconFlag, setDeleteIconFlag] = useState(false);
    const [addIconFlag, setAddIconFlag] = useState(true);

    const toggleEntriesDrop = useCallback(() => {
        setOpenEntriesDrop((prevOpenEntriesDrop) => {
            return !prevOpenEntriesDrop;
        });
    }, []);

    const toggleJournalsDrop = useCallback(() => {
        setOpenJournalsDrop((prevOpenJournalsDrop) => !prevOpenJournalsDrop);
    }, []);

    const renderEntriesDropdownName = useCallback(() => {
        if (!selectedEntry) {
            return 'Select Entry';
        }
        const title = selectedEntry.title
            ? selectedEntry.title
            : `No Title - ${selectedEntry.created}`;
        return title;
    }, [selectedEntry]);

    const renderJournalsDropdownName = useCallback(() => {
        if (!selectedJournal) {
            return 'Select Journal';
        }
        return selectedJournal.title;
    }, [selectedJournal]);

    const changeJournalStatus = useCallback(
        (journal, status) => {
            const sj =
                status === true
                    ? selectedJournal?.id === journal.id
                        ? null
                        : journal
                    : null;
            setSelectedJournal(sj);
        },
        [selectedJournal]
    );
    const _changeEntryStatus = useCallback(
        (entry, status) => {
            setOpenEntriesDrop(false);
            setDeleteIconFlag(true);
            changeEntryStatus &&
                changeEntryStatus(entry, status, index ? index : 0);
        },
        [changeEntryStatus, index]
    );

    useEffect(() => {
        if (selectedJournal) {
            setOpenJournalsDrop(false);
            dispatch(
                EntryActions.fetchEntriesWithOptions({
                    by_journal: selectedJournal.id,
                    order_by: '-entry_date'
                })
            ).then((res) => {
                if (res.payload.status === 200) {
                    const data = res.payload.data;
                    setEntries(data);
                    setHasMore(data?.meta?.next || false);
                }
            });
        }
    }, [dispatch, selectedJournal]);

    useEffect(() => {
        if (selectedEntry) {
            setOpenEntriesDrop(false);
        }
    }, [selectedEntry]);

    const renderJournalItem = useCallback(
        (journal) => {
            const active = Boolean(journal.id === selectedJournal?.id);
            return (
                <li
                    key={`journal-list-${journal.id}`}
                    onClick={() => changeJournalStatus(journal, !active)}
                    className={`${active ? 'active' : ''}`}>
                    <div className="dropdown-selected-list__check">
                        <img src={iconCheckSm} alt="" />
                    </div>
                    <div className="dropdown-selected-list__title truncate">
                        <span>{journal.title}</span>
                    </div>
                </li>
            );
        },
        [changeJournalStatus, selectedJournal]
    );
    const renderEntryTitle = (entry) => {
        const title = entry.title
            ? entry.title
            : `No Content - ${entry.created}`;
        return title;
    };

    const renderEntryItem = useCallback(
        (entry) => {
            const active = Boolean(entry.id === selectedEntry?.id);
            return (
                <li
                    key={`journal-list-${entry.id}`}
                    onClick={() => _changeEntryStatus(entry, !active)}
                    className={`${active ? 'active' : ''}`}>
                    <div className="dropdown-selected-list__check">
                        <img src={iconCheckSm} alt="" />
                    </div>
                    <div className="dropdown-selected-list__title truncate">
                        <span>{renderEntryTitle(entry)}</span>
                    </div>
                </li>
            );
        },
        [_changeEntryStatus, selectedEntry]
    );

    const addJournalEntry = useCallback(() => {
        if (deleteIconFlag) {
            setAddIconFlag(false);
            changeEntryStatus && changeEntryStatus(null, false, index + 1);
        }
    }, [changeEntryStatus, index, deleteIconFlag]);

    const _deleteJournalEntry = useCallback(() => {
        setAddIconFlag(true);
        setDeleteIconFlag(false);
        setSelectedJournal(null);
        deleteJournalEntry && deleteJournalEntry(index);
    }, [index, deleteJournalEntry]);

    return (
        <ul
            className="journals-entries-form-list"
            key={`media-journal-entries-${index}`}>
            <li>
                <div id="media-journal-tags-wrapper" className="dropdown show">
                    <div
                        onClick={toggleJournalsDrop}
                        className="form-group__select dropdown-toggle truncate"
                        role="button"
                        aria-disabled={true}
                        id="selectedTagsModal"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                        <span>{renderJournalsDropdownName()}</span>
                        <img src={iconCaretDown} alt="" />
                    </div>
                    <div
                        className={`dropdown-menu max-height--400 scrollable ${
                            openJournalsDrop ? 'show' : ''
                        }`}
                        aria-labelledby="selectedJournalsModal">
                        <ul className="dropdown-selected-list">
                            {(journals?.journals || []).map((journal) =>
                                renderJournalItem(journal)
                            )}
                        </ul>
                    </div>
                </div>
                {selectedJournal?.id && (
                    <div
                        id="media-entry-journal-tags-wrapper"
                        className="dropdown show">
                        <div
                            className="form-group__select dropdown-toggle truncate"
                            role="button"
                            id="selectedEntryModal"
                            onClick={toggleEntriesDrop}
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                            <span>{renderEntriesDropdownName()}</span>
                            <img src={iconCaretDown} alt="" />
                        </div>
                        <div
                            id="media-library-dd-journal-entries"
                            className={`dropdown-menu max-height--400 scrollable ${
                                openEntriesDrop ? 'show' : ''
                            }`}
                            aria-labelledby="selectedEntryModal"
                            style={{ height: '200px' }}>
                            <ul className="dropdown-selected-list">
                                <EntryInfiniteScrollList
                                    // @ts-ignore
                                    dispatch={dispatch}
                                    // @ts-ignore
                                    entries={entries?.objects || []}
                                    htmlElementId="media-library-dd-journal-entries"
                                    hasMore={hasMore}
                                    setEntries={setEntries}
                                    setHasMore={setHasMore}
                                    renderEntry={renderEntryItem}
                                />
                            </ul>
                        </div>
                    </div>
                )}

                {deleteIconFlag && (
                    <span className="trash-icon" onClick={_deleteJournalEntry}>
                        <img src={deleteIcon} alt="deleteIcon" />
                    </span>
                )}
                {addIconFlag && (
                    <span onClick={addJournalEntry} className="add-icon">
                        <img src={addIcon} alt="addIcon" />
                    </span>
                )}
            </li>
        </ul>
    );
};
const state = createStructuredSelector({
    journals: getJournalList
});
export default connect(state)(AddJournalEntry);
