import React, { useEffect, useRef, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
//Selectors
import { getBookById, getUI } from 'redux/selectors';
// Actions
import * as BookActions from 'redux/actions/book';
import * as BookShippingAddressActions from 'redux/actions/bookshippingaddress';
import * as PaymentProfileActions from 'redux/actions/paymentprofile';
import { showBookPreviewDialog } from 'common/utils/dialog-utils';

const BookPreview = ({ book, match, dispatch, ui }) => {
    const { bookId } = match?.params;
    const [dialogOpen, setDialogOpen] = useState(false);
    const bookIdRef = useRef(null);
    const [existBookFlag, setExistBookFlag] = useState(false);

    useEffect(() => {
        if (bookId && !book && bookIdRef.current !== bookId && !existBookFlag) {
            dispatch(BookActions.poll({ id: bookId }));
        }
        bookIdRef.current = bookId;
    }, [bookId, book, dispatch, existBookFlag]);

    useEffect(() => {
        dispatch(BookShippingAddressActions.fetch());
        dispatch(PaymentProfileActions.fetch_default());
    }, [dispatch]);

    useEffect(() => {
        if (book && dialogOpen === false) {
            if (book.cover_pdf && book.downloadable_pdf) {
                setExistBookFlag(true);
                setTimeout(() => {
                    showBookPreviewDialog(book.id);
                }, 100);

                setDialogOpen(true);
            } else {
                setTimeout(() => {
                    setExistBookFlag(false);
                }, 1000);
            }
        }
    }, [book, dispatch, dialogOpen, ui.dialogs]);

    return <div></div>;
};

const state = createStructuredSelector({
    book: getBookById,
    ui: getUI
});

export default connect(state)(BookPreview);
