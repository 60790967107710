export default (filter, type) => {
    const { entryViewMode } = filter;

    type = type || 'entry';
    let resource = undefined;

    switch (entryViewMode) {
        case 'all':
        case 'shared_with_me':
        case 'shared_by_me':
        case 'sharing_journal': {
            resource = `timeline_${type}`;
            break;
        }
        case 'just_me': {
            resource = `${type}`;
            break;
        }
        default: {
            resource = `${type}`;
        }
    }

    return resource;
};
