import _ from 'underscore';
import {
    FETCH_PAYMENT_PROFILE_LIST,
    CREATE_BOOK_ORDER,
    CREATE_PAYMENT_PROFILE,
    DELETE_PAYMENT_PROFILE,
    EDIT_PAYMENT_PROFILE,
    ACTION_USER_LOGOUT
} from 'redux/actions/actionTypes';
import { SUCCESS_SUFFIX } from 'common/constant';

const initialState = {
    paymentProfiles: []
};

const PaymentProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PAYMENT_PROFILE_LIST + SUCCESS_SUFFIX: {
            let paymentProfiles = state.paymentProfiles;

            paymentProfiles = action.payload.data.objects;
            paymentProfiles.filter((profile) => profile.deleted === true);

            paymentProfiles.unshift(
                paymentProfiles.splice(
                    paymentProfiles.findIndex(
                        (profile) => profile.default === true
                    ),
                    1
                )[0]
            );
            return _.extend({}, state, { paymentProfiles });
        }

        case CREATE_PAYMENT_PROFILE + SUCCESS_SUFFIX: {
            const paymentProfiles = state.paymentProfiles;
            const newProfile = action.payload.data;
            paymentProfiles.unshift(newProfile);

            return _.extend({}, state, { paymentProfiles });
        }
        case 'CREATE_PAYMENT_PROFILE_FAIL': {
            const paymentProfiles = state.paymentProfiles;
            alert(
                'Failed to create card, please make sure you are entering the right information.'
            );
            return _.extend({}, state, { paymentProfiles });
        }

        case DELETE_PAYMENT_PROFILE + SUCCESS_SUFFIX: {
            let paymentProfiles = state.paymentProfiles;
            const deletedProfile = action.payload.config.options.cardId;
            paymentProfiles = _.filter(
                paymentProfiles,
                (profile) => profile.id !== deletedProfile
            );

            return _.extend({}, state, {
                paymentProfiles
            });
        }

        case EDIT_PAYMENT_PROFILE + SUCCESS_SUFFIX: {
            let paymentProfiles = state.paymentProfiles;
            const editedProfile = action.payload.data;

            const index = _.findIndex(
                paymentProfiles,
                (profile) => editedProfile.id === profile.id
            );

            if (index >= 0) {
                paymentProfiles = [].concat(
                    paymentProfiles.slice(0, index),
                    [editedProfile],
                    paymentProfiles.slice(index + 1)
                );
            } else {
                paymentProfiles = [].concat(
                    [editedProfile],
                    paymentProfiles || []
                );
            }
            return _.extend({}, state, {
                paymentProfiles
            });
        }

        case CREATE_BOOK_ORDER + SUCCESS_SUFFIX: {
            let bookOrders = state.bookOrders;
            const object = action.payload.data;

            const index = _.findIndex(
                bookOrders,
                (bookOrder) => object.id === bookOrder.id
            );

            if (index >= 0) {
                bookOrders = [].concat(
                    bookOrders.slice(0, index),
                    [object],
                    bookOrders.slice(index + 1)
                );
            } else {
                bookOrders = [].concat([object], bookOrders || []);
            }
            return _.extend({}, state, {
                bookOrders
            });
        }

        case ACTION_USER_LOGOUT: {
            return _.extend({}, initialState);
        }

        default: {
            return {
                ...state
            };
        }
    }
};

export default PaymentProfileReducer;
