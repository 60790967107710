import React, { Fragment } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Avatar } from '@material-ui/core';
import { useHistory } from 'react-router';
// firebase
import { db, FIREBASE_DB_ROOT, uid } from 'common/firebase';
// images
import facebook from 'assets/images/social-media/facebook.svg';
import { URLS } from 'common/urls';
import { getUser } from 'redux/selectors';
import { getDifferenceDateStr } from 'common/utils/dateUtils';
import moment from 'moment';

const FBAutoImportNotification = ({
    notificationKey,
    data,
    user: authUser,
    fullView
}) => {
    const { entry, read, created_at } = data;
    const history = useHistory();

    // read notification
    const readNotification = React.useCallback(() => {
        db.ref(
            `${FIREBASE_DB_ROOT}${uid}/${authUser.id}/${notificationKey}`
        ).set({
            ...data,
            read: true
        });
    }, [authUser.id, data, notificationKey]);

    const onViewEntry = React.useCallback(() => {
        readNotification();
        history.push(`${URLS.ENTRY.ROOT}${entry.id}`);
    }, [readNotification, history, entry]);

    return (
        <Fragment>
            <li className="alert-card__item alert-card__item--buttons">
                <div className="alert-card__img">
                    <Avatar src={facebook} alt="facebook-icon" />
                </div>
                <div className="alert-card__content">
                    Your latest post from Facebook has been imported into JRNL
                    <div className="alert-card__actions">
                        <button
                            className="alert-card__btn"
                            onClick={onViewEntry}>
                            View Entry
                        </button>
                    </div>
                </div>
                {!read && (
                    <div className="notification-active-bullet-dropdown"></div>
                )}
                {fullView && created_at && (
                    <p className="notification-timestamp ml-auto">
                        {getDifferenceDateStr(
                            moment.utc(created_at).local(),
                            moment(new Date())
                        )}
                    </p>
                )}
            </li>
        </Fragment>
    );
};

const state = createStructuredSelector({
    user: getUser
});
export default connect(state)(FBAutoImportNotification);
