import {
    UPDATE_EDITOR_STATE,
    INSERT_IMAGE_BLOCK,
    INSERT_MEDIA_BLOCK,
    RESET_IMAGE_STATE,
    UNSTAGE_ENTRY_IMAGE,
    UNSTAGE_ALL_IMAGES,
    REMOVE_EDITOR_NEWDATA,
    INSERT_INSTAGRAM_CONTENT
} from 'redux/actions/actionTypes';

export const clearEditor = (editorState) => (dispatch, getState) => {
    return dispatch({
        type: UPDATE_EDITOR_STATE,
        payload: {
            data: editorState
        }
    });
};

export const removeNewData = () => (dispatch) => {
    return dispatch({
        type: REMOVE_EDITOR_NEWDATA,
        payload: {}
    });
};

export const insertImageBlock = (data) => (dispatch, getState) => {
    return dispatch({
        type: INSERT_IMAGE_BLOCK,
        payload: {
            images: data
        }
    });
};

// add instagram content
export const insertInstagramContent = (data) => (dispatch) => {
    return dispatch({
        type: INSERT_INSTAGRAM_CONTENT,
        payload: {
            content: data
        }
    });
};

export const insertMediaBlock = (data) => (dispatch, getState) => {
    return dispatch({
        type: INSERT_MEDIA_BLOCK,
        payload: {
            file: data
        }
    });
};

export const resetImageState = () => (dispatch, getState) => {
    dispatch({
        type: RESET_IMAGE_STATE,
        payload: {
            data: undefined
        }
    });
};

export const unstageEntryImage = (src) => (dispatch, getState) => {
    dispatch({
        type: UNSTAGE_ENTRY_IMAGE,
        payload: {
            data: src
        }
    });
};

export const unstageImages = (src) => (dispatch, getState) => {
    dispatch({
        type: UNSTAGE_ALL_IMAGES,
        payload: {
            data: src
        }
    });
};
