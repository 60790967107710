import React, {
    Fragment,
    useCallback,
    useEffect,
    useRef,
    useState
} from 'react';
import moment from 'moment';
// @ts-ignore
import qs from 'qs';
// Onboarding Flow
import 'intro.js/introjs.css';
import { Steps } from 'intro.js-react';

// Utils

// actions
// @ts-ignore
import { connect } from 'react-redux';
import * as EntryActions from 'redux/actions/entry';
import * as FilterActions from 'redux/actions/filter';
import * as JournalActions from 'redux/actions/journal';
import * as PublicUserActions from 'redux/actions/publicuser';
import * as TagActions from 'redux/actions/tag';
import * as TimelineActions from 'redux/actions/timelineapproval';
import * as InvitationActions from 'redux/actions/journalinvitation';
import * as ShareContactListsActions from 'redux/actions/sharecontactlist';
import { updateUI } from 'redux/actions/ui';

// selectors
import { createStructuredSelector } from 'reselect';
import {
    getAAM,
    getCalendar,
    getCommentList,
    getDefaultJournal,
    getEditorState,
    getEntryState,
    getFilteredEntries,
    getFilterJournal,
    getFilters,
    getFilterTag,
    getJournalList,
    getLoadingProgress,
    getMyPublicUser,
    getRecentContentDate,
    getTagList,
    getUI,
    getUser
} from 'redux/selectors';

// components
import { EmptyListIndicator } from 'components';
import EntryList from 'components/Entry/EntryList2';

// dialogs
import JournalIndicator from 'components/JournalIndicator/JournalIndicator';
import EntryCreate from 'pages/Entry/EntryCreate.js';
// @ts-ignore
import { useHistory, useLocation, useParams } from 'react-router';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { JOURNAL_AAM } from 'common/constant';
// import useWindowDimensions from 'common/hooks/useWindowDimensions';

import { closeDialog, openDialog } from 'common/utils/dialog-utils';
import OnboardingTimeline from 'components/OnboardingTimeline';
import { isOldUser } from 'common/utils/newUser';
import { URLS } from 'common/urls';

const TimelineView = (
    {
        user,
        dispatch,
        filters,
        ui,
        journal,
        editor,
        defaultJournal,
        public_user,
        aam,
        loadingProgress,
        loading,
        filterJournal,
        entryDefaultJournal,
        trial,
        ...rest
    },
    // @ts-ignore
    context
) => {
    // states
    const [hasNoEntries, setHasNoEntries] = useState();
    const [listEmpty, setListEmpty] = useState();
    const [acceptedStatus, setAcceptedStatus] = useState(undefined);
    const [invitationId, setInvitationId] = useState(undefined);
    const [journalUser, setJournalUser] = useState(null);
    const [displayOnboarding, setDisplayOnboarding] = useState(false);
    const [backStatus, setBackStatus] = useState(0);
    const location = useLocation();
    const { journalId } = useParams();
    const history = useHistory();
    const stepsRef = useRef(null);

    const steps = [
        {
            title: '',
            intro: ''
        },
        {
            title: 'New Entry Creation',
            element: '.post',
            intro:
                'Create written entries, image-based entries, or include multimedia files to make the most of your JRNL account.',
            position: 'bottom'
        },
        {
            title: 'Calendar',
            element: '.calendar',
            intro:
                'Miss a day? No problem. Go back in time and jot down your thoughts. Need to transcribe old journals into JRNL? No problem.',
            position: 'right'
        },
        {
            title: 'Create',
            element: '#add-menu-img',
            intro:
                'Click on the ‘+’ button to see all of the options for creating new entries, journals, tags, etc.',
            position: 'left'
        },
        {
            title: 'Greate job!',
            element: '.dropdown-menu--add',
            intro: 'Now, let’s check out the Main Menu!',
            position: 'left'
        },
        {
            title: 'Main Menu',
            element: '#more-img',
            intro: "Click on this button to see 'All about me' and 'Contacts'",
            position: 'top'
        },
        {
            title: 'Main Menu',
            element: '.dropdown--more',
            intro: 'Click on ‘All About Me’ in the dropdown menu.',
            position: 'left'
        },
        {
            title: '',
            intro: ''
        }
    ];
    const [initialStep, setInitialStep] = useState(0);
    const [stepsEnabled, setStepsEnabled] = useState(true);

    const onExit = useCallback(() => {
        setStepsEnabled(false);
        if (backStatus === 2) {
            localStorage.setItem('skipOnboarding', '1');
            dispatch(
                updateUI({
                    onBoarding: false,
                    onBoardingEnd: true
                })
            );
        }
    }, [dispatch, backStatus]);

    const options = {
        hidePrev: false,
        hideNext: false,
        prevLabel: 'Back',
        nextLabel: 'Next',
        showBullets: false,
        doneLabel: 'Next',
        exitOnOverlayClick: false
    };

    useDeepCompareEffect(() => {
        if (
            filters.entryViewMode === 'sharing_journal' &&
            filters.filterByJournal
        ) {
            setJournalUser(filters.filterByJournal[0].user);
        }
    }, [filters]);

    useEffect(() => {
        // dispatch(JournalActions.setLoadingProgress());
        dispatch(TagActions.setLoadingProgress());
        dispatch(
            updateUI({
                showingProgressBar: true
            })
        );
        dispatch(EntryActions.setLoadingProgress());
        dispatch(JournalActions.fetch()); // Remove as it's fetched in App.js
        dispatch(PublicUserActions.fetch());
        dispatch(TagActions.fetch());
        //dispatch(InvitationActions.fetch());
        dispatch(TimelineActions.fetch());
        dispatch(ShareContactListsActions.fetch());
        dispatch(EntryActions.fetch()); // Remove as it's fetched in App.js
        // Reset the search subheader
        dispatch(
            updateUI({
                showSearchInput: false
            })
        );
    }, [dispatch]);

    // Handle invitation banner
    useEffect(() => {
        const queryObj = qs.parse(location.search, {
            ignoreQueryPrefix: true
        });
        setAcceptedStatus(queryObj.accepted);
        setInvitationId(queryObj.invitation);
    }, [location]);

    const onViewRecent = useCallback(
        (date) => {
            const dateAsMoment = moment(date, 'X');
            const viewMode = filters.dateViewMode;
            let minDate, maxDate;

            switch (viewMode) {
                case 'year': {
                    minDate = moment(dateAsMoment).startOf('year');
                    maxDate = moment(dateAsMoment).endOf('year');
                    break;
                }
                case 'month': {
                    minDate = moment(dateAsMoment).startOf('month');
                    maxDate = moment(dateAsMoment).endOf('month');
                    break;
                }
                case 'day': {
                    minDate = moment(dateAsMoment).startOf('day');
                    maxDate = moment(dateAsMoment).endOf('day');
                    break;
                }
                default: {
                    break;
                }
            }

            const data = {
                dateViewMode: viewMode,
                minDate,
                maxDate
            };

            dispatch(FilterActions.update(data));
            dispatch(EntryActions.fetch());
        },
        [dispatch, filters.dateViewMode]
    );

    const onRemoveFilters = useCallback(() => {
        dispatch(FilterActions.reset());
    }, [dispatch]);

    useEffect(() => {
        let listEmpty = false;
        let hasNoEntries;

        const { entry, filtered } = rest;

        if (!(entry && entry.isPending)) {
            if (
                filtered &&
                filtered.entries &&
                filtered.entries.length === 0 &&
                filtered &&
                filtered.unfilteredEntryCount > 0
            ) {
                listEmpty = true;
            } else if (
                !(entry && entry.entries) ||
                (entry && entry.entries && entry.entries.length === 0)
            ) {
                listEmpty = true;
                hasNoEntries = true;
            }
        }

        // @ts-ignore
        setHasNoEntries(hasNoEntries);
        // @ts-ignore
        setListEmpty(listEmpty);
    }, [
        rest.entry,
        editor,
        rest.filtered,
        journal,
        ui,
        user,
        public_user,
        aam,
        filters,
        rest
    ]);

    useEffect(() => {
        if (isOldUser(user)) {
            return;
        }
        if (localStorage.getItem('skipOnboarding') === '1') {
            return;
        }
        if (!user.has_seen_welcome_web) {
            if (!ui.onBoardingEnd) {
                if (ui.onBoarding) {
                    setDisplayOnboarding(true);
                } else {
                    history.push(URLS.SETTINGS.PROFILE);
                }
            }
        } else {
            if (hasNoEntries) {
                if (!ui.onBoardingEnd) {
                    if (ui.onBoarding) {
                        setDisplayOnboarding(true);
                    } else {
                        history.push(URLS.SETTINGS.PROFILE);
                    }
                }
            }
        }
    }, [hasNoEntries, ui, history, user]);

    // render

    const renderEmptyListIndicator = useCallback(() => {
        if (listEmpty) {
            return (
                <EmptyListIndicator
                    filters={filters}
                    filtered={rest.filtered}
                    recentContent={rest.recentContent}
                    filterJournal={rest.filterJournal}
                    filterTag={rest.filterTag}
                    onViewRecent={onViewRecent}
                    onRemoveFilters={onRemoveFilters}
                    hasNoEntries={hasNoEntries}
                />
            );
        }
    }, [
        filters,
        rest.filtered,
        rest.recentContent,
        rest.filterJournal,
        rest.filterTag,
        hasNoEntries,
        onViewRecent,
        onRemoveFilters,
        listEmpty
    ]);

    const renderEntryEditor = useCallback(() => {
        const isAam = () => {
            if (Number(journalId)) {
                return (
                    filters.filterByJournal?.find((journal) => {
                        return journal.id === Number(journalId);
                    })?.journal_type === JOURNAL_AAM
                );
            }
        };

        return (
            <>
                {filters.filterByJournal && (
                    <JournalIndicator journals={filters.filterByJournal} />
                )}
                {!isAam() && (
                    <EntryCreate entryDefaultJournal={entryDefaultJournal} />
                )}
            </>
        );
    }, [filters.filterByJournal, journalId, entryDefaultJournal]);

    const renderEntrylist = useCallback(() => {
        if (!listEmpty) {
            return (
                <EntryList
                    user={user}
                    entries={rest.filtered.entries}
                    filters={filters}
                    meta={rest.entry.meta}
                />
            );
        }
    }, [listEmpty, filters, rest, user]);

    const approveInvitation = useCallback(() => {
        // Update Invitation Accept status
        dispatch(
            InvitationActions.update(
                {
                    accept_status: true
                },
                invitationId
            )
            // @ts-ignore
        ).then((response) => {
            dispatch(
                JournalActions.fetch({
                    id: filters.filterByJournal[0].id
                })
            );
        });
        setAcceptedStatus(true);
    }, [dispatch, filters, invitationId]);

    const renderInvitationBanner = useCallback(() => {
        return (
            <section className="shared-banner">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 banner-card shared-jrnl-banner default-mode">
                            <img
                                className="shared-jrnl-img"
                                src={journalUser?.avatar_image_url}
                                alt="shared-jrnl-img"
                            />
                            <div className="shared-jrnl-banner__content">
                                <h3 className="shared-jrnl-banner__content-title">
                                    {journalUser?.public_display_name} has
                                    shared this journal with you
                                </h3>
                                <p className="shared-jrnl-banner__content-text">
                                    You can add this to My JRNL; you will see it
                                    between your entries
                                </p>
                                <button
                                    onClick={approveInvitation}
                                    className="btn btn-add-to-jrnl">
                                    Add to My JRNL
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }, [journalUser, approveInvitation]);

    const [continueFlag, setContinueFlag] = useState(false);

    const onContinue = useCallback(() => {
        closeDialog();
        setStepsEnabled(true);
        setInitialStep(1);
        setContinueFlag(true);
    }, []);

    useEffect(() => {
        if (
            !loading &&
            parseInt(loadingProgress) === 100 &&
            displayOnboarding &&
            ui.bigStep === 2
        ) {
            setStepsEnabled(true);
            setInitialStep(6);
            setContinueFlag(true);
        }
    }, [ui.bigStep, displayOnboarding, loading, loadingProgress]);

    useEffect(() => {
        if (ui.bigStep === 2) {
            return;
        }
        if (displayOnboarding) {
            dispatch(
                updateUI({
                    onBoardingMoreMenu: false,
                    onBoardingAddMenu: false
                })
            );
            openDialog(OnboardingTimeline, {
                title: 'This is Timeline!',
                text:
                    'Quickly see all of the activity across your JRNL account in one place. Create new entries, manage filters, and more!',
                onContinue
            });
        } else {
            closeDialog();
        }
    }, [onContinue, displayOnboarding, dispatch, ui.bigStep, filterJournal]);

    const onBeforeChange = useCallback(
        (nextStepIndex) => {
            switch (nextStepIndex) {
                case 0:
                    setBackStatus(1);
                    setStepsEnabled(false);
                    dispatch(
                        updateUI({
                            onBoardingMoreMenu: false,
                            onBoardingAddMenu: false
                        })
                    );
                    openDialog(OnboardingTimeline, {
                        title: 'This is Timeline!',
                        text:
                            'Quickly see all of the activity across your JRNL account in one place. Create new entries, manage filters, and more!',
                        onContinue
                    });

                    stepsRef.current.introJs._currentStep = 1;
                    return false;
                case 4:
                    setBackStatus(2);
                    dispatch(
                        updateUI({
                            onBoardingAddMenu: true,
                            onBoardingMoreMenu: false
                        })
                    );
                    break;
                case 5:
                    setBackStatus(2);
                    dispatch(
                        updateUI({
                            onBoardingAddMenu: false,
                            onBoardingMoreMenu: false
                        })
                    );
                    break;
                case 6:
                    setBackStatus(2);
                    dispatch(
                        updateUI({
                            onBoardingAddMenu: false,
                            onBoardingMoreMenu: true
                        })
                    );
                    break;
                case 7:
                    setBackStatus(3);
                    dispatch(
                        updateUI({
                            onBoardingMoreMenu: false
                        })
                    );
                    history.push(URLS.AAM.ROOT);
                    return false;
                default:
                    setBackStatus(2);
                    break;
            }
        },
        [dispatch, onContinue, history]
    );

    // const { width } = useWindowDimensions();
    return (
        <Fragment>
            {/* {renderOnboarding()} */}
            {!loading &&
                parseInt(loadingProgress) === 100 &&
                continueFlag &&
                displayOnboarding && (
                    <Steps
                        ref={stepsRef}
                        enabled={stepsEnabled}
                        initialStep={initialStep}
                        steps={steps}
                        onExit={onExit}
                        options={options}
                        // @ts-ignore
                        onBeforeChange={onBeforeChange}
                    />
                )}

            <div className="timeline-view-container">
                <section className="timeline">
                    <div className={`page-container ${ui.viewWidth}`}>
                        {acceptedStatus === 'false' &&
                            trial &&
                            journalUser &&
                            renderInvitationBanner()}
                        {!loading &&
                            parseInt(loadingProgress) === 100 &&
                            renderEmptyListIndicator()}
                        {!loading &&
                            parseInt(loadingProgress) === 100 &&
                            !ui.fullView &&
                            (!trial || acceptedStatus === true) &&
                            renderEntryEditor()}
                        {!loading &&
                            parseInt(loadingProgress) === 100 &&
                            renderEntrylist()}
                    </div>
                </section>
            </div>
        </Fragment>
    );
};

const state = createStructuredSelector({
    user: getUser,
    entry: getEntryState,
    journal: getJournalList,
    defaultJournal: getDefaultJournal,
    calendar: getCalendar,
    tag: getTagList,
    comment: getCommentList,
    editor: getEditorState,
    filtered: getFilteredEntries,
    filters: getFilters,
    public_user: getMyPublicUser,
    aam: getAAM,
    recentContent: getRecentContentDate,
    filterJournal: getFilterJournal,
    filterTag: getFilterTag,
    ui: getUI,
    loadingProgress: getLoadingProgress
});

export default connect(state)(TimelineView);
