import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getUser } from 'redux/selectors';
import { PLAN_LABELS } from 'common/constant';

const ProAccountJournalLimitDialog = ({ user, close }) => {
    return (
        <div className="modal-frame" id="finished-book-process-modal">
            <div className="modal-box modal-box--600">
                <div className="modal__header">
                    <div className="finished-book-process-header">
                        <h3 className="title">Shared Journal Limit Reached</h3>
                    </div>
                </div>
                <div className="modal__body shared-journal-limit-reached-text p-3">
                    <p className="shared-journal-limit-reached-text">
                        Your {PLAN_LABELS.PRO} account has a limit of{' '}
                        {user.is_pro ? 10 : 1} Shared Journals . Since you’ve
                        reach that limit, you won’t be able to create new shared
                        journals for the rest of your yearly subscription.{' '}
                    </p>

                    <button onClick={close} className="btn btn-modal-primary">
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    user: getUser
});
export default connect(state)(ProAccountJournalLimitDialog);
