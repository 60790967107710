import _ from 'underscore';
import buildAuthorization from 'common/utils/buildAuthorization';
import axios from 'axios';
import settings from 'common/configs/setting';
import { UserSelector } from 'redux/selectors/UserSelector';
import {
    FETCH_PUBLIC_USER_LIST,
    FETCH_PUBLIC_USER,
    FETCH_PUBLIC_USER_DETAIL
} from 'redux/actions/actionTypes';
import { PUBLIC_USER_URL } from 'common/constant';

export const fetch = (options = {}) => (dispatch, getState) => {
    const url = PUBLIC_USER_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);
    options = _.defaults(options, {
        limit: 1000
    });

    return dispatch({
        type: FETCH_PUBLIC_USER_LIST,
        payload: {
            request: {
                url,
                method: 'GET',
                params: options,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};
export const fetchIndividual = (id, user_url) => (dispatch, getState) => {
    const url = user_url || `${PUBLIC_USER_URL}${id}`;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: FETCH_PUBLIC_USER_DETAIL,
        payload: {
            request: {
                url,
                method: 'GET',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const fetchPublicUser = (publicUserId, options = {}) => (
    dispatch,
    getState
) => {
    const url = `${PUBLIC_USER_URL}${publicUserId}/`;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    dispatch({
        type: FETCH_PUBLIC_USER,
        payload: {
            request: {
                url,
                method: 'GET',
                params: options,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const getSharedEntryUser = (userUrl) => (dispatch, getState) => {
    try {
        const url = settings.url + userUrl;
        const user = UserSelector(getState());
        const auth = buildAuthorization(user);
        return axios.get(url, {
            headers: {
                Authorization: auth
            }
        });
    } catch (err) {
        //
    }
};
