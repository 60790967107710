import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import useDeepCompareEffect from 'use-deep-compare-effect';
// selector
import { getUser } from 'redux/selectors';
// constant
import { BOOK_ORDER_LIMIT, PLAN_LABELS } from 'common/constant';

const AccountStatus = ({ user, dispatch }) => {
    const [limit, setLimit] = React.useState(BOOK_ORDER_LIMIT.FREE);
    const [accountType, setAccountType] = React.useState(PLAN_LABELS.FREE);

    const renderDescription = () => {
        return (
            <p className="form-msg">
                Your {accountType} account gives you access to {limit} PDFs per
                year. You have{' '}
                <strong>{user.free_bookorder_count} remaining. </strong>
                {user.free_bookorder_count === 0 && (
                    <React.Fragment>
                        PDF Generation has a cost of{' '}
                        <strong>${user.book_prices.PDF_PRICE}</strong>{' '}
                    </React.Fragment>
                )}
            </p>
        );
    };

    useDeepCompareEffect(() => {
        // get user info and set limit count
        if (user.is_pro) {
            setLimit(BOOK_ORDER_LIMIT.PRO);
            setAccountType(PLAN_LABELS.PRO);
        } else if (user.is_ty) {
            setLimit(BOOK_ORDER_LIMIT.THANKYOU);
            setAccountType(PLAN_LABELS.LOYALTY);
        } else {
            setLimit(BOOK_ORDER_LIMIT.FREE);
            setAccountType(PLAN_LABELS.FREE);
        }
    }, [user]);

    return <React.Fragment>{renderDescription()}</React.Fragment>;
};

const state = createStructuredSelector({
    user: getUser
});
export default connect(state)(AccountStatus);
