import React, { useEffect, useCallback, useState, useRef } from 'react';
import moment from 'moment';
// redux
import { connect } from 'react-redux';
// Selectors
import { getUI } from 'redux/selectors';
import { createStructuredSelector } from 'reselect';
// Actions
import { updateUI, updateStepsFilledIn } from 'redux/actions/ui';
import * as EntryActions from 'redux/actions/entry';
// utils
import { checkAAMSetup } from 'common/utils/validatecheck/bookcreate';
import {
    ENTRY_ALL,
    ENTRY_DATE,
    ENTRY_DO_NOT_PRINT,
    ENTRY_MR,
    TIMEFRAME_STATUS_ERROR,
    TIMEFRAME_STATUS_LOADING,
    TIMEFRAME_STATUS_NONE,
    TIMEFRAME_STATUS_SUCCESS
} from 'common/constant';
// Components
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { convertUTCTime } from 'common/utils/dateUtils';
// images
import loadingRing from 'assets/images/loading-ring.svg';

const AllAboutMeStep = ({ display, ui, dispatch }) => {
    const [entryCount, setEntryCount] = useState(0);
    const [timeframeStatus, setTimeframeStatus] = useState(
        TIMEFRAME_STATUS_NONE
    );
    const [openMaxDateCalendar, setOpenMaxDateCalendar] = useState(false);
    const [openMinDateCalendar, setOpenMinDateCalendar] = useState(false);
    const [dateInfo, setDateInfo] = useState({
        hasWarning: false,
        message: ''
    });
    const startDateRef = useRef();
    const endDateRef = useRef();
    // hooks
    const aamEntryStep = useRef({
        aamMinDate: ui.aamMinDate,
        aamMaxDate: ui.aamMaxDate,
        aam: ui.aam
    });
    const updateStepsFilledIn_AAMS = useCallback(
        (val = false) => {
            dispatch(updateStepsFilledIn('AllAboutMeStep', val));
        },
        [dispatch]
    );

    const initialRenderRef = useRef(true);

    useEffect(() => {
        if (initialRenderRef.current) {
            initialRenderRef.current = false;
            updateStepsFilledIn_AAMS(true);
        }
    }, [updateStepsFilledIn_AAMS]);

    const renderTimeframeStatus = useCallback(() => {
        switch (timeframeStatus) {
            case TIMEFRAME_STATUS_ERROR: {
                // ERROR
                return (
                    <p className="input-error-msg">
                        <i className="icon-error"></i>
                        There are no entries available with this timeframe.
                        Nothing will be printed.
                    </p>
                );
            }
            case TIMEFRAME_STATUS_LOADING: {
                // LOADING
                return (
                    <p className="input-loading-msg">
                        <img src={loadingRing} alt="loading-ring" />
                        Checking dates...
                    </p>
                );
            }
            case TIMEFRAME_STATUS_SUCCESS: {
                // SUCCESS
                return (
                    <p className="input-success-msg">
                        <i className="icon-check-circle"></i> {entryCount}{' '}
                        entries found
                    </p>
                );
            }
            default: {
                return;
            }
        }
    }, [timeframeStatus, entryCount]);

    // handlers
    const onChangeAAM = useCallback(
        (option) => {
            if (
                option === ENTRY_ALL ||
                option === ENTRY_DO_NOT_PRINT ||
                option === ENTRY_MR
            ) {
                updateStepsFilledIn_AAMS(true);
            } else if (option === ENTRY_DATE) {
                updateStepsFilledIn_AAMS(false);
            }
            dispatch(
                updateUI({
                    aam: option
                })
            );
        },
        [dispatch, updateStepsFilledIn_AAMS]
    );

    const onMinDateChanged = useCallback(
        (date) => {
            const canGoNext =
                ui.aamMinDate &&
                ui.aamMaxDate &&
                moment(date) < moment(ui.aamMaxDate);
            dispatch(
                updateUI({
                    aamMinDate: date,
                    canGoNext
                })
            );
            setOpenMinDateCalendar(false);
        },
        [ui, dispatch]
    );

    const onMaxDateChanged = useCallback(
        (date) => {
            if (ui.aamMinDate < moment(date)) {
                dispatch(
                    updateUI({
                        aamMaxDate: date,
                        canGoNext: true
                    })
                );
                setOpenMaxDateCalendar(false);
            } else {
                setDateInfo({
                    hasWarning: true,
                    message: 'Select a date that is greater than the start date'
                });
            }
        },
        [ui.aamMinDate, dispatch]
    );

    const onOpenFirstCalendar = useCallback(() => {
        ui.aam === ENTRY_DATE && setOpenMinDateCalendar(true);
        openMaxDateCalendar && setOpenMaxDateCalendar(false);
    }, [ui.aam, openMaxDateCalendar]);
    const onOpenSecondCalendar = useCallback(() => {
        ui.aam === ENTRY_DATE && setOpenMaxDateCalendar(true);
        openMinDateCalendar && setOpenMinDateCalendar(false);
    }, [ui.aam, openMinDateCalendar]);

    useEffect(() => {
        if (ui.aam !== ENTRY_DATE) {
            setOpenMinDateCalendar(false);
            setOpenMaxDateCalendar(false);
        }
        dateInfo.hasWarning &&
            setTimeout(
                () => setDateInfo({ hasWarning: false, message: '' }),
                3000
            );
    }, [ui.aam, dateInfo.hasWarning]);

    useEffect(() => {
        if (display) {
            const uiEntry = {
                aamMinDate: ui.aamMinDate,
                aamMaxDate: ui.aamMaxDate,
                aam: ui.aam
            };
            if (
                JSON.stringify(aamEntryStep.current) !== JSON.stringify(uiEntry)
            ) {
                const canGoNext = Boolean(checkAAMSetup(ui));
                dispatch(updateUI(canGoNext));

                aamEntryStep.current = uiEntry;
            }
        }
        return () => {};
    }, [display, dispatch, ui]);

    const {
        aamMinDate: uiAAMMinDate,
        aamMaxDate: uiAAMMaxDate,
        aam: uiAAM
    } = ui;
    useEffect(() => {
        if (!display) {
            return;
        }
        if (!uiAAM) {
            dispatch(
                updateUI({
                    canGoNext: false
                })
            );
        }
        if (uiAAM === ENTRY_DATE) {
            if (uiAAMMinDate && uiAAMMaxDate && uiAAMMinDate < uiAAMMaxDate) {
                const filters = {
                    entry_type: 'QA',
                    entry_date__gte: convertUTCTime(uiAAMMinDate)
                        .startOf('day')
                        .format(),
                    entry_date__lte: convertUTCTime(uiAAMMaxDate)
                        .endOf('day')
                        .format()
                };

                setTimeframeStatus(TIMEFRAME_STATUS_LOADING);
                dispatch(EntryActions.fetchEntriesWithOptions(filters)).then(
                    (response) => {
                        if (response.payload.status === 200) {
                            const { meta: { total_count = 0 } = {} } =
                                response.payload.data || {};

                            if (total_count > 0) {
                                dispatch(updateUI({ canGoNext: true }));
                                updateStepsFilledIn_AAMS(true);
                                setTimeframeStatus(TIMEFRAME_STATUS_SUCCESS);
                                setEntryCount(total_count);
                            } else {
                                dispatch(updateUI({ canGoNext: false }));
                                setTimeframeStatus(TIMEFRAME_STATUS_ERROR);
                            }
                        }
                    }
                );
            }
        } else if (uiAAM) {
            dispatch(
                updateUI({
                    canGoNext: true
                })
            );
            setTimeframeStatus(TIMEFRAME_STATUS_NONE);
        }
    }, [
        dispatch,
        display,
        uiAAM,
        uiAAMMaxDate,
        uiAAMMinDate,
        updateStepsFilledIn_AAMS
    ]);

    return (
        <div
            className={`new-book-step-4 new-book-step ${
                display ? 'active-form' : ''
            }`}>
            <div className="section-title">All About Me</div>
            <div className="new-book-step-form">
                <p>
                    Please choose how you would like your All About Me questions
                    and answers included in your exported book.
                </p>
                <form className="all-about-me-options">
                    <div className="form-group">
                        <div className="radio-item">
                            <input
                                onClick={() => onChangeAAM(ENTRY_MR)}
                                onChange={() => {}}
                                className="radio"
                                type="radio"
                                id="recentAnswers"
                                name="all-about-me-options"
                                checked={ui.aam === ENTRY_MR}
                            />
                            <label
                                onClick={() => onChangeAAM(ENTRY_MR)}
                                htmlFor="recentAnswers">
                                Print most recent answers
                            </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="radio-item">
                            <input
                                onClick={() => onChangeAAM(ENTRY_ALL)}
                                onChange={() => {}}
                                className="radio"
                                type="radio"
                                id="allAnswers"
                                name="all-about-me-options"
                                checked={ui.aam === ENTRY_ALL}
                            />
                            <label
                                onClick={() => onChangeAAM(ENTRY_ALL)}
                                htmlFor="allAnswers">
                                Print all answers
                            </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="radio-item">
                            <input
                                onClick={() => onChangeAAM(ENTRY_DATE)}
                                onChange={() => {}}
                                className="radio"
                                type="radio"
                                id="date"
                                name="all-about-me-options"
                                checked={ui.aam === ENTRY_DATE}
                            />
                            <label
                                onClick={() => onChangeAAM(ENTRY_DATE)}
                                htmlFor="date">
                                Print a specific timeframe
                            </label>
                        </div>
                    </div>
                    <div className="form-group mt-4">
                        <div className="form-group__row">
                            <div className="form-group__item pb-0">
                                <label
                                    className="date-time-label"
                                    htmlFor="date-picker-time-from">
                                    From
                                </label>
                                <div className="react-datepicker-wrapper">
                                    <div
                                        className="react-datepicker__input-container"
                                        onClick={() => onOpenFirstCalendar()}>
                                        <input
                                            disabled={ui.aam !== ENTRY_DATE}
                                            type="text"
                                            placeholder="March 25, 2020"
                                            className={`new-book-input ${
                                                timeframeStatus ===
                                                TIMEFRAME_STATUS_ERROR
                                                    ? 'new-book-input-error'
                                                    : ''
                                            }`}
                                            value={moment(
                                                ui.aamMinDate || ''
                                            ).format('MMMM D, yyyy')}
                                        />
                                    </div>
                                </div>
                                {openMinDateCalendar && (
                                    <div ref={startDateRef}>
                                        <Calendar
                                            minDate={new Date('2010')}
                                            onChange={onMinDateChanged}
                                            value={ui.aamMinDate}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="form-group__item pb-0">
                                <label
                                    className="date-time-label"
                                    htmlFor="date-picker-time-to">
                                    To
                                </label>
                                <div className="react-datepicker-wrapper">
                                    <div
                                        className="react-datepicker__input-container"
                                        onClick={() => onOpenSecondCalendar()}>
                                        <input
                                            disabled={ui.aam !== ENTRY_DATE}
                                            type="text"
                                            placeholder="March 25, 2020"
                                            className={`new-book-input ${
                                                timeframeStatus ===
                                                TIMEFRAME_STATUS_ERROR
                                                    ? 'new-book-input-error'
                                                    : ''
                                            }`}
                                            value={moment(
                                                ui.aamMaxDate || ''
                                            ).format('MMMM D, yyyy')}
                                        />
                                    </div>
                                </div>
                                {openMaxDateCalendar && (
                                    <div ref={endDateRef}>
                                        <Calendar
                                            minDate={new Date('2010')}
                                            onChange={onMaxDateChanged}
                                            value={ui.aamMaxDate}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {renderTimeframeStatus()}
                    <div className="form-group">
                        <div className="radio-item">
                            <input
                                onClick={() => onChangeAAM(ENTRY_DO_NOT_PRINT)}
                                onChange={() => {}}
                                className="radio"
                                type="radio"
                                id="noAnswers"
                                name="all-about-me-options"
                                checked={ui.aam === ENTRY_DO_NOT_PRINT}
                            />
                            <label
                                onClick={() => onChangeAAM(ENTRY_DO_NOT_PRINT)}
                                htmlFor="noAnswers">
                                Do not print any All About Me Questions
                            </label>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    ui: getUI
});
export default connect(state)(AllAboutMeStep);
