import React, { useCallback } from 'react';
// Actions
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getUI, getBookById } from 'redux/selectors';

// Components
import Dialog from '@material-ui/core/Dialog';
import withBook from 'common/utils/components/withBook';

// images
import deleteImage from 'assets/images/banners/banner-delete.png';

import BookOrderDialog from 'pages/Dialogs/BookOrderDialog';
import { updateUI } from 'redux/actions/ui';

import * as BookShippingAddressActions from 'redux/actions/bookshippingaddress';

const DeleteShippingAddressDialog = ({
    close,
    match,
    ui,
    dispatch,
    open,
    book
}) => {
    const { address } = match?.params;
    const _close = useCallback(() => {
        dispatch(
            updateUI({
                dialogs: [withBook(BookOrderDialog, book.id)]
            })
        );
    }, [book, dispatch]);

    const _done = useCallback(
        (e) => {
            dispatch(
                BookShippingAddressActions.update({
                    ...address,
                    hidden: true
                })
            ).then((response) => {
                if (response?.payload?.data) {
                    dispatch(
                        updateUI({
                            deleteShippingAddress: address
                        })
                    );
                    _close();
                }
            });
        },
        [_close, address, dispatch]
    );

    return (
        <Dialog
            fullWidth={true}
            open={open}
            onClose={_close}
            aria-labelledby="form-dialog-title">
            <div
                id="delete-modal"
                className="modal-box modal-box--delete modal-box--500">
                <div className="delete-modal">
                    <div className="delete-modal__img">
                        <img
                            src={deleteImage}
                            alt="delete"
                            className="img-fluid"
                        />
                    </div>
                    <div className="delete-modal__content">
                        <div className="delete-modal__title">
                            Delete Address
                        </div>
                        <div className="delete-modal__subtitle">
                            Are you sure you want to delete this address?
                        </div>
                    </div>
                    <div className="delete-modal__actions">
                        <button className="btn btn-outline" onClick={_close}>
                            Cancel
                        </button>
                        <button className="btn btn-delete" onClick={_done}>
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
const state = createStructuredSelector({
    book: getBookById,
    ui: getUI
});
export default connect(state)(DeleteShippingAddressDialog);
