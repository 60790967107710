
import {
    createSelector
} from 'reselect';


export const InstagramSelector = (state) => state.instagram;

export const getInstagramState = createSelector(
    [InstagramSelector],
    (instagram) => instagram
);

export const getInstagramAccessToken = createSelector(
    [InstagramSelector],
    (instagram) => instagram.instagram_access_token
);

export const getInstagramAuthorizationUrl = createSelector(
    [InstagramSelector],
    (instagram) => instagram.instagram_authorization_url
);

export const getInstagramProfile = createSelector(
    [InstagramSelector],
    (instagram) => instagram.profile
);

export const getInstagramMedia = createSelector(
    [InstagramSelector],
    (instagram) => instagram.instagram_media
);

export const getInstagramMediaPaging = createSelector(
    [InstagramSelector],
    (instagram) => instagram.paging
);