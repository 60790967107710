import { DEFAULT_MODE } from 'common/constant';
import _ from 'common/utils/arrayUtils';
import {
    ACTION_UI_UPDATE,
    ACTION_USER_LOGOUT,
    UPDATE_STEPS_FILLED_IN
} from 'redux/actions/actionTypes';

const initState = {
    bulkMode: false,
    summaryView: true,
    viewWidth: DEFAULT_MODE,
    summaryViewWithEntry: {},
    editEntry: {},
    hideEntry: {},
    notifications: {},
    hasNewComment: false,
    selectedContacts: [],
    errors: {},
    dialogs: [],
    donationView: false,
    /**
     * {
     *  bookId: Dialog
     * }
     */
    bookPreviewDialogs: {},
    /**
     * { bookId: true // show else hide}
     */
    bookPreviewDialogShow: {},
    /**
     * We changed the workflow of social connect
     * - Open new window to authenticate social app
     * - Call parent's function from popup window
     * - From parent's function, call dispatch and update this value
     * - In App.JS, will handle this value changes and opens social media content modal
     *
     * Relevant Codes
     * - social-connect-utils.js => socialConnect
     * - InstagramContent/FacebookContent/etc => call above function
     * - App.js useDeepCompareEffect handler for ui.socialConnect change
     * - App.js check location change and get query object and close popup window
     */
    socialConnect: {},
    filterDrawerOpen: false,
    sidebarDrawerOpen: false,
    newEntry: {},
    stepsFilledIn: {
        CustomIntroductionPageStep: true, //optional
        BackCoverStep: true, //optional
        SpineStep: false,
        SubTitleStep: false,
        FrontCoverStep: false,
        PhotosOptionsStep: false,
        ColorOptionsStep: false,
        AllAboutMeStep: false,
        MediaOptionsStep: false,
        EntriesStep: false,
        JournalSelectStep: false,
        ExportFormatStep: false
    }
};
const uiReducer = (state = initState, action) => {
    switch (action.type) {
        case ACTION_UI_UPDATE: {
            const payload = action.payload;

            // while we make API request, user can update UI data on web
            // so once user sets data before API request is completed
            // then ignore that API response
            if (payload.isSetIfNull) {
                Object.keys(payload).forEach((key) => {
                    if (state[key] && key !== 'isSetIfNull') {
                        payload[key] = state[key];
                    }
                });
            }
            return action.replace
                ? {
                      ...state,
                      ...payload
                  }
                : _.mergeDeep({}, state, payload);
        }
        case UPDATE_STEPS_FILLED_IN: {
            const steps = state.stepsFilledIn;
            steps[action.payload.stepKey] = action.payload.value;
            return {
                ...state,
                stepsFilledIn: steps
            };
        }

        case ACTION_USER_LOGOUT: {
            return {
                ...initState
            };
        }
        default: {
            return {
                ...state
            };
        }
    }
};

export default uiReducer;
