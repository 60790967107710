import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import EntryEdit from 'pages/Entry/EntryEdit';
import EntryView from 'pages/Entry/EntryView';

import EntryCreate from './EntryCreate';
import { createStructuredSelector } from 'reselect';
import { getUI } from 'redux/selectors';
// import { MAX_MOBILE_WIDTH } from 'common/constant';
import iconAngleLeft from 'assets/images/icons_svg/icon_angle-left.svg';
import { URLS } from 'common/urls';

const Entry = ({ ui, entry, ...props }) => {
    const location = useLocation();
    const isEdit = location.pathname.indexOf('edit') > -1;
    const isCreateEntry = location.pathname.indexOf('create-entry') > -1;
    // const [isMobile, setIsMobile] = useState(false);

    // const onMobile = useCallback(() => {
    //     if (document.body.clientWidth < MAX_MOBILE_WIDTH) {
    //         setIsMobile(true);
    //     } else {
    //         setIsMobile(false);
    //     }
    // }, []);

    // useEffect(() => {
    //     onMobile();
    // });

    // window.onresize = () => {
    //     onMobile();
    // };
    const localAndOriginEntryIsSame = useCallback((id) => {
        const content = JSON.parse(
            localStorage.getItem(`original-entry-${id}`)
        );
        const title = JSON.parse(
            localStorage.getItem(`original-entry-title-${id}`)
        );
        if (
            title ===
                JSON.parse(localStorage.getItem(`entry-draft-title-${id}`)) &&
            content === JSON.parse(localStorage.getItem(`entry-draft-${id}`))
        ) {
            return true;
        }
        return false;
    }, []);

    const deleteLocalDraftsIfNoChanges = useCallback(() => {
        const entryId = localStorage.getItem('original-entry-id');

        if (localAndOriginEntryIsSame(entryId)) {
            localStorage.removeItem(`entry-draft-${entryId}`);
            localStorage.removeItem(`entry-draft-title-${entryId}`);
            localStorage.removeItem(`original-entry-${entryId}`);
            localStorage.removeItem(`original-entry-title-${entryId}`);
            localStorage.removeItem('original-entry-id');
        } else {
            localStorage.removeItem(`original-entry-${entryId}`);
            localStorage.removeItem(`original-entry-title-${entryId}`);
            localStorage.removeItem('original-entry-id');
        }
    }, [localAndOriginEntryIsSame]);

    return (
        <div>
            <main
                id="main"
                style={{ marginLeft: 0, height: '100vh' }}
                className="app-main">
                <div
                    className={`page-container page-container--single ${ui.viewWidth}`}>
                    <div className="back-bar">
                        <button
                            // className="wrapped-settings-list-btn"
                            className="btn btn-clear"
                            onClick={() => {
                                // goBack()
                                deleteLocalDraftsIfNoChanges();
                                window.location.href = URLS.TIMELINE;
                            }}>
                            <img
                                className="wrapped-settings__angle-left"
                                src={iconAngleLeft}
                                alt=""
                            />
                            Back
                        </button>
                    </div>
                    {isCreateEntry ? (
                        <EntryCreate {...props} />
                    ) : (
                        <>
                            {isEdit ? (
                                <EntryEdit {...props} />
                            ) : (
                                <EntryView {...props} />
                            )}
                        </>
                    )}
                </div>
            </main>
        </div>
    );
};

const state = createStructuredSelector({
    ui: getUI
});
export default connect(state)(Entry);
