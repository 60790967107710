// @ts-nocheck
import React, { useEffect, useState, useCallback, useRef } from 'react';
import SettingList from 'pages/Settings/SettingList';
// Tab Panes
import withFreeAccountCheck from 'common/utils/components/withFreeAccountCheck';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
    getSettingsPageActiveTab,
    getUI,
    isMobileSettingBarOpen
} from 'redux/selectors';
import { useLocation, useHistory } from 'react-router-dom';
import {
    setSettingsPageByPath,
    setSettgsSidebarMobileStatus
} from 'redux/actions/settings_page';
import { URLS } from 'common/urls';
// modals
import OnboardingFirstDialog from 'components/OnboardingFirstDialog';

// utils
import { closeDialog, openDialog } from 'common/utils/dialog-utils';

import { updateUI } from 'redux/actions/ui';

import 'intro.js/introjs.css';
import { Steps } from 'intro.js-react';
import * as UserActions from 'redux/actions/user';

import { createStructuredSelector } from 'reselect';

import { getUser } from 'redux/selectors';

import { isOldUser } from 'common/utils/newUser';

import iconAngleLeft from 'assets/images/icons_svg/icon_angle-left.svg';

const WrappedSettingList = withFreeAccountCheck(SettingList, false);
const Settings = ({ user, ui, openSettingsSidebar }) => {
    const [continueFlag, setContinueFlag] = useState(false);
    const [displayOnboarding, setDisplayOnboarding] = useState(false);
    const stepsRef = useRef(null);
    const steps = [
        {
            title: '',
            intro: ''
        },
        {
            title: 'Profile',
            element: '#profile-tab',
            intro:
                'Be sure to complete your profile. This information is used for all entries, all journals, and published books.',
            position: 'right'
        },
        {
            title: 'Reminders',
            element: '#reminders-tab',
            intro:
                'Sometimes life happens and you forget to write in your journal. Reminders are subtle nudges to help you get back on track.',
            position: 'right'
        },
        {
            title: 'Membership',
            element: '#account-tab',
            intro:
                'All JRNL members get 30-days access to a Power JRNLer account. Upgrade before the trial ends for a 20% discount.',
            position: 'right'
        },
        {
            title: 'Notifications',
            element: '#notifications-tab',
            intro:
                'Manage how JRNL communicates with you and how you’re notified when other users comment on shared entries or journals.',
            position: 'right'
        },
        {
            title: '',
            intro: ''
        }
    ];
    const [initialStep, setInitialStep] = useState(0);
    const [stepsEnabled, setStepsEnabled] = useState(false);
    const [backStatus, setBackStatus] = useState(0);

    const history = useHistory();
    const CurrentTab = useSelector(getSettingsPageActiveTab);
    const dispatch = useDispatch();
    const location = useLocation();
    const pathName = React.useMemo(() => location.pathname, [location]);
    useEffect(() => {
        const {
            PROFILE,
            SOCIALCONNECT,
            SECURITY,
            REMINDER,
            EMAILJRNL,
            BILLING,
            THEMES,
            ACCOUNT,
            NOTIFICATION,
            INVITATIONS,
            IMPORTEXPORT
        } = URLS.SETTINGS;
        const paths = [
            PROFILE,
            SOCIALCONNECT,
            SECURITY,
            REMINDER,
            EMAILJRNL,
            BILLING,
            THEMES,
            ACCOUNT,
            NOTIFICATION,
            INVITATIONS,
            IMPORTEXPORT
        ];
        if (!paths.includes(pathName)) {
            history.push(PROFILE);
        } else {
            if (
                window.location.href.includes('localhost') &&
                !window.location.href.includes('localhost:') &&
                pathName.includes(ACCOUNT)
            ) {
                history.push(PROFILE);
            } else {
                dispatch(setSettingsPageByPath(pathName));
            }
        }
        //eslint-disable-next-line
    }, [dispatch, pathName]);

    const onContinue = React.useCallback(
        (checked) => {
            setStepsEnabled(true);
            setInitialStep(1);
            setContinueFlag(true);
            // stepsRef.current.introJs.goToStep(1).start();
            dispatch(
                UserActions.update({
                    has_seen_welcome_web: checked
                })
            );
            closeDialog();
        },
        [dispatch]
    );

    useEffect(() => {
        if (displayOnboarding && ui.bigStep === 1) {
            setContinueFlag(true);
            setStepsEnabled(true);
            setInitialStep(4);
        }
    }, [ui.bigStep, displayOnboarding]);

    useEffect(() => {
        if (ui.bigStep) {
            return;
        }
        if (displayOnboarding) {
            dispatch(
                updateUI({
                    onBoardingMoreMenu: false,
                    onBoardingAddMenu: false
                })
            );
            openDialog(OnboardingFirstDialog, {
                title: 'Welcome to JRNL!',
                text:
                    'You’ve made a big first step to building your legacy. Please take a moment to familiarize yourself with the JRNL platform.',
                onContinue
            });
        }
    }, [onContinue, displayOnboarding, dispatch, ui.bigStep]);

    const onExit = useCallback(() => {
        setStepsEnabled(false);
        if (backStatus === 2) {
            localStorage.setItem('skipOnboarding', '1');
            dispatch(
                updateUI({
                    onBoarding: false,
                    onBoardingEnd: true
                })
            );
        }
    }, [dispatch, backStatus]);

    const options = {
        hidePrev: false,
        hideNext: false,
        prevLabel: 'Back',
        nextLabel: 'Next',
        showBullets: false,
        doneLabel: 'Next',
        exitOnOverlayClick: false
    };

    const onBeforeChange = useCallback(
        (nextStepIndex) => {
            switch (nextStepIndex) {
                case 0:
                    setBackStatus(1);
                    setStepsEnabled(false);
                    dispatch(
                        updateUI({
                            onBoardingMoreMenu: false,
                            onBoardingAddMenu: false
                        })
                    );
                    openDialog(OnboardingFirstDialog, {
                        title: 'Welcome to JRNL!',
                        text:
                            'You’ve made a big first step to building your legacy. Please take a moment to familiarize yourself with the JRNL platform.',
                        onContinue
                    });

                    stepsRef.current.introJs._currentStep = 1;
                    return false;
                case 5:
                    setBackStatus(3);
                    dispatch(
                        updateUI({
                            onBoarding: true,
                            onBoardingEnd: false,
                            filterDrawerOpen: true
                        })
                    );
                    history.push(URLS.TIMELINE);
                    return false;
                default:
                    setBackStatus(2);
                    break;
            }
        },
        [dispatch, onContinue, history]
    );

    useEffect(() => {
        if (isOldUser(user)) {
            return;
        }
        if (localStorage.getItem('skipOnboarding') === '1') {
            return;
        }

        setDisplayOnboarding(Boolean(!user.has_seen_welcome_web));
    }, [user]);

    const showSettingsBarMobile = useCallback(() => {
        dispatch(setSettgsSidebarMobileStatus(true));
    }, [dispatch]);

    return (
        <div>
            {continueFlag && displayOnboarding && (
                <Steps
                    ref={stepsRef}
                    enabled={stepsEnabled}
                    initialStep={initialStep}
                    steps={steps}
                    onExit={onExit}
                    options={options}
                    onBeforeChange={onBeforeChange}
                />
            )}
            <main id="main" style={{ marginLeft: 0 }} className="app-main">
                <section className="settings-section">
                    <div className="page-container page-container--800">
                        <div className="page">
                            <div className="settings-card">
                                <WrappedSettingList />

                                <div
                                    style={{
                                        width: '100%'
                                    }}
                                    className={
                                        openSettingsSidebar
                                            ? 'wrapped-settings-list-mobile-hide'
                                            : 'full-width-mobile'
                                    }>
                                    <div className="back-bar back-bar--settings">
                                        <button
                                            className="btn btn-clear"
                                            // style={{border: "none"}}
                                            onClick={() => {
                                                showSettingsBarMobile();
                                            }}>
                                            <img
                                                className="wrapped-settings__angle-left"
                                                src={iconAngleLeft}
                                                alt=""
                                            />
                                            Settings
                                        </button>
                                    </div>

                                    <div
                                        className="tab-content settings-content"
                                        id="settingsContent">
                                        {CurrentTab && <CurrentTab />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
};

const state = createStructuredSelector({
    user: getUser,
    ui: getUI,
    openSettingsSidebar: isMobileSettingBarOpen
});

export default connect(state)(Settings);
