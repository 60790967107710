export const getInfoForCircle = (coordinates, ratio) => {
    let cx =
        coordinates[3] +
        Math.abs(parseInt(coordinates[1]) - parseInt(coordinates[3])) / 2;
    cx = cx * ratio.wRatio;
    let cy =
        coordinates[0] +
        Math.abs(parseInt(coordinates[0]) - parseInt(coordinates[2])) / 2;
    cy = cy * ratio.hRatio;

    let radius =
        Math.abs(parseInt(coordinates[1]) - parseInt(coordinates[3])) / 2;
    radius = radius * Math.max(ratio.wRatio, ratio.hRatio);

    return {
        cx,
        cy,
        radius
    };
};
