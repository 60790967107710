import React, {
    Fragment,
    useCallback,
    useEffect,
    useState,
    useRef
} from 'react';
import moment from 'moment';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useLocation } from 'react-router-dom';

// core components
import { DropDown } from 'components';

// constants
import { COMMENT_TYPE } from 'common/constant';

// selectors
import {
    getUser,
    getUI,
    getCommentsForEntry,
    getMyPublicUser,
    getBlockedUsers
} from 'redux/selectors';

// images
import defaultUser from 'assets/images/default-user.jpg';
import { updateUI } from 'redux/actions/ui';
import withCommentInvitations from 'common/utils/components/withCommentInvitations';
import CommentShareUserDialog from 'pages/Dialogs/CommentShareUsersDialog';
import extractIdFromResourceUri from 'common/utils/extractIdFromResourceUri';
import { getSharedEntryUser } from 'redux/actions/publicuser';

// Icons
import iconCaretDown from 'assets/images/icons_svg/icon_caret-down.svg';
import iconCommentPrivateColor from 'assets/images/icons_svg/icon_comment-private-color.svg';
import iconComment1on1Color from 'assets/images/icons_svg/icon_comment-1on1-color.svg';
import iconCommentGroupColor from 'assets/images/icons_svg/icon_comment-group-color.svg';
import iconEllipsisVert from 'assets/images/icons_svg/icon_ellipsis-vert.svg';

// constants
import { URLS } from 'common/urls';
import queryString from 'query-string';
import { checkUserSame } from 'common/utils/resourceUtils';
import { checkResponseSuccess } from 'common/utils/responseUtils';
import { entryShared } from 'common/utils/entry_utils';

const ACTIVE_COMMENT_ITEM = 'active-comment-item';

moment.updateLocale('en', {
    relativeTime: {
        future: '%s',
        past: '%s',
        s: '1m',
        ss: '1m',
        m: '%dm',
        mm: '%dm',
        h: '%dh',
        hh: '%dh',
        d: '%dd',
        dd: '%dd',
        M: '%dm',
        MM: '%dm',
        y: '%dy',
        yy: '%dy'
    }
});

const EntryComment = ({
    comments,
    invitations,
    onPost,
    onEdit,
    onDelete,
    entry,
    dispatch,
    user,
    onSort,
    ui
}) => {
    const [commentSharedUsers, setCommentSharedUsers] = useState([]);
    const [commentType, setcommentType] = useState({
        type: entryShared(entry) ? COMMENT_TYPE.GROUP : COMMENT_TYPE.PERSONAL,
        user: null
    });
    const lastCommentRef = useRef();
    const [commentContent, setCommentContent] = useState('');
    const [editCommentContent, setEditCommentContent] = useState({
        id: null,
        content: ''
    });
    const [commentsReplyShow, setCommentsReplyShow] = useState({});
    const [commentsReplyContent, setCommentsReplyContent] = useState({});
    const [sharedUser, setSharedUser] = useState({
        avatar_image_url: null,
        public_display_name: ''
    });
    const publicMe = useSelector(getMyPublicUser);
    const me = useSelector(getUser);
    const blockedUsers = useSelector(getBlockedUsers);
    const [isScrolled, setIsScrolled] = useState(false);
    const location = useLocation();

    const clickInvitaiton = useCallback((user) => {
        setcommentType({
            type: COMMENT_TYPE.ONE_ON_ONE,
            user
        });
    }, []);

    const onUpdateSharedUsers = useCallback((users) => {
        setCommentSharedUsers(users.map((user) => user.id));
    }, []);

    const isEntryShared = React.useMemo(() => entryShared(entry), [entry]);

    const changeType = useCallback(
        (type) => {
            setcommentType((prevCommentType) => {
                return {
                    ...prevCommentType,
                    type
                };
            });

            if (type === COMMENT_TYPE.GROUP) {
                dispatch(
                    updateUI({
                        dialogs: [
                            ...ui.dialogs,
                            withCommentInvitations(
                                CommentShareUserDialog,
                                invitations,
                                undefined,
                                onUpdateSharedUsers
                            )
                        ]
                    })
                );
            }
        },
        [onUpdateSharedUsers, dispatch, invitations, ui.dialogs]
    );

    const postComment = useCallback(() => {
        const comment = {
            content: commentContent,
            entry: entry.id,
            display_channel: commentType.type,
            shared_users: commentSharedUsers,
            draftId: Date.now()
        };

        if (commentType.type === COMMENT_TYPE.ONE_ON_ONE) {
            comment.one_on_one_user = commentType.user.id;
        }

        if (comment.content && comment.content !== '') {
            if (onPost) {
                onPost(comment);

                setCommentContent('');
                setCommentSharedUsers([]);
            }
        }
    }, [onPost, commentContent, entry, commentType, commentSharedUsers]);

    const editComment = useCallback((comment) => {
        setEditCommentContent({ ...comment });
    }, []);

    const deleteComment = useCallback(
        (comment) => {
            if (onDelete) {
                onDelete({ ...comment });
            }
        },
        [onDelete]
    );

    const updateEditComment = useCallback((e) => {
        const newValue = e.target.value;
        setEditCommentContent((prev) => {
            return {
                ...prev,
                content: newValue
            };
        });
    }, []);

    const saveEditComment = useCallback(() => {
        if (onEdit) {
            onEdit({ ...editCommentContent });
        }
        setEditCommentContent({ id: null, content: '' });
    }, [editCommentContent, onEdit]);

    const updateReplyContent = useCallback((commentId, txt) => {
        setCommentsReplyContent((prev) => ({
            ...prev,
            [commentId]: txt
        }));
    }, []);

    const showCommentsReply = useCallback((commentId, show = true) => {
        setCommentsReplyShow((prev) => ({ ...prev, [commentId]: show }));
    }, []);

    const postReply = useCallback(
        (commentId) => {
            const content = commentsReplyContent[commentId] || '';
            const originalComment = comments.find(
                (item) => item.id === commentId
            );

            if (originalComment && content !== '') {
                const replyComment = {
                    content,
                    entry: entry.id,
                    display_channel: originalComment?.display_channel,
                    shared_users: originalComment?.commentSharedUsers,
                    draftId: Date.now(),
                    parent_comment: commentId
                };

                if (onPost) {
                    onPost(replyComment);

                    showCommentsReply(commentId, false);
                    updateReplyContent(commentId, '');
                }
            }
        },
        [
            comments,
            commentsReplyContent,
            entry.id,
            onPost,
            showCommentsReply,
            updateReplyContent
        ]
    );
    const sortComments = useCallback(
        (isOldest) => {
            if (onSort) {
                onSort(isOldest);
            }
        },
        [onSort]
    );
    const handleCommentsSort = useCallback(
        (e) => {
            sortComments(Boolean(Number(e.target.dataset.sort)));
        },
        [sortComments]
    );

    const getImgType = useCallback((comment) => {
        return comment.display_channel === COMMENT_TYPE.GROUP
            ? iconCommentGroupColor
            : comment.display_channel === COMMENT_TYPE.ONE_ON_ONE
            ? iconComment1on1Color
            : iconCommentPrivateColor;
    }, []);
    const renderCommentItems = useCallback(
        (parent_comment = null) => {
            const repliedComments = comments.filter((item) => {
                const itemId =
                    extractIdFromResourceUri(
                        'timeline_comment',
                        item.parent_comment
                    ) || null;
                return (itemId || null) === (parseInt(parent_comment) || null);
            });

            return repliedComments.map((comment) => {
                const created = moment.utc(comment?.created);
                const {
                    id,
                    display_channel,
                    content,
                    user: commentUser,
                    draftId
                } = comment;
                const isCommentCreated = !!id;
                const replyShow = commentsReplyShow[comment.id];
                return (
                    <Fragment key={id || draftId}>
                        <li className={isCommentCreated ? '' : 'disabled'}>
                            <img
                                src={
                                    commentUser?.avatar_image_url || defaultUser
                                }
                                alt=""
                                className="img-fluid comment-avatar"
                            />
                            <div className="posted-comment-content">
                                <div className="posted-comment-header">
                                    <div className="posted-comment-user truncate">
                                        <span>
                                            {commentUser?.public_display_name}
                                        </span>
                                    </div>
                                    <div className="posted-comment-time">
                                        <img
                                            src={getImgType(comment)}
                                            alt="comment icon"
                                        />
                                        {created.local().fromNow()}
                                    </div>
                                    <div className="spacer"></div>
                                    <div className="dropdown show comments-dropdown">
                                        <DropDown
                                            classes={{
                                                dropdown_list:
                                                    'dropdown-entry-list'
                                            }}
                                            className=""
                                            anchor={
                                                <img
                                                    src={iconEllipsisVert}
                                                    className="btn-icon btn-icon--sm btn-icon--4"
                                                    alt="ellipsis"
                                                />
                                            }>
                                            <li
                                                onClick={() =>
                                                    editComment(comment)
                                                }>
                                                Edit Comment
                                            </li>
                                            <li
                                                onClick={() =>
                                                    deleteComment(comment)
                                                }>
                                                Delete Comment
                                            </li>
                                        </DropDown>
                                    </div>
                                </div>
                                {editCommentContent &&
                                comment?.id &&
                                editCommentContent?.id === comment?.id ? (
                                    <div className="posted-comments-text edit-posted-comments">
                                        <textarea
                                            value={
                                                editCommentContent?.content ||
                                                ''
                                            }
                                            placeholder="comment..."
                                            onChange={
                                                updateEditComment
                                            }></textarea>
                                        <button
                                            onClick={saveEditComment}
                                            className="btn btn-save-comment btn-gradient">
                                            Save Comment
                                        </button>
                                    </div>
                                ) : (
                                    <div
                                        className={`posted-comments-text ${
                                            display_channel ===
                                            COMMENT_TYPE.GROUP
                                                ? 'open-comment-bg'
                                                : ''
                                        }`}
                                        dangerouslySetInnerHTML={{
                                            __html: content
                                        }}
                                    />
                                )}
                                <div className="comment-footer">
                                    <button
                                        onClick={() =>
                                            showCommentsReply(comment.id, true)
                                        }
                                        className="btn-comment-reply">
                                        Reply
                                    </button>
                                </div>
                            </div>
                        </li>
                        <ul className="replied-comments-list">
                            {comment.id && renderCommentItems(comment.id)}
                            {replyShow && (
                                <li className="focused-comment-bg">
                                    <img
                                        src={
                                            user?.avatar_image_url ||
                                            defaultUser
                                        }
                                        alt=""
                                        className="img-fluid comment-avatar"
                                    />
                                    <div className="reply-comment-content">
                                        <textarea
                                            onChange={(e) =>
                                                updateReplyContent(
                                                    comment.id,
                                                    e.target.value
                                                )
                                            }
                                            name=""
                                            id=""
                                            placeholder="Write a comment..."
                                            className="new-comment-textarea"></textarea>
                                        <div className="comment-reply-actions">
                                            <button
                                                className="btn btn-cancel"
                                                onClick={() =>
                                                    showCommentsReply(
                                                        comment.id,
                                                        false
                                                    )
                                                }>
                                                Cancel
                                            </button>
                                            <button
                                                className="btn btn-reply-comment"
                                                onClick={() =>
                                                    postReply(comment.id)
                                                }>
                                                <i className="fa fa-plus-circle"></i>
                                                Reply
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            )}
                        </ul>
                    </Fragment>
                );
            });
        },
        [
            getImgType,
            comments,
            deleteComment,
            editComment,
            editCommentContent,
            saveEditComment,
            updateEditComment,
            user,
            commentsReplyShow,
            showCommentsReply,
            updateReplyContent,
            postReply
        ]
    );
    const checkInvitationExists = useCallback(() => {
        if (me && publicMe) {
            return !(
                checkUserSame(me, entry.user) ||
                checkUserSame(publicMe, entry.user) ||
                blockedUsers?.includes(entry && entry.public_user)
            );
        }
    }, [entry, blockedUsers, me, publicMe]);
    useEffect(() => {
        if (checkInvitationExists) {
            dispatch(getSharedEntryUser(entry.user.resource_uri)).then(
                (response) => {
                    if (checkResponseSuccess(response.status)) {
                        setSharedUser(response.data);
                    }
                }
            );
        }
    }, [checkInvitationExists, dispatch, entry.user.resource_uri]);
    useEffect(() => {
        if (
            location.pathname.includes(URLS.ENTRY.ROOT) &&
            queryString.parse(location.search)?.showComments &&
            !entry.sortOldestComment &&
            !isScrolled
        ) {
            setIsScrolled(true);
            // @ts-ignore
            lastCommentRef.current.scrollIntoView();
        }
        //eslint-disable-next-line
    }, [location.pathname, entry.sortOldestComment]);
    const renderInvitationList = useCallback(() => {
        const userInvitations = invitations.filter(
            (invitation) => !!invitation.user
        );
        return userInvitations.map((invitation, index) => {
            const { user } = invitation;

            const className =
                commentType.type === COMMENT_TYPE.ONE_ON_ONE &&
                commentType.user?.id === user.id
                    ? ACTIVE_COMMENT_ITEM
                    : '';
            return (
                <li
                    key={index}
                    onClick={() => clickInvitaiton(user)}
                    className={className}>
                    <img
                        src={user.avatar_image_url}
                        alt=""
                        className="circle-image"
                    />
                    <div className="contact-name-comment truncate">
                        <span>{user.public_display_name}</span>
                    </div>
                </li>
            );
        });
    }, [invitations, commentType, clickInvitaiton]);
    const renderInvitation = useCallback(() => {
        return (
            <li onClick={() => clickInvitaiton(sharedUser)}>
                <img
                    src={sharedUser?.avatar_image_url || null}
                    alt=""
                    className="circle-image"
                />
                <div className="contact-name-comment truncate">
                    <span>{sharedUser?.public_display_name || ''}</span>
                </div>
            </li>
        );
    }, [clickInvitaiton, sharedUser]);

    const renderSelectedCommentType = useCallback(() => {
        switch (commentType.type) {
            case COMMENT_TYPE.GROUP:
                return (
                    <>
                        <img
                            src={iconCommentGroupColor}
                            className="comment-icon left"
                            alt="Group"
                        />
                        <span>Group</span>
                        <img
                            className="right caret"
                            src={iconCaretDown}
                            alt=""
                        />
                    </>
                );
            case COMMENT_TYPE.PERSONAL:
                return (
                    <>
                        <img
                            src={iconCommentPrivateColor}
                            className="comment-icon left"
                            alt="Private"
                        />
                        <span>Private</span>
                        <img
                            className="right caret"
                            src={iconCaretDown}
                            alt=""
                        />
                    </>
                );
            default:
                return (
                    <ul className="comment-contact-list">
                        <li>
                            <img
                                src={iconComment1on1Color}
                                className="comment-icon left"
                                alt="One on one"
                            />
                            <img
                                src={commentType.user?.avatar_image_url}
                                alt="avatar"
                                className="circle-image"
                            />
                            <div className="contact-name-comment truncate">
                                <span>
                                    {commentType.user?.public_display_name}
                                </span>
                            </div>
                        </li>
                    </ul>
                );
        }
    }, [commentType]);

    const renderNewCommentSection = useCallback(() => {
        return (
            <>
                <div className="new-comment">
                    <img
                        src={user?.avatar_image_url || defaultUser}
                        alt="avatar"
                        className="img-fluid comment-avatar"
                    />
                    <textarea
                        name=""
                        id=""
                        className="new-comment-textarea"
                        value={commentContent}
                        onChange={(e) => setCommentContent(e.target.value)}
                        placeholder="Write a comment..."
                    />
                </div>
                <div className="new-comment-actions">
                    <div className="dropdown show">
                        <DropDown
                            dropDownMenuProps={{
                                className:
                                    'dropdown-menu comment-dropdown-menu '
                            }}
                            classes={{
                                dropdown_list: 'dropdown-comment-list'
                            }}
                            className="dropdown-toggle btn btn-clear"
                            anchor={renderSelectedCommentType()}>
                            <li
                                onClick={() => changeType(COMMENT_TYPE.GROUP)}
                                className={
                                    commentType.type === COMMENT_TYPE.GROUP
                                        ? ACTIVE_COMMENT_ITEM
                                        : ''
                                }>
                                <div className="comment-point-header">
                                    <img
                                        src={iconCommentGroupColor}
                                        alt="Group comment"
                                    />
                                    <div className="comment-point-title">
                                        Group
                                    </div>
                                </div>
                                <div className="comment-point-text">
                                    Everyone with access to this post will be
                                    able to see your comment.
                                </div>
                            </li>
                            <li
                                onClick={() =>
                                    changeType(COMMENT_TYPE.PERSONAL)
                                }
                                className={
                                    commentType.type === COMMENT_TYPE.PERSONAL
                                        ? ACTIVE_COMMENT_ITEM
                                        : ''
                                }>
                                <div className="comment-point-header">
                                    <img
                                        src={iconCommentPrivateColor}
                                        alt="Private comment"
                                    />
                                    <div className="comment-point-title">
                                        Private
                                    </div>
                                </div>
                                <div className="comment-point-text">
                                    You will be the only one able to see your
                                    comment.
                                </div>
                            </li>
                            <li>
                                <div className="comment-point-header">
                                    <img
                                        src={iconComment1on1Color}
                                        alt="One on one comment"
                                    />
                                    <div className="comment-point-title">
                                        Send to...
                                    </div>
                                </div>
                                <div className="comment-point-text">
                                    Only the person you select will be able to
                                    see the comment.
                                </div>
                                <ul className="comment-contact-list">
                                    {checkInvitationExists()
                                        ? renderInvitation()
                                        : renderInvitationList()}
                                </ul>
                            </li>
                        </DropDown>
                    </div>
                    <button
                        onClick={postComment}
                        className="btn btn-post-comment btn-gradient">
                        Post
                    </button>
                </div>
            </>
        );
    }, [
        checkInvitationExists,
        renderInvitation,
        commentContent,
        postComment,
        renderInvitationList,
        renderSelectedCommentType,
        user,
        changeType,
        commentType.type
    ]);

    const renderCommentsHeader = useCallback(() => {
        return (
            <div className="comment-actions">
                <div>
                    {/* <button className="btn btn-load-more-comments">
                        <span className="icon-caret-down"></span>
                        Load more comments (<span className="number-comments">6</span>) */}
                    {/* </button> */}
                </div>
                <div className="dropdown comments-filter">
                    <DropDown
                        className="btn-comments-filter dropdown-toggle"
                        anchor={
                            <React.Fragment>
                                {entry.sortOldestComment
                                    ? 'Newest First'
                                    : 'Oldest First'}
                                <span className="fa fa-caret-down" />
                            </React.Fragment>
                        }
                        classes={{
                            dropdown_list: 'comments-filter-dropdown'
                        }}
                        dropDownMenuProps={{
                            className: 'comments-filter-dropdown'
                        }}>
                        <button
                            className="dropdown-item"
                            type="button"
                            data-sort={0}
                            onClick={handleCommentsSort}>
                            Oldest First
                        </button>
                        <button
                            className="dropdown-item"
                            type="button"
                            data-sort={1}
                            onClick={handleCommentsSort}>
                            Newest First
                        </button>
                    </DropDown>
                </div>
            </div>
        );
    }, [handleCommentsSort, entry.sortOldestComment]);

    React.useEffect(() => {
        setcommentType((prevCommentType) => {
            return {
                ...prevCommentType,
                type: isEntryShared ? COMMENT_TYPE.GROUP : COMMENT_TYPE.PERSONAL
            };
        });
    }, [isEntryShared]);

    return (
        <>
            <div className="posted-comments">
                {renderCommentsHeader()}
                <ul className="posted-comments-list">{renderCommentItems()}</ul>
                <div id="last_comment" ref={lastCommentRef} />
            </div>
            {renderNewCommentSection()}
        </>
    );
};

const state = createStructuredSelector({
    user: getUser,
    ui: getUI,
    comments: getCommentsForEntry
});
export default connect(state)(EntryComment);
