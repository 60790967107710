import axios from 'axios';

import {
    ENTRY_MEDIA_DETECT_FACE_URL,
    ENTRY_MEDIA_SEARCH_URL,
    SUCCESS_SUFFIX
} from 'common/constant';
import { URLS } from 'common/urls';
import buildV2Authorization from 'common/utils/buildV2Authorization';
import { UserSelector } from 'redux/selectors/UserSelector';
import { makeDispatch } from 'redux/utils';
import {
    CREATE_ENTRY_MEDIA,
    CREATE_ENTRY_MEDIA_PROGRESS,
    UPDATE_ENTRY_MEDIA,
    DELETE_ENTRY_MEDIA,
    UPDATE_IMAGE_PROGRESS,
    SET_MEDIA_TYPE,
    UNSTAGE_ENTRY_MEDIA,
    FILTER_ENTRY_MEDIA,
    RESET_FILTER_ENTRY_MEDIA,
    FETCH_ENTRY_MEDIA,
    SEARCH_ENTRY_MEDIA,
    FETCH_ENTRY_MEDIA_INDIVIDUAL,
    DETECT_FACES,
    CREATE_MEDIA_INVITATION
} from 'redux/actions/actionTypes';

export const updateImageProgress = (payload) => {
    return {
        type: UPDATE_IMAGE_PROGRESS,
        payload
    };
};

export const create = (data) => (dispatch, getState) => {
    const url = URLS.API.MEDIA.ROOT;
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);

    return dispatch({
        type: CREATE_ENTRY_MEDIA,
        payload: {
            request: {
                url,
                method: 'POST',
                data,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const update = (data, id) => (dispatch, getState) => {
    const url = `${URLS.API.MEDIA.ROOT}${id}/`;
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);

    return dispatch({
        type: UPDATE_ENTRY_MEDIA,
        payload: {
            request: {
                url,
                method: 'PATCH',
                data,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const createWithProgress = (data, file, dropzone, file_id) => (
    dispatch,
    getState
) => {
    const url = URLS.API.MEDIA.ROOT;
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);

    dispatch({
        type: CREATE_ENTRY_MEDIA_PROGRESS,
        payload: {
            data
        }
    });

    return axios
        .post(url, data, {
            headers: {
                Authorization: auth
            },
            onUploadProgress: (progress) => {
                const { loaded, total } = progress;
                const percentageProgress = Math.floor((loaded / total) * 100);
                file.upload.progress = percentageProgress;
                file.upload.bytesSent = loaded;
                dispatch(
                    updateImageProgress({
                        file_id,
                        progress: percentageProgress
                    })
                );
                dropzone.emit(
                    'uploadprogress',
                    file,
                    percentageProgress,
                    loaded
                );
            }
        })
        .then((response) => {
            if ((response && response.status) === 201) {
                dropzone.emit('success', file);
                dropzone.emit('complete', file);
                dispatch({
                    type: CREATE_ENTRY_MEDIA_PROGRESS + SUCCESS_SUFFIX,
                    payload: {
                        data: response.data
                    }
                });
            } else {
                dropzone.emit('error', file);
            }
        });
};

export const fetchAll = (pageUrl, params) => (dispatch, getState) => {
    const url = pageUrl ? pageUrl : URLS.API.MEDIA.ROOT;
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);
    return dispatch({
        type: FETCH_ENTRY_MEDIA,
        payload: {
            request: {
                url,
                method: 'GET',
                params,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const filter = (params) => (dispatch, getState) => {
    return makeDispatch({
        data: null,
        dispatch,
        method: 'GET',
        params,
        state: getState(),
        type: FILTER_ENTRY_MEDIA,
        url: URLS.API.MEDIA.ROOT
    });
};

export const reset = () => (dispatch) => {
    return dispatch({
        type: RESET_FILTER_ENTRY_MEDIA
    });
};

export const search = (query, entry_id) => (dispatch, getState) => {
    const params = entry_id ? { q: query, entry: entry_id } : { q: query };
    return makeDispatch({
        data: null,
        dispatch,
        method: 'GET',
        params,
        state: getState(),
        type: SEARCH_ENTRY_MEDIA,
        url: ENTRY_MEDIA_SEARCH_URL
    });
};

export const setMediaType = (data) => (dispatch) => {
    return dispatch({
        type: SET_MEDIA_TYPE,
        data
    });
};

export const unstageEntryMedia = () => (dispatch) => {
    return dispatch({
        type: UNSTAGE_ENTRY_MEDIA,
        payload: {
            data: undefined
        }
    });
};

export const fetchIndividual = (objId) => (dispatch, getState) => {
    return makeDispatch({
        data: null,
        dispatch,
        method: 'GET',
        params: null,
        state: getState(),
        type: FETCH_ENTRY_MEDIA_INDIVIDUAL,
        url: `${URLS.API.MEDIA.ROOT}${objId}/`
    });
};

export const detect_faces = (data) => (dispatch, getState) => {
    const url = ENTRY_MEDIA_DETECT_FACE_URL;
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);
    const newData = new FormData();
    newData.append('image_url', data);

    return dispatch({
        type: DETECT_FACES,
        payload: {
            request: {
                url,
                method: 'POST',
                data: newData,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const deleteMedia = (id) => (dispatch, getState) => {
    const url = `${URLS.API.MEDIA.ROOT}${id}/`;
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);

    return dispatch({
        type: DELETE_ENTRY_MEDIA,
        payload: {
            request: {
                url,
                method: 'DELETE',
                options: {
                    id
                },
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const createInvite = (data) => (dispatch, getState) => {
    return makeDispatch({
        data,
        dispatch,
        method: 'POST',
        params: null,
        state: getState(),
        type: CREATE_MEDIA_INVITATION,
        url: URLS.API.MEDIA.INVITATION
    });
};
