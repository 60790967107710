import { FETCH_V2_PUBLIC_USER } from 'redux/actions/actionTypes';
import { SUCCESS_SUFFIX } from 'common/constant';

const initialState = {
    isEnabled: true,
    isPending: false,
    v2_public_user: null
};

const ReminderReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_V2_PUBLIC_USER: {
            return {
                ...state,
                isPending: true
            };
        }
        case FETCH_V2_PUBLIC_USER + SUCCESS_SUFFIX: {
            return {
                ...state,
                isPending: false,
                v2_public_user: action.payload.data
            };
        }
        default: {
            break;
        }
    }
    return state;
};

export default ReminderReducer;
