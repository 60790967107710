import _ from 'underscore';
import { SUCCESS_SUFFIX } from 'common/constant';
import arrayUtils from 'common/utils/arrayUtils';

import {
    CREATE_JOURNAL_EMAIL,
    CREATE_JOURNAL_EMAIL_SENDER,
    EDIT_JOURNAL_EMAIL_SENDER,
    DELETE_JOURNAL_EMAIL_SENDER,
    FETCH_JOURNAL_EMAILS,
    FETCH_AUTHORISED_EMAILS,
    DELETE_JOURNAL_EMAIL,
    ACTION_USER_LOGOUT
} from 'redux/actions/actionTypes';
import { fetch_journal_emails } from 'redux/actions/journalEmail';

const initialState = {
    journalEmails: [],
    senderEmails: []
};
const JournalEmailReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_JOURNAL_EMAILS + SUCCESS_SUFFIX: {
            let journalEmails = state.journalEmails;

            journalEmails = arrayUtils.mergeArrayByField(
                journalEmails,
                action.payload.data.objects
            );

            if (action.payload.data.meta.next !== null) {
                action.asyncDispatch(
                    fetch_journal_emails(action.payload.data.meta.next)
                );
            }
            return {
                ...state,
                journalEmails
            };
        }
        case FETCH_AUTHORISED_EMAILS + SUCCESS_SUFFIX: {
            const senderEmails = arrayUtils.mergeArrayByField(
                state.senderEmails,
                action.payload.data.results
            );
            return _.extend({}, state, { senderEmails });
        }

        case CREATE_JOURNAL_EMAIL + SUCCESS_SUFFIX: {
            return {
                ...state,
                journalEmails: [...state.journalEmails, action.payload.data]
            };
        }

        case EDIT_JOURNAL_EMAIL_SENDER + SUCCESS_SUFFIX:
        case CREATE_JOURNAL_EMAIL_SENDER + SUCCESS_SUFFIX: {
            const object = action.payload.data;
            const senderEmails = arrayUtils.mergeArrayByField(
                state.senderEmails,
                [object]
            );

            return _.extend({}, state, { senderEmails });
        }

        case DELETE_JOURNAL_EMAIL + SUCCESS_SUFFIX: {
            const removedId = action.meta.previousAction.id;
            const newArray = state.journalEmails.filter(
                (email) => email.id !== removedId
            );

            return {
                ...state,
                journalEmails: newArray
            };
        }
        case DELETE_JOURNAL_EMAIL_SENDER + SUCCESS_SUFFIX: {
            const id = action.meta.previousAction.id;
            let senderEmails = state.senderEmails;
            const index = senderEmails.findIndex((obj) => obj.id === id);
            if (index >= 0) {
                senderEmails = [].concat(
                    senderEmails.slice(0, index),
                    senderEmails.slice(index + 1)
                );
            }

            return _.extend({}, state, { senderEmails });
        }

        case ACTION_USER_LOGOUT: {
            return _.extend({}, initialState);
        }

        default:
            return {
                ...state
            };
    }
};

export default JournalEmailReducer;
