import axios from 'axios';
import buildAuthorization from 'common/utils/buildAuthorization';
import { CREATE_AVATAR_IMAGE } from 'redux/actions/actionTypes';

import { UserSelector } from 'redux/selectors/UserSelector';

import { AVATAR_IMAGE_URL, SUCCESS_SUFFIX } from 'common/constant';

export const create = (data, file, dropzone) => (dispatch, getState) => {
    const url = AVATAR_IMAGE_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    dispatch({
        type: CREATE_AVATAR_IMAGE,
        payload: {
            data
        }
    });
    axios
        .post(url, data, {
            headers: {
                Authorization: auth
            },
            onUploadProgress: (progress) => {
                const { loaded, total } = progress;
                const percentageProgress = Math.floor((loaded / total) * 90);
                if (file) {
                    file.upload.progress = percentageProgress;
                    file.upload.bytesSent = loaded;
                    dropzone.emit(
                        'uploadprogress',
                        file,
                        percentageProgress,
                        loaded
                    );
                }
            }
        })
        .then((response) => {
            if ((response && response.status) === 201) {
                dropzone.emit('success', file);
                dropzone.emit('complete', file);
                dispatch({
                    type: CREATE_AVATAR_IMAGE + SUCCESS_SUFFIX,
                    payload: {
                        data: response.data
                    }
                });
            } else {
                dropzone.emit('error', file);
            }
        });
};
