import React, { useCallback, useEffect, useState, useRef } from 'react';
// redux
import { connect } from 'react-redux';
// selecor
import { createStructuredSelector } from 'reselect';
import { getJournalList, getTagList, getUI } from 'redux/selectors';
// Actions
import { updateUI, updateStepsFilledIn } from 'redux/actions/ui';

// icons
import iconCaretDown from 'assets/images/icons_svg/icon_caret-down.svg';
import iconCheckSm from 'assets/images/icons_svg/icon_check-sm.svg';
// import { JOURNAL_EMAIL_SENDER_API_URL } from 'common/constant';

const JournalSelectStep = ({
    display,
    journals,
    ui,
    dispatch,
    tags,
    initCall,
    fetchEntryCount
}) => {
    const {
        book_extra_journals = [],
        book_tags = [],
        book_select_journal = true
    } = ui;
    const [openJournalsDrop, setOpenJournalsDrop] = useState(false);
    const [openTagsDrop, setOpenTagsDrop] = useState(false);
    const toggleJournalsDrop = useCallback(() => {
        setOpenJournalsDrop(!openJournalsDrop);
    }, [openJournalsDrop]);
    const toggleTagsDrop = useCallback(() => {
        setOpenTagsDrop(!openTagsDrop);
    }, [openTagsDrop]);

    const initialRenderRef = useRef(true);

    useEffect(() => {
        if (initialRenderRef.current) {
            initialRenderRef.current = false;
            initCall();
        }
    }, [initCall]);

    useEffect(() => {
        dispatch(
            updateUI({
                book_extra_journals: [],
                book_tags: []
            })
        );
    }, [dispatch]);

    const updateStepsFilledIn_JSS = useCallback(
        (value = false, mode_J = 'none') => {
            if (!value && mode_J !== 'none') {
                //At this point mode_J is either true or false
                if (mode_J) {
                    dispatch(
                        updateStepsFilledIn(
                            'JournalSelectStep',
                            ui.book_extra_journals.length > 0
                        )
                    );
                } else {
                    dispatch(
                        updateStepsFilledIn(
                            'JournalSelectStep',
                            ui.book_tags.length > 0
                        )
                    );
                }
            } else {
                dispatch(updateStepsFilledIn('JournalSelectStep', value));
            }
        },
        [ui, dispatch]
    );

    const changeJournalStatus = useCallback(
        (journal, status) => {
            const newBookJournals = [...book_extra_journals];
            if (status === true) {
                newBookJournals.push(journal);
            } else {
                const index = newBookJournals.findIndex(
                    (item) => item.id === journal.id
                );
                newBookJournals.splice(index, 1);
            }

            fetchEntryCount({
                book_extra_journals: newBookJournals,
                book_tags: [],
                book_select_journal: true
            });
            updateStepsFilledIn_JSS(newBookJournals.length > 0);
            dispatch(
                updateUI({
                    book_extra_journals: newBookJournals
                })
            );
        },
        [
            dispatch,
            book_extra_journals,
            fetchEntryCount,
            updateStepsFilledIn_JSS
        ]
    );

    const changeTagStatus = useCallback(
        (tag, status) => {
            const newTags = [...book_tags];
            if (status === true) {
                newTags.push(tag);
            } else {
                const index = newTags.findIndex((item) => item.id === tag.id);
                newTags.splice(index, 1);
            }
            fetchEntryCount({
                book_extra_journals: [],
                book_tags: newTags,
                book_select_journal: false
            });
            dispatch(
                updateUI({
                    book_tags: newTags
                })
            );
            updateStepsFilledIn_JSS(newTags.length > 0);
        },
        [dispatch, book_tags, fetchEntryCount, updateStepsFilledIn_JSS]
    );

    const renderJournalsDropdownName = useCallback(() => {
        if (book_extra_journals.length === 0) {
            return 'Select Journals';
        }
        return book_extra_journals
            .map((item) => {
                const journal = (journals?.journals || []).find(
                    (journal) => item.id === journal.id
                );

                return journal?.title;
            })
            .join(', ');
    }, [book_extra_journals, journals]);

    const renderTagsDropdownName = useCallback(() => {
        if (book_tags.length === 0) {
            return 'Select Tags';
        }
        return book_tags
            .map((item) => {
                const tag = (tags?.tags || []).find(
                    (tag) => item.id === tag.id
                );
                return tag?.name;
            })
            .join(', ');
    }, [book_tags, tags]);

    const changeBookSelect = useCallback(
        (status) => {
            dispatch(
                updateUI({
                    book_select_journal: status
                })
            );
            updateStepsFilledIn_JSS(false, status);
        },
        [dispatch, updateStepsFilledIn_JSS]
    );

    const renderJournalItem = useCallback(
        (journal) => {
            const active =
                book_extra_journals.findIndex(
                    (item) => item.id === journal.id
                ) >= 0;

            return (
                <li
                    key={`journal-list-${journal.id}`}
                    onClick={() => {
                        changeJournalStatus(journal, !active);
                    }}
                    className={`${active ? 'active' : ''}`}>
                    <div className="dropdown-selected-list__check">
                        <img src={iconCheckSm} alt="" />
                    </div>
                    <div className="dropdown-selected-list__title truncate">
                        <span>
                            {journal.title} ({journal.entry_count} entr
                            {journal.entry_count > 1 ? 'ies' : 'y'})
                        </span>
                    </div>
                </li>
            );
        },
        [book_extra_journals, changeJournalStatus]
    );

    const renderTagItem = useCallback(
        (tag) => {
            const active =
                book_tags.findIndex((item) => item.id === tag.id) >= 0;
            return (
                <li
                    key={`tag-list-${tag.id}`}
                    onClick={() => changeTagStatus(tag, !active)}
                    className={`${active ? 'active' : ''}`}>
                    <div className="dropdown-selected-list__check">
                        <img src={iconCheckSm} alt="" />
                    </div>
                    <div className="dropdown-selected-list__title truncate">
                        <span>{tag.name}</span>
                    </div>
                </li>
            );
        },
        [book_tags, changeTagStatus]
    );

    useEffect(() => {
        if (display === true) {
            dispatch(
                updateUI({
                    canGoNext:
                        (book_select_journal &&
                            book_extra_journals.length > 0) ||
                        (!book_select_journal && book_tags.length > 0)
                })
            );
        }
    }, [
        display,
        dispatch,
        book_select_journal,
        book_extra_journals.length,
        book_tags.length
    ]);
    useEffect(() => {
        if (display) {
            document.body.style.overflow = 'hidden';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [display]);

    return (
        <div
            className={`new-book-step-2 new-book-step ${
                display ? 'active-form' : ''
            }`}>
            <div className="section-title">Journal</div>
            <div className="new-book-step-form">
                <p>Choose which journal or tag to include entries from</p>
                <div>
                    <div className="form-group">
                        <div className="radio-item">
                            <input
                                onClick={() => changeBookSelect(true)}
                                onChange={() => {}}
                                className="radio"
                                type="radio"
                                id="printJournals"
                                // name="print-options"
                                value="0"
                                checked={book_select_journal === true}
                            />
                            <label htmlFor="printJournals">
                                Print from your Journals
                            </label>
                        </div>
                    </div>
                    <div></div>
                    <div className="form-group">
                        <div className="radio-item">
                            <input
                                onClick={() => changeBookSelect(false)}
                                onChange={() => {}}
                                className="radio"
                                type="radio"
                                id="printEntries"
                                // name="print-options"
                                value="1"
                                checked={book_select_journal === false}
                            />
                            <label htmlFor="printEntries">
                                Print from your Tags
                            </label>
                        </div>
                    </div>

                    <div className="form-group">
                        {book_select_journal ? (
                            <div className="form-group__item p-0">
                                <div className="section-title">
                                    Selected Journals
                                </div>
                                <div
                                    id="journal-tags-wrapper"
                                    className="dropdown show">
                                    <div
                                        onClick={toggleJournalsDrop}
                                        className="form-group__select dropdown-toggle truncate"
                                        role="button"
                                        aria-disabled={true}
                                        id="selectedTagsModal"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false">
                                        <span>
                                            {renderJournalsDropdownName()}
                                        </span>
                                        <img src={iconCaretDown} alt="" />
                                    </div>
                                    <div
                                        className={`dropdown-menu max-height--400 scrollable ${
                                            openJournalsDrop ? 'show' : ''
                                        }`}
                                        aria-labelledby="selectedJournalsModal">
                                        <ul className="dropdown-selected-list">
                                            {(journals?.journals || [])
                                                .filter(
                                                    (j) =>
                                                        j.title !==
                                                        'All About Me'
                                                )
                                                .map((journal) =>
                                                    renderJournalItem(journal)
                                                )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="form-group__item p-0">
                                <div className="section-title">
                                    Selected Tags
                                </div>
                                <div
                                    id="journal-tags-wrapper"
                                    className="dropdown show">
                                    <div
                                        className="form-group__select dropdown-toggle truncate"
                                        role="button"
                                        id="selectedTagsModal"
                                        onClick={toggleTagsDrop}
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false">
                                        <span>{renderTagsDropdownName()}</span>
                                        <img src={iconCaretDown} alt="" />
                                    </div>
                                    <div
                                        className={`dropdown-menu max-height--400 scrollable ${
                                            openTagsDrop ? 'show' : ''
                                        }`}
                                        aria-labelledby="selectedTagsModal">
                                        <ul className="dropdown-selected-list">
                                            {tags?.tags.length > 0 ? (
                                                tags?.tags
                                                    .filter((tag) => {
                                                        return (
                                                            tag.entry_count !==
                                                            0
                                                        );
                                                    })
                                                    .map((tag) =>
                                                        renderTagItem(tag)
                                                    )
                                            ) : (
                                                <p>No tags found</p>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    journals: getJournalList,
    tags: getTagList,
    ui: getUI
});
export default connect(state)(JournalSelectStep);
