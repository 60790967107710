import _ from 'underscore';
import {
    FETCH_FAMILY_ACCOUNTS,
    CREATE_FAMILY_ACCOUNT,
    EDIT_FAMILY_ACCOUNT,
    DELETE_FAMILY_ACCOUNT,
    ACTION_USER_LOGOUT
} from 'redux/actions/actionTypes';

const initialState = {
    familyAccounts: []
};

const FamilyAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_FAMILY_ACCOUNTS: {
            return {
                ...state,
                familyAccounts: action.data.familyAccounts
            };
        }

        case CREATE_FAMILY_ACCOUNT: {
            const familyAccounts = state.familyAccounts;
            familyAccounts.push(action.data);

            const data = {
                ...state,
                familyAccounts
            };

            localStorage.setItem('familyAccounts', JSON.stringify(data));
            return data;
        }

        case EDIT_FAMILY_ACCOUNT: {
            const familyAccounts = state.familyAccounts;
            familyAccounts.splice(action.data.index, 1, action.data.account);

            const data = {
                ...state,
                familyAccounts
            };

            localStorage.setItem('familyAccounts', JSON.stringify(data));
            return data;
        }
        case DELETE_FAMILY_ACCOUNT: {
            const familyAccounts = state.familyAccounts;
            familyAccounts.splice(action.data.index, 1);

            const data = {
                ...state,
                familyAccounts
            };

            localStorage.setItem('familyAccounts', JSON.stringify(data));
            return data;
        }

        case ACTION_USER_LOGOUT: {
            return _.extend({}, initialState);
        }

        default:
            return state;
    }
};

export default FamilyAccountReducer;
