import moment from 'moment';

export const ISO_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export const convertUTCTime = (date) => {
    const dateSZ = moment(date).format('YYYY-MM-DD');
    return moment.utc(dateSZ);
};

export const getDifferenceDateStr = (source, dest) => {
    if (source.year() !== dest.year()) {
        return source.format('MMMM D, YYYY');
    } else if (source.month() !== dest.month()) {
        return source.format('MMMM D');
    } else if (dest.diff(source, 'days') > 0) {
        return `${dest.diff(source, 'days')}d ago`;
    } else if (dest.diff(source, 'hours') > 0) {
        return `${dest.diff(source, 'hours')}h ago`;
    } else if (dest.diff(source, 'minutes') > 0) {
        return `${dest.diff(source, 'minutes')}m ago`;
    } else {
        return `${dest.diff(source, 'seconds')}s ago`;
    }
};

export const getTZStr = () => moment(new Date()).format('Z');

const DISPLAY_DATE_FORMAT = 'dddd HH:mm:ss';
export const getUTCTime = (time) => {
    const momentTime = moment(time, DISPLAY_DATE_FORMAT);
    return momentTime.utc();
};

export const getLocalTime = (timeSz) => {
    const momentTime = moment.utc(timeSz, DISPLAY_DATE_FORMAT);
    return momentTime.local();
};

export const dateISO = (date) => {
    return date
        ? moment.utc(date).local().format(ISO_DATE_FORMAT)
        : moment().utc().format(ISO_DATE_FORMAT);
};

export const nowIso = () => {
    return dateISO();
};

export const getDisplayTime = (iso_format_date) => {
    return moment(iso_format_date, ISO_DATE_FORMAT).format('ll');
};

export const compareDate = (dateA, dateB) => {
    if (dateA && !dateB) {
        return true;
    }
    if (dateB && !dateA) {
        return false;
    }
    return Boolean(new Date(dateA).getTime() - new Date(dateB).getTime());
};
