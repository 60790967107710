import _ from 'underscore';
import moment from 'moment';
import {
    FETCH_CALENDAR,
    ACTION_USER_LOGOUT,
    SET_CALENDAR_LOADINGPROGRESS,
    CLEAR_LOADINGPROGRESS,
    UPDATE_CALENDAR
} from 'redux/actions/actionTypes';
import { SUCCESS_SUFFIX, ERROR_SUFFIX } from 'common/constant';

const initialState = {
    calendar: undefined,
    calendar_list: undefined,
    isPending: false,
    utc_offset: moment().format('Z')
};
const onChangeSpecificMonth = (month, options) => {
    const newMonth = [...(month || [])];
    const isExist = (month || []).some((item) => item.day === options.day);
    if (!isExist) {
        return newMonth.concat({ day: options.day, entry_count: 1 });
    } else {
        return newMonth
            .map((item) => {
                if (item.day === options.day) {
                    return {
                        ...item,
                        entry_count: options.isIncrement
                            ? ++item.entry_count
                            : --item.entry_count
                    };
                }
                return item;
            })
            .filter((item) => {
                return item.entry_count !== 0;
            });
    }
};
const changeCalendar = (paramCalendar, entry_date, isIncrement = false) => {
    const calendar = paramCalendar || {};
    return {
        ...calendar,
        [entry_date.year]: {
            ...calendar[entry_date.year],
            [entry_date.month]: onChangeSpecificMonth(
                calendar[entry_date.year]
                    ? calendar[entry_date.year][entry_date.month]
                    : [],
                { ...entry_date, isIncrement }
            )
        }
    };
};
const CalendarReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_CALENDAR: {
            const { date, increment } = action.payload;
            const entry_date = {
                year: Number(moment(date).format('YYYY')),
                month: Number(moment(date).format('MM')),
                day: Number(moment(date).format('DD'))
            };
            const calendar = changeCalendar(
                state.calendar,
                entry_date,
                increment
            );
            return {
                ...state,
                calendar
            };
        }
        case FETCH_CALENDAR: {
            return _.extend({}, state, {
                isPending: true
            });
        }

        case FETCH_CALENDAR + SUCCESS_SUFFIX: {
            const calendarList = [];
            const calendar = action.payload.data;
            if (calendar) {
                let dateString, day, days, month, months, year, _i, _len;

                for (year in calendar) {
                    if (!calendar.hasOwnProperty(year)) {
                        return;
                    }
                    months = calendar[year];
                    for (month in months) {
                        if (!months.hasOwnProperty(month)) {
                            return;
                        }
                        days = months[month];
                        for (_i = 0, _len = days.length; _i < _len; _i++) {
                            day = days[_i];
                            dateString = `${year}-${month}-${day.day}`;
                            calendarList.push(
                                moment(dateString, 'YYYY-M-DD').unix()
                            );
                        }
                    }
                }
            }
            return _.extend({}, state, {
                isPending: false,
                calendar: Object.assign(
                    {},
                    { ...state.calendar, ...action.payload.data }
                ),
                calendar_list: calendarList
            });
        }

        case FETCH_CALENDAR + ERROR_SUFFIX: {
            return _.extend({}, state, {
                isPending: false
            });
        }

        case ACTION_USER_LOGOUT: {
            return _.extend({}, initialState);
        }

        case SET_CALENDAR_LOADINGPROGRESS: {
            return _.extend({}, state, {
                loading: true,
                isPending: undefined
            });
        }

        case CLEAR_LOADINGPROGRESS: {
            return _.extend({}, state, {
                loading: false
            });
        }

        default: {
            return state;
        }
    }
};

export default CalendarReducer;
