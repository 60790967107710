import _ from 'underscore';

import {
    createSelector
} from 'reselect';

export const AvatarImageSelector = (state) => state.avatar;

export const getAvatarImage = createSelector(
    [AvatarImageSelector],
    (avatarImage) => {
        return _.extend({}, avatarImage);
    }
);