import React from 'react';
import { connect } from 'react-redux';
import { getJournalList } from 'redux/selectors';
import { createStructuredSelector } from 'reselect';
import { useLocation } from 'react-router-dom';

// icons
import iconDownloadSm from 'assets/images/icons_svg/icon_download-sm.svg';
import iconLock from 'assets/images/icons_svg/icon_lock.svg';
import iconShare from 'assets/images/icons_svg/icon_share.svg';

//actions
import * as ExportJournalDataActions from 'redux/actions/exportJournalData';
import { updateUI } from 'redux/actions/ui';

import { URLS } from 'common/urls';
import { DATA_BUILDING_STATUS } from 'common/constant';

const ImportExportPane = ({ journals, dispatch }) => {
    const location = useLocation();
    const selectedJournals = [];
    const activeClassName =
        location.pathname === URLS.SETTINGS.IMPORTEXPORT ? 'active show' : '';

    const requestData = () => {
        dispatch(
            ExportJournalDataActions.create({
                journals: selectedJournals,
                status: DATA_BUILDING_STATUS
            })
        );
        dispatch(
            updateUI({
                dialogs: []
            })
        );
    };

    const changeJournalList = (event, journal) => {
        if (event.target.checked) {
            if (!selectedJournals.includes(journal.id)) {
                selectedJournals.push(journal.id);
            }
        } else {
            if (selectedJournals.includes(journal.id)) {
                selectedJournals.splice(
                    selectedJournals.indexOf(journal.id),
                    1
                );
            }
        }
    };

    return (
        <div
            className={`tab-pane fade import-export-settings ${activeClassName}`}
            id="import-export"
            role="tabpanel"
            aria-labelledby="import-export-tab">
            <h3 className="settings-title">Import From WordPress</h3>
            <p>
                You can import your posts and categories from your WordPress
                Blog using our plugin.
            </p>
            <div className="settings-section">
                <div className="imports-section">
                    <div className="import-steps">
                        <div className="import-steps__item">
                            <h3 className="import-step-title">Step 1</h3>
                            <p>
                                Download the WordPress Plugin clicking the
                                following button:
                            </p>
                            <button className="btn btn-download-plugin">
                                Download WordPress Plugin
                                <img
                                    className="right"
                                    src={iconDownloadSm}
                                    aria-labelledby={iconDownloadSm}
                                    alt="Download"
                                />
                            </button>
                        </div>
                        <div className="import-steps__item">
                            <h3 className="import-step-title">Step 2</h3>
                            <p>
                                Upload and install the plugin on your WordPress
                                installation.
                            </p>
                        </div>
                        <div className="import-steps__item">
                            <h3 className="import-step-title">Step 3</h3>
                            <p>
                                Log in and follow the instructions to import
                                your WordPress data into JRNL
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className="settings-title">Export your JRNL data</h3>
            <p>
                Select the Journals you want to export; we will send you an
                email with your data once it’s ready.
            </p>
            <div className="settings-section">
                <div className="exports-section">
                    <ul className="list">
                        {journals.journals?.map((journal) => (
                            <li className="list__item" key={journal.id}>
                                <div className="list__check">
                                    <input
                                        type="checkbox"
                                        id="checkBox"
                                        name="journalExport"
                                        className="checkbox"
                                        onChange={(event) =>
                                            changeJournalList(event, journal)
                                        }
                                    />
                                </div>
                                <div className="list__content">
                                    <div className="list__title">
                                        {journal.title}
                                    </div>
                                    <div className="list__subtitle">
                                        {journal.is_default && (
                                            <span className="default-journal">
                                                Default Journal
                                            </span>
                                        )}
                                    </div>
                                    <div className="list__subtitle">
                                        {journal.entry_count} Entries
                                    </div>
                                </div>
                                <div className="list__status list__status--icon">
                                    {journal.invitations?.length > 0 ? (
                                        <div className="btn-icon--6 btn-icon--rg">
                                            <img
                                                src={iconLock}
                                                aria-labelledby={iconLock}
                                                alt="Lock"
                                            />
                                        </div>
                                    ) : (
                                        <div className="btn-icon--6 btn-icon--rg">
                                            <img
                                                src={iconShare}
                                                aria-labelledby={iconShare}
                                                alt="Share"
                                            />
                                        </div>
                                    )}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <button
                className="btn btn-request-data btn-gradient"
                onClick={requestData}>
                Request Data
            </button>
        </div>
    );
};

const state = createStructuredSelector({
    journals: getJournalList
});
export default connect(state)(ImportExportPane);
