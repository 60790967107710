import _ from 'underscore';
import * as actionTypes from 'redux/actions/actionTypes';

const initialState = {
    notifications: [],
    limit: 20,
    total_count: 0
};

const NotificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_NOTIFICATIONS_LIMIT: {
            return {
                ...state,
                limit: action.payload
            };
        }
        case actionTypes.ADD_NOTIFICATIONS: {
            let notifications = state.notifications;
            let _i,
                _len,
                _ref = action.payload.data;

            for (_i = 0, _len = _ref.length; _i < _len; _i++) {
                const object = _ref[_i];
                const index = _.findIndex(
                    notifications,
                    (notification) => object.key === notification.key
                );

                if (index >= 0) {
                    notifications = [].concat(
                        notifications.slice(0, index),
                        [object],
                        notifications.slice(index + 1)
                    );
                } else {
                    notifications = [].concat([object], notifications || []);
                }
            }

            return _.extend({}, state, {
                notifications,
                total_count: notifications.length
            });
        }
        case actionTypes.ACTION_USER_LOGOUT: {
            return _.extend({}, initialState);
        }
        default: {
            return _.extend({}, state);
        }
    }
};

export default NotificationReducer;
