import { PLAN_LABELS } from 'common/constant';
import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';

// actions
import { renewPlan, fetch } from 'redux/actions/user';

const SubscriptionExpireBanner = ({ subscription }) => {
    const [loading, setLoading] = React.useState(false);

    const endDate = moment(subscription.end);
    const dispatch = useDispatch();

    const onRenewPlan = async () => {
        try {
            setLoading(true);
            // update user
            await dispatch(
                renewPlan({ id: subscription.id, cancel_at_period_end: false })
            );
            // fetch user data
            await dispatch(fetch());
            setLoading(false);
        } catch (e) {
            //
        } finally {
            setLoading(false);
        }
    };
    return (
        <div className="subscription-expiring-soon-banner">
            <h1 className="subscription-title">
                <i className="icon-error"></i>
                Your {PLAN_LABELS.PRO} Subscription is Expiring Soon
            </h1>
            <p className="subscription-info">
                You have downgraded from {PLAN_LABELS.PRO}; you will lose access
                to features like 1 TB of storage, shared journals and PDF
                downloads on {endDate.format('Do MMMM, YYYY')}
            </p>
            <button
                disabled={loading}
                className="btn btn-primary-circled btn-automatic-renewal"
                onClick={onRenewPlan}>
                Enable Automatic Renewal{' '}
            </button>
        </div>
    );
};

export default SubscriptionExpireBanner;
