import React, { useCallback, useState } from 'react';
// @ts-ignore
import _ from 'underscore';
import moment from 'moment';

// @ts-ignore
import DatePicker from 'addon-react-date-picker';

// Icons
import iconAngleLeft from 'assets/images/icons_svg/icon_angle-left.svg';
import iconAngleRight from 'assets/images/icons_svg/icon_angle-right.svg';
import { DEFAULT_DATETIME_FORMAT } from 'common/constant';

const Calendar = (props) => {
    const [viewMode, setViewMode] = useState('month');
    const [date, setDate] = useState(props.date || moment());
    const [monthView, setMonthView] = useState(moment().month());

    const onNav = useCallback(
        // @ts-ignore
        (dateText, dateAsMoment, view, direction) => {
            const _monthValue = monthView;
            setMonthView(_monthValue + direction);
            setDate(dateAsMoment);
        },
        [monthView]
    );

    const onViewChange = useCallback((viewMode) => {
        setViewMode(viewMode);
    }, []);

    const onChange = useCallback(
        // @ts-ignore
        (date, dateAsMoment, ev) => {
            setDate(dateAsMoment);
            if (props.onChange) {
                props.onChange(dateAsMoment, 'day');
            }
        },
        [props]
    );

    const onSelect = useCallback(
        // @ts-ignore
        (date, dateAsMoment, view) => {
            setDate(dateAsMoment);
            if (props.onChange) {
                props.onChange(dateAsMoment, view);
            }
        },
        [props]
    );

    const onRenderDay = useCallback(
        (params) => {
            const _date = params.date || new Date();
            const year = _date.year();
            const month = _date.month() + 1;
            const day = _date.date();

            let classNames = [];

            if (_date.isAfter(moment.utc())) {
                classNames.push(' dp-upcoming');
            }

            const stateDate = moment(_date);
            if (stateDate.endOf('month').isBefore(date)) {
                // @ts-ignore
                classNames = _.without(classNames, 'dp-current');
                if (stateDate.month() !== monthView) {
                    classNames.push(' dp-next');
                }
            }
            if (props.entryCalendar) {
                if (
                    props.entryCalendar[year] &&
                    props.entryCalendar[year][month]
                ) {
                    if (
                        // @ts-ignore
                        _.findWhere(props.entryCalendar[year][month], {
                            day
                        })
                    ) {
                        classNames.push(' entry');
                    }
                }
            }

            if (props.lockWidgetToView) {
                if (
                    date.isSame(params.date, 'day') &&
                    moment(props.minDate, DEFAULT_DATETIME_FORMAT).isSame(
                        moment(props.maxDate, DEFAULT_DATETIME_FORMAT).startOf(
                            'day'
                        )
                    )
                ) {
                    classNames.push(' dp-selected');
                }
            } else {
                if (date.isSame(params.date, 'day')) {
                    classNames.push(' dp-selected');
                }
            }

            if (viewMode !== 'day') {
                // @ts-ignore
                classNames = _.without(classNames, ' dp-value', ' dp-selected');
            }

            let _i;
            for (_i = 0; _i < classNames.length; _i++) {
                const name = classNames[_i];
                params.className += name;
            }
            return params;
        },
        [
            props.entryCalendar,
            props.lockWidgetToView,
            monthView,
            date,
            props.maxDate,
            props.minDate,
            viewMode
        ]
    );

    const onToday = useCallback(() => {
        const _date = moment();
        setDate(_date);
        if (props.onChange) {
            props.onChange(date, 'day');
        }
    }, [date, props]);

    return (
        <div className="modal-box modal-box--300">
            <div id="calendar-widget">
                <DatePicker
                    date={date}
                    view={viewMode}
                    viewOrder={['month', 'year', 'decade']}
                    onRenderDay={onRenderDay}
                    onViewChange={onViewChange}
                    onChange={onChange}
                    onSelect={onSelect}
                    onNav={onNav}
                    hideFooter={true}
                    weekDayNames={[
                        'SUN',
                        'MON',
                        'TUE',
                        'WED',
                        'THR',
                        'FRI',
                        'SAT'
                    ]}
                    monthFormat={'MMM'}
                    navPrev={<img src={iconAngleLeft} alt="" />}
                    navNext={<img src={iconAngleRight} alt="" />}
                />
                <div className="dp-footer">
                    <div onClick={onToday} className="dp-today">
                        Go To Today
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Calendar;
