// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import * as FamilyAccountActions from 'redux/actions/familyaccount';
import Popup from 'reactjs-popup';

//images
// import defaultUser from 'assets/images/default-user.jpg';
// import FamilyAccountDialog from 'pages/Dialogs/SettingsDialog/FamilyAccountDialog';
import {
    // closeDialog,
    openDialog
} from 'common/utils/dialog-utils';
import { createStructuredSelector } from 'reselect';
import { getFamilyAccounts, getUser } from 'redux/selectors';
// import DeleteDialog from 'components/DeleteDialog';
// import iconEdit from 'assets/images/icons_svg/icon_edit.svg';
// import iconDelete from 'assets/images/icons_svg/icon_delete.svg';
import iconInfo from 'assets/images/icons_svg/icon_info.svg';
import { useLocation } from 'react-router-dom';
import { URLS } from 'common/urls';
import UpgradeToProDialog from 'pages/Dialogs/UpgradeToProDialog';
import DeleteAccountDialog from 'pages/Dialogs/DeleteAccountDialog';
import {
    DISCOUNT,
    PLAN_LABELS,
    PLAN_PRICES,
    PLAN_SUBSCRIPTION_PRICES,
    SUBSCRIPTIONS_FREE,
    SUBSCRIPTIONS_MONTHLY,
    SUBSCRIPTIONS_QUARTERLY,
    SUBSCRIPTIONS_YEARLY,
    SUBSCRIPTION_LABELS
    // VIP1
} from 'common/constant';

import { JRNL_PLANS } from 'ssot/publicJRNLSSOT';

// Actions
import * as UserActions from 'redux/actions/user';
import SubscriptionExpireBanner from 'components/SubscriptionExpireBanner/SubscriptionExpireBanner';
import { getDisplayTime } from 'common/utils/dateUtils';
import * as PaymentProfileActions from 'redux/actions/paymentprofile';
// import { Button } from '@material-ui/core';
import { getSelectedPlanDuretion } from 'redux/selectors';
import { setSelectedPlanDuration } from 'redux/actions/settings_page';
import moment from 'moment';

const AccountPane = ({
    dispatch,
    // familyAccounts,
    user,
    selectedPlanDuration
}) => {
    const [isFetched, setIsFetched] = useState(false);
    // const [seats, setSeats] = useState(4);
    // const [downgradeClickFlag, setDowngradeClickFlag] = useState(false);
    const [cardEndNum, setCardEndNum] = useState('');
    const history = useHistory();
    // const [fakeUserSubscriptions, setfakeUserSubscriptions] = useState( SUBSCRIPTIONS_FREE);

    useEffect(() => {
        dispatch(PaymentProfileActions.fetch_default()).then((res) => {
            if (res?.payload?.status === 200) {
                const endNum = res.payload.data.safe_credit_card_number.replaceAll(
                    '*',
                    ''
                );
                setCardEndNum(endNum);
            }
        });
    }, [dispatch]);

    const currentPlan = useCallback(() => {
        if (user.is_free && !user.is_ty) {
            return SUBSCRIPTIONS_FREE;
        } else if (user.is_free && user.is_ty) {
            return 'free_ty';
        } else if (
            (user.is_ty && !user.is_pro) ||
            user.subscription?.plan_name.includes('oyalty')
        ) {
            if (user.subscription?.plan_name?.includes('50')) {
                return SUBSCRIPTIONS_YEARLY;
            } else {
                return 'ty';
            }
        } else if (
            user.is_pro &&
            user.subscription?.plan_name.includes('monthly')
        ) {
            return SUBSCRIPTIONS_MONTHLY;
        } else if (
            user.is_pro &&
            user.subscription?.plan_name.includes('quarterly')
        ) {
            return SUBSCRIPTIONS_QUARTERLY;
        } else if (
            user.is_pro &&
            (user.subscription?.plan_name.includes('yearly') ||
                user.subscription?.plan_name.includes('Discounting') ||
                user.subscription?.plan_name.includes('fake pro plan'))
        ) {
            return SUBSCRIPTIONS_YEARLY;
        } else {
            return SUBSCRIPTIONS_FREE;
        }
    }, [user]);

    const closeAccount = useCallback(() => {
        openDialog(DeleteAccountDialog);
    }, []);

    const [jsonPlans, setJsonPlans] = useState([]);

    useEffect(() => {
        setJsonPlans(JRNL_PLANS.PublicJRNL.Plans.Plan);
    }, [jsonPlans, user]);

    const [powerJrnlerPlan, setPowerJrnlerPlan] = useState({});
    useEffect(() => {
        // setPowerJrnlerPlan({Name: "Hello"})
        setPowerJrnlerPlan(jsonPlans.filter((plan) => plan.Id === 6)[0]);
    }, [powerJrnlerPlan, jsonPlans]);

    // useEffect(() => {

    //     setSeats(4 - familyAccounts.length);
    // }, [familyAccounts]);

    // const AddFamilyAccount = useCallback(() => {
    //     openDialog(FamilyAccountDialog);
    // }, []);

    // const EditFamilyAccount = useCallback((account, index) => {
    //     openDialog(FamilyAccountDialog, { account, index });
    // }, []);

    // const deleteAccount = useCallback(
    //     (index) => {
    //         dispatch(FamilyAccountActions.delete_account(index));
    //         dispatch(FamilyAccountActions.fetch());
    //         closeDialog();
    //     },
    //     [dispatch]
    // );

    // const openDeleteAccountDialog = useCallback(
    //     (index) => {
    //         openDialog(DeleteDialog, {
    //             title: 'Remove User?',
    //             text:
    //                 'You are about to remove this user from your family plan. Are you sure?',
    //             onDelete: () => deleteAccount(index)
    //         });
    //     },
    //     [deleteAccount]
    // );

    // open upgrade pro account modal

    /**
     * Downgrade to Free account
     */
    const downgradeToJRNLFree = useCallback(async () => {
        // Make API Request to downgrade
        //setDowngradeClickFlag(true);
        await dispatch(UserActions.unsubscribeToFree());
        //setDowngradeClickFlag(false);
        dispatch(UserActions.fetch());
    }, [dispatch]);

    const hideFreeTrial = useCallback(() => {
        return !!user.subscription;
    }, [user.subscription]);

    const isNewMember = useCallback(() => {
        const currentDate = moment();
        const dateJoined = moment(user.date_joined);
        if (
            currentDate.diff(dateJoined, 'day') <= 30 &&
            !user.is_pro &&
            !user.is_ty
        ) {
            return true;
        }
        return false;
    }, [user.date_joined, user.is_pro, user.is_ty]);

    useEffect(() => {
        isNewMember();
    });

    // Render Free Plan
    const renderFreePlan = useCallback(() => {
        return (
            <>
                <div className="account-plan__title">{PLAN_LABELS.FREE}</div>
                <div className="account-plan__price">
                    <b>On the house!</b>
                </div>
                <ul className="account-plan__desc">
                    <li>1 Journal</li>
                    <li>2GB storage</li>
                    <li>Unlimited Text Entries</li>
                    <li>Multiple Photos per Entry</li>
                </ul>
            </>
        );
    }, []);

    const upgradeToJRNLPro = (Label) => {
        openDialog(UpgradeToProDialog, { Label });
    };

    const renderProPlanMonthly = useCallback(() => {
        return (
            <>
                <div className="account-plan__title">
                    {
                        powerJrnlerPlan?.PlanPaymentOptions
                            ?.PlanPaymentOption[0].Title
                    }
                </div>

                <div className="account-plan__price">
                    <span className="currency">$</span>
                    <b>
                        {
                            powerJrnlerPlan?.PlanPaymentOptions
                                ?.PlanPaymentOption[0].Price
                        }
                    </b>
                    <span className="duration">
                        per {SUBSCRIPTION_LABELS.MONTH}
                    </span>
                </div>
                <ul className="account-plan__desc">
                    <li>Unlimited journals</li>
                    <li>1TB Storage</li>
                    <li>Unlimited photos</li>
                    <li>Audio</li>
                    <li>Video</li>
                    <li>Shared journals and entries</li>
                </ul>
            </>
        );
    }, [powerJrnlerPlan]);

    const renderProPlanQuartely = useCallback(() => {
        return (
            <>
                <div className="account-plan__title">
                    {PLAN_LABELS.PRO_QUARTERLY}
                </div>
                <div className="account-plan__price">
                    <span className="currency">$</span>
                    <b>{PLAN_PRICES.PRO_QUARTERLY.DEFAULT}</b>
                    <span className="duration">
                        per {SUBSCRIPTION_LABELS.QUARTER}
                        {/* (about $
                        {PLAN_PRICES.PRO_QUARTERLY.MONTHLY}
                        /mo) */}
                    </span>
                </div>
                <ul className="account-plan__desc">
                    <li>Unlimited journals</li>
                    <li>1TB Storage</li>
                    <li>Unlimited photos</li>
                    <li>Audio</li>
                    <li>Video</li>
                    <li>Shared journals and entries</li>
                </ul>
            </>
        );
    }, []);

    const renderProPlanYearly = useCallback(
        (plans) => {
            return (
                <>
                    <div className="account-plan__title">
                        {PLAN_LABELS.PRO_YEARLY}
                    </div>
                    <div className="account-plan__price">
                        {
                            <>
                                {isNewMember() ? (
                                    <>
                                        <span id="cross-price-section">
                                            $
                                            {
                                                plans.filter((plan) => {
                                                    return (
                                                        plan.Name ===
                                                        'POWER JRNLer'
                                                    );
                                                })[0]?.PlanPaymentOptions
                                                    ?.PlanPaymentOption[2].Price
                                            }
                                        </span>
                                        -<span className="currency">$</span>
                                        <b>
                                            {
                                                plans.filter((plan) => {
                                                    return (
                                                        plan.Name ===
                                                        'POWER JRNLer - 20%'
                                                    );
                                                })[0]?.PlanPaymentOptions
                                                    ?.PlanPaymentOption[2].Price
                                            }
                                        </b>
                                    </>
                                ) : currentPlan() === 'ty' ? (
                                    <>
                                        <span id="cross-price-section">
                                            $
                                            {
                                                plans.filter((plan) => {
                                                    return (
                                                        plan.Name ===
                                                        'POWER JRNLer'
                                                    );
                                                })[0]?.PlanPaymentOptions
                                                    ?.PlanPaymentOption[2].Price
                                            }
                                        </span>
                                        -<span className="currency">$</span>
                                        <b>
                                            {
                                                plans.filter((plan) => {
                                                    return (
                                                        plan.Name ===
                                                        'POWER JRNLer - 50%'
                                                    );
                                                })[0]?.PlanPaymentOptions
                                                    ?.PlanPaymentOption[2].Price
                                            }
                                        </b>
                                    </>
                                ) : (
                                    <>
                                        <span className="currency">$</span>
                                        <b>
                                            {
                                                plans.filter((plan) => {
                                                    return (
                                                        plan.Name ===
                                                        'POWER JRNLer'
                                                    );
                                                })[0]?.PlanPaymentOptions
                                                    ?.PlanPaymentOption[2].Price
                                            }
                                        </b>
                                    </>
                                )}
                            </>
                        }
                        {/* <b>{PLAN_PRICES.PRO_YEARLY.DEFAULT}</b> */}
                        <span className="duration">
                            per {SUBSCRIPTION_LABELS.YEAR}
                            {/* (about $
                            {PLAN_PRICES.PRO_YEARLY.MONTHLY}/mo) */}
                        </span>
                    </div>
                    <ul className="account-plan__desc">
                        <li>Unlimited journals</li>
                        <li>1TB Storage</li>
                        <li>Unlimited photos</li>
                        <li>Audio</li>
                        <li>Video</li>
                        <li>Shared journals and entries</li>
                    </ul>

                    {
                        // isNewMember() &&
                        <>
                            <b>PLUS:</b>
                            <ul className="account-plan__desc">
                                <li>
                                    {DISCOUNT}% discount on everything
                                    (including renewal)
                                </li>
                            </ul>
                        </>
                    }
                </>
            );
        },
        [currentPlan, isNewMember]
    );

    const renderUpgradeSectionModal = useCallback(() => {
        return (
            <div className="account-plan__upgrade-section">
                Upgrade within your 30-day trial to get {DISCOUNT}% off!
                <Popup
                    trigger={
                        <span>
                            <img src={iconInfo} alt="info" />
                        </span>
                    }
                    position="right center">
                    <div className="subscription_popup_modal">
                        <div className="subscription_popup_modal_first">
                            We want to offer you the option of a Power JRNLer
                            trial for <span>30 days</span>. You will get access
                            into all the Power JRNLer features and will be able
                            to upgrade before the trial ends for a{' '}
                            <span>
                                20% discount (if you choose our yearly plan).
                            </span>
                        </div>
                        <div className="subscription_popup_modal_second">
                            However, if the trial expires, you won&#39;t be able
                            to use {DISCOUNT}% discount. You can activate this
                            trial option one time ever. You can cancel anytime
                            before your 30-Day Trial expires to avoid being
                            charged and we&#39;ll send an email reminder{' '}
                            <span>7 days before</span> the trial ends
                        </div>
                    </div>
                </Popup>
            </div>
        );
    }, []);

    const renderUpgradeSectionInfoModal = useCallback((key) => {
        return (
            <div className="accont-plan-upgrade-secion-info">
                After your free month, pay as little as ${key} / month. Cancel
                anytime. We&rsquo;ll remind you 7 days before your trial ends.
            </div>
        );
    }, []);
    const renderUpgradeSectionInfoModalQuarterly = useCallback((key) => {
        return (
            <div className="accont-plan-upgrade-secion-info">
                After your free month, pay as little as ${key} / quarter. Cancel
                anytime. We&rsquo;ll remind you 7 days before your trial ends.
            </div>
        );
    }, []);

    const renderUpgradeSectionInfoModalAnnually = useCallback(
        (key) => {
            return (
                <div className="accont-plan-upgrade-secion-info">
                    After your free month, pay as little as $
                    {user.is_ty ? '25' : key} / year. Cancel anytime.
                    We&rsquo;ll remind you 7 days before your trial ends.
                </div>
            );
        },
        [user]
    );
    const location = useLocation();
    const activeClassName =
        location.pathname === URLS.SETTINGS.ACCOUNT ? 'active show' : '';

    const subscriptionCancelled =
        user.subscription?.cancel_at_period_end && user.is_pro;

    useEffect(() => {
        if (!isFetched) {
            dispatch(FamilyAccountActions.fetch());
            setIsFetched(true);
        }
    }, [dispatch, isFetched]);

    const [fakeUserSubscriptions, setfakeUserSubscriptions] = useState(
        selectedPlanDuration
    );

    // const freePlan = user.is_free;
    const selectSubscription = useCallback(
        (duration) => {
            dispatch(setSelectedPlanDuration(duration));
            setfakeUserSubscriptions(duration);
        },
        [dispatch]
    );

    const [isPlanSet, setIsPlanSet] = useState(false);
    useEffect(() => {
        if (!isPlanSet) {
            if (currentPlan() === 'ty') {
                dispatch(setSelectedPlanDuration(SUBSCRIPTIONS_YEARLY));
                setfakeUserSubscriptions(SUBSCRIPTIONS_YEARLY);
            } else if (currentPlan() === 'free_ty') {
                dispatch(setSelectedPlanDuration(SUBSCRIPTIONS_FREE));
                setfakeUserSubscriptions(SUBSCRIPTIONS_FREE);
            } else {
                dispatch(setSelectedPlanDuration(currentPlan()));
                setfakeUserSubscriptions(currentPlan());
            }
            setIsPlanSet(true);
        }
    }, [currentPlan, isPlanSet, dispatch]);

    const renderSubscriptionEndData = useCallback(() => {
        return getDisplayTime(user?.subscription?.end);
    }, [user]);

    const renderJRNLER = useCallback(() => {
        return (
            <>
                <div className="account-plan-title">
                    <div className="account-plan__title_subtitle">
                        {PLAN_LABELS.FREE}
                    </div>
                    <div className="account-plan__title_price">
                        ${PLAN_PRICES.FREE.DEFAULT}
                    </div>
                </div>
                <div className="account-plan__subtitle">
                    <p>Free forever; you&#39;re welcome!</p>
                </div>
            </>
        );
    }, []);

    const renderAccountPlanSubtitle = useCallback(() => {
        if (subscriptionCancelled) {
            return (
                <div className="account-plan__subtitle">
                    <p>
                        Your subscription is set to expire on{' '}
                        {renderSubscriptionEndData()}.
                    </p>
                </div>
            );
        } else {
            return (
                <div className="account-plan__subtitle">
                    <p>
                        Your next payment will be on{' '}
                        {renderSubscriptionEndData()} on the visa ending in{' '}
                        {cardEndNum}. You can manage your{' '}
                        <span
                            onClick={() => {
                                history.push(URLS.SETTINGS.BILLING);
                            }}>
                            payment methods
                        </span>{' '}
                        or{' '}
                        <span
                            onClick={() => {
                                downgradeToJRNLFree();
                            }}>
                            cancel
                        </span>{' '}
                        your subscription.
                    </p>
                </div>
            );
        }
    }, [
        renderSubscriptionEndData,
        cardEndNum,
        history,
        downgradeToJRNLFree,
        subscriptionCancelled
    ]);

    const renderPowerJRNLERMonthly = useCallback(() => {
        return (
            <>
                <div className="account-plan-title">
                    <div className="account-plan__title_subtitle">
                        {PLAN_LABELS.PRO_MONTHLY}
                    </div>
                    <div className="account-plan__title_price">
                        ${user.prices?.SUBSCRIPTION} per{' '}
                        {SUBSCRIPTION_LABELS.MONTH}
                    </div>
                </div>
                {renderAccountPlanSubtitle()}
            </>
        );
    }, [renderAccountPlanSubtitle, user.prices.SUBSCRIPTION]);

    const renderPowerJRNLERQuarterly = useCallback(() => {
        return (
            <>
                <div className="account-plan-title">
                    <div className="account-plan__title_subtitle">
                        {PLAN_LABELS.PRO_QUARTERLY}
                    </div>
                    <div className="account-plan__title_price">
                        ${user.prices?.SUBSCRIPTION} per{' '}
                        {SUBSCRIPTION_LABELS.QUARTER}
                    </div>
                </div>
                {renderAccountPlanSubtitle()}
            </>
        );
    }, [renderAccountPlanSubtitle, user.prices.SUBSCRIPTION]);

    const renderPowerJRNLERYearly = useCallback(() => {
        return (
            <>
                <div className="account-plan-title">
                    <div className="account-plan__title_subtitle">
                        {PLAN_LABELS.PRO_YEARLY}
                    </div>
                    <div className="account-plan__title_price">
                        ${user.prices?.SUBSCRIPTION} per{' '}
                        {SUBSCRIPTION_LABELS.YEAR}
                    </div>
                </div>
                {renderAccountPlanSubtitle()}
            </>
        );
    }, [renderAccountPlanSubtitle, user.prices.SUBSCRIPTION]);

    const renderPowerJRNLERYearly_20 = useCallback(() => {
        return (
            <>
                <div className="account-plan-title">
                    <div className="account-plan__title_subtitle">
                        {PLAN_LABELS.PRO_YEARLY}
                    </div>
                    <div className="account-plan__title_price">
                        ${user.prices?.SUBSCRIPTION} per{' '}
                        {SUBSCRIPTION_LABELS.YEAR}
                    </div>
                </div>
                {renderAccountPlanSubtitle()}
            </>
        );
    }, [renderAccountPlanSubtitle, user.prices.SUBSCRIPTION]);

    const renderPowerJRNLERYearly_50 = useCallback(() => {
        return (
            <>
                <div className="account-plan-title">
                    <div className="account-plan__title_subtitle">
                        {PLAN_LABELS.PRO_YEARLY}
                    </div>
                    <div className="account-plan__title_price">
                        ${user.prices?.SUBSCRIPTION} per{' '}
                        {SUBSCRIPTION_LABELS.YEAR}
                    </div>
                </div>
                {renderAccountPlanSubtitle()}
            </>
        );
    }, [renderAccountPlanSubtitle, user.prices.SUBSCRIPTION]);

    const renderPowerJRNLERYearly_10 = useCallback(() => {
        return (
            <>
                <div className="account-plan-title">
                    <div className="account-plan__title_subtitle">
                        {PLAN_LABELS.PRO_YEARLY}
                    </div>
                    <div className="account-plan__title_price">
                        ${user.prices?.SUBSCRIPTION} per{' '}
                        {SUBSCRIPTION_LABELS.YEAR}
                    </div>
                </div>
                {renderAccountPlanSubtitle()}
            </>
        );
    }, [renderAccountPlanSubtitle, user.prices.SUBSCRIPTION]);

    const renderLoyalJRNLER = useCallback(() => {
        return (
            <>
                <div className="account-plan-title">
                    <div className="account-plan__title_subtitle">
                        {PLAN_LABELS.LOYALTY}
                    </div>
                    <div className="account-plan__title_price">
                        {/* <s> */}${user.prices?.SUBSCRIPTION} per{' '}
                        {SUBSCRIPTION_LABELS.YEAR}
                        {/* </s> */}
                    </div>
                </div>
                <div className="account-plan__subtitle">
                    <p>
                        Thank you for being a Loyal JRNLer! We appreciate you!
                    </p>
                    {/*<span*/}
                    {/*    onClick={() => {*/}
                    {/*        downgradeToJRNLFree();*/}
                    {/*    }}>*/}
                    {/*    cancel*/}
                    {/*</span>*/}
                </div>
            </>
        );
    }, [user.prices.SUBSCRIPTION]);

    const renderUser = useCallback(() => {
        switch (currentPlan()) {
            case SUBSCRIPTIONS_FREE:
                return renderJRNLER();
            case 'free_ty':
                return renderJRNLER();
            case SUBSCRIPTIONS_MONTHLY:
                return renderPowerJRNLERMonthly();
            case SUBSCRIPTIONS_QUARTERLY:
                return renderPowerJRNLERQuarterly();
            case SUBSCRIPTIONS_YEARLY:
                if (user.subscription?.plan_name?.includes('20')) {
                    return renderPowerJRNLERYearly_20();
                } else if (user.subscription?.plan_name?.includes('50')) {
                    return renderPowerJRNLERYearly_50();
                } else if (user.subscription?.plan_name?.includes('10')) {
                    return renderPowerJRNLERYearly_10();
                }
                return renderPowerJRNLERYearly();

            case 'ty':
                return renderLoyalJRNLER();
            default:
                return;
        }
    }, [
        user,
        currentPlan,
        // fakeUserSubscriptions,
        renderLoyalJRNLER,
        renderJRNLER,
        renderPowerJRNLERMonthly,
        renderPowerJRNLERQuarterly,
        renderPowerJRNLERYearly,
        renderPowerJRNLERYearly_20,
        renderPowerJRNLERYearly_50,
        renderPowerJRNLERYearly_10
    ]);

    // const [selectedPlan, setSelectedPlan] = useState(SUBSCRIPTIONS_FREE);

    // const selectPlanSubscription = useCallback(() => {}, []);

    return (
        <div
            className={`tab-pane fade account-settings ${activeClassName}`}
            id="account"
            role="tabpanel"
            aria-labelledby="account-tab">
            {subscriptionCancelled && (
                <SubscriptionExpireBanner subscription={user.subscription} />
            )}
            <>
                <h3 className="settings-title">Current Plan</h3>
                <div className="settings-section">
                    <div className="account-plan">
                        {renderUser()}

                        {/* {user.is_pro && renderProPlan()}
                        {user.is_ty && renderLoyalPlan()}
                        {user.is_free && renderFreePlan()} */}
                    </div>
                    {user.is_ty && !user.is_pro ? (
                        <button
                            onClick={() => {
                                window.location.href = '/v3';
                            }}
                            className="btn btn-danger"
                            style={{ color: 'white' }}>
                            Review upcoming changes
                        </button>
                    ) : null}
                </div>

                {!subscriptionCancelled && (
                    <>
                        <h3 className="settings-title">Subscriptions</h3>
                        <div className="settings-title-section">
                            {PLAN_SUBSCRIPTION_PRICES.filter((item) => {
                                return item.title === 'Free';
                            }).map((price, index) => {
                                return (
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            selectSubscription(price.title);
                                        }}
                                        className={`subscriptions-title-box ${
                                            fakeUserSubscriptions ===
                                            price.title
                                                ? 'active'
                                                : ''
                                        }`}
                                        key={index}>
                                        <b>{price.title}</b>
                                        <p>${price.value}</p>
                                    </div>
                                );
                            })}

                            {jsonPlans
                                .filter((plan) => {
                                    return plan.Name === 'POWER JRNLer';
                                })[0]
                                ?.PlanPaymentOptions?.PlanPaymentOption?.map(
                                    (option, index) => {
                                        return (
                                            <div
                                                onClick={() => {
                                                    selectSubscription(
                                                        option.Duration
                                                    );
                                                }}
                                                className={`subscriptions-title-box ${
                                                    fakeUserSubscriptions ===
                                                    option.Duration
                                                        ? 'active'
                                                        : ''
                                                }`}
                                                key={index}>
                                                <b>{option.Duration}</b>
                                                <p>
                                                    {isNewMember() &&
                                                    currentPlan() !== 'ty' &&
                                                    option.Duration ===
                                                        'Annually' ? (
                                                        <>
                                                            <span id="cross-price-tab">
                                                                ${option.Price}
                                                            </span>
                                                            -$
                                                            {
                                                                jsonPlans.filter(
                                                                    (plan) => {
                                                                        return (
                                                                            plan.Name ===
                                                                            'POWER JRNLer - 20%'
                                                                        );
                                                                    }
                                                                )[0]
                                                                    ?.PlanPaymentOptions
                                                                    ?.PlanPaymentOption[2]
                                                                    .Price
                                                            }
                                                        </>
                                                    ) : (currentPlan() ===
                                                          'ty' ||
                                                          currentPlan() ===
                                                              'free_ty') &&
                                                      option.Duration ===
                                                          'Annually' ? (
                                                        <>
                                                            <span id="cross-price-tab">
                                                                ${option.Price}
                                                            </span>
                                                            -$
                                                            {
                                                                jsonPlans.filter(
                                                                    (plan) => {
                                                                        return (
                                                                            plan.Name ===
                                                                            'POWER JRNLer - 50%'
                                                                        );
                                                                    }
                                                                )[0]
                                                                    ?.PlanPaymentOptions
                                                                    ?.PlanPaymentOption[2]
                                                                    .Price
                                                            }
                                                        </>
                                                    ) : (
                                                        <>${option.Price}</>
                                                    )}{' '}
                                                </p>
                                            </div>
                                        );
                                    }
                                )}
                        </div>
                        <div className="settings-section">
                            <div className="account-plan">
                                {/* {user.is_pro &&
                                    (user.vip === VIP1 ? (
                                        <>
                                            {renderLoyalPlan()}
                                            <button
                                                disabled={downgradeClickFlag}
                                                onClick={downgradeToJRNLFree}
                                                className="btn btn-upgrade-pro">
                                                Downgrade to{' '}
                                                {PLAN_LABELS.LOYALTY}
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            {renderFreePlan()}
                                            <button
                                                disabled={downgradeClickFlag}
                                                onClick={downgradeToJRNLFree}
                                                className="btn btn-upgrade-pro">
                                                Downgrade to {PLAN_LABELS.FREE}
                                            </button>
                                        </>
                                    ))}
                                {(user.is_free || user.is_ty) && (
                                    <>
                                        {renderProPlan()}
                                        <button
                                            onClick={upgradeToJRNLPro}
                                            className="btn btn-upgrade-pro">
                                            Upgrade to {PLAN_LABELS.PRO}
                                        </button>
                                    </>
                                )} */}
                                {fakeUserSubscriptions ===
                                    SUBSCRIPTIONS_FREE && (
                                    <>
                                        {renderFreePlan()}

                                        {/* <button
                                                disabled={true}
                                                onClick={() =>
                                                    upgradeToJRNLPro(
                                                        PLAN_LABELS.FREE
                                                    )
                                                }
                                                className="btn btn-upgrade-pro">
                                                Current Plan
                                            </button> */}
                                    </>
                                )}
                                {fakeUserSubscriptions ===
                                    SUBSCRIPTIONS_MONTHLY && (
                                    <>
                                        {renderProPlanMonthly()}

                                        {/* {isNewMember() && renderUpgradeSectionModal()} */}
                                        <button
                                            disabled={
                                                currentPlan() ===
                                                SUBSCRIPTIONS_MONTHLY
                                            }
                                            onClick={() =>
                                                upgradeToJRNLPro(
                                                    PLAN_LABELS.PRO_MONTHLY
                                                )
                                            }
                                            className="btn btn-upgrade-pro">
                                            Start
                                            {!hideFreeTrial() &&
                                                ' your FREE Trial'}
                                        </button>
                                        {renderUpgradeSectionInfoModal(
                                            PLAN_PRICES.PRO_MONTHLY.DEFAULT
                                        )}
                                    </>
                                )}
                                {fakeUserSubscriptions ===
                                    SUBSCRIPTIONS_QUARTERLY && (
                                    <>
                                        {renderProPlanQuartely()}
                                        {/* {isNewMember() && renderUpgradeSectionModal()} */}
                                        <button
                                            disabled={
                                                currentPlan() ===
                                                SUBSCRIPTIONS_QUARTERLY
                                            }
                                            onClick={() =>
                                                upgradeToJRNLPro(
                                                    PLAN_LABELS.PRO_QUARTERLY
                                                )
                                            }
                                            className="btn btn-upgrade-pro">
                                            Start
                                            {!hideFreeTrial() &&
                                                ' your FREE Trial'}
                                        </button>
                                        {renderUpgradeSectionInfoModalQuarterly(
                                            PLAN_PRICES.PRO_QUARTERLY.DEFAULT
                                        )}
                                    </>
                                )}
                                {fakeUserSubscriptions ===
                                    SUBSCRIPTIONS_YEARLY && (
                                    <>
                                        {renderProPlanYearly(jsonPlans)}

                                        {isNewMember() &&
                                            renderUpgradeSectionModal()}

                                        <button
                                            disabled={
                                                currentPlan() ===
                                                SUBSCRIPTIONS_YEARLY
                                            }
                                            onClick={() =>
                                                upgradeToJRNLPro(
                                                    PLAN_LABELS.PRO_YEARLY
                                                )
                                            }
                                            className="btn btn-upgrade-pro">
                                            Start
                                            {!hideFreeTrial() &&
                                                ' your FREE Trial'}
                                        </button>
                                        {renderUpgradeSectionInfoModalAnnually(
                                            PLAN_PRICES.PRO_YEARLY.DEFAULT
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </>
            {/* Commented block holds Family plane UI */}
            {/* <h3 className="settings-title">Family Plan</h3>

            <div className="settings-section">
                <div className="family-plan-section">
                    <ul className="list list--family-members">
                        <li className="list__item">
                            <div className="list__img list__img--avatar">
                                <img src={user.avatar_image_url} alt="avatar" />
                            </div>
                            <div className="list__content list__content--user">
                                <div className="list__title">{`${user.first_name} ${user.last_name}`}</div>
                                <div className="list__subtitle">
                                    Your Account
                                </div>
                            </div>
                        </li>
                        {familyAccounts?.map((account, index) => (
                            <li
                                className="list__item"
                                key={account.email + index}>
                                <div className="list__img list__img--avatar">
                                    <img src={defaultUser} alt="avatar" />
                                </div>
                                <div className="list__content">
                                    <div className="list__title">
                                        {account.name}
                                    </div>
                                    <div className="list__content list__content--user">
                                        <div className="list__title">{`${user.first_name} ${user.last_name}`}</div>
                                        <div className="list__subtitle">
                                            Your Account
                                        </div>
                                    </div>
                                </li>
                                {familyAccounts?.map((account, index) => (
                                    <li
                                        className="list__item"
                                        key={account.email + index}>
                                        <div className="list__img list__img--avatar">
                                            <img
                                                src={defaultUser}
                                                alt="avatar"
                                            />
                                        </div>
                                        <div className="list__content">
                                            <div className="list__title">
                                                {account.name}
                                            </div>
                                            <div className="list__subtitle">
                                                {account.minor
                                                    ? 'Kids Account'
                                                    : 'Full Account'}
                                            </div>
                                        </div>
                                        <div className="list__actions">
                                            <button
                                                className="list__button btn-icon btn-icon--6"
                                                onClick={() =>
                                                    EditFamilyAccount(
                                                        account,
                                                        index
                                                    )
                                                }>
                                                <img
                                                    src={iconEdit}
                                                    aria-labelledby={iconEdit}
                                                    alt="Edit"
                                                />
                                            </button>
                                            <button
                                                className="list__button btn-icon btn-icon--6"
                                                onClick={() =>
                                                    openDeleteAccountDialog(
                                                        index
                                                    )
                                                }>
                                                <img
                                                    src={iconDelete}
                                                    aria-labelledby={iconDelete}
                                                    alt="Delete"
                                                />
                                            </button>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            <div className="settings-number-left">
                                <button
                                    className="btn btn-outline"
                                    disabled={seats === 0}
                                    onClick={AddFamilyAccount}>
                                    Add to Family Plan
                                </button>
                                <div className="settings-number-left__info">
                                    {seats} seats left
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="close-account-button">
                <button onClick={closeAccount} className="btn-delete-account">
                    Delete my account
                </button>
            </div>
        </div>
    );
};
const state = createStructuredSelector({
    familyAccounts: getFamilyAccounts,
    user: getUser,
    selectedPlanDuration: getSelectedPlanDuretion
});
export default connect(state)(AccountPane);
