import { createSelector } from 'reselect';

export const UISelector = (state) => state.ui;

export const getUI = createSelector([UISelector], (ui) => {
    return ui;
});

export const getUINewEntry = createSelector([UISelector], (ui) => {
    return ui.newEntry ? JSON.stringify(ui.newEntry) : null;
});

export const getStepsFilledIn = createSelector([UISelector], (ui) => {
    return ui.stepsFilledIn;
});
