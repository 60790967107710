import React, { useCallback, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Dialog from '@material-ui/core/Dialog';

import DefaultUser from 'assets/images/default-user.jpg';
import { URLS } from 'common/urls';
import { createInvite } from 'redux/actions/entrymedia';
import * as TaggedUserActions from 'redux/actions/taggedUser';
import { getShareContactList } from 'redux/selectors';

const AddTagUser = ({
    open,
    onClose,
    contacts,
    media,
    detectFace,
    onUpdate
}) => {
    const dispatch = useDispatch();
    const getContact = (Id) => {
        if (!Id) {
            return;
        }
        return contacts.find((obj) => {
            return obj?.user?.id === Id;
        });
    };
    const [anotherUser, setAnotherUser] = useState(false);
    const [anotherUserEmail, setAnotherUserEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [selectContact, setSelectContact] = useState(
        getContact(detectFace?.user?.id)
    );
    const [privateFlag, setPrivateFlag] = useState(
        detectFace?.tag_user_object?.private || false
    );

    // const renderAnotherUser = useCallback(() => {
    //     return (
    //         <li>
    //             {anotherUser ? anotherUser.email : ''}
    //             <span className="fa fa-check-circle blue-color"></span>
    //         </li>
    //     );
    // }, [anotherUser]);

    const clickContact = useCallback(
        (contact) => {
            // @ts-ignore
            if (selectContact?.id === contact?.id) {
                setSelectContact(null);
                setUserName('');
            } else {
                setSelectContact(contact);
                setUserName(contact.user.username);
                setAnotherUser(false);
            }
            setAnotherUserEmail('');
        },
        [selectContact]
    );

    /*
    const inputForAnotherUserEmail = useCallback((e) => {
        setAnotherUserEmail(e.target.value);
    }, []);
    */

    const addTagUser = useCallback(() => {
        if (anotherUser) {
            dispatch(
                createInvite({
                    emf: media.url,
                    email: anotherUserEmail,
                    sharing_user: media.user.url
                })
            );
        }
        // @ts-ignore
        if (selectContact?.user?.id) {
            // @ts-ignore
            const user = `${URLS.API.USER.PUBLIC}${selectContact.user.id}/`;
            const data = {
                coordinates: {
                    x1: detectFace.coordinates[3],
                    y1: detectFace.coordinates[0],
                    x2: detectFace.coordinates[1],
                    y2: detectFace.coordinates[0],
                    x3: detectFace.coordinates[1],
                    y3: detectFace.coordinates[2],
                    x4: detectFace.coordinates[3],
                    y4: detectFace.coordinates[2]
                },
                emf: media.url,
                private: privateFlag,
                user
            };
            dispatch(
                detectFace.tag_user_object
                    ? TaggedUserActions.update(
                          data,
                          null,
                          detectFace.tag_user_object.url
                      )
                    : TaggedUserActions.create(data)
            ).then((r) => {
                onUpdate(r.payload.data);
            });
        }
        onClose();
    }, [
        media,
        dispatch,
        anotherUserEmail,
        anotherUser,
        detectFace,
        selectContact,
        onClose,
        privateFlag,
        onUpdate
    ]);

    const renderContact = useCallback(() => {
        return (
            <div className="tag-person-modal-content-contacts">
                <h4 className="tag-person-modal-content-subtitle">
                    Your Contacts
                </h4>
                <ul className="tag-person-modal-content-list">
                    {(contacts || [])
                        .filter((item) => item.user)
                        .map((contact, index) => (
                            <li
                                onClick={() => clickContact(contact)}
                                key={index}>
                                <img
                                    src={
                                        contact?.user
                                            ? contact?.user?.avatar_image_url
                                            : DefaultUser
                                    }
                                    alt="avatar"
                                    className="circle-img"
                                />
                                <p className="comment-contact-name">
                                    {contact?.user?.username || contact?.email}
                                </p>
                                <span
                                    className={`fa fa-check-circle ${
                                        // @ts-ignore
                                        contact?.id === selectContact?.id
                                            ? 'selected-contact'
                                            : ''
                                    } ml-auto`}></span>
                            </li>
                        ))}
                </ul>
            </div>
        );
    }, [contacts, selectContact, clickContact]);

    return (
        <Dialog open={open} style={{ zIndex: 99999 }} onClose={onClose}>
            <div id="tag-person-modal" className="tag-person-modal">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8 col-xl-6 tag-person-modal-content">
                            <div className="tag-person-modal-content-header">
                                <h3 className="tag-person-modal-content-title">
                                    Tag This Person...
                                </h3>
                            </div>
                            {renderContact()}
                            <div className="tag-person-modal-add-user">
                                {/*
                                <div className="form-group">
                                    <label
                                        className="tag-person-modal-label"
                                        htmlFor="addUser">
                                        {anotherUser
                                            ? 'Add another person'
                                            : 'Add another user...'}
                                    </label>
                                    <div className="add-contact-input">
                                        <input
                                            type="text"
                                            disabled={!anotherUser}
                                            value={anotherUserEmail}
                                            onChange={inputForAnotherUserEmail}
                                            placeholder="Write an email..."
                                        />
                                        {!anotherUser && !selectContact && (
                                            <button
                                                className="btn icon-add-circle-filled"
                                                onClick={() => {
                                                    setAnotherUser(
                                                        !anotherUser
                                                    );
                                                }}></button>
                                        )}
                                    </div>
                                </div>
                                */}

                                <div className="media-photo-availability form-group">
                                    <input
                                        type="checkbox"
                                        id="photoAvailability"
                                        name="photoAvailability"
                                        className="media-checkmark"
                                        onChange={() =>
                                            setPrivateFlag(
                                                (prevValue) => !prevValue
                                            )
                                        }
                                        checked={!privateFlag}
                                    />
                                    <label
                                        className="default-journal-label"
                                        htmlFor="photoAvailability">
                                        Make this photo available for {userName}
                                    </label>
                                </div>

                                {/*anotherUser && (
                                    <p className="media-extra-info">
                                        We will send them an email invitation in
                                        your name to join JRNL and see the
                                        email.
                                    </p>
                                )*/}
                            </div>

                            <div className="tag-person-modal-content-actions">
                                <button
                                    className="btn btn-select-user"
                                    onClick={addTagUser}>
                                    <span className="fa fa-check-circle"></span>
                                    Add Users
                                </button>
                                <button
                                    className="btn btn-cancel"
                                    onClick={() => onClose()}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

const state = createStructuredSelector({
    contacts: getShareContactList
});

export default connect(state)(AddTagUser);
