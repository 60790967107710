import React from 'react';

// material ui components
import { Backdrop } from '@material-ui/core';

// image
import jrnlPreloader from 'assets/images/preloader.gif';

const PreLoader = () => {
    return (
        <Backdrop open style={{ zIndex: 9999 }}>
            <section className="loader-section">
                <div className="loader">
                    <img src={jrnlPreloader} alt="loader" />
                </div>
            </section>
        </Backdrop>
    );
};

export default PreLoader;
