import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import mozillaIcon from 'assets/images/icons_svg/icon_mozilla.svg';
import { setSettgsSidebarMobileStatus } from 'redux/actions/settings_page';

export const SettingsListItem = ({ item, listClick, idx, active_tab }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [imgIcon, setImgIcon] = useState('');
    useEffect(() => {
        item.title === 'JRNL Extension' &&
        navigator.userAgent.indexOf('Firefox') !== -1
            ? setImgIcon(mozillaIcon)
            : setImgIcon(item.icon);
    }, [item]);

    const handleMobileSettingsSideBar = useCallback(() => {
        dispatch(setSettgsSidebarMobileStatus(false));
    }, [dispatch]);

    const handleClick = useCallback(
        (e) => {
            history.push(item.path);
            handleMobileSettingsSideBar();
            listClick(e, item.id, idx);
        },
        [item, listClick, idx, history, handleMobileSettingsSideBar]
    );

    return (
        <li
            className="nav-item pointer-cursor"
            onClick={handleClick}
            key={item.id}>
            <div
                className={`nav-item__link ${
                    idx !== undefined && idx === active_tab ? 'active' : ''
                }`}
                id={item.id}
                data-toggle="tab"
                role="tab"
                aria-controls={item.aria_controls}
                aria-selected="true">
                <div className="nav-item__icon">
                    <img
                        src={imgIcon}
                        aria-labelledby={item.aria_controls}
                        alt=""
                    />
                </div>
                <div className="nav-item__title">
                    <span>{item.title}</span>
                </div>
            </div>
        </li>
    );
};
