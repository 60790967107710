import _ from 'underscore';
import buildAuthorization from 'common/utils/buildAuthorization';

import { UserSelector } from 'redux/selectors/UserSelector';
import {
    FETCH_RANDOM_QUESTION,
    FETCH_AAM_QUESTION,
    FETCH_AAM_QUESTION_LIST,
    FETCH_AAM_CATEGORY,
    FETCH_AAM_CATEGORY_LIST,
    CREATE_AAM_QUESTION,
    DELETE_AAM_QUESTION,
    UPDATE_AAM_QUESTION,
    CLEAR_RANDOM_QUESTION
} from 'redux/actions/actionTypes';
import { AAM_CATEGORY_URL, AAM_QUESTION_URL } from 'common/constant';

export const fetchCategoryList = (options = {}) => (dispatch, getState) => {
    const user = UserSelector(getState());
    const url = AAM_CATEGORY_URL;
    const auth = buildAuthorization(user);

    return dispatch({
        type: FETCH_AAM_CATEGORY_LIST,
        payload: {
            request: {
                url,
                method: 'GET',
                params: options,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const fetchCategory = (categoryId, options = {}) => (
    dispatch,
    getState
) => {
    const user = UserSelector(getState());
    const url = `${AAM_CATEGORY_URL}${categoryId}/`;
    const auth = buildAuthorization(user);

    return dispatch({
        type: FETCH_AAM_CATEGORY,
        payload: {
            request: {
                url,
                method: 'GET',
                params: options,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const fetchQuestionList = (options = {}) => (dispatch, getState) => {
    const user = UserSelector(getState());
    const url = AAM_QUESTION_URL;
    const auth = buildAuthorization(user);

    options = _.defaults(options, {
        limit: 10000
    });

    return dispatch({
        type: FETCH_AAM_QUESTION_LIST,
        payload: {
            request: {
                url,
                method: 'GET',
                params: options,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const fetchQuestion = (questionId, options = {}) => (
    dispatch,
    getState
) => {
    const user = UserSelector(getState());
    const url = `${AAM_QUESTION_URL}${questionId}/`;
    const auth = buildAuthorization(user);

    return dispatch({
        type: FETCH_AAM_QUESTION,
        payload: {
            request: {
                url,
                method: 'GET',
                params: options,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const fetchRandomQuestion = (options = {}) => (dispatch, getState) => {
    const user = UserSelector(getState());
    const url = `${AAM_QUESTION_URL}random/`;
    const auth = buildAuthorization(user);

    return dispatch({
        type: FETCH_RANDOM_QUESTION,
        payload: {
            request: {
                url,
                method: 'GET',
                params: options,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const clearRandomQuestion = () => (dispatch) => {
    return dispatch({
        type: CLEAR_RANDOM_QUESTION
    });
};

export const createAAMQuestion = (data) => (dispatch, getState) => {
    const user = UserSelector(getState());
    const url = `${AAM_QUESTION_URL}`;
    const auth = buildAuthorization(user);

    return dispatch({
        type: CREATE_AAM_QUESTION,
        payload: {
            request: {
                url,
                method: 'POST',
                data,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const updateAAMQuestion = (id, data) => (dispatch, getState) => {
    const user = UserSelector(getState());
    const url = `${AAM_QUESTION_URL}${id}/`;
    const auth = buildAuthorization(user);

    return dispatch({
        type: UPDATE_AAM_QUESTION,
        payload: {
            request: {
                url,
                method: 'PATCH',
                data,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const deleteAAMQuestion = (id) => (dispatch, getState) => {
    const user = UserSelector(getState());
    const url = `${AAM_QUESTION_URL}${id}/`;
    const auth = buildAuthorization(user);

    return dispatch({
        type: DELETE_AAM_QUESTION,
        payload: {
            request: {
                url,
                method: 'DELETE',
                headers: {
                    Authorization: auth
                }
            }
        },
        id
    });
};
