import { isDescendant } from 'common/utils/elementUtils';
import React, { useCallback, useState, useEffect } from 'react';

const DropDown = ({
    anchor,
    children,
    className,
    position = 'right_bottom',
    dropDownMenuProps: { className: menuClassName, ...menuProps } = {
        className: ''
    },
    classes,
    ...props
}) => {
    const ref = React.useRef(null);
    const [open, setOpen] = useState(false);
    const anchorClicked = useCallback((e) => {
        //e.stopPropagation();
        setOpen((prev) => !prev);
    }, []);

    const onClick = useCallback((e) => {
        setOpen(isDescendant(ref.current, e.target));
    }, []);

    useEffect(() => {
        window.addEventListener('click', onClick);
        return () => {
            window.removeEventListener('click', onClick);
        };
    }, [onClick]);

    return (
        <div className="dropdown">
            <div
                className={`dropdown-toggle ${className}`}
                style={{
                    backgroundColor: 'transparent',
                    border: 'none'
                }}
                role="button"
                onClick={anchorClicked}
                data-toggle="dropdown"
                aria-haspopup="true"
                ref={ref}
                aria-expanded={open}
                {...props}>
                {anchor}
            </div>
            <div
                className={`dropdown-menu ${menuClassName} ${
                    open ? `show ${position}` : ''
                }`}
                {...menuProps}>
                <ul className={classes?.dropdown_list}>{children}</ul>
            </div>
        </div>
    );
};

export default DropDown;
