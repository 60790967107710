import React from 'react'
import tripleImage from 'assets/images/static-pages/triple-photos.svg';
import {Button} from '@material-ui/core'

export const SaveMemories = ({classes,setDialogs}) => (
    <section className="save-memories-plans">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-12 save-memories-plans-card">
                    <img src={tripleImage} alt="triple-photos"/>
                    <div className="save-memories-card-content">
                        <h3 className="save-memories-card-title">
                            Start Saving Your Memories
                        </h3>
                        <p className="save-memories-card-text">
                            JRNL makes it easier to gather your thoughts and stories through journaling.
                            Start writing and saving your photos for free now.
                        </p>
                        <Button className={classes.btn} variant="outlined" color="inherit" fullWidth onClick={() => setDialogs({free: true})}>
                            Try JRNL Now
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </section>
)
