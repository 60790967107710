import _ from 'underscore';
import buildAuthorization from 'common/utils/buildAuthorization';

import { UserSelector } from 'redux/selectors/UserSelector';

import {
    FETCH_JOURNAL_LIST,
    FETCH_JOURNAL_DETAIL,
    CREATE_JOURNAL,
    UPDATE_JOURNAL,
    REMOVE_JOURNAL,
    DOWNLOAD_VCARD,
    RESET_VCARD,
    GENERATE_EMAIL_TO_JRNL_ADDRESS,
    PATCH_JOURNAL,
    SET_JOURNAL_LOADINGPROGRESS
} from 'redux/actions/actionTypes';
import { JournalSelector } from 'redux/selectors/JournalSelector';
import {
    JOURNAL_URL,
    INCOMING_EMAIL_ADDRESS_URL,
    JOURNAL_AAM
} from 'common/constant';

export const fetch = (options = {}, pageurl) => (dispatch, getState) => {
    const journal = JournalSelector(getState());
    if (journal.isPending === true) {
        return;
    }

    const url = pageurl || JOURNAL_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);
    const dispatch_options = _.defaults(options, {
        order_by: '-title',
        limit: 1000
    });

    return dispatch({
        type: FETCH_JOURNAL_LIST,
        payload: {
            request: {
                url,
                method: 'GET',
                params: dispatch_options,
                headers: {
                    Authorization: auth
                }
            }
        },
        timestamp: new Date()
    });
};

export const fetchJournal = (resource_uri) => (dispatch, getState) => {
    const url = resource_uri;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: FETCH_JOURNAL_DETAIL,
        payload: {
            request: {
                url,
                method: 'GET',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const createAAMJournal = () => (dispatch, getState) => {
    const url = JOURNAL_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    const data = {
        journal_type: JOURNAL_AAM,
        title: 'All About Me'
    };

    return dispatch({
        type: CREATE_JOURNAL,
        payload: {
            request: {
                url,
                method: 'POST',
                data,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const create = (data) => (dispatch, getState) => {
    const url = JOURNAL_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: CREATE_JOURNAL,
        payload: {
            request: {
                url,
                method: 'POST',
                data,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const update = (data) => (dispatch, getState) => {
    const url = `${JOURNAL_URL}${data.id}/`;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: UPDATE_JOURNAL,
        payload: {
            request: {
                url,
                method: 'PATCH',
                data,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const patch = (data, id) => (dispatch, getState) => {
    const url = `${JOURNAL_URL}${id}/`;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: PATCH_JOURNAL,
        payload: {
            request: {
                url,
                method: 'PATCH',
                data,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const remove = (data) => (dispatch, getState) => {
    const url = `${JOURNAL_URL}${data.id}/`;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: REMOVE_JOURNAL,
        payload: {
            request: {
                url,
                method: 'DELETE',
                options: {
                    id: data.id
                },
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const downloadVcard = (journal, options = {}) => (
    dispatch,
    getState
) => {
    const url = `${JOURNAL_URL}${journal.id}/vcard/`;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: DOWNLOAD_VCARD,
        payload: {
            request: {
                url,
                method: 'GET',
                params: options,
                responseType: 'blob',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const resetVcard = (journal, options = {}) => (dispatch, getState) => {
    return dispatch({
        type: RESET_VCARD,
        payload: {
            data: journal
        }
    });
};

export const generateEmailToJRNLAddress = (options = {}) => (
    dispatch,
    getState
) => {
    const url = INCOMING_EMAIL_ADDRESS_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    dispatch({
        type: GENERATE_EMAIL_TO_JRNL_ADDRESS,
        payload: {
            request: {
                url,
                method: 'POST',
                data: options,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const setLoadingProgress = () => (dispatch, getState) => {
    return dispatch({
        type: SET_JOURNAL_LOADINGPROGRESS
    });
};
