import React, { useCallback, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useHistory, useLocation } from 'react-router-dom';

import { getUI, getUser } from 'redux/selectors';
import * as FilterActions from 'redux/actions/filter';
import { ENTRY_QA, ENTRY_HIDDEN } from 'common/constant';
import _ from 'underscore';
import { openDialog } from 'common/utils/dialog-utils';
import AAMEditQuestionDialog from 'pages/Dialogs/AAMQuestion/AAMEditQuestionDialog';
import AAMDeleteQuestionDialog from 'pages/Dialogs/AAMQuestion/AAMDeleteQuestionDilaog';
import extractIdFromResourceUri from 'common/utils/extractIdFromResourceUri';
const QuestionCard = ({ question, ui, answers, user }) => {
    // history
    const history = useHistory();

    // router hooks
    const location = useLocation();
    const dispatch = useDispatch();

    const [openQuestionDrop, setOpenQuestionDrop] = useState(false);
    const toggleQuestionDrop = useCallback(() => {
        setOpenQuestionDrop(!openQuestionDrop);
    }, [openQuestionDrop]);
    const moveToQuestion = useCallback(
        (questionId) => {
            dispatch(
                FilterActions.update({
                    entry_type: ENTRY_QA,
                    question: questionId
                })
            );

            history.push(`/all-about-me/question/${questionId}`);
        },
        [history, dispatch]
    );

    const moveToAllAnswers = useCallback(
        (questionId) => {
            dispatch(
                FilterActions.update({
                    entry_type: ENTRY_QA,
                    question: questionId
                })
            );

            history.push(`/all-about-me/answers/${questionId}`);
        },
        [history, dispatch]
    );
    const calcHideEntriesCount = () => {
        let count = question.answer_count;
        if (
            answers &&
            !_.isEmpty(answers) &&
            location.pathname.includes('answers')
        ) {
            for (const answer of answers) {
                if (answer.state === ENTRY_HIDDEN) {
                    count--;
                }
            }
        }
        return count;
    };

    const isCustom = !!question.question_type;
    const isOwner =
        user.id === extractIdFromResourceUri('public_user', question.user);
    return (
        <div key={question.id} className="question-card">
            <div className="question-card-completed">
                <h3 className="question-card-title">
                    {isCustom && <span className="fa fa-edit">&nbsp;</span>}
                    {question.text}
                </h3>
                {isCustom && isOwner && (
                    <div className="dropdown-container">
                        <div
                            className="fa fa-ellipsis-v"
                            id="questionDropdown"
                            onClick={toggleQuestionDrop}
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                            {' '}
                        </div>
                        <div
                            className={`dropdown-menu question-dropdown-menu ${
                                openQuestionDrop ? 'show' : ''
                            }`}
                            aria-labelledby="questionDropdown">
                            <ul className="dropdown-entry-list">
                                <li
                                    onClick={() =>
                                        openDialog(AAMEditQuestionDialog, {
                                            question
                                        })
                                    }>
                                    <span className="icon-pencil"></span>
                                    Edit Question
                                </li>
                                <li
                                    onClick={() =>
                                        openDialog(AAMDeleteQuestionDialog, {
                                            question
                                        })
                                    }>
                                    <span className="icon-trash"></span>
                                    Delete Question
                                </li>
                            </ul>
                        </div>
                    </div>
                )}
            </div>

            <div className="question-card-actions">
                <div className="question-card-state">
                    {question.answer_count > 0
                        ? `Answered ${calcHideEntriesCount()}`
                        : 'Unanswered'}
                </div>

                <div className="d-flex">
                    {question.answer_count > 0 &&
                        !location.pathname.includes('answers') && (
                            <button
                                className="btn btn-all-answers"
                                onClick={() => {
                                    moveToAllAnswers(question?.id);
                                }}>
                                View Answers
                                <span
                                    className="fas fa-eye"
                                    style={{ marginRight: '.5rem' }}
                                />
                            </button>
                        )}
                    <button
                        className={`btn btn-answer ${
                            question.answer_count > 0 ? 'new-answer-btn-w' : ''
                        }`}
                        onClick={() => {
                            moveToQuestion(question?.id);
                        }}>
                        {question.answer_count > 0 ? 'New Answer' : 'Answer'}
                        <span className="fa fa-plus-circle" />
                    </button>
                </div>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    ui: getUI,
    user: getUser
});
export default connect(state)(QuestionCard);
