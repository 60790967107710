import { store } from 'redux/store/configureStore';
import { updateUI } from 'redux/actions/ui';

// send social connected dispatch
export const socialConnect = (data) => {
    store.dispatch(
        updateUI({
            socialConnect: { data }
        })
    );
};
