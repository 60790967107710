import React, { useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import * as EntryActions from 'redux/actions/entry';
import EntryTile from 'components/Entry/EntryTile';

// Utils
import {
    ENTRY_HIDDEN,
    DISPLAY_FILTER,
    JOURNAL_STATE,
    ENTRY_DRAFT
} from 'common/constant';
import { useLocation } from 'react-router-dom';
import { URLS } from 'common/urls';
import InfiniteScroll from 'react-infinite-scroll-component';
import CircularProgress from '@material-ui/core/CircularProgress';

// Icons
import logoIconColor from 'assets/images/svgs/svg_icon-color.svg';
import { getSearch } from 'redux/selectors';
import BannerCard from 'components/BannerCard';
import oopsImage from 'assets/images/banners/banner-oops.png';
import { checkResponseSuccess } from 'common/utils/responseUtils';

export const EntryInfiniteScrollList = React.forwardRef(
    (
        {
            // @ts-ignore
            entries,
            // @ts-ignore
            htmlElementId,
            // @ts-ignore
            hasMore,
            // @ts-ignore
            dispatch,
            // @ts-ignore
            renderEntry,
            // @ts-ignore
            setEntries,
            // @ts-ignore
            setHasMore
        },
        entryListRef
    ) => {
        const htmlElement = document.getElementById(htmlElementId);
        const onScrollBottom = useCallback(async () => {
            const top = htmlElement?.scrollTop;
            if (hasMore) {
                const res = await dispatch(
                    EntryActions.fetchEntriesWithOptions({
                        entry_date__lte:
                            entries[entries.length - 1]?.entry_date,
                        order_by: '-entry_date',
                        limit: 20
                    })
                ); // Switch this to fetch with Date ...
                if (
                    top < htmlElement.scrollTop &&
                    checkResponseSuccess(res.payload.status)
                ) {
                    window.scrollTo({ top });
                    setEntries((prevEntries) => {
                        return {
                            meta: res.payload.data.meta,
                            objects: [
                                ...prevEntries.objects,
                                ...res.payload.data.objects
                            ]
                        };
                    });
                    setHasMore(res?.payload?.data?.meta?.next || false);
                }
            }
            // eslint-disable-next-line
        }, [dispatch, hasMore, htmlElement?.scrollTop, setEntries, setHasMore]);

        const renderScrollComponent = () => {
            return (
                <InfiniteScroll
                    next={onScrollBottom}
                    hasMore={hasMore}
                    loader={
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress size={20} />
                        </div>
                    }
                    dataLength={entries?.length}
                    scrollThreshold="100%"
                    scrollableTarget={htmlElementId}
                    endMessage={
                        <div className="no-more">
                            <img src={logoIconColor} alt="" />
                        </div>
                    }>
                    {entries.map((entry) => renderEntry(entry))}
                </InfiniteScroll>
            );
        };
        return renderScrollComponent();
    }
);

const EntryList = React.forwardRef(
    // @ts-ignore
    ({ entries, filters, meta, dispatch, user }, entryListRef) => {
        const { pathname } = useLocation();
        const { fetched } = useSelector(getSearch);
        const onScrollBottom = useCallback(async () => {
            const top = document.documentElement.scrollTop;
            if (meta?.next) {
                const res = await dispatch(
                    EntryActions.fetchEntriesWithOptions({
                        entry_date__lte:
                            entries[entries.length - 1]?.entry_date,
                        order_by: '-entry_date',
                        limit: 20
                    })
                );
                if (
                    top < document.documentElement.scrollTop &&
                    checkResponseSuccess(res.payload.status)
                ) {
                    window.scrollTo({ top });
                }
            }
            // eslint-disable-next-line
        }, [dispatch, meta, entries?.length]);

        const entriesIsEmpty = () => {
            return Array.isArray(entries) && entries.length === 0;
        };

        const renderEntry = useCallback(
            (entry) => {
                const sharedButNotPublished =
                    entry.user.id !== user.id && entry.status === ENTRY_DRAFT;
                if (sharedButNotPublished) {
                    return;
                }
                //hide entry
                if (
                    entry.journal?.state === JOURNAL_STATE.HIDDEN &&
                    entry?.state === ENTRY_HIDDEN &&
                    !filters?.filterByJournal &&
                    !filters?.filterByTag &&
                    filters?.entryViewMode !== DISPLAY_FILTER.HIDDEN
                ) {
                    if (pathname.includes(URLS.SEARCH)) {
                        return <EntryTile key={entry.id} entry={entry} />;
                    }
                    return undefined;
                } else {
                    if (entry.journal?.state !== JOURNAL_STATE.HIDDEN) {
                        if (
                            entry?.state !== JOURNAL_STATE.HIDDEN ||
                            filters?.entryViewMode === DISPLAY_FILTER.HIDDEN
                        ) {
                            return <EntryTile key={entry.id} entry={entry} />;
                        }
                    }
                }
            },
            [filters, pathname, user.id]
        );
        const renderScrollComponent = () => {
            if (pathname === URLS.TIMELINE || pathname.includes(URLS.JOURNAL)) {
                return (
                    <InfiniteScroll
                        // @ts-ignore
                        id="timeline-entry-list"
                        next={onScrollBottom}
                        hasMore={meta?.next}
                        loader={
                            <div style={{ textAlign: 'center' }}>
                                <CircularProgress />
                            </div>
                        }
                        dataLength={entries?.length}
                        scrollThreshold="100%"
                        endMessage={
                            <div className="no-more">
                                <img src={logoIconColor} alt="" />
                            </div>
                        }>
                        {pathname.includes(URLS.JOURNAL) && filters?.journal
                            ? entries
                                  ?.filter((item) => {
                                      return (
                                          item.journal.id === filters?.journal
                                      );
                                  })
                                  .map((entry) => renderEntry(entry))
                            : entries?.map((entry) => renderEntry(entry))}
                    </InfiniteScroll>
                );
            } else {
                return (
                    <>
                        {entriesIsEmpty() && fetched ? (
                            <BannerCard
                                bannerClassName={'oops-card banner-card--dark'}
                                image={oopsImage}
                                title={"Oops! We didn't find anything."}
                                text={
                                    "We couldn't find any post matching your search. Try changing your filters and search again."
                                }
                                id={'oops-card'}
                            />
                        ) : (
                            entries.map((entry) => renderEntry(entry))
                        )}
                    </>
                );
            }
        };
        return renderScrollComponent();
    }
);

export default connect()(EntryList);
