import React from 'react';

// this function will take a content (html string) and parse it to find max characters that can be shown in summary mode.

// if node is not a text, we will do a recursion with that html element
// if node is an img, we will show everythin before that img

export const renderSummaryText = (content, readMore, entryId) => {
    const div = document.createElement('div');
    div.innerHTML = content;
    let count = 0;
    const strip = (el, max) => {
        const children = Array.prototype.slice.call(el.childNodes);
        children.forEach((node) => {
            if (node instanceof Text) {
                const newCount = count + node.textContent.length;
                const diff = newCount - max; // how many chars to show before max
                if (diff > 0) {
                    node.textContent = node.textContent.substring(
                        0,
                        node.textContent.length - diff
                    );
                }
                count += node.textContent.length;
            } else if (node instanceof HTMLElement) {
                if (
                    node.nodeName === 'IMG' &&
                    node?.nextElementSibling?.className !==
                        'add-media-box-file-info'
                ) {
                    count = max; //show only before the img
                }
                if (count >= max) {
                    node.remove(); // remove unnecessary tags
                } else {
                    strip(node, max); // do recursively
                }
            }
        });
    };

    strip(div, 150);

    const lastElement = div;

    if (lastElement) {
        if (
            lastElement?.textContent &&
            lastElement.textContent.length > 0 &&
            readMore
        ) {
            lastElement.innerHTML += '... '; // add three dots
        }
        if (content.includes('src') || content.includes('data-tag="img"') > 0) {
            lastElement.innerHTML +=
                '<div class="media-below">(Media Below)</div>';
        }
        if (readMore) {
            lastElement.innerHTML += `<span id="${`${entryId}read-more`}" class="read-more">See More</span>`;
        }
    }

    return <div dangerouslySetInnerHTML={{ __html: div.innerHTML }} />;
};
