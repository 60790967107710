import React, { useCallback } from 'react';
import { connect } from 'react-redux';
// Icons
import iconCloseSm from 'assets/images/icons_svg/icon_close-sm.svg';
import iconDelete from 'assets/images/icons_svg/icon_delete.svg';
import iconSharedJournal from 'assets/images/icons_svg/icon_shared-journal.svg';
import iconSharedEntry from 'assets/images/icons_svg/icon_shared-entry.svg';
import defaultUser from 'assets/images/default-user.jpg';

import { closeDialog, openDialog } from 'common/utils/dialog-utils';
import RevokeDialog from 'components/RevokeDialog';
import { Link } from 'react-router-dom';

import * as JournalInvitationActions from 'redux/actions/journalinvitation';
import * as EntryInvitationActions from 'redux/actions/invitation';
import * as ShareContactListsActions from 'redux/actions/sharecontactlist';
import DeleteDialog from 'components/DeleteDialog';
import EditContactDialog from './Contacts/EditContactDialog';
import { getSelectedContact } from 'redux/selectors/ShareContactListSelector';
import { createStructuredSelector } from 'reselect';

const SingleContactDialog = ({ dispatch, selectedContact }) => {
    const deleteContact = useCallback(
        (contact) => {
            dispatch(ShareContactListsActions.deleteContact(contact.id));
            dispatch(ShareContactListsActions.setSelectedContact(null));
            closeDialog();
        },
        [dispatch]
    );

    const stopSharingJournal = useCallback(
        (journal) => {
            const removed_user = journal.invitations.find(
                (invitation) => selectedContact.email === invitation.email
            );
            dispatch(JournalInvitationActions.remove(removed_user));
            closeDialog();
            dispatch(ShareContactListsActions.fetchDetailed());
        },
        [selectedContact, dispatch]
    );

    const stopSharingEntry = useCallback(
        (entry) => {
            const removed_user = entry.invitations.find(
                (invitation) => selectedContact.email === invitation.email
            );
            dispatch(EntryInvitationActions.remove(removed_user));
            closeDialog();
            dispatch(ShareContactListsActions.fetchDetailed());
        },
        [selectedContact, dispatch]
    );

    const revokeAccess = useCallback(() => {
        closeDialog();
        selectedContact.journals.map((journal) => {
            const removed_user = journal.invitations.find(
                (invitation) => selectedContact.email === invitation.email
            );
            return dispatch(JournalInvitationActions.remove(removed_user));
        });
        selectedContact.entries.map((entry) => {
            const removed_user = entry.invitations.find(
                (invitation) => selectedContact.email === invitation.email
            );
            return dispatch(EntryInvitationActions.remove(removed_user));
        });
        dispatch(ShareContactListsActions.fetchDetailed());
    }, [dispatch, selectedContact]);

    const openRevokeAccessDialog = useCallback(() => {
        openDialog(RevokeDialog, {
            title: 'Revoke all access?',
            text: 'Are you sure you want to revoke all access to this contact?',
            onDelete: () => revokeAccess()
        });
    }, [revokeAccess]);

    const openDeleteContactDialog = useCallback(
        (contact) => {
            openDialog(DeleteDialog, {
                title: 'Remove contact?',
                text: 'Are you sure you want remove this contact?',
                onDelete: () => deleteContact(contact)
            });
        },
        [deleteContact]
    );

    const openDeleteJournalDialog = useCallback(
        (journal) => {
            openDialog(DeleteDialog, {
                title: 'Stop sharing journal?',
                text:
                    'Are you sure you want to stop sharing this journal with this contact?',
                onDelete: () => stopSharingJournal(journal)
            });
        },
        [stopSharingJournal]
    );

    const openDeleteEntryDialog = useCallback(
        (entry) => {
            openDialog(DeleteDialog, {
                title: 'Stop sharing entry?',
                text:
                    'Are you sure you want to stop sharing this entry with this contact?',
                onDelete: () => stopSharingEntry(entry)
            });
        },
        [stopSharingEntry]
    );

    const openEditContactDialog = useCallback(() => {
        openDialog(EditContactDialog, {
            contact: selectedContact
        });
    }, [selectedContact]);

    return (
        <>
            <div className="modal-frame">
                <div className="modal-box modal-box--400">
                    <div className="modal__header">
                        Manage Contact
                        <div
                            className="modal__close"
                            onClick={() =>
                                dispatch(
                                    ShareContactListsActions.setSelectedContact(
                                        null
                                    )
                                )
                            }>
                            <img src={iconCloseSm} alt="" />
                        </div>
                    </div>
                    <div className="modal__body">
                        <div className="manage-contact">
                            <div className="manage-contact__avatar">
                                <img
                                    src={
                                        selectedContact.user?.avatar_image_url
                                            ? selectedContact.user
                                                  ?.avatar_image_url
                                            : defaultUser
                                    }
                                    alt="avatar"
                                />
                            </div>
                            <div className="manage-contact__title">
                                {selectedContact.user?.public_display_name && (
                                    <div className="list__title">
                                        {
                                            selectedContact.user
                                                .public_display_name
                                        }
                                    </div>
                                )}
                            </div>
                            <div className="manage-contact__subtitle">
                                {selectedContact.email}
                            </div>
                        </div>
                    </div>
                    <div className="modal__footer">
                        <button
                            className="btn btn-outline"
                            onClick={openEditContactDialog}>
                            Edit
                        </button>
                        <button
                            className="btn btn-outline"
                            onClick={openRevokeAccessDialog}>
                            Revoke Access
                        </button>
                        <button
                            className="btn btn-delete"
                            onClick={() =>
                                openDeleteContactDialog(selectedContact)
                            }>
                            Remove
                        </button>
                    </div>
                    <div className="shared-content scrollable">
                        <textarea className="cloudsponge-contacts"></textarea>
                        {selectedContact?.journals &&
                            selectedContact.journals.length > 0 && (
                                <>
                                    <div className="shared-content-title">
                                        Shared Journals{' '}
                                        {selectedContact.journals.length}
                                    </div>
                                    <div className="list list--page">
                                        {selectedContact.journals.map(
                                            (journal) => (
                                                <div
                                                    className="list__item"
                                                    key={journal.id}>
                                                    <div className="list__img 	list__img--icon-md">
                                                        <img
                                                            src={
                                                                iconSharedJournal
                                                            }
                                                            className="btn-icon 	btn-icon--6"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="list__content">
                                                        <Link
                                                            className="list__title"
                                                            to={`/journals/${journal.id}`}>
                                                            {journal.title}
                                                        </Link>
                                                    </div>
                                                    <div className="list__options">
                                                        <button
                                                            className="btn-icon 	btn-icon--4"
                                                            onClick={() =>
                                                                openDeleteJournalDialog(
                                                                    journal
                                                                )
                                                            }>
                                                            <img
                                                                src={iconDelete}
                                                                alt=""
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </>
                            )}

                        {selectedContact?.entries &&
                            selectedContact.entries.length > 0 && (
                                <>
                                    <div className="shared-content-title">
                                        Shared Entries{' '}
                                        {selectedContact.entries.length}
                                    </div>
                                    <div className="list list--page">
                                        {selectedContact.entries.map(
                                            (entry, index) => (
                                                <div
                                                    className="list__item"
                                                    key={index}>
                                                    <div className="list__img  		list__img--icon-md">
                                                        <img
                                                            src={
                                                                iconSharedEntry
                                                            }
                                                            className="btn-icon 	btn-icon--6"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="list__content">
                                                        <Link
                                                            className="list__title"
                                                            to={`/entry/${entry.id}`}>
                                                            {entry.title ||
                                                                'Shared entry'}
                                                        </Link>
                                                    </div>
                                                    <div className="list__options">
                                                        <button
                                                            className="btn-icon 		btn-icon--4"
                                                            onClick={() => {
                                                                openDeleteEntryDialog(
                                                                    entry
                                                                );
                                                            }}>
                                                            <img
                                                                src={iconDelete}
                                                                alt=""
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </>
                            )}
                    </div>
                </div>
            </div>
        </>
    );
};
const state = createStructuredSelector({
    selectedContact: getSelectedContact
});
export default connect(state)(SingleContactDialog);
