import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as BeneficiariesActions from 'redux/actions/beneficiaries';

//images
// import defaultUser from 'assets/images/default-user.jpg';
import { closeDialog, openDialog } from 'common/utils/dialog-utils';
import BeneficiaryDialog from 'pages/Dialogs/SettingsDialog/BeneficiaryDialog';
import { getBeneficiariesAccounts } from 'redux/selectors';
import { createStructuredSelector } from 'reselect';
import DeleteDialog from 'components/DeleteDialog';
import iconEdit from 'assets/images/icons_svg/icon_edit.svg';
import iconDelete from 'assets/images/icons_svg/icon_delete.svg';
import { PLAN_LABELS } from 'common/constant';

const JRNLGuardPane = ({ dispatch, beneficiaries }) => {
    const [isFetched, setIsFetched] = useState(false);

    useEffect(() => {
        if (!isFetched) {
            dispatch(BeneficiariesActions.fetch());
            setIsFetched(true);
        }
    }, [dispatch, isFetched]);

    const openAddBeneficiaryDialog = useCallback(() => {
        openDialog(BeneficiaryDialog);
    }, []);

    const openEditBeneficiaryDialog = useCallback((account) => {
        openDialog(BeneficiaryDialog, { account });
    }, []);

    const deleteAccount = useCallback(
        (index) => {
            dispatch(BeneficiariesActions.delete_account(index));
            dispatch(BeneficiariesActions.fetch());
            closeDialog();
        },
        [dispatch]
    );

    const openDeleteBeneficiaryDialog = useCallback(
        (index) => {
            openDialog(DeleteDialog, {
                title: 'Remove Beneficiary?',
                text:
                    'You are about to remove this user from your beneficiaries. Are you sure?',
                onDelete: () => {
                    deleteAccount(index);
                }
            });
        },
        [deleteAccount]
    );

    return (
        <div
            className="tab-pane fade jrnlGuard-settings"
            id="jrnlGuard"
            role="tabpanel"
            aria-labelledby="jrnlGuard-tab">
            <h3 className="settings-title">JRNL Guard</h3>
            <div className="settings-section">
                <div className="info-block">
                    <div className="info-block__title">
                        Protect your Journals if something happens
                    </div>
                    <ul className="info-block__list">
                        <li>
                            JRNL Guard lets you select up to 3 beneficiaries,
                            giving them restricted or complete access to your
                            JRNL account in case something happens to you.
                        </li>
                        <li>
                            We’ll send you notifications to your email and phone
                            number if a beneficiary tries to access your
                            account.
                        </li>
                        <li>
                            They’ll have access after 7 days if you don’t deny
                            access before that period of time.
                        </li>
                        <li>
                            Beneficiaries will have access to the Journals you
                            choose. They will be able to read their content,
                            save them in a PDF file, and order books with the
                            Journal’s content.
                        </li>
                        <li>
                            JRNL Guard is a part of {PLAN_LABELS.PRO}{' '}
                            subscription. It’s also available as a one-time
                            payment for Free users.
                        </li>
                    </ul>
                </div>
            </div>
            <h3 className="settings-title">Beneficiaries</h3>
            <div className="settings-section">
                <ul className="list">
                    {beneficiaries?.map((account, index) => (
                        <li className="list__item" key={index}>
                            <div className="list__content">
                                <div className="list__title">
                                    {account.name}
                                </div>
                                <div className="list__subtitle">
                                    {account.isSelectedAll
                                        ? 'Full Access'
                                        : 'Limited Access'}
                                    , {account.email}, {account.phoneNumber}
                                </div>
                            </div>
                            <div className="list__actions">
                                <button
                                    className="list__button"
                                    onClick={() =>
                                        openEditBeneficiaryDialog(account)
                                    }>
                                    <img
                                        src={iconEdit}
                                        aria-labelledby={iconEdit}
                                        alt="Edit"
                                    />
                                </button>
                                <button
                                    className="list__button"
                                    onClick={() =>
                                        openDeleteBeneficiaryDialog(index)
                                    }>
                                    <img
                                        src={iconDelete}
                                        aria-labelledby={iconDelete}
                                        alt="Delete"
                                    />
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>
                <div className="settings-number-left">
                    <button
                        className="btn btn-outline"
                        onClick={openAddBeneficiaryDialog}>
                        Add to JRNL Guard
                    </button>
                    <div className="settings-number-left__info">
                        1 seat left
                    </div>
                </div>
            </div>
            <div className="jrnlGuard-accounts-beneficiaries">
                <h3 className="settings-title">
                    Accounts you’re beneficiary of
                </h3>
                <ul className="jrnlGuards-accounts-list">
                    {/* <li>
                        <img src={defaultUser} alt="avatar" />
                        <p className="beneficiary-name">Marisa Williams</p>
                        <button className="btn btn-access-account">
                            Access Account
                        </button>
                        <span className="icon-trash"></span>
                    </li> */}
                </ul>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    beneficiaries: getBeneficiariesAccounts
});

export default connect(state)(JRNLGuardPane);
