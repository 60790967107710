import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { YEAR_END, YEAR_START } from 'common/constant';
import { FaArrowLeft } from 'react-icons/fa';

export const MonthYearTabs = ({
    currentDate,
    setCurrentDate,
    onDateSelect
}) => {
    const [showYearTab, setShowYearTab] = useState(false);
    const [showMonthTab, setShowMonthTab] = useState(true);
    const [decadeTab, setDecadeTab] = useState({
        show: false,
        year: moment().year()
    });
    const changeCurrentDate = useCallback(
        (value, unit) => {
            const date = moment(currentDate).set(unit, value);
            setCurrentDate(date);
            onDateSelect(date, unit);
        },
        [currentDate, setCurrentDate, onDateSelect]
    );
    const toggleTabs = useCallback(
        (name) => {
            if (name === 'year') {
                setShowYearTab(true);
                decadeTab.show && setDecadeTab(false);
                showMonthTab && setShowMonthTab(false);
            } else if (name === 'month') {
                decadeTab.show && setDecadeTab(false);
                showYearTab && setShowYearTab(false);
                setShowMonthTab(true);
            }
        },
        [decadeTab.show, showMonthTab, showYearTab]
    );
    const showDecade = (year) => {
        setShowYearTab(false);
        setDecadeTab({ show: true, year });
    };
    const renderYearList = useCallback(() => {
        const years = [];
        for (let year = YEAR_START; year <= YEAR_END; year += 10) {
            years.push(year);
        }
        return (
            showYearTab && (
                <div className="tab-pane fade show active" id="pills-year">
                    <ul className="calendar-list decades-calendar-list">
                        {years.map((year) => (
                            <li
                                key={`cal-header${year}`}
                                onClick={() => {
                                    showDecade(year);
                                }}
                                className={`${
                                    moment(currentDate).format('YYYY') ===
                                    year.toString()
                                        ? 'active'
                                        : ''
                                }`}>
                                {year}s
                            </li>
                        ))}
                    </ul>
                </div>
            )
        );
    }, [currentDate, showYearTab]);
    const renderMonthList = useCallback(() => {
        return (
            showMonthTab && (
                <div className="tab-pane show active" id="pills-month">
                    <ul className="calendar-list month-calendar-list">
                        {moment.monthsShort().map((month, index) => (
                            <li
                                key={`cal-header${month}`}
                                onClick={() =>
                                    changeCurrentDate(index, 'month')
                                }
                                className={`${
                                    moment(currentDate).format('MMM') === month
                                        ? 'active'
                                        : ''
                                }`}>
                                {month}
                            </li>
                        ))}
                    </ul>
                </div>
            )
        );
    }, [currentDate, changeCurrentDate, showMonthTab]);
    const renderDecadeTab = useCallback(() => {
        const years = [];
        for (let year = decadeTab.year; year <= decadeTab.year + 9; year++) {
            years.push(year);
        }
        return (
            decadeTab.show && (
                <div className="tab-pane fade show active" id="pills-year">
                    <ul className="calendar-list decades-calendar-list">
                        <li>
                            <button
                                className="btn-decades"
                                onClick={() => toggleTabs('year')}>
                                <FaArrowLeft />
                            </button>
                        </li>
                        {years.map((year) => (
                            <li
                                key={`cal-header${year}`}
                                onClick={() => {
                                    changeCurrentDate(year, 'year');
                                }}
                                className={`${
                                    moment(currentDate).format('YYYY') ===
                                    year.toString()
                                        ? 'active'
                                        : ''
                                }`}>
                                {year}
                            </li>
                        ))}
                    </ul>
                </div>
            )
        );
    }, [
        currentDate,
        changeCurrentDate,
        decadeTab.show,
        decadeTab.year,
        toggleTabs
    ]);
    useEffect(() => {
        if (showYearTab) {
            const parent = document.querySelector('.calendar-list');
            const child = document.querySelector('.calendar-list li.active');

            // Where is the parent on page
            const parentRect = parent.getBoundingClientRect();
            // What can you see?
            const parentViewableArea = {
                height: parent.clientHeight,
                width: parent.clientWidth
            };
            // Where is the child
            const childRect = child?.getBoundingClientRect();
            // Is the child viewable?
            if (childRect) {
                const isViewable =
                    childRect.top >= parentRect.top &&
                    childRect.top <= parentRect.top + parentViewableArea.height;
                if (!isViewable) {
                    parent.scrollTop =
                        childRect.top + parent.scrollTop - parentRect.top;
                }
            }
        }
    }, [showYearTab]);

    return (
        <div
            className={'show calendar-dropdown-menu'}
            aria-labelledby="calendarFilter">
            <ul className="nav nav-pills calendar-filters">
                <li className="nav-item calendar-filter-item">
                    <span
                        className={`nav-link ${showMonthTab ? 'active' : ''}`}
                        onClick={() => toggleTabs('month')}>
                        Month
                    </span>
                </li>
                <li className="nav-item calendar-filter-item">
                    <span
                        className={`nav-link ${showYearTab ? 'active' : ''}`}
                        onClick={() => toggleTabs('year')}>
                        Year
                    </span>
                </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
                {renderYearList()}
                {renderMonthList()}
                {renderDecadeTab()}
            </div>
        </div>
    );
};
