import React, { useEffect, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { URLS } from 'common/urls';
// components
import {
    ChooseAccountPlan,
    HeaderCard,
    IncludedAccounts,
    PlansInfo,
    SaveMemories
} from './sections';
import { makeStyles, Dialog } from '@material-ui/core';
import {
    FreeAccountDialog,
    ProAccountDialog,
    ThankYouAccountModal
} from './Modals';
// images
import bgImg from 'assets/images/static-pages/thank-you-header-img.jpg';
import logo from 'assets/images/static-pages/logo.png';
import bottomBgImg from 'assets/images/static-pages/thank-you-bottom-img.jpg';

//actions
import { useSignUpForm } from './Modals/modals-utils';
import { bindActionCreators } from 'redux';
import {
    checkEmailAvailability as checkEmailAvailabilityAction,
    checkUsernameAvailability as checkUsernameAvailabilityAction,
    register as registerAction
} from 'redux/actions/registration';
import { connect } from 'react-redux';
import { PLAN_LABELS } from 'common/constant';

const useStyles = makeStyles({
    btn: {
        color: 'white',
        width: '17.5625rem',
        marginRight: 0,
        height: '4rem',
        backgroundColor: 'var(--primary-color)',
        fontFamily: 'Cabin',
        fontSize: '1.625rem',
        fontWeight: 500,
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '0.625rem',
        lineHeight: 1,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'var(--primary-color)',
            color: '#FFF'
        }
    }
});
export const PricingContainer = ({
    checkEmailAvailability,
    checkUsernameAvailability,
    registerAction
}) => {
    const history = useHistory();
    const classes = useStyles();
    const [isBasic, setIsBasic] = useState(true);
    const [dialogs, setDialogs] = useState({
        free: false,
        pro: false,
        thank_you: false
    });
    const onSetAndValidate = useSignUpForm(
        checkEmailAvailability,
        checkUsernameAvailability
    );
    const [formData, setFormData] = useState({
        username: { value: '', isValid: false, error: false },
        email: { value: '', isValid: false, error: false },
        password: { value: '', isValid: false },
        confirm_password: { value: '', isValid: false },
        privacy: { value: false, isValid: false }
    });
    const onChange = (value, name) => {
        onSetAndValidate(value, name, setFormData, formData);
    };
    const register = (e) => {
        e.preventDefault();
        const data = {};
        for (const item in formData) {
            switch (item) {
                case 'confirm_password':
                    break;
                case 'privacy':
                    break;
                default:
                    data[item] = formData[item].value;
            }
        }
        registerAction(data);
    };
    const onCloseDialogs = () => {
        setDialogs({});
    };
    const location = useLocation();
    useEffect(() => {
        document.body.className = 'static-page';
    }, []);

    useEffect(
        (effect) => {
            switch (location.pathname) {
                case URLS.PRICING.ROOT:
                    return setIsBasic(true);
                case URLS.PRICING.THANKYOUPAGE:
                    return setIsBasic(false);
                default:
                    return setIsBasic(true);
            }
        },
        [location.pathname]
    );
    useEffect(() => {
        setFormData({
            username: { value: '', isValid: false, error: false },
            email: { value: '', isValid: false, error: false },
            password: { value: '', isValid: false },
            confirm_password: { value: '', isValid: false },
            privacy: { value: false, isValid: false }
        });
    }, [dialogs]);
    return (
        <>
            <header className="thank-you-header">
                <div
                    className="logo-img"
                    onClick={() => history.push(URLS.HOME)}
                    style={{ cursor: 'pointer' }}>
                    <img src={logo} alt="logo" />
                </div>
                <div>
                    <NavLink to={URLS.PRICING.ROOT} className="mr-3">
                        ROOT
                    </NavLink>
                    <NavLink to={URLS.PRICING.THANKYOUPAGE}>
                        {PLAN_LABELS.LOYALTY}
                    </NavLink>
                </div>
            </header>
            <div className="thank-you-header-bg">
                <img src={bgImg} alt="header-bg" />
            </div>
            <main>
                <HeaderCard isBasic={isBasic} />
                <ChooseAccountPlan
                    isBasic={isBasic}
                    classes={classes}
                    setDialogs={setDialogs}
                />
                <IncludedAccounts />
                <PlansInfo
                    classes={classes}
                    isBasic={isBasic}
                    setDialogs={setDialogs}
                />
                <SaveMemories classes={classes} setDialogs={setDialogs} />
            </main>
            <div className="thank-you-footer-bg">
                <img src={bottomBgImg} alt="bottom-bg" />
            </div>
            <footer className="thank-you-footer">
                <div className="logo-img">
                    <img src={logo} alt="logo" />
                </div>
                <ul className="thank-you-footer-list">
                    <li>
                        <a href="#terms">Terms</a>
                    </li>
                    <li>
                        <a href="#terms">Privacy</a>
                    </li>
                </ul>
            </footer>
            <Dialog open={dialogs.free || dialogs.pro || dialogs.thank_you}>
                {dialogs.free ? (
                    <FreeAccountDialog
                        onCloseDialogs={onCloseDialogs}
                        formData={formData}
                        onChange={onChange}
                        register={register}
                        setFormData={setFormData}
                    />
                ) : dialogs.thank_you ? (
                    <ThankYouAccountModal
                        register={register}
                        onCloseDialogs={onCloseDialogs}
                        formData={formData}
                        setFormData={setFormData}
                        onChange={onChange}
                    />
                ) : (
                    <ProAccountDialog
                        onCloseDialogs={onCloseDialogs}
                        formData={formData}
                        onChange={onChange}
                        setFormData={setFormData}
                    />
                )}
            </Dialog>
        </>
    );
};
const dispatches = (dispatch) => ({
    checkEmailAvailability: bindActionCreators(
        checkEmailAvailabilityAction,
        dispatch
    ),
    checkUsernameAvailability: bindActionCreators(
        checkUsernameAvailabilityAction,
        dispatch
    ),
    registerAction: bindActionCreators(registerAction, dispatch)
});
const Pricing = connect(null, dispatches)(PricingContainer);
export default Pricing;
