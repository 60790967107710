import React, { useEffect, useCallback, useState } from 'react';

// actions
import { connect } from 'react-redux';
import * as AllAboutMeActions from 'redux/actions/allaboutme';

// selectors
import { createStructuredSelector } from 'reselect';
import {
    getUser,
    getCategoryForId,
    getQuestionsForCategory,
    getUI
} from 'redux/selectors';

import QuestionCard from './QuestionCard';
import AAMCategoryImage from 'common/utils/images/AAMCategoryImage';
import { NavLink } from 'react-router-dom';
import { URLS } from 'common/urls';

// Icons
import iconAngleLeft from 'assets/images/icons_svg/icon_angle-left.svg';
import { openDialog } from 'common/utils/dialog-utils';
import AAMNewQuestionDialog from 'pages/Dialogs/AAMQuestion/AAMNewQuestionDialog';
import withFreeAccountCheck from 'common/utils/components/withFreeAccountCheck';

const AllAboutMeCategory = ({ ui, match, category, questions, dispatch }) => {
    const [isFetched, setIsFetched] = useState(false);

    // componentDidMount
    useEffect(() => {
        const { params } = match;

        if (params.categoryId) {
            if (!isFetched) {
                dispatch(AllAboutMeActions.fetchCategory(params.categoryId));
                dispatch(
                    AllAboutMeActions.fetchQuestionList({
                        category: params.categoryId
                    })
                );

                setIsFetched(true);
            }
        }
    }, [dispatch, match, isFetched]);

    const renderQuestionList = useCallback(() => {
        if (questions) {
            return questions.map((question) => (
                <QuestionCard question={question} key={question.id} />
            ));
        }
    }, [questions]);
    return (
        <div className="page-container">
            <section className="back-to-all-about-me">
                <div className="back-to-all-about-me-content">
                    <NavLink to={URLS.AAM.ROOT}>
                        <img
                            src={iconAngleLeft}
                            alt=""
                            className="icon-static btn-icon--6"
                        />
                        Back to All About Me
                    </NavLink>
                </div>
            </section>

            <div className="selected-category-card">
                <div className="selected-category-card-img">
                    {category && (
                        <img
                            src={AAMCategoryImage(
                                category?.name
                                    ?.toLowerCase()
                                    .split(' ')
                                    .join('-')
                            )}
                            alt="aam-icon"
                        />
                    )}
                </div>
                <div className="selected-category-card-content">
                    <div className="category-card-content-title">
                        {category?.name}
                    </div>
                    <div className="category-card-content-subtitle">
                        {' '}
                        {category?.answered_count} / {category?.question_count}{' '}
                        Answered
                    </div>
                    <button
                        onClick={() =>
                            openDialog(
                                withFreeAccountCheck(AAMNewQuestionDialog),
                                { category }
                            )
                        }
                        className="btn btn-outline btn--sm">
                        Create a question
                    </button>
                </div>
            </div>

            {renderQuestionList()}
        </div>
    );
};

const state = createStructuredSelector({
    ui: getUI,
    user: getUser,
    category: getCategoryForId,
    questions: getQuestionsForCategory
});
export default connect(state)(AllAboutMeCategory);
