import _ from 'underscore';
import {
    FETCH_BOOK_LIST,
    CREATE_BOOK,
    UPDATE_BOOK,
    FETCH_BOOK,
    POLL_BOOK,
    ADD_BOOK_TIMER,
    REMOVE_BOOK_TIMER,
    REMOVE_ALL_BOOK_TIMERS,
    REMOVE_BOOK,
    SET_BOOK_LOADINGPROGRESS,
    CLEAR_LOADINGPROGRESS,
    ACTION_USER_LOGOUT,
    SET_BOOK_DOWNLOAD_PROGRESS,
    BOOK_BUIDER_FULL_VIEW
} from 'redux/actions/actionTypes';
import { SUCCESS_SUFFIX, ERROR_SUFFIX } from 'common/constant';

const initialState = {
    books: undefined,
    timers: undefined,
    meta: undefined,
    isPending: false,
    download_progress: 0,
    isCalculating: false,
    isBookBuilderFullView: false
};

const BookReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BOOK_DOWNLOAD_PROGRESS: {
            return {
                ...state,
                download_progress: action.payload
            };
        }
        case FETCH_BOOK_LIST: {
            return _.extend({}, state, {
                isPending: true
            });
        }
        case 'SET_IS_CALC': {
            return _.extend({}, state, {
                isCalculating: action.payload.value
            });
        }
        case FETCH_BOOK_LIST + SUCCESS_SUFFIX: {
            let books = state.books;

            action.payload.data.objects.forEach((object) => {
                const index = _.findIndex(
                    books,
                    (book) => object.id === book.id
                );

                if (index >= 0) {
                    books = [].concat(
                        books.slice(0, index),
                        [object],
                        books.slice(index + 1)
                    );
                } else {
                    books = [].concat(books || [], [object]);
                }
            });

            return _.extend({}, state, {
                books,
                meta: action.payload?.data.meta,
                isPending: false
            });
        }

        case CREATE_BOOK + SUCCESS_SUFFIX:
        case UPDATE_BOOK + SUCCESS_SUFFIX:
        case FETCH_BOOK + SUCCESS_SUFFIX:
        case POLL_BOOK + SUCCESS_SUFFIX: {
            let books = state.books;
            const object = action.payload.data;
            const index = (books || []).findIndex(
                (book) => object.id === book.id
            );

            if (index >= 0) {
                books = [].concat(
                    books.slice(0, index),
                    [object],
                    books.slice(index + 1)
                );
            } else {
                books = [].concat([object], books || []);
            }

            return _.extend({}, state, {
                books
            });
        }

        case POLL_BOOK + ERROR_SUFFIX: {
            return _.extend({}, state, {
                isPolling: false
            });
        }

        case BOOK_BUIDER_FULL_VIEW: {
            return _.extend({}, state, {
                isBookBuilderFullView: action.payload.data
            });
        }

        case ADD_BOOK_TIMER: {
            const timers = (state && state.timers) || [];

            timers.push(action.payload.data);
            return _.extend({}, state, {
                timers
            });
        }

        case REMOVE_BOOK_TIMER: {
            let timers = (state && state.timers) || [];

            clearTimeout(action.payload.timer);
            timers = _.without(timers, action.payload.data);
            return _.extend({}, state, {
                timers
            });
        }

        case REMOVE_ALL_BOOK_TIMERS: {
            const timers = state.timers;
            if (timers) {
                timers.forEach((timer) => {
                    clearTimeout(timer);
                });
            }
            return _.extend({}, state, {
                timers: undefined,
                isPolling: false
            });
        }

        case POLL_BOOK: {
            return _.extend({}, state, {
                isPolling: true
            });
        }

        case REMOVE_BOOK + SUCCESS_SUFFIX: {
            let books = state.books;
            books = books.filter(
                (book) => book.id !== action.payload.config.options.bookId
            );

            return _.extend({}, state, {
                books
            });
        }

        case SET_BOOK_LOADINGPROGRESS: {
            return _.extend({}, state, {
                loading: true,
                isPending: undefined
            });
        }

        case ACTION_USER_LOGOUT: {
            return _.extend({}, initialState);
        }

        case CLEAR_LOADINGPROGRESS: {
            return _.extend({}, state, {
                loading: false
            });
        }

        default: {
            break;
        }
    }

    return state;
};

export default BookReducer;
