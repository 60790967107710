import React, { useState, useEffect, useCallback, Fragment } from 'react';
// @ts-ignore
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import _ from 'underscore';
// @ts-ignore
import { Link, useLocation } from 'react-router-dom';
import { DropdownMenu } from 'components';
import AAMCategoryImage from 'common/utils/images/AAMCategoryImage';
import { ENTRY_DRAFT, ENTRY_HIDDEN } from 'common/constant';
import { getJournalList, getUser, getUI, getFilters } from 'redux/selectors';
import { updateUI } from 'redux/actions/ui';
import { Avatar } from '@material-ui/core';
// images
import defaultAvatar from 'assets/images/default-user.jpg';
import { getActions } from 'common/utils/Entry/EntryTileHelpers';
import emotions from 'common/emotions';

// Icons
import iconEllipsisVert from 'assets/images/icons_svg/icon_ellipsis-vert.svg';
import iconEdit from 'assets/images/icons_svg/icon_edit.svg';
import { checkUserSame } from 'common/utils/resourceUtils';

const EntryHeader = ({
    ui,
    entry,
    filters,
    // @ts-ignore
    journals,
    dispatch,
    user,
    // @ts-ignore
    checkInvitationExists,
    // @ts-ignore
    public_users_list
}) => {
    const [entryDate, setEntryDate] = useState();
    const [entryModifiedDate, setEntryModifiedDate] = useState();
    const [sharedUser, setSharedUser] = useState(undefined);
    const categoryName = entry.question?.category?.name;
    const actions = getActions(dispatch, entry, user);

    useEffect(() => {
        moment.updateLocale('en', {
            relativeTime: {
                future: '%s',
                past: '%s',
                s: '1m',
                ss: '1m',
                m: '1m',
                mm: '%dm',
                h: '1h',
                hh: '%dh',
                d: '1d',
                dd: '%dd',
                M: '1mo',
                MM: '%dmo',
                y: '1y',
                yy: '%dy'
            }
        });
    }, []);
    useEffect(() => {
        const entry_date = entry?.entry_date && moment.utc(entry?.entry_date);
        const entry_modified = entry?.modified && moment.utc(entry?.modified);
        // @ts-ignore
        setEntryDate(entry_date);
        // @ts-ignore
        setEntryModifiedDate(entry_modified);
    }, [entry]);

    useEffect(() => {
        if (sharedUser === undefined) {
            const value =
                entry.user && checkUserSame(user, entry.user)
                    ? null
                    : entry.user;
            setSharedUser(value);
        }
    }, [user, entry, sharedUser]);

    const showDate = useCallback(
        (date) => {
            const df = entry.display_time
                ? 'MMM D, YYYY, h:mma'
                : 'MMM D, YYYY';
            return moment.isMoment(date) && date.local().format(df);
        },
        [entry.display_time]
    );

    const showAvatar = useCallback(() => {
        if (!categoryName) {
            if (sharedUser) {
                return sharedUser.avatar_image_url;
            }
            return user ? user.avatar_image_url : defaultAvatar;
        } else {
            return AAMCategoryImage(
                categoryName.toLowerCase().split(' ').join('-')
            );
        }
    }, [categoryName, user, sharedUser]);

    const getJournalName = useCallback(() => {
        let journals = [];

        if (entry.journal) {
            journals.push(entry.journal);
        }
        const filtered = entry?.extra_journals?.filter((item) => {
            return item?.id !== entry.journal?.id;
        });
        if (filtered?.length > 0) {
            journals = [...journals, ...filtered];
        }
        return `${journals[0].title} ${
            journals?.length === 1 ? '' : ` and ${journals?.length - 1} more.`
        }`;
    }, [entry]);

    const setEntriesToBulk = useCallback(() => {
        const entries = ui.bulkEntries;
        const index = entries.findIndex((item) => item.id === entry.id);
        if (index > -1) {
            entries.splice(index, 1);
        } else {
            entries.push(entry);
        }
        dispatch(
            updateUI({
                bulkEntries: entries
            })
        );
    }, [dispatch, ui.bulkEntries, entry]);

    const bulkClassName = useCallback(() => {
        return `fal fa-check bulk-select ${
            ui.bulkEntries?.findIndex((item) => item.id === entry.id) > -1
                ? 'active'
                : ''
        }`;
    }, [ui.bulkEntries, entry]);

    const date = showDate(entryDate);
    const modifiedDate = showDate(entryModifiedDate);

    // const displayName = useCallback(() => {
    //     if (sharedUser) {
    //         return sharedUser.short_public_display_name;
    //     }
    //     return user ? user.short_public_display_name : 'Undefined User';
    // }, [sharedUser, user]);

    const isEdited =
        Math.abs(
            moment(entry.created).diff(moment(entry.modified), 'minutes')
        ) > 2;
    const getSharedPersonName = useCallback(() => {
        return (
            user.id !== entry.user?.id &&
            `${entry.user?.public_display_name}  •  `
        );
    }, [entry.user, user.id]);

    const emoImgSrc = emotions.find((emotion) => emotion.id === entry.emotion)
        ?.img;

    const renderImageAndTitle = useCallback(() => {
        return (
            <>
                <div className="post-image">
                    {ui.bulkMode ? (
                        <span
                            className={bulkClassName()}
                            onClick={setEntriesToBulk}
                            style={
                                bulkClassName().includes('active')
                                    ? { color: '#0064ff' }
                                    : {}
                            }
                        />
                    ) : (
                        <Avatar alt="User Avatar" src={showAvatar()} />
                    )}
                    {entry.emotion && !ui.bulkMode && (
                        <div className="emoji-avatar-mood">
                            <img src={emoImgSrc} alt="hearts" />
                        </div>
                    )}
                </div>
                {categoryName && (
                    <div className={categoryName && 'category-post-wrapper'}>
                        {categoryName && (
                            <div className="category-title">
                                {entry.title}
                                {categoryName && (
                                    <div className="posted-date">
                                        <span className="date">{date}</span>
                                        <span className="separator-in">in</span>
                                        <div className="category-name truncate">
                                            {' '}
                                            <span>{categoryName}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
                {!categoryName && (
                    <div className="entry-title">
                        {!categoryName && (
                            <>
                                <div className="posted-date title-date-header">
                                    <Link
                                        className="date truncate"
                                        to={`/entry/${entry.id}`}>
                                        <span>{date}</span>
                                    </Link>
                                    {isEdited && false && (
                                        <button className="last-edited">
                                            (edited)
                                            <span className="last-edited__date">
                                                {modifiedDate}
                                            </span>
                                        </button>
                                    )}
                                </div>
                                <div className="journal-name truncate">
                                    <span>
                                        {getSharedPersonName()}
                                        {getJournalName()}
                                    </span>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </>
        );
    }, [
        getSharedPersonName,
        date,
        modifiedDate,
        entry.id,
        categoryName,
        entry.emotion,
        entry.title,
        getJournalName,
        showAvatar,
        bulkClassName,
        setEntriesToBulk,
        ui.bulkMode,
        isEdited,
        emoImgSrc
    ]);

    const canUserDeleteOrEdit = entry.user && checkUserSame(entry.user, user);

    const location = useLocation();
    const allAboutClassName = () => {
        return location.pathname.includes('answers') ? 'post—aam' : '';
    };

    const isFreeUserAndEntryIsNot_in_DefaultJournal = useCallback(() => {
        return (
            (_.findIndex(
                entry.extra_journals || [],
                (journal) => journal?.is_default === false
            ) >= 0 ||
                entry.journal.is_default === false) &&
            user.is_free
        );
    }, [user, entry]);

    useEffect(() => {}, [isFreeUserAndEntryIsNot_in_DefaultJournal]);
    return (
        <Fragment>
            {entry.status === ENTRY_DRAFT && (
                <div className="draft-badge">
                    <img src={iconEdit} alt="" className="draft-badge__icon" />
                    <div className="draft-badge__title">Saved as a Draft</div>
                </div>
            )}
            <div className={`posted-title-section ${allAboutClassName()}`}>
                {ui.bulkMode ? (
                    <span className="posted-title">
                        {renderImageAndTitle()}
                    </span>
                ) : (
                    <div className="posted-title">{renderImageAndTitle()}</div>
                )}
                <div className="extra-info">
                    <span
                        // @ts-ignore
                        title={entryDate?.local().format('MMM D, YYYY')}
                        className="days-passed mr-1">
                        {
                            // @ts-ignore
                            entryDate?.local().fromNow()
                        }
                    </span>
                    <div className="dropdown show" style={{ zIndex: 1 }}>
                        <DropdownMenu
                            anchor={
                                <img
                                    className="extra-info-btn btn-icon btn-icon--4"
                                    src={iconEllipsisVert}
                                    alt=""
                                />
                            }>
                            {canUserDeleteOrEdit &&
                                !isFreeUserAndEntryIsNot_in_DefaultJournal() && (
                                    <span>
                                        <Link to={`/entry/edit/${entry.id}`}>
                                            Edit Entry
                                        </Link>
                                    </span>
                                )}
                            {entry.state !== ENTRY_HIDDEN ? (
                                <span onClick={actions.hide}>Hide Entry</span>
                            ) : (
                                <span onClick={actions.show}>Show Entry</span>
                            )}
                            {canUserDeleteOrEdit && (
                                <span onClick={actions.move}>Move Entry</span>
                            )}
                            {canUserDeleteOrEdit && (
                                <span onClick={actions.copy}>Copy Entry</span>
                            )}
                            {canUserDeleteOrEdit && (
                                <span onClick={actions.delete}>
                                    Delete Entry
                                </span>
                            )}
                        </DropdownMenu>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

const state = createStructuredSelector({
    ui: getUI,
    journals: getJournalList,
    user: getUser,
    filters: getFilters
});

export default connect(state)(EntryHeader);
