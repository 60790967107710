import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
// redux
import { connect } from 'react-redux';
// Components
import 'react-datepicker/dist/react-datepicker.css';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
// Selectors
import { getUI } from 'redux/selectors';
import { createStructuredSelector } from 'reselect';
// Actions
import { updateUI, updateStepsFilledIn } from 'redux/actions/ui';
import * as EntryActions from 'redux/actions/entry';
import * as FilterActions from 'redux/actions/filter';
import {
    ENTRY_ALL,
    ENTRY_DATE,
    ENTRY_DO_NOT_PRINT,
    ENTRY_PUBLISHED,
    TIMEFRAME_STATUS_ERROR,
    TIMEFRAME_STATUS_LOADING,
    TIMEFRAME_STATUS_NONE,
    TIMEFRAME_STATUS_SUCCESS
} from 'common/constant';
// import Calendar from 'react-calendar';
import { CustomCalendar as Calendar } from 'components';
import 'react-calendar/dist/Calendar.css';
import { convertUTCTime } from 'common/utils/dateUtils';
// images
import loadingRing from 'assets/images/loading-ring.svg';
import { TIMEZONES } from 'common/timezones';
import { Selector } from 'components';

const EntriesStep = ({ display, dispatch, ui, initCall }) => {
    // handlers
    const [entryCount, setEntryCount] = useState(0);
    const [timeframeStatus, setTimeframeStatus] = useState(
        TIMEFRAME_STATUS_NONE
    );
    const [minDate, setMinDate] = useState(undefined);
    const [maxDate, setMaxDate] = useState(undefined);
    const [openMaxDateCalendar, setOpenMaxDateCalendar] = useState(false);
    const [openMinDateCalendar, setOpenMinDateCalendar] = useState(false);
    const [dateInfo, setDateInfo] = useState({
        hasWarning: false,
        message: ''
    });
    const startDateRef = useRef();
    const endDateRef = useRef();
    const {
        entryRange,
        book_extra_journals = [],
        book_tags = [],
        book_select_journal = true
    } = ui;
    const uiMinDate = React.useMemo(() => {
        return typeof ui.minDate === 'string' ? moment(ui.minDate) : ui.minDate;
    }, [ui.minDate]);
    const uiMaxDate = React.useMemo(() => {
        return typeof ui.maxDate === 'string' ? moment(ui.maxDate) : ui.maxDate;
    }, [ui.maxDate]);

    const updateStepsFilledIn_ES = useCallback(
        (val = false) => {
            dispatch(updateStepsFilledIn('EntriesStep', val));
        },
        [dispatch]
    );

    const initialRenderRef = useRef(true);
    useEffect(() => {
        if (initialRenderRef.current) {
            initialRenderRef.current = false;
            updateStepsFilledIn_ES(true);
            initCall();
        }
    }, [initCall, updateStepsFilledIn_ES]);

    const renderTimeframeStatus = useCallback(() => {
        switch (timeframeStatus) {
            case TIMEFRAME_STATUS_ERROR: {
                // ERROR
                return (
                    <p className="input-error-msg">
                        <i className="icon-error"></i>
                        There are no entries available with this timeframe.
                        Nothing will be printed.
                    </p>
                );
            }
            case TIMEFRAME_STATUS_LOADING: {
                // LOADING
                return (
                    <p className="input-loading-msg">
                        <img src={loadingRing} alt="loading-ring" />
                        Checking dates...
                    </p>
                );
            }
            case TIMEFRAME_STATUS_SUCCESS: {
                // SUCCESS
                return (
                    <p className="input-success-msg">
                        <i className="icon-check-circle"></i> {entryCount}{' '}
                        entries found
                    </p>
                );
            }
            default: {
                return;
            }
        }
    }, [timeframeStatus, entryCount]);

    const onChangeOption = useCallback(
        (value) => {
            if (value === ENTRY_ALL || value === ENTRY_DO_NOT_PRINT) {
                updateStepsFilledIn_ES(true);
            } else if (value === ENTRY_DATE) {
                updateStepsFilledIn_ES();
            }

            updateStepsFilledIn('EntriesStep', true);
            dispatch(
                updateUI({
                    entryRange: value,
                    canGoNext:
                        value === ENTRY_ALL || value === ENTRY_DO_NOT_PRINT
                })
            );
        },
        [dispatch, updateStepsFilledIn_ES]
    );

    useEffect(() => {
        setMinDate(uiMinDate);
        setMaxDate(uiMaxDate);
    }, [uiMinDate, uiMaxDate]);

    const onMinDateChanged = useCallback(
        (data) => {
            dispatch(
                updateUI({
                    minDate: data.minDate
                })
            );

            setMinDate(data.minDate);
            setOpenMinDateCalendar(false);
        },
        [dispatch]
    );

    const onMaxDateChanged = useCallback(
        ({ maxDate: date }) => {
            if (minDate < moment(date)) {
                dispatch(
                    updateUI({
                        maxDate: date
                    })
                );
                setMaxDate(date);
                setOpenMaxDateCalendar(false);
            } else {
                setDateInfo({
                    hasWarning: true,
                    message: 'Select a date that is greater than the start date'
                });
            }
        },
        [minDate, dispatch]
    );

    // hooks
    useEffect(() => {
        if (ui.entryRange !== ENTRY_DATE) {
            setOpenMinDateCalendar(false);
            setOpenMaxDateCalendar(false);
        }
        dateInfo.hasWarning &&
            setTimeout(
                () => setDateInfo({ hasWarning: false, message: '' }),
                3000
            );
    }, [ui.entryRange, dateInfo.hasWarning]);

    const onOpenFirstCalendar = useCallback(() => {
        dispatch(FilterActions.reset());
        ui.entryRange === ENTRY_DATE && setOpenMinDateCalendar(true);
        openMaxDateCalendar && setOpenMaxDateCalendar(false);
    }, [ui.entryRange, openMaxDateCalendar, dispatch]);

    const onOpenSecondCalendar = useCallback(() => {
        dispatch(FilterActions.reset());
        ui.entryRange === ENTRY_DATE && setOpenMaxDateCalendar(true);
        openMinDateCalendar && setOpenMinDateCalendar(false);
    }, [ui.entryRange, openMinDateCalendar, dispatch]);

    const handleOutsideClick = (e) => {
        const node_ = startDateRef.current || endDateRef.current || null;
        if (node_ && node_.contains(e.target)) {
            return;
        }
        setOpenMinDateCalendar(false);
        setOpenMaxDateCalendar(false);
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);
    // fetch entry count for selected optionn

    const initOptionsFetch = useCallback(() => {
        if (!display) {
            return;
        }
        if (
            entryRange === ENTRY_DATE &&
            uiMinDate &&
            uiMaxDate &&
            uiMinDate < uiMaxDate
        ) {
            // TODO: Fetch Entry count for selected options
            const filters = {};
            // add filter for journals/tags
            if (book_select_journal) {
                // filters['extra_journals'] = book_extra_journals
                //     .map((item) => item.id)
                //     .join(',');
                filters['by_journal'] = book_extra_journals
                    .map((item) => item.id)
                    .join(',');
            } else {
                filters['tags__in'] = book_tags
                    .map((item) => item.id)
                    .join(',');
            }

            // add filter for entry date
            filters['entry_date__gte'] = convertUTCTime(uiMinDate)
                .startOf('day')
                .format();
            filters['entry_date__lte'] = convertUTCTime(uiMaxDate)
                .endOf('day')
                .format();
            // exclude draft status
            filters['status'] = ENTRY_PUBLISHED;

            // fetch entries with filter options
            setTimeframeStatus(TIMEFRAME_STATUS_LOADING);
            dispatch(EntryActions.fetchEntriesWithOptions(filters)).then(
                (response) => {
                    if (response.payload.status === 200) {
                        const { meta: { total_count = 0 } = {} } =
                            response.payload.data || {};

                        if (total_count > 0) {
                            dispatch(updateUI({ canGoNext: true }));
                            setTimeframeStatus(TIMEFRAME_STATUS_SUCCESS);

                            updateStepsFilledIn_ES(true);
                            setEntryCount(total_count);
                        } else {
                            dispatch(updateUI({ canGoNext: false }));
                            setTimeframeStatus(TIMEFRAME_STATUS_ERROR);
                        }
                    }
                }
            );
        } else if (
            entryRange === ENTRY_ALL ||
            entryRange === ENTRY_DO_NOT_PRINT
        ) {
            dispatch(
                updateUI({
                    canGoNext: true
                })
            );
            setTimeframeStatus(TIMEFRAME_STATUS_NONE);
        } else {
            dispatch(
                updateUI({
                    canGoNext: false
                })
            );
        }
    }, [
        display,
        entryRange,
        uiMinDate,
        uiMaxDate,
        book_select_journal,
        book_tags,
        book_extra_journals,
        dispatch,
        updateStepsFilledIn_ES
    ]);
    useEffect(() => {
        initOptionsFetch();
    }, [initOptionsFetch]);

    // update timezone to ui reducer
    const onChangeTimezone = (e) => {
        dispatch(
            updateUI({
                timezone: e.target.value
            })
        );
    };

    return (
        <div
            className={`new-book-step-3 new-book-step ${
                display ? 'active-form' : ''
            }`}>
            <div className="section-title">Entries</div>
            <div className="new-book-step-form">
                <p>
                    Choose to have all entries included in your book OR select a
                    specific range of entries by beginning and end date.
                </p>
                <div className="entries-options">
                    <div className="form-group">
                        <div className="radio-item">
                            <input
                                onClick={() => onChangeOption(ENTRY_ALL)}
                                onChange={() => {}}
                                className="radio"
                                type="radio"
                                id="allEntries"
                                name="entries-options"
                                checked={ui.entryRange === ENTRY_ALL}
                            />
                            <label
                                onClick={() => onChangeOption(ENTRY_ALL)}
                                onChange={() => {}}
                                htmlFor="allEntries">
                                Print all journal entries
                                <span className="all-entries">
                                    ({ui.selected_entries_count || 0})
                                </span>
                            </label>
                        </div>
                        {ui?.selected_entries_count_fetching &&
                            (ui?.book_extra_journals.length > 0 ||
                                ui?.book_tags.length > 0) && (
                                <p className="input-loading-msg">
                                    <img src={loadingRing} alt="loading-ring" />
                                    Fetching entry count...
                                </p>
                            )}
                    </div>
                    <div className="form-group">
                        <div className="radio-item">
                            <input
                                onClick={() => onChangeOption(ENTRY_DATE)}
                                onChange={() => {}}
                                className="radio"
                                type="radio"
                                id="specificDate"
                                name="entries-options"
                                checked={ui.entryRange === ENTRY_DATE}
                            />
                            <label
                                onClick={() => onChangeOption(ENTRY_DATE)}
                                onChange={() => {}}
                                htmlFor="specificDate">
                                Print a specific date range (Select a date
                                range)
                            </label>
                        </div>
                    </div>
                    <div className="new-book-input-form mt-4">
                        <div className="form-group">
                            <div className="form-group__row">
                                <div className="form-group__item">
                                    <label htmlFor="date-picker-time-from">
                                        From
                                    </label>
                                    <div className="react-datepicker-wrapper">
                                        <div
                                            className="react-datepicker__input-container"
                                            onClick={() =>
                                                onOpenFirstCalendar()
                                            }>
                                            <input
                                                disabled={
                                                    ui.entryRange !== ENTRY_DATE
                                                }
                                                type="text"
                                                placeholder="March 25, 2020"
                                                className={`new-book-input ${
                                                    timeframeStatus ===
                                                    TIMEFRAME_STATUS_ERROR
                                                        ? 'new-book-input-error'
                                                        : ''
                                                }`}
                                                value={moment(
                                                    minDate || ''
                                                ).format('MMMM D, yyyy')}
                                            />
                                        </div>
                                    </div>
                                    {openMinDateCalendar && (
                                        <div ref={startDateRef}>
                                            <Calendar
                                                date={moment(ui.minDate)}
                                                onDateSelect={onMinDateChanged}
                                                mode="single"
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="form-group__item">
                                    <label htmlFor="date-picker-time-to">
                                        To
                                    </label>
                                    <div className="react-datepicker-wrapper">
                                        <div
                                            className="react-datepicker__input-container"
                                            onClick={() =>
                                                onOpenSecondCalendar()
                                            }>
                                            <input
                                                disabled={
                                                    ui.entryRange !== ENTRY_DATE
                                                }
                                                type="text"
                                                placeholder="March 25, 2020"
                                                className={`new-book-input ${
                                                    timeframeStatus ===
                                                    TIMEFRAME_STATUS_ERROR
                                                        ? 'new-book-input-error'
                                                        : ''
                                                }`}
                                                value={moment(
                                                    maxDate || ''
                                                ).format('MMMM D, yyyy')}
                                            />
                                        </div>
                                    </div>
                                    {openMaxDateCalendar && (
                                        <div ref={endDateRef}>
                                            <Calendar
                                                date={moment(ui.maxDate)}
                                                onDateSelect={onMaxDateChanged}
                                                mode="single"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            {renderTimeframeStatus()}
                            <div className="form-group">
                                <div className="form-group__item">
                                    <label htmlFor="date-picker-time-to">
                                        Timezone
                                    </label>
                                    <Selector
                                        value={ui.timezone || ''}
                                        options={TIMEZONES}
                                        style={{ maxWidth: '18.75rem' }}
                                        onChange={onChangeTimezone}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="radio-item">
                                    <input
                                        onClick={() =>
                                            onChangeOption(ENTRY_DO_NOT_PRINT)
                                        }
                                        onChange={() => {}}
                                        className="radio"
                                        type="radio"
                                        id="noEntries"
                                        name="entries-options"
                                        checked={
                                            ui.entryRange === ENTRY_DO_NOT_PRINT
                                        }
                                    />
                                    <label
                                        onClick={() =>
                                            onChangeOption(ENTRY_DO_NOT_PRINT)
                                        }
                                        htmlFor="noEntries">
                                        Do not print any journal entries
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                <Snackbar open={dateInfo.hasWarning}>
                    <Alert severity="warning">{dateInfo.message}</Alert>
                </Snackbar>
            }
        </div>
    );
};

const state = createStructuredSelector({
    ui: getUI
});
export default React.memo(connect(state)(EntriesStep));
