import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import countryList from 'react-select-country-list';

// other components
import Select from 'react-select';

// core components
import TextField from 'components/TextField';

// redux selectors
import { getUI } from 'redux/selectors';

// redux actions
import { updateUI } from 'redux/actions/ui';
// import * as BookActions from 'redux/actions/book';
import * as BookShippingAddressActions from 'redux/actions/bookshippingaddress';

// constant
import { DEFAULT_COUNTRY, STATES_LIST, PROVINCES_LIST } from 'common/constant';

// utils
import arrayUtils from 'common/utils/arrayUtils';
import Checkbox from '@material-ui/core/Checkbox';

// set United States Label to US
countryList().setLabel(DEFAULT_COUNTRY, DEFAULT_COUNTRY);

const ShippingAddress = ({
    ui,
    dispatch,
    book,
    onUpdateBookData,
    shipping,
    onSave
}) => {
    const [error, setError] = React.useState(false);
    const [addressError, setAddressError] = React.useState({});
    const [addressSubmitted, setAddressSubmitted] = React.useState(false);
    const [defaultAddressFlag, setDefaultAddressFlag] = React.useState(false);
    const [stateViewFlag, setStateViewFlag] = React.useState(true);
    const [provinceViewFlag, setProvinceViewFlag] = React.useState(false);
    const [stateFieldErrorFlag, setStateFieldErrorFlag] = React.useState(true);
    // const [bookShippingInfo, setBookShippingInfo] = React.useState({
    //     shipping_coast: null,
    //     total_amount: null
    // });
    const options = React.useMemo(() => countryList().getData(), []);

    const stateOptions = STATES_LIST.map((state) => {
        return { value: state, label: state };
    });

    const provinceOptions = PROVINCES_LIST.map((state) => {
        return { value: state, label: state };
    });

    const updateShippingAddress = React.useCallback(
        (e) => {
            const shipping = { ...ui.shipping };
            shipping[e.target.name] = e.target.value;
            setStateViewFlag(Boolean(e.target.value === DEFAULT_COUNTRY));
            setProvinceViewFlag(Boolean(e.target.value === 'Canada'));
            dispatch(
                updateUI({
                    shipping
                })
            );
        },
        [ui, dispatch]
    );

    useEffect(() => {
        if (!ui.shipping) {
            const shipping = {
                country: DEFAULT_COUNTRY
            };
            dispatch(
                updateUI({
                    shipping
                })
            );
        }
    }, [dispatch, ui.shipping]);

    React.useEffect(() => {
        setDefaultAddressFlag(Boolean(shipping?.default));
    }, [shipping]);

    const onAddressValidated = React.useCallback((name, error) => {
        setAddressError((prev) => ({ ...prev, [name]: error }));
    }, []);

    useEffect(() => {
        setStateViewFlag(Boolean(ui.shipping?.country === DEFAULT_COUNTRY));
        setProvinceViewFlag(Boolean(ui.shipping?.country === 'Canada'));
    }, [ui.shipping]);

    const updateBookData = React.useCallback(
        (shipping) => {
            onUpdateBookData && onUpdateBookData(shipping);
            // dispatch(BookActions.update(book)).then((response) => {
            //     if (response?.error) {
            //         setError(true);
            //     }
            //     if (response?.payload?.data) {
            //         // const {
            //         //     shipping_cost,
            //         //     total_amount
            //         // } = response.payload.data;

            //         // setBookShippingInfo((prev) => ({
            //         //     ...prev,
            //         //     total_amount,
            //         //     shipping_cost
            //         // }));

            //         // scroll down to checkout section
            //         onUpdateBookData && onUpdateBookData();
            //     }
            // });
        },
        [onUpdateBookData]
    );

    const onSaveAndCalculate = React.useCallback(() => {
        setAddressSubmitted(true);
        if (!arrayUtils.checkErrorObjValidated(addressError)) {
            return;
        }
        if (ui.shipping.country === DEFAULT_COUNTRY && !ui.shipping.state) {
            setStateFieldErrorFlag(true);
            return;
        }

        const errorHandler = (response) => {
            const validation_errors =
                response?.error?.response?.data?.validation_errors;

            if (validation_errors) {
                Object.keys(validation_errors).forEach((name) => {
                    setAddressError((prev) => ({
                        ...prev,
                        [name]: validation_errors[name][0]
                    }));
                });
            }
        };

        ui.shipping['default'] = Boolean(defaultAddressFlag);

        dispatch(
            ui.shipping?.id
                ? BookShippingAddressActions.update(ui.shipping)
                : BookShippingAddressActions.create(ui.shipping)
        ).then((response) => {
            if (response?.payload?.status === 201) {
                updateBookData(response.payload.data);
            }
            onSave && onSave(false);
        }, errorHandler);
    }, [
        dispatch,
        ui,
        updateBookData,
        addressError,
        defaultAddressFlag,
        onSave
    ]);

    React.useEffect(() => {
        error && setTimeout(() => setError(false), 3000);
    }, [error]);

    const _onChangeDefaultAddressChecked = useCallback(() => {
        setDefaultAddressFlag(!defaultAddressFlag);
    }, [defaultAddressFlag]);

    function AddressStateSelect() {
        if (stateViewFlag) {
            return (
                <div className="form-group__item">
                    <label className="custom-label" htmlFor="state-options">
                        State
                    </label>

                    <Select
                        options={stateOptions}
                        value={{
                            value: ui.shipping?.state || '',
                            label: ui.shipping?.state || ''
                        }}
                        onChange={(state) => {
                            updateShippingAddress({
                                target: {
                                    value: state.label,
                                    name: 'state'
                                }
                            });
                            setStateFieldErrorFlag(false);
                        }}
                    />
                    {addressSubmitted && stateFieldErrorFlag && (
                        <p className="input-error-msg">
                            This field is required
                        </p>
                    )}
                </div>
            );
        } else if (provinceViewFlag) {
            return (
                <div className="form-group__item">
                    <label className="custom-label" htmlFor="state-options">
                        Province
                    </label>

                    <Select
                        options={provinceOptions}
                        value={{
                            value: ui.shipping?.state || '',
                            label: ui.shipping?.state || ''
                        }}
                        onChange={(state) => {
                            updateShippingAddress({
                                target: {
                                    value: state.label,
                                    name: 'state'
                                }
                            });
                            setStateFieldErrorFlag(false);
                        }}
                    />
                    {addressSubmitted && stateFieldErrorFlag && (
                        <p className="input-error-msg">
                            This field is required
                        </p>
                    )}
                </div>
            );
        } else {
            return null;
        }
    }

    return (
        <div className="checkout-user-info">
            <div className="settings-list-title">Shipping Address</div>

            <div className="form-group contact-info-section">
                <div className="form-group__row">
                    <div className="form-group__item">
                        <TextField
                            label="Name"
                            value={ui.shipping?.first_name || ''}
                            className="new-book-input"
                            name="first_name"
                            placeholder=""
                            onChange={updateShippingAddress}
                            required={true}
                            submitted={addressSubmitted}
                            onValidated={onAddressValidated}
                        />
                    </div>
                    <div className="form-group__item">
                        <TextField
                            label="Surname"
                            value={ui.shipping?.last_name || ''}
                            className="new-book-input"
                            name="last_name"
                            placeholder=""
                            onChange={updateShippingAddress}
                            required={true}
                            submitted={addressSubmitted}
                            onValidated={onAddressValidated}
                        />
                    </div>
                </div>

                <div className="form-group__row address-custom">
                    <div className="form-group__item">
                        <TextField
                            label="Address 1"
                            value={ui.shipping?.street || ''}
                            className="new-book-input"
                            name="street"
                            placeholder=""
                            onChange={updateShippingAddress}
                            required={true}
                            submitted={addressSubmitted}
                            onValidated={onAddressValidated}
                        />
                    </div>
                </div>

                <div className="form-group__row address-custom">
                    <div className="form-group__item">
                        <TextField
                            label="Address 2"
                            value={ui.shipping?.street2 || ''}
                            className="new-book-input"
                            name="street2"
                            placeholder=""
                            onChange={updateShippingAddress}
                        />
                    </div>
                </div>
                <div className="form-group__row">
                    <div className="form-group__item">
                        <TextField
                            label="City"
                            value={ui.shipping?.city || ''}
                            className="new-book-input"
                            name="city"
                            placeholder=""
                            onChange={updateShippingAddress}
                            required={true}
                            submitted={addressSubmitted}
                            onValidated={onAddressValidated}
                        />
                    </div>

                    <AddressStateSelect />
                </div>
                <div className="form-group__row">
                    <div className="form-group__item">
                        <TextField
                            label="ZIP CODE"
                            value={ui.shipping?.zip_code || ''}
                            className="new-book-input"
                            name="zip_code"
                            placeholder=""
                            onChange={updateShippingAddress}
                            required={true}
                            submitted={addressSubmitted}
                            //validator={Zipcode}
                            onValidated={onAddressValidated}
                            error={addressError['zip_code']}
                        />
                    </div>
                    <div className="form-group__item">
                        <label
                            className="custom-label"
                            htmlFor="country-options"
                            style={{ marginBottom: '7px' }}>
                            Country
                        </label>
                        <Select
                            options={options}
                            value={{
                                value: countryList().getValue(
                                    ui.shipping?.country || DEFAULT_COUNTRY
                                ),
                                label: ui.shipping?.country || DEFAULT_COUNTRY
                            }}
                            onChange={(country) =>
                                updateShippingAddress({
                                    target: {
                                        value: country.label,
                                        name: 'country'
                                    }
                                })
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="checkout__checkbox">
                <Checkbox
                    checked={defaultAddressFlag}
                    onChange={_onChangeDefaultAddressChecked}
                />
                Make it my default address
            </div>
            <button onClick={onSaveAndCalculate} className="btn btn-gradient">
                Save and Continue
            </button>
        </div>
    );
};

const state = createStructuredSelector({
    ui: getUI
});
export default connect(state)(ShippingAddress);
