import React from 'react';

// images
import deleteImage from 'assets/images/delete-policy.jpg';

const RemoveMyDataPage = () => {
    return (
        <main id="main" className="app-main">
            <div className="page-container">
                <div className="page page--data-deletion">
                    <div className="page__header page__header--search">
                        <div className="page__title">
                            JRNL Data Deletion Policy
                        </div>
                    </div>
                    <div className="data-deletion__content type">
                        <img
                            className="data-deletion__image"
                            src={deleteImage}
                            alt=""
                        />
                        <p>We entirely respect that your data is your own.</p>
                        <p>
                            To delete what you want, and to receive a copy of
                            what you need, please contact:
                        </p>
                        <a
                            href="mailto:support@jrnl.com"
                            className="data-deletion-link btn-primary-link">
                            support@jrnl.com
                        </a>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default RemoveMyDataPage;
