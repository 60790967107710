/**
 * Add util functions here to check if same instance object of DB on backend
 */
import extractIdFromResourceUri from 'common/utils/extractIdFromResourceUri';

/**
 * check user
 */
export const checkUserSame = (user1, user2) => {
    const userId1 =
        typeof user1 === 'string'
            ? extractIdFromResourceUri('public_user', user1) ||
              extractIdFromResourceUri('user', user1)
            : user1?.id;
    const userId2 =
        typeof user2 === 'string'
            ? extractIdFromResourceUri('public_user', user2) ||
              extractIdFromResourceUri('user', user2)
            : user2?.id;

    return parseInt(userId1) === parseInt(userId2);
};
