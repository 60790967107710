import _ from 'underscore';
import * as actionTypes from 'redux/actions/actionTypes';
import { SUCCESS_SUFFIX, ERROR_SUFFIX } from 'common/constant';
import arrayUtils from 'common/utils/arrayUtils';

const initialState = {
    isPending: false,
    instagram_access_token: undefined,
    instagram_authorization_url: undefined,
    instagram_media: undefined,
    instagram_media_meta: undefined,
    import_filter_value: 'show_all',
    selected_instagram_media: undefined,
    search_query: undefined
};

const InstagramReducer = (state = initialState, action) => {
    switch (action.type) {
        // instagram fetch profile
        case actionTypes.INSTAGRAM_FETCH_PROFILE: {
            return _.extend({}, state, {
                isPending: true
            });
        }

        case actionTypes.INSTAGRAM_FETCH_PROFILE + SUCCESS_SUFFIX: {
            return _.extend({}, state, {
                isPending: false,
                profile: action.payload.data
            });
        }

        case actionTypes.INSTAGRAM_FETCH_PROFILE + ERROR_SUFFIX: {
            return _.extend({}, state, {
                isPending: false
            });
        }

        // instagram fetch media
        case actionTypes.INSTAGRAM_FETCH_MEDIA: {
            if (action.payload?.data?.clear) {
                return _.extend({}, state, {
                    isPending: true,
                    instagram_media: []
                });
            } else {
                return _.extend({}, state, {
                    isPending: true
                });
            }
        }

        case actionTypes.INSTAGRAM_CONNECT: {
            return _.extend({}, state, {
                isPending: true
            });
        }

        case actionTypes.INSTAGRAM_CONNECT + SUCCESS_SUFFIX:
        case actionTypes.INSTAGRAM_CONNECT + ERROR_SUFFIX: {
            return _.extend({}, state, {
                isPending: false
            });
        }

        case actionTypes.INSTAGRAM_FETCH_MEDIA + SUCCESS_SUFFIX: {
            if (
                action.payload.data &&
                action.payload.data.error === undefined
            ) {
                let instagram_media = state.instagram_media || [];

                const medias = arrayUtils.flatArray(
                    action.payload.data.media,
                    'children',
                    (obj) => obj.data
                );
                for (let i = 0; i < medias.length; i++) {
                    const media = medias[i];
                    if (media.media_url.includes('https://video')) {
                        continue;
                    }

                    const index = _.findIndex(
                        instagram_media,
                        (obj) => obj.id === media.id
                    );
                    if (index >= 0) {
                        instagram_media = [].concat(
                            instagram_media.slice(0, index),
                            [media],
                            instagram_media.slice(index + 1)
                        );
                    } else {
                        instagram_media = [].concat(instagram_media, [media]);
                    }
                }
                return _.extend({}, state, {
                    isPending: false,
                    instagram_media,
                    paging: action.payload.data.media.paging
                });
            } else {
                return _.extend({}, state);
            }
        }

        case actionTypes.INSTAGRAM_FETCH_MEDIA + ERROR_SUFFIX: {
            return _.extend({}, state, {
                isPending: false
            });
        }

        case actionTypes.FETCH_INSTAGRAM_ACCESS_TOKEN + SUCCESS_SUFFIX: {
            return _.extend({}, state, {
                instagram_access_token: action.payload.data.objects[0],
                isPending: false
            });
        }

        case actionTypes.FETCH_INSTAGRAM_ACCESS_TOKEN: {
            return _.extend({}, state, {
                isPending: true
            });
        }

        case actionTypes.FETCH_INSTAGRAM_ACCESS_TOKEN + ERROR_SUFFIX: {
            return _.extend({}, state, {
                isPending: false
            });
        }

        case actionTypes.FETCH_INSTAGRAM_AUTHORIZATION_URL + SUCCESS_SUFFIX: {
            return _.extend({}, state, {
                instagram_authorization_url: action.payload.data.objects[0]
            });
        }

        case actionTypes.AUTHORIZE_INSTAGRAM_ACCESS_TOKEN + SUCCESS_SUFFIX:
        case actionTypes.UPDATE_INSTAGRAM_ACCESS_TOKEN + SUCCESS_SUFFIX: {
            return _.extend({}, state, {
                instagram_access_token: action.payload.data
            });
        }

        case actionTypes.FETCH_INSTAGRAM_MEDIA_LIST + SUCCESS_SUFFIX: {
            let instagram_media = state.instagram_media;
            let _i,
                _len,
                _ref = action.payload.data.objects;

            for (_i = 0, _len = _ref.length; _i < _len; _i++) {
                const object = _ref[_i];
                const index = _.findIndex(
                    instagram_media,
                    (media) => object.id === media.id
                );

                if (index >= 0) {
                    instagram_media = [].concat(
                        instagram_media.slice(0, index),
                        [object],
                        instagram_media.slice(index + 1)
                    );
                } else {
                    instagram_media = [].concat(
                        [object],
                        instagram_media || []
                    );
                }
            }
            return _.extend({}, state, {
                instagram_media,
                instagram_media_meta: action.payload.data.meta
            });
        }
        case actionTypes.UPDATE_SELECTED_INSTAGRAM_MEDIA: {
            let selected_instagram_media;
            if (action.payload.data.checked) {
                selected_instagram_media = state.selected_instagram_media || [];
                selected_instagram_media.push(action.payload.data.media);
            } else {
                selected_instagram_media = state.selected_instagram_media.filter(
                    (media) => media.id !== action.payload.data.media.id
                );
            }

            return _.extend({}, state, {
                selected_instagram_media
            });
        }

        case actionTypes.UPDATE_IMPORT_FILTER_VALUE: {
            return _.extend({}, state, {
                import_filter_value: action.payload.data
            });
        }

        case actionTypes.BULK_SELECT_INSTAGRAM_MEDIA: {
            return _.extend({}, state, {
                selected_instagram_media: action.payload.data
            });
        }

        case actionTypes.UPDATE_SEARCH_QUERY: {
            return _.extend({}, state, {
                search_query: action.payload.data
            });
        }

        case actionTypes.SET_INSTAGRAM_LOADINGPROGRESS: {
            return _.extend({}, state, {
                loading: true,
                isPending: undefined
            });
        }

        case actionTypes.CLEAR_LOADINGPROGRESS: {
            return _.extend({}, state, {
                loading: false
            });
        }

        case actionTypes.REMOVE_INSTAGRAM_ACCESS_TOKEN: {
            return _.extend({}, initialState);
        }

        case actionTypes.ACTION_USER_LOGOUT: {
            return _.extend({}, initialState);
        }
        default: {
            return state;
        }
    }
};
export default InstagramReducer;
