import { PLAN_LABELS } from 'common/constant';
import { URLS } from 'common/urls';
import React from 'react';
import { Link } from 'react-router-dom';

const FreeAccountPermissionDialog = ({ close }) => {
    return (
        <div id="upgrade-pro-modal" className="modal-frame">
            <div className="modal-box modal-box--600">
                <div className="modal__header">
                    Upgrade to {PLAN_LABELS.PRO}
                </div>
                <div className="modal__body">
                    <p className="upgrade-pro-desc">
                        This action is only available to {PLAN_LABELS.PRO}s.
                    </p>
                    <p className="upgrade-pro-desc">
                        With {PLAN_LABELS.PRO}, you can create unlimited
                        journals, upload up to 1TB of storage, create shared
                        entries and journals, automatically import content from
                        your social media networks, and more.
                    </p>
                    <p className="upgrade-pro-desc">
                        To continue, please upgrade your account.
                    </p>
                </div>
                <div className="modal__footer">
                    <button onClick={close} className="btn btn-outline">
                        Cancel
                    </button>
                    <Link to={URLS.SETTINGS.ACCOUNT}>
                        <button onClick={close} className="btn btn-gradient">
                            Learn More
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default FreeAccountPermissionDialog;
