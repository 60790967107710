import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import DefaultUser from 'assets/images/default-user.jpg';
import { SOCIAL_TYPE_FACEBOOK, SOCIAL_TYPE_INSTAGRAM } from 'common/constant';
import { URLS } from 'common/urls';
import { DropDown } from 'components';
import AddTagUser from 'components/media/addTagUser';
import AddImageUpload from 'components/media/AddImageUpload';
import AddMediaUpload from 'components/media/AddMediaUpload';
import withSocialType from 'common/utils/components/withSocialType';

import { updateUI } from 'redux/actions/ui';
import * as EntryMediaActions from 'redux/actions/entrymedia';
import { getUser } from 'redux/selectors';
import { getTaggedUsersOnlyList } from 'redux/selectors/TaggedUserSelector';

import SocialMediaPhotosDialog from 'pages/Dialogs/SocialMedia/SocialMediaPhotosDialog';

const NewMediaButton = ({ dispatch }) => {
    const uploadImageFromDevice = useCallback(() => {
        dispatch(
            updateUI({
                dialogs: [AddImageUpload]
            })
        );
    }, [dispatch]);

    const uploadMediaFromDevice = useCallback(() => {
        dispatch(
            updateUI({
                dialogs: [AddMediaUpload]
            })
        );
    }, [dispatch]);

    const uploadInstagramMedia = useCallback(() => {
        dispatch(
            updateUI({
                dialogs: [
                    withSocialType(
                        SocialMediaPhotosDialog,
                        SOCIAL_TYPE_INSTAGRAM,
                        true
                    )
                ]
            })
        );
    }, [dispatch]);

    const uploadFacebookMedia = useCallback(() => {
        dispatch(
            updateUI({
                dialogs: [
                    withSocialType(
                        SocialMediaPhotosDialog,
                        SOCIAL_TYPE_FACEBOOK,
                        true
                    )
                ]
            })
        );
    }, [dispatch]);

    return (
        <ul className="media-new-button">
            <DropDown
                dropDownMenuProps={{
                    className: 'dropdown-menu--media',
                    // @ts-ignore
                    'aria-labelledby': 'mediaUpload',
                    style: { height: 'auto' }
                }}
                classes={{
                    dropdown_list: 'media-dropdown-list'
                }}
                anchor={
                    <span
                        className="btn btn-new-media dropdown-toggle"
                        role="button"
                        id="mediaUpload"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                        <span className="fa fa-plus-circle"></span>
                        New Media
                    </span>
                }>
                <li onClick={uploadImageFromDevice}>
                    <span className="fa fa-camera" />
                    Upload images from your device
                </li>
                <li className="dropdown-menu__divider">
                    <hr />
                </li>
                <li onClick={uploadMediaFromDevice}>
                    <span className="fa fa-upload" />
                    Upload media from your device
                </li>
                <li>
                    <span className="icon-google-drive" />
                    Upload from Google Drive
                </li>
                <li className="dropdown-menu__divider">
                    <hr />
                </li>
                <li onClick={uploadInstagramMedia}>
                    <span className="fa fa-instagram" />
                    Upload from Instagram
                </li>
                <li onClick={uploadFacebookMedia}>
                    <span className="fa fa-facebook" />
                    Upload from Facebook
                </li>
            </DropDown>
        </ul>
    );
};

const UserMediaNavbar = ({
    count,
    dispatch,
    onTagUser,
    selectUser,
    setSelectUser
}) => {
    const back = useCallback(() => {
        setSelectUser(undefined);
        dispatch(EntryMediaActions.reset());
    }, [dispatch, setSelectUser]);

    return (
        <div className="col-12 media-navbar media-navbar-user">
            <div className="media-user" style={{ marginLeft: 0 }}>
                <img
                    src={
                        selectUser
                            ? selectUser.public_avatar_image_url
                            : DefaultUser
                    }
                    alt="avatar"
                    className="circle_image"
                    style={{ marginLeft: 0 }}
                />
                <div className="media-user-content">
                    {selectUser ? (
                        <div className="media-user-info">
                            <p className="media-user-name">
                                {selectUser.short_public_display_name}
                            </p>
                            <Link to={URLS.CONTACTS.ROOT}>
                                <div className="media-redirect">
                                    <span className="fa fa-users"></span>
                                    &nbsp;&nbsp; View in Contacts
                                </div>
                            </Link>
                        </div>
                    ) : (
                        <div className="media-user-info">
                            <p className="media-user-name unknown-user-name">
                                Unknown Person
                            </p>
                            <div
                                className="btn btn-add-to-entry"
                                onClick={onTagUser}>
                                <span className="icon-add-circle-filled"></span>
                                Tag User
                            </div>
                        </div>
                    )}
                    {count && (
                        <p className="media-user-collection">{count} images</p>
                    )}
                </div>
            </div>
            <button className="btn btn-back-home" onClick={() => back()}>
                <span
                    className="icon-arrow-left"
                    style={{ fontSize: '12px' }}></span>
                Back to Home
            </button>
        </div>
    );
};

const TagUser = ({ clickUser, user, is_me }) => {
    return (
        <li onClick={() => clickUser(user)}>
            <div
                className="MuiAvatar-root MuiAvatar-circle"
                style={{
                    width: '48px',
                    height: '48px'
                }}>
                <img
                    src={
                        user.public_avatar_image_url
                            ? user.public_avatar_image_url
                            : DefaultUser
                    }
                    alt="avatar"
                />
            </div>
            <p className="media-contact-name">
                {is_me ? 'You' : user?.username || ''}
            </p>
        </li>
    );
};

const MediaNavbar = ({ tagged_users_only, dispatch, user }) => {
    const [selectUser, setSelectUser] = React.useState(undefined);
    const [openTagUser, setOpenTagUser] = React.useState(false);
    const [mediaCount, setMediaCount] = React.useState(null);

    const onTagUser = () => {
        setOpenTagUser(true);
    };

    const clickUser = useCallback(
        (user) => {
            setSelectUser(user);
            setMediaCount(null);
            dispatch(
                EntryMediaActions.filter({
                    emftaggeduser__user_id: user.id
                })
            ).then((r) => {
                setMediaCount(r?.payload?.data?.count || 0);
            });
        },
        [dispatch]
    );

    const taggedUsers = (tagged_users_only || []).map((contact, index) => (
        <TagUser
            clickUser={clickUser}
            key={index}
            user={contact}
            is_me={false}
        />
    ));

    return (
        <>
            <section className="mb-5">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        {selectUser ? (
                            <UserMediaNavbar
                                count={mediaCount}
                                dispatch={dispatch}
                                onTagUser={onTagUser}
                                selectUser={selectUser}
                                setSelectUser={setSelectUser}
                            />
                        ) : (
                            <div className="col-12 media-navbar media-navbar-fade">
                                <ul className="media-contacts">
                                    <TagUser
                                        clickUser={clickUser}
                                        user={user}
                                        is_me={true}
                                    />
                                    {taggedUsers}
                                </ul>
                                <NewMediaButton dispatch={dispatch} />
                            </div>
                        )}
                    </div>
                </div>
            </section>
            {selectUser && (
                <AddTagUser
                    open={openTagUser}
                    onClose={() => setOpenTagUser(false)}
                />
            )}
        </>
    );
};

const state = createStructuredSelector({
    tagged_users_only: getTaggedUsersOnlyList,
    user: getUser
});

export default connect(state)(MediaNavbar);
