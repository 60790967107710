import React, { useCallback, useEffect, useMemo } from 'react';
import _ from 'underscore';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
    getDefaultJournal,
    getJournalList,
    getUI,
    getUser
} from 'redux/selectors';
import { JOURNAL_AAM } from 'common/constant';

// Icons
import iconJournal from 'assets/images/icons_svg/icon_journal.svg';

const JournalDropdownList = ({
    test,
    user,
    dispatch,
    onUpdate,
    ui,
    entry,
    defaultJournal,
    showJournalList,
    setShowJournalList,
    extraJournals,
    setExtraJournals,
    ...rest
}) => {
    const defaultJournalResourceUri = useMemo(
        () => defaultJournal?.resource_uri,
        [defaultJournal]
    );
    const journalList = useMemo(() => {
        if (user.is_free) {
            return rest.journals?.journals?.filter(
                (journal) => journal.journal_type === 'Default'
            );
        } else {
            return rest.journals?.journals;
        }
    }, [rest, user.is_free]);

    useEffect(() => {
        // set defaultJournal to the first place
        if (
            journalList &&
            journalList[0] !== defaultJournal &&
            defaultJournal
        ) {
            journalList.splice(
                journalList.findIndex((e) => e.id === defaultJournal.id),
                1
            );
            journalList.unshift(defaultJournal);
        }
    }, [dispatch, defaultJournalResourceUri, defaultJournal, journalList]);

    const onSelect = useCallback(
        (journal) => {
            const alreadySelected = (extraJournals || []).find(
                (extraJournal) => extraJournal.id === journal.id
            );
            if (alreadySelected && extraJournals.length === 1) {
                return;
            }

            if (onUpdate) {
                const entry = {
                    journal: journal.resource_uri
                };
                onUpdate(entry, journal);
            }

            // let newExtraJournals = [...(extraJournals || [])];
            // if (alreadySelected) {
            //     newExtraJournals = extraJournals.filter(
            //         (extraJournal) => extraJournal.id !== journal.id
            //     );
            // } else {
            //     newExtraJournals.push(journal);
            // }
            const newExtraJournals = [journal];

            setExtraJournals(newExtraJournals);
        },
        [onUpdate, extraJournals, setExtraJournals]
    );

    const renderJournalListItem = useCallback(
        (journal) => {
            const exists =
                _.findIndex(
                    extraJournals || [],
                    (extraJournal) => extraJournal?.id === journal?.id
                ) >= 0;
            const className = exists ? 'active-item' : '';

            return (
                <li
                    key={journal?.id}
                    onClick={() => {
                        onSelect(journal);
                    }}
                    className={className}>
                    <img src={iconJournal} alt="Journal" />
                    {journal?.title}
                </li>
            );
        },
        [onSelect, extraJournals]
    );

    return (
        <div className="dropdown">
            <div
                className={`dropdown-menu journals-dropdown-menu ${
                    showJournalList && 'show'
                }`}>
                <ul className="dropdown-journals-list">
                    {journalList &&
                        journalList
                            .filter(
                                (journal) =>
                                    journal.journal_type !== JOURNAL_AAM
                            )
                            .map((journal) => {
                                return renderJournalListItem(journal);
                            })}
                </ul>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    user: getUser,
    journals: getJournalList,
    ui: getUI,
    defaultJournal: getDefaultJournal
});

export default connect(state)(JournalDropdownList);
