import React, { useState, useEffect, useCallback } from 'react';
import { getUser } from 'redux/selectors';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Avatar } from '@material-ui/core';

import * as UserActions from 'redux/actions/user';
import {
    USER_PROFILE,
    USER_SHARING_STATUS,
    USER_SETTINGS
} from 'common/constant';
import MediaManagerDialog from 'pages/Dialogs/MediaManagerDialog';
import { useLocation } from 'react-router';
import { URLS } from 'common/urls';
import { openDialog } from 'common/utils/dialog-utils';

const ProfileTabPane = ({ user, dispatch }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUserName] = useState('');
    const [sharing, setSharing] = useState(user.sharing);
    const [showName, setShowName] = useState(user.show_name);
    const [showAvatar, setShowAvatar] = useState(user.show_avatar);
    const [error, setError] = useState({});

    useEffect(() => {
        setFirstName(user.first_name);
        setLastName(user.last_name);
        setEmail(user.email);
        setUserName(user.username);
    }, [user.first_name, user.last_name, user.email, user.username]);

    const changeFirstName = useCallback((e) => {
        setFirstName(e.target.value);
    }, []);

    const changeLastName = useCallback((e) => {
        setLastName(e.target.value);
    }, []);

    const changeEmail = useCallback((e) => {
        setEmail(e.target.value);
    }, []);

    const changeUserName = useCallback((e) => {
        setUserName(e.target.value);
        setError((prev) => ({ ...prev, username: undefined }));
    }, []);

    const sharingChange = useCallback(
        (e) => {
            dispatch(
                UserActions.update(
                    {
                        sharing: !sharing
                    },
                    USER_SHARING_STATUS
                )
            );
            setSharing((prev) => !prev);
        },
        [dispatch, sharing]
    );

    const showNameChange = useCallback(
        (e) => {
            dispatch(
                UserActions.update(
                    {
                        show_name: !showName
                    },
                    USER_SETTINGS
                )
            );
            setShowName((prev) => !prev);
        },
        [dispatch, showName]
    );

    const showAvatarChange = useCallback(
        (e) => {
            dispatch(
                UserActions.update(
                    {
                        show_avatar: !showAvatar
                    },
                    USER_SETTINGS
                )
            );
            setShowAvatar((prev) => !prev);
        },
        [dispatch, showAvatar]
    );

    const blurFirstName = useCallback(() => {
        if (firstName !== user.first_name) {
            dispatch(
                UserActions.update(
                    {
                        first_name: firstName
                    },
                    USER_PROFILE
                )
            );
        }
    }, [firstName, user.first_name, dispatch]);

    const blurLastName = useCallback(() => {
        if (lastName !== user.last_name) {
            dispatch(
                UserActions.update(
                    {
                        last_name: lastName
                    },
                    USER_PROFILE
                )
            );
        }
    }, [lastName, user.last_name, dispatch]);

    const blurUserName = useCallback(() => {
        if (username !== user.username) {
            dispatch(
                UserActions.update({
                    username
                })
            )
                .then((response) => {
                    if (response.payload.status !== 202) {
                        setError((prev) => ({ ...prev, username: true }));
                    }
                })
                .catch((response) => {
                    setError((prev) => ({ ...prev, username: true }));
                });
        }
    }, [username, user.username, dispatch]);

    const changeAvatar = useCallback(() => {
        openDialog(MediaManagerDialog, { mode: 'avatar' });
    }, []);

    const location = useLocation();
    const activeClassName =
        location.pathname === URLS.SETTINGS.PROFILE ||
        location.pathname === URLS.SETTINGS.ROOT
            ? 'active show'
            : '';
    return (
        <div
            className={`tab-pane fade profile-settings ${activeClassName}`}
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab">
            <div className="personal-information-settings-form">
                <h3 className="settings-title">Personal Information</h3>
                <div className="avatar-settings" onClick={changeAvatar}>
                    <Avatar alt="User Avatar" src={user.avatar_image_url} />

                    <label
                        htmlFor="file"
                        className="btn avatar-settings__btn btn-outline">
                        Change Avatar
                    </label>
                </div>
                <div className="form-group settings-inputs">
                    <div className="form-group__row">
                        <div className="form-group__item form-group__item--6">
                            <label className="settings-label" htmlFor="">
                                First Name
                            </label>
                            <input
                                className="settings-input"
                                value={firstName}
                                type="text"
                                placeholder="John"
                                onChange={changeFirstName}
                                onBlur={blurFirstName}
                            />
                        </div>
                        <div className="form-group__item form-group__item--6">
                            <label className="settings-label" htmlFor="">
                                Last Name
                            </label>
                            <input
                                className="settings-input"
                                value={lastName}
                                type="text"
                                placeholder="Miller"
                                onChange={changeLastName}
                                onBlur={blurLastName}
                            />
                        </div>
                    </div>
                    <div className="form-group__row">
                        <div className="form-group__item form-group__item--6">
                            <label className="settings-label" htmlFor="">
                                Username
                            </label>
                            <input
                                className="settings-input"
                                value={username}
                                type="text"
                                placeholder="test123"
                                onChange={changeUserName}
                                onBlur={blurUserName}
                            />
                            {error['username'] && (
                                <p className="input-error-msg">
                                    <i className="icon-error"></i>
                                    This UserName already exists.
                                </p>
                            )}
                        </div>
                        <div className="form-group__item form-group__item--6">
                            <label className="settings-label" htmlFor="">
                                Email
                            </label>
                            <input
                                className="settings-input"
                                value={email}
                                type="text"
                                placeholder="john.miller@example.com"
                                disabled={true}
                                onChange={changeEmail}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="sharing-privacy-settings">
                <h3 className="settings-title">Sharing and Privacy Settings</h3>
                <p>
                    {`To share journals or entries with other JRNL members, make
                    sure the "Enable Sharing" option is toggled to ON. If you
                    accept an invitation, sharing will be enabled by default.`}
                </p>
                <div className="list">
                    <div className="list__item">
                        <div className="list__content">
                            <div className="list__title">Enable Sharing</div>
                        </div>
                        <div className="toggle">
                            <input
                                type="checkbox"
                                id="enableSharing"
                                onChange={sharingChange}
                                defaultChecked={sharing}
                            />
                            <label
                                className="settings-toggle-desc"
                                htmlFor="enableSharing"></label>
                        </div>
                    </div>
                    <div className="list__item">
                        <div className="list__content">
                            <div className="list__title">Display My Name</div>
                        </div>
                        <div className="toggle">
                            <input
                                type="checkbox"
                                id="displayName"
                                onChange={showNameChange}
                                defaultChecked={showName}
                            />
                            <label
                                className="settings-toggle-desc"
                                htmlFor="displayName"
                            />
                        </div>
                    </div>
                    <div className="list__item">
                        <div className="list__content">
                            <div className="list__title">Display My Avatar</div>
                        </div>
                        <div className="toggle">
                            <input
                                type="checkbox"
                                id="displayAvatar"
                                onChange={showAvatarChange}
                                defaultChecked={showAvatar}
                            />
                            <label
                                className="settings-toggle-desc"
                                htmlFor="displayAvatar"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    user: getUser
});

export default connect(state)(ProfileTabPane);
