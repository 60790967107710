import axios from 'axios';
import {
    ERROR_SUFFIX,
    FACEBOOK_CONNECT_API_V2_URL,
    FACEBOOK_LOGIN_API_V2_URL,
    FACEBOOK_MEDIA_API_V2_URL,
    SUCCESS_SUFFIX
} from 'common/constant';
import buildV2Authorization from 'common/utils/buildV2Authorization';
import { getCookie } from 'common/utils/cookieUtils';
import {
    FACEBOOK_CONNECT,
    FACEBOOK_FETCH_MEDIA,
    FACEBOOK_LOGIN
} from 'redux/actions/actionTypes';
import { UserSelector } from 'redux/selectors/UserSelector';

export const connectFacebook = (token) => (dispatch, getState) => {
    const url = FACEBOOK_CONNECT_API_V2_URL;
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);

    return dispatch({
        type: FACEBOOK_CONNECT,
        payload: {
            request: {
                url,
                method: 'POST',
                data: {
                    access_token: token
                },
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const fetchFacebookMedia = () => (dispatch, getState) => {
    const url = FACEBOOK_MEDIA_API_V2_URL;
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);

    return dispatch({
        type: FACEBOOK_FETCH_MEDIA,
        payload: {
            data: {
                clear: true
            },
            request: {
                url,
                method: 'GET',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const fetchFacebookNextMedia = (pageUrl) => (dispatch, getState) => {
    dispatch({
        type: FACEBOOK_FETCH_MEDIA
    });

    axios
        .get(pageUrl)
        .then((response) => {
            dispatch({
                type: FACEBOOK_FETCH_MEDIA + SUCCESS_SUFFIX,
                payload: {
                    data: {
                        media: response.data
                    }
                }
            });
        })
        .catch((err) => {
            dispatch({
                type: FACEBOOK_FETCH_MEDIA + ERROR_SUFFIX
            });
        });
};

export const loginFacebook = (token) => (dispatch) => {
    const url = FACEBOOK_LOGIN_API_V2_URL;
    const csrftoken = getCookie('csrftoken');

    return dispatch({
        type: FACEBOOK_LOGIN,
        payload: {
            request: {
                url,
                method: 'POST',
                data: {
                    access_token: token
                },
                headers: {
                    'X-CSRFToken': csrftoken
                }
            }
        }
    });
};
