import React from 'react';

const RadioListItem = ({ order: { label, ...orderProps }, onClick }) => {
    return (
        <div className="form-group">
            <div
                className="radio-item"
                onClick={() => onClick(orderProps.value)}>
                <input
                    className="radio"
                    onChange={() => {}}
                    type="radio"
                    {...orderProps}
                />
                <label htmlFor="bookPdfDownload">{label}</label>
            </div>
        </div>
    );
};

const RadioList = ({ radios, onUpdate }) => {
    return (
        <React.Fragment>
            {radios.map((order, index) => (
                <RadioListItem
                    key={`order-list-item-${index}`}
                    order={order}
                    onClick={onUpdate}
                />
            ))}
        </React.Fragment>
    );
};

export default RadioList;
