import React from 'react';

import Dropzone from 'components/MediaManager/Dropzone';

export default (props) => {
    return (
        <div className="modal__body">
            <form className="box">
                <Dropzone {...props} />
            </form>
            <div className="photo-grid" id="previews"></div>
        </div>
    );
};
