import { combineForms } from 'react-redux-form';

const initialLogin = {
    email_username: '',
    password: '',
};
const initialSignUp = {
    email: '',
    username: '',
    password: '',
};

const formReducer = combineForms({
    login: initialLogin,
    signup: initialSignUp,
});

export default formReducer;