import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABSEURL
};

firebase.initializeApp(config);

export const firebase_console = firebase;
export const db = firebase.database();
export let uid = '';

// constants
export const FIREBASE_DB_ROOT = '/notification/';

export const updateNotification = (url, data) => {
    db.ref(url).set(data);
};

export const updateFirebaseUserUID = (userId) => {
    uid = userId;
};
