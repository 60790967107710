import React, { Fragment, useCallback } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// Hooks
import { useHistory } from 'react-router';
// Selectors
import { getUser } from 'redux/selectors';
// firebase
import { db, FIREBASE_DB_ROOT, uid } from 'common/firebase';
// images
import defaultUser from 'assets/images/default-user.jpg';
import { Avatar } from '@material-ui/core';
import moment from 'moment';
import { getDifferenceDateStr } from 'common/utils/dateUtils';

const EntryCommentNotification = ({
    notificationKey,
    data,
    user: authUser,
    fullView
}) => {
    const { user, entry_id, read, created_at } = data;
    const history = useHistory();

    // show entry
    const showEntry = useCallback(
        (add = true) => {
            // update the notification read
            db.ref(
                `${FIREBASE_DB_ROOT}${uid}/${authUser.id}/${notificationKey}`
            ).set({
                ...data,
                read: true
            });
            // navigate to show entry page
            history.push(`/entry/${entry_id}/?showComments=true`);
        },
        [history, entry_id, authUser, data, notificationKey]
    );

    return (
        <Fragment>
            <li
                className="alert-card__item alert-card__item--no-buttons"
                onClick={() => showEntry(false)}>
                <div className="alert-card__img">
                    <Avatar
                        src={user?.avatar_image_url || defaultUser}
                        alt=""
                    />
                </div>
                <div className="alert-card__content">
                    <span className="alert-card__name">
                        {user?.public_display_name}{' '}
                    </span>
                    has commented on your entry
                </div>
                {!read && (
                    <div className="notification-active-bullet-dropdown"></div>
                )}
                {fullView && created_at && (
                    <p className="notification-timestamp ml-auto">
                        {getDifferenceDateStr(
                            moment.utc(created_at).local(),
                            moment(new Date())
                        )}
                    </p>
                )}
            </li>
        </Fragment>
    );
};

const state = createStructuredSelector({
    user: getUser
});
export default connect(state)(EntryCommentNotification);
