import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SenderEmailDialog from 'pages/Dialogs/SettingsDialog/SenderEmailDialog';
import { createStructuredSelector } from 'reselect';
import { getJournalEmails, getSenderEmails, getUser } from 'redux/selectors';
import * as JournalEmailActions from 'redux/actions/journalEmail';
import DeleteDialog from 'components/DeleteDialog';
import { openDialog, closeDialog } from 'common/utils/dialog-utils';

// icons
import iconAddSm from 'assets/images/icons_svg/icon_add-sm.svg';
import iconCopy from 'assets/images/icons_svg/icon_copy.svg';
import iconEdit from 'assets/images/icons_svg/icon_edit.svg';
import iconDelete from 'assets/images/icons_svg/icon_delete.svg';
import withFreeAccountCheck from 'common/utils/components/withFreeAccountCheck';
import { URLS } from 'common/urls';
import { useLocation } from 'react-router-dom';
import JournalEmailDialog from 'pages/Dialogs/SettingsDialog/JournalEmailDialog';
import { checkUserSame } from 'common/utils/resourceUtils';

const EmailToJRNLPane = ({
    dispatch,
    journalEmails: propsJournalEmails,
    senderEmails,
    user
}) => {
    const [isFetched, setIsFetched] = useState(false);
    const [copyed, setCopyed] = useState(false);
    const location = useLocation();
    useEffect(() => {
        if (!isFetched) {
            dispatch(JournalEmailActions.fetch_journal_emails());
            dispatch(JournalEmailActions.fetch_authorised_emails());
            setIsFetched(true);
        }
    }, [dispatch, isFetched]);

    const openJournalEmailDialog = useCallback(() => {
        openDialog(JournalEmailDialog);
        // openDialog(withFreeAccountCheck(JournalEmailDialog));
    }, []);

    const openSenderEmailDialog = useCallback(() => {
        openDialog(SenderEmailDialog);
        // openDialog(withFreeAccountCheck(SenderEmailDialog));
    }, []);

    const editSenderEmail = useCallback((sender, index) => {
        openDialog(withFreeAccountCheck(SenderEmailDialog), {
            sender
        });
    }, []);

    const journalEmails = React.useMemo(() => {
        return propsJournalEmails.sort((a, b) =>
            a.journal?.title
                .toLowerCase()
                .localeCompare(b.journal?.title.toLowerCase())
        );
    }, [propsJournalEmails]);

    const deleteSender = useCallback(
        (sender) => {
            dispatch(JournalEmailActions.delete_sender_email(sender.id));
            closeDialog();
        },
        [dispatch]
    );
    const deleteJournalEmail = useCallback(
        (id) => {
            dispatch(JournalEmailActions.delete_journal_email(id));
            closeDialog();
        },
        [dispatch]
    );

    const openDeleteSenderDialog = useCallback(
        (email) => {
            openDialog(DeleteDialog, {
                title: 'Delete Sender?',
                text: 'You are about to delete this sender. Are you sure?',
                onDelete: () => deleteSender(email)
            });
        },
        [deleteSender]
    );

    const openDeleteJournalEmailDialog = useCallback(
        (id) => {
            openDialog(DeleteDialog, {
                title: 'Delete Journal Email?',
                text:
                    'You are about to delete this journal email. Are you sure?',
                onDelete: () => deleteJournalEmail(id)
            });
        },
        [deleteJournalEmail]
    );

    const copyAddress = useCallback((email) => {
        setCopyed(true);
        navigator.clipboard.writeText(email);
    }, []);

    const renderJournalItem = useCallback(
        (email, index) => {
            return (
                <li className="list__item" key={`${index}_${email.email}`}>
                    <div className="list__content">
                        <div className="list__title">
                            {email?.journal?.title}
                        </div>
                        <div className="list__subtitle">{email?.address}</div>
                    </div>
                    <div className="list__actions d-flex">
                        <div
                            className="copy-to-clipboard position-relative"
                            onMouseLeave={() => {
                                copyed && setCopyed(false);
                            }}>
                            <button
                                className="list__button btn-icon btn-icon--6 copy-button"
                                onClick={() => copyAddress(email.address)}>
                                <img
                                    src={iconCopy}
                                    aria-labelledby={iconCopy}
                                    alt="Copy"
                                />
                            </button>
                            <span className="copy-span">
                                {copyed ? 'Copied' : 'Copy to Clipboard'}
                            </span>
                        </div>
                        <button
                            className="list__button btn-icon btn-icon--6"
                            onClick={() => {
                                openDeleteJournalEmailDialog(email?.id);
                            }}>
                            <img
                                src={iconDelete}
                                aria-labelledby={iconDelete}
                                alt="Delete"
                            />
                        </button>
                    </div>
                </li>
            );
        },
        [copyAddress, copyed, openDeleteJournalEmailDialog]
    );

    const activeClassName =
        location.pathname === URLS.SETTINGS.EMAILJRNL ? 'active show' : '';

    const checkMyEmails = (email) => {
        return (
            !email || !email.journal || checkUserSame(email.journal.user, user)
        );
    };

    const myEmailJRNLs = journalEmails.filter((email) => checkMyEmails(email));

    const sharedJRNLs = journalEmails.filter((email) => !checkMyEmails(email));

    return (
        <div
            className={`tab-pane fade emailJRNL-settings ${activeClassName}`}
            id="emailJRNL"
            role="tabpanel"
            aria-labelledby="emailJRNL-tab">
            <div className="journals-email-section">
                <h3 className="settings-title">
                    Journals and Their Email Address
                </h3>
                <p>
                    Every journal has its own email address. If you send any
                    email to a journal’s email address, it will create a new
                    entry in that journal.
                </p>
                <ul className="list">
                    {myEmailJRNLs?.map((email, index) =>
                        renderJournalItem(email, index)
                    )}
                    {sharedJRNLs && sharedJRNLs.length > 0 && (
                        <h3 className="journals-section-title">
                            Shared With Me
                        </h3>
                    )}
                    {sharedJRNLs?.map((email, index) =>
                        renderJournalItem(email, index)
                    )}
                </ul>
                <button
                    className="btn btn-address btn-gradient"
                    onClick={openJournalEmailDialog}>
                    Change an address
                    <img
                        className="right"
                        src={iconAddSm}
                        aria-labelledby={iconAddSm}
                        alt="Add"
                    />
                </button>
            </div>
            <h3 className="settings-title">Authorized Email Senders</h3>
            <p>
                We will only accept emails that come from the following email
                addresses.
            </p>
            <div className="list authorised-emails-section">
                <ul className="list">
                    {senderEmails?.map((email, index) => (
                        <li className="list__item" key={index + email.email}>
                            <div className="list__content">
                                <div className="list__title truncate">
                                    {email.email}
                                </div>
                            </div>
                            <div className="list__actions">
                                <button
                                    className="list__button btn-icon btn-icon--6"
                                    onClick={() =>
                                        editSenderEmail(email, index)
                                    }>
                                    <img
                                        src={iconEdit}
                                        aria-labelledby={iconEdit}
                                        alt="Edit"
                                    />
                                </button>
                                <button
                                    className="list__button btn-icon btn-icon--6"
                                    onClick={() =>
                                        openDeleteSenderDialog(email)
                                    }>
                                    <img
                                        src={iconDelete}
                                        aria-labelledby={iconDelete}
                                        alt="Delete"
                                    />
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>
                <button
                    className="btn btn-sender btn-gradient"
                    onClick={openSenderEmailDialog}>
                    Add a sender
                    <img
                        className="right"
                        src={iconAddSm}
                        aria-labelledby={iconAddSm}
                        alt="Add"
                    />
                </button>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    journalEmails: getJournalEmails,
    senderEmails: getSenderEmails,
    user: getUser
});

export default connect(state)(EmailToJRNLPane);
