import React from 'react';
import { getUser } from 'redux/selectors/UserSelector';
import { connect } from 'react-redux';
import AuthHeader from 'components/Header/AuthHeader';
import NotAuthHeader from 'components/Header/NonAuthHeader';

const Header = ({ user }) => {
    return (
        <div>
            {user.isAuthorized === true ? <AuthHeader /> : <NotAuthHeader />}
        </div>
    );
};

const states = (state) => ({
    user: getUser(state)
});
export default connect(states)(Header);
