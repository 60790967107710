import {
    ACTION_UI_UPDATE,
    UPDATE_STEPS_FILLED_IN
} from 'redux/actions/actionTypes';

export const updateUI = (data = {}, replace = true) => ({
    type: ACTION_UI_UPDATE,
    payload: data,
    replace
});

export const updateStepsFilledIn = (stepKey = '', value = false) => ({
    type: UPDATE_STEPS_FILLED_IN,
    payload: { stepKey, value }
});
