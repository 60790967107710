import React, { Fragment, useCallback } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// Constants
import {
    FACEBOOK_O_AUTHORIZATION_URL,
    SOCIAL_TYPE_FACEBOOK
} from 'common/constant';
// Selectors
import { getUser } from 'redux/selectors';
// Icons
import iconCloseSm from 'assets/images/icons_svg/icon_close-sm.svg';
import iconFacebook from 'assets/images/icons_svg/icon_social-facebook.svg';
import { socialConnect } from 'common/utils/social-connect-utils';

const FacebookContent = ({ dispatch, user, close }) => {
    const connectAccount = useCallback(() => {
        const titleURL = `${FACEBOOK_O_AUTHORIZATION_URL}&state={"provider": "${SOCIAL_TYPE_FACEBOOK}", "userid": ${user.id}}`;
        // In popup window, will call this function to connect
        window.location.connectSocialConnect = socialConnect;
        window.open(titleURL);

        close();
    }, [user, close]);

    return (
        <Fragment>
            <div className="modal-box modal__box--dropzone modal-box--800">
                <div className="modal__header">
                    Add Photos from Facebook
                    <img
                        className="modal__close"
                        onClick={close}
                        src={iconCloseSm}
                        alt=""
                    />
                </div>
                <div className="modal__body">
                    <div className="social-media-connection">
                        <div className="social-icon social-icon--lg facebook-bg">
                            <img src={iconFacebook} alt="Facebook" />
                        </div>
                        <p>
                            You haven&#39;t connected your Facebook account yet.
                            Connect your account to upload your Facebook photos
                            to&nbsp;JRNL.
                        </p>
                        <button
                            onClick={connectAccount}
                            className="btn btn-gradient"
                            id="social-media-connect">
                            Connect Account
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

const state = createStructuredSelector({
    user: getUser
});

export default connect(state)(FacebookContent);
