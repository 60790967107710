import {
    Modifier,
    EditorState,
    RichUtils,
    AtomicBlockUtils,
    SelectionState,
    genKey,
    ContentBlock,
    CharacterMetadata
} from 'addon-draft-js';
import { List, Repeat } from 'immutable';
import getCurrentlySelectedBlock from 'components/Editor/utils/getCurrentlySelectedBlock';

export const ALIGNMENTS = {
    CENTER: 'center',
    JUSTIFY: 'justify',
    LEFT: 'left',
    RIGHT: 'right'
};

export const ALIGNMENT_DATA_KEY = 'textAlignment';
export const INDENT_DATA_KEY = 'textIndent';

const ExtendedRichUtils = Object.assign({}, RichUtils, {
    getLinkFromSelection(editorState) {
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            const contentState = editorState.getCurrentContent();
            const anchorKey = selection.getAnchorKey();
            const currentContentBlock = contentState.getBlockForKey(anchorKey);
            const startKey = selection.getStartKey();
            const startOffset = selection.getStartOffset();
            const endOffset = selection.getEndOffset();
            const blockWithLinkAtBeginning = contentState.getBlockForKey(
                startKey
            );
            const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

            let url = '';
            if (linkKey) {
                const linkInstance = contentState.getEntity(linkKey);
                url = linkInstance.getData().url;
            }

            return {
                text: currentContentBlock
                    .getText()
                    .slice(startOffset, endOffset),
                url
            };
        }
    },

    toggleInsertLink(editorState, linkobj) {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            'LINK',
            'MUTABLE',
            { url: linkobj.url }
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, {
            currentContent: contentStateWithEntity
        });
        return RichUtils.toggleLink(
            newEditorState,
            newEditorState.getSelection(),
            entityKey
        );
    },

    toggleImageDelete(contentState, block) {
        const newBlockMap = contentState.blockMap.delete(block.getKey());
        const newContentState = contentState.set('blockMap', newBlockMap);
        const newEditorState = EditorState.createWithContent(newContentState);
        return newEditorState;
    },

    toggleImageContentChange(contentState, block, newImageContent) {
        var entityKey = block.getEntityAt(0);
        if (!entityKey) {
            return;
        }
        const newContentState = contentState.mergeEntityData(entityKey, {
            caption: newImageContent
        });
        return EditorState.createWithContent(newContentState);
    },

    // Largely copied from RichUtils' `toggleBlockType`
    toggleAlignment(editorState, alignment, automatic) {
        const {
            content,
            currentBlock,
            hasAtomicBlock,
            target
        } = getCurrentlySelectedBlock(editorState);

        if (hasAtomicBlock) {
            return editorState;
        }

        const blockData = currentBlock.getData();
        const alignmentToSet =
            blockData && blockData.get(ALIGNMENT_DATA_KEY) === alignment
                ? undefined
                : alignment;

        if (automatic && currentBlock.getText().length > 0) {
            return {
                editorState
            };
        }
        return {
            alignment: alignmentToSet,
            editorState: EditorState.push(
                editorState,
                Modifier.mergeBlockData(content, target, {
                    [ALIGNMENT_DATA_KEY]: alignmentToSet
                }),
                'change-block-data'
            )
        };
    },

    toggleIndent(editorState, offset) {
        const {
            content,
            currentBlock,
            hasAtomicBlock,
            target
        } = getCurrentlySelectedBlock(editorState);

        if (hasAtomicBlock) {
            return editorState;
        }

        const blockData = currentBlock.getData();
        if (blockData.get(INDENT_DATA_KEY)) {
            offset = offset + parseInt(blockData.get(INDENT_DATA_KEY));
        }
        if (offset < 0) {
            offset = 0;
        }
        return EditorState.push(
            editorState,
            Modifier.mergeBlockData(content, target, {
                [INDENT_DATA_KEY]: offset
            }),
            'change-block-data'
        );
    },

    toggleFont(editorState, font) {
        if (ExtendedRichUtils.checkSelection(editorState)) {
            return RichUtils.toggleInlineStyle(editorState, font);
        }
        return editorState;
    },

    checkSelection(editorState) {
        const selection = editorState.getSelection();
        const startOffset = selection.getStartOffset();
        const endOffset = selection.getEndOffset();
        const { currentBlock } = getCurrentlySelectedBlock(editorState);

        if (endOffset - startOffset <= currentBlock.getLength()) {
            return true;
        }
        return false;
    },

    toggleAddLink(editorState, linkText, link) {
        const selectionState = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const currentBlock = contentState.getBlockForKey(
            selectionState.getStartKey()
        );
        const currentBlockKey = currentBlock.getKey();
        const blockMap = contentState.getBlockMap();
        const blocksBefore = blockMap
            .toSeq()
            .takeUntil((v) => v === currentBlock);
        const blocksAfter = blockMap
            .toSeq()
            .skipUntil((v) => v === currentBlock)
            .rest();
        const newBlockKey = genKey();

        // add new ContentBlock to editor state with appropriate text
        // @ts-ignore
        const newBlock = new ContentBlock({
            key: newBlockKey,
            type: 'unstyled',
            text: linkText,
            characterList: List(
                Repeat(CharacterMetadata.create(), linkText.length)
            )
        });

        const newBlockMap = blocksBefore
            .concat(
                [
                    [currentBlockKey, currentBlock],
                    [newBlockKey, newBlock]
                ],
                blocksAfter
            )
            .toOrderedMap();

        const selection = editorState.getSelection();

        const newContent = contentState.merge({
            blockMap: newBlockMap,
            selectionBefore: selection,
            selectionAfter: selection.merge({
                anchorKey: newBlockKey,
                anchorOffset: 0,
                focusKey: newBlockKey,
                focusOffset: 0,
                isBackward: false
            })
        });

        let newEditorState = EditorState.push(
            editorState,
            newContent,
            'split-block'
        );

        // programmatically apply selection on this text
        // @ts-ignore
        let newSelection = new SelectionState({
            anchorKey: newBlockKey,
            anchorOffset: 0,
            focusKey: newBlockKey,
            focusOffset: linkText.length
        });

        newEditorState = EditorState.forceSelection(
            newEditorState,
            newSelection
        );

        // create link entity
        const newContentState = newEditorState.getCurrentContent();

        const contentStateWithEntity = newContentState.createEntity(
            'LINK',
            'IMMUTABLE',

            {
                url: link,
                target: '_blank',
                className: 'link-attached not-clicked'
            }
        );

        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        newEditorState = EditorState.set(newEditorState, {
            currentContent: contentStateWithEntity
        });

        newEditorState = RichUtils.toggleLink(
            newEditorState,
            newEditorState.getSelection(),
            entityKey
        );

        // reset selection
        // @ts-ignore
        newSelection = new SelectionState({
            anchorKey: newBlockKey,
            anchorOffset: linkText.length,
            focusKey: newBlockKey,
            focusOffset: linkText.length
        });

        newEditorState = EditorState.forceSelection(
            newEditorState,
            newSelection
        );
        return newEditorState;
    },

    getEntities(editorState, entityType = null) {
        const content = editorState.getCurrentContent();
        const entities = [];
        content.getBlocksAsArray().forEach((block) => {
            let selectedEntity = null;
            block.findEntityRanges(
                (character) => {
                    if (character.getEntity() !== null) {
                        const entity = content.getEntity(character.getEntity());
                        if (
                            !entityType ||
                            (entityType && entity.getType() === entityType)
                        ) {
                            selectedEntity = {
                                entityKey: character.getEntity(),
                                blockKey: block.getKey(),
                                entity: content.getEntity(character.getEntity())
                            };
                            return true;
                        }
                    }
                    return false;
                },
                (start, end) => {
                    entities.push({ ...selectedEntity, start, end });
                }
            );
        });
        return entities;
    },

    getEntityFromKey(editorState, key) {
        const entities = this.getEntities(editorState);
        const entity = entities && entities[key];
        const content = editorState.getCurrentContent();
        if (entity) {
            return content.getEntity(entity.entityKey);
        }
        return null;
    },

    getTextAlignmentOfSelection(editorState) {
        const { currentBlock, hasAtomicBlock } = getCurrentlySelectedBlock(
            editorState
        );
        if (hasAtomicBlock) {
            return null;
        }
        const blockData = currentBlock.getData();

        return blockData.get(ALIGNMENT_DATA_KEY);
    },

    getFontFamilyOfSelection(editorState) {
        const styles = editorState.getCurrentInlineStyle();
        const key = 'font-';
        const fontFamily = styles
            .filter((value) => value?.startsWith(key))
            .last();

        return fontFamily;
    },

    insertImageBlock(editorState, data) {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            'IMAGE',
            'IMMUTABLE',
            {
                width: 100,
                ...data
            }
        );

        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

        const newEditorState = EditorState.set(editorState, {
            currentContent: contentStateWithEntity
        });
        return AtomicBlockUtils.insertAtomicBlock(
            newEditorState,
            entityKey,
            ' '
        );
    },

    insertMediaBlock(editorState, data, type) {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            type,
            'IMMUTABLE',
            data
        );

        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

        const newEditorState = EditorState.set(editorState, {
            currentContent: contentStateWithEntity
        });
        return AtomicBlockUtils.insertAtomicBlock(
            newEditorState,
            entityKey,
            ' '
        );
    },

    /*
     * An extension of the default split block functionality, originally pulled from
     * https://github.com/facebook/draft-js/blob/master/src/component/handlers/edit/commands/keyCommandInsertNewline.js
     *
     * This version ensures that the text alignment is copied from the previously selected block.
     */
    splitBlock(editorState) {
        // Original split logic
        const contentState = Modifier.splitBlock(
            editorState.getCurrentContent(),
            editorState.getSelection()
        );
        const splitState = EditorState.push(
            editorState,
            contentState,
            'split-block'
        );

        // Assign alignment if previous block has alignment. Note that `currentBlock` is the block that was selected
        // before the split.
        const { currentBlock } = getCurrentlySelectedBlock(editorState);
        const alignment = currentBlock.getData().get(ALIGNMENT_DATA_KEY);
        if (alignment) {
            return ExtendedRichUtils.toggleAlignment(splitState, alignment);
        } else {
            return splitState;
        }
    }
});

export default ExtendedRichUtils;
