import _ from 'underscore';

import { createSelector } from 'reselect';
import { JOURNAL_AAM } from 'common/constant';
import { checkUserSame } from 'common/utils/resourceUtils';

export const JournalSelector = (state) => state.journal;

export const DefaultJournalSelector = (state) => {
    const { journal, user } = state;

    return (journal.journals || []).find(
        (journal) => journal.is_default && checkUserSame(journal.user, user)
    );
};

export const AamJournalSelector = (state) =>
    _.findWhere(state.journal.journals, {
        journal_type: JOURNAL_AAM
    });

export const JournalForEntrySelector = (state, props) => {
    let entry;
    if (props.match?.params && props.match?.params.entryId) {
        const { entries } = state.entry;

        entry = _.findWhere(entries, {
            id: parseInt(props.match?.params.entryId)
        });
    } else {
        entry = props.entry;
    }

    const { journals } = state.journal;

    let entryJournal = undefined;
    if (entry) {
        entryJournal = _.find(
            journals,
            (journal) =>
                entry.journal === journal.resource_uri ||
                entry.journal === journal.id
        );
    }

    if (entryJournal) {
        return entryJournal;
    } else {
        return {
            title: 'Shared Journal'
        };
    }
};

export const JournalForBookSelector = (state, props) => {
    if (props.match?.params && props.match?.params.bookId) {
        const { books } = state.book;
        const { journals } = state.journal;

        const book = _.findWhere(books, {
            id: parseInt(props.match?.params.bookId)
        });

        let bookJournal = undefined;
        if (book) {
            bookJournal = _.find(
                journals,
                (journal) =>
                    book.journal === journal.resource_uri ||
                    book.journal === journal.id
            );
        }

        return bookJournal;
    }
    return undefined;
};

export const getJournalList = createSelector([JournalSelector], (journal) => {
    //state.journals = _.sortBy(state.journals, (journal) => journal?.title?.toLowerCase());
    return _.extend({}, journal);
});

export const getDefaultJournal = createSelector(
    [DefaultJournalSelector],
    (default_journal) => default_journal
);

export const getJournalForEntry = createSelector(
    [JournalForEntrySelector],
    (journal) => _.extend({}, journal)
);

export const getAamJournal = createSelector(
    [AamJournalSelector],
    (aam_journal) => aam_journal
);

export const getJournalForBook = createSelector(
    [JournalForBookSelector],
    (journal) => journal
);

export const getHiddenJournal = createSelector([JournalSelector], (journal) => {
    const journals = _.filter(journal.journals, {
        state: 100
    });

    return journals;
});
