import _ from 'underscore';
import {
    FETCH_BOOK_SHIPPING_ADDRESS_LIST,
    CREATE_BOOK_SHIPPING_ADDRESS_LIST,
    UPDATE_BOOK_SHIPPING_ADDRESS_LIST,
    ACTION_USER_LOGOUT,
    UPDATE_BOOK_SHIPPING_ADDRESS
} from 'redux/actions/actionTypes';
import { SUCCESS_SUFFIX } from 'common/constant';

const initialState = {
    shippingAddresses: undefined,
    meta: undefined
};

const BookShippingAddressReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BOOK_SHIPPING_ADDRESS_LIST + SUCCESS_SUFFIX: {
            let shippingAddresses = state.shippingAddresses;

            action.payload.data.objects.forEach((object) => {
                const index = _.findIndex(
                    shippingAddresses,
                    (shippingAddress) => object.id === shippingAddress.id
                );

                if (index >= 0) {
                    shippingAddresses = [].concat(
                        shippingAddresses.slice(0, index),
                        [object],
                        shippingAddresses.slice(index + 1)
                    );
                } else {
                    shippingAddresses = [].concat(
                        [object],
                        shippingAddresses || []
                    );
                }
            });

            return _.extend({}, state, {
                shippingAddresses,
                meta: action.payload?.data.meta
            });
        }

        case UPDATE_BOOK_SHIPPING_ADDRESS + SUCCESS_SUFFIX: {
            const shippingAddresses = state.shippingAddresses.filter(
                (shippingAddress) =>
                    shippingAddress.id !== action.payload.config.options.id
            );

            return _.extend({}, state, {
                shippingAddresses
            });
        }
        case CREATE_BOOK_SHIPPING_ADDRESS_LIST + SUCCESS_SUFFIX:
        case UPDATE_BOOK_SHIPPING_ADDRESS_LIST + SUCCESS_SUFFIX: {
            let shippingAddresses = state.shippingAddresses;
            const object = action.payload.data;

            const index = _.findIndex(
                shippingAddresses,
                (shippingAddress) => object.id === shippingAddress.id
            );

            if (index >= 0) {
                shippingAddresses = [].concat(
                    shippingAddresses.slice(0, index),
                    [object],
                    shippingAddresses.slice(index + 1)
                );
            } else {
                shippingAddresses = [].concat(
                    [object],
                    shippingAddresses || []
                );
            }

            return _.extend({}, state, {
                shippingAddresses
            });
        }

        case ACTION_USER_LOGOUT: {
            return _.extend({}, initialState);
        }

        default: {
            break;
        }
    }

    return state;
};

export default BookShippingAddressReducer;
