import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getCalendar, getUI } from 'redux/selectors';
import { updateUI } from 'redux/actions/ui';

// Components
import Dialog from '@material-ui/core/Dialog';
import { Calendar } from 'components';

import { MenuItem, Select } from '@material-ui/core';
import { useLocation } from 'react-router';
import { URLS } from 'common/urls';

const DateTimeDialog = ({
    entry,
    onUpdate,
    close,
    ui,
    open,
    calendar,
    dispatch
}) => {
    const [date, setDate] = useState(null);
    const [hours, setHours] = useState(null);
    const [minutes, setMinutes] = useState(null);
    const [meridian, setMeridian] = useState(null);
    const [displayTime, setDisplayTime] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const entryDate = entry?.entry_date || null;
        const _date = entryDate
            ? moment.utc(entryDate).local()
            : moment().local();
        setDate(_date);
        setHours(_date.format('h'));
        setMinutes(_date.format('mm'));
        setMeridian(_date.format('a'));
        setDisplayTime((entry && entry).display_time);
    }, [entry]);

    const _close = useCallback(() => {
        if (close) {
            close();
        }
    }, [close]);

    const done = useCallback(() => {
        const time = `${hours}:${minutes} ${meridian}`;
        const dateString = `${date.format('YYYY-MM-DD')}T${time}`;
        const entryDate = moment(dateString, 'YYYY-MM-DDTH:mm a')
            .local()
            .format('YYYY-MM-DDTHH:mm:ss');

        const lastDate = location.pathname.includes(URLS.ENTRY.EDIT)
            ? entryDate
            : entry.entry_date;

        const newEntry = {
            ...entry,
            entry_date: entryDate,
            last_date: lastDate,
            display_time: displayTime,
            dateChanged: dateString !== entry.entry_date
        };
        onUpdate(newEntry);
        _close();
    }, [
        _close,
        hours,
        minutes,
        meridian,
        date,
        entry,
        onUpdate,
        displayTime,
        location
    ]);

    const toggleDatePicker = useCallback(() => {
        dispatch(
            updateUI({
                showDatePicker: !ui.showDatePicker
            })
        );
    }, [ui.showDatePicker, dispatch]);

    const onHoursSelect = useCallback((e) => {
        setHours(e.target.value);
    }, []);

    const onMinutesSelect = useCallback((e) => {
        setMinutes(e.target.value);
    }, []);

    const onMeridianSelect = useCallback((e) => {
        setMeridian(e.target.value);
    }, []);

    const onDisplayTimeChange = useCallback(() => {
        setDisplayTime((prevValue) => !prevValue);
    }, [setDisplayTime]);

    const onDateSelect = useCallback(
        (_date, view) => {
            setDate(moment.utc(_date).local());
            if (view === 'day') {
                toggleDatePicker();
            }
        },
        [toggleDatePicker]
    );

    const hourOptions = useCallback(() => {
        const options = [];
        for (let i = 1; i <= 12; i++) {
            options.push(<MenuItem value={i}>{i}</MenuItem>);
        }
        return options;
    }, []);

    const minuteOptions = useCallback(() => {
        const options = [];
        for (let i = 0; i <= 59; i++) {
            const value = String(i).padStart(2, '0');
            options.push(<MenuItem value={value}>{value}</MenuItem>);
        }
        return options;
    }, []);

    return (
        <Dialog
            open={open}
            className={'dialog dialog--medium'}
            onClose={_close}>
            <div className="modal-box modal-box--600">
                <div className="modal__header">Date &amp; Time</div>
                <div className="modal__body">
                    <div className="form-group">
                        <div className="form-group__item p-0">
                            <input
                                type="text"
                                value={
                                    date && date.format('dddd, MMMM D, YYYY')
                                }
                                onClick={toggleDatePicker}
                            />
                            <Dialog
                                aria-labelledby="simple-dialog-title"
                                open={ui.showDatePicker}
                                maxWidth="xs">
                                <Calendar
                                    date={date}
                                    onChange={onDateSelect}
                                    entryCalendar={
                                        (calendar && calendar).calendar
                                    }
                                />
                            </Dialog>
                        </div>
                        <div className="form-group__item p-0">
                            <div className="time-picker">
                                <Select
                                    value={hours}
                                    onChange={onHoursSelect}
                                    MenuProps={{
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left'
                                        },
                                        className: 'date-time-dropdowns'
                                    }}
                                    style={{
                                        padding: '0',
                                        margin: '0 2px',
                                        borderBottom:
                                            '0.125rem solid rgb(213, 213, 213)'
                                    }}>
                                    {hourOptions()}
                                </Select>
                                <Select
                                    value={minutes}
                                    onChange={onMinutesSelect}
                                    renderValue={(value) => value}
                                    MenuProps={{
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left'
                                        },
                                        className: 'date-time-dropdowns'
                                    }}
                                    style={{
                                        padding: '0',
                                        margin: '0 2px',
                                        borderBottom:
                                            '0.125rem solid rgb(213, 213, 213)'
                                    }}>
                                    {minuteOptions()}
                                </Select>
                                <Select
                                    value={meridian}
                                    onChange={onMeridianSelect}
                                    MenuProps={{
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left'
                                        },
                                        className: 'date-time-dropdowns'
                                    }}
                                    style={{
                                        padding: '0',
                                        margin: '0 2px',
                                        borderBottom:
                                            '0.125rem solid rgb(213, 213, 213)'
                                    }}>
                                    <MenuItem value="am">
                                        <option>AM</option>
                                    </MenuItem>
                                    <MenuItem value="pm">PM</MenuItem>
                                </Select>
                            </div>
                        </div>
                        <div className="checkbox-item">
                            <input
                                type="checkbox"
                                checked={displayTime}
                                onChange={onDisplayTimeChange}
                                className="checkbox"
                            />
                            <label htmlFor="display-time">
                                Display entry time
                            </label>
                        </div>
                    </div>
                </div>
                <div className="modal__footer">
                    <button className="btn btn-outline" onClick={_close}>
                        Cancel
                    </button>{' '}
                    &nbsp;
                    <button className="btn btn-gradient" onClick={done}>
                        Update
                    </button>
                </div>
            </div>
        </Dialog>
    );
};

const state = createStructuredSelector({
    calendar: getCalendar,
    ui: getUI
});

export default connect(state)(DateTimeDialog);
