import _ from 'underscore';
import moment from 'moment';

import {
    createSelector
} from 'reselect';

import {
    FiltersSelector
} from 'redux/selectors/FiltersSelector';

export const CalendarSelector = (state) => state.calendar;

export const getCalendar = createSelector(
    [CalendarSelector],
    (calendar) => _.extend({}, calendar)
)

export const getCalendarPageRange = createSelector(
    [CalendarSelector, FiltersSelector],
    (calendar, filters) => {
        const list = calendar.calendar_list
        const viewMode = filters.dateViewMode
        let isFirst = false
        let isLast = false

        if (!_.isEmpty(list)) {
            let previous = moment(filters.minDate).startOf(viewMode);
            let next = moment(filters.minDate).endOf(viewMode);

            let previousOrderIndex = _.sortedIndex(list, previous.unix());
            let nextOrderIndex = _.sortedIndex(list, next.unix());

            if (previousOrderIndex > 0)
                previousOrderIndex = previousOrderIndex - 1
            else {
                isFirst = true
                previousOrderIndex = 0
            }

            if (nextOrderIndex === list.length)
                isLast = true

            if (nextOrderIndex > 0) {
                if (nextOrderIndex >= (list.length - 1))
                    nextOrderIndex = (list.length - 1)
            }
            else
                nextOrderIndex = 0

            const pages = {
                previous: moment(list[previousOrderIndex], 'X'),
                next: moment(list[nextOrderIndex], 'X'),
                isFirst: isFirst,
                isLast: isLast,
            }

            return pages;
        }
        return undefined;
    }
)

export const getRecentContentDate = createSelector(
    [CalendarSelector],
    (calendar) => {
        let list = calendar.calendar_list;
        if (list) {
            // Return the most recent content date that is not beyond today's month
            const future = _.sortedIndex(list, moment().endOf('month').unix());
            list = list.slice(0, future);
            return _.last(list);
        }
        return undefined;
    }
);