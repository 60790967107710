import React, { useCallback, useEffect } from 'react';
import moment from 'moment';
import _ from 'underscore';
// Actions
import { updateUI } from 'redux/actions/ui';
// Constants
import { FILTERS_EXCLUSSION_URLS, MAX_MOBILE_WIDTH } from 'common/constant';
// Redux
import { connect } from 'react-redux';
// Router
import { useLocation } from 'react-router-dom';
// Dialogs
import { createStructuredSelector } from 'reselect';
import { getFilters, getUI } from 'redux/selectors';
// Icons
import iconMenu from 'assets/images/icons_svg/icon_calendar.svg';
import iconFilters from 'assets/images/icons_svg/icon_filters.svg';
// import { set } from 'addon-draft-js/lib/DefaultDraftBlockRenderMap';

const OptionsBar = ({ filters, dispatch, ui }) => {
    const location = useLocation();

    const setOpenFilterDrawer = useCallback(() => {
        dispatch(
            updateUI({
                filterDrawerOpen: !ui.filterDrawerOpen
            })
        );
    }, [dispatch, ui.filterDrawerOpen]);

    const defaultSideBarStatus = useCallback(() => {
        //     575.98
        //    912
        if (document.body.clientWidth > MAX_MOBILE_WIDTH) {
            // setOpenFilterDrawer();
            // setOpenRightSidebar();
            dispatch(
                updateUI({
                    sidebarDrawerOpen: true,
                    filterDrawerOpen: true
                })
            );
        } else {
            dispatch(
                updateUI({
                    sidebarDrawerOpen: false,
                    filterDrawerOpen: false
                })
            );
        }
    }, [dispatch]);

    useEffect(() => {
        defaultSideBarStatus();
    }, [defaultSideBarStatus]);
    window.onresize = () => {
        defaultSideBarStatus();
    };

    const setOpenRightSidebar = useCallback(() => {
        dispatch(
            updateUI({
                sidebarDrawerOpen: !ui.sidebarDrawerOpen
            })
        );
    }, [dispatch, ui.sidebarDrawerOpen]);

    const disableFilters = () => {
        for (const url in FILTERS_EXCLUSSION_URLS) {
            if (location.pathname.includes(FILTERS_EXCLUSSION_URLS[url])) {
                return true;
            }
        }
    };

    const renderFilterByDates = useCallback(() => {
        return filters.minDate && filters.maxDate ? (
            <span className="options-bar__content options-bar__content--dates">
                {`${moment(filters.minDate).format('MMM DD, YYYY')} - ${moment(
                    filters.maxDate
                ).format('MMM DD, YYYY')}`}
            </span>
        ) : null;
    }, [filters]);

    const renderFilteredJournalsTitles = useCallback(() => {
        if (!_.isEmpty(filters.filterByJournal)) {
            return (
                <span className="options-bar__content options-bar__content--journals">
                    {filters.filterByJournal
                        .map((items) => {
                            return items.title;
                        })
                        .join(', ')}
                </span>
            );
        }
    }, [filters]);

    const renderFilteredTagsNames = useCallback(() => {
        return !_.isEmpty(filters.filterByTag) ? (
            <span className="options-bar__content options-bar__content--tags">
                {filters.filterByTag.map((items) => items.name).join(', ')}
            </span>
        ) : null;
    }, [filters]);

    const renderFilteredContacts = useCallback(() => {
        return filters.filterByContact ? (
            <span className="options-bar__content options-bar__content--tags">
                {filters.filterByContact?.user?.public_display_name}
            </span>
        ) : null;
    }, [filters]);

    const renderTab = () => {
        if (
            renderFilterByDates() ||
            renderFilteredJournalsTitles() ||
            renderFilteredTagsNames() ||
            renderFilteredContacts()
        ) {
            return (
                <>
                    {renderFilterByDates()}
                    {renderFilteredJournalsTitles()}
                    {renderFilteredTagsNames()}
                    {renderFilteredContacts()}
                </>
            );
        } else {
            return <span className="options-bar__content">All Entries</span>;
        }
    };

    return (
        <div className="options-bar">
            <button
                disabled={disableFilters()}
                id="sidebarOpen"
                className="options-bar__btn options-bar__btn--menu"
                onClick={setOpenFilterDrawer}>
                <img src={iconMenu} alt="Timeline Menu" />
            </button>

            <div className="options-bar__title">
                <div className="options-bar__gradient options-bar__gradient--left"></div>
                {renderTab()}
                <div className="options-bar__gradient options-bar__gradient--right"></div>
            </div>
            <button
                disabled={disableFilters()}
                className="options-bar__btn options-bar__filters"
                onClick={setOpenRightSidebar}>
                <img src={iconFilters} alt="Timeline Filters" />
            </button>
        </div>
    );
};

const state = createStructuredSelector({
    ui: getUI,
    filters: getFilters
});
export default React.memo(connect(state)(OptionsBar));
