import React, { Fragment, useCallback, useEffect, useState } from 'react';
// Redux
import { connect } from 'react-redux';

// Selectors
import { createStructuredSelector } from 'reselect';
import {
    getInstagramMedia,
    getInstagramProfile,
    getInstagramMediaPaging,
    getInstagramState,
    getUI
} from 'redux/selectors';

// Dialogs
import SocialConnectDialog from 'pages/Dialogs/SocialMedia/SocialConnectDialog';
import SocialImageUploadDialog from 'pages/Dialogs/SocialImageUploadDialog';

// Actions
import * as InstagramActions from 'redux/actions/instagram';
import { updateUI } from 'redux/actions/ui';
import withSocialType from 'common/utils/components/withSocialType';
import { SOCIAL_TYPE_INSTAGRAM } from 'common/constant';

// Icons
import iconCheckSm from 'assets/images/icons_svg/icon_check-sm.svg';
// import withFreeAccountCheck from 'common/utils/components/withFreeAccountCheck';
import { openDialog } from 'common/utils/dialog-utils';
import AddJournalEntry from 'components/media/AddJournalEntry';

const InstagramMediaContent = ({
    instagramProfile,
    instagramMedia,
    instagramPaging,
    instagram,
    dispatch,
    close,
    ui,
    media_view
}) => {
    const [selectedImages, setSelectedImages] = useState([]);
    // const [selectedEntry, setSelectedEntry] = useState();
    const [selectedEntryList, setSelectedEntryList] = useState([null]);

    const addPhotos = useCallback(() => {
        const imageDatas = selectedImages.map((item) => ({
            src: item.media_url,
            isInstagram: true,
            caption: ui.importInstagramDesc ? item.caption : null,
            type: 'image'
        }));
        if (media_view) {
            openDialog(SocialImageUploadDialog, {
                images: imageDatas,
                media_view: true,
                selectedEntry: selectedEntryList[0]
            });
        } else {
            openDialog(SocialImageUploadDialog, { images: imageDatas });

            // set entry date
            if (ui.use_instagram_date && selectedImages.length === 1) {
                // Use Instagram Date and Time.
                const image = selectedImages[0];
                dispatch(
                    updateUI({
                        newEntry: {
                            ...ui.newEntry,
                            entry_date: image.timestamp
                        }
                    })
                );
            }
        }
    }, [
        selectedImages,
        dispatch,
        ui.importInstagramDesc,
        ui.use_instagram_date,
        ui.newEntry,
        media_view,
        selectedEntryList
    ]);

    useEffect(() => {
        dispatch(InstagramActions.fetchInstagramProfile());
        dispatch(InstagramActions.fetchInstagramMedia()).then((response) => {
            if (response.payload.data.error) {
                dispatch(
                    updateUI({
                        dialogs: [
                            withSocialType(
                                SocialConnectDialog,
                                SOCIAL_TYPE_INSTAGRAM
                            )
                        ]
                    })
                );
            }
        });
    }, [dispatch]);

    const onImageClick = useCallback(
        (item) => {
            setSelectedImages((prevState) => {
                const newState = [...(prevState || [])];
                const index = newState.findIndex((obj) => obj.id === item.id);
                if (media_view) {
                    if (index >= 0) {
                        return [];
                    } else {
                        return [item];
                    }
                } else {
                    if (index >= 0) {
                        newState.splice(index, 1);
                    } else {
                        newState.push(item);
                    }
                    return newState;
                }
            });
        },
        [media_view]
    );

    const renderInstagramPhotoItem = useCallback(
        (item) => {
            const selected =
                (selectedImages || []).findIndex((obj) => obj.id === item.id) >=
                0;
            const className = selected
                ? 'image-grid__item selected-img'
                : 'image-grid__item';

            return (
                <div
                    key={item.id}
                    className={className}
                    onClick={() => onImageClick(item)}>
                    <img
                        src={item.media_url}
                        alt="instagram"
                        className="image-grid__img"
                    />
                    {selected && (
                        <div className="file__check">
                            <img src={iconCheckSm} alt="" />
                        </div>
                    )}
                </div>
            );
        },
        [onImageClick, selectedImages]
    );

    const renderInstagramPhotos = useCallback(() => {
        return (instagramMedia || []).map((item) => {
            return renderInstagramPhotoItem(item);
        });
    }, [renderInstagramPhotoItem, instagramMedia]);

    const isBottom = (el, elparent) => {
        return (
            el.getBoundingClientRect().bottom <=
            elparent.getBoundingClientRect().bottom
        );
    };
    const onBookScrolling = useCallback(() => {
        const wrappedElement = document.getElementById('media-container');
        const parentElement = document.getElementById('media-parent');
        if (
            isBottom(wrappedElement, parentElement) &&
            instagramPaging.next &&
            instagram.isPending === false
        ) {
            dispatch(
                InstagramActions.fetchInstagramNextMedia(instagramPaging.next)
            );
        }
    }, [instagramPaging, dispatch, instagram]);

    const changeAccount = useCallback(() => {
        dispatch(
            updateUI({
                dialogs: [
                    withSocialType(SocialConnectDialog, SOCIAL_TYPE_INSTAGRAM)
                ]
            })
        );
    }, [dispatch]);

    // component did mount
    useEffect(() => {
        const wrappedElem = document.getElementById('media-container');
        wrappedElem.addEventListener('scroll', onBookScrolling);
        return () => {
            wrappedElem.removeEventListener('scroll', onBookScrolling);
        };
    }, [onBookScrolling]);

    const addPhotosBtnDisabled = selectedImages.length === 0;
    const addPhotosBtnClassName = `btn btn-gradient ${
        addPhotosBtnDisabled ? 'opacity-disabled' : ''
    }`;

    const changeEntryStatus = useCallback(
        (entry, status, key) => {
            if (status === true) {
                const selectEntry = selectedEntryList[key]
                    ? selectedEntryList[key]?.id === entry.id
                        ? null
                        : entry
                    : entry;
                setSelectedEntryList((prevState) => {
                    const entryList = [...prevState];
                    let newEntryList = [];
                    newEntryList = entryList.map((entryEle, index) => {
                        return index === key ? selectEntry : entryEle;
                    });
                    return newEntryList;
                });
            } else {
                setSelectedEntryList((prevState) => {
                    if (prevState.length === key) {
                        return [...prevState, entry];
                    }
                    const entryList = [...prevState];
                    let newEntryList = [];
                    newEntryList = entryList.map((entryEle, index) => {
                        return index === key ? null : entryEle;
                    });
                    return newEntryList;
                });
            }
        },
        [selectedEntryList]
    );

    const deleteJournalEntry = useCallback((index) => {
        setSelectedEntryList((prevState) => {
            const entryList = [...prevState];
            entryList.splice(index, 1);
            return entryList;
        });
    }, []);

    const renderAddJournalEntry = useCallback(() => {
        return (selectedEntryList || []).map((selectedEntry, index) => {
            return (
                <AddJournalEntry
                    index={index}
                    key={`add-journal-entry-component-${index}`}
                    selectedEntry={selectedEntry}
                    changeEntryStatus={changeEntryStatus}
                    deleteJournalEntry={deleteJournalEntry}
                />
            );
        });
    }, [selectedEntryList, changeEntryStatus, deleteJournalEntry]);

    return (
        <Fragment>
            <div className="modal-box modal-box--gallery modal-box--800">
                <div className="modal__header">Add Photos from Instagram</div>
                <div className="social-profile">
                    <div className="social-profile__username">
                        @{instagramProfile?.username}
                    </div>
                    <div
                        onClick={changeAccount}
                        className="social-profile__change">
                        Switch account
                    </div>
                    <div className="social-media-description form-group ml-auto">
                        <div className="checkbox-item">
                            <input
                                type="checkbox"
                                id="socialDesc"
                                name="socialDesc"
                                className="checkbox"
                                checked={ui.importInstagramDesc}
                                onClick={() => {
                                    dispatch(
                                        updateUI({
                                            importInstagramDesc: !ui.importInstagramDesc
                                        })
                                    );
                                }}
                            />
                            <label
                                className="default-journal-label"
                                htmlFor="socialDesc">
                                Import Instagram Posts as descriptions
                            </label>
                        </div>
                    </div>
                </div>
                <div className="modal__body" id="media-container">
                    <div className="image-grid" id="media-parent">
                        {renderInstagramPhotos()}
                    </div>
                </div>
                <div className="social-media-description form-group mr-auto mt-3">
                    <div className="checkbox-item">
                        <input
                            type="checkbox"
                            id="useInstagramDate"
                            name="useInstagramDate"
                            className="checkbox"
                            checked={ui.use_instagram_date}
                            onClick={() =>
                                dispatch(
                                    updateUI({
                                        use_instagram_date: !ui.use_instagram_date
                                    })
                                )
                            }
                        />
                        <label
                            className="default-journal-label"
                            htmlFor="useInstagramDate">
                            Use Instagram date & time
                        </label>
                    </div>
                </div>
                {media_view && renderAddJournalEntry()}
                <div className="modal__footer">
                    <button onClick={close} className="btn btn-outline">
                        Cancel
                    </button>
                    <button
                        onClick={addPhotos}
                        disabled={addPhotosBtnDisabled}
                        className={addPhotosBtnClassName}>
                        Add Photos
                    </button>
                </div>
            </div>
        </Fragment>
    );
};

const state = createStructuredSelector({
    instagramProfile: getInstagramProfile,
    instagramMedia: getInstagramMedia,
    instagramPaging: getInstagramMediaPaging,
    instagram: getInstagramState,
    ui: getUI
});

export default connect(state)(InstagramMediaContent);
