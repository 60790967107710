import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getJournalList, getUser } from 'redux/selectors';
import { updateUI } from 'redux/actions/ui';
import * as JournalActions from 'redux/actions/journal';
import * as ReminderActions from 'redux/actions/reminder';

import { MenuItem, Select } from '@material-ui/core';
import CustomSelect from 'components/CustomSelect';
import {
    AAM_JOURNAL_TYPES,
    REMINDER_EVERY_DAY,
    REMINDER_EVERY_OTHER_DAY,
    REMINDER_EVERY_WEEK
} from 'common/constant';
import { getUTCTime } from 'common/utils/dateUtils';

const ReminderDialog = ({
    reminder,
    user,
    index,
    dispatch,
    journals: propsJournals
}) => {
    const [isFetched, setIsFetched] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [email, setEmail] = useState(reminder?.email || '');
    const [journal, setJournal] = useState(reminder?.journal || 'select');
    const [frequency, setFrequency] = useState(reminder?.frequency || 1);
    const [day, setDay] = useState(reminder?.day_of_the_week || 'monday');
    const [hours, setHours] = useState(10);
    const [meridian, setMeridian] = useState('am');
    const [message, setMessage] = useState(reminder?.custom_message || '');
    const [enabled, setEnabled] = useState(reminder?.enabled || true);
    const journals = React.useMemo(
        () =>
            (propsJournals?.journals || []).filter(
                (item) =>
                    !AAM_JOURNAL_TYPES.includes(item.journal_type) &&
                    item.user.id === user.id
            ),
        [propsJournals, user.id]
    );

    useEffect(() => {
        if (reminder) {
            const time = Number(reminder.time.substring(0, 2));

            setMeridian(time > 12 ? 'pm' : 'am');
            setHours(time > 12 ? time - 12 : time);
        }
    }, [reminder]);

    useEffect(() => {
        if (!isFetched) {
            dispatch(JournalActions.fetch());
            setIsFetched(true);
        }
    }, [isFetched, dispatch]);

    useEffect(() => {
        const validateEmail = new RegExp(
            /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
        ).test(email);

        if (validateEmail && journal !== 'select') {
            setIsDisabled(false);
        }
    }, [email, journal]);

    const addReminder = () => {
        const time = `${hours <= 9 && meridian === 'am' ? '0' : ''}${
            meridian === 'pm' ? hours + 12 : hours
        }:00:00`;
        const utcTime = getUTCTime(`${day} ${time}`);

        const newReminder = {
            email,
            journal: journal.toString(),
            day_of_the_week: utcTime.format('dddd').toLowerCase(),
            time: utcTime.format('HH:mm:ss'),
            frequency,
            enabled,
            custom_message: message,
            user: user.id
        };
        if (reminder) {
            newReminder.id = reminder.id;
            dispatch(ReminderActions.edit(newReminder));
        } else {
            dispatch(ReminderActions.create(newReminder));
        }
        dispatch(
            updateUI({
                dialogs: []
            })
        );
    };
    const closeReminderModal = () => {
        dispatch(
            updateUI({
                dialogs: []
            })
        );
    };

    const hourOptions = useCallback(() => {
        const options = [];
        for (let i = 1; i <= 12; i++) {
            options.push(
                <MenuItem key={`hour${i}`} value={i}>
                    {i}
                </MenuItem>
            );
        }
        return options;
    }, []);

    return (
        <div id="add-reminder-modal" className="modal-frame">
            <div className="modal-box modal-box--600">
                <div className="modal__header">
                    {reminder?.email ? 'Edit Reminder' : 'Add a Reminder'}
                </div>
                <div className="modal__body">
                    <div className="form-group">
                        <div className="form-group__item">
                            <label htmlFor="emailReminder">E-mail to...</label>
                            <input
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                                defaultValue={email}
                                type="email"
                                placeholder="email@example.com"
                                id="emailReminder"
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-group__item">
                            <label
                                className="settings-label"
                                htmlFor="journalSelect">
                                Journal
                            </label>
                            <Select
                                id="mui-select"
                                value={journal}
                                onChange={(e) => setJournal(e.target.value)}
                                MenuProps={{
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left'
                                    },
                                    className:
                                        'select-dropdown reminder_journal_list'
                                }}>
                                <MenuItem value="select">Select</MenuItem>
                                {journals?.map((journal) => (
                                    <MenuItem
                                        key={journal.id}
                                        value={journal.id}>
                                        {journal.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-group__item">
                            <label htmlFor="reminderSelection">
                                Reminder Frequency
                            </label>
                            <div className="form-group__select-list">
                                <CustomSelect
                                    value={frequency}
                                    onChange={(e) => {
                                        setFrequency(e.target.value);
                                    }}>
                                    <MenuItem value={REMINDER_EVERY_DAY}>
                                        Every Day
                                    </MenuItem>
                                    <MenuItem value={REMINDER_EVERY_OTHER_DAY}>
                                        Every Other Day
                                    </MenuItem>
                                    <MenuItem value={REMINDER_EVERY_WEEK}>
                                        Once Per Week
                                    </MenuItem>
                                </CustomSelect>
                                {frequency !== REMINDER_EVERY_DAY && (
                                    <CustomSelect
                                        value={day}
                                        onChange={(e) => {
                                            setDay(e.target.value);
                                        }}>
                                        {moment.weekdays().map((day, index) => (
                                            <MenuItem
                                                key={day + index}
                                                value={day.toLowerCase()}>
                                                {day}
                                            </MenuItem>
                                        ))}
                                    </CustomSelect>
                                )}
                                <CustomSelect
                                    value={hours}
                                    onChange={(e) => {
                                        setHours(e.target.value);
                                    }}>
                                    {hourOptions()}
                                </CustomSelect>
                                <CustomSelect
                                    value={meridian}
                                    onChange={(e) => {
                                        setMeridian(e.target.value);
                                    }}>
                                    <MenuItem value="am">AM</MenuItem>
                                    <MenuItem value="pm">PM</MenuItem>
                                </CustomSelect>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-group__item">
                            <label htmlFor="customMessageReminder">
                                Custom Message (Optional)
                            </label>
                            <input
                                onChange={(e) => {
                                    setMessage(e.target.value);
                                }}
                                defaultValue={message}
                                type="email"
                                placeholder="Your message"
                                id="customMessageReminder"
                            />
                        </div>
                    </div>
                    {reminder && (
                        <div className="list">
                            <div className="list__item">
                                <div className="list__content">
                                    <div className="list__title">Enabled?</div>
                                </div>
                                <div className="toggle toggle--right">
                                    <input
                                        type="checkbox"
                                        id={'enableReminder'}
                                        checked={enabled}
                                        onChange={(e) =>
                                            setEnabled(e.target.checked)
                                        }
                                    />
                                    <label htmlFor={'enableReminder'}></label>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="modal__footer">
                    <button
                        onClick={closeReminderModal}
                        className="btn btn-outline">
                        Cancel
                    </button>
                    {reminder?.email ? (
                        <button
                            onClick={addReminder}
                            disabled={isDisabled}
                            className="btn btn-gradient">
                            Edit a Reminder
                        </button>
                    ) : (
                        <button
                            onClick={addReminder}
                            disabled={isDisabled}
                            className="btn btn-gradient">
                            Add a Reminder
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    journals: getJournalList,
    user: getUser
});

export default connect(state)(ReminderDialog);
