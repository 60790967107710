import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

// Actions
import { connect } from 'react-redux';
import * as EditorActions from 'redux/actions/editor';
import * as EntryMediaActions from 'redux/actions/entrymedia';
import { updateUI } from 'redux/actions/ui';

import DisplayFile from 'components/DisplayFile/DisplayFile';

// Selectors
import { createStructuredSelector } from 'reselect';
import {
    getEditorState,
    getAvatarImage,
    getUI,
    getUser
} from 'redux/selectors';

import Dialog from '@material-ui/core/Dialog';
import formatBytes from 'common/utils/formatBytes';

// Icons
import iconCloseSm from 'assets/images/icons_svg/icon_close-sm.svg';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { IMAGE_MAX_SIZE } from 'common/constant';

const ImportFileDialog = ({ dispatch, close, open, editor, ui, me }) => {
    const [file, setFile] = useState(null);
    const [imageTooLarge, setImageTooLarge] = useState(false);
    useEffect(() => {
        dispatch(
            updateUI({
                disableButton: !file || ui.editor?.isPending
            })
        );
    }, [dispatch, file, ui.editor]);

    const onDrop = useCallback(
        (acceptedFiles) => {
            acceptedFiles.forEach((file) => {
                const reader = new FileReader();
                const filesize = parseFloat(
                    Number(file.size / (1024 * 1024)).toFixed(2)
                );
                if (filesize > IMAGE_MAX_SIZE) {
                    setImageTooLarge(true);
                    return;
                }
                reader.onload = () => {
                    // Do whatever you want with the file contents
                    const entryId = ui.newEntry
                        ? ui.newEntry.id
                        : ui.editEntry.id;
                    const fileType = file.type.slice(0, file.type.indexOf('/'));
                    const binaryStr = URL.createObjectURL(
                        new Blob([new Uint8Array(reader.result)], {
                            type: fileType
                        })
                    );
                    const formData = new FormData();
                    formData.append('file', file, file.name);
                    formData.append('entry', entryId || '');

                    dispatch(EntryMediaActions.create(formData)).then(
                        (response) => {
                            if (response?.payload?.status === 201) {
                                if (!entryId) {
                                    dispatch(
                                        updateUI({
                                            newEntry: {
                                                ...ui.newEntry,
                                                id:
                                                    response.payload.data.entry
                                                        ?.id
                                            }
                                        })
                                    );
                                }
                            }
                        }
                    );
                    dispatch(
                        EntryMediaActions.setMediaType({ type: fileType })
                    );

                    setFile({
                        src: binaryStr,
                        type: file.type,
                        name: file.name,
                        size: file.size,
                        file
                    });
                };

                reader.readAsArrayBuffer(file);
            });
        },
        [dispatch, ui.editEntry, ui.newEntry]
    );

    const _close = useCallback(() => {
        dispatch(EditorActions.resetImageState());
        if (close) {
            close();
        }
    }, [dispatch, close]);

    const done = useCallback(
        (description) => {
            editor.staged_entry_media[0].description = description;
            editor.staged_entry_media[0].file_name = file.name;
            editor.staged_entry_media[0].file_formatted_size = formatBytes(
                file.size
            );

            dispatch(
                EditorActions.insertMediaBlock(editor.staged_entry_media[0])
            );
            dispatch(EntryMediaActions.unstageEntryMedia());
            _close();
        },
        [dispatch, editor.staged_entry_media, _close, file]
    );
    useEffect(() => {
        if (imageTooLarge) {
            setTimeout(() => setImageTooLarge(false), 3000);
        }
    }, [imageTooLarge]);
    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    return (
        <Dialog
            fullWidth={true}
            open={open}
            onClose={_close}
            aria-labelledby="form-dialog-title">
            <div className="modal-box modal__box--dropzone modal-box--600">
                <div className="modal__header">
                    Add File
                    <img
                        className="modal__close"
                        onClick={_close}
                        src={iconCloseSm}
                        alt=""
                    />
                </div>
                {file ? (
                    <DisplayFile file={file} close={_close} done={done} />
                ) : (
                    <div className="modal__body" {...getRootProps()}>
                        <input
                            {...getInputProps()}
                            accept="audio/*,video/*,.pdf,.doc,.docx,.xlsm,.csv"
                        />
                        <div className="box-input mx-auto">
                            <div className="photos-action">
                                <div>
                                    <strong>Drop file here to upload</strong>
                                    <span className="btn btn-gradient">
                                        Select Files
                                    </span>
                                </div>
                            </div>
                            <div className="photo-details">
                                <p>Maximum upload size: {IMAGE_MAX_SIZE} MB</p>
                                <p>
                                    Formats:
                                    .mp3,.mp4,.mov,.pdf,.doc.docx,.xlsm,.csv
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Snackbar
                open={imageTooLarge}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                    backgroundColor: 'white'
                }}
                key={'top center'}
                style={{ width: '100%', zIndex: '9999' }}>
                <Alert severity="error">
                    The maximum size of file to upload should be{' '}
                    {IMAGE_MAX_SIZE} MB
                </Alert>
            </Snackbar>
        </Dialog>
    );
};

const state = createStructuredSelector({
    editor: getEditorState,
    avatar: getAvatarImage,
    ui: getUI,
    me: getUser
});
export default connect(state)(ImportFileDialog);
