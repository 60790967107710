import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

// images
import GmailImage from 'assets/images/contacts/gmail.png';
import OutlookImage from 'assets/images/contacts/outlook.png';
import YahooImage from 'assets/images/contacts/yahoo.png';
import LinkedinImage from 'assets/images/contacts/linkedin.png';
import AolImage from 'assets/images/contacts/aol.png';
import { closeDialog, openDialog } from 'common/utils/dialog-utils';
import * as ShareContactListsActions from 'redux/actions/sharecontactlist';

// Icons
import iconCloseSm from 'assets/images/icons_svg/icon_close-sm.svg';
import SuccessAddedContactsDialog from '../SuccessAddedContactsDialog';

const AddContactsDialog = ({ dispatch }) => {
    const [email, setEmail] = useState('');
    useEffect(() => {
        if (window['cloudsponge']) {
            window['cloudsponge'].init({
                afterSubmitContacts: (contacts) => {
                    contacts.map((contact) => {
                        return dispatch(
                            ShareContactListsActions.createContact(
                                contact.selectedEmail()
                            )
                        );
                    });
                }
            });
        }
    }, [dispatch]);

    const addContact = useCallback(() => {
        dispatch(ShareContactListsActions.createContact(email)).then((res) => {
            dispatch(
                openDialog(SuccessAddedContactsDialog, {
                    contacts: [{ ...res.payload.data }]
                })
            );
        });
        closeDialog();
    }, [dispatch, email]);

    return (
        <div id="add-contact-account-modal" className="modal-frame">
            <div className="modal-box modal-box--600">
                <div className="modal__header">
                    Add a Contact
                    <button
                        className="modal__close btn-icon btn-icon--3"
                        onClick={closeDialog}>
                        <img src={iconCloseSm} alt="" />
                    </button>
                </div>
                <div className="modal__body pb-3">
                    <p className="mb-0">
                        Write your contact’s email; we will send them an
                        invitation to join your JRNL’s contacts. If your
                        contacts do not have a JRNL Account, we will send them
                        an email invitation on your name. We will not send any
                        spam.
                    </p>
                    <div className="form-group mb-0">
                        <div className="form-group__item mb-0">
                            <div className="form-input-btn">
                                <input
                                    className="form-input-btn__input"
                                    type="email"
                                    placeholder="example@example.com"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <button
                                    className="form-input-btn__btn"
                                    onClick={addContact}>
                                    <i className="icon-circle-check"></i>
                                    Send
                                </button>
                            </div>
                        </div>
                    </div>
                    <p>or import your contacts from one of these services:</p>
                    <div className="import-contacts-section">
                        <ul className="import-banner-media-list">
                            <li
                                className="cloudsponge-launch"
                                data-cloudsponge-source="gmail">
                                <img src={GmailImage} alt="gmail" />
                            </li>
                            <li
                                className="cloudsponge-launch"
                                data-cloudsponge-source="outlook">
                                <img src={OutlookImage} alt="gmail" />
                            </li>
                            <li
                                className="cloudsponge-launch"
                                data-cloudsponge-source="yahoo">
                                <img src={YahooImage} alt="gmail" />
                            </li>
                            <li
                                className="cloudsponge-launch"
                                data-cloudsponge-source="linkedin">
                                <img src={LinkedinImage} alt="gmail" />
                            </li>
                            <li
                                className="cloudsponge-launch"
                                data-cloudsponge-source="aol">
                                <img src={AolImage} alt="gmail" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect()(AddContactsDialog);
