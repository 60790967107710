import _ from 'underscore';
import moment from 'moment';
import extractIdFromResourceUri from 'common/utils/extractIdFromResourceUri';

import { createSelector } from 'reselect';

export const CommentSelector = (state) => state.comment;

export const CommentsForEntrySelector = (state, props) => {
    let entryComments;

    const propsEntryId = props.match?.params?.entryId || props.entry?.id;

    if (propsEntryId) {
        const { comments } = state.comment;
        const { entries } = state.entry;

        entryComments = _.filter(comments, (comment) => {
            const entryId =
                extractIdFromResourceUri('entry', comment.entry) ||
                extractIdFromResourceUri('shared_entry', comment.entry) ||
                extractIdFromResourceUri('timeline_entry', comment.entry);
            return parseInt(entryId) === parseInt(propsEntryId);
        });

        const entry = _.find(
            entries,
            (item) => parseInt(item.id) === parseInt(propsEntryId)
        );

        if (entry?.sortOldestComment) {
            entryComments = _.sortBy(
                entryComments,
                (item) => -moment(item.created).valueOf()
            );
        } else {
            entryComments = _.sortBy(entryComments, (item) =>
                moment(item.created).valueOf()
            );
        }
    }
    return entryComments;
};

export const getCommentsForEntry = createSelector(
    [CommentsForEntrySelector],
    (comments) => comments
);

export const getCommentList = createSelector([CommentSelector], (comment) =>
    _.extend({}, comment)
);
