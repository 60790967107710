import {
    ADD_CONTACT,
    FETCH_CONTACTS,
    DELETE_CONTACT,
    EDIT_CONTACT
} from 'redux/actions/actionTypes';

export const fetch = () => (dispatch) => {
    const contacts = JSON.parse(localStorage.getItem('contacts'));

    return dispatch({
        type: FETCH_CONTACTS,
        data: contacts || {
            contacts: []
        }
    });
};

export const add = (contact) => (dispatch) => {
    return dispatch({
        type: ADD_CONTACT,
        data: contact
    });
};
export const remove = (contact) => (dispatch) => {
    return dispatch({
        type: DELETE_CONTACT,
        data: contact
    });
};
export const edit = (contact) => (dispatch) => {
    return dispatch({
        type: EDIT_CONTACT,
        data: contact
    });
};
