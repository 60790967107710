import React, { useCallback, useEffect, useState, useRef } from 'react';
import EntryHeader from 'components/Entry/EntryHeader';
import EntryFooter from 'components/Entry/EntryFooter';
import { getPublicUsersList, getUI, getUser } from 'redux/selectors';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { updateUI } from 'redux/actions/ui';
import { renderSummaryText } from 'common/utils/renderSummaryText';
import {
    ENTRY_DRAFT,
    ENTRY_PUBLISHED,
    ENTRY_QA,
    FONT_SIZE_USE_TYPE
} from 'common/constant';
import getFontSize from 'common/utils/getFontSize';
import { toggleImageFullScreen } from 'common/utils/images/to_full_screen';
// Selectors
import {
    getFontSize as getFontSizeSelector,
    getUserTimeLineReadingType
} from 'redux/selectors';

const EntryTile = ({
    entry,
    ui,
    isDetailView,
    dispatch,
    showShareButton = true,
    userFontSize,
    me,
    public_users_list
}) => {
    const [summaryView, setSummaryView] = useState(ui.summaryView);

    const [canReadMore, setCanReadMore] = useState(true);
    const contentRef = useRef();
    const isOnEdit = window.location.href.includes('entry/edit/');

    useEffect(() => {
        if (
            !!ui.summaryViewWithEntry &&
            ui.summaryViewWithEntry[entry.id] !== undefined
        ) {
            setSummaryView(ui.summaryViewWithEntry[entry.id]);
        } else if (ui.summaryView !== undefined) {
            setSummaryView(ui.summaryView);
        }
    }, [ui.summaryView, ui.summaryViewWithEntry, entry]);

    useEffect(() => {
        // @ts-ignore
        const text = contentRef?.current?.innerText || '';
        const isCanReadMore =
            text?.length > 150 ||
            entry.content?.indexOf('img') > 0 ||
            entry.content?.includes('data-tag="img"') > 0;
        setCanReadMore(Boolean(isCanReadMore));
    }, [entry, contentRef]);

    const changeViewMode = useCallback(
        (e) => {
            const summaryViewWithEntry = { ...ui.summaryViewWithEntry };
            summaryViewWithEntry[entry.id] = !summaryView;
            dispatch(
                updateUI({
                    summaryViewWithEntry
                })
            );
        },
        [summaryView, entry, ui.summaryViewWithEntry, dispatch]
    );
    const openDocument = (file) => {
        if (file) {
            const pdfWindow = window.open('');
            pdfWindow.document.write(
                `<iframe width='100%' height='100%' src="${file}"'></iframe>`
            );
        }
    };
    useEffect(() => {
        const readmore_ = document.getElementById(`${entry.id}read-more`);
        readmore_ && readmore_.addEventListener('click', changeViewMode);
    }, [entry.id, changeViewMode]);
    useEffect(() => {
        const documents = document.querySelectorAll('q');
        for (const element of documents) {
            if (!element.getAttribute('data-binded')) {
                element.setAttribute('data-binded', '1');
                const caption = element.getAttribute('caption');
                const figCaption = document.createElement('figcaption');
                figCaption.innerHTML = caption;
                element.appendChild(figCaption);
                const div = element.querySelector('.add-media-box-file-info');
                if (div) {
                    div.addEventListener('click', () => {
                        openDocument(element.getAttribute('src'));
                    });
                }
            }
        }
    }, [entry.content, summaryView]);
    const checkInvitationExists = useCallback(() => {
        if (me && entry?.shared_with) {
            return entry.shared_with?.some((user) => {
                return user.id === me.id;
            });
        }
    }, [entry, me]);
    const renderDefault = useCallback(() => {
        return (
            <div
                className={`post posted-journal ${
                    entry.status === ENTRY_DRAFT ? 'draft-journal' : ''
                } ${checkInvitationExists() ? 'shared' : ''}`}>
                {!isOnEdit && (
                    <EntryHeader
                        public_users_list={public_users_list}
                        entry={entry}
                        checkInvitationExists={checkInvitationExists}
                    />
                )}

                {isOnEdit && (
                    <div
                        style={{
                            padding: '0.5em',
                            border: '0px solid gray',
                            background: '#99cc33',
                            color: 'white'
                        }}>
                        <b>Original Entry</b>
                    </div>
                )}

                <div
                    className="post-body p-3"
                    style={{
                        fontSize: getFontSize(
                            FONT_SIZE_USE_TYPE.ENTRY,
                            userFontSize
                        )
                    }}
                    onClick={toggleImageFullScreen}>
                    <h3
                        className="entry-title"
                        style={{
                            fontSize: getFontSize(
                                FONT_SIZE_USE_TYPE.ENTRY_TITLE,
                                userFontSize
                            ),
                            marginBottom: '10px'
                        }}>
                        {entry.title}
                    </h3>
                    <div
                        className="type"
                        ref={contentRef}
                        style={{ display: 'none' }}
                        dangerouslySetInnerHTML={{ __html: entry.content }}
                    />
                    {!summaryView ? (
                        <div>
                            <div
                                className="type read__more"
                                dangerouslySetInnerHTML={{
                                    __html: entry.content
                                }}
                            />
                            {canReadMore && (
                                <div
                                    className="read-more"
                                    onClick={(e) => {
                                        changeViewMode(e);
                                    }}>
                                    Show Less
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="type">
                            {renderSummaryText(
                                entry.content,
                                canReadMore,
                                entry.id
                            )}
                        </div>
                    )}
                </div>
                {!isOnEdit && (
                    <EntryFooter
                        entry={entry}
                        isDetailView={isDetailView}
                        showShareButton={showShareButton}
                    />
                )}
            </div>
        );
        // eslint-disable-next-line
    }, [
        entry,
        summaryView,
        canReadMore,
        changeViewMode,
        showShareButton,
        userFontSize,
        checkInvitationExists,
        isDetailView
    ]);

    const renderQA = useCallback(
        () => (
            <div
                className={`post posted-journal all-about-me ${
                    entry.status === ENTRY_DRAFT ? 'draft-journal' : ''
                } ${checkInvitationExists() ? 'shared' : ''}`}>
                <EntryHeader
                    entry={entry}
                    checkInvitationExists={checkInvitationExists}
                />
                <div className="post-body p-3" onClick={toggleImageFullScreen}>
                    <div
                        className="type"
                        ref={contentRef}
                        style={{ display: 'none' }}
                        dangerouslySetInnerHTML={{ __html: entry.content }}
                    />
                    {!summaryView ? (
                        <div>
                            <div
                                className="type"
                                dangerouslySetInnerHTML={{
                                    __html: entry.content
                                }}
                                style={{
                                    fontSize: getFontSize(
                                        FONT_SIZE_USE_TYPE.ENTRY,
                                        userFontSize
                                    )
                                }}
                            />
                            {canReadMore && (
                                <div
                                    className="read-more"
                                    onClick={changeViewMode}>
                                    Show Less
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="type">
                            <span
                                style={{
                                    fontSize: getFontSize(
                                        FONT_SIZE_USE_TYPE.ENTRY,
                                        userFontSize
                                    )
                                }}>
                                {renderSummaryText(
                                    entry.content,
                                    canReadMore,
                                    entry.id
                                )}
                            </span>
                        </div>
                    )}
                </div>
                <EntryFooter entry={entry} isDetailView={isDetailView} />
            </div>
        ),
        [
            entry,
            canReadMore,
            changeViewMode,
            summaryView,
            userFontSize,
            checkInvitationExists,
            isDetailView
        ]
    );

    switch (entry.entry_type) {
        case ENTRY_QA: {
            if (entry.status === ENTRY_PUBLISHED) {
                return renderQA();
            } else {
                return <></>;
            }
        }
        default: {
            return renderDefault();
        }
    }
};
const state = createStructuredSelector({
    ui: getUI,
    userFontSize: getFontSizeSelector,
    reading_type: getUserTimeLineReadingType,
    me: getUser,
    public_users_list: getPublicUsersList
});
export default connect(state)(EntryTile);
