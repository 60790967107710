import {
    GOOGLE_CONNECT_API_V2_URL,
    GOOGLE_LOGIN_API_V2_URL,
    GOOGLE_UPLOAD_API_V2_URL
} from 'common/constant';
import buildV2Authorization from 'common/utils/buildV2Authorization';
import { getCookie } from 'common/utils/cookieUtils';
import {
    GOOGLE_CONNECT,
    GOOGLE_LOGIN,
    GOOGLE_UPLOAD
} from 'redux/actions/actionTypes';
import { UserSelector } from 'redux/selectors/UserSelector';

export const connectGoogle = (token) => (dispatch, getState) => {
    const url = GOOGLE_CONNECT_API_V2_URL;
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);

    return dispatch({
        type: GOOGLE_CONNECT,
        payload: {
            request: {
                url,
                method: 'POST',
                data: {
                    access_token: token
                },
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const uploadFile = (data) => (dispatch, getState) => {
    const url = GOOGLE_UPLOAD_API_V2_URL;
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);

    return dispatch({
        type: GOOGLE_UPLOAD,
        payload: {
            request: {
                url,
                method: 'POST',
                data,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const loginGoogle = (token) => (dispatch) => {
    const url = GOOGLE_LOGIN_API_V2_URL;
    const csrftoken = getCookie('csrftoken');

    return dispatch({
        type: GOOGLE_LOGIN,
        payload: {
            request: {
                url,
                method: 'POST',
                data: {
                    access_token: token
                },
                headers: {
                    'X-CSRFToken': csrftoken
                }
            }
        }
    });
};
