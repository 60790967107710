import { actions } from 'react-redux-form';
import settings from 'common/configs/setting';
import buildAuthorization from 'common/utils/buildAuthorization';
import buildV2Authorization from 'common/utils/buildV2Authorization';
import { UserSelector } from 'redux/selectors/UserSelector';
import {
    CLOSE_ACCOUNT_URL,
    ENABLE_2FA_URL,
    SOCIAL_ACCOUNT_DEFAULT_URL,
    SUBSCRIBE_URL,
    SUBSCRIPTION_URL,
    SUCCESS_SUFFIX,
    UNSUBSCRIBE_URL
} from 'common/constant';
import { URLS } from 'common/urls';
import {
    ACTION_USER_LOGOUT,
    ACTION_USER_SAVE,
    ACTION_USER_AUTHENTICATE,
    ACTION_USER_FETCH,
    ACTION_USER_UPDATE,
    ACTION_USER_RESET_STATUS_MESSAGE,
    ACTION_USER_REQUEST_PASSWORD_RESET,
    ACTION_USER_RESET_PASSWORD,
    ACTION_USER_UPDATE_PASSWORD,
    ACTION_CHANGE_ACCOUNT_DEFAULT,
    ACTION_USER_SUBSCRIBE_PRO,
    ACTION_USER_ENABLE_2FA,
    ACTION_USER_RENEW_PLAN
} from 'redux/actions/actionTypes';

export const logoutAction = () => (dispatch, getState) => {
    dispatch({
        type: ACTION_USER_LOGOUT,
        payload: {}
    });
};

export const clearStorage = () => (dispatch, getState) => {
    dispatch({
        type: ACTION_USER_SAVE,
        payload: {}
    });
};

export const authenticate = (login_form, validationCode) => (dispatch) => {
    function b64EncodeUnicode(str) {
        return btoa(
            encodeURIComponent(str).replace(
                /%([0-9A-F]{2})/g,
                function (match, p1) {
                    // @ts-ignore
                    return String.fromCharCode(`0x${p1}`);
                }
            )
        );
    }
    const auth = b64EncodeUnicode(
        `${login_form.email_username}:${login_form.password}`
    );
    const headers = {
        Authorization: `Basic ${auth}`,
        'Content-Type': 'application/json'
    };

    if (validationCode) {
        headers['X-CODE'] = validationCode;
    }

    // login endpoint check
    return dispatch({
        type: ACTION_USER_AUTHENTICATE,
        payload: {
            request: {
                url: `${settings.api}/user/me/`,
                method: 'GET',
                data: login_form,
                headers
            }
        }
    });
};

export const logout = () => async (dispatch) => {
    dispatch(logoutAction());
    dispatch(actions.reset('login'));
    dispatch(actions.setInitial('login'));

    window.localStorage.removeItem('jrnluser');
    window.localStorage.removeItem('skipOnboarding');
};

export const fetch = () => async (dispatch, getState) => {
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: ACTION_USER_FETCH,
        payload: {
            request: {
                url: URLS.API.USER.V1_ME,
                headers: {
                    Authorization: auth,
                    'Content-Type': 'application/json'
                }
            }
        }
    });
};

export const update = (profile, target) => async (dispatch, getState) => {
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);
    const url = `${URLS.API.USER.V1}${user.id}/`;

    return dispatch({
        type: ACTION_USER_UPDATE,
        payload: {
            request: {
                url,
                method: 'PATCH',
                data: JSON.stringify(profile),
                options: {
                    target
                },
                headers: {
                    Authorization: auth,
                    'Content-Type': 'application/json'
                }
            }
        }
    });
};

export const deleteAccount = () => async (dispatch, getState) => {
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);
    const url = CLOSE_ACCOUNT_URL;

    return dispatch({
        type: ACTION_USER_UPDATE,
        payload: {
            request: {
                url,
                method: 'PATCH',
                headers: {
                    Authorization: auth,
                    'Content-Type': 'application/json'
                }
            }
        }
    });
};

export const reset_status_message = () => ({
    type: ACTION_USER_RESET_STATUS_MESSAGE,
    payload: {
        value: true
    }
});

export const request_password_reset = (params) => (dispatch) => {
    return dispatch({
        type: ACTION_USER_REQUEST_PASSWORD_RESET,
        payload: {
            request: {
                url: `${settings.api}/password_reset/`,
                method: 'POST',
                data: { email: params },
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        }
    });
};

export const reset_password = (params) => (dispatch) => {
    return dispatch({
        type: ACTION_USER_RESET_PASSWORD,
        payload: {
            request: {
                url: `${settings.api}/password_change/`,
                method: 'POST',
                data: params,
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        }
    });
};

export const updatePassword = (params) => async (dispatch, getState) => {
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);
    return dispatch({
        type: ACTION_USER_UPDATE_PASSWORD,
        payload: {
            request: {
                url: `${settings.api_v2}/password/`,
                method: 'PUT',
                data: JSON.stringify(params),
                headers: {
                    Authorization: auth,
                    'Content-Type': 'application/json'
                }
            }
        }
    });
};

export const updateAccountDefault = (data) => (dispatch, getState) => {
    const user = UserSelector(getState());
    const auth = buildV2Authorization(user);
    const url = `${SOCIAL_ACCOUNT_DEFAULT_URL}`;

    return dispatch({
        type: ACTION_CHANGE_ACCOUNT_DEFAULT,
        payload: {
            request: {
                url,
                method: 'POST',
                data,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const subscribeToPro = (profile_id, interval) => (
    dispatch,
    getState
) => {
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);
    const url = `${SUBSCRIBE_URL}`;

    return dispatch({
        type: ACTION_USER_SUBSCRIBE_PRO,
        payload: {
            request: {
                url,
                method: 'POST',
                data: {
                    payment_profile_id: profile_id,
                    trial: 1,
                    interval
                },
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const unsubscribeToFree = () => (dispatch, getState) => {
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);
    const url = `${UNSUBSCRIBE_URL}`;

    return dispatch({
        type: ACTION_USER_SUBSCRIBE_PRO,
        payload: {
            request: {
                url,
                method: 'POST',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const enable2FA = (enable, xCode = null) => (dispatch, getState) => {
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);
    const url = `${ENABLE_2FA_URL}`;
    const headers = {
        Authorization: auth
    };

    if (xCode) {
        headers['X-CODE'] = xCode;
    }

    return dispatch({
        type: ACTION_USER_ENABLE_2FA,
        payload: {
            request: {
                url,
                method: 'PATCH',
                headers,
                data: {
                    status: enable ? 1 : 0
                }
            }
        }
    });
};

export const renewPlan = (data) => (dispatch, getState) => {
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);
    const url = `${SUBSCRIPTION_URL}${data.id}/`;
    const headers = {
        Authorization: auth
    };

    return dispatch({
        type: ACTION_USER_RENEW_PLAN,
        payload: {
            request: {
                url,
                method: 'PATCH',
                headers,
                data
            }
        }
    });
};

export const UserActions = [
    ACTION_USER_LOGOUT,
    ACTION_USER_AUTHENTICATE,
    ACTION_USER_AUTHENTICATE + SUCCESS_SUFFIX,
    ACTION_USER_SAVE,
    ACTION_USER_FETCH,
    ACTION_USER_FETCH + SUCCESS_SUFFIX,
    ACTION_USER_UPDATE,
    ACTION_USER_UPDATE + SUCCESS_SUFFIX,
    ACTION_USER_RESET_STATUS_MESSAGE,
    ACTION_USER_REQUEST_PASSWORD_RESET,
    ACTION_USER_REQUEST_PASSWORD_RESET + SUCCESS_SUFFIX,
    ACTION_USER_RESET_PASSWORD,
    ACTION_USER_RESET_PASSWORD + SUCCESS_SUFFIX,
    ACTION_USER_UPDATE_PASSWORD,
    ACTION_USER_UPDATE_PASSWORD + SUCCESS_SUFFIX
];
