import _ from 'underscore';
import React, { useCallback, useRef, useState } from 'react';
import { updateUI } from 'redux/actions/ui';
import { getUI } from 'redux/selectors';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import ShareWithDialog from 'pages/Dialogs/InvitationDialog/ShareWithDialog';
import withSharedUsers from 'common/utils/components/withSharedUsers';
import Select from 'react-select';
// Icons
import iconAddSm from 'assets/images/icons_svg/icon_add-sm.svg';
import iconCloseSm from 'assets/images/icons_svg/icon_close-sm.svg';

const ShareDialog = ({ dispatch, ui, close, editable = true, ...props }) => {
    const originalSharedUsersRef = useRef(props.sharedUsers || []);
    const [sharedUsers, setSharedUsers] = useState(props.sharedUsers || []);
    const [publicEntry, setPublicEntry] = useState(props.publicEntry);
    const [message, setMessage] = useState('');

    const onPublic = useCallback((e) => {
        setPublicEntry(e.value);
    }, []);

    const onUpdate = useCallback((sharedUsers, added_users, removed_users) => {
        setSharedUsers(sharedUsers);
    }, []);

    const openShareWithDialog = useCallback(() => {
        dispatch(
            updateUI({
                dialogs: [
                    ...ui.dialogs,
                    withSharedUsers(
                        ShareWithDialog,
                        [...sharedUsers],
                        onUpdate,
                        publicEntry
                    )
                ]
            })
        );
    }, [dispatch, sharedUsers, onUpdate, ui, publicEntry]);

    const done = useCallback(() => {
        let added_users = [],
            removed_users = [];

        sharedUsers.forEach((user) => {
            const index = _.findIndex(originalSharedUsersRef.current, {
                email: user.email
            });

            if (index < 0) {
                added_users.push(user);
            }
        });

        originalSharedUsersRef.current.forEach((user) => {
            const index = _.findIndex(sharedUsers, { email: user.email });

            if (index < 0) {
                removed_users.push(user);
            }
        });

        let shared_users = sharedUsers;

        if (!publicEntry) {
            added_users = [];
            removed_users = originalSharedUsersRef.current;
            shared_users = [];
        }

        if (added_users.length < 1 && shared_users.length < 1) {
            dispatch(
                updateUI({
                    publicEntry: false
                })
            );
        }

        props.onUpdate(shared_users, added_users, removed_users, message);

        if (close) {
            close();
        }
    }, [close, sharedUsers, props, dispatch, publicEntry, message]);

    const removeSharedUser = useCallback(
        (user) => {
            let shared_users = [...sharedUsers];
            shared_users = shared_users.filter(
                (shared_user) => shared_user.email !== user.email
            );

            setSharedUsers(shared_users);
        },
        [sharedUsers]
    );

    const renderSharedUsers = useCallback(() => {
        const shared_users = [...sharedUsers];

        return shared_users.map((user, index) => {
            if (user.user) {
                return (
                    <div key={index} className="user-tags__item">
                        <img
                            className="user-tags__avatar"
                            src={user.user.avatar_image_url}
                            alt="user"
                        />
                        <div className="user-tags__title truncate">
                            <span>{user.user.public_display_name}</span>
                        </div>
                        {editable && (
                            <img
                                className="user-tags__close"
                                src={iconCloseSm}
                                onClick={() => removeSharedUser(user)}
                                alt=""
                            />
                        )}
                    </div>
                );
            } else {
                return (
                    <div key={index} className="user-tags__item">
                        <div className="user-tags__title truncate">
                            <span>{user.email}</span>
                        </div>
                        {editable && (
                            <img
                                className="user-tags__close"
                                src={iconCloseSm}
                                onClick={() => removeSharedUser(user)}
                                alt=""
                            />
                        )}
                    </div>
                );
            }
        });
    }, [sharedUsers, removeSharedUser, editable]);

    const options = [
        { value: false, label: 'Private' },
        { value: true, label: 'Shared' }
    ];

    return (
        <div id="share-modal" className="modal-frame">
            <div className="modal-box modal__box--dropzone modal-box--600">
                <div className="modal__header">Share</div>
                <div className="modal__body">
                    <div className="form-group">
                        <div className="form-group__item">
                            <label htmlFor="visibilityShare">Visibility</label>
                            <Select
                                onChange={onPublic}
                                defaultValue={
                                    !publicEntry ? options[0] : options[1]
                                }
                                options={options}
                                isSearchable={false}
                                menuPosition={'fixed'}
                                isDisabled={!editable}
                            />
                        </div>
                    </div>
                    {publicEntry > 0 && (
                        <div className="form-group">
                            <div className="form-group__item">
                                <label htmlFor="visibilityShare">
                                    Share with...
                                </label>
                                <div className="user-tags">
                                    {renderSharedUsers()}
                                </div>
                                {editable && (
                                    <div
                                        className="user-tags__add btn btn-gradient"
                                        onClick={openShareWithDialog}>
                                        <img src={iconAddSm} alt="Add" />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    <div className="form-group  form-group--single">
                        <div className="form-group__item">
                            <label htmlFor="customMessageReminder">
                                Custom Message (Optional)
                            </label>
                            <input
                                onChange={(e) => {
                                    setMessage(e.target.value);
                                }}
                                defaultValue={message}
                                type="email"
                                placeholder="Your message"
                                id="customMessageShare"
                            />
                        </div>
                    </div>
                </div>

                <div className="modal__footer">
                    <button className="btn btn-outline" onClick={close}>
                        Cancel
                    </button>
                    {editable && (
                        <button className="btn btn-gradient" onClick={done}>
                            Update
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    ui: getUI
});
export default connect(state)(ShareDialog);
