import React, { useRef, useEffect, useState } from 'react';

import { connect } from 'react-redux';

// Actions
import * as BookActions from 'redux/actions/book';
import * as BookShippingAddressActions from 'redux/actions/bookshippingaddress';
import * as PaymentProfileActions from 'redux/actions/paymentprofile';

// selectors
import { createStructuredSelector } from 'reselect';
import { getBookById, getUI } from 'redux/selectors';
import { updateUI } from 'redux/actions/ui';

// components
import withBook from 'common/utils/components/withBook';

// Dialogs
import BookOrderDialog from 'pages/Dialogs/BookOrderDialog';

const BookOrder = ({ book, dispatch, match, ui }) => {
    const { bookId } = match?.params;
    const [dialogOpen, setDialogOpen] = useState(false);

    const bookIdRef = useRef(null);
    useEffect(() => {
        if (bookId && !book && bookIdRef.current !== bookId) {
            dispatch(BookActions.poll({ id: bookId }));
        }
        bookIdRef.current = bookId;
    }, [bookId, book, dispatch]);

    useEffect(() => {
        if (book && dialogOpen === false) {
            setTimeout(() => {
                dispatch(
                    updateUI({
                        dialogs: [withBook(BookOrderDialog, book.id)]
                    })
                );
            }, 100);

            setDialogOpen(true);
        }
    }, [book, dispatch, dialogOpen, ui.dialogs]);

    useEffect(() => {
        dispatch(BookShippingAddressActions.fetch());
        // dispatch(PaymentProfileActions.fetch_default());
        dispatch(PaymentProfileActions.fetch());
    }, [dispatch]);

    return <div></div>;
};

const state = createStructuredSelector({
    book: getBookById,
    ui: getUI
});

export default connect(state)(BookOrder);
