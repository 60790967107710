import { URLS } from 'common/urls';
import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router';
import { updateUI } from 'redux/actions/ui';

const PrivateRoute = ({ component: Component, dispatch, ...rest }) => {
    const pathname = rest?.location?.pathname;
    if (!rest.user.isAuthorized && pathname) {
        dispatch(
            updateUI({
                prevPath: pathname
            })
        );
    }
    return (
        <Route
            {...rest}
            render={(props) =>
                rest.user.isAuthorized === true ? (
                    rest.is_lazy ? (
                        <Suspense
                            fallback={
                                <div
                                    style={{
                                        position: 'fixed',
                                        top: '10%'
                                    }}>
                                    Page is Loading...
                                </div>
                            }>
                            <Component {...props} />
                        </Suspense>
                    ) : (
                        <Component {...props} />
                    )
                ) : (
                    <Redirect
                        to={{
                            pathname: URLS.LOGIN,
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
};
const states = (state) => ({ ...state });
export default connect(states)(PrivateRoute);
