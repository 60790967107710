import React from 'react';
import settings from './configs/setting';
import { URLS } from './urls';
import LogoWhite from 'assets/images/logo-white.png';
import LogoWhiteGradient from 'assets/images/logo-white-gradient.png';
import LogoBlackGradient from 'assets/images/logo.png';
import { fontsToStyle } from '../components/Editor/components/Buttons/Fonts/FontsDropdown';
import userIcon from 'assets/images/icons_svg/icon_profile.svg';
import shareIcon from 'assets/images/icons_svg/icon_social-connect.svg';
import lockIcon from 'assets/images/icons_svg/icon_security.svg';
import iconReminders from 'assets/images/icons_svg/icon_reminders.svg';
import iconEmail from 'assets/images/icons_svg/icon_email-to-journal.svg';
import iconBilling from 'assets/images/icons_svg/icon_billing.svg';
import iconThemes from 'assets/images/icons_svg/icon_themes.svg';
import iconAccount from 'assets/images/icons_svg/icon_account.svg';
import chromeIcon from 'assets/images/icons_svg/icon_chrome.svg';
// import iconJrnlGuard from 'assets/images/icons_svg/icon_jrnl-guard.svg';
import iconNotifications from 'assets/images/icons_svg/icon_notifications.svg';
// import iconInvitations from 'assets/images/icons_svg/icon_invitations.svg';
import iconKnowledge from 'assets/images/icons_svg/icon_knowledgebase.svg';
import iconContactSupport from 'assets/images/icons_svg/icon_contact-support.svg';
import iconJrnlBlog from 'assets/images/icons_svg/icon_jrnl-blog.svg';
import iconLogout from 'assets/images/icons_svg/icon_logout.svg';
//import iconImportExport from 'assets/images/icons_svg/icon_import-export.svg';
// Social Icons
import iconSocialGoogle from 'assets/images/icons_svg/icon_social-google.svg';
import iconSocialFacebook from 'assets/images/icons_svg/icon_social-facebook.svg';
import iconSocialInstagram from 'assets/images/icons_svg/icon_social-instagram.svg';

import {
    ALIGNMENT_DATA_KEY,
    ALIGNMENTS,
    INDENT_DATA_KEY
} from 'components/Editor/utils/ExtendedRichUtils';

export const MAX_MOBILE_WIDTH = 1476;
export const SUCCESS_SUFFIX = '_SUCCESS';
export const ERROR_SUFFIX = '_FAIL';

export const default_avatar_url =
    'https://s3.amazonaws.com/jrnl/static/images/avatar.png';

export const DEFAULT_WALLPAPER_IMAGE_URI =
    '/api/v1/public_wallpaper_image/197/';
export const ENTRY_HIDDEN = 100;
export const ENTRY_SHOWN = 101;
export const ENTRY_DATE = 'DATE';
export const ENTRY_ALL = 'ALL';
export const ENTRY_DO_NOT_PRINT = 'DO_NOT_PRINT';
export const ENTRY_MR = 'MR';
export const ENTRY_QA = 'QA';
export const ENTRY_DRAFT = 'draft';
export const ENTRY_PUBLISHED = 'published';
export const ENTRY_PUBLISH = 'publish';
export const BOOK_EXPORT_FORMAT = {
    '6_9_FORMAT': '6X9',
    PDF: 'PDF'
};
export const IMAGE_MAX_SIZE = 500;
export const MEDIA_OPTIONS_PRINT_QR_CODE = 'QR_CODE';
export const MEDIA_OPTIONS_DO_NOT_PRINT_QR_CODE = 'NO_QR_CODE';
export const SHOW_ENTRY_QR_CODE = 'SHOW_ENTRY_QR_CODE';
export const SHOW_MEDIA_QR_CODE = 'SHOW_MEDIA_QR_CODE';

export const SUBTITLE_OPTION_RANGE = 'RANGE';
export const SUBTITLE_OPTION_CUSTOM = 'CUSTOM';

export const JOURNAL_STATE = {
    HIDDEN: 100,
    PRIVATE_SHOWN: 101, // private and shown
    PUBLIC_SHOWN: 111 // public/shared and shown
};
export const JOURNAL_AAM = 'AllAboutMe';
// BOOK BUILDER STATUS
export const BOOK_STATUS_SUCCESS = 100;
export const BOOK_STATUS_DEFAULT = 26;
export const BOOK_STATUS_DRAFT = 2;
export const BOOK_STATUS_ITEM_TO_PROCESS = 33;
export const BOOK_STATUS_BUILDING = 44;
// TODO: add individual constant value
export const SUCCESS_BOOK_BUILDER_STATUS = [BOOK_STATUS_SUCCESS];

// api urls
export const AAM_CATEGORY_URL = `${settings.api}/aam_category/`;
export const AAM_QUESTION_URL = `${settings.api}/aam_question/`;
// avatarimage urls
// export const AVATAR_IMAGE_URL = `${settings.url}${settings.api}/avatar_image/`;
export const AVATAR_IMAGE_URL = `${localStorage.getItem('API_URL')}${
    settings.api
}/avatar_image/`;

// book urls
export const BOOK_URL = `${settings.api}/book/`;
// book order url
export const BOOK_ORDER_URL = `${settings.api}/book_order/`;
// book shipping address url
export const BOOK_SHIPPING_ADDRESS_URL = `${settings.api}/book_shipping_address/`;
// comment url
export const COMMENT_URL = `${settings.api}/comment/`;
export const SHARED_COMMENT_URL = `${settings.api}/shared_comment/`;
// entry url
export const ENTRY_URL = `${settings.api}/entry/`;
export const TIMELINE_ENTRY_URL = `${settings.api}/timeline_entry/`;
export const SHARED_ENTRY_URL = `${settings.api}/shared_entry/`;
export const JOURNAL_SHARING_INVITATION = `${settings.api}/journal_sharing_invitation/`;
// entry image url
export const ENTRY_IMAGE_URL = `${settings.api}/entry_image/`;
// entry media url
export const ENTRY_MEDIA_SEARCH_URL = `${settings.api_v2}/entry_media/search/`;
export const ENTRY_MEDIA_DETECT_FACE_URL = `${settings.api_v2}/entry_media/detect_faces/`;
// instagram url
export const INSTAGRAM_ACCESS_TOKEN_URL = `${settings.api}/instagram_access_token/`;
export const INSTAGRAM_AUTHORIZATION_URL = `${settings.api}/instagram_authorization_url/`;
export const INSTAGRAM_MEDIA_URL = `${settings.api}/instagram_media/`;
export const INSTAGRAM_MEDIA_SEARCH_URL = `${settings.api}/instagram_media/search/`;
// invitaiton url
export const SHARED_ENTRY_SHARING_INVITATION_URL = `${settings.api}/shared_entry_sharing_invitation/`;
export const PUBLIC_ENTRY_SHARING_INVIATAION_URL = `${settings.api}/public_entry_sharing_invitation/`;
export const ENTRY_SHARING_INVITATION_URL = `${settings.api}/entry_sharing_invitation/`;
// journal url
export const JOURNAL_URL = `${settings.api}/journal/`;
export const INCOMING_EMAIL_ADDRESS_URL = `${settings.api}/incoming_email_address/`;
export const JOURNAL_EMAIL_SENDER_API_URL = `${settings.api_v2}/email_senders/`;
// public user url
export const PUBLIC_USER_URL = `${settings.api}/public_user/`;
export const SUBSCRIBE_URL = `${settings.api}/user/me/plan/subscribe/`;
export const UNSUBSCRIBE_URL = `${settings.api}/user/me/plan/unsubscribe/`;
export const ENABLE_2FA_URL = `${settings.api}/user/me/2fa/`;
export const CLOSE_ACCOUNT_URL = `${settings.api}/user/me/delete/`;
export const SUBSCRIPTION_URL = `${settings.api}/user_subscription/`;
// registration url
export const REGISTRATION_URL = `${settings.api}/registration/`;
export const ACTIVATION_URL = `${settings.api}/activation/`;
export const USERNAME_URL = `${settings.api}/username/`;
export const EMAIL_ADDRESS_URL = `${settings.api}/email_address/`;
// search url
export const ENTRY_SEARCH_URL = `${settings.api}/entry/search/`;
// invitation contact list url
export const INVITATION_CONTACT_LIST_URL = `${settings.api}/invitation_contact_list_2/`;
export const DETAILED_CONTACT_LIST_URL = `${settings.api}/invitation_contact_list/`;
export const CREATE_CONTACT_URL = `${settings.api}/invitation_contact_list/`;
// tag url
export const TAG_URL = `${settings.api}/tag/`;
// timeline approval url
export const TIMELINE_APPROVAL_URL = `${settings.api}/timeline_approval/`;
// payment profile url
export const PAYMENT_PROFILE_URL = `${settings.api}/payment_profile/`;
export const PAYMENT_USER_ME_URL = `${settings.api}/payment_user/me/`;
// Instagram Authorization URL
export const INSTAGRAM_O_AUTHORIZATION_URL = `https://www.instagram.com/oauth/authorize?redirect_uri=${settings.redirect_url}/&response_type=code&client_id=${settings.instagram_client_id}&scope=user_profile,user_media`;
// Instagram Connect API URl
export const INSTAGRAM_CONNECT_API_V2_URL = `${settings.api_v2}/auth/instagram/connect/?callback_url=${settings.redirect_url}/`;
export const INSTAGRAM_PROFILE_API_V2_URL = `${settings.api_v2}/auth/instagram/profile/`;
export const INSTAGRAM_MEDIA_API_V2_URL = `${settings.api_v2}/auth/instagram/profile/?media=1`;
export const INSTAGRAM_DISCONNECT_API_V2_URL = `${settings.api_v2}/auth/socials/disconnect/`;
export const SOCIAL_ACCOUNT_DEFAULT_URL = `${settings.api_v2}/social_profile/`;
// Facebook relevant API Urls
export const FACEBOOK_O_AUTHORIZATION_URL = `https://www.facebook.com/v10.0/dialog/oauth?client_id=${settings.facebook_client_id}&response_type=token&display=popup&redirect_uri=${settings.redirect_url}/&scope=public_profile email user_photos user_posts`;
export const FACEBOOK_LOGIN_URL = `https://www.facebook.com/v10.0/dialog/oauth?client_id=${settings.facebook_client_id}&redirect_uri=${settings.redirect_url}/&state={"{st=state123abc,ds=123456789}"}`;

export const FACEBOOK_CONNECT_API_V2_URL = `${settings.api_v2}/auth/facebook/connect/?callback_url=${settings.redirect_url}/`;
export const FACEBOOK_LOGIN_API_V2_URL = `${settings.api_v2}/auth/facebook/?callback_url=${settings.redirect_url}/`;
export const FACEBOOK_MEDIA_API_V2_URL = `${settings.api_v2}/auth/facebook/posts/?media=1`;
// Google relevant API Urls
export const GOOGLE_CONNECT_API_V2_URL = `${settings.api_v2}/auth/google/connect/?callback_url=${settings.redirect_url}/`;
export const GOOGLE_LOGIN_API_V2_URL = `${settings.api_v2}/auth/google/?callback_url=${settings.redirect_url}/`;
export const GOOGLE_UPLOAD_API_V2_URL = `${settings.api_v2}/entry_media/google_upload/`;
export const SOCIALIMAGE_UPLOAD_API_V2_URL = `${settings.api_v2}/entry_media/image_upload/`;
// journal sharing API urlv
export const SHARED_JOURNAL_SHARING_INVITATION_URL = `${settings.api}/journal_sharing_invitation/`;
// Reminder
export const SETTINGS_REMINDER_URL = `${settings.api_v2}/reminder/`;
// Background
export const BACKGROUND_IMAGES_URL = `${settings.api}/public_wallpaper_image/?limit=98&offset=0`;
// Settings Export Data
export const USER_EXPORT_DATA = `${settings.api_v2}/user_export_data/`;
//map
export const MAPBOX_API_URL = 'https://api.mapbox.com/geocoding/v5/';
export const MAPBOX_PLACES_METHOD = 'mapbox.places/';
// Book Creation Steps
export const EXPORT_FORMAT_STEP = 100;
export const JOURNAL_SELECT_STEP = 200;
export const ENTRIES_STEP = 300;
export const ALL_ABOUT_ME_STEP = 400;
export const COLOR_OPTIONS_STEP = 500;
export const PHOTOS_OPTIONS_STEP = 600;
export const FRONT_COVER_STEP = 700;
export const SUB_TITLE_STEP = 800;
export const SPINE_STEP = 900;
export const BACK_COVER_STEP = 1000;
export const CUSTOM_INTRODUCTION_PAGE_STEP = 1100;
export const BOOK_EDIT_STEP = 1111;
export const BOOK_COMPLETE = 11111;
export const book_creation_steps = [
    EXPORT_FORMAT_STEP,
    JOURNAL_SELECT_STEP,
    ENTRIES_STEP,
    ALL_ABOUT_ME_STEP,
    COLOR_OPTIONS_STEP,
    PHOTOS_OPTIONS_STEP,
    FRONT_COVER_STEP,
    SUB_TITLE_STEP,
    SPINE_STEP,
    BACK_COVER_STEP,
    CUSTOM_INTRODUCTION_PAGE_STEP
];

export const SORT = {
    ENTRY_DATE_NEWEST: '-entry_date',
    ENTRY_DATE_OLDEST: 'entry_date',
    CREATED_NEWEST: '-created',
    CREATED_OLDEST: 'created'
};

export const DISPLAY_FILTER = {
    ALL: 'all',
    JUST_ME: 'just_me',
    SHARED_WITH_ME: 'shared_with_me',
    SHARED_BY_ME: 'shared_by_me',
    HIDDEN: 'hidden',
    DRAFT: 'draft'
};

// Book Default Title and Spine Text
export const BOOK_DEFAULT_TITLE = 'My JRNL';

export const STATES_LIST = [
    'AK',
    'AL',
    'AR',
    'AZ',
    'CA',
    'CO',
    'CT',
    'DC',
    'DE',
    'FL',
    'GA',
    'HI',
    'IA',
    'ID',
    'IL',
    'IN',
    'KS',
    'KY',
    'LA',
    'MA',
    'MD',
    'ME',
    'MI',
    'MN',
    'MO',
    'MS',
    'MT',
    'NC',
    'ND',
    'NE',
    'NH',
    'NJ',
    'NM',
    'NV',
    'NY',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VA',
    'VT',
    'WA',
    'WI',
    'WV',
    'WY'
];

export const PROVINCES_LIST = [
    'AB',
    'BC',
    'MB',
    'NB',
    'NL',
    'NS',
    'NT',
    'NU',
    'ON',
    'PE',
    'QC',
    'SK',
    'YT'
];

export const COMMENT_TYPE = {
    ONE_ON_ONE: 'one_on_one',
    PERSONAL: 'personal',
    GROUP: 'group'
};

// Setting List
export const SETTING_LIST = [
    {
        id: 'profile-tab',
        title: 'Profile',
        link: '#profile',
        aria_controls: 'profile',
        icon: userIcon,
        path: URLS.SETTINGS.PROFILE
    },
    {
        id: 'socialConnect-tab',
        title: 'Social Connect',
        link: '#socialConnect',
        aria_controls: 'socialConnect',
        icon: shareIcon,
        path: URLS.SETTINGS.SOCIALCONNECT
    },
    {
        id: 'security-tab',
        title: 'Security',
        link: '#security',
        aria_controls: 'security',
        icon: lockIcon,
        path: URLS.SETTINGS.SECURITY
    },
    {
        id: 'reminders-tab',
        title: 'Reminders',
        link: '#reminders',
        aria_controls: 'reminders',
        icon: iconReminders,
        path: URLS.SETTINGS.REMINDER
    },
    {
        id: 'emailJRNL-tab',
        title: 'Email to JRNL',
        link: '#emailJRNL',
        aria_controls: 'emailJRNL',
        icon: iconEmail,
        path: URLS.SETTINGS.EMAILJRNL
    },
    {
        id: 'billing-tab',
        title: 'Billing',
        link: '#billing',
        aria_controls: 'billing',
        icon: iconBilling,
        path: URLS.SETTINGS.BILLING
    },
    {
        id: 'themes-tab',
        title: 'Themes',
        link: '#themes',
        aria_controls: 'themes',
        icon: iconThemes,
        path: URLS.SETTINGS.THEMES
    },
    {
        id: 'account-tab',
        title: 'Membership',
        link: '#account',
        aria_controls: 'account',
        icon: iconAccount,
        path: URLS.SETTINGS.ACCOUNT
    },
    // {
    //     id: 'jrnlGuard-tab',
    //     title: 'JRNL Guard',
    //     link: '#jrnlGuard',
    //     aria_controls: 'jrnlGuard',
    //     icon: iconJrnlGuard
    // },
    {
        id: 'notifications-tab',
        title: 'Notifications',
        link: '#notifications',
        aria_controls: 'notifications',
        icon: iconNotifications,
        path: URLS.SETTINGS.NOTIFICATION
    }
    // {
    //     id: 'invitations-tab',
    //     title: 'Invitations',
    //     link: '#invitations',
    //     aria_controls: 'invitations',
    //     icon: iconInvitations,
    //     clickable: true,
    //     path: URLS.SETTINGS.INVITATIONS
    // }
    //{
    //    id: 'import-export-tab',
    //    title: 'Import & Export',
    //    link: '#import-export',
    //    aria_controls: 'import-export-tab',
    //    icon: iconImportExport,
    //    path: URLS.SETTINGS.IMPORTEXPORT
    //}
];

export const SUPPORT_SETTING_LIST = [
    {
        id: 'knowledge-tab',
        title: 'Helpdesk',
        link: '#knowledge',
        aria_controls: 'knowledge',
        icon: iconKnowledge
    },
    {
        id: 'contactSupport-tab',
        title: 'Contact Support',
        link: '#contactSupport',
        aria_controls: 'contactSupport',
        icon: iconContactSupport
    },
    {
        id: 'jrnlBlog-tab',
        title: 'JRNL Blog',
        link: '#jrnlBlog',
        aria_controls: 'jrnlBlog',
        icon: iconJrnlBlog
    },
    {
        id: 'chrome-extension-tab',
        title: 'JRNL Extension',
        link: '#jrnlBlog',
        aria_controls: 'chrome-extension',
        icon: chromeIcon,
        clickable: true
    },
    {
        id: 'logout-tab',
        title: 'Logout',
        link: '#logout',
        aria_controls: 'logout',
        icon: iconLogout,
        clickable: true
    }
];

// update user target
export const USER_PROFILE = 'PROFILE';
export const USER_SHARING_STATUS = 'SHARING STATUS';
export const USER_SETTINGS = 'PRIVACY SETTINGS';

// Draft JS Editor Data Types
export const DRAFTJS_PLUGINS = {
    VIDEO: 'VIDEO',
    AUDIO: 'AUDIO',
    IMAGE: 'IMAGE',
    DOCUMENT: 'DOCUMENT'
};

export const ENTRYEDITOR_NEWDATA_TYPES = {
    VIDEO: 'video',
    AUDIO: 'audio',
    IMAGE: 'image',
    CLEAR: 'clear',
    APPLICATION: 'application',
    INSTAGRAM: 'instagram'
};
export const options = {
    customInlineFn: (element, { Style, Entity }) => {
        const font = element.style['font-family'];
        if (font) {
            return Style(fontsToStyle[font]);
        }
    },
    customBlockFn: (element) => {
        const offset = element.attributes.getNamedItem('offset');

        const customBlock = {
            [INDENT_DATA_KEY]: parseInt(offset?.nodeValue) || 0
        };
        if (element.className.indexOf('align-center') >= 0) {
            return {
                data: {
                    [ALIGNMENT_DATA_KEY]: ALIGNMENTS.CENTER,
                    ...customBlock
                }
            };
        } else if (element.className.indexOf('align-right') >= 0) {
            return {
                data: {
                    [ALIGNMENT_DATA_KEY]: ALIGNMENTS.RIGHT,
                    ...customBlock
                }
            };
        } else if (element.className.indexOf('align-left') >= 0) {
            return {
                data: {
                    [ALIGNMENT_DATA_KEY]: ALIGNMENTS.LEFT,
                    ...customBlock
                }
            };
        } else if (element.className.indexOf('align-justify') >= 0) {
            return {
                data: {
                    [ALIGNMENT_DATA_KEY]: ALIGNMENTS.JUSTIFY,
                    ...customBlock
                }
            };
        }
        return {
            data: { ...customBlock }
        };
    }
};

export const PASSWORD_MIN_LENGTH = process.env.REACT_APP_PASSWORD_LENGTH || 8;
export const RESET_PASSWORD_MIN_LENGTH =
    process.env.REACT_APP_PASSWORD_LENGTH_FOR_REGISTER || 8;

// Font Sizes
export const FONT_SIZE_TYPES = {
    DEFAULT: 'DEFAULT',
    SMALL: 'SMALL',
    LARGE: 'LARGE'
};

export const FONT_SIZE_USE_TYPE = {
    ENTRY: 'ENTRY',
    ENTRY_TITLE: 'ENTRY_TITLE',
    ENTRY_JOURNAL: 'ENTRY_JOURNAL'
};

export const FONT_SIZE_VALUES = {
    // post-body
    ENTRY: {
        DEFAULT: '1rem',
        SMALL: '0.9rem',
        LARGE: '1.3rem'
    },
    // entry-title
    ENTRY_TITLE: {
        DEFAULT: '1.3rem',
        SMALL: '1.1rem',
        LARGE: '1.6rem'
    },
    // entry-title-fix
    ENTRY_JOURNAL: {
        DEFAULT: '1.3rem',
        SMALL: '1.2rem',
        LARGE: '1.6rem'
    }
};

export const HEADER_COLORS = {
    WHITE: {
        name: 'WHITE',
        color: '#fff',
        className: 'color-default',
        navBarClassName: 'theme-default theme-light',
        logo: LogoBlackGradient,
        button: '#000'
    },
    GRAY: {
        name: 'GRAY',
        color: '#222222',
        className: 'color-gray',
        navBarClassName: 'theme-gray theme-dark',
        logo: LogoWhiteGradient,
        button: '#fff'
    },
    BLACK: {
        name: 'BLACK',
        color: '#000',
        className: 'color-black',
        navBarClassName: 'theme-black theme-dark',
        logo: LogoWhiteGradient,
        button: '#fff'
    },
    BLUE: {
        name: 'BLUE',
        color: '#0064ff',
        className: 'color-blue',
        navBarClassName: 'theme-blue theme-dark',
        logo: LogoWhiteGradient,
        button: '#fff'
    },
    CYAN: {
        name: 'CYAN',
        color: '#00d7f0',
        className: 'color-teal',
        navBarClassName: 'theme-teal theme-dark',
        logo: LogoWhite,
        button: '#fff'
    },
    MINT: {
        name: 'MINT',
        color: '#20c999',
        className: 'color-mint',
        navBarClassName: 'theme-mint theme-dark',
        logo: LogoWhite,
        button: '#fff'
    },
    GREEN: {
        name: 'GREEN',
        color: '#006335',
        className: 'color-green',
        navBarClassName: 'theme-green theme-dark',
        logo: LogoWhite,
        button: '#fff'
    },
    GOLD: {
        name: 'GOLD',
        color: '#7b4627',
        className: 'color-gold',
        navBarClassName: 'theme-gold theme-dark',
        logo: LogoWhite,
        button: '#fff'
    },
    ORANGE: {
        name: 'ORANGE',
        color: '#f44400',
        className: 'color-orange',
        navBarClassName: 'theme-orange theme-dark',
        logo: LogoWhite,
        button: '#fff'
    },
    PINK: {
        name: 'PINK',
        color: '#c200a3',
        className: 'color-pink',
        navBarClassName: 'theme-pink theme-dark',
        logo: LogoWhite,
        button: '#fff'
    },
    PURPLE: {
        name: 'PURPLE',
        color: '#8228d2',
        className: 'color-violet',
        navBarClassName: 'theme-violet theme-dark',
        logo: LogoWhite,
        button: '#fff'
    },
    RED: {
        name: 'RED',
        color: '#d60000',
        className: 'color-red',
        navBarClassName: 'theme-red theme-dark',
        logo: LogoWhite,
        button: '#fff'
    }
};

export const REMINDER_EVERY_DAY = 1;
export const REMINDER_EVERY_OTHER_DAY = 2;
export const REMINDER_EVERY_WEEK = 7;

// SOCIAL Types
export const SOCIAL_TYPE_INSTAGRAM = 'fb_instagram';
export const SOCIAL_TYPE_FACEBOOK = 'facebook';
export const SOCIAL_TYPE_GOOGLE = 'google';

export const SOCIAL_CONNECT = {
    fb_instagram: {
        name: 'Instagram',
        user_acc: SOCIAL_TYPE_INSTAGRAM,
        icon_name: 'instagram-bg--gradient',
        hasCssIcon: false,
        icon: <img src={iconSocialInstagram} alt="" />
    },
    facebook: {
        name: 'Facebook',
        user_acc: SOCIAL_TYPE_FACEBOOK,
        icon_name: 'facebook-bg',
        hasCssIcon: false,
        icon: <img src={iconSocialFacebook} alt="" />
    },
    google: {
        name: 'Google',
        user_acc: SOCIAL_TYPE_GOOGLE,
        icon_name: 'google-bg',
        hasCssIcon: false,
        icon: <img src={iconSocialGoogle} alt="" />
    }
};

//Devices Types
export const IPHONE = 'IPHONE';
export const ANDROID = 'ANDROID';

// GOOGLE Actions
export const GOOGLE_ACTION_PICKED = 'picked';
export const GOOGLE_ACTION_LOADED = 'loaded';
export const ZIP_CODE_ERROR =
    'The Destination ZIP Code you have entered is invalid.';

export const WALPAPERS_ENDPOINTS = {
    ACRILIC: ['/api/v1/public_wallpaper_category/2/'],
    BLUR: ['/api/v1/public_wallpaper_category/3/'],
    PHOTOS: ['/api/v1/public_wallpaper_category/5/']
};

export const PASSWORD_STATE = 'pass';
export const SIGNUP_STATE = 'SIGNUP';

// Journal Desc Text Word Limit
export const JOURNAL_DESC_TEXT_WORD_LIMIT = 100;
export const JOURNAL_TITLE_LIMIT_OF_CHARACTERS = 50;

// Journal Patterns
export const JOURNAL_PATTERN = {
    STANDARD: 1,
    PHOTO: 11,
    NO_DATES: 22,
    VS_JOURNAL: 33,
    OTHER: 999
};

export const FAVICON_TEXT = [
    { url: URLS.CONTACTS.ROOT, title: 'JRNL | Contacts' },
    { url: URLS.SETTINGS.ROOT, title: 'JRNL | Settings' },
    { url: URLS.PRICING.ROOT, title: 'JRNL | Pricing' }
];

export const FILTERS_EXCLUSSION_URLS = {
    SETTINGS: URLS.SETTINGS.ROOT,
    CONTACTS: URLS.CONTACTS.ROOT,
    BOOK: URLS.BOOK.ROOT
};

//calendar
export const YEAR_START = 1900;
export const YEAR_END = 3000;
//TimeLine Reading Type

export const FULL_VIEW_TYPE = 11;
export const SUMMARY_VIEW_TYPE = 1;

// Timeframe Status
export const TIMEFRAME_STATUS_NONE = 0;
export const TIMEFRAME_STATUS_ERROR = 1;
export const TIMEFRAME_STATUS_LOADING = 2;
export const TIMEFRAME_STATUS_SUCCESS = 3;

// notification types

export const notification_comment = 'entry_comment';

// Error Messages
const FREE_ACCOUNT_PERMISSION_ERROR = 100;
export const ERROR_MESSAGES = {
    FREE_ACCOUNT_PERMISSION_ERROR
};

// AAM Question Type
export const AAM_QUESTION_TYPE_CUSTOM = 1;
export const AAM_QUESTION_TYPE_DEFAULT = 0;

export const AAM_JOURNAL_TYPES = ['AllAboutMe', 'AAM'];

const DEFAULT_HELPDESK_URL = 'https://helpdesk.jrnl.com/en/';

export const SETTING_CONNECT_URLS = {
    'account-payments': 'https://jrnl.com/settings/account/',
    'book-payments': 'https://jrnl.com/book-order/',
    'card-payments': 'https://jrnl.com/settings/billing/',
    'contactSupport-tab': 'mailto:helpdesk@jrnl.com',
    'knowledge-tab': DEFAULT_HELPDESK_URL,
    'jrnlBlog-tab': 'https://blog.jrnl.com/',
    'chrome-extension-tab':
        'https://chrome.google.com/webstore/detail/jrnl/enbkpihjpgcnigclhlenbenfidgigagf?hl=en&authuser=8',
    'firefox-extension-tab':
        'https://addons.mozilla.org/en-US/firefox/addon/jrnl-extension/'
};

export const TWOFA_ERROR_MESSAGE =
    'The user enabled 2factor authentication. The code is sent.';

export const FOOTER_URLS = {
    Publishing: 'https://jrnl.com/publishing.html',
    JRNL_101: 'https://jrnl.com/101.html',
    Facebook: 'https://www.facebook.com/jrnlcom',
    Instagram: 'https://www.instagram.com/jrnl/',
    Twitter: 'https://twitter.com/jrnl',
    Knowledgebase: DEFAULT_HELPDESK_URL,
    Create_a_Ticket: 'mailto:helpdesk@jrnl.com',
    Blog: 'https://blog.jrnl.com/',
    Privacy: 'https://jrnl.com/privacy',
    Terms: 'https://jrnl.com/terms.html'
};

export const BOOK_ORDER_LIMIT = {
    PRO: 12,
    THANKYOU: 1,
    FREE: 0
};

export const DEFAULT_TIMEZONE = 'America/New_York';

// update UI data from user's object
export const COMPACT_MODE = 'compact-mode',
    DEFAULT_MODE = 'default-mode',
    EXPANDED_MODE = 'expanded-mode';
export const VIEW_WIDTH_MAP = {
    1: COMPACT_MODE,
    11: DEFAULT_MODE,
    111: EXPANDED_MODE
};

export const PLAN_LABELS = {
    FREE: 'JRNLER',
    LOYALTY: 'Loyal JRNLer',
    PRO: 'Power JRNLer',
    PRO_MONTHLY: 'POWER JRNLER MONTHLY',
    PRO_QUARTERLY: 'POWER JRNLER QUARTERLY',
    PRO_YEARLY: 'POWER JRNLER YEARLY'
};

export const SUBSCRIPTION_LABELS = {
    FREE: 'free',
    MONTH: 'month',
    QUARTER: 'quarter',
    YEAR: 'year'
};

export const PLAN_PRICES = {
    FREE: {
        DEFAULT: 0,
        EXTRA: 0
    },
    LOYALTY: {
        DEFAULT: 34.99,
        EXTRA: 34.99
    },
    PRO: {
        DEFAULT: 49.99,
        EXTRA: 39.99
    },
    PRO_MONTHLY: {
        DEFAULT: 8.99,
        EXTRA: 8.99
    },
    PRO_QUARTERLY: {
        DEFAULT: 19.99,
        EXTRA: 19.99,
        MONTHLY: 6.67
    },
    PRO_YEARLY: {
        DEFAULT: 49.99,
        EXTRA: 49.99,
        MONTHLY: 4.17
    }
};

export const PRICES_LOYALTY_TO_PRO = 44.99;
export const VIP1 = 1;
export const DATA_BUILDING_STATUS = 33;
export const DELETE_VALUE = 'DELETE';
export const DEFAULT_COUNTRY = 'US';
export const DEFAULT_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export const NO_STATE = 'NC';
export const OLD_USER_TERM = 1;

export const PLAN_SUBSCRIPTION_PRICES = [
    {
        title: 'Free',
        value: 0
    },
    {
        title: 'Monthly',
        value: 8.99
    },
    {
        title: 'Quarterly',
        value: 19.99
    },
    {
        title: 'Annually',
        value: 49.99
    }
];

export const SUBSCRIPTIONS_FREE = PLAN_SUBSCRIPTION_PRICES[0].title;
export const SUBSCRIPTIONS_MONTHLY = PLAN_SUBSCRIPTION_PRICES[1].title;
export const SUBSCRIPTIONS_QUARTERLY = PLAN_SUBSCRIPTION_PRICES[2].title;
export const SUBSCRIPTIONS_YEARLY = PLAN_SUBSCRIPTION_PRICES[3].title;

export const DISCOUNT = 20;
export const HTTP_CODES = {
    ACCEPTED: 202,
    BAD_REQUEST: 400,
    CREATED: 201,
    OK: 200,
    success: [200, 202],
    error: [0, 408, 503, 504]
};
