import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import JournalsAndTags from 'pages/JournalsAndTags/JournalsAndTags';
import { useParams } from 'react-router';
import { Timeline } from '../index';
import * as FilterActions from 'redux/actions/filter';

const JournalTags = ({ dispatch }) => {
    useEffect(() => {
        document.title = 'JRNL | Journals';
    }, []);
    const { journalId } = useParams();

    useEffect(() => {
        if (journalId) {
            dispatch(
                FilterActions.update({
                    entryViewMode: 'sharing_journal'
                })
            );
        }
    }, [journalId, dispatch]);

    if (journalId) {
        return <Timeline />;
    } else {
        return (
            <div>
                <main id="main" style={{ marginLeft: 0 }} className="app-main">
                    <JournalsAndTags />
                </main>
            </div>
        );
    }
};

export default connect()(JournalTags);
