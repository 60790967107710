import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

// Actions
import * as UserActions from 'redux/actions/user';
import { closeDialog } from 'common/utils/dialog-utils';
import { RESET_PASSWORD_MIN_LENGTH } from 'common/constant';

const ChangePasswordDialog = ({ dispatch }) => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatedPassword, setRepeatedPassword] = useState('');
    const [error, setError] = useState(null);

    useEffect(() => {
        if (oldPassword.length < 1) {
            return setError('Please enter a current password.');
        } else if (newPassword.length < RESET_PASSWORD_MIN_LENGTH) {
            return setError(
                `New password needs to have ${RESET_PASSWORD_MIN_LENGTH} or more characters.`
            );
        } else if (newPassword !== repeatedPassword) {
            return setError('New and repeated password are not the same.');
        } else {
            return setError(null);
        }
    }, [newPassword, oldPassword.length, repeatedPassword]);

    const changePassword = useCallback(() => {
        dispatch(
            UserActions.updatePassword({
                old_password: oldPassword,
                new_password: newPassword,
                repeated_password: repeatedPassword
            })
        ).then((response) => {
            const data = response.payload.data;
            if (
                Array.isArray(data.new_password) &&
                data.new_password.length > 0
            ) {
                setError(data.new_password[0]);
            } else {
                closeDialog();
            }
        });
    }, [dispatch, newPassword, oldPassword, repeatedPassword]);

    return (
        <div id="step-verification-modal" className="modal-frame">
            <div className="modal-box modal-box--400">
                <div className="modal__header">Change Password</div>
                <div className="modal__body">
                    <div className="form-group">
                        <div className="form-group__item">
                            <label htmlFor="password">
                                Enter your actual password
                            </label>
                            <input
                                type="password"
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                                placeholder=""
                            />
                        </div>
                        <div className="form-group__item">
                            <label htmlFor="password">
                                Enter your new password
                            </label>
                            <input
                                type="password"
                                placeholder=""
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </div>
                        <div className="form-group__item">
                            <label htmlFor="password">
                                Repeat your new password
                            </label>
                            <input
                                type="password"
                                value={repeatedPassword}
                                onChange={(e) =>
                                    setRepeatedPassword(e.target.value)
                                }
                                placeholder=""
                            />
                            {error && (
                                <div className="form__error">{error}</div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="modal__footer">
                    <button className="btn btn-outline" onClick={closeDialog}>
                        Cancel
                    </button>
                    <button
                        className="btn btn-gradient"
                        onClick={changePassword}
                        disabled={error}>
                        Save Changes
                    </button>
                </div>
            </div>
        </div>
    );
};

export default connect()(ChangePasswordDialog);
