import _ from 'underscore';
import { push } from 'react-router-redux';

import * as EntryActions from 'redux/actions/entry';
import * as FilterActions from 'redux/actions/filter';
import * as InvitationActions from 'redux/actions/invitation';
import * as JournalInvitationActions from 'redux/actions/journalinvitation';
import { updateUI } from 'redux/actions/ui';

import { ENTRY_HIDDEN, ENTRY_SHOWN } from 'common/constant';
import withEntry from 'common/utils/components/withEntry';
import { checkObject } from 'common/utils/validatecheck/checkVariableTypes';

import InvitationDialog from 'pages/Dialogs/InvitationDialog';
import DeleteEntryDialog from 'pages/Dialogs/DeleteEntryDialog';
import MoveEntryDialog from 'pages/Dialogs/MoveEntryDialog';
import { checkUserSame } from 'common/utils/resourceUtils';

// utils
import arrayUtils from 'common/utils/arrayUtils';
import CopyEntryDialog from 'pages/Dialogs/CopyEntryDialog';

export const getActions = (dispatch, entry, user) => {
    return {
        delete: () => {
            dispatch(
                updateUI({
                    dialogs: [withEntry(DeleteEntryDialog, entry)]
                })
            );
        },

        sharedEntryDelete: async () => {
            // find invitation for user
            const invitation = (entry.invitations || []).find((invitation) =>
                checkUserSame(invitation.user, user)
            );

            // if invitation exists, then delete it.
            if (invitation) {
                await dispatch(InvitationActions.remove(invitation, entry.id));
            } else {
                // remove journal invitations
                const journals = arrayUtils.getSetOfArray(
                    [...entry.extra_journals, entry.journal],
                    (items, obj) => items.find((item) => item.id === obj.id)
                );
                (journals || []).forEach((journal) => {
                    const invitation = (
                        journal.invitations || []
                    ).find((invitation) =>
                        checkUserSame(invitation.user, user)
                    );

                    if (invitation) {
                        dispatch(
                            JournalInvitationActions.remove(
                                invitation,
                                journal.id
                            )
                        );
                    }
                });
            }
        },

        share: () => {
            dispatch(
                updateUI({
                    dialogs: [withEntry(InvitationDialog, entry)]
                })
            );
        },

        move: () => {
            dispatch(
                updateUI({
                    dialogs: [withEntry(MoveEntryDialog, entry)]
                })
            );
        },

        copy: () => {
            dispatch(
                updateUI({
                    dialogs: [withEntry(CopyEntryDialog, entry)]
                })
            );
        },

        journalTitleClick: () => {
            const journal = entry.journal;
            if (journal) {
                if (typeof journal === 'string') {
                    dispatch(
                        FilterActions.update({ filterByJournal: [journal] })
                    );
                } else {
                    const filterByJournal = (journal.journals || []).find(
                        (obj) => obj.id === journal
                    );
                    if (filterByJournal) {
                        dispatch(
                            FilterActions.update({
                                filterByJournal: [filterByJournal]
                            })
                        );
                    }
                }
            }
        },

        comment: (entry) => {
            if (entry && entry.id) {
                dispatch(push(`/entry/view/${entry.id}?comments=1`));
            }
        },

        hide: () => {
            dispatch(EntryActions.patch({ state: ENTRY_HIDDEN }, entry.id));
        },

        show: () => {
            dispatch(EntryActions.patch({ state: ENTRY_SHOWN }, entry.id));
        }
    };
};

export const getJournal = (journals, entry) => {
    if (journals) {
        const entryJournal = (journals || []).find(
            (journal) =>
                (checkObject(entry.journal) &&
                    entry.journal.resource_uri === journal.resource_uri) ||
                entry.journal === journal.resource_uri ||
                entry.journal === journal.id
        );

        return entryJournal || { title: 'Shared Journal' };
    } else {
        return { title: 'Shared Journal' };
    }
};

export const getQuestion = (questions, entry) => {
    if (questions) {
        const entryQuestion = (questions || []).find(
            (question) =>
                entry.question === question?.resource_uri ||
                entry.question === question?.id
        );

        if (entryQuestion) {
            return entryQuestion;
        }
    }
};

export const getEntryUser = (public_user, entryUsers, entry, user) => {
    if (
        checkUserSame(entry.user, user) ||
        checkUserSame(entry.user, public_user)
    ) {
        return _.extend(
            {},
            _.pick(user, [
                'public_display_name',
                'avatar_image_url',
                'sharing'
            ]),
            {
                isOwner: true
            }
        );
    }
    const entryUser = (entryUsers || []).find((obj) =>
        checkUserSame(obj, entry.user)
    );

    return entryUser;
};

export const isEditable = (user, public_user, entry) => {
    return Boolean(
        checkUserSame(entry.user, user) ||
            checkUserSame(entry.user, public_user)
    );
};
