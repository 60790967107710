import { getTZStr } from 'common/utils/dateUtils';
import extractIdFromResourceUri from 'common/utils/extractIdFromResourceUri';

export default (filter) => {
    const {
        entryViewMode,
        filterByJournal,
        filterByTag,
        question,
        entry_type,
        journal,
        calendar_min_date,
        calendar_max_date
    } = filter;

    const options = {};

    if (filterByJournal) {
        options.journal = extractIdFromResourceUri('journal', filterByJournal);
    }

    if (journal) {
        options['journal'] = journal;
    }
    if (filterByTag) {
        options['tags__id'] = extractIdFromResourceUri('tag', filterByTag);
    }

    if (question) {
        options.question = question;
    }
    if (entry_type) {
        options.entry_type = entry_type;
    }

    if (calendar_min_date && calendar_max_date) {
        options['entry_date__gte'] = calendar_min_date;
        options['entry_date__lte'] = calendar_max_date;
    }

    options['utc_offset'] = getTZStr();

    if (entryViewMode) {
        switch (entryViewMode) {
            case 'shared_by_me':
            case 'shared_with_me': {
                options[entryViewMode] = true;
                break;
            }
            default: {
                break;
            }
        }
    }

    return options;
};
