import React, { Fragment, useCallback, useEffect, useState } from 'react';
// Redux
import { connect } from 'react-redux';

// Selectors
import { createStructuredSelector } from 'reselect';
import {
    getFacebookState,
    getFacebookProfile,
    getFacebookMedia,
    getFacebookMediaPaging,
    getUI,
    getSocialAccounts
} from 'redux/selectors';

// Dialogs
import SocialConnectDialog from 'pages/Dialogs/SocialMedia/SocialConnectDialog';

// Actions
import * as FacebookActions from 'redux/actions/facebook';
import * as UserActions from 'redux/actions/user';
import { updateUI } from 'redux/actions/ui';
import withSocialType from 'common/utils/components/withSocialType';
import { SOCIAL_TYPE_FACEBOOK } from 'common/constant';

// Icons
import iconCheckSm from 'assets/images/icons_svg/icon_check-sm.svg';
// import withFreeAccountCheck from 'common/utils/components/withFreeAccountCheck';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { openDialog } from 'common/utils/dialog-utils';
import SocialImageUploadDialog from 'pages/Dialogs/SocialImageUploadDialog';
import AddJournalEntry from 'components/media/AddJournalEntry';

const FacebookMediaContent = ({
    facebookProfile,
    facebookMedia,
    facebookPaging,
    socialAccounts,
    facebook,
    dispatch,
    close,
    ui,
    media_view
}) => {
    const [selectedImages, setSelectedImages] = useState([]);
    const [facebookAccount, setFacebookAccount] = useState(null);
    // const [selectedEntry, setSelectedEntry] = useState();
    const [selectedEntryList, setSelectedEntryList] = useState([null]);

    const addPhotos = useCallback(() => {
        const imageDatas = selectedImages.map((item) => ({
            src: item.images[0].src,
            isFacebook: true,
            caption: ui.importFacebookDesc ? item.name : null,
            type: 'image'
        }));
        const data_ = media_view
            ? {
                  images: imageDatas,
                  media_view: true,
                  selectedEntry: selectedEntryList[0]
              }
            : {
                  images: imageDatas
              };
        openDialog(SocialImageUploadDialog, data_);

        if (
            !media_view &&
            facebookAccount?.use_facebook_date &&
            selectedImages.length === 1
        ) {
            const image = selectedImages[0];
            dispatch(
                updateUI({
                    newEntry: {
                        ...ui.newEntry,
                        entry_date: image.created_time
                    }
                })
            );
        }
    }, [
        selectedImages,
        dispatch,
        ui.importFacebookDesc,
        facebookAccount,
        ui.newEntry,
        media_view,
        selectedEntryList
    ]);

    useEffect(() => {
        dispatch(FacebookActions.fetchFacebookMedia()).then((response) => {
            if (response.payload.data.error) {
                dispatch(
                    updateUI({
                        dialogs: [
                            withSocialType(
                                SocialConnectDialog,
                                SOCIAL_TYPE_FACEBOOK
                            )
                        ]
                    })
                );
            }
        });
    }, [dispatch]);

    const onImageClick = useCallback(
        (item) => {
            setSelectedImages((prevState) => {
                const newState = [...(prevState || [])];
                const index = newState.findIndex((obj) => obj.id === item.id);
                if (media_view) {
                    return index >= 0 ? [] : [item];
                } else {
                    if (index >= 0) {
                        newState.splice(index, 1);
                    } else {
                        newState.push(item);
                    }
                    return newState;
                }
            });
        },
        [media_view]
    );

    const renderFacebookPhotoItem = useCallback(
        (item) => {
            const selected =
                (selectedImages || []).findIndex((obj) => obj.id === item.id) >=
                0;
            const className = selected
                ? 'image-grid__item selected-img'
                : 'image-grid__item';
            return (
                <div
                    key={item.id}
                    className={className}
                    onClick={() => onImageClick(item)}>
                    <img
                        src={item.images[0]?.src}
                        alt="facebook"
                        className="image-grid__img"
                    />
                    {selected && (
                        <div className="file__check">
                            <img src={iconCheckSm} alt="" />
                        </div>
                    )}
                </div>
            );
        },
        [onImageClick, selectedImages]
    );

    const renderFacebookPhotos = useCallback(() => {
        return (facebookMedia || []).map((item) => {
            return renderFacebookPhotoItem(item);
        });
    }, [renderFacebookPhotoItem, facebookMedia]);

    const isBottom = (el, elparent) => {
        return (
            el.getBoundingClientRect().bottom <=
            elparent.getBoundingClientRect().bottom
        );
    };
    const onBookScrolling = useCallback(() => {
        const wrappedElement = document.getElementById('media-container');
        const parentElement = document.getElementById('media-parent');
        if (
            isBottom(wrappedElement, parentElement) &&
            facebookPaging.next &&
            facebook.isPending === false
        ) {
            dispatch(
                FacebookActions.fetchFacebookNextMedia(facebookPaging.next)
            );
        }
    }, [facebookPaging, dispatch, facebook]);

    const changeAccount = useCallback(() => {
        dispatch(
            updateUI({
                dialogs: [
                    withSocialType(SocialConnectDialog, SOCIAL_TYPE_FACEBOOK)
                ]
            })
        );
    }, [dispatch]);

    const updatePostDateOption = useCallback(() => {
        if (facebookAccount) {
            dispatch(
                UserActions.updateAccountDefault({
                    social_account: facebookAccount.id,
                    use_facebook_date: !facebookAccount?.use_facebook_date
                })
            ).then((response) => {
                dispatch(UserActions.fetch());
            });
        }
    }, [facebookAccount, dispatch]);

    // component did mount
    useEffect(() => {
        const wrappedElem = document.getElementById('media-container');
        wrappedElem.addEventListener('scroll', onBookScrolling);
        return () => {
            wrappedElem.removeEventListener('scroll', onBookScrolling);
        };
    }, [onBookScrolling]);

    // get default facebook social account
    useDeepCompareEffect(() => {
        if (socialAccounts) {
            const index = socialAccounts.findIndex(
                (item) => item.provider === 'facebook' && item.default
            );
            if (index >= 0) {
                setFacebookAccount(socialAccounts[index]);
            }
        }
    }, [socialAccounts]);

    const addPhotosBtnDisabled = selectedImages.length === 0;
    const addPhotosBtnClassName = `btn btn-gradient ${
        addPhotosBtnDisabled ? 'opacity-disabled' : ''
    }`;
    const multiImageSelected = selectedImages.length > 1;

    const changeEntryStatus = useCallback(
        (entry, status, key) => {
            setSelectedEntryList((prevState) => {
                if (status !== true && prevState.length === key) {
                    return [...prevState, entry];
                }
                const entryList = [...prevState];
                let newEntryList = [];
                const selectEntry =
                    status === true
                        ? selectedEntryList[key] &&
                          selectedEntryList[key]?.id === entry.id
                            ? null
                            : entry
                        : null;
                newEntryList = entryList.map((entryEle, index) => {
                    return index === key ? selectEntry : entryEle;
                });
                return newEntryList;
            });
        },
        [selectedEntryList]
    );

    const deleteJournalEntry = useCallback((index) => {
        setSelectedEntryList((prevState) => {
            const entryList = [...prevState];
            entryList.splice(index, 1);
            return entryList;
        });
    }, []);

    const renderAddJournalEntry = useCallback(() => {
        return (selectedEntryList || []).map((selectedEntry, index) => {
            return (
                <AddJournalEntry
                    index={index}
                    key={`add-journal-entry-component-${index}`}
                    selectedEntry={selectedEntry}
                    changeEntryStatus={changeEntryStatus}
                    deleteJournalEntry={deleteJournalEntry}
                />
            );
        });
    }, [selectedEntryList, changeEntryStatus, deleteJournalEntry]);

    return (
        <Fragment>
            <div className="modal-box modal-box--gallery modal-box--800">
                <div className="modal__header">Add Photos from Facebook</div>
                <div className="social-profile">
                    <div className="social-profile__username">
                        @{facebookProfile?.username}
                    </div>
                    <div
                        onClick={changeAccount}
                        className="social-profile__change">
                        Switch account
                    </div>
                    <div className="social-media-description form-group ml-auto">
                        <div className="checkbox-item">
                            <input
                                type="checkbox"
                                id="socialDesc"
                                name="socialDesc"
                                className="checkbox"
                                checked={ui.importFacebookDesc}
                                onClick={() => {
                                    dispatch(
                                        updateUI({
                                            importFacebookDesc: !ui.importFacebookDesc
                                        })
                                    );
                                }}
                            />
                            <label
                                className="default-journal-label"
                                htmlFor="socialDesc">
                                Import Facebook Posts as descriptions
                            </label>
                        </div>
                    </div>
                </div>
                <div className="modal__body" id="media-container">
                    <div
                        className="image-grid"
                        onScroll={onBookScrolling}
                        id="media-parent">
                        {renderFacebookPhotos()}
                    </div>
                </div>
                <div className="social-media-description form-group mt-2 mr-auto">
                    {!multiImageSelected && (
                        <div className="checkbox-item">
                            <input
                                type="checkbox"
                                id="useFacebookDate"
                                name="useFacebookDate"
                                className="checkbox"
                                checked={facebookAccount?.use_facebook_date}
                                onClick={updatePostDateOption}
                            />
                            <label
                                className="default-journal-label"
                                htmlFor="useFacebookDate">
                                Use Facebook date & time
                            </label>
                        </div>
                    )}
                </div>
                {media_view && renderAddJournalEntry()}
                <div className="modal__footer">
                    <button onClick={close} className="btn btn-outline">
                        Cancel
                    </button>
                    <button
                        onClick={addPhotos}
                        disabled={addPhotosBtnDisabled}
                        className={addPhotosBtnClassName}>
                        Add Photos
                    </button>
                </div>
            </div>
        </Fragment>
    );
};

const state = createStructuredSelector({
    facebookProfile: getFacebookProfile,
    facebookMedia: getFacebookMedia,
    facebookPaging: getFacebookMediaPaging,
    facebook: getFacebookState,
    socialAccounts: getSocialAccounts,
    ui: getUI
});

export default connect(state)(FacebookMediaContent);
