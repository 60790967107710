import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

// images
import defaultUser from 'assets/images/jrnl-logo-circle.png';

import { updateUI } from 'redux/actions/ui';

const OnboardingFirstDialog = ({
    dispatch,
    title,
    text,
    onContinue,
    close
}) => {
    const [checked, setChecked] = useState(false);
    const closeDialog = useCallback(() => {
        dispatch(
            updateUI({
                onBoarding: false,
                onBoardingEnd: true
            })
        );
        localStorage.setItem('skipOnboarding', '1');
        close();
    }, [close, dispatch]);

    const _onContinue = useCallback(() => {
        close();
        onContinue && onContinue(checked);
    }, [onContinue, close, checked]);

    return (
        <div id="delete-sender-modal" className="modal-frame">
            <div className="modal-box modal-box--delete modal-box--500">
                <div className="onboarding-modal">
                    <div className="onboarding-modal__img">
                        <img
                            src={defaultUser}
                            alt="banner-img"
                            className="img-fluid"
                        />
                    </div>
                    <div className="onboarding-modal__content">
                        <div className="onboarding-modal__title">{title}</div>
                        <div className="onboarding-modal__subtitle">{text}</div>
                        <div className="checkbox-item">
                            <input
                                className="checkbox"
                                type="checkbox"
                                name="againShow"
                                id="againShow"
                                defaultChecked={checked}
                                onChange={() => setChecked(!checked)}
                            />
                            <label
                                htmlFor="againShow"
                                className="default-journal-label">
                                Don&apos;t Show This Message Again
                            </label>
                        </div>
                    </div>
                    <div className="onboarding-modal__actions">
                        <button
                            className="btn btn-outline btn-skip"
                            onClick={closeDialog}>
                            Skip Onboarding
                        </button>
                        <div></div>
                        <button
                            className="btn btn-continue"
                            onClick={_onContinue}>
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect()(OnboardingFirstDialog);
