import React, { useEffect, useCallback, useMemo } from 'react';
// redux
import { connect } from 'react-redux';
// Selectors
import { getUI } from 'redux/selectors';
import { createStructuredSelector } from 'reselect';
// Actions
import { updateUI } from 'redux/actions/ui';
// utils
import { checkColorSetup } from 'common/utils/validatecheck/bookcreate';

const ColorOptionsStep = ({ display, ui, dispatch }) => {
    // check validability
    const color = useMemo(() => ui.color, [ui]);
    useEffect(() => {
        if (display) {
            dispatch(
                updateUI({
                    canGoNext: checkColorSetup(color)
                })
            );
        }
        return () => {};
    }, [display, color, dispatch]);

    // handlers
    const onChangeColor = useCallback(
        (color) => {
            dispatch(
                updateUI({
                    color
                })
            );
        },
        [dispatch]
    );
    return (
        <div
            className={`new-book-step-5 new-book-step ${
                display ? 'active-form' : ''
            }`}>
            <div className="section-title">Color Options</div>
            <div className="new-book-step-form">
                <p>
                    Choose to export your book in color or black and white. You
                    might want to consider black and white if your book contains
                    only text.
                </p>
                <div className="color-options">
                    <div className="form-group">
                        <div className="radio-item">
                            <input
                                onClick={() => onChangeColor(true)}
                                onChange={() => {}}
                                className="radio"
                                type="radio"
                                id="fullColors"
                                name="color-options"
                                checked={ui.color === true}
                            />
                            <label
                                onClick={() => onChangeColor(true)}
                                htmlFor="fullColors">
                                Print in Full Color
                            </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="radio-item">
                            <input
                                onClick={() => onChangeColor(false)}
                                onChange={() => {}}
                                className="radio"
                                type="radio"
                                id="bnwColors"
                                name="color-options"
                                checked={ui.color === false}
                            />
                            <label
                                onClick={() => onChangeColor(false)}
                                htmlFor="bnwColors">
                                Print in Black and White
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    ui: getUI
});
export default connect(state)(ColorOptionsStep);
