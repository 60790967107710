import moment from 'moment';
import moment_tz from 'moment-timezone';
import {
    BOOK_STATUS_ITEM_TO_PROCESS,
    ENTRY_DO_NOT_PRINT,
    ENTRY_ALL,
    ENTRY_DATE,
    BOOK_STATUS_DRAFT,
    BOOK_COMPLETE
} from 'common/constant';
import { convertUTCTime } from 'common/utils/dateUtils';

export default (ui, isCreate = true, isDraft = false, step = BOOK_COMPLETE) => {
    const {
        aam,
        entryRange,
        color,
        photos,
        comments,
        book_select_journal = true,
        mediaOptions: media_option,
        mediaPrivacyOptions: media_privacy_permission,
        export_format,
        timezone
    } = ui;
    let { book_extra_journals = [], book_tags = [] } = ui;

    const aamRange = aam === ENTRY_DO_NOT_PRINT ? '' : aam;
    let start_date,
        start_datetime,
        end_date,
        end_datetime,
        aam_start_datetime,
        aam_end_datetime;
    let journal = ui.selectedJournal?.id;

    switch (entryRange) {
        case ENTRY_ALL: {
            start_date = undefined;
            end_date = undefined;
            break;
        }
        case ENTRY_DATE: {
            if (ui.minDate) {
                start_date = convertUTCTime(ui.minDate).format('YYYY-MM-DD');
                start_datetime = convertUTCTime(ui.minDate)
                    .startOf('day')
                    .format('YYYY-MM-DDTHH:mm:ss');
            } else {
                start_date = moment
                    .utc(ui.selectedJournal?.first_entry_date)
                    .format('YYYY-MM-DD');
                start_datetime = moment
                    .utc(ui.selectedJournal?.first_entry_date)
                    .format('YYYY-MM-DDTHH:mm:ss');
            }

            if (ui.maxDate) {
                end_date = convertUTCTime(ui.maxDate).format('YYYY-MM-DD');
                end_datetime = convertUTCTime(ui.maxDate)
                    .endOf('day')
                    .format('YYYY-MM-DDTHH:mm:ss');
            } else {
                end_date = moment
                    .utc(ui.selectedJournal?.last_entry_date)
                    .format('YYYY-MM-DD');
                end_datetime = moment
                    .utc(ui.selectedJournal?.last_entry_date)
                    .format('YYYY-MM-DDTHH:mm:ss');
            }
            break;
        }
        case ENTRY_DO_NOT_PRINT: {
            journal = undefined;
            start_date = undefined;
            end_date = undefined;
            book_extra_journals = [];
            book_tags = [];
            break;
        }
        default: {
            break;
        }
    }

    if (aamRange === ENTRY_DATE) {
        aam_start_datetime = convertUTCTime(ui.aamMinDate)
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm:ss');
        aam_end_datetime = convertUTCTime(ui.aamMaxDate)
            .endOf('day')
            .format('YYYY-MM-DDTHH:mm:ss');
    }
    const book = {
        export_format,
        all_about_me: aamRange || '',
        color,
        photos: !!photos,
        comments: !!comments,
        book_builder_status: !isDraft
            ? BOOK_STATUS_ITEM_TO_PROCESS
            : BOOK_STATUS_DRAFT,
        timezone,
        timezone_offset: moment_tz().tz(timezone).format('ZZ'),
        end_date,
        end_datetime,
        start_date,
        start_datetime,
        journal,
        title: ui.title || '',
        subtitle: ui.subtitle || '',
        spine: ui.spine || '',
        back_cover: ui.back_cover || '',
        custom_introduction: ui.custom_introduction || '',
        book_draft_step: step,
        extra_journals: book_select_journal
            ? book_extra_journals.map((item) =>
                  isCreate ? item.id : item.resource_uri
              )
            : null,
        tags: !book_select_journal
            ? book_tags.map((item) => (isCreate ? item.id : item.resource_uri))
            : null,
        aam_start_datetime,
        aam_end_datetime,
        media_option,
        media_privacy_permission
    };

    return book;
};
