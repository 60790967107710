import React, { useCallback, useState } from 'react';

// Actions
import { useDispatch } from 'react-redux';

import { isEmpty } from 'common/utils/arrayUtils';

// Dialogs
import * as EntryMediaActions from 'redux/actions/entrymedia';
import MediaSearchInput from 'components/Subheader/MediaSearchInput';

const MediaSearch = () => {
    const dispatch = useDispatch();
    const [queryString, SetQueryString] = useState('');

    const updateQuery = useCallback((query) => {
        SetQueryString(query);
    }, []);

    const searchEntries = useCallback(() => {
        if (queryString && !isEmpty(queryString)) {
            dispatch(EntryMediaActions.search(queryString));
        } else {
            dispatch(EntryMediaActions.fetchAll());
        }
    }, [dispatch, queryString]);

    return (
        <>
            <MediaSearchInput
                onEnterKeyDown={searchEntries}
                onChange={updateQuery}
                query={queryString}
            />
            {/* <div>{isSearchEntered && <MediaGallery media={media} />}</div> */}
        </>
    );
};

export default MediaSearch;
