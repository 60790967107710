import React, { useCallback } from 'react';

// Selectors
import {
    SOCIAL_TYPE_FACEBOOK,
    SOCIAL_TYPE_INSTAGRAM,
    SOCIAL_TYPE_GOOGLE
} from 'common/constant';
import InstagramContent from 'pages/Dialogs/SocialMedia/ConnectContents/InstagramContent';
import FacebookContent from 'pages/Dialogs/SocialMedia/ConnectContents/FacebookContent';
import GoogleContent from 'pages/Dialogs/SocialMedia/ConnectContents/GoogleContent';

const SocialConnectDialog = ({ close, type }) => {
    const renderConnectContent = useCallback(() => {
        switch (type) {
            case SOCIAL_TYPE_INSTAGRAM: {
                return <InstagramContent close={close} />;
            }
            case SOCIAL_TYPE_FACEBOOK: {
                return <FacebookContent close={close} />;
            }
            case SOCIAL_TYPE_GOOGLE: {
                return <GoogleContent close={close} />;
            }
            default: {
                return null;
            }
        }
    }, [close, type]);

    return (
        <div className="modal-frame" id="social-media-modal">
            {renderConnectContent()}
        </div>
    );
};

export default SocialConnectDialog;
