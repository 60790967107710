import React, { useCallback } from 'react';
import NotificationCard from 'components/NotificationCard';
import withMemo from 'components/HOC/withMemo';
import { useSelector, useDispatch } from 'react-redux';
import { setNotificationsLimit } from 'redux/actions/notification';
import {
    getNotificationsLimit,
    getNotificationsTotalCount
} from 'redux/selectors';
import { Link } from 'react-router-dom';
import { URLS } from 'common/urls';
import { markReadAllNotifications } from 'common/utils/notification-utils';

const NotificationsList = ({ notifications, openNotification }) => {
    const dispatch = useDispatch();
    const limit = useSelector(getNotificationsLimit);
    const total_count = useSelector(getNotificationsTotalCount);
    const hasMore = useCallback(() => {
        return limit < total_count;
    }, [limit, total_count]);
    const onLoadMore = useCallback(() => {
        if (hasMore()) {
            dispatch(setNotificationsLimit(limit + 20));
        }
    }, [dispatch, limit, hasMore]);

    const renderNotifications = useCallback(() => {
        return notifications.map((item) => (
            <NotificationCard key={item.key} notification={item} />
        ));
    }, [notifications]);
    const handleScroll = useCallback(
        (e) => {
            const element = e.currentTarget;
            const pos =
                (element.scrollTop || element.scrollTop) + element.offsetHeight;
            const max = element.scrollHeight;
            if (pos + 40 >= max) {
                onLoadMore();
            }
        },
        [onLoadMore]
    );
    return (
        <div
            className={`dropdown-menu dropdown-notifications ${
                openNotification ? 'show' : ''
            }`}
            onScroll={handleScroll}>
            <div className="notification-options">
                <Link to={URLS.NOTIFICATIONS}>View All</Link>
                <button onClick={markReadAllNotifications}>
                    Mark All Read
                </button>
                <Link to={URLS.SETTINGS.NOTIFICATION}>Settings</Link>
            </div>
            <ul className="alert-cards">{renderNotifications()}</ul>
        </div>
    );
};

export default withMemo(NotificationsList);
