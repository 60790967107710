import React from 'react';
// Icons
import iconCloseSm from 'assets/images/icons_svg/icon_close-sm.svg';
import defaultUser from 'assets/images/default-user.jpg';
import { closeDialog } from 'common/utils/dialog-utils';
import { Dialog } from '@material-ui/core';

const SuccessAddedContactsDialog = ({ contacts }) => {
    return (
        <Dialog open={true}>
            <div className="modal-frame">
                <div className="modal-box modal-box--400">
                    <div className="modal__header">
                        <span className="contacts-added-number mr-2">
                            {contacts.length}
                        </span>
                        <span className="mr-2">
                            {contacts.length > 1 ? 'Contacts' : 'Contact'}
                        </span>
                        <span>added</span>

                        <div
                            className="modal__close"
                            onClick={() => closeDialog()}>
                            <img src={iconCloseSm} alt="" />
                        </div>
                    </div>
                    <div className="modal__body">
                        <ul className="contacts-added-list">
                            {contacts.map((contact, index) => {
                                return (
                                    <li key={index}>
                                        <img src={defaultUser} alt="avatar" />
                                        <div className="contacts-info">
                                            {contact.first_name?.length > 0 && (
                                                <p className="contact-name">
                                                    {`${contact.first_name} ${contact.last_name}`}
                                                </p>
                                            )}

                                            <p className="contact-email">
                                                {contact.selectedEmail
                                                    ? contact?.selectedEmail()
                                                    : contact.email}
                                            </p>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                        <button
                            className="btn btn-primary btn-return-to-contacts mb-3"
                            onClick={() => closeDialog()}>
                            Return to Contacts
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default SuccessAddedContactsDialog;
