import _ from 'underscore';
import {
    FETCH_BENEFICIARIES_ACCOUNTS,
    CREATE_BENEFICIARIES_ACCOUNT,
    EDIT_BENEFICIARIES_ACCOUNT,
    DELETE_BENEFICIARIES_ACCOUNT,
    ACTION_USER_LOGOUT
} from 'redux/actions/actionTypes';

const initialState = {
    beneficiariesAccounts: []
};

const BeneficiariesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BENEFICIARIES_ACCOUNTS: {
            return {
                ...state,
                beneficiariesAccounts: action.data.beneficiariesAccounts
            };
        }

        case CREATE_BENEFICIARIES_ACCOUNT: {
            const beneficiariesAccounts = state.beneficiariesAccounts;
            beneficiariesAccounts.push(action.data);

            const data = {
                ...state,
                beneficiariesAccounts: beneficiariesAccounts
            };

            localStorage.setItem('beneficiariesAccounts', JSON.stringify(data));
            return data;
        }

        case EDIT_BENEFICIARIES_ACCOUNT: {
            const beneficiariesAccounts = state.beneficiariesAccounts;
            beneficiariesAccounts.splice(
                action.data.index,
                1,
                action.data.account
            );

            const data = {
                ...state,
                beneficiariesAccounts: beneficiariesAccounts
            };

            localStorage.setItem('beneficiariesAccounts', JSON.stringify(data));
            return data;
        }
        case DELETE_BENEFICIARIES_ACCOUNT: {
            const beneficiariesAccounts = state.beneficiariesAccounts;
            beneficiariesAccounts.splice(action.data.index, 1);

            const data = {
                ...state,
                beneficiariesAccounts: beneficiariesAccounts
            };

            localStorage.setItem('beneficiariesAccounts', JSON.stringify(data));
            return data;
        }

        case ACTION_USER_LOGOUT: {
            return _.extend({}, initialState);
        }

        default:
            return state;
    }
};

export default BeneficiariesReducer;
