import React, { useCallback } from 'react';

// Actions
import { connect } from 'react-redux';
import * as TagActions from 'redux/actions/tag';

// Coponents
import Dialog from '@material-ui/core/Dialog';

// images
import deleteImage from 'assets/images/banners/banner-delete.png';

const DeleteTagDialog = ({ close, done, tag, open, dispatch }) => {
    const _close = useCallback(() => {
        if (close) {
            close();
        }
    }, [close]);

    const _done = useCallback(() => {
        dispatch(TagActions.remove(tag));

        _close();
    }, [tag, _close, dispatch]);

    return (
        <Dialog
            fullWidth={true}
            open={open}
            onClose={_close}
            aria-labelledby="form-dialog-title">
            <div
                id="delete-modal"
                className="modal-box modal-box--delete modal-box--500">
                <div className="delete-modal">
                    <div className="delete-modal__img">
                        <img
                            src={deleteImage}
                            alt="delete"
                            className="img-fluid"
                        />
                    </div>
                    <div className="delete-modal__content">
                        <div className="delete-modal__title">Delete Tag?</div>
                        <div className="delete-modal__text">
                            Are you sure you want to delete{' '}
                            <strong>&quot;{tag.name}&quot;</strong> journal?
                        </div>
                    </div>
                    <div className="delete-modal__actions">
                        <button className="btn btn-outline" onClick={_close}>
                            Cancel
                        </button>
                        <button className="btn btn-delete" onClick={_done}>
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default connect()(DeleteTagDialog);
