import { FIREBASE_DB_ROOT, updateNotification, uid } from 'common/firebase';
import * as actionTypes from 'redux/actions/actionTypes';
import { NotificationSelector } from 'redux/selectors/NotificationSelector';
import { UserSelector } from 'redux/selectors/UserSelector';

export const addNotifications = (notifications) => (dispatch) => {
    return dispatch({
        type: actionTypes.ADD_NOTIFICATIONS,
        payload: {
            data: notifications
        }
    });
};

export const markReadAll = () => (dispatch, getState) => {
    const { notifications } = NotificationSelector(getState());
    const user = UserSelector(getState());

    notifications
        .filter((obj) => !obj.read)
        .forEach((item) => {
            const { key, data } = item;
            updateNotification(`${FIREBASE_DB_ROOT}${uid}/${user.id}/${key}`, {
                ...data,
                read: true
            });
        });
};

export const setNotificationsLimit = (payload) => {
    return {
        type: actionTypes.SET_NOTIFICATIONS_LIMIT,
        payload
    };
};
