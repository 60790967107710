import {
    CompositeDecorator
} from "addon-draft-js"

import Link from "components/Editor/components/Entities/Link";

const createTypeStrategy = (type) => {
    return (contentBlock, callback, contentState) => {
        contentBlock.findEntityRanges(
            (character) => {
                const entityKey = character.getEntity();
                return (entityKey !== null && contentState.getEntity(entityKey).getType() === type)
            },
            callback
        )
    }
};

const LinkDecorator = new CompositeDecorator([
    {
        strategy: createTypeStrategy('LINK'),
        component: Link
    }
]);

export default LinkDecorator;