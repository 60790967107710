import React, { useEffect } from 'react';

const FilterDrawer = ({ children, open }) => {
    useEffect(() => {
        if (open === true) {
            document.getElementById('drawer_left').style.transform =
                'translate(0px)';
        } else {
            document.getElementById('drawer_left').style.transform =
                'translate(-320px)';
        }
    }, [open]);

    return (
        <section id="drawer_left" className="drawer drawer--left">
            <div className="drawer__content">{open && children}</div>
        </section>
    );
};

export default FilterDrawer;
