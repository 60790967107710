import React, {
    Fragment,
    useCallback,
    useEffect,
    useRef,
    useState
} from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import GooglePicker from 'react-google-picker';
// Selectors
import { getUser } from 'redux/selectors';
// Actions
import * as GoogleActions from 'redux/actions/google';
import { updateUI } from 'redux/actions/ui';
// images
import { GOOGLE_ACTION_PICKED } from 'common/constant';
// Components
import withSocialFile from 'common/utils/components/withSocialFile';
import UploadingBarFromSocialDialog from 'pages/Dialogs/SocialMedia/UploadingBarFromSocialDialog';

// Icons
import iconCloseSm from 'assets/images/icons_svg/icon_close-sm.svg';
import iconSocialDrive from 'assets/images/icons_svg/icon_social-drive.svg';

import settings from 'common/configs/setting';
import { checkResponseSuccess } from 'common/utils/responseUtils';

const GoogleContent = ({ dispatch, user, close }) => {
    const [tokenSaved, setTokenSaved] = useState(false);
    const [file, setFile] = useState(undefined);
    const openDialog = useRef(false);

    const saveGoogleToken = useCallback(
        (token) => {
            dispatch(GoogleActions.connectGoogle(token)).then((response) => {
                if (checkResponseSuccess(response.payload.status)) {
                    setTokenSaved(true);
                }
            });
        },
        [dispatch]
    );

    const uploadGoogleFile = useCallback((data) => {
        switch (data.action) {
            case GOOGLE_ACTION_PICKED: {
                setFile(data.docs[0]);

                break;
            }

            default: {
                return;
            }
        }
    }, []);

    useEffect(() => {
        if (tokenSaved && file && !openDialog.current) {
            dispatch(
                updateUI({
                    dialogs: [
                        withSocialFile(UploadingBarFromSocialDialog, file)
                    ]
                })
            );

            openDialog.current = true;
        }
    }, [file, tokenSaved, dispatch]);

    return (
        <Fragment>
            <div className="modal-box modal__box--dropzone modal-box--800">
                <div className="modal__header">
                    Add Photos from Google
                    <img
                        className="modal__close"
                        onClick={close}
                        src={iconCloseSm}
                        alt=""
                    />
                </div>
                <div className="modal__body">
                    <div className="social-media-connection">
                        <div className="social-icon social-icon--lg google-bg">
                            <img src={iconSocialDrive} alt="Google Drive" />
                        </div>
                        <p>
                            You haven&#39;t connected your Google account yet.
                            Connect your account to upload your Google Files
                            to&nbsp;JRNL.
                        </p>
                        <GooglePicker
                            clientId={settings.google_client_id}
                            developerKey={settings.google_devkey}
                            scope={[
                                'https://www.googleapis.com/auth/drive.readonly'
                            ]}
                            onChange={uploadGoogleFile}
                            onAuthenticate={saveGoogleToken}
                            multiselect={false}
                            navHidden={true}
                            authImmediate={false}
                            mimeTypes={[
                                'video/mp4',
                                'audio/mp3',
                                'application/pdf',
                                'image/png',
                                'image/jpeg'
                            ]}>
                            <button
                                className="btn btn-gradient"
                                id="social-media-connect">
                                Connect Account
                            </button>
                        </GooglePicker>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

const state = createStructuredSelector({
    user: getUser
});

export default connect(state)(GoogleContent);
