// import zipcodes from 'zipcodes';

import settings from 'common/configs/setting';

const Required = (value) => {
    if (
        (!value && value !== 0) ||
        (typeof value === 'string' && value === '')
    ) {
        return 'This field is required';
    }
};

const passwordLimitation = (value) => {
    if (!value || typeof value !== 'string') {
        return '';
    }

    if (value.length < settings.SIGN_UP_PAGE_PASSWORD_MIN_LENGTH) {
        return `The Password must be contains minimum ${settings.SIGN_UP_PAGE_PASSWORD_MIN_LENGTH} characters`;
    }
};

const Email = (value) => {
    if (
        value &&
        value.indexOf(',') === -1 &&
        new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(value)
    ) {
        return null;
    }

    return 'Enter validate email';
};

/*
const Zipcode = (value) => {
    const info = zipcodes.lookup(value);

    if (info === null || info === undefined) {
        return 'Invalid zipcode';
    }
};
*/
export { Required /*, Zipcode*/, passwordLimitation, Email };
