import _ from 'underscore';
import {
    FETCH_AAM_CATEGORY,
    FETCH_AAM_CATEGORY_LIST,
    FETCH_AAM_QUESTION_LIST,
    FETCH_AAM_QUESTION,
    FETCH_RANDOM_QUESTION,
    ACTION_USER_LOGOUT,
    CREATE_AAM_QUESTION,
    UPDATE_AAM_QUESTION,
    DELETE_AAM_QUESTION,
    CLEAR_RANDOM_QUESTION
} from 'redux/actions/actionTypes';
import { SUCCESS_SUFFIX } from 'common/constant';
import arrayUtils from 'common/utils/arrayUtils';

const initialState = {
    categories: undefined,
    questions: undefined,
    randomQuestion: undefined
};

const AllAboutMeReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_AAM_CATEGORY + SUCCESS_SUFFIX:
        case FETCH_AAM_CATEGORY_LIST + SUCCESS_SUFFIX: {
            const data = action.payload.data.objects
                ? action.payload.data.objects
                : action.payload.data;
            const categories = arrayUtils.mergeArrayByField(
                state.categories,
                Array.isArray(data) ? data : [data]
            );
            return _.extend({}, state, { categories });
        }

        case CREATE_AAM_QUESTION + SUCCESS_SUFFIX:
        case UPDATE_AAM_QUESTION + SUCCESS_SUFFIX:
        case FETCH_AAM_QUESTION + SUCCESS_SUFFIX:
        case FETCH_AAM_QUESTION_LIST + SUCCESS_SUFFIX: {
            const data = action.payload.data.objects
                ? action.payload.data.objects
                : action.payload.data;
            const questions = arrayUtils.mergeArrayByField(
                state.questions,
                Array.isArray(data) ? data : [data]
            );
            return _.extend({}, state, {
                questions
            });
        }

        case DELETE_AAM_QUESTION + SUCCESS_SUFFIX: {
            const questions = [...state.questions];
            const index = (questions || []).findIndex(
                (obj) => obj.id === action.meta.previousAction.id
            );

            if (index >= 0) {
                questions.splice(index, 1);
            }
            return _.extend({}, state, {
                questions
            });
        }

        case FETCH_RANDOM_QUESTION + SUCCESS_SUFFIX: {
            return _.extend({}, state, {
                randomQuestion: action.payload.data
            });
        }

        case CLEAR_RANDOM_QUESTION: {
            return _.extend({}, state, {
                randomQuestion: undefined
            });
        }

        case ACTION_USER_LOGOUT: {
            return _.extend({}, initialState);
        }
        default: {
            return state;
        }
    }
};

export default AllAboutMeReducer;
