import _ from 'underscore';
import {
    UPDATE_QUERY,
    FETCH_RESULTS,
    FETCH_ADDITIONAL_RESULTS,
    ACTION_USER_LOGOUT,
    SET_SEARCH_LOADINGPROGRESS,
    CLEAR_LOADINGPROGRESS,
    RESET_SEARCH_PAGE
} from 'redux/actions/actionTypes';
import { ERROR_SUFFIX, SUCCESS_SUFFIX } from 'common/constant';

const initialState = {
    query: undefined,
    results: undefined,
    isPending: false,
    fetched: false
};

const SearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_QUERY: {
            return _.extend({}, state, {
                query: action.payload.value
            });
        }

        case FETCH_RESULTS: {
            return _.extend({}, state, {
                isPending: true,
                results: undefined,
                fetched: true
            });
        }

        case FETCH_ADDITIONAL_RESULTS: {
            return _.extend({}, state, {
                isPending: true
            });
        }

        case FETCH_RESULTS + ERROR_SUFFIX: {
            return _.extend({}, state, {
                isPending: false
            });
        }

        case FETCH_RESULTS + SUCCESS_SUFFIX:
        case FETCH_ADDITIONAL_RESULTS + SUCCESS_SUFFIX: {
            let results = state.results;

            action.payload.data.objects.forEach((object) => {
                const index = _.findIndex(
                    results,
                    (result) => result.id === object.id
                );

                if (index >= 0) {
                    results = [].concat(
                        results.slice(0, index),
                        [object],
                        results.slice(index + 1)
                    );
                } else {
                    results = [].concat([object], results || []);
                }
            });
            return _.extend({}, state, {
                results,
                meta: action.payload.data.meta,
                isPending: false,
                resultsQuery: state.query || state.resultsQuery
            });
        }

        case SET_SEARCH_LOADINGPROGRESS: {
            return _.extend({}, state, {
                loading: true,
                isPending: undefined
            });
        }

        case CLEAR_LOADINGPROGRESS: {
            return _.extend({}, state, {
                loading: false
            });
        }

        case ACTION_USER_LOGOUT: {
            return _.extend({}, initialState);
        }
        case RESET_SEARCH_PAGE: {
            return {
                ...state,
                query: undefined,
                results: undefined
            };
        }
        default: {
            return {
                ...state
            };
        }
    }
};

export default SearchReducer;
