import React from 'react';
import { Select } from '@material-ui/core';

const CustomSelect = ({ value, onChange, children }) => {
    return (
        <Select
            className="time-select"
            value={value}
            onChange={onChange}
            MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                },
                className: 'date-time-dropdowns'
            }}
            style={{
                padding: '0 15px',
                margin: '0 2px',
                borderBottom: '0.125rem solid rgb(213, 213, 213)'
            }}>
            {children}
        </Select>
    );
};

export default CustomSelect;
