import {
    FETCH_JOURNAL_LIST,
    CREATE_JOURNAL,
    REMOVE_JOURNAL,
    DOWNLOAD_VCARD,
    RESET_VCARD,
    GENERATE_EMAIL_TO_JRNL_ADDRESS,
    ACTION_USER_LOGOUT,
    UPDATE_JOURNAL,
    PATCH_JOURNAL,
    SET_JOURNAL_LOADINGPROGRESS,
    CLEAR_LOADINGPROGRESS,
    REMOVE_JOURNAL_INVITATION
} from 'redux/actions/actionTypes';
import { SUCCESS_SUFFIX, ERROR_SUFFIX } from 'common/constant';

const initialState = {
    journals: undefined,
    meta: undefined,
    isPending: false,
    pending: 0,
    loggedOutTime: undefined
};

const JournalReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_JOURNAL_LIST: {
            return Object.assign({}, state, {
                pending: state.pending + 1,
                isPending: true
            });
        }

        case FETCH_JOURNAL_LIST + SUCCESS_SUFFIX: {
            if (
                state.loggedOutTime &&
                state.loggedOutTime > action.meta.previousAction.timestamp
            ) {
                return state;
            }

            let journals = state.journals;
            let _i, _len;
            const _ref = action.payload.data.objects;

            for (_i = 0, _len = _ref.length; _i < _len; _i++) {
                const object = _ref[_i];
                const index = (journals || []).findIndex(
                    (journal) => object.id === journal.id
                );

                if (index >= 0) {
                    journals = [].concat(
                        journals.slice(0, index),
                        [object],
                        journals.slice(index + 1)
                    );
                } else {
                    journals = [].concat([object], journals || []);
                }
            }

            return Object.assign({}, state, {
                isPending: state.pending > 1,
                pending: state.pending - 1,
                journals,
                meta: action.payload.data.meta
            });
        }

        case CREATE_JOURNAL + SUCCESS_SUFFIX:
        case PATCH_JOURNAL + SUCCESS_SUFFIX:
        case UPDATE_JOURNAL + SUCCESS_SUFFIX: {
            let journals = state.journals;
            const index = (journals || []).findIndex(
                (journal) => action.payload.data.id === journal.id
            );
            if (index >= 0) {
                journals[index] = action.payload.data;
            } else {
                journals = [].concat([action.payload.data], journals || []);
            }
            return Object.assign({}, state, {
                journals
            });
        }

        case FETCH_JOURNAL_LIST + ERROR_SUFFIX: {
            return Object.assign({}, state, {
                isPending: state.pending > 1,
                pending: state.pending - 1
            });
        }

        case REMOVE_JOURNAL_INVITATION + SUCCESS_SUFFIX: {
            const journalID = action.meta.previousAction.payload.journal;
            const journals = state.journals.filter(
                (journal) => journal.id !== journalID
            );

            return Object.assign({}, state, {
                journals
            });
        }
        case REMOVE_JOURNAL + SUCCESS_SUFFIX: {
            const journals = state.journals.filter(
                (journal) => journal.id !== action.payload.config.options.id
            );

            return Object.assign({}, state, {
                journals
            });
        }

        case DOWNLOAD_VCARD + SUCCESS_SUFFIX: {
            const windowUrl = window.URL || window.webkitURL;
            const vcard = windowUrl.createObjectURL(action.payload.data);
            return Object.assign({}, state, {
                vcard
            });
        }

        case RESET_VCARD: {
            return Object.assign({}, state, {
                vcard: undefined
            });
        }

        case SET_JOURNAL_LOADINGPROGRESS: {
            return Object.assign({}, state, {
                loading: true,
                isPending: undefined
            });
        }

        case CLEAR_LOADINGPROGRESS: {
            return state;
            /*
            return Object.assign({}, state, {
                loading: false
            });
            */
        }

        case GENERATE_EMAIL_TO_JRNL_ADDRESS: {
            return Object.assign({}, state);
        }

        case ACTION_USER_LOGOUT: {
            return Object.assign({}, initialState, {
                loggedOutTime: new Date()
            });
        }
        default: {
            return state;
        }
    }
};

export default JournalReducer;
