import _ from 'underscore';
import {
    SETTINGS_REMINDER_FETCH,
    SETTINGS_REMINDER_TOGGLE,
    SETTINGS_REMINDER_CREATE,
    SETTINGS_REMINDER_EDIT,
    SETTINGS_REMINDER_DELETE,
    ACTION_USER_LOGOUT
} from 'redux/actions/actionTypes';
import { SUCCESS_SUFFIX } from 'common/constant';
// import { SUCCESS_SUFFIX } from 'common/constant';

const initialState = {
    isEnabled: true,
    isPending: false,
    reminders: null
};

const ReminderReducer = (state = initialState, action) => {
    switch (action.type) {
        case SETTINGS_REMINDER_FETCH: {
            return {
                ...state,
                isPending: true
            };
        }
        case SETTINGS_REMINDER_FETCH + SUCCESS_SUFFIX: {
            return {
                ...state,
                isPending: false,
                reminders: action.payload.data
            };
        }
        case SETTINGS_REMINDER_TOGGLE: {
            const data = {
                ...state,
                isEnabled: !state.isEnabled
            };
            localStorage.setItem('reminders', JSON.stringify(data));
            return data;
        }
        case SETTINGS_REMINDER_CREATE + SUCCESS_SUFFIX: {
            const reminders = state.reminders.results;
            reminders.push(action.payload.data);

            return {
                ...state,
                reminders: { ...state.reminders, results: reminders }
            };
        }
        case SETTINGS_REMINDER_EDIT + SUCCESS_SUFFIX: {
            const reminders = state.reminders.results;
            const newReminder = action.payload.data;
            const oldReminderIndex = reminders.findIndex(
                (reminder) => reminder.id === newReminder.id
            );

            reminders.splice(oldReminderIndex, 1, newReminder);

            return {
                ...state,
                reminders: { ...state.reminders, results: reminders }
            };
        }
        case SETTINGS_REMINDER_DELETE + SUCCESS_SUFFIX: {
            const reminders = state.reminders.results;
            const reminderIndex = reminders.findIndex(
                (reminder) => reminder.id === action.payload.config.options.id
            );

            reminders.splice(reminderIndex, 1);
            return {
                ...state,
                reminders: { ...state.reminders, results: reminders }
            };
        }

        case ACTION_USER_LOGOUT: {
            return _.extend({}, initialState);
        }
        default: {
            break;
        }
    }
    return state;
};

export default ReminderReducer;
