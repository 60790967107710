import React from 'react';

const Link = ({
    contentState,
    entityKey,
    children
              }) => {
    const { url } = contentState.getEntity(entityKey).getData();
    return (
        <a className="link-attached not-clicked" href={url} title={url} target="_blank" rel="noopener noreferrer" onClick={() => window.open(url, '_blank')}>
            {children}
        </a>
    );
}

export default Link;
