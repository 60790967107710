import _ from 'underscore';

import {
    createSelector
} from 'reselect';
import { checkObject } from 'common/utils/validatecheck/checkVariableTypes';

export const TagSelector = (state) => state.tag;

export const TagsForEntrySelector = (state, props) => {
    let entryId;
    let entry;
    const {
        entries
    } = state.entry;
    const {
        tags
    } = state.tag;

    if (props.match?.params && props.match?.params.entryId) entryId = props.match?.params.entryId;

    if (!props.entry) {
        entry = _.findWhere(entries, {
            id: parseInt(entryId)
        });
    }
    else entry = props.entry;

    if (entry) {
        let tagsForEntry = [];
        for (let _i in entry.tags) {
            let tagResourceUri = entry.tags[_i];
            const tag = _.findWhere(tags, {
                resource_uri: checkObject(tagResourceUri) ? tagResourceUri.resource_uri : tagResourceUri
            });
            if (tag)
                tagsForEntry.push(tag);
        }
        if (_.isEmpty(tagsForEntry))
            return undefined;

        return tagsForEntry;
    }
    return undefined;
}

export const getTagList = createSelector(
    [TagSelector],
    (tag) => {
        const state = _.extend({}, tag);
        if (state.tags)
            state.tags = _.sortBy(state.tags, (tag) => tag.name.toLowerCase());
        return state;
    }
)

export const getTagsForEntry = createSelector(
    [TagsForEntrySelector],
    (tags) => tags
);