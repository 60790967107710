import FaceWithHearts from 'assets/images/emoticons/smiling-face-with-hearts.png';
import PensiveFace from 'assets/images/emoticons/pensive-face.png';
import WorriedFace from 'assets/images/emoticons/worried-face.png';
import CryingFace from 'assets/images/emoticons/loudly-crying-face.png';
import GrinningFace from 'assets/images/emoticons/grinning-face.png';
import BeamingFace from 'assets/images/emoticons/beaming-face-with-smiling-eyes.png';
import PoutingFace from 'assets/images/emoticons/pouting-face.png';
import FrustratedFace from 'assets/images/emoticons/frustrated-face.png';
import SadSweatFace from 'assets/images/emoticons/sad-sweat-face.png';
import ScaredFace from 'assets/images/emoticons/scared-face.png';
import UnamusedFace from 'assets/images/emoticons/unamused-face.png';
import ReverseSmileyFace from 'assets/images/emoticons/reverse-smiley-face.png';
import HeartEyesFace from 'assets/images/emoticons/heart-eyes-face.png';
import BlushFace from 'assets/images/emoticons/blush-face.png';
import Sparkles from 'assets/images/emoticons/sparkles.png';
import Fire from 'assets/images/emoticons/fire.png';
import JoyFace from 'assets/images/emoticons/joy-face.png';
import Heart from 'assets/images/emoticons/heart.png';
import PleadingFace from 'assets/images/emoticons/pleading-face.png';

const emotions = [
    {
        id: 'hearts',
        img: FaceWithHearts
    },
    {
        id: 'pensive',
        img: PensiveFace
    },
    {
        id: 'worried',
        img: WorriedFace
    },
    {
        id: 'crying',
        img: CryingFace
    },
    {
        id: 'grinning',
        img: GrinningFace
    },
    {
        id: 'beaming',
        img: BeamingFace
    },
    {
        id: 'pouting',
        img: PoutingFace
    },
    {
        id: 'frustrated',
        img: FrustratedFace
    },
    {
        id: 'sad-sweat',
        img: SadSweatFace
    },
    {
        id: 'scared',
        img: ScaredFace
    },
    {
        id: 'unamused',
        img: UnamusedFace
    },
    {
        id: 'reverse-smiley',
        img: ReverseSmileyFace
    },
    {
        id: 'heart-eyes',
        img: HeartEyesFace
    },
    {
        id: 'blush',
        img: BlushFace
    },
    {
        id: 'sparkles',
        img: Sparkles
    },
    {
        id: 'fire',
        img: Fire
    },
    {
        id: 'joy',
        img: JoyFace
    },
    {
        id: 'heart',
        img: Heart
    },
    {
        id: 'pleading',
        img: PleadingFace
    }
];

export default emotions;
