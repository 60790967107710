import _ from 'underscore';

import { createSelector } from 'reselect';

import { getSearch } from 'redux/selectors/SearchSelector';
import {
    getSettingsPageActiveTabIndex,
    getSettingsPageActiveTab,
    isMobileSettingBarOpen,
    getSelectedPlanDuretion
} from 'redux/selectors/get_settings_page_current_tab';
import {
    getUser,
    getFontSize,
    getUserHeaderColor,
    getSocialAccounts,
    getUserEmotions,
    getUserTimeLineReadingType
} from 'redux/selectors/UserSelector';

import {
    LoginSelector,
    LoginFormSelector
} from 'redux/selectors/LoginFormSelector';

import {
    SignupSelector,
    SignupFormSelector
} from 'redux/selectors/SignupFormSelector';

/* TODO
import { 
  ForgotPasswordSelector,
  ForgotPasswordFormSelector 
} from 'redux/selectors/ForgotPasswordFormSelector';
*/

import { RegistrationSelector } from 'redux/selectors/RegistrationSelector';

/*
import { 
    profileSelector ,
    profileFormSelector
} from 'redux/selectors/ProfileFormSelector';
*/

import {
    getEntryState,
    getEntryById,
    getFilteredEntries,
    getEntryForInvitationUUID,
    getAnswersForQuestion,
    getDraftEntry,
    getQADraftEntry,
    getFilteredLastEntry
} from 'redux/selectors/EntrySelector';

import {
    getJournalList,
    getDefaultJournal,
    getJournalForEntry,
    getAamJournal,
    getJournalForBook,
    getHiddenJournal
} from 'redux/selectors/JournalSelector';

import {
    getCalendar,
    getCalendarPageRange,
    getRecentContentDate
} from 'redux/selectors/CalendarSelector';

import { getTagList, getTagsForEntry } from 'redux/selectors/TagSelector';

import {
    getCommentList,
    getCommentsForEntry
} from 'redux/selectors/CommentSelector';

import {
    getEditorState,
    getEditorNewData,
    getImageUploadProgress,
    getFilesListWhenUpload
} from 'redux/selectors/EditorSelector';

import {
    getFilters,
    getFilterByJournal,
    getFilterByTag
} from 'redux/selectors/FiltersSelector';

import { getAvatarImage } from 'redux/selectors/AvatarImageSelector';

import {
    getPublicUsersList,
    getMyPublicUser,
    getPublicUserList
} from 'redux/selectors/PublicUserSelector';

import {
    getInvitations,
    getOutstandingInvitations,
    getInvitationForUUID,
    getInviteId
} from 'redux/selectors/InvitationSelector';

import {
    AllAboutMeSelector,
    CategoryForId,
    QuestionsForCategory,
    QuestionForId,
    getRandomQuestion,
    getAAM,
    getCategoryList,
    getCategoryForId,
    getQuestionsForCategory,
    getQuestionForId,
    getCategoryForQuestion
} from 'redux/selectors/AllAboutMeSelector';

import {
    getInstagramAuthorizationUrl,
    getInstagramAccessToken,
    getInstagramMedia,
    getInstagramState,
    getInstagramProfile,
    getInstagramMediaPaging
} from 'redux/selectors/InstagramSelector';

import { getBlockedUsers } from 'redux/selectors/TimelineApprovalSelector';

import { getUI } from 'redux/selectors/UISelector';

import {
    getBook,
    getBookList,
    getBookById,
    getBookTimers,
    getBookPendingStatus,
    isPriceCalculating
} from 'redux/selectors/BookSelector';

import { getShippingAddressForBook } from 'redux/selectors/BookShippingAddressSelector';

import { getBookOrderList } from 'redux/selectors/BookOrderSelector';

import { getLoadingProgress } from 'redux/selectors/LoadingProgressSelector';

import {
    getShareContactList,
    getSearchedContactList
} from 'redux/selectors/ShareContactListSelector';

import { getDefaultPaymentProfile } from 'redux/selectors/PaymentProfileSelector';

import { getJournalInvitations } from 'redux/selectors/JournalInvitationSelector';

import {
    FacebookSelector,
    getFacebookState,
    getFacebookProfile,
    getFacebookMedia,
    getFacebookMediaPaging
} from 'redux/selectors/FaceBookSelector';

import {
    getJournalEmails,
    getSenderEmails
} from 'redux/selectors/JournalEmailSelector';

import { getFamilyAccounts } from 'redux/selectors/FamilyAccountSelector';
import { getBeneficiariesAccounts } from 'redux/selectors/BeneficiariesSelector';
import { getBackgroundImages } from 'redux/selectors/BackgroundImageSelector';
import {
    getNotifications,
    getNotificationsLimit,
    getNotificationsTotalCount
} from 'redux/selectors/NotificationSelector';
import { getContacts } from 'redux/selectors/ContactSelector';
import { checkUserSame } from 'common/utils/resourceUtils';

/*
{
  getDefaultPaymentProfile
} = require 'redux/selectors/PaymentProfileSelector'

{
  getTheme
} = require 'redux/selectors/ThemeSelector'
{
  getReminderProfile
} = require 'redux/selectors/ReminderSelector'

{
  confirmPasswordSelector
  confirmPasswordFormSelector
} = require 'redux/selectors/ConfirmPasswordSelector'

getConfirmPasswordForm = createSelector(
  [confirmPasswordSelector, confirmPasswordFormSelector],
  (confirmPassword, confirmPasswordForm)=>
    return _.extend {},
      confirmPassword: confirmPassword
      confirmPasswordForm: confirmPasswordForm
)

*/

const getSignupForm = createSelector(
    SignupSelector,
    SignupFormSelector,
    (signup, signupForm) => ({ signup, signupForm })
);

const getRegistration = createSelector([RegistrationSelector], (registration) =>
    _.extend(
        {},
        {
            registration
        }
    )
);

const getLoginForm = createSelector(
    LoginSelector,
    LoginFormSelector,
    (login, loginForm) => ({ login, loginForm })
);

/*
const getForgotPasswordForm = createSelector(
  [ForgotPasswordSelector, ForgotPasswordFormSelector],
  (forgotPassword, forgotPasswordForm) => _.extend({}, {
      forgotPassword: forgotPassword,
      forgotPasswordForm: forgotPasswordForm
  })
)
const getProfileForm = createSelector(
  [ProfileSelector, ProfileFormSelector],
  (profile, profileForm) =>  _.extend({}, {
      profile: profile,
      profileForm: profileForm
  })
);
*/

// Well I'll be damned. This has to be here to avoid circular import which causes EntrySelector to be undefined when required in PublicUserSelector
const getPublicUserByEntry = createSelector(
    [getEntryById, getPublicUserList, getUser, getMyPublicUser],
    (entry, public_users, me, publicMe) => {
        let publicUser = undefined;

        if (me && me.sharing) {
            if (
                public_users &&
                public_users.public_users &&
                entry &&
                entry.user
            ) {
                publicUser = (
                    (public_users && public_users.public_users) ||
                    []
                ).find((obj) => checkUserSame(obj, entry.user));
            }

            if (checkUserSame(me, entry && entry.user)) {
                publicUser = _.extend({}, publicMe, {
                    isOwner: true
                });
            }
        }

        return publicUser;
    }
);

const getFilterJournal = createSelector(
    [getFilters, getJournalList],
    (filters, journals) => {
        let filterJournal = undefined;
        if (filters.filterByJournal) {
            filterJournal = _.findWhere(journals.journals, {
                resource_uri: filters.filterByJournal
            });
        }

        return filterJournal;
    }
);

const getFilterTag = createSelector(
    [getFilters, getTagList],
    (filters, tags) => {
        let filterTag = undefined;
        if (filters.filterByTag) {
            filterTag = _.findWhere(tags.tags, {
                resource_uri: filters.filterByTag
            });
        }

        return filterTag;
    }
);

export {
    getSettingsPageActiveTabIndex,
    getSettingsPageActiveTab,
    isMobileSettingBarOpen,
    getSelectedPlanDuretion,
    getFilesListWhenUpload,
    getImageUploadProgress,
    getPublicUsersList,
    getBookPendingStatus,
    getSearch,
    getUser,
    getFontSize,
    getUserEmotions,
    getUserHeaderColor,
    getSocialAccounts,
    getUserTimeLineReadingType,
    getLoginForm,
    getSignupForm,
    getRegistration,
    //getForgotPasswordForm as getForgotPasswordForm,
    //getProfileForm as getProfileForm,
    getPublicUserByEntry as getPublicUserForEntry,
    // entry
    getEntryState,
    getEntryById,
    getFilteredEntries,
    getEntryForInvitationUUID,
    getAnswersForQuestion,
    getDraftEntry,
    getQADraftEntry,
    getFilteredLastEntry,
    // journal
    getJournalList,
    getDefaultJournal,
    getJournalForEntry,
    getAamJournal,
    getJournalForBook,
    getHiddenJournal,
    // calendar
    getCalendar,
    getCalendarPageRange,
    getRecentContentDate,
    // tags
    getTagList,
    getTagsForEntry,
    // comment
    getCommentList,
    getCommentsForEntry,
    // editor
    getEditorState,
    getEditorNewData,
    // filters
    getFilters,
    getFilterByTag,
    getFilterByJournal,
    // avatar image
    getAvatarImage,
    // public_user
    getMyPublicUser,
    getPublicUserList,
    // Invitations
    getInvitations,
    getOutstandingInvitations,
    getInvitationForUUID,
    getInviteId,
    // Instagram
    getInstagramAccessToken,
    getInstagramAuthorizationUrl,
    getInstagramMedia,
    getInstagramState,
    getInstagramProfile,
    getInstagramMediaPaging,
    // Timeline Approvals
    getBlockedUsers,
    // Books
    getBook,
    getBookList,
    getBookById,
    getBookTimers,
    isPriceCalculating,
    // Book Orders
    getBookOrderList,
    // Book Shipping Address
    getShippingAddressForBook,
    // Payment Profiles
    getDefaultPaymentProfile,
    /*    
    // Theme
    getTheme as getTheme,
    // Reminder
    getReminderProfile as getReminderProfile,
    */
    getFilterJournal,
    getFilterTag,
    // confirm Password
    /*
    getConfirmPasswordForm as getConfirmPasswordForm
    */

    // all about me
    AllAboutMeSelector,
    CategoryForId,
    QuestionsForCategory,
    QuestionForId,
    getRandomQuestion,
    getAAM,
    getCategoryList,
    getCategoryForId,
    getQuestionsForCategory,
    getQuestionForId,
    getCategoryForQuestion,
    // UI
    getUI,
    // loading progress
    getLoadingProgress,
    // share contact list
    getShareContactList,
    getSearchedContactList,
    // journal invitations
    getJournalInvitations,
    // Facebook Selector
    FacebookSelector,
    getFacebookState,
    getFacebookProfile,
    getFacebookMedia,
    getFacebookMediaPaging,
    // Journal Email Selector
    getJournalEmails,
    getSenderEmails,
    // Family Account Selector
    getFamilyAccounts,
    // Beneficiaries Selector
    getBeneficiariesAccounts,
    // Background image selector
    getBackgroundImages,
    // Notifications
    getNotifications,
    getNotificationsLimit,
    getNotificationsTotalCount,
    // Contacts
    getContacts
};
