import React from 'react';
import androidAppLogo from 'assets/images/android-app-logo.png';
import androidRatingImg from 'assets/images/android-rating.png';
import googlePlayStoreBtn from 'assets/images/google-play-store-btn.png';
export const AndroidBanner = ({ onClose }) => {
    return (
        <div className="android-banner-install">
            <span
                className="android-close"
                id="banner-close"
                onClick={() => onClose()}
                style={{ cursor: 'pointer' }}>
                ×
            </span>
            <div className="android-app-logo">
                <img src={androidAppLogo} alt="android-app-logo" />
            </div>
            <div className="android-app-desc">
                <p className="android-app-title">JRNL App</p>
                <img src={androidRatingImg} alt="android-rating" />
            </div>
            <button className="btn btn-get-app">
                <img src={googlePlayStoreBtn} alt="google-store" />
            </button>
        </div>
    );
};
