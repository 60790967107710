import React from 'react';
import { connect } from 'react-redux';

import { Footer } from 'components';

const Terms = () => {
    React.useEffect(() => {
        document.title = 'JRNL Service Policies | JRNL.com';
    });
    return (
        <div id="subpage" className="page-about">
            <div id="content">
                <div className="container">
                    <main>
                        <div className="page-header">
                            <h1>JRNL Service Policies</h1>
                        </div>
                        <div className="type">
                            <p>
                                Welcome to JRNL! Before you register for your
                                JRNL account, you must read and agree to these
                                JRNL Terms of Use and the following terms and
                                conditions and policies, including any future
                                amendments (collectively, the
                                &quot;Agreement&quot;):
                            </p>
                            <ul>
                                <li>
                                    JRNL Terms of Service – Our general terms
                                    and conditions
                                </li>
                                <li>
                                    JRNL Privacy Policy – How we maintain and
                                    protect your personal information in JRNL
                                </li>
                                <li>
                                    JRNL Service Policies – Guidelines for using
                                    JRNL
                                </li>
                            </ul>
                            <p>
                                Although we may attempt to notify you via your
                                email address and the JRNL Help Desk when major
                                changes are made, you should visit this page
                                periodically to review the terms.
                            </p>

                            <p>
                                JRNL may, in its sole discretion, modify or
                                revise these terms and conditions and policies
                                at any time, and you agree to be bound by such
                                modifications or revisions. If you do not accept
                                and abide by this Agreement, you should not use
                                the JRNL service. In the event of an
                                inconsistency between the JRNL Terms of Use and
                                either JRNL&apos;s general Terms of Service
                                (available at https://JRNL.com/terms) or the
                                JRNL Privacy Policy (available at
                                https://JRNL.com/privacy), the JRNL Terms of Use
                                shall take precedence. Nothing in this Agreement
                                shall be deemed to confer any third party rights
                                or benefits.
                            </p>

                            <h4>1. Description of Service.</h4>
                            <p>
                                JRNL is a free mobile and web-based journal (the
                                &quot;Service&quot;). You understand and agree
                                that the Service may include advertisements or
                                other related information, as further described
                                below and in the JRNL Privacy Policy. In
                                addition, you understand and agree that the
                                Service is provided on an AS IS and AS AVAILABLE
                                basis. JRNL disclaims all responsibility and
                                liability for the availability, timeliness,
                                security or reliability of the Service. JRNL
                                also reserves the right to modify, suspend or
                                discontinue the Service with or without notice
                                at any time and without any liability to you.
                            </p>

                            <h4>2. Personal Use</h4>
                            <p>
                                The Service is made available for personal use
                                only. In order to use this service:
                            </p>
                            <ol type="a">
                                <li>
                                    You must be at least thirteen (13) years of
                                    age, and
                                </li>
                                <li>
                                    You must provide current, accurate
                                    identification, contact, and other
                                    information that may be required as part of
                                    the registration process and/or continued
                                    use of the Service.
                                </li>
                            </ol>
                            <p>
                                You are responsible for maintaining the
                                confidentiality of your Service password and
                                account, and for all activities that occur
                                thereunder. JRNL reserves the right to refuse
                                service to anyone at any time without notice and
                                for any reason.
                            </p>

                            <h4>3. Proper Use</h4>
                            <p>
                                You agree that you are responsible for your own
                                journal entries and for any consequences
                                thereof. Your use of the Service is subject to
                                your acceptance of and compliance with the
                                Agreement, including the JRNL Service Policies
                                (&quot;Service Policies&quot;), located at
                                http://www.JRNL.com/service.html, or such other
                                URL as JRNL may provide from time to time. You
                                agree that you will use the Service in
                                compliance with all applicable local, state,
                                national, and international laws, rules and
                                regulations, including any laws regarding the
                                transmission of technical data exported from
                                your country of residence. You shall not, shall
                                not agree to, and shall not authorize or
                                encourage any third party to: (i) use the
                                Service to upload, promote or otherwise
                                distribute any content that is unlawful,
                                defamatory, harassing, abusive, fraudulent,
                                obscene, contains viruses, or is otherwise
                                objectionable as reasonably determined by JRNL;
                                (ii) upload, transmit or otherwise distribute
                                content that infringes upon another party&apos;s
                                intellectual property rights or other
                                proprietary, contractual or fiduciary rights or
                                obligations; (iii) prevent others from using the
                                Service; (iv) use the Service for any fraudulent
                                or inappropriate purpose; or (v) act in any way
                                that violates the Program Policies, as may be
                                revised from time to time. Violation of any of
                                the foregoing may result in immediate
                                termination of this Agreement, and may subject
                                you to state and federal penalties and other
                                legal consequences. JRNL reserves the right, but
                                shall have no obligation, to investigate your
                                use of the Service in order to determine whether
                                a violation of the Agreement has occurred or to
                                comply with any applicable law, regulation,
                                legal process or governmental request.
                            </p>
                            <p>
                                Content of the Service. JRNL takes no
                                responsibility for third party content
                                (including, without limitation, any viruses or
                                other disabling features), nor does JRNL have
                                any obligation to monitor such third party
                                content. JRNL reserves the right at all times to
                                remove or refuse to distribute any content on
                                the Service, such as content which violates the
                                terms of this Agreement. JRNL also reserves the
                                right to access, read, preserve, and disclose
                                any information as it reasonably believes is
                                necessary to (a) satisfy any applicable law,
                                regulation, legal process or governmental
                                request, (b) enforce this Agreement, including
                                investigation of potential violations hereof,
                                (c) detect, prevent, or otherwise address fraud,
                                security or technical issues, (d) respond to
                                user support requests, or (e) protect the
                                rights, property or safety of JRNL, its users
                                and the public. JRNL will not be responsible or
                                liable for the exercise or non-exercise of its
                                rights under this Agreement.
                            </p>

                            <h4>4. Intellectual Property Rights.</h4>
                            <p>
                                JRNL&apos;s Intellectual Property Rights. You
                                acknowledge that JRNL owns all right, title and
                                interest in and to the Service, including
                                without limitation all intellectual property
                                rights (the &quot;JRNL Rights&quot;), and such
                                JRNL Rights are protected by U.S. and
                                international intellectual property laws.
                                Accordingly, you agree that you will not copy,
                                reproduce, alter, modify, or create derivative
                                works from the Service. You also agree that you
                                will not use any robot, spider, other automated
                                device, or manual process to monitor or copy any
                                content from the Service. The JRNL Rights
                                include rights to (i) the Service developed and
                                provided by JRNL; and (ii) all software
                                associated with the Service. The JRNL Rights do
                                not include third-party content used as part of
                                Service, including the content of communications
                                appearing on the Service.
                            </p>

                            <h4>5. Your Intellectual Property Rights.</h4>
                            <p>
                                JRNL does not claim any ownership in any of the
                                content, including any text, data, information,
                                images, photographs, music, sound, video, or
                                other material, that you upload, or store in
                                your JRNL account. We will not use any of your
                                content for any purpose except to provide you
                                with the Service.
                            </p>

                            <h4>6. Representations and Warranties</h4>
                            <p>
                                You represent and warrant that (a) all of the
                                information provided by you to JRNL to
                                participate in the Services is correct and
                                current; and (b) you have all necessary right,
                                power and authority to enter into this Agreement
                                and to perform the acts required of you
                                hereunder.
                            </p>

                            <h4>7. Privacy</h4>
                            <p>
                                As a condition to using the Service, you agree
                                to the terms of the JRNL Privacy Policy as it
                                may be updated from time to time. JRNL
                                understands that privacy is important to you.
                                You do, however, agree that JRNL may monitor,
                                edit or disclose your personal information,
                                including the content of your journal entries,
                                if required to do so in order to comply with any
                                valid legal process or governmental request
                                (such as a search warrant, subpoena, statute, or
                                court order), or as otherwise provided in these
                                Terms of Use and the JRNL Privacy Policy.
                                Personal information collected by JRNL may be
                                stored and processed in the United States or any
                                other country in which JRNL Inc. or its agents
                                maintain facilities. By using JRNL, you consent
                                to any such transfer of information outside of
                                your country.
                            </p>

                            <h4>8. Advertisements</h4>
                            <p>
                                As consideration for using the Service, you
                                agree and understand that JRNL may in the future
                                display ads and other information adjacent to
                                your journal entries. JRNL may choose at some
                                point to serves ads using an automated process.
                                No human will ever read the content of your
                                entries in order to target such advertisements,
                                or for any other purpose without your consent,
                                and no journal content or other personally
                                identifiable information will be provided to
                                advertisers as part of the Service.
                            </p>

                            <h4>9. Account Inactivity.</h4>
                            <p>
                                If a user fails to login to their account for a
                                period of twelve (12) months, JRNL reserves the
                                right to disable or terminate a user&apos;s
                                account for inactivity. If an account has been
                                deactivated for inactivity, the username address
                                associated with that account may be given to
                                another user without notice to you or such other
                                party. For more information on how JRNL deals
                                with account inactivity, please consult the
                                Service Policies.
                            </p>

                            <h4>10. Publicity.</h4>
                            <p>
                                Any use of JRNL&apos;s trade names, trademarks,
                                service marks, logos, domain names, and other
                                distinctive brand features (&quot;Brand
                                Features&quot;) must be in compliance with this
                                Agreement and in compliance with JRNL&apos;s
                                then current Brand Feature use guidelines, and
                                any content contained or reference therein,
                                which may be found at the following URL:
                                http://www.JRNL.com/brand.html
                            </p>

                            <h4>11. Termination by User.</h4>
                            <p>
                                You may cancel your use of the Services and/or
                                terminate this Agreement with or without cause
                                at any time by providing notice to JRNL;
                                provided, however, that a terminated account may
                                continue to exist for up to five business days
                                before such cancellation takes effect.
                            </p>

                            <h4>12. Termination by JRNL.</h4>
                            <p>
                                JRNL may at any time and for any reason
                                terminate the Services, terminate this
                                Agreement, or suspend or terminate your account.
                                In the event of termination, your account will
                                be disabled and you may not be granted access to
                                your account or any entries, files, or other
                                content contained in your account although
                                residual copies of information may remain in our
                                system. Except as set forth above or unless JRNL
                                has previously canceled or terminated your use
                                of the Services (in which case subsequent notice
                                by JRNL shall not be required), if you have
                                provided a current and active email address with
                                your account profile, JRNL will notify you via
                                email of any such termination or cancellation,
                                which shall be effective immediately upon
                                JRNL&apos;s delivery of such notice. Sections 3,
                                4, 5, 6, 7, 10, 13, and 14 of the Agreement,
                                along with applicable provisions of the general
                                Terms of Service (including the section
                                regarding limitation of liability), shall
                                survive expiration or termination.
                            </p>

                            <h4>13. Indemnification.</h4>
                            <p>
                                You agree to hold harmless and indemnify JRNL,
                                and its subsidiaries, affiliates, officers,
                                agents, and employees from and against any third
                                party claim arising from or in any way related
                                to your use of the Service, including any
                                liability or expense arising from all claims,
                                losses, damages (actual and consequential),
                                suits, judgments, litigation costs and
                                attorneys&apos; fees, of every kind and nature.
                                In such a case, JRNL will provide you with
                                written notice of such claim, suit or action.
                            </p>

                            <h4>14. Choice of Law; Jurisdiction.</h4>
                            <p>
                                These Terms of Use will be governed by and
                                construed in accordance with the laws of the
                                State of Nevada, without giving effect to its
                                conflict of laws provisions or your actual state
                                or country of residence. Any claims, legal
                                proceeding or litigation arising in connection
                                with the Service will be brought solely in Clark
                                County, Nevada, and you consent to the
                                jurisdiction of such courts.
                            </p>
                        </div>
                    </main>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

const states = (state) => ({
    ...state
});
export default connect(states)(Terms);
