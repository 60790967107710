export const toggleImageFullScreen = (e) => {
    const element = e.target;
    const parentElem = element.parentNode;
    const container = document.getElementById('image_full_screen_container');
    const imgElement = document.getElementById('image_full_screen');
    const isIcon = Object.values(parentElem.attributes)
        .map((item) => {
            return item.nodeName;
        })
        .some((attribute) => attribute === 'not-draft-js-data');
    if (imgElement) {
        imgElement.remove();
        container.style.visibility = 'hidden';
        document.body.style.overflow = 'unset';
        return;
    }
    if (element.nodeName !== 'IMG' || isIcon) {
        return;
    }
    const img = document.createElement('img');
    img.onload = () => {
        document.body.style.overflow = 'hidden';
        container.style.visibility = 'visible';
        img.id = 'image_full_screen';
        container.appendChild(img);
    };

    img.src = element.src;
};
