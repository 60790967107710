import {
    ACTION_REGISTRATION_REGISTER,
    ACTION_REGISTERATION_ACTIVATE,
    ACTION_REGISTERATION_CHECK_USERNAME_AVAILABILITY,
    ACTION_REGISTERATION_CHECK_EMAIL_AVAILABILITY
} from 'redux/actions/actionTypes';
import {
    REGISTRATION_URL,
    ACTIVATION_URL,
    USERNAME_URL,
    EMAIL_ADDRESS_URL
} from 'common/constant';

export const register = (signup_form) => ({
    type: ACTION_REGISTRATION_REGISTER,
    payload: {
        request: {
            url: REGISTRATION_URL,
            method: 'POST',
            data: signup_form
        }
    }
});

export const activate = (activation_key) => (dispatch) => {
    return dispatch({
        type: ACTION_REGISTERATION_ACTIVATE,
        payload: {
            request: {
                url: ACTIVATION_URL,
                method: 'POST',
                data: { activation_key }
            }
        }
    });
};

export const checkUsernameAvailability = (username) => ({
    type: ACTION_REGISTERATION_CHECK_USERNAME_AVAILABILITY,
    payload: {
        request: {
            url: `${USERNAME_URL}${username}/`,
            method: 'GET'
        }
    }
});

export const checkEmailAvailability = (email_address) => ({
    type: ACTION_REGISTERATION_CHECK_EMAIL_AVAILABILITY,
    payload: {
        request: {
            url: `${EMAIL_ADDRESS_URL}${email_address}/`,
            method: 'GET'
        }
    }
});
