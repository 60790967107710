import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// selectors
import { getBookById } from 'redux/selectors';
// constant
import { BOOK_COMPLETE, book_creation_steps } from 'common/constant';
// utils
import { openDialog } from 'common/utils/dialog-utils';
// dialogs
import BookCreateDialog from 'pages/Dialogs/BookCreateDialog';
// import BookCreateDialog2 from 'pages/Dialogs/BookCreateDialog2';
import { useHistory } from 'react-router';
import { URLS } from 'common/urls';
// images
import iconCloseSm from 'assets/images/icons_svg/icon_close-sm.svg';

const EditBookDialog = ({ book, close }) => {
    const history = useHistory();

    // open book creation dialog
    const openBookDialog = (step) => {
        openDialog(BookCreateDialog, {
            match: {
                params: {
                    bookId: book.id
                }
            },
            step
        });
    };

    // handler for editing book from previous steps
    const onKeepEditing = () => {
        const bookDraftStep = book.book_draft_step;

        const index = book_creation_steps.findIndex(
            (obj) => obj === bookDraftStep
        );
        if (index >= 0) {
            // open book w/ previous step
            openBookDialog(index + 1);
        } else {
            if (bookDraftStep === BOOK_COMPLETE) {
                // book preview dialog
                history.push(`${URLS.BOOK.PREVIEW}${book.id}`);
            } else {
                // open from front
                openBookDialog(1);
            }
        }
    };

    // handler for editing book from start
    const onStartFront = () => {
        openBookDialog(1);
    };

    return (
        <div className="modal-frame" id="edit-book-modal">
            <div className="modal-box modal-box--600">
                <div className="modal__header">
                    Edit Options
                    <img
                        onClick={close}
                        src={iconCloseSm}
                        alt=""
                        className="modal__close btn-icon btn-icon--3"
                    />
                </div>
                <div className="modal__body edit-book-content">
                    <p>
                        Do you want to start editing where you left off, or at
                        the beginning?
                    </p>
                </div>
                <div className="modal__footer">
                    <button
                        onClick={onKeepEditing}
                        className="btn btn-gradient">
                        Where I left off
                    </button>
                    <button onClick={onStartFront} className="btn btn-outline">
                        At the beginning
                    </button>
                </div>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    book: getBookById
});
export default connect(state)(EditBookDialog);
