import _ from 'underscore';
import {
    FETCH_TIMELINE_APPROVAL_LIST,
    ACTION_USER_LOGOUT
} from 'redux/actions/actionTypes';
import {
    SUCCESS_SUFFIX
} from "common/constant";

const initialState = {
    timelineApprovals: undefined
}

const TimelineApprovalReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TIMELINE_APPROVAL_LIST + SUCCESS_SUFFIX: {
            let timelineApprovals = state.timelineApprovals;

            for (let _i in action.payload.data.objects) {
                let object = action.payload.data.objects[_i];
                const index = _.findIndex(
                    timelineApprovals,
                    (timelineApproval) => object.id === timelineApproval.id
                );

                if (index >= 0)
                    timelineApprovals = [].concat(
                        timelineApprovals.slice(0, index),
                        [object],
                        timelineApprovals.slice(index + 1)
                    );
                else
                    timelineApprovals = [].concat(
                        [object],
                        timelineApprovals || []
                    );
            }

            return _.extend({}, state, {
                timelineApprovals: timelineApprovals,
                meta: action.payload.meta
            });
        }

        case ACTION_USER_LOGOUT: {
            return _.extend({}, initialState);
        }
        default: {
            return state;
        }
    }
}

export default TimelineApprovalReducer;