import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
// Actions
import * as AAMActions from 'redux/actions/allaboutme';
// Images
import deleteImage from 'assets/images/banners/banner-delete.png';
// Icons
import iconCheckSm from 'assets/images/icons_svg/icon_check-sm.svg';

const AAMDeleteQuestionDialog = ({ question, close }) => {
    const [isSaving, setIsSaving] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const dispatch = useDispatch();

    const deleteQuestion = useCallback(async () => {
        setIsSaving(true);
        try {
            await dispatch(AAMActions.deleteAAMQuestion(question.id));
            setIsSaved(true);
            close();
        } catch (err) {
            //
        } finally {
            setIsSaving(false);
        }
    }, [question.id, dispatch, close]);
    return (
        <div id="delete-modal" className="modal-frame">
            <div className="modal-box modal-box--delete modal-box--500">
                <div className="delete-modal">
                    <div className="delete-modal__img">
                        <img
                            src={deleteImage}
                            alt="delete"
                            className="img-fluid"
                        />
                    </div>
                    <div className="delete-modal__content">
                        <div className="delete-modal__title">
                            Delete Question?
                        </div>
                        <div className="delete-modal__subtitle">
                            You are about to delete this question. Are you sure?
                        </div>
                    </div>
                    <div className="delete-modal__actions">
                        <button onClick={close} className="btn btn-cancel mr-2">
                            Cancel
                        </button>
                        {isSaving && (
                            <div className="saving-text pr-2">
                                <div className="saving-gif pr-3" />
                                Saving...
                            </div>
                        )}
                        {!isSaving && isSaved && (
                            <div className="saving-text pr-2">
                                <img src={iconCheckSm} alt="" />
                                Saved!
                            </div>
                        )}
                        <button
                            disabled={isSaving}
                            onClick={deleteQuestion}
                            className="btn btn-delete">
                            <span className="fa fa-trash mr-2"></span>
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AAMDeleteQuestionDialog;
