import React, { useCallback } from 'react';

import AudioWave from 'assets/images/media/audiowave.png';
import { openDialog } from 'common/utils/dialog-utils';
import MediaViewer from './MediaViewer';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import * as EntryMediaActions from 'redux/actions/entrymedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import { URLS } from 'common/urls';
import { useHistory } from 'react-router-dom';
import MediaBanner from 'assets/images/banners/media.svg';
import { createStructuredSelector } from 'reselect';
import { getEntryState } from 'redux/selectors';
import { connect } from 'react-redux';

const MediaGallery = ({ entry }) => {
    const history = useHistory();
    const fetchDataFlag = false;
    const openMediaViewer = useCallback(
        (media) => {
            history.push(URLS.MEDIA.VIEW + media.id);
            openDialog(MediaViewer, { media });
        },
        [history]
    );
    const dispatch = useDispatch();

    const getSrc = (media) => {
        return (
            media?.file || media?.webp_image || media?.xs_image || media?.image
        );
    };

    const renderMedia = useCallback(
        (item, index) => {
            switch (item.type) {
                case 'image': {
                    return (
                        <li onClick={() => openMediaViewer(item)} key={index}>
                            <img src={getSrc(item)} alt="media" />
                        </li>
                    );
                }
                case 'video': {
                    return (
                        <li
                            className="media-gallery-video-detail"
                            onClick={() => openMediaViewer(item)}
                            key={index}>
                            <video className="paused-video">
                                <source src={item?.file} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <div className="media-video-controls">
                                <button
                                    id="play-above"
                                    className="btn play-pause">
                                    <span className="fa fa-play" />
                                </button>
                            </div>
                        </li>
                    );
                }
                case 'audio': {
                    return (
                        <li
                            className="media-gallery-audio-detail"
                            onClick={() => openMediaViewer(item)}
                            key={index}>
                            <div className="audio-visual-holder-gallery">
                                <img src={AudioWave} alt="audiowave" />
                            </div>
                            <div className="media-audio-controls">
                                <button
                                    id="play-above"
                                    className="btn play-pause">
                                    <span className="fa fa-play"></span>
                                </button>
                            </div>
                        </li>
                    );
                }
                case 'file': {
                    return (
                        <li
                            className="media-gallery-file-detail"
                            onClick={() => openMediaViewer(item)}
                            key={index}>
                            <span className="icon-file" />
                            <p className="media-file-desc">
                                {item.file_size / 1000} MB
                            </p>
                        </li>
                    );
                }
                default:
                    return null;
            }
        },
        [openMediaViewer]
    );
    const fetchMoreData = useCallback(() => {
        if (entry.media.next) {
            dispatch(EntryMediaActions.fetchAll(entry.media.next));
        }
    }, [entry.media.next, dispatch]);

    return (
        <>
            {fetchDataFlag ? (
                <section className="banner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 media-banner-card media-card">
                                <div className="banner-img">
                                    <img
                                        src={MediaBanner}
                                        alt="media"
                                        className="img-fluid media-svg"
                                    />
                                </div>
                                <div className="banner-content">
                                    <h3 className="banner-title">
                                        All your media content
                                    </h3>
                                    <p className="banner-text">
                                        Here you will find all the photos,
                                        videos, audios and files you upload to
                                        JRNL. Start uploading files to your
                                        entries or press the “New Media” button.
                                    </p>
                                </div>
                                <button className="btn banner-close">
                                    <span className="icon-times-light"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                <section className="media-gallery-section">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-12 media-gallery">
                                <ul className="media-gallery-list">
                                    <InfiniteScroll
                                        style={{ flexDirection: 'row' }}
                                        className="media-gallery-list"
                                        dataLength={
                                            entry.media.results.length || 20
                                        }
                                        next={fetchMoreData}
                                        hasMore={entry.media.next}
                                        loader={<CircularProgress />}>
                                        {entry.media.results.map(
                                            (item, index) =>
                                                renderMedia(item, index)
                                        )}
                                    </InfiniteScroll>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
};

const state = createStructuredSelector({
    entry: getEntryState
});

export default connect(state)(MediaGallery);
