// @ts-nocheck
import React, { useEffect, useCallback, useRef, useState } from 'react';
import _ from 'underscore';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getUser, getUI, getTagList, getTagsForEntry } from 'redux/selectors';
import * as TagActions from 'redux/actions/tag';
import * as EntryActions from 'redux/actions/entry';
import OutsideClickHandler from 'react-outside-click-handler/esm/OutsideClickHandler';

// Icons
import iconTag from 'assets/images/icons_svg/icon_tag.svg';
import iconAddSm from 'assets/images/icons_svg/icon_add-sm.svg';

const TagDropdownList = ({
    user,
    dispatch,
    ui,
    entry,
    tags,
    onUpdate,
    shouldRenderTagsName,
    selectedTags,
    // @ts-ignore
    ...rest
}) => {
    const tagRef = useRef();
    const dropdownRef = useRef();
    const [showTagsList, setShowTagsList] = useState(false);
    const [newTags, setNewTags] = useState(selectedTags || []);
    const renderTagsName = useCallback(() => {
        if (newTags && newTags !== 0) {
            let name = '';
            for (let i = 0; i < 2; i++) {
                if (newTags[i]?.name) {
                    if (i > 0) {
                        name += `, ${newTags[i].name}`;
                    } else {
                        name = newTags[0].name;
                    }
                }
            }
            return name;
        }
        return undefined;
    }, [newTags]);
    useEffect(() => {
        //dispatch(TagActions.fetch());
    }, [dispatch]);

    useEffect(() => {
        setNewTags(selectedTags || []);
    }, [selectedTags]);

    const onSelect = (tag) => {
        const alreadySelected = newTags.find((t) => t.id === tag.id);
        let data = [...(newTags || [])];
        if (alreadySelected) {
            data = newTags.filter((t) => t.id !== tag.id);
        } else {
            data.push(tag);
        }
        setNewTags(data);
    };
    const onAddTag = useCallback(() => {
        const tagName = tagRef.current.value;
        const tag = {
            name: tagName
        };
        dispatch(TagActions.create(tag)).then((response) => {
            if (response.payload.status === 201) {
                tagRef.current.value = '';
                //dispatch(TagActions.fetch());
                onUpdate(response.payload.data, true);
            }
        });
    }, [dispatch, onUpdate]);

    const renderTagListItem = useCallback(
        (tag) => {
            const isSelected = !!_.findWhere(newTags, {
                resource_uri: tag.resource_uri
            });
            return (
                <li
                    key={tag.id}
                    className={isSelected ? 'active-item-list' : ''}
                    onClick={() => {
                        onSelect(tag);
                    }}>
                    <img src={iconTag} alt="Tag" />
                    {tag.name}
                </li>
            );
        },
        // eslint-disable-next-line
        [newTags]
    );

    const renderTagsBadge = useCallback(() => {
        if (newTags && newTags.length > 1 && newTags.length - 2) {
            return `+${newTags.length - 2}`;
        }
        return null;
    }, [newTags]);
    const onUpdateTags = () => {
        setShowTagsList(!showTagsList);
        if (!_.isEmpty(newTags) || entry.id) {
            if (entry.id) {
                onUpdate(newTags);
                dispatch(EntryActions.update(newTags, entry.id, true));
            } else {
                onUpdate(newTags);
            }
        }
    };
    const handleOutsideClick = () => {
        if (showTagsList) {
            onUpdateTags();
        }
    };
    const toggleTags = () => {
        if (showTagsList) {
            onUpdateTags();
        } else {
            setShowTagsList(true);
        }
    };
    return (
        <div className="post-tags ml-auto">
            {renderTagsName() && (
                <span className={'tags-used'}>
                    {shouldRenderTagsName && renderTagsName()}
                </span>
            )}
            {renderTagsBadge() && (
                <span className="tags-badge">{renderTagsBadge()}</span>
            )}
            <OutsideClickHandler
                onOutsideClick={() => {
                    handleOutsideClick();
                }}>
                <div className="dropdown show">
                    <button
                        className={`btn-icon btn-icon--4 post-tags__button ${
                            newTags.length > 0 && 'btn-xp'
                        }`}
                        onClick={(e) => toggleTags()}>
                        <img src={iconTag} alt="Tags" />
                    </button>
                    <div
                        ref={dropdownRef}
                        className={`dropdown-menu tags-dropdown-menu ${
                            showTagsList && 'show'
                        } `}>
                        <ul className="dropdown-tags-list">
                            {tags.tags &&
                                tags.tags.map((tag) => renderTagListItem(tag))}
                        </ul>
                        <div className="form-input-btn">
                            <input
                                ref={tagRef}
                                className="form-input-btn__input"
                                type="text"
                                placeholder="Add a new tag..."
                            />
                            <button
                                onClick={onAddTag}
                                className="form-input-btn__btn"
                                id="tag-input">
                                <img src={iconAddSm} alt="" />
                            </button>
                        </div>
                    </div>
                </div>
            </OutsideClickHandler>
        </div>
    );
};

const state = createStructuredSelector({
    user: getUser,
    tags: getTagList,
    ui: getUI,
    selectedTags: getTagsForEntry
});

export default connect(state)(TagDropdownList);
