import React, { useEffect, useCallback, useRef } from 'react';
// redux
import { connect } from 'react-redux';
// Selectors
import { getUI } from 'redux/selectors';
import { createStructuredSelector } from 'reselect';
// Actions
import { updateUI, updateStepsFilledIn } from 'redux/actions/ui';
// utils
import { checkFrontCoverSetup } from 'common/utils/validatecheck/bookcreate';

const FrontCoverStep = ({ display, ui, dispatch }) => {
    // check validability
    const title = ui.title;
    const updateStepsFilledIn_FCS = useCallback(
        (val = false) => {
            dispatch(updateStepsFilledIn('FrontCoverStep', val));
        },
        [dispatch]
    );

    const initialRenderRef = useRef(true);

    useEffect(() => {
        if (initialRenderRef.current) {
            initialRenderRef.current = false;
            if (title !== '') {
                updateStepsFilledIn_FCS(true);
            }
        }
    }, [updateStepsFilledIn_FCS, title]);

    useEffect(() => {
        if (display) {
            dispatch(
                updateUI({
                    canGoNext: checkFrontCoverSetup(title)
                })
            );
        }
        return () => {};
    }, [display, title, dispatch]);

    // handlers
    const onChangeFrontCover = useCallback(
        (e) => {
            if (
                e.target.value !== '' &&
                ui.stepsFilledIn.FrontCoverStep === false
            ) {
                updateStepsFilledIn_FCS(true);
            } else if (
                e.target.value === '' &&
                ui.stepsFilledIn.FrontCoverStep === true
            ) {
                updateStepsFilledIn_FCS(false);
            }
            dispatch(
                updateUI({
                    title: e.target.value
                })
            );
        },
        [dispatch, ui.stepsFilledIn.FrontCoverStep, updateStepsFilledIn_FCS]
    );

    return (
        <div
            className={`new-book-step-7 new-book-step ${
                display ? 'active-form' : ''
            }`}>
            <div className="section-title">Front Cover</div>
            <div className="new-book-step-form">
                <div className="form-group">
                    <div className="form-group__item">
                        <label htmlFor="front-cover">Title</label>
                        <input
                            type="text"
                            onChange={onChangeFrontCover}
                            value={ui.title || ''}
                            id="frontCoverTitle"
                            name="front-cover"
                            placeholder="Enter Book Title"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    ui: getUI
});
export default connect(state)(FrontCoverStep);
