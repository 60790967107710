import {
    FETCH_SHARING_CONTACT_LIST,
    ACTION_USER_LOGOUT,
    FETCH_DETAILED_CONTACT_LIST,
    FETCH_SEARCHED_CONTACT_LIST,
    DELETE_CONTACT,
    CREATE_EMPTY_CONTACT,
    EDIT_CONTACT,
    SET_SELECTED_CONTACT
} from 'redux/actions/actionTypes';
import * as ShareContactListsActions from 'redux/actions/sharecontactlist';
import { SUCCESS_SUFFIX } from 'common/constant';
import arrayUtils from 'common/utils/arrayUtils';

const initialState = {
    shareContactLists: [],
    searchedContactList: [],
    selectedContact: null,
    isPending: false,
    isDetailedFetched: false
};

const getIndex = (contactList, object) => {
    return contactList.findIndex((result) => result.id === object.id);
};

const ShareContactListReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SHARING_CONTACT_LIST: {
            return {
                ...state,
                isPending: true
            };
        }
        case FETCH_SHARING_CONTACT_LIST + SUCCESS_SUFFIX: {
            let shareContactLists = state.shareContactLists;
            const deletedContacts =
                JSON.parse(localStorage.getItem('deletedContacts')) || [];

            action.payload.data.objects.forEach((object) => {
                if (!deletedContacts.includes(object.id)) {
                    const index = getIndex(shareContactLists, object);

                    if (index >= 0) {
                        shareContactLists = [].concat(
                            shareContactLists.slice(0, index),
                            [object],
                            shareContactLists.slice(index + 1)
                        );
                    } else {
                        shareContactLists = [].concat(
                            [object],
                            shareContactLists || []
                        );
                    }
                }
            });

            if (action.payload.data.meta.next !== null) {
                action.asyncDispatch(
                    ShareContactListsActions.fetch(
                        action.payload.data.meta.next
                    )
                );
            }

            return {
                ...state,
                shareContactLists,
                isDetailedFetched: false,
                isPending: false
            };
        }
        case FETCH_DETAILED_CONTACT_LIST:
        case FETCH_SEARCHED_CONTACT_LIST: {
            return {
                ...state,
                isPending: true
            };
        }
        case FETCH_DETAILED_CONTACT_LIST + SUCCESS_SUFFIX: {
            let shareContactLists = state.shareContactLists;

            action.payload.data.objects.forEach((object) => {
                const index = getIndex(shareContactLists, object);

                if (index >= 0) {
                    shareContactLists = [].concat(
                        shareContactLists.slice(0, index),
                        [object],
                        shareContactLists.slice(index + 1)
                    );
                } else {
                    shareContactLists = [].concat(
                        [object],
                        shareContactLists || []
                    );
                }
            });

            if (action.payload.data.meta.next !== null) {
                action.asyncDispatch(
                    ShareContactListsActions.fetchDetailed(
                        action.payload.data.meta.next
                    )
                );
            }

            return {
                ...state,
                shareContactLists,
                isDetailedFetched: true,
                isPending: false
            };
        }
        case FETCH_SEARCHED_CONTACT_LIST + SUCCESS_SUFFIX: {
            let searchedContactList = [];

            action.payload.data.objects.forEach((object) => {
                const index = getIndex(searchedContactList, object);

                if (index >= 0) {
                    searchedContactList = [].concat(
                        searchedContactList.slice(0, index),
                        [object],
                        searchedContactList.slice(index + 1)
                    );
                } else {
                    searchedContactList = [].concat(
                        [object],
                        searchedContactList || []
                    );
                }
            });

            return {
                ...state,
                searchedContactList,
                isDetailedFetched: true,
                isPending: false
            };
        }
        case DELETE_CONTACT: {
            const deletedContactId = action.payload.request.data.contactId;
            const shareContactLists = state.shareContactLists.filter(
                (contact) => contact.id !== deletedContactId
            );
            return {
                ...state,
                shareContactLists
            };
        }

        case EDIT_CONTACT + SUCCESS_SUFFIX: {
            return {
                ...state,
                selectedContact: action.payload.data,
                shareContactLists: arrayUtils.mergeArrayByField(
                    state.shareContactLists || [],
                    [action.payload.data],
                    'id',
                    true
                )
            };
        }
        case SET_SELECTED_CONTACT: {
            return {
                ...state,
                selectedContact: action.payload.data
            };
        }
        case CREATE_EMPTY_CONTACT + SUCCESS_SUFFIX: {
            const list = [...state.shareContactLists, action.payload.data];

            return {
                ...state,
                shareContactLists: list
            };
        }

        case ACTION_USER_LOGOUT: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default ShareContactListReducer;
