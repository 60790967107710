import moment from 'moment';
import { ENTRY_DATE } from 'common/constant';

export const checkEntrySetup = (entryRange, minDate, maxDate) => {
    if (!entryRange) {
        return false;
    }
    if (entryRange === ENTRY_DATE) {
        if (!minDate || !maxDate) {
            return false;
        }
        if (moment(minDate) > moment(maxDate)) {
            return false;
        }
    }
    return true;
};

export const checkAAMSetup = (aam) => {
    return !!aam;
};

export const checkColorSetup = (color) => {
    return color !== undefined;
};

export const checkPhotoSetup = (photos, comments) => {
    return photos !== undefined && comments !== undefined;
};

export const checkFrontCoverSetup = (title) => {
    if (title && title.length > 0) {
        return true;
    }
    return false;
};

export const checkSubtitleSetup = (subtitleOption, subtitle) => {
    if (!subtitleOption) {
        return false;
    }
    if (subtitleOption === 'CUSTOM') {
        if (!subtitle || subtitle.length === 0) {
            return false;
        }
    }
    return true;
};

export const checkSpineSetup = (spine) => {
    if (spine && spine.length > 0 && spine.length < 75) {
        return true;
    }
    return false;
};

export const checkBackCoverSetup = (back_cover) => {
    if (back_cover && back_cover.length > 0) {
        return true;
    }
    return false;
};

export const checkCustomIntroductionSetup = (custom_introduction) => {
    if (custom_introduction && custom_introduction.length > 0) {
        return true;
    }
    return false;
};
