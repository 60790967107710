import Compress from 'compress.js';
import { piexif } from 'piexifjs';

const compress = new Compress();

/**
 * Compress Large Image File
 * @param file
 */
export const resizeImageFile = async (file) => {
    const resizedImage = await compress.compress([file], {
        size: 1, // the max size in MB, defaults to 2MB
        quality: 1, // the quality of the image, max is 1,
        resize: true // defaults to true, set false if you do not want to resize the image width and height
    });

    const img = resizedImage[0];
    const base64str = img.data;
    const imgPrefix = img.prefix;
    const imgExt = img.ext;
    let resizedFile;

    try {
        const getExifFromJpegFile = piexif.load(file.dataURL);
        if (getExifFromJpegFile['0th'].hasOwnProperty('274')) {
            // Typically, you will only get flag 1, 8, 3, 6 for digital photos. Flag 2, 7, 4, 5 represent mirrored and rotated version of images.
            switch (getExifFromJpegFile['0th']['274']) {
                case 5:
                    getExifFromJpegFile['0th']['274'] = 5;
                    break;
                case 7:
                    getExifFromJpegFile['0th']['274'] = 7;
                    break;
                case 6:
                    getExifFromJpegFile['0th']['274'] = 8;
                    break;
                case 8:
                    getExifFromJpegFile['0th']['274'] = 6;
                    break;
                default:
                    break;
            }
            getExifFromJpegFile['Exif']['41729'] = '\u0001';
        }
        const exifFile = piexif.insert(
            piexif.dump(getExifFromJpegFile),
            imgPrefix + base64str
        );

        resizedFile = Compress.convertBase64ToFile(
            exifFile.split(',')[1],
            imgExt
        );
    } catch (e) {
        resizedFile = Compress.convertBase64ToFile(base64str, imgExt);
    }

    [
        'lastModifiedDate',
        'name',
        'previewElement',
        'previewTemplate',
        'upload'
    ].forEach((key) => {
        resizedFile[key] = file[key];
    });
    resizedFile['resize'] = true;

    return resizedFile;
};

export const DataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(',');
    const byteString =
        splitDataURI[0].indexOf('base64') >= 0
            ? atob(splitDataURI[1])
            : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
};

export const getFileType = (value) => {
    switch (true) {
        case Boolean(value?.match(/.(jpg|jpeg|png|gif|tiff)$/i)):
            return 'image';
        case Boolean(value?.match(/.(mp4|m4a|flv|MOV|mov)$/i)):
            return 'video';
        case Boolean(value?.match(/.(pdf|doc|docx|xlsm|csv)$/i)):
            return 'file';
        case Boolean(value?.match(/.(mp3|ogg|wav)$/i)):
            return 'audio';
        default:
            break;
    }
    return null;
};
