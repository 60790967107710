import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import axios from 'axios';
import qs from 'qs';
// import settings from 'common/configs/setting';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import * as reducers from 'redux/reducers';
import axiosMiddleware from 'redux-axios-middleware';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import { UserActions } from 'redux/actions/user';
import * as storage from 'redux-storage';
import createEngine from 'redux-storage-engine-localstorage';
import filter from 'redux-storage-decorator-filter';
import history from 'router/history';
import { asyncDispatchMiddleware } from 'redux/middlware/asyncMiddleware';
// middlwares
const logger = createLogger({ collapsed: true });
// axios middleware
const client = axios.create({
    // baseURL: settings.url,
    baseURL: localStorage.getItem('API_URL'),

    responseType: 'json',
    paramsSerializer: (params) => qs.stringify(params, { indicies: false })
});

// storage middleware
const jrnlUserEngine = createEngine('jrnluser');
// persist only these state keys to localStorage
const engine = filter(jrnlUserEngine, ['user']);
export const loadStateFromStorage = storage.createLoader(engine);

const storageMiddleware = storage.createMiddleware(
    engine,
    [],
    [...UserActions]
);

// reducers
const rootReducer = combineReducers({ ...reducers, routing: routerReducer });
const reducer = storage.reducer(rootReducer);
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const errorOption = { returnRejectedPromiseOnError: true }; // option for control full .then() and .catch() functions
export const store = createStore(
    reducer,
    composeEnhancer(
        applyMiddleware(
            axiosMiddleware(client, errorOption),
            thunkMiddleware,
            logger,
            routerMiddleware(history),
            storageMiddleware,
            asyncDispatchMiddleware
        )
    )
);
