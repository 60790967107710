import { BACKGROUND_IMAGES_URL } from 'common/constant';
import buildAuthorization from 'common/utils/buildAuthorization';
import { FETCH_BACKGROUND_IMAGES } from 'redux/actions/actionTypes';
import { UserSelector } from 'redux/selectors/UserSelector';

export const fetch = () => (dispatch, getState) => {
    const url = BACKGROUND_IMAGES_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    return dispatch({
        type: FETCH_BACKGROUND_IMAGES,
        payload: {
            request: {
                url: url,
                method: 'GET',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};
