import React, { useCallback, useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { createStructuredSelector } from 'reselect';
import { getTagsForEntry, getUI, getUser } from 'redux/selectors';
import { connect } from 'react-redux';
import qs from 'qs';

// components
import { updateUI } from 'redux/actions/ui';
// import DropDown from 'components/DropDown';
import EntryComment from 'components/Entry/EntryComment';
import ShareDialog from 'pages/Dialogs/InvitationDialog/ShareDialog';

// Redux Actions
import * as InvitationActions from 'redux/actions/invitation';
import * as CommentActions from 'redux/actions/comment';
import * as EntryActions from 'redux/actions/entry';
// import * as FilterActions from 'redux/actions/filter';
import { setEntryPosition } from 'redux/actions/entry';

// Icons
import iconComments from 'assets/images/icons_svg/icon_comments.svg';
import iconLock from 'assets/images/icons_svg/icon_lock.svg';
import iconShare from 'assets/images/icons_svg/icon_share.svg';
import iconPin from 'assets/images/icons_svg/icon_pin.svg';
import widthGeoLocation from 'common/utils/components/widhGeoLocation';
// import iconTag from 'assets/images/icons_svg/icon_tag.svg';

// Modals
import { AddLocationDialog } from 'pages/Dialogs/add-location-dialog';

// import withFreeAccountCheck from 'common/utils/components/withFreeAccountCheck';
import { URLS } from 'common/urls';
import { openDialog } from 'common/utils/dialog-utils';
import { checkUserSame } from 'common/utils/resourceUtils';
import { getEntryAndJournalInvitations } from 'common/utils/entry_utils';
import ShareEntryInvitationDialog from 'pages/Dialogs/ShareEntryInvitationDialog';
import TagDropdownList from 'components/TagDropdownList/TagDropdownList';

const EntryFooter = ({ entry, isDetailView, dispatch, ui, user }) => {
    const location = useLocation();
    const history = useHistory();
    const showComment = queryString.parse(location.search).showComment;
    const [expandComment, setExpandComment] = useState(Boolean(showComment));
    const [acceptedStatus, setAcceptedStatus] = useState(undefined);
    const [invitationId, setInvitationId] = useState(undefined);

    const checkInvitationExists = useCallback(() => {
        const isJournalShared = Boolean(
            entry?.journal?.shared_with?.length > 0 ||
                entry?.journal?.invitations?.length > 0
        );
        const isJournalSharedWithUser = entry.journal.user?.id !== user.id;
        return (
            (entry?.invitations &&
                Array.isArray(entry?.invitations) &&
                entry?.invitations.length > 0) ||
            isJournalShared ||
            isJournalSharedWithUser
        );
    }, [entry, user.id]);

    const entryHasLocation = useCallback(() => {
        return entry.location?.trim() && entry.location.includes(';');
    }, [entry]);

    const onOpenMap = useCallback(() => {
        const coords = entry.location.split(';');
        const entryLocation = {
            lat: Number(coords[0]),
            lng: Number(coords[1])
        };
        dispatch(setEntryPosition(entryLocation));
        dispatch(
            updateUI({
                dialogs: [
                    widthGeoLocation(
                        AddLocationDialog,
                        entry,
                        '',
                        false,
                        true,
                        true
                    )
                ]
            })
        );
    }, [dispatch, entry]);

    const onSharedUserUpdate = useCallback(
        (sharedUsers, added_users, removed_users, customMessage) => {
            dispatch(
                InvitationActions.inviteUsers(
                    added_users,
                    entry.id,
                    customMessage
                )
            );
            removed_users.forEach((user) => {
                const invitation = (entry?.invitations || []).find((obj) => {
                    return obj.email === user.email;
                });
                if (invitation) {
                    dispatch(InvitationActions.remove(invitation));
                }
            });

            dispatch(
                updateUI({
                    shared_users: [],
                    sharedEntryId: undefined
                })
            );
        },
        [dispatch, entry]
    );

    const openShareDialog = useCallback(() => {
        // check if owner
        const editable =
            checkUserSame(entry.user, user) &&
            (entry.invitations || []).length > 0;
        const invitations = getEntryAndJournalInvitations(entry, user);

        openDialog(ShareDialog, {
            sharedUsers: invitations || [],
            onUpdate: onSharedUserUpdate,
            publicEntry: (invitations || []).length > 0,
            editable
        });
    }, [entry, onSharedUserUpdate, user]);

    const onPostComment = useCallback(
        (comment) => {
            dispatch(CommentActions.create(comment)).then((response) => {
                if (response?.payload?.status === 201) {
                    dispatch(EntryActions.fetchIndividualEntry(entry.id));
                }
            });

            // save draft comment
            dispatch(CommentActions.saveDraftComment({ ...comment, user }));
        },
        [dispatch, user, entry.id]
    );

    const onEditComment = useCallback(
        (comment) => {
            const newComment = {
                id: comment.id,
                content: comment.content
            };

            dispatch(CommentActions.update(newComment)).then((response) => {
                if (response?.payload?.status === 202) {
                    dispatch(EntryActions.fetchIndividualEntry(entry.id));
                }
            });
        },
        [dispatch, entry.id]
    );

    const onDeleteComment = useCallback(
        (comment) => {
            dispatch(CommentActions.remove(comment)).then((response) => {
                if (response?.payload?.status === 204) {
                    dispatch(EntryActions.fetchIndividualEntry(entry.id));
                }
            });
        },
        [dispatch, entry.id]
    );

    const onSortComment = useCallback(
        (sort) => {
            dispatch(EntryActions.entryCommentSort(entry.id, sort));
        },
        [dispatch, entry.id]
    );

    const setShowComments = useCallback(() => {
        if (location.pathname.indexOf('entry') === -1) {
            history.push(`/entry/${entry.id}?showComment=true`);
        } else {
            if (isDetailView === true || acceptedStatus === 'true') {
                setExpandComment(true);
            } else {
                openDialog(ShareEntryInvitationDialog, {
                    invitationId
                });
            }
        }
    }, [
        location,
        entry.id,
        history,
        acceptedStatus,
        invitationId,
        isDetailView
    ]);

    const closedCommentsText = `${entry.comments?.length} ${
        entry.comments?.length === 1 || entry.comments?.length === 0
            ? 'Comment'
            : entry.comments?.length > 1
            ? 'Comments'
            : ''
    }`;

    const onPublicEntry = useCallback(
        (isPublic, entry) => {
            openDialog(ShareDialog, {
                sharedUsers:
                    (entry?.id ? entry.invitations : ui.shared_users) || [],
                onUpdate: onSharedUserUpdate,
                publicEntry: isPublic
            });
        },
        [ui, onSharedUserUpdate]
    );

    // const onClickTag = useCallback(
    //     (tag) => {
    //         dispatch(FilterActions.reset());
    //         dispatch(
    //             FilterActions.update({
    //                 filterByTag: [tag],
    //                 minDate: undefined,
    //                 maxDate: undefined
    //             })
    //         );
    //         dispatch(EntryActions.setLoadingProgress());
    //         dispatch(EntryActions.fetch());
    //         history.push('/');
    //     },
    //     [dispatch, history]
    // );

    useEffect(() => {
        if (
            location.pathname.includes(URLS.ENTRY.ROOT) &&
            queryString.parse(location.search)?.showComments
        ) {
            setExpandComment(true);
        }
        //eslint-disable-next-line
    }, [location.pathname]);

    const onEntryTagUpdate = useCallback(
        (tag, isAdd) => {
            const editEntry = { ...ui.editEntry };
            if (isAdd) {
                if (!editEntry.tags) {
                    editEntry.tags = [];
                }
                editEntry.tags.push(tag.resource_uri);
            } else {
                editEntry.tags = tag;
            }
            dispatch(
                updateUI({
                    editEntry
                })
            );
        },
        [ui.editEntry, dispatch]
    );

    useEffect(() => {
        const queryObj = qs.parse(location.search, {
            ignoreQueryPrefix: true
        });
        setAcceptedStatus(queryObj.accepted);
        setInvitationId(queryObj.invitation);
    }, [location]);

    return (
        <>
            <div className="posted-details">
                <div className="posted-tags-comments w-auto">
                    {!expandComment ? (
                        <button
                            onClick={setShowComments}
                            className="posted-tags-comments__btn btn-icon btn-icon--4">
                            <img src={iconComments} alt="Comments" />
                            {entry.comments?.length}
                        </button>
                    ) : (
                        <button
                            className="btn btn--sm btn-outline btn-close-comments"
                            onClick={() => setExpandComment(false)}>
                            <span>{closedCommentsText}</span>
                            <i className="fal fa-close" />
                        </button>
                    )}
                </div>

                <div className="posted-details__right">
                    {entryHasLocation() && (
                        <div className="entry-location" onClick={onOpenMap}>
                            <div className="entry-location__title truncate">
                                <span>{entry.location.split(';')[2]}</span>
                            </div>
                            <img
                                src={iconPin}
                                className="btn-icon btn-icon--4"
                                alt="Location"
                            />
                        </div>
                    )}

                    <TagDropdownList
                        entry={entry}
                        onUpdate={onEntryTagUpdate}
                        shouldRenderTagsName={false}
                    />

                    {user.sharing && (
                        <div className="posted-share w-auto">
                            {checkInvitationExists() ? (
                                <img
                                    src={iconShare}
                                    className="btn-icon btn-icon--4"
                                    onClick={openShareDialog}
                                    alt="Shared entry"
                                    title="Shared entry"
                                />
                            ) : (
                                <img
                                    src={iconLock}
                                    className="btn-icon btn-icon--4"
                                    onClick={() => onPublicEntry(false)}
                                    alt="Private entry"
                                    title="Private entry"
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
            {expandComment && (
                <EntryComment
                    entry={entry}
                    invitations={entry.invitations || []}
                    onPost={onPostComment}
                    onEdit={onEditComment}
                    onDelete={onDeleteComment}
                    onSort={onSortComment}
                />
            )}
        </>
    );
};

const state = createStructuredSelector({
    entryTags: getTagsForEntry,
    ui: getUI,
    user: getUser
});
export default connect(state)(EntryFooter);
