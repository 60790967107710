import React, { useCallback, useState, useEffect } from 'react';

// Actions
import { connect } from 'react-redux';

// Selectors
import { createStructuredSelector } from 'reselect';

// components
import { getUI } from 'redux/selectors';

const EditImageDataDialog = ({
    open,
    close,
    onUpdate,
    ui,
    dispatch,
    image,
    contentState,
    imageData
}) => {
    const [data, setData] = useState('');
    const _close = useCallback(() => {
        if (close) {
            close();
        }
    }, [close]);

    const done = useCallback(() => {
        if (onUpdate) {
            onUpdate(contentState, image, data);
        }

        _close();
    }, [onUpdate, image, _close, data, contentState]);

    const updateImageData = useCallback((e) => {
        setData(e.target.value);
    }, []);

    useEffect(() => {
        setData(imageData);
        return () => {};
    }, [imageData]);
    return (
        <div className="modal-frame">
            <div className="modal-box modal-box--600">
                <div className="modal__header">Edit Caption</div>
                <div className="modal__body">
                    <form>
                        <div className="form-group">
                            <div className="form-group__item p-0">
                                <textarea
                                    className="min-height-100px"
                                    value={data || ''}
                                    onChange={updateImageData}
                                    placeholder="Add caption here..."
                                />
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal__footer">
                    <button className="btn btn-outline" onClick={_close}>
                        Cancel
                    </button>
                    <button className="btn btn-gradient" onClick={done}>
                        Update
                    </button>
                </div>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    ui: getUI
});

export default connect(state)(EditImageDataDialog);
