import { UserSelector } from 'redux/selectors/UserSelector';
import buildAuthorization from 'common/utils/buildAuthorization';
import {
    FETCH_SHARING_CONTACT_LIST,
    FETCH_DETAILED_CONTACT_LIST,
    CREATE_EMPTY_CONTACT,
    DELETE_CONTACT,
    FETCH_SEARCHED_CONTACT_LIST,
    EDIT_CONTACT,
    SET_SELECTED_CONTACT
} from 'redux/actions/actionTypes';
import {
    DETAILED_CONTACT_LIST_URL,
    INVITATION_CONTACT_LIST_URL,
    CREATE_CONTACT_URL
} from 'common/constant';

export const fetch = (pageUrl) => (dispatch, getState) => {
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    const url = pageUrl || INVITATION_CONTACT_LIST_URL;

    return dispatch({
        type: FETCH_SHARING_CONTACT_LIST,
        payload: {
            request: {
                url,
                method: 'GET',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const fetchDetailed = (pageUrl) => (dispatch, getState) => {
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    const url = pageUrl || DETAILED_CONTACT_LIST_URL;

    return dispatch({
        type: FETCH_DETAILED_CONTACT_LIST,
        payload: {
            request: {
                url,
                method: 'GET',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};
export const fetchSearched = (query) => (dispatch, getState) => {
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    const url = `${CREATE_CONTACT_URL}search/?q=${query}`;
    return dispatch({
        type: FETCH_SEARCHED_CONTACT_LIST,
        payload: {
            request: {
                url,
                data: {
                    query
                },
                method: 'GET',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const createContact = (email) => (dispatch, getState) => {
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    const url = CREATE_CONTACT_URL;

    return dispatch({
        type: CREATE_EMPTY_CONTACT,
        payload: {
            request: {
                url,
                method: 'POST',
                data: {
                    email
                },
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const deleteContact = (contactId) => (dispatch, getState) => {
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    const url = CREATE_CONTACT_URL + contactId;

    return dispatch({
        type: DELETE_CONTACT,
        payload: {
            request: {
                url,
                data: {
                    contactId
                },
                method: 'DELETE',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const editContact = (contactId, email) => (dispatch, getState) => {
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    const url = `${CREATE_CONTACT_URL}${contactId}/`;

    return dispatch({
        type: EDIT_CONTACT,
        payload: {
            request: {
                url,
                data: {
                    email
                },
                method: 'PATCH',
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};

export const setSelectedContact = (contact) => (dispatch, getState) => {
    return dispatch({
        type: SET_SELECTED_CONTACT,
        payload: {
            data: contact
        }
    });
};
