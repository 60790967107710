import React, { useState, useCallback } from 'react';
import AudioWave from 'assets/images/media/audiowave.png';

const DisplayAudio = ({ file }) => {
    const [audioReady, setAudioReady] = useState(false);
    const [play, setPlay] = useState(false);
    const [mute, setMute] = useState(false);

    const PlayPauseAudio = useCallback(() => {
        const audio = document.querySelector('#audio');
        if (play) {
            audio.pause();
            setPlay(false);
        } else {
            audio.play();
            setPlay(true);
        }
    }, [play]);

    const MuteAudio = useCallback(() => {
        const audio = document.querySelector('#audio');
        audio.muted = Boolean(mute);
        setMute(audio.muted);
    }, [mute]);

    const getAudioDuration = useCallback(() => {
        const audio = document.querySelector('#audio');
        if (audioReady) {
            const str_pad_left = (string, pad, length) => {
                return (new Array(length + 1).join(pad) + string).slice(
                    -length
                );
            };
            const duration = parseInt(audio.duration, 10);
            const minutes = Math.floor(duration / 60);
            const seconds = duration - minutes * 60;

            return `${str_pad_left(minutes, '0', 2)}:${str_pad_left(
                seconds,
                '0',
                2
            )}`;
        }
        return '00:00';
    }, [audioReady]);

    const timeUpdate = useCallback(() => {
        const audio = document.querySelector('#audio');
        if (audioReady) {
            const percentage = (audio.currentTime / audio.duration) * 100;
            document.querySelector(
                '#seek-bar span'
            ).style.width = `${percentage}%`;
        }
    }, [audioReady]);

    const onClickBar = useCallback((e) => {
        const audio = document.querySelector('#audio');
        const offset = e.target.getBoundingClientRect();
        const left = e.pageX - offset.left;
        const totalWidth = document.querySelector('#seek-bar').clientWidth;
        const percentage = left / totalWidth;
        const vidTime = audio.duration * percentage;
        audio.currentTime = vidTime;
    }, []);

    return (
        <div>
            {!file.src && (
                <div className="audio-visual-holder">
                    <img
                        src={AudioWave}
                        alt="audiowave"
                        className="audio-visual-img"
                    />
                </div>
            )}
            <div className="add-media-box">
                <audio
                    id="audio"
                    className="add-media-player"
                    src={file.src || file}
                    onCanPlayThrough={() => setAudioReady(true)}
                    onTimeUpdate={timeUpdate}
                />
                <div
                    id="video-controls"
                    className="audio-controls media-controls">
                    <button
                        type="button"
                        id="play-pause"
                        className="btn btn-play"
                        onClick={PlayPauseAudio}>
                        {play ? (
                            <span className="fa fa-pause"></span>
                        ) : (
                            <span className="fa fa-play"></span>
                        )}
                    </button>
                    <div
                        id="seek-bar"
                        className="audio-progress-bar"
                        value={0}
                        max={100}
                        onClick={onClickBar}>
                        <span />
                    </div>
                    <p className="video-duration">{getAudioDuration()}</p>
                    <button
                        type="button"
                        id="mute"
                        className="btn btn-mute"
                        onClick={MuteAudio}>
                        {mute ? (
                            <span className="fa fa-volume-off"></span>
                        ) : (
                            <span className="fa fa-volume-up"></span>
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DisplayAudio;
