import _ from 'underscore';
import {
    CREATE_AVATAR_IMAGE,
    ACTION_USER_LOGOUT,
} from 'redux/actions/actionTypes';
import {
    SUCCESS_SUFFIX
} from 'common/constant';

// Model
const initialState = {
    avatar_image: undefined
};

const AvatarImageReducer = (state = initialState, action) => {

    switch (action.type) {
        case CREATE_AVATAR_IMAGE + SUCCESS_SUFFIX: {
            return _.extend({}, state, {
                avatar_image: action.payload.data
            });
        }
        case ACTION_USER_LOGOUT: {
            return _.extend({}, initialState);
        }
        default: {
            return {
                ...state
            };
        }
    }
}

export default AvatarImageReducer;