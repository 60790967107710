import React, { useCallback, useState } from 'react';

// Actions
import { connect } from 'react-redux';
import * as TagActions from 'redux/actions/tag';

// Icons
import iconCheckSm from 'assets/images/icons_svg/icon_check-sm.svg';
import TextField from 'components/TextField';

const NewTagDialog = ({ close, dispatch, open }) => {
    const [tagName, setTagName] = useState();
    const [isSaving, setIsSaving] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [nameError, setNameError] = useState('');

    const _close = useCallback(() => {
        if (close) {
            close();
        }
    }, [close]);

    const _done = useCallback(() => {
        setIsSaving(true);
        setIsSaved(false);
        setSubmitted(true);

        try {
            dispatch(
                TagActions.create({
                    name: tagName
                })
            )
                .then((response) => {
                    setIsSaved(true);
                    setIsSaving(false);

                    _close();
                })
                .catch((error) => {
                    setIsSaved(false);
                    setIsSaving(false);
                    setNameError(
                        error.error.response.data.validation_errors.name[0]
                    );
                });
        } catch (e) {
            setNameError(e.error.response.data.validation_errors.name[0]);
        }
    }, [_close, dispatch, tagName]);

    const onChangeTagName = useCallback((e) => {
        setTagName(e.target.value);
    }, []);

    return (
        <div className="add-tag-modal modal-frame">
            <div className="modal-box modal-box--600">
                <div className="modal__header modal__header--button">
                    New Tag
                    {isSaving && (
                        <div className="saving-text pr-2">
                            <div className="saving-gif pr-3"></div>Saving...
                        </div>
                    )}
                    {!isSaving && isSaved && (
                        <div className="saving-text pr-2">
                            <img src={iconCheckSm} alt="" /> Saved!
                        </div>
                    )}
                </div>
                <div className="modal__body">
                    <div className="form-group form-group--single">
                        <div className="form-group__item p-0">
                            <TextField
                                id="edit-tag"
                                onChange={onChangeTagName}
                                value={tagName}
                                name="name"
                                required={true}
                                placeholder={'New tag name'}
                                submitted={submitted}
                                errorProps={{
                                    style: { color: '#f00' }
                                }}
                                error={nameError}
                            />
                        </div>
                    </div>
                </div>
                <div className="modal__footer">
                    <button className="btn btn-outline" onClick={_close}>
                        Cancel
                    </button>
                    <button className="btn btn-gradient" onClick={_done}>
                        Add
                    </button>
                </div>
            </div>
        </div>
    );
};

export default connect()(NewTagDialog);
