import React, { useEffect, useCallback } from 'react';
import qs from 'qs';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useHistory } from 'react-router-dom';
// reselects
import { getUser, getUI } from 'redux/selectors';

// core components
import BannerCard from 'components/BannerCard';
import PreLoader from 'components/PreLoader/PreLoader';

// Actions
import * as InvitationActions from 'redux/actions/invitation';
import * as JournalInvitationActions from 'redux/actions/journalinvitation';
import * as FilterActions from 'redux/actions/filter';

// images
import oopsImage from 'assets/images/banners/banner-oops.png';

const InvitationPage = ({ match, user, dispatch, location, ui, ...props }) => {
    const history = useHistory();
    const isAuthorized = user?.isAuthorized;
    const [failedCount, setFailedCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);

    const {
        params: { uniqueId }
    } = match;

    const queryObj = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });

    const handleInvitation = useCallback(
        (isJournal = false) => {
            const DispatchActions = isJournal
                ? JournalInvitationActions
                : InvitationActions;

            const DispatchPromise = dispatch(
                DispatchActions.fetchInvitationWithUniqueKey(uniqueId)
            );
            setLoading(true);

            DispatchPromise.then(async (response) => {
                if (response.payload.status === 200 && response.payload?.data) {
                    const invitationData = response.payload.data;

                    let instanceId;
                    // fetch instance id - entryId or journalId
                    if (!isJournal) {
                        instanceId = invitationData.entry.id;
                    } else {
                        instanceId = invitationData.journal.id;
                    }

                    // get accepted status for invitation
                    let accept_status = invitationData.accept_status;

                    if (queryObj.add) {
                        // Update Invitation Accept status
                        dispatch(
                            DispatchActions.update(
                                {
                                    accept_status: true
                                },
                                uniqueId
                            )
                        );

                        accept_status = true;
                    }

                    // redirect to the page after processing
                    if (isJournal) {
                        dispatch(
                            FilterActions.update({
                                filterByJournal: [
                                    {
                                        id: instanceId
                                    }
                                ],
                                entryViewMode: 'sharing_journal'
                            })
                        );
                        history.push(
                            `/journals/${instanceId}?accepted=${!!accept_status}&invitation=${uniqueId}`
                        );
                    } else {
                        history.push(
                            `/entry/${instanceId}?accepted=${!!accept_status}&invitation=${uniqueId}`
                        );
                    }
                }
            }).catch(() => {
                setFailedCount((prev) => prev + 1);
            });
        },
        [dispatch, uniqueId, history, queryObj.add]
    );

    useEffect(() => {
        if (isAuthorized === true) {
            handleInvitation(true); // invitation handle for journal
            handleInvitation(false); // invitaiton handle for entry
        } else {
            history.push(`/signup/?uniqueId=${uniqueId}`);
        }
    }, [history, handleInvitation, uniqueId, isAuthorized]);
    return (
        <div className="timeline-view-container">
            <section className="timeline">
                <div className={`page-container ${ui.viewWidth}`}>
                    {failedCount === 2 && (
                        <BannerCard
                            bannerClassName={'oops-card banner-card--dark'}
                            image={oopsImage}
                            title={"Oops! We didn't find anything."}
                            text={'Sorry, this invitation has been removed.'}
                            id={'oops-card'}
                        />
                    )}
                </div>
            </section>
            {loading && failedCount !== 2 && <PreLoader />}
        </div>
    );
};

const state = createStructuredSelector({
    user: getUser,
    ui: getUI
});
export default connect(state)(InvitationPage);
