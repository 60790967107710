import React, { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ReminderDialog from 'pages/Dialogs/SettingsDialog/ReminderDialog';
import { getSettingsRemindersList } from 'redux/selectors/ReminderSelector';
import * as ReminderActions from 'redux/actions/reminder';
import {
    REMINDER_EVERY_DAY,
    REMINDER_EVERY_OTHER_DAY,
    REMINDER_EVERY_WEEK
} from 'common/constant';
import { closeDialog, openDialog } from 'common/utils/dialog-utils';
import DeleteDialog from 'components/DeleteDialog';
import DropDown from 'components/DropDown';
import { getJournalList } from 'redux/selectors';

// icons
import iconReminders from 'assets/images/icons_svg/icon_reminders.svg';
import iconAddSm from 'assets/images/icons_svg/icon_add-sm.svg';
import iconEllipsisVert from 'assets/images/icons_svg/icon_ellipsis-vert.svg';
import { URLS } from 'common/urls';
import { useLocation } from 'react-router-dom';

const RemindersPane = ({ reminders, dispatch, journals }) => {
    const location = useLocation();
    const [isFetched, setIsFetched] = useState(false);

    useEffect(() => {
        if (!isFetched) {
            dispatch(ReminderActions.fetch());
            setIsFetched(true);
        }
    }, [dispatch, reminders, isFetched]);

    const toggleAllReminders = useCallback(
        (e) => {
            if (!reminders.reminders && !('results' in reminders.reminders)) {
                return;
            }
            const { results } = reminders.reminders;
            results.map((reminder) => {
                reminder.enabled = e.target.checked;
                return dispatch(ReminderActions.edit(reminder));
            });
        },
        [dispatch, reminders]
    );

    const addReminderModal = useCallback(() => {
        openDialog(ReminderDialog);
    }, []);

    const editReminderModal = useCallback((reminder, index) => {
        openDialog(ReminderDialog, {
            reminder,
            index
        });
    }, []);

    const deleteReminder = useCallback(
        (id) => {
            dispatch(ReminderActions.delete_reminder(id));
            closeDialog();
        },
        [dispatch]
    );

    const openDeleteReminderDialog = useCallback(
        (index) => {
            openDialog(DeleteDialog, {
                title: 'Delete Reminder?',
                text: 'You are about to delete this reminder. Are you sure?',
                onDelete: () => deleteReminder(index)
            });
        },
        [deleteReminder]
    );

    const renderFrequency = (reminder) => {
        switch (reminder.frequency) {
            case REMINDER_EVERY_DAY:
                return 'Every Day';

            case REMINDER_EVERY_OTHER_DAY:
                return 'Every Other Day';

            case REMINDER_EVERY_WEEK:
                return 'Every Week';
            default:
                return '';
        }
    };

    const getJournalName = useCallback(
        (id) => {
            const journal = journals.journals?.find(
                (journal) => journal.id === id
            );
            return journal?.title;
        },
        [journals.journals]
    );

    const toggleReminder = useCallback(
        (reminder, e) => {
            reminder.enabled = e.target.checked;
            dispatch(ReminderActions.edit(reminder));
        },
        [dispatch]
    );
    const isEnabledReminders = reminders?.reminders?.results?.some(
        (reminder) => reminder.enabled
    );
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };
    const activeClassName =
        location.pathname === URLS.SETTINGS.REMINDER ? 'active show' : '';
    return (
        <div
            className={`tab-pane fade reminders-settings ${activeClassName}`}
            id="reminders"
            role="tabpanel"
            aria-labelledby="reminders-tab">
            <div className="enable-reminders-section">
                <p>
                    Reminders sends you an email to remind you to write in JRNL.
                    They are the perfect way to remind yourself to journal your
                    day.
                </p>
                <div className="list">
                    <div className="list__item">
                        <div className="list__content">
                            <div className="list__title">Enable Reminders</div>
                        </div>
                        <div className="toggle toggle--right">
                            <input
                                type="checkbox"
                                id="enableReminders"
                                checked={isEnabledReminders}
                                onChange={toggleAllReminders}
                            />
                            <label
                                className="settings-toggle-desc"
                                htmlFor="enableReminders"></label>
                        </div>
                    </div>
                </div>
            </div>
            {reminders?.isEnabled && (
                <div className="your-reminders-section disabled-reminders-section">
                    <h3 className="settings-title">Your Reminders</h3>
                    {reminders?.reminders?.count === 0 ? (
                        <div className="settings-desc mb-2">
                            You have not created any reminders yet. Create your
                            first reminder clicking the button below.
                        </div>
                    ) : (
                        <ul className="list reminders-list">
                            {reminders?.reminders?.results.map(
                                (reminder, index) => {
                                    return (
                                        <li className="list__item" key={index}>
                                            <div className="list__img list__img--icon btn-icon btn-icon--6 btn-icon--md">
                                                <img
                                                    src={iconReminders}
                                                    alt="Reminder icon"
                                                    aria-labelledby={
                                                        iconReminders
                                                    }
                                                />
                                            </div>
                                            <div className="list__content">
                                                <div className="list__title">
                                                    {reminder.message ||
                                                        `${getJournalName(
                                                            reminder.journal
                                                        )} `}
                                                </div>
                                                <div className="list__subtitle">
                                                    {reminder.frequency ===
                                                    1 ? (
                                                        `${renderFrequency(
                                                            reminder
                                                        )} @ ${reminder.time}`
                                                    ) : (
                                                        <div className="list__subtitle-item">
                                                            {renderFrequency(
                                                                reminder
                                                            )}{' '}
                                                            {capitalizeFirstLetter(
                                                                reminder.day_of_the_week
                                                            )}{' '}
                                                            @ {reminder.time}
                                                        </div>
                                                    )}{' '}
                                                    <div className="list__subtitle-item">
                                                        {reminder.email}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="toggle toggle--right">
                                                <input
                                                    type="checkbox"
                                                    id={`enable ${index}`}
                                                    checked={reminder.enabled}
                                                    onChange={(e) =>
                                                        toggleReminder(
                                                            reminder,
                                                            e
                                                        )
                                                    }
                                                />
                                                <label
                                                    htmlFor={`enable ${index}`}
                                                />
                                            </div>
                                            <div className="dropdown show">
                                                <DropDown
                                                    className="btn-icon btn-icon--4"
                                                    anchor={
                                                        <img
                                                            src={
                                                                iconEllipsisVert
                                                            }
                                                            alt="Options"
                                                        />
                                                    }
                                                    dropDownMenuProps={{
                                                        className:
                                                            'dropdown-reminder-menu '
                                                    }}
                                                    classes={{
                                                        dropdown_list:
                                                            'dropdown-reminder-list'
                                                    }}>
                                                    <li
                                                        onClick={() =>
                                                            editReminderModal(
                                                                reminder,
                                                                index
                                                            )
                                                        }>
                                                        Edit Reminder
                                                    </li>
                                                    <li
                                                        onClick={() =>
                                                            openDeleteReminderDialog(
                                                                reminder.id
                                                            )
                                                        }>
                                                        Delete Reminder
                                                    </li>
                                                </DropDown>
                                            </div>
                                        </li>
                                    );
                                }
                            )}
                        </ul>
                    )}
                    <button
                        onClick={addReminderModal}
                        className="btn btn-reminder btn-gradient">
                        Add a Reminder
                        <img
                            className="right"
                            src={iconAddSm}
                            alt="Add a Reminder"
                            aria-labelledby={iconAddSm}
                        />
                    </button>
                </div>
            )}
        </div>
    );
};

const state = createStructuredSelector({
    reminders: getSettingsRemindersList,
    journals: getJournalList
});

export default connect(state)(RemindersPane);
