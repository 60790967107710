import React, { Fragment, useCallback } from 'react';
// Components
import SharingNotification from 'components/NotificationCard/SharingNotification';
import PasswordChangedNotification from 'components/NotificationCard/PasswordChangedNotification';
import EntryCommentNotification from 'components/NotificationCard/EntryCommentNotification';
import PDFReadyNotification from 'components/NotificationCard/PDFReadyNotification';
import EntryCreateNotification from 'components/NotificationCard/EntryCreateNotification';
import FBAutoImportNotification from 'components/NotificationCard/FBAutoImportNotification';
import ExportDataRequestNotification from 'components/NotificationCard/ExportDataRequestNotification';

const NOTIFICATION_MAP = {
    entry_share: {
        component: (props) => (
            <SharingNotification type="entry_share" {...props} />
        )
    },
    journal_share: {
        component: (props) => (
            <SharingNotification type="journal_share" {...props} />
        )
    },
    change_password: {
        component: PasswordChangedNotification
    },
    entry_comment: {
        component: EntryCommentNotification
    },
    pdf_ready: {
        component: PDFReadyNotification
    },
    journal_export_data_ready: {
        component: ExportDataRequestNotification
    },
    entry_create: {
        component: EntryCreateNotification
    },
    facebook_autoimport: {
        component: FBAutoImportNotification
    }
};

const NotificationCard = ({ notification, fullView = false }) => {
    const { key, data } = notification;

    const renderCard = useCallback(() => {
        const type = data?.type || 'entry_share';
        const NotificationComponent = NOTIFICATION_MAP[type]?.component;

        return NotificationComponent ? (
            <NotificationComponent
                fullView={fullView}
                notificationKey={key}
                data={data}
            />
        ) : (
            <></>
        );
    }, [data, key, fullView]);

    return <Fragment>{renderCard()}</Fragment>;
};

export default NotificationCard;
