import React from 'react';
import logo from 'assets/images/jrnl-logo-graphic.png';

export const UploadProgress = ({ progress }) => {
    return (
        <div className="uploading-photos-modal" id="uploading-photos-modal">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8 col-xl-7 uploading-photos-content">
                        <img src={logo} alt="" />
                        <h5 className="uploading-title uploading-photos-title">
                            Uploading Photos...
                        </h5>
                        <div className="progress-meter-upload">
                            <span style={{ width: `${progress}%` }} />
                        </div>
                        <p className="loading-info">
                            Uploading (
                            <span className="loading-percentage">
                                {progress}%
                            </span>
                            )
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
