import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
// Actions
import * as AAMActions from 'redux/actions/allaboutme';
// Images
import iconCheckSm from 'assets/images/icons_svg/icon_check-sm.svg';
import { AAM_QUESTION_TYPE_CUSTOM } from 'common/constant';

const AAMNewQuestionDialog = ({ close, category }) => {
    const [questionTitle, setQuestionTitle] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const dispatch = useDispatch();

    const createAAMQuestion = useCallback(async () => {
        if (questionTitle !== '') {
            setIsSaving(true);
            try {
                await dispatch(
                    AAMActions.createAAMQuestion({
                        text: questionTitle,
                        question_type: AAM_QUESTION_TYPE_CUSTOM,
                        category: category?.id
                    })
                );

                setIsSaved(true);
                close();
            } catch (err) {
                //
            } finally {
                setIsSaving(false);
            }
        }
    }, [dispatch, questionTitle, category, close]);
    return (
        <div id="add-question-modal" className="modal-frame">
            <div className="modal-box modal-box--600">
                <div className="modal__header modal__header--button">
                    Create a question
                    {isSaving && (
                        <div className="saving-text pr-2">
                            <div className="saving-gif pr-3" />
                            Saving...
                        </div>
                    )}
                    {!isSaving && isSaved && (
                        <div className="saving-text pr-2">
                            <img src={iconCheckSm} alt="" />
                            Saved!
                        </div>
                    )}
                </div>
                <div className="modal__body">
                    <div className="form-group">
                        <div className="form-group__item pt-0">
                            <input
                                type="text"
                                value={questionTitle}
                                onChange={(e) =>
                                    setQuestionTitle(e.target.value)
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="modal__footer">
                    <button onClick={close} className="btn btn-outline">
                        Cancel
                    </button>
                    <button
                        disabled={isSaving}
                        onClick={createAAMQuestion}
                        className="btn btn-gradient">
                        Create
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AAMNewQuestionDialog;
