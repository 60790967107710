/**
 * Description: Custom React Selector
 */

import React from 'react';

const Selector = ({ options, value, ...props }) => {
    return (
        <select className="selector" {...props}>
            {options.map((option, index) => (
                <option key={`selector-option-${index}`} value={option.label}>
                    {option.label}
                </option>
            ))}
        </select>
    );
};

export default Selector;
