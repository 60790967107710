import React, { useCallback } from 'react';

import DropZoneComponent from 'react-dropzone-component';
import dropzoneConfig from 'components/MediaManager/DropzoneConfigs';

let dropzone;
export default ({
    mediaType = 'default',
    onComplete,
    onSuccess,
    onError,
    onRemove,
    onFileAdded,
    onProcessing,
    setFileWhenUploading
}) => {
    const config = useCallback(() => {
        if (dropzoneConfig) {
            return dropzoneConfig[mediaType].config;
        }
    }, [mediaType]);

    const djsConfig = useCallback(() => {
        if (dropzoneConfig) {
            const config = {
                autoProcessQueue: false,
                previewTemplate: dropzoneConfig[mediaType].previewTemplate(),
                error: (file, message) => {
                    if (message) {
                        if (typeof message !== 'string' && !message.error) {
                            message.error = 'Upload Failed!';
                        }
                    }

                    if (file.previewElement) {
                        file.previewElement.classList.add('dz-error');
                        file.previewElement.classList.remove('dz-success');
                        if (
                            typeof message !== 'string' &&
                            message &&
                            message?.error
                        ) {
                            message = message.error;
                        }
                        const _ref = file.previewElement.querySelectorAll(
                            '[data-dz-errormessage]'
                        );
                        let _i, _len;
                        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
                            const node = _ref[_i];
                            node.textContent = message;
                        }
                    }
                }
            };

            return {
                ...dropzoneConfig[mediaType].djsConfig,
                ...config
            };
        }
    }, [mediaType]);

    const dropzoneEventHandlers = useCallback(() => {
        return {
            init: (_dropzone) => {
                dropzone = _dropzone;
            },
            complete: (file) => {
                if (onComplete) {
                    onComplete(file);
                }
            },
            success: (file) => {
                if (onSuccess) {
                    onSuccess(file);
                }
            },
            processing: (file) => {
                if (onProcessing) {
                    onProcessing(file);
                }
            },
            uploadprogress: (file) => {},
            error: (error) => {
                if (onError) {
                    onError(error);
                }
            },
            removedfile: (file) => {
                if (onRemove) {
                    onRemove(file);
                }
            },
            addedfile: (file) => {
                if (onFileAdded) {
                    setFileWhenUploading &&
                        setFileWhenUploading([file.upload.uuid], 0);
                    onFileAdded(file, dropzone);
                }
            }
        };
    }, [
        onComplete,
        onSuccess,
        onProcessing,
        onError,
        onRemove,
        onFileAdded,
        setFileWhenUploading
    ]);

    return (
        <div className="box-input text-center">
            <DropZoneComponent
                config={config()}
                eventHandlers={dropzoneEventHandlers()}
                djsConfig={djsConfig()}>
                <div className="photos-action">
                    <strong>{config().dropMessage}</strong>
                    <div className="btn btn-gradient">
                        <DropZoneComponent
                            config={config()}
                            eventHandlers={dropzoneEventHandlers()}
                            djsConfig={djsConfig()}>
                            {config().uploadButtonLabel}
                        </DropZoneComponent>
                    </div>
                </div>
                <div className="photo-details">
                    {djsConfig().maxFilesize && (
                        <p>Maximum upload size: {djsConfig().maxFilesize} MB</p>
                    )}
                    <p>Formats: {djsConfig().acceptedFiles}</p>
                </div>
            </DropZoneComponent>
        </div>
    );
};
