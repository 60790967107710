import { createSelector } from 'reselect';

export const UserSelector = (state) => state.user;
export const getUser = createSelector(UserSelector, (user) => ({ ...user }));

export const getFontSize = createSelector(
    [UserSelector],
    (user) => user?.font_size
);

export const getUserHeaderColor = createSelector(
    [UserSelector],
    (user) => user?.header_color
);

export const getUserEmotions = createSelector([UserSelector], (user) => {
    if (!user || !('emoticons' in user)) {
        return [];
    }
    return JSON.parse(user?.emoticons);
});

export const getSocialAccounts = createSelector(
    [UserSelector],
    (user) => user?.social_accounts
);

export const getUserTimeLineReadingType = createSelector(
    UserSelector,
    (user) => {
        return user.timeline_view_type;
    }
);
