import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { updateUI } from 'redux/actions/ui';
import { createStructuredSelector } from 'reselect';

// redux selectors
import {
    getFontSize as getFontSizeSelector,
    getShareContactList,
    getUI,
    getUserTimeLineReadingType
} from 'redux/selectors';

// core components
import DropDown from 'components/DropDown';
import SortFilter from './TimelineFilter/SortFilter';
import DisplayFilter from './TimelineFilter/DisplayFilter';
import MoveEntryDialog from 'pages/Dialogs/MoveEntryDialog';
import DeleteEntryDialog from 'pages/Dialogs/DeleteEntryDialog';
import ChangeBackgroundDialog from 'pages/Dialogs/SettingsDialog/ChangeBackgroundDialog';

// constant
import {
    ENTRY_HIDDEN,
    FONT_SIZE_TYPES,
    USER_SETTINGS,
    FULL_VIEW_TYPE,
    SUMMARY_VIEW_TYPE,
    VIEW_WIDTH_MAP,
    COMPACT_MODE,
    DEFAULT_MODE,
    EXPANDED_MODE
} from 'common/constant';
import { FONTSIZE_INPUT_VALUES } from 'pages/Settings/TabPanes/ThemePane';

// Actions
import * as EntryActions from 'redux/actions/entry';
import * as UserActions from 'redux/actions/user';
import * as ContactsActions from 'redux/actions/contact';

// icons
import iconCheck from 'assets/images/icons_svg/icon_check.svg';
import iconManage from 'assets/images/icons_svg/icon_manage-entries.svg';
import iconCaretDown from 'assets/images/icons_svg/icon_caret-down.svg';
import iconThemes from 'assets/images/icons_svg/icon_themes.svg';
import iconDelete from 'assets/images/icons_svg/icon_delete.svg';
import iconMove from 'assets/images/icons_svg/icon_move.svg';
import iconHide from 'assets/images/icons_svg/icon_hide.svg';
import iconCopy from 'assets/images/icons_svg/icon_copy.svg';

// images
import { ListContactItem } from 'components/list_contact_item';
import CopyEntryDialog from 'pages/Dialogs/CopyEntryDialog';

const SettingsSidebar = ({
    dispatch,
    ui,
    userFontSize,
    contacts,
    reading_type,
    open
}) => {
    const [fontSize, setFontSize] = useState(FONTSIZE_INPUT_VALUES.DEFAULT);
    const [openBulkMode, setOpenBulkMode] = useState(false);

    useEffect(() => {
        dispatch(ContactsActions.fetch());
    }, [dispatch]);

    useEffect(() => {
        const rhsElement = document.getElementById('drawer_right');
        if (open === true) {
            rhsElement.classList.add('show-drawer');
        } else {
            rhsElement.classList.remove('show-drawer');
        }
    }, [open]);

    const onChangeFont = useCallback(
        (e) => {
            setFontSize(e.target.value);
            dispatch(
                UserActions.update(
                    {
                        font_size: Object.keys(FONTSIZE_INPUT_VALUES).find(
                            (obj) =>
                                parseInt(FONTSIZE_INPUT_VALUES[obj]) ===
                                parseInt(e.target.value)
                        )
                    },
                    USER_SETTINGS
                )
            );
        },
        [dispatch]
    );

    const changeEntryViewMode = useCallback(
        (isSummaryView) => {
            dispatch(
                UserActions.update({
                    timeline_view_type: isSummaryView
                        ? SUMMARY_VIEW_TYPE
                        : FULL_VIEW_TYPE
                })
            );
            dispatch(
                updateUI({
                    summaryView: isSummaryView,
                    summaryViewWithEntry: {}
                })
            );
        },
        [dispatch]
    );

    const changeViewWidth = useCallback(
        (mode) => {
            dispatch(
                updateUI({
                    viewWidth: mode
                })
            );

            // call user's update
            dispatch(
                UserActions.update({
                    timeline_width: Object.keys(VIEW_WIDTH_MAP).find(
                        (key) => VIEW_WIDTH_MAP[key] === mode
                    )
                })
            );
        },
        [dispatch]
    );

    const setBulkMode = useCallback(
        (e) => {
            const mode = Boolean(Number(e.target.dataset.mode));
            setOpenBulkMode(mode);
            dispatch(
                updateUI({
                    bulkMode: mode,
                    bulkEntries: []
                })
            );
        },
        [dispatch]
    );

    useEffect(() => {
        !ui.bulkMode && setOpenBulkMode(false);
    }, [ui.bulkMode]);

    const openModal = useCallback(
        (modal) => {
            if (ui.bulkEntries?.length > 0) {
                const modal_ =
                    modal === 'move'
                        ? MoveEntryDialog
                        : modal === 'copy'
                        ? CopyEntryDialog
                        : DeleteEntryDialog;
                dispatch(
                    updateUI({
                        dialogs: [modal_]
                    })
                );
            }
        },
        [dispatch, ui.bulkEntries]
    );

    const hideEntries = useCallback(() => {
        ui.bulkEntries.map((entry) => {
            return dispatch(
                EntryActions.patch({ state: ENTRY_HIDDEN }, entry.id)
            );
        });
    }, [ui.bulkEntries, dispatch]);

    const openBackgroundDialog = useCallback(() => {
        dispatch(
            updateUI({
                dialogs: [ChangeBackgroundDialog]
            })
        );
    }, [dispatch]);
    const renderReadingType = useCallback(() => {
        return (
            <>
                {open && (
                    <div className="form-group__item form-group__item--6">
                        <label>Entries</label>
                        <DropDown
                            className="form-group__select"
                            id="readingTypeModal"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            anchor={
                                <React.Fragment>
                                    <span className="form-group__select-text">
                                        {reading_type === SUMMARY_VIEW_TYPE
                                            ? 'Summary'
                                            : 'Full View'}
                                    </span>
                                    <img src={iconCaretDown} alt="" />
                                </React.Fragment>
                            }
                            dropDownMenuProps={{
                                'aria-labelledby': 'readingTypeModal',
                                style: { width: 'auto' }
                            }}>
                            <li
                                onClick={() => changeEntryViewMode(false)}
                                className={`${
                                    reading_type === FULL_VIEW_TYPE &&
                                    'selected'
                                }`}>
                                Full View
                            </li>
                            <li
                                onClick={() => changeEntryViewMode(true)}
                                className={`${
                                    reading_type === SUMMARY_VIEW_TYPE &&
                                    'selected'
                                }`}>
                                Summary
                            </li>
                        </DropDown>
                    </div>
                )}
            </>
        );
    }, [changeEntryViewMode, reading_type, open]);
    const renderReadingView = useCallback(() => {
        const views = [
            { id: COMPACT_MODE, name: 'Compact' },
            { id: DEFAULT_MODE, name: 'Default' },
            { id: EXPANDED_MODE, name: 'Expanded' }
        ];

        const selectedView = (
            views.find((view) => view.id === ui.viewWidth) || views[1]
        ).name;

        return (
            <div className="form-group__item form-group__item--6">
                <label>Reading Width</label>
                <DropDown
                    className="form-group__select"
                    id="readingViewModal"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    dropDownMenuProps={{
                        'aria-labelledby': 'readingViewModal',
                        style: { width: 'auto' }
                    }}
                    anchor={
                        <React.Fragment>
                            <span className="form-group__select-text">
                                {selectedView}
                            </span>
                            <img src={iconCaretDown} alt="" />
                        </React.Fragment>
                    }>
                    {views.map((view, index) => (
                        <li
                            key={index}
                            onClick={() => changeViewWidth(view.id)}
                            className={`${
                                ui.viewWidth === view.id ? 'selected' : ''
                            }`}>
                            {view.name}
                        </li>
                    ))}
                </DropDown>
            </div>
        );
    }, [changeViewWidth, ui.viewWidth]);

    // filter by contact handler
    useEffect(() => {
        setFontSize(
            FONTSIZE_INPUT_VALUES[userFontSize || FONT_SIZE_TYPES.DEFAULT]
        );
    }, [userFontSize]);
    return (
        <div
            className="drawer drawer--right"
            style={{ right: 0, position: 'fixed' }}
            id="drawer_right">
            <div className="drawer__content">
                <div className="drawer__section">
                    {openBulkMode ? (
                        <button
                            data-mode={0}
                            className="btn btn-gradient btn-finish"
                            onClick={setBulkMode}>
                            Finish Editing
                            <img src={iconCheck} alt="" className="right" />
                        </button>
                    ) : (
                        <button
                            data-mode={1}
                            className="btn-drawer btn-drawer--manage"
                            onClick={setBulkMode}>
                            <img src={iconManage} alt="" className="left" />
                            Manage Entries
                        </button>
                    )}

                    {openBulkMode && (
                        <>
                            <ul className="manage-entries-list">
                                <li className="active-item">
                                    <button
                                        className="manage-entries-list__btn"
                                        onClick={hideEntries}>
                                        <img src={iconHide} alt="" />
                                        Hide{' '}
                                        {ui.bulkEntries?.length <= 1
                                            ? 'Entry'
                                            : `${ui.bulkEntries?.length} Entries`}
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className="manage-entries-list__btn"
                                        onClick={() => openModal('move')}>
                                        <img src={iconMove} alt="" />
                                        Move{' '}
                                        {ui.bulkEntries?.length <= 1
                                            ? 'Entry'
                                            : `${ui.bulkEntries?.length} Entries`}
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className="manage-entries-list__btn"
                                        onClick={() => openModal('copy')}>
                                        <img src={iconCopy} alt="" />
                                        Copy{' '}
                                        {ui.bulkEntries?.length <= 1
                                            ? 'Entry'
                                            : `${ui.bulkEntries?.length} Entries`}
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className="manage-entries-list__btn"
                                        onClick={() => openModal('delete')}>
                                        <img src={iconDelete} alt="" />
                                        Delete{' '}
                                        {ui.bulkEntries?.length <= 1
                                            ? 'Entry'
                                            : `${ui.bulkEntries?.length} Entries`}
                                    </button>
                                </li>
                            </ul>
                        </>
                    )}

                    <button
                        className="btn-drawer btn-drawer--background"
                        onClick={openBackgroundDialog}>
                        <img src={iconThemes} alt="" className="left" />
                        Change Background
                    </button>
                </div>

                <div className="drawer__section">
                    <div className="form-group">
                        <div className="form-group__row">
                            {renderReadingView()}
                            {renderReadingType()}
                        </div>
                    </div>

                    <SortFilter />
                    <DisplayFilter />

                    <div className="form-group form-group--text-size">
                        <div className="form-group__item">
                            <label>Text Size</label>
                            <div className="font-slide-container w-100">
                                <ul className="font-size-list-indicators">
                                    <li className="light-font">Aa</li>
                                    <li className="medium-font">Aa</li>
                                    <li className="strong-font">Aa</li>
                                </ul>
                                <input
                                    type="range"
                                    min={FONTSIZE_INPUT_VALUES.SMALL}
                                    max={FONTSIZE_INPUT_VALUES.LARGE}
                                    value={fontSize}
                                    onChange={onChangeFont}
                                    className="font-slider"
                                    id="fontRange"
                                    step={
                                        FONTSIZE_INPUT_VALUES.LARGE -
                                        FONTSIZE_INPUT_VALUES.DEFAULT
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="drawer__section">
                    <div className="drawer__heading">
                        <div className="drawer__title">Contacts</div>
                    </div>
                    <div className="list list--contacts">
                        {contacts?.map((contact) => (
                            <ListContactItem
                                key={contact.id}
                                contact={contact}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    userFontSize: getFontSizeSelector,
    ui: getUI,
    contacts: getShareContactList,
    reading_type: getUserTimeLineReadingType
});

export default connect(state)(SettingsSidebar);
