import React from 'react';

const IndentButton = ({ onClick }) => {
    return (
        <li onClick={onClick}>
            <span className="icon-indent-more"></span>
        </li>
    );
};

export default IndentButton;
