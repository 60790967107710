import _ from 'underscore';
import React, { useCallback, useState, useEffect } from 'react';

// Icons
import iconJournal from 'assets/images/icons_svg/icon_journal.svg';
import iconAdd from 'assets/images/icons_svg/icon_add.svg';
import iconRemove from 'assets/images/icons_svg/icon_remove.svg';
import iconCloseSm from 'assets/images/icons_svg/icon_close-sm.svg';

const JournalFilterList = ({
    journals,
    onChange,
    filterJournals // journal array of filtering
}) => {
    const [selectedId, setSelectedId] = useState();
    const [showAllJournals, setShowAllJournals] = useState(false);
    const [filteredJournals, setFilteredJournals] = useState([]);

    const onSelectJournal = useCallback(
        (journal) => {
            if (journal === 'all') {
                onChange(journal);
                return;
            }

            let newFilterJournals = [...(filterJournals || [])];

            const index = _.findIndex(
                newFilterJournals,
                (item) => item.id === journal.id
            );
            if (index >= 0) {
                newFilterJournals = newFilterJournals.slice(index);
            } else {
                newFilterJournals.push(journal);
            }
            if (onChange) {
                onChange(newFilterJournals);
            }
        },
        [onChange, filterJournals]
    );

    useEffect(() => {
        if (filterJournals && !_.isEmpty(filterJournals)) {
            setSelectedId(filterJournals.map((item) => item.id));
        } else {
            setSelectedId('all');
        }
        return () => {};
    }, [filterJournals]);

    useEffect(() => {
        if (journals) {
            setFilteredJournals(journals?.slice(0, 4));
        }
    }, [journals]);

    const onRemoveJournalFilter = useCallback(
        (journal) => {
            const newFilterJournals = [...(filterJournals || [])];

            const index = _.findIndex(
                newFilterJournals,
                (item) => item.id === journal.id
            );
            if (index >= 0) {
                newFilterJournals.splice(index);
            }
            if (onChange) {
                console.error(newFilterJournals);
                onChange(newFilterJournals.length ? newFilterJournals : 'all');
            }
        },
        [onChange, filterJournals]
    );
    const SetShowAllJournals = useCallback(
        (value) => {
            if (value) {
                setShowAllJournals(true);
                setFilteredJournals(journals);
            } else {
                setShowAllJournals(false);
                setFilteredJournals(journals?.slice(0, 4));
            }
        },
        [journals]
    );
    return (
        <ul className="filter-list">
            <li
                onClick={() => {
                    onSelectJournal('all');
                }}
                className={selectedId === 'all' ? 'active-item-list' : ''}
                key={'all'}>
                <div className="filter-list__content">
                    <img
                        src={iconJournal}
                        alt="All Journals"
                        className="btn-icon btn-icon--6"
                    />
                    All Journals
                </div>
            </li>
            {filteredJournals &&
                filteredJournals.map((journal) => {
                    const activeItem =
                        Array.isArray(selectedId) &&
                        selectedId.includes(journal.id);
                    return (
                        <li
                            className={activeItem ? 'active-item-list' : ''}
                            key={journal.id}>
                            <div
                                className="filter-list__content"
                                onClick={() => {
                                    onSelectJournal(journal);
                                }}>
                                <img
                                    src={iconJournal}
                                    alt="Journal"
                                    className="btn-icon btn-icon--6"
                                />
                                <span className="truncate">
                                    <span>{journal.title}</span>
                                </span>
                            </div>
                            <img
                                src={iconCloseSm}
                                className="close btn-icon btn-icon--4"
                                alt=""
                                onClick={() => onRemoveJournalFilter(journal)}
                            />
                        </li>
                    );
                })}
            <>
                {!showAllJournals ? (
                    <li className="btn-show-all">
                        <div
                            className="filter-list__content"
                            onClick={() => SetShowAllJournals(true)}>
                            <img
                                src={iconAdd}
                                className="btn-icon btn-icon--6"
                                alt=""
                            />
                            Show all journals ({journals?.length})
                        </div>
                    </li>
                ) : (
                    <li className="btn-show-all">
                        <div
                            className="filter-list__content"
                            onClick={() => SetShowAllJournals(false)}>
                            <img
                                src={iconRemove}
                                className="btn-icon btn-icon--6"
                                alt=""
                            />
                            Hide all journals
                        </div>
                    </li>
                )}
            </>
        </ul>
    );
};

export default JournalFilterList;
