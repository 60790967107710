import React, { useCallback } from 'react';
import { connect } from 'react-redux';

// images
import timeLineIcon from 'assets/images/icon-timeline.png';
import iconClose from 'assets/images/icons_svg/icon_close.svg';

import { updateUI } from 'redux/actions/ui';
import { useHistory } from 'react-router';

const OnboardingFirstDialog = ({
    title,
    text,
    onContinue,
    close,
    dispatch
}) => {
    const history = useHistory();
    const onBack = useCallback(() => {
        dispatch(
            updateUI({
                bigStep: 1
            })
        );
        close();
        history.push('/settings/profile');
    }, [close, dispatch, history]);

    const onSkipOnboarding = useCallback(() => {
        localStorage.setItem('skipOnboarding', '1');
        close();
    }, [close]);
    return (
        <div id="delete-sender-modal" className="modal-frame">
            <div className="modal-box modal-box--delete modal-box--500">
                <div className="onboarding-modal">
                    <div className="onboarding-modal__img">
                        <img
                            src={timeLineIcon}
                            alt="banner-img"
                            className="img-fluid"
                        />
                    </div>
                    <div className="onboarding-modal__content">
                        <div className="onboarding-modal__title">{title}</div>
                        <div className="onboarding-modal__subtitle">{text}</div>
                    </div>
                    <div className="onboarding-modal__actions onboarding-modal__actions__timeline ">
                        <button
                            className="btn btn-outline btn-back"
                            onClick={onBack}>
                            Back
                        </button>
                        <div></div>
                        <button
                            className="btn btn-continue btn-timeline"
                            onClick={onContinue}>
                            Continue
                        </button>
                    </div>
                    <button
                        className="banner-card__close btn-close"
                        onClick={onSkipOnboarding}>
                        <img src={iconClose} alt="" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default connect()(OnboardingFirstDialog);
