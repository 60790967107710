const DONATION_TYPE = {
    ONE_TIME: 1,
    RECURRING: 2
};
export const DONATION_AMOUNT_TYPE = [500, 1000, 2500, 5000, 10000];

const STRIPE_CONFIG = {
    development: {
        DONATIONS: {
            ONE_TIME: [
                {
                    link: 'https://buy.stripe.com/test_28og1NaY4cc06BydQQ',
                    price_id: 'price_1Jxq9RBjMLKnfiLWqftaP5Ij',
                    type: DONATION_TYPE.ONE_TIME,
                    qty: 1,
                    value: 500
                },
                {
                    link: 'https://buy.stripe.com/test_00gg1N1nu2Bqgc87st',
                    price_id: 'price_1Jxq9tBjMLKnfiLWs1DRFnGH',
                    type: DONATION_TYPE.ONE_TIME,
                    qty: 1,
                    value: 1000
                },
                {
                    link: 'https://buy.stripe.com/test_8wM2aXaY44JybVS28a',
                    price_id: 'price_1JxqJxBjMLKnfiLWnPKIwmqn',
                    type: DONATION_TYPE.ONE_TIME,
                    qty: 1,
                    value: 2500
                },
                {
                    link: 'https://buy.stripe.com/test_4gwg1N3vCdg43pm4gj',
                    price_id: 'price_1JxqKRBjMLKnfiLWsvzplhlo',
                    type: DONATION_TYPE.ONE_TIME,
                    qty: 1,
                    value: 5000
                },
                {
                    link: 'https://buy.stripe.com/test_fZe4j59U0ek80da4gk',
                    price_id: 'price_1JxqKcBjMLKnfiLWZbk4XSaA',
                    type: DONATION_TYPE.ONE_TIME,
                    qty: 1,
                    value: 10000
                },
                {
                    link: 'https://buy.stripe.com/test_cN24j58PWdg4gc8fZ3',
                    price_id: 'price_1Jp7aXBjMLKnfiLWem9ze8iL',
                    type: DONATION_TYPE.ONE_TIME,
                    value: 100,
                    qty: null,
                    label: '$1~99'
                }
            ],
            RECURRING: [
                {
                    link: 'https://buy.stripe.com/test_fZedTF1nu8ZO9NKcMS',
                    price_id: 'price_1K6WepBjMLKnfiLWkbeZRhuM',
                    type: DONATION_TYPE.RECURRING,
                    qty: 1,
                    value: 10000
                },
                {
                    link: 'https://buy.stripe.com/test_cN24j5gio3Fu7FCaEL',
                    price_id: 'price_1K6WwaBjMLKnfiLWrhpTvtPS',
                    type: DONATION_TYPE.RECURRING,
                    qty: 1,
                    value: 5000
                },
                {
                    link: '',
                    price_id: 'price_1K6WxdBjMLKnfiLWqaxZqMTT',
                    type: DONATION_TYPE.RECURRING,
                    qty: 1,
                    value: 2500
                },
                {
                    link: '',
                    price_id: 'price_1K6WyBBjMLKnfiLW07Wzu1hM',
                    type: DONATION_TYPE.RECURRING,
                    qty: 1,
                    value: 1000
                },
                {
                    link: '',
                    price_id: 'price_1K6Wz6BjMLKnfiLWvosnEbv6',
                    type: DONATION_TYPE.RECURRING,
                    qty: 1,
                    value: 500
                },
                {
                    link: '',
                    price_id: 'price_1K6WzPBjMLKnfiLWNwKE8ABy',
                    type: DONATION_TYPE.RECURRING,
                    qty: 1,
                    value: 100
                }
            ]
        }
    },
    stage: {
        DONATIONS: {
            ONE_TIME: [
                {
                    link: '',
                    price_id: 'price_1KLO6ZJOW6WcAMJKaPzHqaoz',
                    type: DONATION_TYPE.ONE_TIME,
                    qty: 1,
                    value: 500
                },
                {
                    link: '',
                    price_id: 'price_1KLO6ZJOW6WcAMJK54VCBbDz',
                    type: DONATION_TYPE.ONE_TIME,
                    qty: 1,
                    value: 1000
                },
                {
                    link: '',
                    price_id: 'price_1KLO6ZJOW6WcAMJKL0UuDKA6',
                    type: DONATION_TYPE.ONE_TIME,
                    qty: 1,
                    value: 2500
                },
                {
                    link: '',
                    price_id: 'price_1KLO6ZJOW6WcAMJKFEdzrTMc',
                    type: DONATION_TYPE.ONE_TIME,
                    qty: 1,
                    value: 5000
                },
                {
                    link: '',
                    price_id: 'price_1KLO6ZJOW6WcAMJKpnYxHJvR',
                    type: DONATION_TYPE.ONE_TIME,
                    qty: 1,
                    value: 10000
                },
                {
                    link: '',
                    price_id: 'price_1KLO6ZJOW6WcAMJKEnL1ChHq',
                    type: DONATION_TYPE.ONE_TIME,
                    value: 100,
                    qty: null,
                    label: '$1~99'
                }
            ],
            RECURRING: [
                {
                    link: '',
                    price_id: 'price_1KLO6ZJOW6WcAMJKYlsSTAj5',
                    type: DONATION_TYPE.RECURRING,
                    qty: 1,
                    value: 10000
                },
                {
                    link: '',
                    price_id: 'price_1KLO6ZJOW6WcAMJKotGyRawt',
                    type: DONATION_TYPE.RECURRING,
                    qty: 1,
                    value: 5000
                },
                {
                    link: '',
                    price_id: 'price_1KLO6ZJOW6WcAMJK2hbeRaFr',
                    type: DONATION_TYPE.RECURRING,
                    qty: 1,
                    value: 2500
                },
                {
                    link: '',
                    price_id: 'price_1KLO6ZJOW6WcAMJKZvF9Wqi1',
                    type: DONATION_TYPE.RECURRING,
                    qty: 1,
                    value: 1000
                },
                {
                    link: '',
                    price_id: 'price_1KLO6ZJOW6WcAMJKqzq44OXi',
                    type: DONATION_TYPE.RECURRING,
                    qty: 1,
                    value: 500
                },
                {
                    link: '',
                    price_id: 'price_1KLO6ZJOW6WcAMJK3mxHvDtd',
                    type: DONATION_TYPE.RECURRING,
                    qty: 1,
                    value: 100
                }
            ]
        }
    },
    production: {
        DONATIONS: {
            ONE_TIME: [
                {
                    link: '',
                    price_id: 'price_1KLNlgJOW6WcAMJKMvNowUZU',
                    type: DONATION_TYPE.ONE_TIME,
                    qty: 1,
                    value: 500
                },
                {
                    link: '',
                    price_id: 'price_1KLNlgJOW6WcAMJK7XGpS7n8',
                    type: DONATION_TYPE.ONE_TIME,
                    qty: 1,
                    value: 1000
                },
                {
                    link: '',
                    price_id: 'price_1KLNlgJOW6WcAMJKhWxiWq50',
                    type: DONATION_TYPE.ONE_TIME,
                    qty: 1,
                    value: 2500
                },
                {
                    link: '',
                    price_id: 'price_1KLNlgJOW6WcAMJKe2WYSLf3',
                    type: DONATION_TYPE.ONE_TIME,
                    qty: 1,
                    value: 5000
                },
                {
                    link: '',
                    price_id: 'price_1KLNlgJOW6WcAMJKrscn36nv',
                    type: DONATION_TYPE.ONE_TIME,
                    qty: 1,
                    value: 10000
                },
                {
                    link: '',
                    price_id: 'price_1KLNlgJOW6WcAMJKy0gj93S1',
                    type: DONATION_TYPE.ONE_TIME,
                    value: 100,
                    qty: null,
                    label: '$1~99'
                }
            ],
            RECURRING: [
                {
                    link: '',
                    price_id: 'price_1KLNlgJOW6WcAMJKtpJS2jdv',
                    type: DONATION_TYPE.RECURRING,
                    qty: 1,
                    value: 10000
                },
                {
                    link: '',
                    price_id: 'price_1KLNlgJOW6WcAMJKlhUh9iyC',
                    type: DONATION_TYPE.RECURRING,
                    qty: 1,
                    value: 5000
                },
                {
                    link: '',
                    price_id: 'price_1KLNlgJOW6WcAMJKCeS2mBSx',
                    type: DONATION_TYPE.RECURRING,
                    qty: 1,
                    value: 2500
                },
                {
                    link: '',
                    price_id: 'price_1KLNlgJOW6WcAMJK6RqYpGku',
                    type: DONATION_TYPE.RECURRING,
                    qty: 1,
                    value: 1000
                },
                {
                    link: '',
                    price_id: 'price_1KLNlgJOW6WcAMJKjX8MqdtY',
                    type: DONATION_TYPE.RECURRING,
                    qty: 1,
                    value: 500
                },
                {
                    link: '',
                    price_id: 'price_1KLNlgJOW6WcAMJKZzjLASVz',
                    type: DONATION_TYPE.RECURRING,
                    qty: 1,
                    value: 100
                }
            ]
        }
    }
};

export default STRIPE_CONFIG[process.env.REACT_APP_TYPE];
