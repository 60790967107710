import { createSelector } from 'reselect';

export const BookSelector = (state) => state.book;
export const getBookPendingStatus = createSelector(BookSelector, (book) => {
    return book.download_progress;
});
export const BookByIdSelector = (state, props) => {
    if (props.match?.params.bookId) {
        const books = state.book.books;
        const book = (books || []).find(
            (obj) => obj.id === parseInt(props.match?.params.bookId)
        );

        return book;
    }
    return undefined;
};

export const getBookList = createSelector(
    [BookSelector],
    (state) => state.books
);

export const getBookById = createSelector([BookByIdSelector], (book) => book);
export const isPriceCalculating = createSelector(BookSelector, (book) => {
    return book.isCalculating;
});

export const isBookBuilderFullView = createSelector(BookSelector, (book) => {
    return book.isBookBuilderFullView;
});

export const getBookTimers = createSelector([BookSelector], (state) => {
    const data = {
        timers: state.timers,
        isPolling: state.isPolling
    };

    return data;
});

export const getBook = createSelector([BookSelector], (state) => state);
