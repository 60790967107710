import {
    FETCH_BENEFICIARIES_ACCOUNTS,
    CREATE_BENEFICIARIES_ACCOUNT,
    EDIT_BENEFICIARIES_ACCOUNT,
    DELETE_BENEFICIARIES_ACCOUNT
} from 'redux/actions/actionTypes';

export const fetch = () => (dispatch) => {
    const beneficiariesAccounts = JSON.parse(
        localStorage.getItem('beneficiariesAccounts')
    );

    return dispatch({
        type: FETCH_BENEFICIARIES_ACCOUNTS,
        data: beneficiariesAccounts || {
            beneficiariesAccounts: []
        }
    });
};

export const create_account = (data) => (dispatch) => {
    return dispatch({
        type: CREATE_BENEFICIARIES_ACCOUNT,
        data: data
    });
};

export const edit = (account, index) => (dispatch) => {
    return dispatch({
        type: EDIT_BENEFICIARIES_ACCOUNT,
        data: {
            account,
            index
        }
    });
};

export const delete_account = (index) => (dispatch) => {
    return dispatch({
        type: DELETE_BENEFICIARIES_ACCOUNT,
        data: { index }
    });
};
