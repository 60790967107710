import {IPHONE, ANDROID} from 'common/constant';

export const checkDevice = () => {
    const ua = navigator.userAgent;
    const tablet_regex = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i;
    const mobile_regex = /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/;
    const isIphone = /iPad|iPhone|iPod/.test(ua) && !window.MSStream
    return tablet_regex.test(ua)
        ? 'tablet'
        : mobile_regex.test(ua)
            ? isIphone
                ? IPHONE
                : ANDROID
            : 'desktop'
};
