import React, { useCallback, useEffect, useState } from 'react';
import qs from 'qs';
import { connect } from 'react-redux';
import { db, FIREBASE_DB_ROOT } from 'common/firebase';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';

// material ui components
import { Backdrop } from '@material-ui/core';
// Selectors
import { createStructuredSelector } from 'reselect';
import {
    getEntryById,
    getLoadingProgress,
    getUI,
    getUser
} from 'redux/selectors';

// Actions
import * as EntryActions from 'redux/actions/entry';
import * as JournalActions from 'redux/actions/journal';
import * as TagActions from 'redux/actions/tag';
import * as InvitationActions from 'redux/actions/invitation';
import EntryTile from 'components/Entry/EntryTile';
import { updateUI } from 'redux/actions/ui';

// images
import oopsImage from 'assets/images/banners/banner-oops.png';
import BannerCard from 'components/BannerCard';
import jrnlPreloader from 'assets/images/preloader.gif';
import { openDialog } from 'common/utils/dialog-utils';
import EnableSharingConfirmationDialog from 'pages/Dialogs/SettingsDialog/EnableSharingConfirmationDialog';

const EntryView = ({
    match,
    selectedEntry: propsSelectedEntry,
    dispatch,
    location,
    ui,
    user,
    loadingProgress
}) => {
    const [isEmpty, setIsEmpty] = useState(false);
    const [acceptedStatus, setAcceptedStatus] = useState(undefined);
    const [invitationId, setInvitationId] = useState(undefined);
    const [selectedEntry, setSelectedEntry] = React.useState(
        propsSelectedEntry
    );
    const entryUser = React.useMemo(() => selectedEntry && selectedEntry.user, [
        selectedEntry
    ]);
    const { params } = match;

    const fetchInvitationInstance = React.useCallback(
        async (inviteId) => {
            const response = await dispatch(
                InvitationActions.fetchIndividual(inviteId)
            );
            if (response.payload.data) {
                setSelectedEntry(response.payload.data.entry);
            }
        },
        [dispatch]
    );

    useDeepCompareEffectNoCheck(() => {
        dispatch(JournalActions.fetch());
        dispatch(TagActions.fetch());
        dispatch(
            EntryActions.fetch({
                id: params.entryId
            })
        ).then((response) => {
            if (response.payload.data.length === 0) {
                setIsEmpty(true);
            }
        });
    }, []);

    useDeepCompareEffectNoCheck(() => {
        setSelectedEntry(propsSelectedEntry);
    }, [propsSelectedEntry]);

    useEffect(() => {
        const queryObj = qs.parse(location.search, {
            ignoreQueryPrefix: true
        });
        setAcceptedStatus(queryObj.accepted);
        setInvitationId(queryObj.invitation);
        // fetch invitation instance for entry
        if (queryObj.invitation) {
            fetchInvitationInstance(queryObj.invitation);
        }
    }, [location, fetchInvitationInstance]);

    const approveInvitation = useCallback(() => {
        if (user.sharing) {
            // Update Invitation Accept status
            dispatch(
                InvitationActions.update(
                    {
                        accept_status: true
                    },
                    invitationId
                )
            );
            setAcceptedStatus(true);
        } else {
            openDialog(EnableSharingConfirmationDialog, { invitationId });
        }
    }, [dispatch, invitationId, user.sharing]);
    useEffect(() => {
        if (ui.hasNewComment) {
            dispatch(EntryActions.fetchIndividualEntry(params.entryId));
            db.ref(
                `${FIREBASE_DB_ROOT}${user.id}/${ui.lastNotification.key}`
            ).set({
                ...ui.lastNotification,
                read: true
            });
            dispatch(
                updateUI({
                    hasNewComment: false
                })
            );
        }
    }, [
        ui.hasNewComment,
        params.entryId,
        dispatch,
        ui.lastNotification,
        user.id
    ]);
    const renderInvitationBanner = useCallback(() => {
        return (
            <section className="shared-banner">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 banner-card shared-jrnl-banner default-mode">
                            <img
                                className="shared-jrnl-img"
                                src={entryUser?.avatar_image_url}
                                alt="shared-jrnl-img"
                            />
                            <div className="shared-jrnl-banner__content">
                                <h3 className="shared-jrnl-banner__content-title">
                                    {entryUser?.public_display_name} has shared
                                    this entry with you
                                </h3>
                                <p className="shared-jrnl-banner__content-text">
                                    You can add this to My JRNL; you will see it
                                    between your entries
                                </p>
                                <button
                                    onClick={approveInvitation}
                                    className="btn btn-add-to-jrnl">
                                    Add to My JRNL
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }, [entryUser, approveInvitation]);

    const renderLoader = useCallback(() => {
        if (loadingProgress !== 100) {
            return <React.Fragment></React.Fragment>;
        }
        return (
            <Backdrop open style={{ zIndex: 9999 }}>
                <section className="loader-section">
                    <div className="loader">
                        <img src={jrnlPreloader} alt="loader" />
                    </div>
                </section>
            </Backdrop>
        );
    }, [loadingProgress]);

    const loading = !isEmpty && !selectedEntry;

    return (
        <div>
            {acceptedStatus === 'false' &&
                entryUser &&
                renderInvitationBanner()}
            {loading ? (
                renderLoader()
            ) : selectedEntry ? (
                <EntryTile
                    isDetailView={true}
                    entry={selectedEntry}
                    showShareButton={false}
                />
            ) : (
                <BannerCard
                    bannerClassName={'oops-card banner-card--dark'}
                    image={oopsImage}
                    title={"Oops! We didn't find anything"}
                    text={
                        "We couldn't find any post matching your search. Try changing your filters and search again"
                    }
                    id={'hello-card'}
                />
            )}
        </div>
    );
};

const state = createStructuredSelector({
    selectedEntry: getEntryById,
    ui: getUI,
    user: getUser,
    loadingProgress: getLoadingProgress
});

export default connect(state)(EntryView);
