import {
    FETCH_ENTRY_LIST,
    CREATE_ENTRY,
    UPDATE_ENTRY,
    FETCH_ENTRY,
    REMOVE_ENTRY,
    ACTION_USER_LOGOUT,
    PATCH_ENTRY,
    SET_ENTRY_LOADINGPROGRESS,
    CLEAR_LOADINGPROGRESS,
    FETCH_DRAFT_ENTRY,
    CHANGE_SORT_ENTRY_COMMENT,
    FILTER_ENTRY_MEDIA,
    RESET_FILTER_ENTRY_MEDIA,
    FETCH_ENTRY_MEDIA,
    SET_ENTRY_POSITION,
    REMOVE_INVITATION,
    FETCH_SHARED_JOURNAL_ENTRY,
    FETCH_ENTRY_WITH_OPTIONS_SUCCESS,
    FETCH_ENTRY_WITH_OPTIONS,
    RESET_ENTRIES,
    CREATE_ENTRY_MEDIA,
    UPDATE_ENTRY_MEDIA,
    SEARCH_ENTRY_MEDIA,
    FETCH_MEDIA_ENTRY,
    SOCIALIMAGE_UPLOAD
} from 'redux/actions/actionTypes';
import { SUCCESS_SUFFIX, ERROR_SUFFIX } from 'common/constant';
import arrayUtils from 'common/utils/arrayUtils';
import { compareDate } from 'common/utils/dateUtils';

const initialState = {
    entries: [],
    trialEntries: [],
    meta: undefined,
    isPending: false,
    isDraftPending: false,
    isTimelinePending: false,
    isInitialLoad: true,
    media: {
        results: [],
        next: null,
        count: 0
    },
    _media: null,
    mediaEntries: [],
    mediaMeta: undefined,
    coords: undefined
};

const sortOldest = (firstEl, secondEl) =>
    new Date(firstEl.created).getTime() - new Date(secondEl.created).getTime();

const sortNewest = (firstEl, secondEl) =>
    new Date(secondEl.created).getTime() - new Date(firstEl.created).getTime();

const EntryReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ENTRY_WITH_OPTIONS:
        case FETCH_ENTRY_LIST: {
            return {
                ...state,
                isTimelinePending: true,
                isPending: true
            };
        }

        case FETCH_DRAFT_ENTRY: {
            return {
                ...state,
                isDraftPending: true,
                isPending: true
            };
        }

        case RESET_ENTRIES: {
            return {
                ...state,
                ...initialState
            };
        }

        case FETCH_ENTRY_WITH_OPTIONS_SUCCESS:
        case FETCH_ENTRY_LIST + SUCCESS_SUFFIX:
        case FETCH_DRAFT_ENTRY + SUCCESS_SUFFIX: {
            let _i,
                entries = state?.entries || [];
            for (_i = 0; _i < action.payload.data.objects.length; _i++) {
                const object = action.payload.data.objects[_i];

                if (action.payload.config.params.shared_by_me) {
                    object.shared_by_me = true;
                }

                const index = entries.findIndex(
                    (entry) => object.id === entry.id
                );

                if (index >= 0) {
                    if (entries[index].question && !object.question) {
                        object.question = entries[index].question;
                    }

                    if (
                        entries[index].previous_entry &&
                        !object.previous_entry
                    ) {
                        object.previous_entry = entries[index].previous_entry;
                    }
                    if (entries[index].next_entry && !object.next_entry) {
                        object.next_entry = entries[index].next_entry;
                    }
                    if (!object?.comments) {
                        object.comments = [];
                    }
                    object.comments.sortOldestComment =
                        entries[index].sortOldestComment;
                    if (!entries[index].sortOldestComment) {
                        object.comments.sort(sortNewest);
                    } else {
                        object.comments.sort(sortOldest);
                    }

                    entries = [].concat(
                        entries.slice(0, index),
                        [object],
                        entries.slice(index + 1)
                    );
                } else {
                    entries = [].concat([object], entries || []);
                }
            }
            let isTimelinePending = state.isTimelinePending,
                isDraftPending = state.isDraftPending;

            if (action.type === FETCH_ENTRY_LIST + SUCCESS_SUFFIX) {
                isTimelinePending = false;
            } else if (action.type === FETCH_DRAFT_ENTRY + SUCCESS_SUFFIX) {
                isDraftPending = false;
            }
            return {
                ...state,
                isPending: isTimelinePending || isDraftPending,
                isTimelinePending,
                isDraftPending,
                isInitialLoad: false,
                entries,
                meta: action.payload.data.meta
            };
        }

        case FETCH_SHARED_JOURNAL_ENTRY + SUCCESS_SUFFIX: {
            const journal = arrayUtils.eject(action.payload.data.journal, [
                'entries'
            ]);
            const entries = action.payload.data.journal.entries.map(
                (entry) => ({ ...entry, journal })
            );
            const trialEntries = arrayUtils.mergeArrayByField(
                state.trialEntries,
                entries
            );

            return Object.assign({}, state, { trialEntries });
        }

        case CREATE_ENTRY_MEDIA + SUCCESS_SUFFIX:
        case SOCIALIMAGE_UPLOAD + SUCCESS_SUFFIX: {
            const index = state.media.results.findIndex(
                (media) => action.payload.data.id === media.id
            );
            const media =
                index > -1
                    ? state.media.results
                    : [action.payload.data, ...state.media.results];
            return {
                ...state,
                media: {
                    ...state.media,
                    results: media
                }
            };
        }

        case UPDATE_ENTRY_MEDIA + SUCCESS_SUFFIX: {
            const object = action.payload.data;
            let medias = state.media.results;

            const index = medias.findIndex((media) => object.id === media.id);

            medias = [].concat(
                medias.slice(0, index),
                [object],
                medias.slice(index + 1)
            );

            return {
                ...state,
                media: {
                    ...state.media,
                    results: medias
                }
            };
        }

        case CREATE_ENTRY + SUCCESS_SUFFIX: {
            const entries = state.entries;

            return {
                ...state,
                entries,
                draft: action.payload.data
            };
        }
        case UPDATE_ENTRY + SUCCESS_SUFFIX: {
            let entries = state.entries;
            const object = action.payload.data;
            if (
                action.payload.config.params &&
                action.payload.config.params.shared_by_me
            ) {
                object.shared_by_me = true;
            }
            const index = entries.findIndex((entry) => object.id === entry.id);

            if (index >= 0) {
                // These fields only comes to us if we use the /entry/ route. We do not want to throw them away if we have them. Yes this is dumb.
                // fields = ['question','previous_entry','next_entry']
                if (entries[index].question && !object.question) {
                    object.question = entries[index].question;
                }

                if (entries[index].previous_entry && !object.previous_entry) {
                    object.previous_entry = entries[index].previous_entry;
                }

                if (entries[index].next_entry && !object.next_entry) {
                    object.next_entry = entries[index].next_entry;
                }

                object.comments.sortOldestComment =
                    entries[index].sortOldestComment;
                if (!entries[index].sortOldestComment) {
                    object.comments.sort(sortNewest);
                } else {
                    object.comments.sort(sortOldest);
                }

                entries = [].concat(
                    entries.slice(0, index),
                    [object],
                    entries.slice(index + 1)
                );
            }
            return {
                ...state,
                entries,
                draft: action.payload.data
            };
        }
        case PATCH_ENTRY + SUCCESS_SUFFIX:
        case FETCH_ENTRY + SUCCESS_SUFFIX: {
            let entries = state.entries;
            const object = action.payload.data;
            if (
                action.payload.config.params &&
                action.payload.config.params.shared_by_me
            ) {
                object.shared_by_me = true;
            }

            const index = entries.findIndex((entry) => object.id === entry.id);

            if (index >= 0) {
                // These fields only comes to us if we use the /entry/ route. We do not want to throw them away if we have them. Yes this is dumb.
                // fields = ['question','previous_entry','next_entry']
                if (entries[index].question && !object.question) {
                    object.question = entries[index].question;
                }

                if (entries[index].previous_entry && !object.previous_entry) {
                    object.previous_entry = entries[index].previous_entry;
                }

                if (entries[index].next_entry && !object.next_entry) {
                    object.next_entry = entries[index].next_entry;
                }

                object.comments.sortOldestComment =
                    entries[index].sortOldestComment;
                if (!entries[index].sortOldestComment) {
                    object.comments.sort(sortNewest);
                } else {
                    object.comments.sort(sortOldest);
                }

                entries = [].concat(
                    entries.slice(0, index),
                    [object],
                    entries.slice(index + 1)
                );
            } else {
                const sortDirect = compareDate(
                    entries[0]?.entry_date,
                    entries[1]?.entry_date
                );
                if (sortDirect) {
                    if (
                        compareDate(
                            object.entry_date,
                            entries[entries.length - 1].entry_date
                        )
                    ) {
                        entries = [].concat([object], entries || []);
                    }
                } else {
                    if (
                        compareDate(entries[0]?.entry_date, object?.entry_date)
                    ) {
                        entries = [].concat(entries || [], [object]);
                    }
                }
            }
            return {
                ...state,
                entries,
                draft: action.payload.data
            };
        }

        case FETCH_ENTRY_LIST + ERROR_SUFFIX: {
            return {
                ...state,
                isPending: false
            };
        }

        case REMOVE_ENTRY + SUCCESS_SUFFIX: {
            const entries = state.entries.filter(
                (entry) => entry.id !== action.payload.config.options.id
            );

            return {
                ...state,
                entries
            };
        }

        case SET_ENTRY_LOADINGPROGRESS: {
            return {
                ...state,
                loading: true,
                isPending: undefined
            };
        }

        case CLEAR_LOADINGPROGRESS: {
            return {
                ...state,
                loading: false
            };
        }

        case CHANGE_SORT_ENTRY_COMMENT: {
            const entryId = action.payload.entryId;
            const oldest = action.payload.sort;

            const entries = [...(state.entries || [])];
            const index = entries.findIndex((entry) => entry.id === entryId);
            if (index >= 0) {
                const entry = { ...entries[index] };

                entry.sortOldestComment = oldest;
                if (oldest) {
                    entry.comments.sort(sortOldest);
                } else {
                    entry.comments.sort(sortNewest);
                }

                entries[index] = entry;
            }
            return {
                ...state,
                entries
            };
        }

        case REMOVE_INVITATION + SUCCESS_SUFFIX: {
            const entryId = action.meta.previousAction.payload.entryId;
            const entries = state.entries.filter(
                (entry) => entry.id !== entryId
            );

            return {
                ...state,
                entries
            };
        }
        case ACTION_USER_LOGOUT: {
            return initialState;
        }
        case SEARCH_ENTRY_MEDIA + SUCCESS_SUFFIX: {
            return {
                ...state,
                media: action.payload.data
            };
        }
        case FILTER_ENTRY_MEDIA + SUCCESS_SUFFIX: {
            const result = {
                ...state,
                media: action.payload.data
            };
            if (!result._media) {
                result._media = state.media;
            }
            return result;
        }
        case RESET_FILTER_ENTRY_MEDIA: {
            return {
                ...state,
                _media: null,
                media: state._media
            };
        }
        case FETCH_ENTRY_MEDIA + SUCCESS_SUFFIX: {
            return {
                ...state,
                media: {
                    ...action.payload.data,
                    results: [
                        ...state.media.results,
                        ...action.payload.data.results
                    ]
                }
            };
        }
        case FETCH_MEDIA_ENTRY + SUCCESS_SUFFIX: {
            const newMedia = [
                ...state.mediaEntries,
                ...action.payload.data.objects
            ];
            return {
                ...state,
                mediaEntries: newMedia,
                mediaMeta: action.payload.data.meta
            };
        }
        case SET_ENTRY_POSITION: {
            return { ...state, coords: action.coords };
        }
        default: {
            return state;
        }
    }
};

export default EntryReducer;
