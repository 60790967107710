import buildAuthorization from 'common/utils/buildAuthorization';

import { UserSelector } from 'redux/selectors/UserSelector';
import { FETCH_TIMELINE_APPROVAL_LIST } from 'redux/actions/actionTypes';
import { TIMELINE_APPROVAL_URL } from 'common/constant';

export const fetch = (options = {}) => (dispatch, getState) => {
    const url = TIMELINE_APPROVAL_URL;
    const user = UserSelector(getState());
    const auth = buildAuthorization(user);

    dispatch({
        type: FETCH_TIMELINE_APPROVAL_LIST,
        payload: {
            request: {
                url,
                method: 'GET',
                params: options,
                headers: {
                    Authorization: auth
                }
            }
        }
    });
};
