import React, { useCallback } from 'react';
// constants
import { SOCIAL_TYPE_FACEBOOK, SOCIAL_TYPE_INSTAGRAM } from 'common/constant';
//components
import FacebookMediaContent from 'pages/Dialogs/SocialMedia/MedialContents/FacebookMediaContent';
import InstagramMediaContent from 'pages/Dialogs/SocialMedia/MedialContents/InstagramMediaContent';

const SocialMediaPhotosDialog = ({ type, close, media_view = false }) => {
    const renderMediaContent = useCallback(() => {
        switch (type) {
            case SOCIAL_TYPE_INSTAGRAM: {
                return (
                    <InstagramMediaContent
                        close={close}
                        media_view={media_view}
                    />
                );
            }
            case SOCIAL_TYPE_FACEBOOK: {
                return (
                    <FacebookMediaContent
                        close={close}
                        media_view={media_view}
                    />
                );
            }
            default: {
                return null;
            }
        }
    }, [close, type, media_view]);

    return (
        <div className="modal-frame" id="social-media-modal">
            {renderMediaContent()}
        </div>
    );
};

export default SocialMediaPhotosDialog;
