import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { Modal } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { donate as donateAPI } from 'api/stripe/donate';
import donationMindImg from 'assets/images/don-mind.jpg';
import donationAdsImg from 'assets/images/don-ads.jpg';
import donationPromiseImg from 'assets/images/don-promise.jpg';
import StripeCFG from 'common/configs/stripe';
import { URLS } from 'common/urls';
import { getUser } from 'redux/selectors';

const DonationMain = () => {
    return (
        <div className="donation-main">
            <div className="donation-content__message">
                <h1 className="bold-font">
                    Your donation supports the human right to free thought for
                    everyone.
                </h1>

                <a className="dbtn skip-to-donate" href="#donations">
                    Donation Form{' '}
                    <span
                        className="iconify-inline"
                        data-icon="akar-icons:arrow-down"></span>
                </a>

                <img className="img__right" src={donationMindImg} alt="" />

                <p>
                    <b className="bold-font">Our mission here at JRNL</b> is to
                    provide our users with a safe place where they can preserve
                    their inner-most thoughts and feelings, and do so in a
                    private, secure manner.
                </p>

                <p>
                    <b className="bold-font">Thought police are so 1984</b>. We
                    believe privately recording your thoughts or sharing them
                    with family and friends without fear of censorship is a
                    basic human right.
                </p>

                <p>
                    <b className="bold-font">Free journaling with no ads</b>. We
                    believe that anyone who wants to keep a personal journal
                    should be able to do so, freely, with JRNL. We also believe
                    that the best JRNL should be AD-FREE and never compromise
                    user data. In an effort to off-set some of the costs of
                    maintaining servers and developing the best online
                    journaling platform available, we have introduced Power
                    JRNLer accounts. These paid accounts offer additional
                    features and added benefits to a Basic JRNLer plan.&nbsp;
                </p>

                <img className="img__left" src={donationAdsImg} alt="" />

                <p>
                    <b className="bold-font">The best online journal</b>. The
                    features available now are just the tip of the iceberg. We
                    have many additional features lined up in our roadmap ready
                    for design, development and deployment. When we said we are
                    working to develop the BEST online journaling platform
                    available, we weren&#39;t exaggerating. We have been working
                    toward this goal for more than a decade and we are finally
                    in the position to complete our vision.
                </p>

                <p>
                    <b className="bold-font">We have promises to keep</b>. As
                    you can imagine, building JRNL is neither easy nor cheap. So
                    many of you have remained loyal to us for years, as we’ve
                    navigated hiccups in the development process, and for that
                    we are eternally grateful.
                </p>

                <img className="img__right" src={donationPromiseImg} alt="" />

                <p>
                    We have always maintained we would keep our platform private
                    and free of any ads. We also have no desire to sell the JRNL
                    platform, as we are all too aware that not all companies
                    would have the same mission or integrity. We have made
                    promises to our users that we plan to keep.
                </p>

                <p>
                    <b className="bold-font">Here&#39;s where you come in</b>.
                    To keep our costs manageable, we need your help. We are
                    asking that you consider supporting us in some financial
                    way. Whether that is through book orders, upgrading your
                    account to a Power JRNLer account or offering a donation,
                    any and all financial support is so appreciated and helps us
                    to continue toward our mission.
                </p>

                <p>
                    <b className="bold-font">Thank you</b> for being a JRNLer!
                </p>

                <p>– The JRNL Team</p>
            </div>
        </div>
    );
};

const QuantityInput = (props) => {
    return (
        <>
            $
            <input
                data-checkout-mode="payment"
                data-price-id={props.price}
                disabled={props.disabled}
                id={props.price}
                name={props.price}
                type="number"
                min="1"
                max="99"
                step="1"
                defaultValue={props.qty}
                onChange={(e) => props.setQty(e.target.value)}
                style={{ width: '50px' }}
            />
        </>
    );
};

const DonationItems = (props) => {
    const donations = props.items.map((item) => {
        const price = parseFloat(String(item.value / 100)).toFixed(2);
        const label = item?.label || `$${price}`;
        return (
            <li key={item.price_id}>
                <input
                    checked={item.price_id === props.priceId}
                    className="radio"
                    data-checkout-mode={props.mode}
                    data-price-id={item.price_id}
                    disabled={props.loading}
                    onChange={() => {}}
                    id={item.price_id}
                    name={item.price_id}
                    type="radio"
                    value={item.value}
                />
                <label htmlFor={item.price_id}>
                    {item.qty === 1 ? (
                        label
                    ) : (
                        <QuantityInput
                            disabled={props.loading}
                            qty={props.qty}
                            setQty={props.setQty}
                            price={item.price_id}
                        />
                    )}
                </label>
            </li>
        );
    });
    return <>{donations}</>;
};

const DonationOnce = (props) => {
    const mode = 'payment';
    const [priceId, setPriceId] = useState(props.donations[0].price_id);
    const [qty, setQty] = useState(props.donations[0].qty);
    const changePrice = (event) => {
        setPriceId(event.target.id);
    };
    const donate = () => {
        props.setLoading(true);
        donateAPI({ mode, priceId, qty, user: props.user }).then(
            (r) => {
                window.location = r.url;
                props.setLoading(false);
            },
            () => {
                props.setLoading(false);
            }
        );
    };
    return (
        <div id="content1" className="tabs__content">
            <ul onChange={changePrice}>
                <DonationItems
                    mode={mode}
                    items={props.donations}
                    loading={props.loading}
                    priceId={priceId}
                    setQty={setQty}
                />
            </ul>
            <button
                className="dbtn donations__btn donations__btn--once"
                disabled={props.loading}
                onClick={() => donate()}
                type="button">
                Donate Selected Amount
            </button>
        </div>
    );
};

const DonationRecurring = (props) => {
    // TODO: make DonationRecurring & DonationOnce as one object with diff type
    const mode = 'subscription';
    const [priceId, setPriceId] = useState(props.donations[0].price_id);
    const [qty, setQty] = useState(props.donations[0].qty);
    const changePrice = (event) => {
        setPriceId(event.target.id);
    };
    const donate = () => {
        props.setLoading(true);
        donateAPI({ mode, priceId, qty, user: props.user }).then(
            (r) => {
                window.location = r.url;
                props.setLoading(false);
            },
            () => {
                props.setLoading(false);
            }
        );
    };
    return (
        <div id="content2" className="tabs__content">
            <ul onChange={changePrice}>
                <DonationItems
                    mode={mode}
                    items={props.donations}
                    loading={props.loading}
                    priceId={priceId}
                    setQty={setQty}
                />
            </ul>
            <button
                className="dbtn donations__btn donations__btn--month div-button"
                disabled={props.loading}
                onClick={() => donate()}
                type="button">
                Donate Selected Amount
            </button>
        </div>
    );
};

const DonationAside = (props) => {
    const [tab, setTab] = useState(0);
    const [loading, setLoading] = useState(false);
    return (
        <div className="donation-aside">
            <div id="donations">
                <h2>Donation Type</h2>
                <div className="tabs tabs--settings tabs--light">
                    <div className="tabs__title-wrapper">
                        <button
                            id="tab1"
                            onClick={() => setTab(0)}
                            className={`tabs__title tabs__title--once div-button ${
                                tab === 0 && 'active'
                            }`}>
                            One-time
                        </button>
                        <div className="spacer"></div>
                        <button
                            id="tab2"
                            onClick={() => setTab(1)}
                            className={`tabs__title tabs__title--month div-button ${
                                tab === 1 && 'active'
                            }`}>
                            Recurring
                        </button>
                    </div>
                    <div className="tabs__content-wrapper">
                        {tab === 0 && (
                            <DonationOnce
                                donations={StripeCFG.DONATIONS.ONE_TIME}
                                loading={loading}
                                setLoading={setLoading}
                                user={props.user}
                            />
                        )}
                        {tab === 1 && (
                            <DonationRecurring
                                donations={StripeCFG.DONATIONS.RECURRING}
                                loading={loading}
                                setLoading={setLoading}
                                user={props.user}
                            />
                        )}
                        <div className="tabs__info">
                            <h3>Where your donation goes</h3>
                            <p>
                                In a nutshell, all the stuff that Ad revenue
                                typically pays for: Servers, bandwidth,
                                maintenance, development, design, everything
                                needed to keep JRNL going strong and continually
                                innovating to be the best journal out there!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const DONATE_INIT = -1;
const DONATE_MODAL_OPEN = 0;
const DONATE_MODAL_OPENED = 1;
const DONATE_FAIL_TEXT = 'Unable to complete the transaction!';
// const DONATE_SUCCESS = 1;
const DONATE_SUCCESS_TEXT = 'Thanks for your donation!';
const DONATE_RESULT_Q = 'paid_success';

const DonateModal = (props) => {
    const closeModal = () => {
        if (props.donateModalOpen === DONATE_MODAL_OPEN) {
            props.setDonateModalOpen(DONATE_MODAL_OPENED);
        }
    };
    return (
        <Modal
            open={props.donateModalOpen === DONATE_MODAL_OPEN}
            style={{ top: '20%' }}>
            <section className="donate-modal">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6 col-xl-4 donate-modal-card">
                            <div className="col-12 close-btn">
                                <IconButton
                                    aria-label="close"
                                    onClick={() => closeModal()}>
                                    <CloseIcon />
                                </IconButton>
                            </div>

                            <div className="col-12 p-2">
                                <span
                                    className="fa-stack"
                                    style={{
                                        color: '#1852f5',
                                        verticalAlign: 'top'
                                    }}>
                                    <i
                                        className="fas fa-hands fa-stack-2x"
                                        style={{ paddingTop: '.66rem' }}></i>
                                    {props.paid ? (
                                        <i
                                            className="far fa-heart fa-stack-1x"
                                            style={{
                                                paddingLeft: '.1rem'
                                            }}></i>
                                    ) : (
                                        <i
                                            className="fas fa-close fa-stack-1x"
                                            style={{
                                                color: 'Tomato',
                                                paddingLeft: '.1rem'
                                            }}></i>
                                    )}
                                </span>
                                <h3 className="mt-4">
                                    {props.paid
                                        ? DONATE_SUCCESS_TEXT
                                        : DONATE_FAIL_TEXT}
                                </h3>
                            </div>
                            <div className="col-12 p-4">
                                <Link
                                    to={URLS.TIMELINE}
                                    onClick={() => closeModal()}>
                                    Go back to Dashoard
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Modal>
    );
};

const DonationView = (props) => {
    const location = useLocation();
    const [donateModalOpen, setDonateModalOpen] = useState(DONATE_INIT);
    const [donateSuccess, setDonateSuccess] = useState(DONATE_INIT);

    useEffect(() => {
        if (
            location.search.includes(DONATE_RESULT_Q) &&
            donateModalOpen < DONATE_MODAL_OPEN
        ) {
            const q = new URLSearchParams(location.search);
            const paid = q.get(DONATE_RESULT_Q);
            setDonateSuccess(Number(paid));
            setDonateModalOpen(DONATE_MODAL_OPEN);
        }
    }, [location.search, donateModalOpen]);

    return (
        <div className="donation-content">
            <DonateModal
                donateModalOpen={donateModalOpen}
                donateSuccess={donateSuccess}
                paid={donateSuccess}
                setDonateModalOpen={setDonateModalOpen}
            />
            <DonationMain />
            <DonationAside user={props.user} />
        </div>
    );
};

const state = createStructuredSelector({
    user: getUser
});
export default connect(state)(DonationView);
