import _ from 'underscore';
import {
    FETCH_COMMENT_LIST,
    FETCH_SHARED_COMMENT_LIST,
    CREATE_COMMENT,
    UPDATE_COMMENT,
    REMOVE_COMMENT,
    ACTION_USER_LOGOUT,
    SET_COMMENT_LOADINGPROGRESS,
    CLEAR_LOADINGPROGRESS,
    FETCH_ENTRY_LIST,
    FETCH_ENTRY,
    SAVE_DRAFT_COMMENT
} from 'redux/actions/actionTypes';
import { SUCCESS_SUFFIX, ERROR_SUFFIX } from 'common/constant';

const initialState = {
    comments: undefined,
    meta: undefined,
    isPending: false
};

const updateOriginalComments = (
    originalData = [],
    newData = [],
    field = 'id'
) => {
    for (let i = 0, len = newData.length; i < len; i++) {
        const object = newData[i];

        const index = _.findIndex(
            originalData,
            (item) => parseInt(item[field]) === parseInt(object[field])
        );

        if (index >= 0) {
            originalData = [].concat(
                originalData.slice(0, index),
                [object],
                originalData.slice(index + 1)
            );
        } else {
            originalData = [].concat([object], originalData || []);
        }
    }

    return originalData;
};
const CommentReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_COMMENT_LIST: {
            return _.extend({}, state, {
                isPending: true
            });
        }

        case FETCH_ENTRY_LIST + SUCCESS_SUFFIX: {
            const entries = action.payload.data.objects;
            let comments = state.comments || [];

            for (let i = 0; i < entries.length; i++) {
                const entry = entries[i];
                comments = updateOriginalComments(comments, entry.comments);
            }

            return _.extend({}, state, {
                comments
            });
        }

        case FETCH_ENTRY + SUCCESS_SUFFIX: {
            const entry = action.payload.data;
            let comments = state.comments || [];

            comments = updateOriginalComments(comments, entry.comments);

            return _.extend({}, state, {
                comments
            });
        }

        case FETCH_COMMENT_LIST + SUCCESS_SUFFIX:
        case FETCH_SHARED_COMMENT_LIST + SUCCESS_SUFFIX: {
            let comments = state.comments;
            let _i,
                _len,
                _ref = action.payload.data.objects;

            for (_i = 0, _len = _ref.length; _i < _len; _i++) {
                const object = _ref[_i];

                const index = _.findIndex(
                    comments,
                    (comment) => object.id === comment.id
                );

                if (index >= 0) {
                    comments = [].concat(
                        comments.slice(0, index),
                        [object],
                        comments.slice(index + 1)
                    );
                } else {
                    comments = [].concat([object], comments || []);
                }
            }

            return _.extend({}, state, {
                isPending: false,
                comments,
                meta: action.payload.data.meta
            });
        }

        case CREATE_COMMENT + SUCCESS_SUFFIX:
        case UPDATE_COMMENT + SUCCESS_SUFFIX: {
            let comments = state.comments;
            const object = action.payload.data;

            let index = _.findIndex(
                comments,
                (comment) => object.id === comment.id
            );

            if (index < 0) {
                index = _.findIndex(
                    comments,
                    (comment) => `${object.draftId}` === `${comment.draftId}`
                );
            }

            if (index >= 0) {
                comments = [].concat(
                    comments.slice(0, index),
                    [object],
                    comments.slice(index + 1)
                );
            } else {
                comments = [].concat([object], comments || []);
            }

            return _.extend({}, state, {
                isPending: false,
                comments
            });
        }

        case FETCH_COMMENT_LIST + ERROR_SUFFIX: {
            return _.extend({}, state, {
                isPending: false
            });
        }

        case REMOVE_COMMENT + SUCCESS_SUFFIX: {
            let comments = state.comments;
            const commentId =
                action.payload.config &&
                action.payload.config.options &&
                action.payload.config.options.commentId;
            comments = state.comments.filter(
                (comment) => comment.id !== commentId
            );

            return _.extend({}, state, {
                isPending: false,
                comments
            });
        }

        case SAVE_DRAFT_COMMENT: {
            let comments = state.comments || [];

            comments = updateOriginalComments(comments, [action.payload.data]);
            return _.extend({}, state, {
                comments
            });
        }

        case SET_COMMENT_LOADINGPROGRESS: {
            return _.extend({}, state, {
                loading: true,
                isPending: undefined
            });
        }

        case CLEAR_LOADINGPROGRESS: {
            return _.extend({}, state, {
                loading: false
            });
        }

        case ACTION_USER_LOGOUT: {
            return _.extend({}, initialState);
        }
        default: {
            return state;
        }
    }
};

export default CommentReducer;
