import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import DisplayVideo from './DisplayVideo';
import DisplayAudio from './DisplayAudio';
import DisplayDocument from './DisplayDocument';

// Selectors
import { createStructuredSelector } from 'reselect';
import { getUI, getEditorState } from 'redux/selectors';
import AddJournalEntry from 'components/media/AddJournalEntry';

const DisplayFile = ({
    file,
    close,
    done,
    ui,
    editor,
    media_view = false,
    changeEntryStatus,
    selectedEntryList,
    deleteJournalEntry
}) => {
    const [description, setDescription] = useState('');
    const type = file.type.slice(0, file.type.indexOf('/'));
    const ShowFile = useCallback(() => {
        switch (type) {
            case 'video':
                return <DisplayVideo file={file} />;
            case 'audio':
                return <DisplayAudio file={file} />;
            case 'application':
                return <DisplayDocument file={file} />;
            default:
                return <p>Unsupported format.</p>;
        }
    }, [file, type]);
    const onChangeInputValue = (el) => {
        if (el.style.maxHeight !== 'none') {
            el.style.maxHeight = 'none';
        }
        setDescription(el?.value);
    };
    const renderAddJournalEntry = useCallback(() => {
        return (selectedEntryList || []).map((selectedEntry, index) => {
            return (
                <AddJournalEntry
                    key={index}
                    index={index}
                    selectedEntry={selectedEntry}
                    changeEntryStatus={changeEntryStatus}
                    deleteJournalEntry={deleteJournalEntry}
                />
            );
        });
    }, [selectedEntryList, changeEntryStatus, deleteJournalEntry]);

    return (
        <div>
            <div className="modal__body">
                {ShowFile()}
                <div className="form-group form-group--single mt-3">
                    <div className="form-group__item">
                        <label htmlFor="fileDesc">File Description</label>
                        <textarea
                            type="text"
                            value={description}
                            onChange={(e) => onChangeInputValue(e.target)}
                            className="video-description-input"
                            placeholder="Type description here..."
                            rows="4"
                        />
                    </div>
                </div>
            </div>
            {media_view && renderAddJournalEntry()}
            <div className="modal__footer">
                <div onClick={close} className="btn btn-outline">
                    Cancel
                </div>
                <button
                    onClick={() => done(description)}
                    className="btn btn-gradient"
                    disabled={ui.disableButton && editor.isPending}>
                    {editor.isPending ? (
                        <span className="fa fa-spinner fa-spin"></span>
                    ) : (
                        <>Add File</>
                    )}
                </button>
            </div>
        </div>
    );
};

const state = createStructuredSelector({
    ui: getUI,
    editor: getEditorState
});
export default connect(state)(DisplayFile);
