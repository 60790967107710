import React from 'react';
import { Required } from './validators';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
// error components
import IconError from './IconError';

const DefaultInput = (props) => <input {...props} />;
const TextField = ({
    label,
    value,
    validator,
    onValidated,
    submitted,
    onChange,
    errorProps,
    error,
    ErrorComponent = IconError,
    Component = DefaultInput,
    labelProps,
    ...props
}) => {
    const [validationError, setValidationError] = React.useState(null);
    const validatorRef = React.useRef(validator);

    // if the input is required add the required placeholder
    // also add the required validator
    if (props.required) {
        validatorRef.current = validator
            ? (value) => Required(value) || validator(value)
            : Required;
    }

    // run validator fn which checks the validator each value change
    // sets the validators result (string) as the validationError
    const runValidator = React.useCallback(
        ({ target: { value } }) => {
            if (!validatorRef.current) {
                return;
            }
            const validationResult = validatorRef.current(value);
            onValidated && onValidated(props.name, validationResult);

            if (!submitted) {
                return;
            }

            setValidationError(validationResult);
        },
        [setValidationError, validatorRef, submitted, onValidated, props.name]
    );

    // hooks the run validator function into the onChange callback
    props.onChange = onChange
        ? (event) => {
              onChange(event);
              if (value !== event.target.value) {
                  runValidator(event);
              }
          }
        : runValidator;

    // runs it once at start
    useDeepCompareEffectNoCheck(() => {
        if (props) {
            runValidator({ target: { value } });
        }
    }, []);

    return (
        <React.Fragment>
            <label className="custom-label" {...labelProps}>
                {label}
            </label>
            <Component type="text" value={value} {...props} />
            {!!(submitted && (validationError || error)) && (
                <ErrorComponent
                    error={validationError || error}
                    errorProps={errorProps}
                />
            )}
        </React.Fragment>
    );
};
export default TextField;
