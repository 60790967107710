import _ from 'underscore';

import { createSelector } from 'reselect';
import { UserSelector } from 'redux/selectors/UserSelector';

export const PublicUserSelector = (state) => state.public_user;

export const MyPublicUserSelector = (state) => {
    const user = state.user;
    const publicUsers = state.public_user.public_users;
    const index = publicUsers?.findIndex(
        (public_user) => user.id === public_user.id
    );
    return index >= 0 ? _.extend({}, publicUsers[index]) : undefined;
};

export const getPublicUserList = createSelector(
    [PublicUserSelector],
    (publicUser) => _.extend({}, publicUser)
);

export const getMyPublicUser = createSelector(
    [PublicUserSelector, UserSelector],
    (publicUserState, user) => {
        const index = publicUserState.public_users?.findIndex(
            (public_user) => user.id === public_user.id
        );
        return index >= 0
            ? _.extend({}, publicUserState.public_users[index])
            : undefined;
    }
);
export const getPublicUsersList = createSelector(
    PublicUserSelector,
    (public_user_state) => {
        return public_user_state.public_user_list;
    }
);
