import React, { useCallback, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { hideBanner, openDialog, showBanner } from 'common/utils/dialog-utils';
import AddContactsDialog from 'pages/Dialogs/Contacts/AddContactsDialog';

// images
import defaultUser from 'assets/images/default-user.jpg';
import GmailImage from 'assets/images/contacts/gmail.png';
import OutlookImage from 'assets/images/contacts/outlook.png';
import YahooImage from 'assets/images/contacts/yahoo.png';
import LinkedinImage from 'assets/images/contacts/linkedin.png';
import AolImage from 'assets/images/contacts/aol.png';
import { createStructuredSelector } from 'reselect';
import {
    getShareContactList,
    getSearchedContactList,
    getUI,
    getUser
} from 'redux/selectors';

import * as ShareContactListsActions from 'redux/actions/sharecontactlist';

// Icons
import iconSearch from 'assets/images/icons_svg/icon_search.svg';
import iconCloseSm from 'assets/images/icons_svg/icon_close-sm.svg';
import SingleContactDialog from 'pages/Dialogs/SingleContactDialog';
import SuccessAddedContactsDialog from 'pages/Dialogs/SuccessAddedContactsDialog';
import { getSelectedContact } from 'redux/selectors/ShareContactListSelector';

// Onboarding Flow
import 'intro.js/introjs.css';
import { Steps } from 'intro.js-react';
import { updateUI } from 'redux/actions/ui';

import { isOldUser } from 'common/utils/newUser';
import { useHistory } from 'react-router';
import { URLS } from 'common/urls';

const Contacts = ({
    dispatch,
    contacts,
    searchedContacts,
    selectedContact,
    user
}) => {
    const [search, setSearch] = useState('');
    const searchRef = useRef(null);
    const [displayOnboarding, setDisplayOnboarding] = useState(false);
    const [completeStatus, setCompleteStatus] = useState(false);
    const [backStatus, setBackStatus] = useState(false);
    const history = useHistory();
    const stpesRef = useRef(null);

    useEffect(() => {
        if (isOldUser(user)) {
            return;
        }
        if (localStorage.getItem('skipOnboarding') === '1') {
            return;
        }

        setDisplayOnboarding(Boolean(!user.has_seen_welcome_web));
    }, [user]);

    useEffect(() => {
        // must wait a bit for a first fetching...
        setTimeout(() => {
            if (!contacts.isDetailedFetched && !contacts.isPending) {
                dispatch(ShareContactListsActions.fetchDetailed());
            }
        }, 100);
    }, [contacts.isDetailedFetched, contacts.isPending, dispatch]);

    useEffect(() => {
        if (window['cloudsponge']) {
            window['cloudsponge'].init({
                afterSubmitContacts: (contacts) => {
                    openDialog(SuccessAddedContactsDialog, {
                        contacts
                    });
                    contacts.map((contact) => {
                        return dispatch(
                            ShareContactListsActions.createContact(
                                contact.selectedEmail()
                            )
                        );
                    });
                }
            });
        }
    }, [dispatch]);

    useEffect(() => {
        if (selectedContact) {
            const contact = contacts.find(
                (contact) => contact.id === selectedContact.id
            );
            if (contact !== selectedContact) {
                dispatch(ShareContactListsActions.setSelectedContact(contact));
            }
        }
    }, [contacts, dispatch, selectedContact]);

    const renderContactCommons = useCallback((shared) => {
        const entryCount = shared?.entries?.length || 0;
        const journalCount = shared?.journals?.length || 0;
        return `${entryCount} Entries & ${journalCount} Journals in common.`;
    }, []);

    const onEnterPress = useCallback(
        (e) => {
            clearTimeout(searchRef.current);
            searchRef.current = setTimeout(() => {
                dispatch(ShareContactListsActions.fetchSearched(search));
            }, 2000);

            if (e.key === 'Enter') {
                clearTimeout(searchRef.current);
                dispatch(ShareContactListsActions.fetchSearched(search));
            }
        },
        [search, dispatch]
    );

    const steps = [
        {
            title: '',
            intro: ''
        },
        {
            title: 'Import Contacts',
            element: '.import-banner-media-list',
            intro:
                'Looking for a better way to share memories with your friends and family? Import your contacts from numerous sources.',
            position: 'left'
        },
        {
            title: 'Add Contact',
            element: '.page__header-btn',
            intro: 'Or click here to add a contact to your list manually.',
            position: 'right'
        }
    ];
    const [initialStep, setInitialStep] = useState(0);
    const [stepsEnabled, setStepsEnabled] = useState(false);

    useEffect(() => {
        if (displayOnboarding) {
            setTimeout(() => {
                setInitialStep(1);
                setStepsEnabled(true);
            }, 500);
        }
    }, [displayOnboarding]);
    const onExit = useCallback(() => {
        setStepsEnabled(false);
        if (!completeStatus && !backStatus) {
            localStorage.setItem('skipOnboarding', '1');
            dispatch(
                updateUI({
                    onBoarding: false,
                    onBoardingEnd: true
                })
            );
        }
    }, [dispatch, completeStatus, backStatus]);

    const options = {
        hidePrev: false,
        hideNext: false,
        prevLabel: 'Back',
        nextLabel: 'Next',
        showBullets: false,
        doneLabel: 'Finish onboarding',
        exitOnOverlayClick: false
    };

    const onComplete = useCallback(() => {
        setCompleteStatus(true);
        dispatch(
            updateUI({
                onBoarding: false,
                onBoardingEnd: true
            })
        );
        localStorage.setItem('skipOnboarding', '1');
    }, [dispatch]);

    const onBeforeChange = useCallback(
        (nextStepIndex) => {
            switch (nextStepIndex) {
                case 0:
                    setBackStatus(true);
                    setStepsEnabled(false);
                    dispatch(
                        updateUI({
                            onBoardingMoreMenu: true,
                            onBoardingAddMenu: false,
                            bigStep: 3
                        })
                    );
                    stpesRef.current.introJs._currentStep = 1;
                    history.push(URLS.AAM.ROOT);
                    return false;
                default:
                    break;
            }
        },
        [dispatch, history]
    );

    return (
        <main id="main" className="app-main">
            <div className="page-container">
                {displayOnboarding && (
                    <Steps
                        ref={stpesRef}
                        enabled={stepsEnabled}
                        initialStep={initialStep}
                        steps={steps}
                        onExit={onExit}
                        options={options}
                        onBeforeChange={onBeforeChange}
                        onComplete={onComplete}
                    />
                )}

                <div
                    className={`page ${
                        selectedContact
                            ? 'page--contacts contacts-content'
                            : 'page--contacts contacts-content-no-selection'
                    }`}>
                    <div className="page__header page__header--search">
                        <div className="page__title">Contacts</div>
                        <button
                            className="page__header-btn btn btn-gradient"
                            onClick={() => openDialog(AddContactsDialog)}>
                            Add Contact
                        </button>
                    </div>
                    {showBanner('contacts-import-banner') && (
                        <div className="contacts-import-banner">
                            <div className="contacts-import-banner__header">
                                <div className="contacts-import-banner__title">
                                    Import Contacts
                                </div>
                                <div
                                    className="contacts-import-banner__close btn-icon btn-icon--3 btn-icon--sm"
                                    onClick={() =>
                                        hideBanner('contacts-import-banner')
                                    }>
                                    <img src={iconCloseSm} alt="" />
                                </div>
                            </div>
                            <p>
                                Sync your contacts with JRNL to invite them to
                                your entries and journals easily. You decide
                                when do you want to share content. Also, you
                                will be able to invite your contacts if they
                                don’t have a JRNL Account yet.
                            </p>
                            <ul className="import-banner-media-list">
                                <li
                                    className="cloudsponge-launch"
                                    data-cloudsponge-source="gmail">
                                    <img src={GmailImage} alt="gmail" />
                                </li>
                                <li
                                    className="cloudsponge-launch"
                                    data-cloudsponge-source="outlook">
                                    <img src={OutlookImage} alt="gmail" />
                                </li>
                                <li
                                    className="cloudsponge-launch"
                                    data-cloudsponge-source="yahoo">
                                    <img src={YahooImage} alt="gmail" />
                                </li>
                                <li
                                    className="cloudsponge-launch"
                                    data-cloudsponge-source="linkedin">
                                    <img src={LinkedinImage} alt="gmail" />
                                </li>
                                <li
                                    className="cloudsponge-launch"
                                    data-cloudsponge-source="aol">
                                    <img src={AolImage} alt="gmail" />
                                </li>
                            </ul>
                        </div>
                    )}
                    {selectedContact && (
                        <SingleContactDialog
                            selectedContact={selectedContact}
                        />
                    )}
                    <div className="search-box mx-3 mb-3">
                        <img
                            className="search-box__icon icon-static"
                            src={iconSearch}
                            alt=""
                        />
                        <input
                            className="search-box__input"
                            type="search"
                            placeholder="Search contacts"
                            onKeyDown={onEnterPress}
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                        />
                    </div>
                    {contacts.length === 0 && searchedContacts === 0 ? (
                        <div className="no-contacts">No contacts imported.</div>
                    ) : (
                        <div className="contacts-list">
                            <ul className="list list--page">
                                {(searchedContacts.length === 0
                                    ? contacts
                                    : searchedContacts
                                ).map((contact, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={
                                                contact === selectedContact
                                                    ? 'list__item list__item--link active'
                                                    : 'list__item list__item--link'
                                            }
                                            onClick={() =>
                                                dispatch(
                                                    ShareContactListsActions.setSelectedContact(
                                                        selectedContact ===
                                                            contact
                                                            ? null
                                                            : contact
                                                    )
                                                )
                                            }>
                                            <div className="list__img list__img--avatar">
                                                <img
                                                    src={
                                                        contact.user
                                                            ?.avatar_image_url
                                                            ? contact.user
                                                                  ?.avatar_image_url
                                                            : defaultUser
                                                    }
                                                    alt="avatar"
                                                />
                                            </div>
                                            <div className="list__content">
                                                {contact.user
                                                    ?.public_display_name && (
                                                    <div className="list__title">
                                                        <span>
                                                            {
                                                                contact.user
                                                                    .public_display_name
                                                            }
                                                        </span>
                                                    </div>
                                                )}

                                                <div className="list__subtitle">
                                                    <span className="list__subtitle-item">
                                                        {contact.email}
                                                    </span>
                                                    <span className="list__subtitle-item">
                                                        {renderContactCommons(
                                                            contact
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </main>
    );
};

const state = createStructuredSelector({
    contacts: getShareContactList,
    searchedContacts: getSearchedContactList,
    ui: getUI,
    selectedContact: getSelectedContact,
    user: getUser
});
export default connect(state)(Contacts);
