import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// Hooks
import { useHistory } from 'react-router';
// Selectors
import { getUser } from 'redux/selectors';
// firebase
import { db, FIREBASE_DB_ROOT, uid } from 'common/firebase';
// images
import defaultUser from 'assets/images/default-user.jpg';
import { Avatar } from '@material-ui/core';
import { URLS } from 'common/urls';
import { getDifferenceDateStr } from 'common/utils/dateUtils';
import moment from 'moment';

const EntryCreateNotification = ({
    notificationKey,
    data,
    user: authUser,
    dispatch,
    type,
    fullView
}) => {
    const { user, entry, journal, read, created_at } = data;
    const history = useHistory();

    // read notification
    const readNotification = React.useCallback(() => {
        db.ref(
            `${FIREBASE_DB_ROOT}${uid}/${authUser.id}/${notificationKey}`
        ).set({
            ...data,
            read: true
        });
    }, [authUser.id, data, notificationKey]);

    // view entry
    const onViewEntry = React.useCallback(() => {
        readNotification();
        history.push(`${URLS.ENTRY.ROOT}${entry.id}`);
    }, [readNotification, history, entry.id]);

    // go to journal
    const onGoToJournal = React.useCallback(() => {
        readNotification();
        history.push(`${URLS.JOURNAL}${journal.id}`);
    }, [readNotification, history, journal.id]);

    return (
        <Fragment>
            <li className="alert-card__item alert-card__item--buttons">
                <div className="alert-card__img">
                    <Avatar
                        src={(user && user.avatar_image_url) || defaultUser}
                        alt=""
                    />
                </div>
                <div className="alert-card__content">
                    <span className="alert-card__name">
                        {user?.public_display_name}{' '}
                    </span>
                    {`has created an entry, "${entry.title}", on the "
                    ${journal.title}" Shared Journal`}
                    <div className="alert-card__actions">
                        <button
                            className="alert-card__btn"
                            onClick={onViewEntry}>
                            View Entry
                        </button>
                        <button
                            className="alert-card__btn"
                            onClick={onGoToJournal}>
                            Go to Journal+
                        </button>
                    </div>
                </div>
                {!read && (
                    <div className="notification-active-bullet-dropdown"></div>
                )}
                {fullView && created_at && (
                    <p className="notification-timestamp ml-auto">
                        {getDifferenceDateStr(
                            moment.utc(created_at).local(),
                            moment(new Date())
                        )}
                    </p>
                )}
            </li>
        </Fragment>
    );
};

const state = createStructuredSelector({
    user: getUser
});
export default connect(state)(EntryCreateNotification);
